import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextareaAutosize,
  Theme,
  Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import * as React from 'react';
import {
  sendReportCategories,
  reportProductApi
} from 'src/apis/socialMarketplace.api';
import { reportPost, sendReport } from 'src/apis/socialUser';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { buttonColor, modalBackdrop } from 'src/constants/styles';
import IconButtonOptions from '../Button/IconButtonOption';
import { notiSharePost } from 'src/store/action/socialPostAction';
import { useDispatch } from 'react-redux';
import UploadMultipleFiles from '../SocialFieldUpdate/UploadMultipleFiles';
import { uploadMediaSaga } from 'src/store/saga/socialPostSaga';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
    // overflowY: 'hidden'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: 600,
        // overflowY: 'hidden',
        // '&:hover': {
        //   overflowY: 'hidden'
        // },
        '&:hover&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
        },
        '&::-webkit-scrollbar-thumb': {
          background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
          borderRadius: '100px'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundImage: 'linear-gradient(to bottom,#ffa400,#00aefd)'
        }
      },
      textArea: {
        width: '100%',
        minHeight: '120px !important',
        height: '120px !important',
        color: '#000',
        fontFamily: 'Segoe UI, Roboto, Helvetica, Arial, sans-serif',
        border: '1px solid #ccc',
        wordBreak: 'break-word',
        '&:focus-visible': {
          outline: 'none'
        },
        backgroundColor: 'transparent',
        resize: 'none',
        marginTop: '10px',
        marginLeft: '8px',
        padding: '10px ',

        zIndex: 110
      },
      textError: {
        marginTop: '5px !important'
      }
    }),
  { index: 1 }
);

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  back?: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, back, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {back ? (
        <IconButton
          aria-label="close"
          onClick={back}
          sx={{
            position: 'absolute',
            left: 12,
            top: 12,
            height: 36,
            width: 36,
            color: theme => theme.palette.grey[500],
            backgroundColor: '#e5e5e5'
          }}
        >
          <i
            className="fa-regular fa-arrow-left-long"
            style={{ fontSize: 18 }}
          ></i>
        </IconButton>
      ) : null}
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 12,
            height: 36,
            width: 36,
            color: theme => theme.palette.grey[500],
            backgroundColor: '#e5e5e5'
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function DialogUpdatePage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    title,
    accountReport,
    headerText,
    headerSubText,
    listCategoriesReport,
    open,
    styleText,
    handleClose,
    info,
    postReport,
    typeReport,
    tagId,
    groupId
  } = props;
  const dispatch = useDispatch();
  const [fieldUpdate, setFieldUpdate] = React.useState<any>(null);
  const [fieldConfirm, setFieldConfirm] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const [valueText, setValueText] = React.useState<any>('');

  const validationFormReport = Yup.object().shape({
    imageReport: Yup.array()
      .max(1, `Chỉ có thể tải lên 1 ảnh trong một lần`)
      .nullable()
  });

  const formikFormReport: any = useFormik<any>({
    initialValues: {
      imageReport: [] as any
    },
    validationSchema: validationFormReport,
    onSubmit: (values, { resetForm }) => {
      resetForm();
    }
  });

  const uploadImage = async () => {
    const dataRes: Array<string> = await uploadMediaSaga(
      formikFormReport.values.imageReport,
      'moment',
      'market'
    );
    return dataRes || [];
  };

  const handlePostReport = async () => {
    let noti: any;
    try {
      let res;

      const idImage = await uploadImage();

      if (typeReport === 'reportHashTag' || typeReport === 'reportGroup') {
        res = await sendReport({
          entity_id: typeReport === 'reportHashTag' ? tagId : groupId,
          entity_type: typeReport === 'reportHashTag' ? 'Tag' : 'Group',
          report_category_id: fieldConfirm?.id,
          note: ''
        });
      } else if (typeReport === 'product') {
        res = await reportProductApi({
          entity_id: postReport?.id,
          entity_type: 'Product',
          report_category_id: fieldUpdate?.id,
          note: valueText,
          image_id: idImage.length ? idImage[0] : null
        });
      } else if (typeReport === 'page') {
        res = await sendReportCategories({
          entity_id: postReport?.id,
          entity_type: 'Page',
          report_category_id: fieldConfirm?.id ?? fieldUpdate?.id,
          note: valueText
        });
      } else if (typeReport === 'account') {
        res = await sendReport({
          entity_id: accountReport.infor.id,
          entity_type: 'Account',
          report_category_id: fieldConfirm?.id,
          note: ''
        });
      } else {
        res = await reportPost({
          account_id: postReport?.account?.id ?? info?.account?.id,
          status_ids: [postReport?.id ?? info?.id],
          comment: '',
          forward: false,
          report_category_id: fieldConfirm?.id
        });
      }

      if (res.status === 200) {
        noti = {
          code: 200,
          msg: 'Đã gửi phản hồi cho Admin. Cảm ơn vì sự đóng góp của bạn.'
        };
      }
    } catch (error: any) {
      noti = {
        code: 400,
        msg:
          error?.response?.error?.data || 'Có lỗi xảy ra vui lòng thử lại sau.'
      };
    } finally {
      setIsLoading(false);
      handleClose();
      dispatch(notiSharePost(noti));
    }
  };

  const renderContentAction = (option: any, type: any = null) => {
    return (
      <IconButtonOptions
        name={option.text}
        style={{
          width: '100%',
          justifyContent: 'space-between',
          height: 56,
          hover: {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? '#3e4042 !important'
                : '#fff !important'
          },
          marginLeft: '0px'
        }}
        icon={
          <i
            className="fa-light fa-angle-right"
            style={{
              marginRight: '4px'
            }}
          ></i>
        }
        startIconStyle={{
          fontSize: 18,
          margin: '0px 10px 0px 0px',
          backgroundColor: '#ecebeb',
          color: '#000',
          padding: '8px',
          borderRadius: '50%',
          border: '1px solid #e2e2e2'
        }}
        styleNameButton={{
          color:
            theme.palette.mode === 'dark'
              ? '#fff !important'
              : '#000 !important'
        }}
        action={() => {
          if (option?.description) {
            setFieldConfirm(option);
          } else setFieldUpdate(option);
        }}
      />
    );
  };

  return (
    <Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxHeight: '300px'
        }}
      >
        <BootstrapDialog
          onClose={() => {
            handleClose();
            setFieldUpdate(null);
          }}
          aria-labelledby="customized-dialog-title"
          open={open}
          BackdropProps={modalBackdrop}
          maxWidth={'sm'}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              handleClose();
              setFieldUpdate(null);
            }}
            back={
              fieldConfirm?.id
                ? () => setFieldConfirm(null)
                : fieldUpdate?.id
                ? () => setFieldUpdate(null)
                : undefined
            }
          >
            <Typography
              style={{ fontWeight: 'bold', fontSize: 21 }}
              textAlign={'center'}
            >
              {fieldConfirm?.id ? 'Tiêu chuẩn cộng đồng' : title}
            </Typography>
          </BootstrapDialogTitle>
          {fieldConfirm?.id ? (
            <DialogContent dividers className={classes.root}>
              <i
                style={{ fontSize: 24, color: 'red', marginLeft: 8 }}
                className="fa-sharp fa-solid fa-circle-exclamation"
              ></i>

              <div
                style={{
                  marginLeft: '8px',
                  ...styleText
                }}
                dangerouslySetInnerHTML={{ __html: fieldConfirm?.description }}
              />
              <ButtonInherit
                loading={isLoading}
                label="Gửi"
                style={{
                  width: '100%',
                  backgroundColor: isLoading
                    ? '#ffffff'
                    : buttonColor.backgroundColor,
                  marginTop: 10,
                  color: '#ffffff'
                }}
                action={() => {
                  setIsLoading(true);
                  handlePostReport();
                }}
              />
              <Divider />
            </DialogContent>
          ) : (
            <DialogContent
              dividers
              className={classes.root}
              style={{ overflow: 'hidden !important' }}
            >
              <Typography
                style={{
                  fontSize: 20,
                  marginLeft: '8px',
                  fontWeight: 'bold',
                  ...styleText
                }}
              >
                {headerText}
              </Typography>
              <Typography
                style={{
                  fontSize: 16,
                  marginLeft: '8px',
                  ...styleText
                }}
              >
                {headerSubText}
              </Typography>
              {fieldUpdate?.id && fieldUpdate?.children?.length ? (
                fieldUpdate?.children?.map((el: any, index) => (
                  <div
                    key={index}
                    style={{
                      width: '100%',
                      margin: '5px 0px',
                      overflow: 'hidden'
                    }}
                  >
                    {renderContentAction(el, 'confirm')}
                  </div>
                ))
              ) : typeReport === 'product' && fieldUpdate?.id ? (
                <>
                  <TextareaAutosize
                    id="textInputFeedBack"
                    className={classes.textArea}
                    value={valueText}
                    placeholder="Báo cáo sản phẩm..."
                    onChange={e => setValueText(e.target.value)}
                  ></TextareaAutosize>
                  <Grid width={'100%'} marginTop={'10px'} marginLeft={'8px'}>
                    <Typography>Hình ảnh sản phẩm</Typography>
                    <Grid
                      marginTop={'10px'}
                      borderRadius={2}
                      padding={'12px'}
                      sx={{
                        backgroundColor:
                          theme.palette.mode === 'dark' ? '#2a2a2a' : '#f1f2f4'
                      }}
                    >
                      <UploadMultipleFiles
                        heightBoxWrap="100px"
                        widthBoxWrap="100px"
                        paddingBoxWrap="10px"
                        showIconEdit="none"
                        type="file_image"
                        action={file => {
                          formikFormReport.setFieldValue('imageReport', file);
                        }}
                        formik={formikFormReport}
                        field="imageReport"
                        maxImage={1}
                      />
                    </Grid>
                    {
                      <Typography
                        fontSize={13}
                        color="red"
                        className={classes.textError}
                      >
                        {formikFormReport.errors['imageReport']}
                      </Typography>
                    }
                  </Grid>
                  <ButtonInherit
                    loading={isLoading}
                    label="Gửi"
                    style={{
                      width: '100%',
                      backgroundColor: valueText
                        ? buttonColor.backgroundColor
                        : '#ccc',
                      marginTop: 10,
                      color: '#ffffff',
                      marginLeft: '8px'
                    }}
                    disabled={
                      !valueText || formikFormReport.errors['imageReport']
                    }
                    action={async () => {
                      setIsLoading(true);
                      handlePostReport();
                    }}
                  />
                </>
              ) : (
                listCategoriesReport?.map((el: any, index) => (
                  <div
                    key={index}
                    style={{
                      width: '100%',
                      margin: '5px 0px',
                      overflow: 'hidden'
                    }}
                  >
                    {renderContentAction(el)}
                  </div>
                ))
              )}
            </DialogContent>
          )}
        </BootstrapDialog>
      </div>
    </Box>
  );
}
