import axios, { AxiosRequestConfig } from 'axios';
import { USER_TOKEN } from 'src/util/config';
import { BASE_ROOT } from 'src/util/config';

const fetchApi = async (endPoint, method, params, formdata) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v2/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: formdata
  };

  let response = await axios(configs);
  return response;
};

const fetchApiv1 = async (endPoint, method, params, formdata) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: formdata
  };

  let response = await axios(configs);
  return response;
};

export const searchUserGlobal = async (params: any) => {
  return fetchApi('search', 'GET', params, null);
};

export const userSearchHistory = async (params: any) => {
  return fetchApiv1('search_histories', 'GET', params, null);
};

export const addUserSearchHistory = async (formdata: any) => {
  return fetchApiv1('search_histories', 'POST', null, formdata);
};

export const deleteUserSearchHistory = async (id: any) => {
  return fetchApiv1(`search_histories/${id}`, 'DELETE', null, null);
};
