import * as types from 'src/constants/store/report';
export const getListReportReq = () => ({
  type: types.GET_LIST_REPORT_REQ
});
export const getListReport = (data: any) => ({
  type: types.GET_LIST_REPORT_SUCCESS,
  payload: {
    data
  }
});
