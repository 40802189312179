import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { useMatchMedia } from 'src/common/useMatchMedia';
import PostInGroup from './PostInGroup';
import YourContribute from './YourContribute';
const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      rootRight: {
        height: '100%'
      },
      feed: {
        width: '100%'
      },
      grid: {
        height: '100%'
      },
      gridItem: {
        height: '100%'
      },
      root: {
        display: 'flex',
        width: '95%',
        maxWidth: 1200,
        marginBottom: 20
      },
      boxNoti: {
        backgroundColor: theme.palette.background.primary,
        borderRadius: '10px',
        border: '2px solid #cccccc6b',
        marginTop: '22px',
        padding: '80px 0px',
        textAlign: 'center'
      },
      boxRule: {
        backgroundColor: theme.palette.background.primary,
        borderRadius: '10px',
        border: '2px solid #cccccc6b',
        marginTop: '22px'
      },
      imageNoti: {
        width: '120px',
        height: '120px'
      },
      textNoti: {
        fontSize: '20px !important',
        fontWeight: '700 !important',
        color: theme.palette.text.primary
      }
    }),
  { index: 1 }
);

function Index(props) {
  const { keyTab, type } = props;
  const classes = useStyles();
  const groupInfo = useSelector(
    (state: any) => state.groupReducer.groupSelected
  );
  const groupRelationShip =
    useSelector((state: any) => state.groupReducer.groupRelationShip) || {};
  const isDesktopResolution = useMatchMedia(
    groupRelationShip?.admin ? '(min-width:1250px)' : '(min-width:947px)',
    true
  );
  const renderBody = (
    keyTab: string,
    groupRelationShip: any,
    groupInfo: any
  ) => {
    let result: any = null;
    switch (keyTab) {
      case 'postInGroup':
        result = (
          <PostInGroup
            groupRelationShip={groupRelationShip}
            groupInfo={groupInfo}
          />
        );
        break;
      case 'yourContribute':
        result = <YourContribute groupInfo={groupInfo} />;
      default:
        break;
    }
    return result;
  };
  return (
    <div
      className={classes.root}
      style={{
        display: 'flex',
        alignItems: isDesktopResolution ? 'start' : 'center',
        flexDirection: isDesktopResolution ? 'row' : 'column-reverse',
        width: type === 'preview' ? '100%' : undefined
      }}
    >
      <div className={classes.feed}>
        {renderBody(keyTab?.id, groupRelationShip, groupInfo)}
      </div>
    </div>
  );
}

export default Index;
