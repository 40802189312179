import {
  Box,
  CardMedia,
  Rating,
  Stack,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  getDetailProductApi,
  interesteProductApi,
  noInteresteProductApi
} from 'src/apis/socialMarketplace.api';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { buttonColor } from 'src/constants/styles';
import { handlePrice } from 'src/helpers/string';

const useStyles = makeStyles((theme: Theme) => ({
  containerShareProduct: {
    margin: '0 20px 10px',
    cursor: 'pointer'
  },
  shareProduct: {
    display: 'flex',
    flexDirection: 'column',
    // padding: '10px',
    border: theme.palette.border.light,
    borderRadius: '8px',
    overflow: 'hidden'
  },

  productRight: {
    padding: '16px',
    backgroundColor: theme.palette.background.selected
  },
  imageProduct: {
    // width: '100%',
    height: '100%',
    borderRadius: '20px'
  },
  lineClamp: {
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden'
  },
  priceProduct: {
    fontSize: '18px !important',
    color: buttonColor.newTextColor,
    fontWeight: '500 !important'
  },
  ratingProduct: {
    display: 'flex',
    alignItems: 'center'
  },
  countRating: {
    marginLeft: '6px',
    color: '#65676b'
  },
  shareBottom: {
    width: '100%',
    padding: '10px',
    backgroundColor: theme.palette.mode === 'dark' ? '#242526' : '#fff'
  },
  buttonStyle: {
    height: 35,
    width: 35,
    position: 'absolute',
    borderRadius: '50%',
    border: '1px solid rgba(0,0,0,0.12)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#3f3f3f',
    color: '#d6d6d6',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#525252'
    }
  },
  nextButton: {
    right: '5px',
    top: '50%',
    transform: 'translate(0,-50%)'
  },
  prevButton: {
    left: '5px',
    top: '50%',
    transform: 'translate(0,-50%)'
  }
}));

const ShareProductOrCourse = props => {
  const { postData, styleContainerShareProduct, imageProduct } = props;

  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const [productDetail, setProductDetail] = React.useState<any>(null);
  const [isFollowProduct, setIsFollowProduct] = React.useState(false);

  React.useEffect(() => {
    getDetailProduct();
  }, []);

  const getDetailProduct = async () => {
    try {
      if (!postData?.id) return;

      const res = await getDetailProductApi(postData?.id);
      if (res.status === 200) {
        setProductDetail(res.data);
        setIsFollowProduct(res.data?.product_relationships?.follow_product);
      }
    } catch (error) {
      console.log('🚀 - error:', error);
    }
  };

  const handleJoinProduct = async (typeJoin: any) => {
    let response;
    if (typeJoin === 'interested' && postData?.id) {
      response = await interesteProductApi(postData.id);
    } else if (typeJoin === 'no_interested' && postData?.id) {
      response = await noInteresteProductApi(
        postData.id,
        postData.followers_count
      );
    }
    if (response.status === 200) {
      setIsFollowProduct(!isFollowProduct);
    }
  };

  return (
    <Box
      onClick={() => {
        if (postData?.entity_type === 'Product') {
          history.push(`/product/${postData?.id}/about`);
        } else {
          history.push(`/course/${postData?.id}/about`);
        }
      }}
      className={classes.containerShareProduct}
      sx={{ ...styleContainerShareProduct }}
    >
      <Box className={classes.shareProduct}>
        <CardMedia
          component="img"
          src={postData?.preview_url ?? imageProduct}
          height="400px"
        />

        <Box className={classes.productRight}>
          <Typography
            className={classes.lineClamp}
            sx={{ fontSize: '18px', fontWeight: '500' }}
            gutterBottom
          >
            {postData?.title}
          </Typography>

          <Typography className={classes.priceProduct}>
            {postData?.price
              ? Number(postData?.price).toFixed() === '0'
                ? 'Miễn phí'
                : handlePrice(postData?.price)
              : postData?.min_price === postData?.max_price
              ? handlePrice(postData?.min_price)
              : `${handlePrice(postData?.min_price)} - ${handlePrice(
                  postData?.max_price
                )}`}
          </Typography>
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <div className={classes.ratingProduct}>
                <Rating
                  name="read-only"
                  value={postData?.rating}
                  size="medium"
                  readOnly
                  color={buttonColor.newBackgroundColor}
                  precision={0.5}
                />
                <Typography className={classes.countRating}>
                  ({postData?.rating_count ? postData?.rating_count : 0} đánh
                  giá)
                </Typography>
              </div>
            </Stack>

            {/* {!productDetail?.product_relationships?.host_product ? (
              !isFollowProduct ? (
                <ButtonInherit
                  label="Quan tâm"
                  icon={
                    <i
                      style={{
                        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                        fontSize: 17
                      }}
                      className="fa-light fa-star"
                    ></i>
                  }
                  style={{
                    fontSize: 15
                  }}
                  styleText={{
                    [theme.breakpoints.down('lg')]: {
                      display: 'none'
                    }
                  }}
                  action={e => {
                    e.stopPropagation();
                    handleJoinProduct('interested');
                  }}
                />
              ) : (
                <ButtonInherit
                  label="Quan tâm"
                  icon={
                    <i
                      style={{
                        fontSize: '16px',
                        color: 'white'
                      }}
                      className="fa-solid fa-circle-check"
                    ></i>
                  }
                  style={{
                    margin: '0px 8px 0px 0px',
                    width: 'auto',
                    color: '#fff',
                    backgroundColor: '#7165e0'
                  }}
                  styleText={{
                    color: 'white',
                    [theme.breakpoints.down('lg')]: {
                      display: 'none'
                    }
                  }}
                  action={e => {
                    e.stopPropagation();

                    handleJoinProduct('no_interested');
                  }}
                />
              )
            ) : null} */}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default ShareProductOrCourse;
