import {
  Box,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import InputSearch from 'src/components/Input/SocialInput/InputSearch';
import { PATHS } from 'src/constants/paths';
import ClickOutHandler from 'react-onclickout';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useHistory } from 'react-router-dom';
import CUPost from 'src/components/CUPost';
import DialogCUMoment from 'src/components/Dialog/DialogCUMoment';
import BaseLink from 'src/components/Link/BaseLink';
import SocialCutImage from 'src/components/SocialCutImage/SocialCutImage';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 600,
    height: 'calc(100vh - 75px)',
    borderRadius: 10,
    backgroundColor: '#f1f2f5',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
    border: '1px solid rgba(0,0,0,0.05)',
    overflow: 'hidden'
  },
  wrapBlock: {
    borderRadius: '10px',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
    padding: '12px'
  },
  title: {
    fontSize: '17px !important',
    fontWeight: '600 !important'
  },
  avatar: {
    width: '36px !important',
    height: '36px !important',
    marginTop: 5
  },
  listItem: {
    padding: '4px 4px 4px 8px !important',
    alignItems: 'flex-start !important',
    '&:hover': {
      borderRadius: '10px !important'
    }
  },
  listItemTitle: {
    padding: '8px 4px !important',
    alignItems: 'flex-start !important',
    '&:hover': {
      borderRadius: '10px !important'
    }
  },
  text: {
    fontSize: '15px',

    fontWeight: '500 !important'
  },
  subText: {
    color: '#65676b',
    fontSize: '13px !important'
  },
  wrapIcon: {
    height: 32,
    width: 32,
    borderRadius: '50%',
    backgroundColor: 'rgba(0,0,0,0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const MenuHeader = props => {
  const { isOpen, setIsOpen, matches, handleClose } = props;
  const classes = useStyles();
  const history = useHistory();
  const [openMoment, setOpenMoment] = React.useState(false);
  const [openCUPost, setOpenCUPost] = React.useState(false);
  const [keyword, setKeyword] = React.useState<string>('');

  let listSocial = [
    {
      key: 'friendSocial',
      label: 'Bạn bè',
      subLabel: 'Tìm kiếm bạn bè hoặc những người bạn có thể biết.',
      action: () => {
        history.push('/friends');
      },
      positionY: -225,
      path: null
    },

    {
      key: 'groupSocial',
      label: 'Nhóm',
      subLabel: 'Kết nối với những người cùng chung sở thích.',
      action: () => {
        history.push(PATHS.GROUP);
      },
      positionY: -35,
      path: null
    },
    {
      key: 'pageSocial',
      label: 'Trang',
      subLabel: 'Khám phá và kết nối với các doanh nghiệp trên EMSO.',
      action: () => {
        history.push(PATHS.PAGE);
      },
      positionY: -256,
      positionX: 2,
      path: null
    },
    {
      key: 'eventSocial',
      label: 'Sự kiện',
      subLabel:
        'Tổ chức hoặc tìm những sự kiện cũng những hoạt động khác trên mạng và quanh đây.',
      action: () => {
        history.push(PATHS.EVENT);
      },
      positionY: -633,
      path: null
    },
    {
      key: 'growSocial',
      label: 'Gọi vốn',
      subLabel: 'Tìm các gọi vốn mà bạn muốn xem.',

      action: () => {
        history.push(PATHS.GROW);
      },
      positionY: -159,
      path: null
    },
    {
      key: 'recruitSocial',
      label: 'Tuyển dụng',
      subLabel: 'Tìm các công việc mà bạn muốn xem.',

      action: () => {
        history.push(PATHS.RECRUIT);
      },
      positionY: -194,
      path: null
    },
    {
      key: 'courseSocial',
      label: 'Khóa học',
      subLabel: 'Tìm các khóa học mà bạn muốn xem.',

      action: () => {
        history.push(PATHS.COURSE);
      },
      positionY: -96.2,
      path: null
    },
    {
      key: 'fundSocial',
      label: 'Hiến máu',
      subLabel: 'Tham gia các hoạt động xã hội trong cộng đồng của bạn.',

      action: () => {
        history.push(PATHS.BLOOD_DONATION);
      },
      positionY: -331,
      path: null
    },
    {
      key: 'musicSpace',
      label: 'Không gian âm nhạc',
      subLabel: 'Một không gian âm nhạc của cộng đồng, bạn có thể vào xem.',
      action: () => {
        history.push(PATHS.MUSICSPACE);
      },
      positionY: -435,
      path: null
    },
    {
      key: 'chat',
      label: 'Trò chuyện',
      subLabel: 'Nơi giao tiếp của bạn với cộng đồng người dùng.',
      action: () => {
        history.push(PATHS.CHAT);
      },
      positionY: -501,
      path: null
    }
  ];

  const listMenuEntertainment = [
    {
      key: 'moment',
      subLabel: 'Video ngắn về những khoảnh khắc thú vị làm bạn yêu thích.',
      label: 'Khoảnh khắc',

      action: () => {
        history.push('/moment');
      },
      positionY: 0,
      path: null
    },
    {
      key: 'watch',
      subLabel:
        'Đích đến của video phù hợp với sở thích và quan hệ kết nối của bạn.',
      label: 'Watch',

      action: () => {
        history.push('/watch');
      },
      positionY: -67,
      path: null
    }
  ];

  const listMenuCart = [
    {
      key: 'marketPlace',
      subLabel: 'Mua bán trong cộng đồng của bạn.',
      label: 'Không gian thương mại',
      action: () => {
        history.push(PATHS.MARKETPLACE);
      },
      positionY: -368,
      path: null
    }
  ];

  const listMenuOwn = [
    {
      key: 'storyHistory',
      label: 'Kỷ niệm',
      subLabel: 'Lướt xem ảnh, video, bài viết của của bạn trên EMSO.',
      action: () => {
        history.push(PATHS.CELEBRATE);
      },
      positionY: -402,
      path: null
    },
    {
      key: 'saveSocial',
      label: 'Đã lưu',
      subLabel: 'Tìm bài viết, ảnh, video mà bạn đã lưu để xem sau.',

      action: () => {
        history.push(PATHS.PAGE_SAVED);
      },
      positionY: -126,
      path: null
    },
    {
      key: 'historyPayment',
      label: 'Đơn đặt hàng và thanh toán',
      subLabel: 'Tra cứu lịch sử thanh toán bạn đã thực hiện.',

      action: () => {
        history.push(PATHS.PAYMENT_HISTORY);
      },
      positionY: -666,
      path: null
    }
  ];

  const listMenuAction = [
    {
      id: 'post',
      icon: 'fa-solid fa-pen-to-square',
      label: 'Post',
      action: () => {
        setOpenCUPost(true);
      }
    },
    {
      id: 'moment',
      icon: 'fa-solid fa-bolt',
      label: 'Khoảnh khắc',
      action: () => {
        setOpenMoment(true);
      }
    },
    // {
    //   id: 'watch',
    //   icon: 'fa-solid fa-airplay',
    //   label: 'Watch'
    // },
    {
      id: 'page',
      icon: 'fa-solid fa-flag-swallowtail',
      label: 'Trang',
      action: () => {
        history.push('/pages/creation');
      }
    },
    {
      id: 'group',
      icon: 'fa-solid fa-users',
      label: 'Nhóm',
      action: () => {
        history.push('/groups/create');
      }
    },
    {
      id: 'event',
      icon: 'fa-solid fa-calendar',
      label: 'Sự kiện',
      action: () => {
        history.push('/events/create');
      }
    }
  ];

  const renderBlock = (title, listMenu) => {
    return (
      <Box
        sx={{
          backgroundColor: 'background.primary'
        }}
      >
        <Box style={{ height: 12 }}></Box>
        <Typography className={classes.title}>{title}</Typography>

        {listMenu.map((item: any) => {
          const isMatchSearch =
            item.label.toUpperCase().includes(keyword.toUpperCase()) ||
            keyword === '' ||
            item.id;

          return (
            isMatchSearch && (
              <BaseLink
                key={item.key}
                url={item.path}
                component={
                  <ListItem
                    classes={{
                      root: title ? classes.listItem : classes.listItemTitle
                    }}
                    button
                    onClick={() => {
                      item.action && item.action();
                      setIsOpen(false);
                      handleClose?.();
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: '44px' }}>
                      {item.icon ? (
                        <div className={classes.wrapIcon}>
                          <i style={{ fontSize: 18 }} className={item.icon}></i>
                        </div>
                      ) : (
                        <SocialCutImage
                          item={item}
                          image={
                            'https://s3-hn-2.cloud.cmctelecom.vn/sn-web/uploads/menu/IconMenu.png'
                          }
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography className={classes.text}>
                          {item.label}
                        </Typography>
                      }
                      secondary={
                        item.subLabel ? (
                          <Typography className={classes.subText}>
                            {item.subLabel}
                          </Typography>
                        ) : (
                          ''
                        )
                      }
                      sx={{ marginTop: '0px' }}
                    />
                  </ListItem>
                }
              />
            )
          );
        })}
        <div style={{ height: 12 }}></div>
      </Box>
    );
  };

  const renderCreate = () => {
    return (
      <Box
        className={classes.wrapBlock}
        sx={{
          width: 200,
          position: 'sticky',
          top: 0,
          right: 0,
          backgroundColor: 'background.primary'
        }}
      >
        <Typography style={{ fontSize: 20, fontWeight: 700 }}>Tạo</Typography>
        {renderBlock('', listMenuAction.slice(0, 3))}
        <Divider />
        {renderBlock('', listMenuAction.slice(3))}
      </Box>
    );
  };

  const renderMenu = () => {
    return (
      <Box
        className={classes.wrapBlock}
        sx={{
          width: 360,
          marginBottom: 12,
          backgroundColor: 'background.primary'
        }}
      >
        <InputSearch
          keyword={keyword}
          setKeyword={setKeyword}
          label="Tìm kiếm trong menu"
        />
        {renderBlock('Xã hội', listSocial)}
        <Divider />
        {renderBlock('Giải trí', listMenuEntertainment)}
        <Divider />
        {renderBlock('Mua sắm', listMenuCart)}
        <Divider />
        {renderBlock('Cá nhân', listMenuOwn)}
      </Box>
    );
  };

  return (
    <>
      {isOpen && (
        <ClickOutHandler onClickOut={() => setIsOpen(false)}>
          {!matches ? (
            renderCreate()
          ) : (
            <Box
              className={classes.root}
              sx={{
                backgroundColor: 'background.default'
              }}
            >
              <Typography
                style={{ fontSize: 24, fontWeight: 700, padding: '12px' }}
              >
                Menu
              </Typography>

              <PerfectScrollbar
                options={{ suppressScrollX: true }}
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  height: 'calc(100% - 60px)',
                  position: 'relative'
                }}
              >
                {renderMenu()}
                <div style={{ width: 12 }}></div>
                {renderCreate()}
              </PerfectScrollbar>
            </Box>
          )}
        </ClickOutHandler>
      )}
      {openMoment && (
        <DialogCUMoment
          type="header"
          open={openMoment}
          setOpen={setOpenMoment}
          handleClose={() => setOpenMoment(false)}
        />
      )}
      <CUPost
        type="header-post"
        openCUPost={openCUPost}
        setOpenCUPost={setOpenCUPost}
      />
    </>
  );
};

export default MenuHeader;
