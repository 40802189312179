import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from 'src/store/action/socialProductAction';
import * as types from 'src/constants/store/marketplaceSocial';
import {
  detailOrder,
  detailOrderBuyer,
  getDetailProductApi,
  getDetailProductShopApi
} from 'src/apis/socialMarketplace.api';
import { getListPagesApi } from 'src/apis/socialPages.api';
const typesSetting: any = types;

export const getProductDetailSagas = function* ({ payload }) {
  try {
    yield put(actions.loadingProduct(true));
    const { idProduct, type, pageId } = payload;
    let response;
    if (type === 'buyer') {
      response = yield call(getDetailProductApi, idProduct);
    }

    if (type === 'seller' && pageId) {
      response = yield call(getDetailProductShopApi, idProduct, pageId);
    }
    if (response.status === 200) {
      yield put(actions.getProductDetailSuccess(response.data));
    }
  } catch (error: any) {
    yield put(
      actions.getProductDetailError(error?.response?.data?.error || '')
    );
  } finally {
    yield put(actions.loadingProduct(false));
  }
};

export const getOrderDetailSagas = function* ({ payload }) {
  try {
    const { pageId, idOrder, type } = payload;
    let response;

    if (type === 'buyer') {
      response = yield call(detailOrderBuyer, idOrder);
    }

    if (type === 'seller' && pageId) {
      response = yield call(detailOrder, pageId, idOrder);
    }
    if (response.status === 200) {
      yield put(actions.getOrderDetailSuccess(response.data));
    }
  } catch (error: any) {
    yield put(actions.getOrderDetailError(error.response));
  }
};

export const getListMyPageMarketSagas = function* ({ payload }) {
  try {
    const { params } = payload;

    yield put(actions.isLoadingListPageMarket(true));

    let response = yield call(getListPagesApi, params);

    if (response.status === 200) {
      yield put(
        actions.getListMyPageMarketSuccess({
          listPage: response.data,
          hasMore: response.data?.length >= params.limit,
          isLoading: false
        })
      );
    }
  } catch (error) {}
};

export function* watchProductSocialAsync() {
  yield takeEvery(
    typesSetting.GET_PRODUCT_DETAIL_REQUEST,
    getProductDetailSagas
  );
  yield takeEvery(
    typesSetting.GET_LIST_MY_PAGE_MARKET_REQUEST,
    getListMyPageMarketSagas
  );
}

export function* watchOrderSocialAsync() {
  yield takeEvery(typesSetting.GET_ORDER_DETAIL_REQUEST, getOrderDetailSagas);
}
