import { Box, CircularProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import DialogPreviewMedia from 'src/components/Dialog/DialogPreviewMedia';
import ImageRender from 'src/components/ImageGrid/components/ImageRender';
import { useInView } from 'react-hook-inview';
import { getPostDetailApi, getPostMediaApi } from 'src/apis/socialPost.api';
import VideoJs from 'src/components/VideoPlayer/VideoJs';

const useStyles = makeStyles((theme: Theme) => ({
  wrapMediaComment: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: 15,
    marginTop: -5,
    '&:hover': {
      cursor: 'pointer'
    },
    border: '1px solid rgba(0,0,0,0.1)'
  },
  wrapPreComment: {
    overflow: 'hidden',
    borderRadius: '15px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    display: 'block',
    opacity: 0.5,
    width: '100%',
    maxWidth: '260px',
    maxHeight: '260px',
    borderRadius: 15,
    objectFit: 'cover',
    objectPosition: 'center'
  },
  loadingPreComment: {
    '&.MuiCircularProgress-root': {
      position: 'absolute'
    }
  }
}));
const Media = React.memo((props: any) => {
  const {
    post,
    type,
    typePost,
    isPreCommment,
    scrollToTop,
    postShare,
    setPostData,
    styleBox,
    styleImage
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const [mediaSelected, setMediaSelected] = React.useState<any>(null);
  const [indexMedia, setIndexMedia] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [postMedia, setPostMedia] = React.useState<any>(null);
  const [idViewPort, setIdViewPort] = React.useState(null as any);
  const [visibleVideo, setVisibleVideo] = React.useState(false);
  const [openPreview, setOpenPreview] = React.useState(false);

  const [ref, isVisible] = useInView({
    threshold: 1
  });

  let fileData = post?.media_attachments;

  const getPostMediaDetail = async () => {
    let res = await getPostMediaApi(mediaSelected);

    if (res.status === 200) {
      const postId = res.data.id;
      let response = await getPostDetailApi(postId);
      if (response.status === 200) {
        setPostMedia({
          ...res.data,
          comment_moderation: response.data.comment_moderation,
          off_comment: response.data.off_comment
        });
      }
    }
  };

  React.useEffect(() => {
    if (mediaSelected) {
      setOpen(true);

      getPostMediaDetail();
    }

    if (isVisible) {
      setIdViewPort(post?.media_attachments[0]?.id);
      setVisibleVideo(true);
    } else {
      setVisibleVideo(false);
      setIdViewPort(null);
    }

    return () => {};
  }, [mediaSelected, isVisible]);

  const isAVideo = path => {
    if (!path) return false;
    if (
      path.includes('.mp4') ||
      path.includes('.mov') ||
      path.includes('.qt')
    ) {
      return true;
    }

    return false;
  };

  const renderMedia = () => {
    let aspect = post?.media_attachments?.[0]?.meta?.original?.aspect ?? 1;
    let aspectVideo = post?.media_attachments?.[0]?.meta?.small?.aspect ?? 1;
    let mediaAttachments = post?.media_attachments[0][0];

    return (
      <>
        {type === 'comment' ? (
          isPreCommment && post?.media_attachments[0]?.length > 0 ? (
            <Box className={classes.wrapPreComment}>
              {['image/jpeg', 'image/png'].includes(mediaAttachments?.type) ? (
                <img
                  src={mediaAttachments.preview}
                  className={classes.img}
                  alt=""
                />
              ) : (
                <video src={mediaAttachments.preview} className={classes.img} />
              )}
              <CircularProgress className={classes.loadingPreComment} />
            </Box>
          ) : isAVideo(post?.media_attachments[0]?.url) ? (
            <video
              src={post?.media_attachments[0].url}
              controls
              style={{
                maxWidth:
                  aspectVideo === 1 || aspectVideo > 1.5
                    ? 300
                    : aspectVideo > 1
                    ? 300
                    : 404 * aspectVideo,
                height:
                  aspectVideo === 1
                    ? 300
                    : aspectVideo > 1
                    ? 324 / aspectVideo
                    : 324,
                backgroundColor: '#000'
              }}
              className={classes.wrapMediaComment}
            ></video>
          ) : (
            <div
              style={{
                backgroundImage: `url(${post?.media_attachments[0].preview_url})`,
                width: aspect === 1 ? 210 : aspect > 1 ? 260 : 260 * aspect,
                height: aspect === 1 ? 210 : aspect > 1 ? 260 / aspect : 210
              }}
              className={classes.wrapMediaComment}
              onClick={() =>
                setMediaSelected &&
                setMediaSelected(post?.media_attachments[0]?.id)
              }
            ></div>
          )
        ) : post.post_type === 'livestream' ? (
          <div
            ref={ref}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push(
                `/live/detail/${post?.id}?host=${post?.account?.username}`
              );
            }}
          >
            <VideoJs
              processingLiveStream={post?.processing}
              isLiveStream={true}
              src={post?.media_attachments[0]?.remote_url}
              previewMedia={
                post?.media_attachments[0]?.preview_url ??
                post?.media_attachments[0]?.preview_remote_url
              }
              id={post?.media_attachments[0]?.id}
              idViewPort={idViewPort}
              visibleVideo={visibleVideo}
              autoPlay={true}
            />
          </div>
        ) : (
          <ImageRender
            postShare={postShare}
            typePost={typePost}
            type={type}
            post={post}
            images={fileData?.map((el: any) =>
              el.type !== 'video' ? el.url : el.remote_url ?? el.url
            )}
            medias={fileData}
            setMediaSelected={setMediaSelected}
            setIndexMedia={setIndexMedia}
            scrollToTop={scrollToTop}
            styleBox={styleBox}
            styleImage={styleImage}
          />
        )}

        {open && mediaSelected && (
          <DialogPreviewMedia
            open={open}
            setOpen={setOpen}
            post={post}
            postMedia={postMedia}
            setPostMedia={setPostMedia}
            indexMedia={indexMedia}
            setIndexMedia={setIndexMedia}
            setMediaSelected={setMediaSelected}
          />
        )}
      </>
    );
  };

  return (
    <>
      {renderMedia()}
      {openPreview && Object.keys(post).length && (
        <DialogPreviewMedia
          open={openPreview}
          setOpen={setOpenPreview}
          post={post}
          postMedia={post}
          setPostMedia={setPostData}
          indexMedia={indexMedia}
          setIndexMedia={setIndexMedia}
          setMediaSelected={setPostData}
          typePreview="preview_live"
        />
      )}
    </>
  );
});

export default Media;
