import { Box, Divider, Grid, Theme, Typography } from '@mui/material';import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';

import ListButton from 'src/components/List/ListButton';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        height: '100%',
        position: 'fixed',
        padding: '5px 8px 40px 3px',
        boxShadow: '1px 0px 5px 0.4px rgb(0 0 0 / 10%)'
      },
      headerPage: {
        minHeight: 50,
        margin: '10px 0px 10px'
      },
      footerPage: {
        position: 'absolute',
        bottom: '55px',
        left: 0,
        width: '100%',
        zIndex: 1200
      },
      rootPage: {
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        '&:hover': {
          overflowY: 'auto'
        },
        '&:hover&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#bdbdbd',
          borderRadius: 10
        }
      },
      inputSearch: {
        width: '100%'
        // marginTop: '10px !important'
      },
      cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
          borderColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        '& fieldset': {
          display: 'none'
        },
        '&:hover': {
          backgroundColor:
            theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C',
        borderRadius: '30px !important',
        height: 35,
        padding: '7px !important',
        margin: 'auto'
      },
      notchedOutline: {
        border: `1px solid ${theme.palette.myBackgroundColor?.main} !important`
      },
      multilineColor: {
        fontSize: 13
      },
      avatar: {
        width: '56px !important',
        height: '56px !important'
      },
      list: {
        width: '100%'
      },
      selected: {
        backgroundColor: '#ECF3FF !important'
      }
    }),
  { index: 1 }
);

const Index = () => {
  const classes = useStyles();
  const history = useHistory();
  const match: any = useRouteMatch();
  const theme: any = useTheme();
  const [keyParams, setKeyParams] = React.useState<any>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<any>('feed');
  const listMenuPages = [
    {
      id: 'common',
      title: 'Bài viết của bạn',
      icon: 'fa-light fa-gear',
      action: () => {
        history.push(`/diary/common`);
      },
      position: 'top',
      text: [
        {
          name: 'Cập nhật nội dung, lượt check in, ghi chú, v.v',
          action: () => {}
        },
        { name: 'Ảnh và video', action: () => {} },
        { name: 'Bài viết từ ứng dụng khác', action: () => {} }
      ]
    },
    {
      id: 'tags',
      title: 'Hoạt động có gắn thẻ bạn',
      icon: 'fa-light fa-tag',
      action: () => {
        history.push('/diary/tags');
      },
      position: 'top'
    },
    {
      id: 'bookmark',
      title: 'Bộ sưu tập và mục đã lưu',
      icon: 'fa-regular fa-bookmark',
      action: () => {
        history.push('/diary/bookmark');
      },
      accordion: true,
      itemChildren: [
        {
          id: 'status_bookmarks_logs',
          title: 'Mục bạn đã lưu',
          styleTitle: { fontWeight: '400' },
          action: () => {
            history.push('/diary/status_bookmarks_logs');
          }
        },
        {
          id: 'bookmark_collections_logs',
          title: 'Bộ sưu tập',
          styleTitle: { fontWeight: '400', margin: '0px !important' },
          action: () => {
            history.push(`/diary/bookmark_collections_logs`);
          }
        }
      ],
      position: 'top'
    },
    {
      id: 'pages_logs',
      title: 'Trang',
      icon: 'fa-regular fa-flag',
      action: () => {
        history.push('/diary/pages_logs');
      },
      position: 'top'
    },
    {
      id: '',
      title: 'Nhóm, sự kiện và thước phim',
      icon: 'fa-light fa-users',
      action: () => {},
      position: 'top'
    },
    {
      id: '',
      title: 'Thông tin trên trang cá nhân',
      icon: 'fa-light fa-user',
      action: () => {},
      position: 'top'
    },
    {
      id: '',
      title: 'Quan hệ kết nối',
      icon: 'fa-light fa-sliders',
      action: () => {},
      position: 'top'
    },
    {
      id: '',
      title: 'Hoạt động đã ghi lại và hoạt động khác',
      icon: 'fa-light fa-shield-plus',
      action: () => {},
      position: 'top'
    },
    {
      id: '',
      title: 'Kho lưu trữ',
      icon: 'fa-light fa-ellipsis',
      action: () => {},
      position: 'bottom'
    },
    {
      id: '',
      title: 'Thùng rác',
      icon: 'fa-light fa-trash-can',
      action: () => {},
      position: 'bottom'
    },
    {
      id: '',
      title: 'Lịch sử hoạt động',
      icon: 'fa-light fa-list-radio',
      action: () => {},
      position: 'bottom'
    },
    {
      id: '',
      title: 'Xem lại dòng thời gian và ảnh thẻ',
      icon: 'fa-light fa-ellipsis',
      action: () => {},
      position: 'bottom'
    }
  ];

  React.useEffect(() => {
    let { key } = match.params;
    setSelectedIndex(key);
  }, [match.path, match.params.key]);
  const renderHeader = () => {
    return (
      <Grid container spacing={2}>
        <Grid container item justifyContent="space-between" alignItems="center">
          <Typography
            style={{ fontWeight: 'bold', paddingLeft: 4 }}
            variant="h5"
          >
            Nhật ký hoạt động
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderBody = () => {
    return {
      bodyTop: (
        <div>
          <ListButton
            item={listMenuPages.filter(el => el.position === 'top')}
            selectedItem={selectedIndex}
            width={'290px'}
            type="watch"
            typeAction={'action'}
          />
        </div>
      ),
      bodyCenter: (
        <div style={{ marginBottom: '60px' }}>
          <ListButton
            item={listMenuPages.filter(el => el.position === 'bottom')}
            selectedItem={selectedIndex}
            width={'290px'}
            type="watch"
            typeAction={'action'}
          />
        </div>
      ),
      bodyFooter: null
    };
  };
  return (
    <Box
      className={classes.root}
      sx={{
        position:
          renderBody().bodyTop ||
          renderBody().bodyCenter ||
          renderBody().bodyFooter
            ? 'fixed'
            : 'static',

        width:
          renderBody().bodyTop ||
          renderBody().bodyCenter ||
          renderBody().bodyFooter
            ? keyParams
              ? '380px'
              : 'undefined'
            : '0px',
        margin:
          renderBody().bodyTop ||
          renderBody().bodyCenter ||
          renderBody().bodyFooter
            ? '0px'
            : '5px 10px 40px -20px',
        backgroundColor: 'background.primary'
      }}
    >
      <div className={classes.headerPage}>{renderHeader()}</div>

      <div className={classes.rootPage}>
        {renderBody().bodyTop && <Divider />}
        {renderBody().bodyTop}
        {<div style={{ height: 10 }}></div>}
        {renderBody().bodyCenter && <Divider />}
        {renderBody().bodyCenter}
        {renderBody().bodyFooter && <Divider />}
      </div>
    </Box>
  );
};

export default Index;
