/* eslint-disable jsx-a11y/alt-text */ import {
  Badge,
  Box,
  IconButton
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { getNotificationApi } from 'src/apis/commonSocialApi';
import { postSeenNotiApi } from 'src/apis/socialNoti';
import menuglobal from 'src/assets/images/menuglobal.png';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { PATHS } from 'src/constants/paths';
import MessageIcon from 'src/containers/MessageIcon/MessageIcon';
import NotificationHeader from './NotificationHeader';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      rightHeader: {
        width: 300,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'relative'
      },

      btnUser: {
        color: '#000000 !important',
        borderRadius: `30px !important`,
        marginRight: '3px !important',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'hsla(0,0%,95%,.8) !important'
        }
      },
      btnRightHeader: {
        margin: '0 3px !important',
        width: theme.spacing(5),
        height: theme.spacing(5),
        fontSize: '17px'
        // backgroundColor: '#e0e0e0 !important',
        // '&:hover': {
        //   backgroundColor: 'hsla(0,0%,95%,.8) !important'
        // }
      },

      userNameHeader: {
        margin: '0 5px',
        textTransform: 'capitalize',
        fontWeight: 700
      },
      wrapArrow: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: '#f1f2f5',
        borderRadius: '50%',
        padding: 1,
        height: 14,
        width: 14,
        right: -5,
        bottom: 0,
        // border: '2px solid rgba(0,0,0,0.1)',
        zIndex: 1
      }
    }),
  { index: 1 }
);

export default function RightHeaderGlobal(props) {
  const classes = useStyles(props);
  const [openNotification, setOpenNotification] = React.useState(false);
  const {
    children,
    onSelectExpand,
    numberNotification,
    setNumberNotification,
    noti,
    defaultDataNoti,
    handleClosePreview,
    setDefaultDataNoti
  } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const meInfo = useSelector((state: any) => state.meReducer.info) || {};
  const [notifications, setNotifications] =
    React.useState<any>(defaultDataNoti);
  const [newNotis, setNewNotis] = React.useState<any>([]);
  const [oldNotis, setOldNotis] = React.useState<any>([]);
  const [hasMore, setHasMore] = React.useState(true);
  const currentTime = new Date().getHours() * 60 + new Date().getMinutes();
  const currentDay = new Date().getDay();
  const match: any = useRouteMatch();

  function handleClickExpand(event) {
    if (typeof onSelectExpand === 'function') {
      onSelectExpand(event);
    }
  }
  const fetchDataNoti = async () => {
    let response = await getNotificationApi({ limit: 20 });
    if (response.status === 200) {
      setDefaultDataNoti(response.data);

      if (response.data.length) {
      } else {
      }
    }
  };
  const fetchNotification = async params => {
    let response = await getNotificationApi(params);
    if (response.status === 200) {
      if (response.data.length) {
        setNotifications(prev => [...prev, ...response.data]);
      } else {
        setHasMore(false);
      }
    }
  };

  const handleSeenNoti = async () => {
    setOpenNotification(true);

    let response = await postSeenNotiApi({
      notifications: {
        last_read_id: defaultDataNoti[0]?.id
      }
    });
    if (response.status === 200) {
      setNumberNotification && setNumberNotification(0);
    }
  };

  useEffect(() => {
    setNewNotis(
      notifications?.filter(
        (el: any) =>
          currentTime -
            (new Date(el?.created_at).getHours() * 60 +
              new Date(el?.created_at).getMinutes()) <
            90 &&
          currentDay - new Date(el?.created_at).getDay() === 0 &&
          el
      )
    );
    setOldNotis(
      notifications?.filter(
        (el: any) =>
          currentTime -
            (new Date(el?.created_at).getHours() * 60 +
              new Date(el?.created_at).getMinutes()) >
            90 ||
          (currentDay - new Date(el?.created_at).getDay() > 0 && el)
      )
    );
  }, [JSON.stringify(notifications)]);

  React.useEffect(() => {
    if (defaultDataNoti) setNotifications(defaultDataNoti);
    else {
      fetchDataNoti();
    }
  }, [JSON.stringify(defaultDataNoti)]);
  React.useEffect(() => {
    if (match.path === PATHS.CHAT) {
    }
  }, [JSON.stringify(match)]);

  const isShowIconMessage = () => {
    if (match.path === PATHS.CHAT) {
      return null;
    } else if (match.params?.key === 'chat') {
      return null;
    } else {
      return <MessageIcon handleClose={handleClosePreview} meInfo={meInfo} />;
    }
  };
  return (
    <>
      <div className={classes.rightHeader}>
        {children}
        {isShowIconMessage()}
        <IconButton
          aria-label="add an alarm"
          className={classes.btnRightHeader}
          onClick={() => handleSeenNoti()}
          disabled={match.path === '/notifications' ? true : false}
          sx={{
            backgroundColor: 'button.secondary.background',
            '&:hover': {
              backgroundColor: 'button.primary.hover'
            }
          }}
        >
          {numberNotification > 0 ? (
            <Badge badgeContent={numberNotification} color="error">
              <div
                style={{
                  height: '21px',
                  width: '25px',
                  display: 'flex',
                  position: 'relative',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    height: '25px',
                    width: '25px',
                    overflow: 'hidden'
                  }}
                >
                  <img
                    style={{
                      width: '49px',
                      height: '1050px',
                      transform: `translate(${-5}px, ${
                        theme.palette.mode === 'light' ? -132 : -160
                      }px)`
                    }}
                    src={menuglobal}
                    alt={'menuglobal'}
                  ></img>
                </div>
              </div>
            </Badge>
          ) : (
            <div
              style={{
                height: '21px',
                width: '25px',
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  height: '25px',
                  width: '25px',
                  overflow: 'hidden'
                }}
              >
                <img
                  style={{
                    width: '49px',
                    height: '1050px',
                    transform: `translate(${-5}px, ${
                      theme.palette.mode === 'light' ? -132 : -160
                    }px)`
                  }}
                  src={menuglobal}
                  alt="menuglobal"
                ></img>
              </div>
            </div>
          )}
        </IconButton>

        <div
          className={classes.btnRightHeader}
          style={{
            borderRadius: '50%',
            position: 'relative',
            border: '1px solid rgba(0,0,0,0.1)'
          }}
          onClick={handleClickExpand}
        >
          <AvatarSocial
            type="feed"
            avatarObj={meInfo?.avatar_media ?? meInfo?.avatar_static}
            style={{ width: 40, height: 40 }}
          />
          <Box
            className={classes.wrapArrow}
            sx={{
              backgroundColor: 'button.primary.background'
            }}
          >
            <i style={{ fontSize: 12 }} className="fa-solid fa-angle-down"></i>
          </Box>
        </div>
      </div>

      {openNotification && (
        <NotificationHeader
          hasMore={hasMore}
          setOpen={setOpenNotification}
          notifications={notifications}
          oldNotis={oldNotis}
          newNotis={newNotis}
          fetchNotification={fetchNotification}
          handleClosePreview={handleClosePreview}
        />
      )}
    </>
  );
}
