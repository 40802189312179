import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import {
  fetchAccountRelationship,
  fetchFamilyRelationship
} from 'src/apis/socialUser';
import logoEmso from 'src/assets/images/LogoEmso.svg';
import noti from 'src/assets/images/Noti.svg';
import avatarDefault from 'src/assets/images/avatar_default.jpg';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import DialogViolate from 'src/components/Dialog/DialogViolate';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import SocialCutImage from 'src/components/SocialCutImage/SocialCutImage';
import { PATHS } from 'src/constants/paths';
import { buttonColor } from 'src/constants/styles';
import { handleTimeShow } from 'src/helpers/string';
import { getGroupDetailReq } from 'src/store/action/socialGroupAction';
import { updateNotifyVerifyPageName } from 'src/store/action/socialPageAction';
const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    alignItems: 'flex-start !important',
    '&:hover': {
      borderRadius: '10px !important'
    }
  },
  text: {
    fontSize: '15px !important',
    fontWeight: '500 !important'
  },
  subText: {
    color: '#65676b',
    fontSize: '13px !important'
  },
  title: {
    paddingBottom: 0
  },
  content: {
    paddingTop: 0
  },
  itemText: {
    marginTop: 0
  },
  lineClamp: {
    display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden'
  },
  textContentNoti: {
    fontWeight: '400 !important'
  }
}));

interface Props {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>> | any;
  item?: any;
  type?: String;
  openDialogViolate?: boolean;
  setOpenDialogViolate?: React.Dispatch<React.SetStateAction<boolean>>;
  handleClosePreview?: any;
}

const CommonNoti = (props: Props) => {
  const {
    setOpen,
    item,
    type,
    openDialogViolate,
    setOpenDialogViolate,
    handleClosePreview
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [familyRelaionship, setFamilyRelaionship] =
    React.useState<any>('pending');
  const [isLoadingAccept, setIsLoadingAccept] = React.useState<any>(false);
  const [isLoadingReject, setIsLoadingReject] = React.useState<any>(false);
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const accountSettings =
    useSelector((state: any) => state.userReducer?.accountSetting) || {};
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const convertText = (data: any, status_tags: any) => {
    if (!status_tags) return data;
    if (!data) return '';
    else {
      return reactStringReplace(data, /(\[\d+\])/gm, (match, i, offset) => {
        let objectMentions = status_tags?.find(
          el => el.entity_id === match.slice(1, match.length - 1)
        );
        return objectMentions?.name;
      }).join('');
    }
  };

  const renderActionText = (post: any, content: string) => {
    if (post?.post_type === 'event_shared') {
      return 'đã tạo một sự kiện mới. ';
    } else if (post?.post_type === 'moment') {
      return 'đã thêm một video vào Khoảnh khắc';
    } else if (post?.post_type === 'watch') {
      return 'đã thêm một video vào Watch';
    } else if (post?.post_type === 'question') {
      return 'đã đặt một câu hỏi';
    } else if (post?.post_type === 'target') {
      return 'đã đặt một mục tiêu';
    } else if (post?.target_account_id) {
      return `đã ${
        post?.reblog_of_id ? ' chia sẻ một liên kết ' : ' đăng '
      } trên dòng thời gian của bạn ${content ? ':' : '.'} `;
    } else if (post?.course_id) {
      return `đã tạo một bài viết mới trong khoá học với nội dung: `;
    } else return `đã tạo một bài viết mới ${content ? ':' : '.'} `;
  };

  const renderDisplayNameObj = (data: any) => {
    if (data?.status?.page) return data.status.page.title;
    else if (data?.status?.group) return data.status.group.title;
    else return data?.account?.display_name;
  };

  const renderTextBold = (textNone: string, textBold: any = null) => {
    return (
      <span>
        {textNone}
        <Typography
          className={classes.text}
          sx={{
            display: 'inline',
            color: !item?.read ? 'text.primary' : 'text.secondary'
          }}
        >
          {textBold}
        </Typography>
      </span>
    );
  };
  const renderNameInfo = item => {
    const infoPageFavourite = item?.favourite?.page;
    const infoPageStatus = item?.status?.page || item?.status?.page_owner;
    if (
      item?.type === 'favourite' &&
      item?.favourite?.status?.favourites_count > 1
    ) {
      return `${
        infoPageFavourite
          ? infoPageFavourite.title
          : item?.account?.display_name
      } và ${item?.favourite?.status?.favourites_count - 1} người khác`;
    } else if (item?.type === 'reblog' && item?.status?.reblogs_count > 1) {
      return `${
        infoPageStatus ? infoPageStatus?.title : item?.account?.display_name
      } và ${item?.status?.reblogs_count - 1} người khác`;
    } else {
      return infoPageFavourite
        ? infoPageFavourite?.title
        : infoPageStatus
        ? infoPageStatus?.title
        : item?.account?.display_name;
    }
  };
  const renderPrimary = item => {
    let text: any = '';
    let user: string = renderNameInfo(item);

    let notiContent = '';
    if (item?.status?.content && item?.status?.page) {
      notiContent = convertText(
        item?.status?.content,
        item?.status?.status_tags
      );
    } else {
      notiContent = item?.status?.page
        ? item?.status?.page?.title
        : item?.status?.content;
    }
    if (!item.status?.content?.length && !item.status?.page) {
      if (
        item?.type === 'favourite' &&
        item?.favourite?.status?.favourites_count > 1
      ) {
        notiContent = `${item?.account?.display_name} và ${item?.favourite?.status?.favourites_count} người khác`;
      } else {
        notiContent = item?.status?.account?.display_name;
      }
    }

    if (item?.type === 'favourite') {
      text =
        `đã bày tỏ cảm xúc về ${
          item?.favourite?.status?.in_reply_to_parent_id ||
          item?.favourite?.status?.in_reply_to_id
            ? 'bình luận'
            : 'bài viết'
        } ${
          !item?.favourite?.status?.page_owner &&
          item?.favourite?.status?.account?.id === meInfo?.id
            ? `của bạn ${item?.favourite?.status?.content ? ': ' : ''}`
            : !item?.favourite?.status?.content && item?.favourite?.status?.page
            ? `của trang ${item?.favourite?.status?.content ? ': ' : ''}`
            : ''
        }` +
        convertText(
          item?.favourite?.status?.page?.title
            ? item?.favourite?.status?.page?.title
            : item?.favourite?.status?.content,
          item?.favourite?.status?.status_tags
        );
    } else if (item?.type === 'follow') {
      text = 'đã theo dõi bạn';
    } else if (item?.type === 'reblog') {
      text = 'đã chia sẻ bài viết của bạn';
    } else if (item?.type === 'mention') {
      text = 'đã nhắc đến bạn trong một bài viết';
    } else if (item?.type === 'poll') {
      text = 'đã bầu chọn trong cuộc thăm dò của bạn';
    } else if (item?.type === 'rejected_project') {
      user = '';
      text = (
        <Typography
          className={classes.text}
          sx={{
            display: 'inline',
            color: !item?.read ? 'text.primary' : 'text.secondary'
          }}
        >
          Dự án của bạn đã bị từ chối
        </Typography>
      );
    } else if (item?.type === 'approved_project') {
      user = '';
      text = (
        <Typography
          className={classes.text}
          sx={{
            display: 'inline',
            color: !item?.read ? 'text.primary' : 'text.secondary'
          }}
        >
          Dự án của bạn đã được phê duyệt
        </Typography>
      );
    } else if (item?.type === 'status') {
      if (item?.status?.reblog) {
        text = (
          <span>
            đã chia sẻ một bài viết
            {item?.status?.reblog?.group || item?.status?.reblog?.page
              ? ' trong'
              : ''}{' '}
            {item?.status?.reblog?.group || item?.status?.reblog?.page ? (
              <Typography
                className={classes.text}
                sx={{
                  display: 'inline',
                  color: !item?.read ? 'text.primary' : 'text.secondary'
                }}
              >
                {item?.status?.reblog?.group
                  ? item?.status?.reblog?.group?.title
                  : item?.status?.reblog?.page?.title}
              </Typography>
            ) : (
              ''
            )}
            {': '}
            {item?.status?.reblog?.content}
          </span>
        );
      } else if (item?.status?.shared_project) {
        text = (
          <span>
            đã chia sẻ dự án
            {item?.status?.group || item?.status?.page ? ' trong ' : ' '}
            {item?.status?.group || item?.status?.page ? (
              <Typography
                className={classes.text}
                sx={{
                  display: 'inline',
                  color: !item?.read ? 'text.primary' : 'text.secondary'
                }}
              >
                {item?.status?.group
                  ? item?.status?.group?.title
                  : item?.status?.page?.title}
              </Typography>
            ) : (
              <Typography
                className={classes.text}
                sx={{
                  display: 'inline',
                  color: !item?.read ? 'text.primary' : 'text.secondary'
                }}
              >
                {` ${item?.status?.shared_project.title} `}
              </Typography>
            )}
            {item?.status?.target_account_id === meInfo.id
              ? ' lên trang cá nhân của bạn'
              : null}
          </span>
        );
      } else if (item?.status?.shared_recruit) {
        text = (
          <span>
            đã chia sẻ một bài viết tuyển dụng
            {item?.status?.group
              ? ' trong nhóm '
              : item?.status?.page
              ? 'trong page'
              : ' '}
            {item?.status?.group || item?.status?.page ? (
              <Typography
                className={classes.text}
                sx={{
                  display: 'inline',
                  color: !item?.read ? 'text.primary' : 'text.secondary'
                }}
              >
                {item?.status?.group
                  ? item?.status?.group?.title
                  : item?.status?.page?.title}
              </Typography>
            ) : (
              <Typography
                className={classes.text}
                sx={{
                  display: 'inline',
                  color: !item?.read ? 'text.primary' : 'text.secondary'
                }}
              >
                {` ${item?.status?.shared_recruit?.title}`}
              </Typography>
            )}
            {item?.status?.target_account_id === meInfo.id
              ? ' lên trang cá nhân của bạn'
              : null}
          </span>
        );
      } else if (item?.status?.shared_course) {
        text = (
          <span>
            {'đã chia sẻ một khoá học '}
            {item?.status?.group || item?.status?.page ? (
              <Typography
                className={classes.text}
                sx={{
                  display: 'inline',
                  color: !item?.read ? 'text.primary' : 'text.secondary'
                }}
              >
                {item?.status?.group
                  ? item?.status?.group?.title
                  : item?.status?.page?.title}
              </Typography>
            ) : (
              <Typography
                className={classes.text}
                sx={{
                  display: 'inline',
                  color: !item?.read ? 'text.primary' : 'text.secondary'
                }}
              >
                {` ${item?.status?.shared_course?.title}`}
              </Typography>
            )}
            {item?.status?.target_account_id === meInfo.id
              ? ' lên trang cá nhân của bạn'
              : null}
          </span>
        );
      } else if (item?.status?.shared_event) {
        text = (
          <span>
            đã chia sẻ một sự kiện
            {item?.status?.group
              ? ' trong nhóm '
              : item?.status?.page
              ? 'trong page'
              : ' '}
            {item?.status?.group || item?.status?.page ? (
              <Typography
                className={classes.text}
                sx={{
                  display: 'inline',
                  color: !item?.read ? 'text.primary' : 'text.secondary'
                }}
              >
                {item?.status?.group
                  ? item?.status?.group?.title
                  : item?.status?.page?.title}
              </Typography>
            ) : (
              <Typography
                className={classes.text}
                sx={{
                  display: 'inline',
                  color: !item?.read ? 'text.primary' : 'text.secondary'
                }}
              >
                {` ${item?.status?.shared_event?.title}`}
              </Typography>
            )}
            {item?.status?.target_account_id === meInfo.id
              ? ' lên trang cá nhân của bạn'
              : null}
          </span>
        );
      } else {
        if (item?.status?.page_owner) {
          user = item?.status?.page_owner?.title;

          text =
            item?.status?.post_type === 'event_shared'
              ? 'đã tạo một sự kiện '
              : 'đã có một bài viết mới: ' + item?.status?.content;
        } else if (item?.status?.group || item?.status?.page) {
          if (
            item.account?.relationships?.friendship_status === 'ARE_FRIENDS'
          ) {
            user = item?.account?.display_name;
            text = (
              <span>
                {item?.status?.post_type === 'event_shared'
                  ? 'đã tạo sự kiện trong'
                  : 'đã tạo bài viết trong'}{' '}
                {item?.status?.group ? 'nhóm' : 'trang'}{' '}
                <span
                  className={classes.text}
                  style={{
                    color: !item?.read ? 'text.primary' : 'text.secondary'
                  }}
                >
                  {item?.status?.group
                    ? item?.status?.group?.title
                    : item?.status?.page?.title}
                </span>
                <span>: {item?.status?.content}</span>
              </span>
            );
          } else {
            user = item?.status?.group
              ? item?.status?.group?.title
              : item?.status?.page?.title;
            text =
              item?.status?.post_type === 'event_shared'
                ? 'có sự kiện mới: '
                : 'có bài viết mới: ' + item?.status?.content;
          }
        } else if (item?.status?.event) {
          text = renderTextBold('đã đăng trong:', item.status.event?.title);
        } else if (item?.status?.course) {
          text = renderTextBold(
            'đã đăng trong khóa học: ',
            item.status.course?.title
          );
        } else {
          text =
            renderActionText(item?.status, item?.status?.content) +
            (item?.status?.content
              ? convertText(item?.status?.content, item?.status?.status_tags)
              : '');
        }
      }
    } else if (item?.type === 'project_donate') {
      text = (
        <span>
          {` đã ủng hộ dự án: `}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.project?.title}
          </Typography>
        </span>
      );
    } else if (item?.type === 'music_invitation_author') {
      text = (
        <span>
          {` đã mời bạn làm tác giả bài hát: `}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.music?.name}
          </Typography>
        </span>
      );
    } else if (item?.type === 'accept_course_invitation_host') {
      text = (
        <span>
          {` đã chấp nhận làm giáo viên: `}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.course?.title}
          </Typography>
        </span>
      );
    } else if (item?.type === 'course_invitation_host') {
      text = (
        <span>
          {` đã mời bạn làm giáo viên khoá học: `}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.course?.title}
          </Typography>
        </span>
      );
    } else if (item?.type === 'project_invitation_host') {
      text = (
        <span>
          {` đã mời bạn làm người đồng tổ chức dự án: `}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.project?.title}
          </Typography>
        </span>
      );
    } else if (item?.type === 'project_invitation') {
      text = (
        <span>
          {` đã mời bạn quan tâm dự án: `}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.project?.title}
          </Typography>
        </span>
      );
    } else if (item?.type === 'accept_project_invitation_host') {
      text = (
        <span>
          {` đã xác nhận làm đồng tổ chức cho dự án:  `}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.project?.title}
          </Typography>
        </span>
      );
    } else if (item?.type === 'accept_project_invitation') {
      text = (
        <span>
          {` đã xác nhận quan tâm dự án: `}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.project?.title}
          </Typography>
        </span>
      );
    } else if (item?.type === 'cancel_project') {
      text = (
        <span>
          {` đã hủy dự án: `}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.project?.title}
          </Typography>
        </span>
      );
    } else if (item?.type === 'friendship_request') {
      text = 'đã gửi cho bạn lời mời kết bạn.';
    } else if (
      item?.type === 'status_donate' ||
      item?.type === 'music_donate' ||
      item?.type === 'donate'
    ) {
      text = 'đã ủng hộ cho bạn.';
    } else if (item?.type === 'event_invitation') {
      text = 'đã gửi cho bạn lời mời tham gia sự kiện.';
    } else if (item?.type === 'event_invitation_host') {
      user = item?.event?.page
        ? item?.event?.page?.title
        : item?.account?.display_name;
      text = renderTextBold(
        'đã mời bạn đồng tổ chức sự kiện: ',
        item?.event?.title
      );
    } else if (item?.type === 'course_invitation') {
      text = renderTextBold(
        'đã mời bạn tham gia khóa học ',
        item?.course?.title
      );
    } else if (item?.type === 'rejected_course') {
      user = '';
      text = (
        <Typography
          className={classes.text}
          sx={{
            display: 'inline',
            color: !item?.read ? 'text.primary' : 'text.secondary'
          }}
        >
          Khoá học của bạn đã bị từ chối
        </Typography>
      );
    } else if (item?.type === 'approved_course') {
      user = '';
      text = (
        <Typography
          className={classes.text}
          sx={{
            display: 'inline',
            color: !item?.read ? 'text.primary' : 'text.secondary'
          }}
        >
          Khoá học của bạn đã được phê duyệt
        </Typography>
      );
    } else if (item?.type === 'product_invitation') {
      text = renderTextBold(
        'đã mời bạn quan tâm đến sản phẩm ',
        item?.product?.title
      );
    } else if (item?.type === 'page_follow') {
      text = renderTextBold('đã thích trang ', item?.page?.title);
    } else if (item?.type === 'admin_page_invitation') {
      text = renderTextBold('đã mời bạn làm quản trị viên ', item?.page?.title);
    } else if (item?.type === 'moderator_page_invitation') {
      text = renderTextBold(
        'đã mời bạn làm kiểm duyệt viên ',
        item?.page?.title
      );
    } else if (item?.type === 'page_invitation_follow') {
      text = renderTextBold('đã mời bạn thích trang ', item?.page?.title);
    } else if (item?.type === 'group_invitation') {
      text = renderTextBold('đã mời bạn tham gia nhóm ', item?.group?.title);
    } else if (item?.type === 'group_invitation_host') {
      text = renderTextBold(
        'đã mời bạn làm quản trị viên của nhóm ',
        item?.group?.title
      );
    } else if (item?.type === 'moderator_group_invitation_host') {
      text = renderTextBold(
        'đã mời bạn làm người kiểm duyệt của nhóm ',
        item?.group?.title
      );
    } else if (item?.type === 'accept_moderator_group_invitation_host') {
      text = renderTextBold(
        'đã đồng làm người kiểm duyệt của nhóm ',
        item?.event?.title
      );
    } else if (item?.type === 'accept_event_invitation') {
      text = renderTextBold(
        'đã trả lời là Sẽ tham gia sự kiện ',
        item?.event?.title
      );
    } else if (item?.type === 'accept_event_invitation_host') {
      text = renderTextBold(
        'đã đồng ý đồng tổ chức sự kiện ',
        item?.event?.title
      );
    } else if (item?.type === 'accept_follow_event_invitation') {
      text = renderTextBold(
        'đã trả lời là Quan tâm sự kiện ',
        item?.event.title
      );
    } else if (item?.type === 'recruit_invitation') {
      text = renderTextBold(
        'đã mời bạn tham gia tuyển dụng ',
        item?.recruit?.title
      );
    } else if (item?.type === 'recruit_apply') {
      text = renderTextBold('đã nộp CV tuyển dụng ', item?.recruit?.title);
    } else if (item?.type === 'accept_admin_page_invitation') {
      text = renderTextBold(
        'đã đồng ý làm quản trị viên trang ',
        item?.page?.title
      );
    } else if (item?.type === 'accept_moderator_page_invitation') {
      text = renderTextBold(
        'đã đồng ý làm kiểm duyệt viên trang ',
        item?.page?.title
      );
    } else if (item?.type === 'accept_page_invitation_follow') {
      text = renderTextBold(
        'đã chấp nhận lời mời thích trang ',
        item?.page?.title
      );
    } else if (item?.type === 'approved_page_identity_verification') {
      user = `Trang ${item?.page_identity_verification?.page?.title}`;
      text = ` của bạn đã được xác minh thành công.`;
    } else if (item?.type === 'rejected_page_identity_verification') {
      user = `Trang ${item?.page_identity_verification?.page?.title}`;
      text = ` của bạn xác minh thất bại.`;
    } else if (item?.type === 'approved_identity_verification') {
      user = '';
      text = 'Tài khoản của bạn xác minh thành công.';
    } else if (item?.type === 'rejected_identity_verification') {
      user = '';
      text = 'Tài khoản bị từ chối xác minh.Vui lòng thử lại sau!';
    } else if (item?.type === 'rejected_earn_money' && item?.earn_money?.page) {
      user = `Trang ${item?.earn_money?.page?.title}`;
      text = ' của bạn bật kiếm tiền thất bại.Vui lòng thử lại sau!';
    } else if (item?.type === 'approved_earn_money' && item?.earn_money?.page) {
      user = `Trang ${item?.earn_money?.page?.title}`;
      text = ' của bạn bật kiếm tiền thành công.';
    } else if (item?.type === 'approved_earn_money') {
      user = '';
      text = 'Tài khoản của bạn bật kiếm tiền thành công.';
    } else if (item?.type === 'rejected_earn_money') {
      user = '';
      text = 'Tài khoản của bạn bật kiếm tiền thất bại.Vui lòng thử lại sau!';
    } else if (
      item?.type === 'donate' ||
      item?.type === 'music_donate' ||
      item?.type === 'status_donate'
    ) {
      user = '';
      text = 'đã donate cho bạn.';
    } else if (item?.type === 'accept_group_invitation') {
      text = renderTextBold(
        'đã chấp nhận lời mời tham gia nhóm',
        item?.group?.title
      );
    } else if (item?.type === 'accept_friendship_request') {
      text = 'đã chấp nhận lời mời kết bạn.';
    } else if (item?.type === 'group_join_request') {
      text = renderTextBold('đã yêu cầu tham gia ', item?.group?.title);
    } else if (item?.type === 'approved_group_join_request') {
      user = '';
      text = (
        <span>
          Chào mừng bạn đến với{' '}
          <span
            className={classes.text}
            style={{
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.group?.title}
          </span>
          <span>.Quản trị viên đã phê duyệt yêu cầu tham gia của bạn.</span>
        </span>
      );
    } else if (item?.type === 'created_status') {
      text = renderTextBold(
        'Video của bạn đã sẵn sàng.Bây giờ bạn có thể mở xem.'
      );
      user = '';
    } else if (item?.type === 'status_tag') {
      user = '';
      if (item?.status?.in_reply_to_parent_id || item?.status?.in_reply_to_id) {
        text = renderTextBold(
          'Bạn đã được nhắc đến trong một bình luận của ',
          item?.status?.page_owner
            ? item?.status?.page_owner?.title
            : item?.account?.display_name
        );
      } else {
        text = renderTextBold(
          `Bạn đã được gắn thẻ trong một bài viết của ${
            item?.status?.page ? 'trang' : item?.status?.group ? 'nhóm' : ''
          }`,
          renderDisplayNameObj(item)
        );
      }
    } else if (item?.type === 'family_member') {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.account.display_name}
          </Typography>
          &nbsp;đã liệt kê bạn là&nbsp;
          {item?.family_member?.family_relationship_category?.name}.
        </span>
      );
    } else if (item?.type === 'account_relationship') {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.account.display_name}
          </Typography>
          &nbsp;đã liệt kê rằng hai bạn đang&nbsp;
          {item?.account_relationship?.relationship_category?.name}.
        </span>
      );
    } else if (item?.type === 'approved_group_status') {
      user = '';
      if (item?.group || item?.page) {
        text = (
          <>
            Bài viết của bạn tại {item?.group ? 'nhóm' : 'trang'}:
            <Typography
              className={classes.text}
              sx={{
                display: 'inline',
                color: !item?.read ? 'text.primary' : 'text.secondary'
              }}
            >
              {' '}
              {item?.group?.title ?? item?.page?.title}
            </Typography>{' '}
            đã được quản trị viên phê duyệt
          </>
        );
      }
    } else if (item?.type === 'group_status_alert') {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.status.page_owner
              ? item.status.page_owner.title
              : item.account.display_name}
          </Typography>{' '}
          đã bình luận nội dung chứa từ khoá bị cấm cần được kiểm duyệt:{' '}
          {convertText(item?.status?.content, item?.status?.status_tags)}
        </span>
      );
    } else if (item?.type === 'page_status_alert') {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.account.display_name}
          </Typography>{' '}
          đã bình luận nội dung chứa từ khoá bị cấm cần được kiểm duyệt:{' '}
          {convertText(item?.status?.content, item?.status?.status_tags)}
        </span>
      );
    } else if (item?.type === 'comment' && item?.status?.page_owner) {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.status?.page_owner?.title}
          </Typography>{' '}
          đã bình luận bài viết{' '}
          {item?.status?.group
            ? 'trong nhóm: '
            : item?.status?.group
            ? 'trong trang: '
            : ''}
          <Typography
            className={classes.text}
            sx={{
              display: 'contents',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.status?.group
              ? item?.status?.group?.title
              : item?.status?.page?.title}
          </Typography>
          {' với nội dung: '}
          {convertText(item?.status?.content, item?.status?.status_tags)}
        </span>
      );
    } else if (item?.type === 'comment' && !item?.status?.page_owner) {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.account.display_name}
          </Typography>{' '}
          đã bình luận bài viết{' '}
          {item?.status?.group
            ? 'trong nhóm: '
            : item?.status?.group
            ? 'trong trang: '
            : ''}
          <Typography
            className={classes.text}
            sx={{
              display: 'contents',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.status?.group
              ? item?.status?.group?.title
              : item?.status?.page?.title}
          </Typography>
          {' với nội dung: '}
          {convertText(item?.status?.content, item?.status?.status_tags)}
        </span>
      );
    } else if (item?.type === 'group_pending_status') {
      user = '';
      text = (
        <span>
          Bài viết của{' '}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.account.display_name}
          </Typography>{' '}
          trong{' '}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.status?.group?.title}
          </Typography>{' '}
          cần được phê duyệt: {item?.status?.content}
        </span>
      );
    } else if (item.type === 'rejected_group_status') {
      user = '';
      text = (
        <span>
          Bài viết của bạn trong nhóm{' '}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.group.title}
          </Typography>{' '}
          đã bị quản trị viên từ chối, vui lòng đăng những nội dung phù hợp hơn.
        </span>
      );
    } else if (item?.type === 'group_reported_status') {
      user = '';
      text = (
        <span>
          <span style={{ fontWeight: '500' }}>
            {item.account.display_name + ' '}
          </span>
          <span
            style={{
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            <span className={classes.textContentNoti}>
              đã báo cáo vi phạm bài viết trong{' '}
            </span>
            <span style={{ fontWeight: '500' }}>
              {item?.status?.group?.title}
            </span>
          </span>
        </span>
      );
    } else if (item?.type === 'accept_group_invitation_host') {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.account.display_name}
          </Typography>{' '}
          đã đồng ý làm quản trị viên của nhóm{' '}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.group?.title}
          </Typography>{' '}
        </span>
      );
    } else if (item.type === 'accept_moderator_group_invitation_host') {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.account.display_name}
          </Typography>{' '}
          đã đồng ý làm người kiểm duyệt của nhóm{' '}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.group?.title}
          </Typography>{' '}
        </span>
      );
    } else if (item?.type === 'approved_identity_verification') {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            Tài khoản của bạn xác minh thành công.
          </Typography>{' '}
        </span>
      );
    } else if (item?.type === 'rejected_identity_verification') {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            Tài khoản của bạn bị từ chối xác minh. Vui lòng thử lại sau!
          </Typography>{' '}
        </span>
      );
    } else if (item?.type === 'order') {
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            đã tạo một đơn hàng mới
          </Typography>{' '}
        </span>
      );
    }

    return (
      <span className={classes.lineClamp}>
        <Typography
          className={classes.text}
          sx={{
            display: 'inline-block',
            color: !item?.read ? 'text.primary' : 'text.secondary'
          }}
        >
          {user}
        </Typography>
        {user ? ' ' : null}
        <span style={{ fontSize: 14 }}>{text}</span>
      </span>
    );
  };
  const renderIdOrNameObj = (item: any) => {
    if (item?.page_owner) return item.page_owner.username ?? item.page_owner.id;
    else if (item?.page) return item.page.username ?? item.page.id;
    else if (item?.group) return item.group.username ?? item.group.id;
    else return item?.account.username ?? item?.account.id;
  };

  const renderLink = item => {
    if (!item) return '/';
    if (
      ['favourite', 'status_tag', 'created_status', 'reblog'].includes(
        item?.type
      ) ||
      (item?.type === 'status' &&
        !item?.status?.group &&
        !item?.status?.page &&
        !item?.status?.event &&
        !item?.status?.course)
    ) {
      if (item?.status?.course_id) {
        return `/course/${item?.status?.course_id}/discussion/${item?.status?.id}`;
      } else if (item?.favourite) {
        if (
          item?.favourite?.status?.in_reply_to_parent_id ||
          item?.favourite?.status?.in_reply_to_id
        ) {
          return `/posts/${
            item?.favourite?.status?.in_reply_to_parent_id
              ? item?.favourite?.status?.in_reply_to_parent_id
              : item?.favourite?.status?.in_reply_to_id
              ? item?.favourite?.status?.in_reply_to_id
              : item?.favourite?.status?.id
          }`;
        } else {
          return `/${renderIdOrNameObj(item?.favourite?.status)}/posts/${
            item?.favourite?.status?.in_reply_to_parent_id
              ? item?.favourite?.status?.in_reply_to_parent_id
              : item?.favourite?.status?.in_reply_to_id
              ? item?.favourite?.status?.in_reply_to_id
              : item?.favourite?.status?.id
          }`;
        }
      } else if (
        item?.type === 'status_tag' &&
        !(item?.status?.in_reply_to_parent_id || item?.status?.in_reply_to_id)
      ) {
        return '/diary/tags';
      } else if (
        item?.type === 'status_tag' &&
        (item?.status?.in_reply_to_parent_id || item?.status?.in_reply_to_id)
      ) {
        return `/posts/${
          item?.status?.in_reply_to_parent_id
            ? item?.status?.in_reply_to_parent_id
            : item?.status?.in_reply_to_id
            ? item?.status?.in_reply_to_id
            : item?.status?.id
        }?commentId=${item?.status.id}`;
      } else {
        return `/${renderIdOrNameObj(item?.status)}/posts/${
          item?.status?.in_reply_to_parent_id
            ? item?.status?.in_reply_to_parent_id
            : item?.status?.in_reply_to_id
            ? item?.status?.in_reply_to_id
            : item?.status?.id
        }`;
      }
    } else if (
      item?.project &&
      [
        'approved_project',
        'project_donate',
        'project_invitation_host',
        'project_invitation',
        'accept_project_invitation',
        'accept_project_invitation_host'
      ].includes(item?.type)
    ) {
      return `/grow/${item.project.id}/about`;
    } else if (item?.type === 'cancel_project') {
      return '/grows/home';
    } else if (item?.course && item?.type === 'course_invitation_host') {
      return `/course/${item.course.id}/about`;
    } else if (item?.type === 'music_invitation_author') {
      return `/music_space/invites`;
    } else if (item?.status?.event && item?.type === 'status') {
      return `/event/${item.status.event?.id}`;
    } else if (item?.status?.group && item?.type === 'status') {
      return `/${
        item?.status?.group?.username ?? item?.status?.group?.id
      }/posts/${item?.status?.id}`;
    } else if (item?.status?.page && item?.type === 'status') {
      return `/${
        item?.status?.page?.username ?? item?.status?.page?.id
      }/posts/${item?.status?.id}`;
    } else if (item?.status?.event && item?.type === 'status') {
      return `/${
        item?.status?.event?.username ?? item?.status?.event?.id
      }/posts/${item?.status?.id}`;
    } else if (item?.status?.course && item?.type === 'status') {
      return `/course/${item?.status?.course?.id}`;
    } else if (item?.type === 'friendship_request') {
      return `/friends/requests/${item?.account?.id}`;
    } else if (
      ['course_invitation', 'rejected_course', 'approved_course'].includes(
        item?.type
      )
    ) {
      return `/course/${item?.course?.id}/about`;
    } else if (item?.type === 'product_invitation') {
      return `/product/${item?.product?.id}/about`;
    } else if (item?.type === 'mention') {
      if (accountSettings?.review_tag_on_profile) {
        return `/diary/tags`;
      } else {
        return `/${
          item?.status?.account?.username ?? item?.status?.account?.id
        }/posts/${item?.status?.id}`;
      }
    } else if (
      [
        'event_invitation',
        'event_invitation_host',
        'accept_event_invitation',
        'accept_event_invitation_host',
        'accept_follow_event_invitation'
      ].includes(item?.type)
    ) {
      return `/event/${item?.event?.id}`;
    } else if (
      [
        'page_follow',
        'accept_admin_page_invitation',
        'accept_moderator_page_invitation',
        'accept_page_invitation_follow'
      ].includes(item?.type)
    ) {
      return `/page/${item?.page?.username ?? item?.page?.id}`;
    } else if (
      [
        'rejected_project',
        'rejected_page_identity_verification',
        'rejected_identity_verification'
      ].includes(item?.type)
    ) {
      return `/grows/not/approve/${item.id}`;
    } else if (
      [
        'page_invitation_follow',
        'admin_page_invitation',
        'moderator_page_invitation'
      ].includes(item?.type)
    ) {
      return `/pages/invites`;
    } else if (
      [
        'group_invitation',
        'group_invitation_host',
        'accept_group_invitation',
        'approved_group_join_request',
        'rejected_group_status'
      ].includes(item?.type)
    ) {
      return `/group/${item?.group?.username ?? item?.group?.id}`;
    } else if (item?.type === 'group_join_request') {
      return `/group/${
        item?.group?.username ?? item?.group?.id
      }/request_member`;
    } else if (['recruit_invitation', 'recruit_apply'].includes(item?.type)) {
      return `/recruit/${item?.recruit?.id}/about`;
    } else if (
      item?.type === 'accept_friendship_request' ||
      item?.type === 'family_member'
    ) {
      return `/user/${item?.account?.username ?? item?.account?.id}`;
    } else if (item?.type === 'group_status_alert' && item?.status?.group.id) {
      return `/group/${item?.status?.group.id}/report_censorship`;
    } else if (item?.type === 'page_status_alert' && item?.status?.page.id) {
      return `/page/${item?.status?.page.id}/notifications/moderation_activity_log`;
    } else if (item?.type === 'comment') {
      return `/posts/${
        item?.status?.in_reply_to_parent_id ?? item?.status?.in_reply_to_id
      }`;
    } else if (item?.type === 'approved_group_status') {
      return `/group/${item?.group?.id}`;
    } else if (item?.status?.in_reply_to_id) {
      return `/posts/${
        item?.status?.in_reply_to_parent_id ?? item?.status?.in_reply_to_id
      }`;
    } else if (item?.type === 'group_pending_status') {
      return `/group/${item.status.group.id}/waiting_post`;
    } else if (item?.type === 'group_reported_status') {
      return `/group/${item.status.group.id}/content_report`;
    } else if (item?.type === 'moderator_group_invitation_host') {
      return `/group/${item.group.id}`;
    } else if (
      [
        'approved_earn_money',
        'rejected_earn_money',
        'approved_identity_verification',
        'rejected_page_identity_verification',
        'approved_page_identity_verification'
      ].includes(item?.type)
    ) {
      if (
        [
          'rejected_page_identity_verification',
          'approved_page_identity_verification'
        ].includes(item?.type)
      ) {
        dispatch(
          updateNotifyVerifyPageName(
            item?.page_identity_verification.page.title
          )
        );
      }
      return '/settings/money';
    } else if (
      ['status_donate', 'music_donate', 'donate'].includes(item?.type)
    ) {
      return '/emso_payment/home';
    } else if (item?.type === 'accept_group_invitation_host') {
      return `/group/${item.group.id}`;
    } else if (item?.type === 'account_relationship') {
      return `/user/${
        item?.account_relationship?.partner?.username ??
        item?.account_relationship?.partner?.id
      }`;
    } else if (item?.type === 'order') {
      return `/marketplace/shop/product_order/${item?.order_id}`;
    } else {
      return '/';
    }
  };

  const renderImage = item => {
    switch (item?.type) {
      case 'follow':
      case 'reblog':
      case 'favourite':
      case 'poll':
      case 'project_invitation_host':
      case 'project_donate':
      case 'accept_project_invitation':
      case 'project_invitation':
      case 'approved_project':
      case 'rejected_project':
      case 'product_invitation':
      case 'rejected_course':
      case 'approved_course':
      case 'rejected_group_status':
        return { positionY: -461 };
      case 'status':
        if (item?.status?.group) return { positionY: -337 };
        else if (item?.status?.page) return { positionY: -420 };
        else if (item?.status?.post_type === 'event_shared')
          return { positionY: -127 };
        else if (item?.status?.post_type === 'watch')
          return { positionY: -502 };
        else if (item?.status?.post_type === 'moment')
          return { positionY: -251 };
        return { positionY: -291 };
      case 'friendship_request':
      case 'accept_friendship_request':
      case 'family_member':
      case 'mention':
      case 'account_relationship':
        return { positionY: -542 };
      case 'event_invitation':
      case 'event_invitation_host':
      case 'accept_event_invitation':
      case 'accept_event_invitation_host':
      case 'accept_follow_event_invitation':
        return { positionY: -127 };
      case 'page_follow':
      case 'admin_page_invitation':
      case 'moderator_page_invitation':
      case 'page_invitation_follow':
      case 'accept_admin_page_invitation':
      case 'accept_moderator_page_invitation':
      case 'accept_page_invitation_follow':
      case 'page_status_alert':
        return { positionY: -420 };
      case 'group_invitation':
      case 'group_invitation_host':
      case 'group_join_request':
      case 'accept_group_invitation':
      case 'approved_group_join_request':
      case 'group_pending_status':
      case 'group_reported_status':
      case 'group_status_alert':
        return { positionY: -337 };
      case 'comment':
        return { positionY: -168 };
      case 'status_tag':
        return { positionY: -291 };
      case 'created_status':
        return { positionY: -502 };
      case 'music_invitation_author':
        return { positionY: -625 };
      case 'order':
        return { positionY: -587 };
      default:
        break;
    }
  };
  const renderAvatarObj = (data: any) => {
    if (data?.type === 'created_status') {
      return data.account.avatar_media
        ? data.account.avatar_media.preview_url
        : data.account.avatar_static;
    } else if (!data?.status?.page_owner) {
      return data?.account?.avatar_media?.preview_url;
    } else if (data?.status?.page_owner) {
      return data.status.page_owner?.avatar_media
        ? data.status.page_owner?.avatar_media?.preview_url
        : avatarDefault;
    } else if (data?.type === 'favourite' && data?.favourite?.page) {
      return data?.favourite?.page?.avatar_media?.preview_url ?? avatarDefault;
    } else if (data?.status?.page) {
      return data.status.page.avatar_media;
    } else if (data?.status?.group) {
      if (data?.type === 'group_pending_status') {
        return data.account.avatar_media;
      } else {
        return data.status.group.avatar_media;
      }
    } else if (data?.event?.page?.avatar_media) {
      return data.item.event.page.avatar_media;
    } else if (
      data?.group?.banner &&
      ['approved_group_status', 'rejected_group_status'].includes(data?.type)
    ) {
      return data?.group?.banner;
    } else if (
      data?.type === 'approved_page_identity_verification' ||
      data?.type === 'rejected_page_identity_verification'
    ) {
      return logoEmso;
    } else if (
      [
        'approved_identity_verification',
        'rejected_identity_verification',
        'rejected_earn_money',
        'approved_earn_money',
        'rejected_course',
        'approved_course'
      ].includes(data?.type)
    ) {
      return logoEmso;
    } else {
      return data?.account?.avatar_media;
    }
  };

  const handleAction = async type => {
    let response;
    const checkCallApi = () => {
      if (item?.type === 'account_relationship') {
        return fetchAccountRelationship(item?.account_relationship?.id, {
          status: type
        });
      } else {
        return fetchFamilyRelationship(item?.family_member?.id, {
          status: type
        });
      }
    };
    response = await checkCallApi();
    if (response.status === 200) {
      if (type === 'approved') {
        setIsLoadingAccept(false);
      } else if (type === 'rejected') {
        setIsLoadingReject(false);
      }
      setFamilyRelaionship(response.data.status);
    }
  };
  const renderButton = () => {
    return (
      <>
        {familyRelaionship === 'pending' ? (
          <Box sx={{ position: 'absolute', bottom: '7px', left: '84px' }}>
            <ButtonInherit
              style={{
                height: '30px',
                width: '100px'
              }}
              color={buttonColor.backgroundColor}
              label="Đồng ý"
              action={() => handleAction('approved')}
              loading={isLoadingAccept}
            />
            <ButtonInherit
              style={{
                height: '30px',
                width: '100px'
              }}
              label="Từ chối"
              action={() => handleAction('rejected')}
              loading={isLoadingReject}
            />
          </Box>
        ) : familyRelaionship === 'approved' ? (
          <Box sx={{ position: 'absolute', bottom: '18px', left: '84px' }}>
            <Typography sx={{ fontSize: '14px', color: 'text.primary' }}>
              Đã chấp nhận lời mời
            </Typography>
          </Box>
        ) : (
          <Box sx={{ position: 'absolute', bottom: '18px', left: '84px' }}>
            <Typography sx={{ fontSize: '14px', color: 'text.primary' }}>
              Đã từ chối lời mời
            </Typography>
          </Box>
        )}
      </>
    );
  };
  const renderImgAvatar = item => {
    if (item.status?.page_owner) {
      return (
        item.status?.page_owner?.avatar_media?.preview_url ?? avatarDefault
      );
    } else if (item?.type === 'favourite' && item?.favourite?.page) {
      return item?.favourite?.page?.avatar_media?.preview_url ?? avatarDefault;
    } else if (
      ['rejected_group_status', 'approved_group_status'].includes(item?.type)
    ) {
      return (
        item?.group?.banner?.preview_url ?? item?.group?.banner?.preview_url
      );
    } else if (item.account.avatar_media) {
      return item.account.avatar_media.preview_url;
    } else if (item.account.avatar_static) {
      return item.account.avatar_static;
    }
  };

  const renderBlock = item => {
    return (
      <Box sx={{ position: 'relative' }}>
        <NavLink
          to={renderLink(item)}
          style={{ textDecoration: 'none', width: '100%' }}
          onClick={() => {
            if (
              item?.type === 'approved_group_join_request' &&
              match.path === PATHS.GROUP_DETAIL
            ) {
              dispatch(getGroupDetailReq(item.group.id, ''));
            }
          }}
        >
          <ListItem
            className={classes.listItem}
            button
            onClick={() => {
              handleClosePreview && handleClosePreview();
              setOpen(false);
            }}
            sx={
              item?.type === 'family_member' ||
              item?.type === 'account_relationship'
                ? { padding: '6px 6px 35px 6px !important' }
                : { padding: '6px !important' }
            }
          >
            <ListItemAvatar
              sx={
                type === 'notiSoket'
                  ? { position: 'relative', marginTop: 1 }
                  : { position: 'relative' }
              }
            >
              <AvatarSocial
                type="feed"
                style={{ width: 60, height: 60, marginRight: 8 }}
                src={
                  [
                    'approved_identity_verification',
                    'rejected_identity_verification',
                    'approved_page_identity_verification',
                    'rejected_page_identity_verification',
                    'approved_project',
                    'rejected_project',
                    'approved_earn_money',
                    'rejected_earn_money',
                    'rejected_course',
                    'approved_course'
                  ].includes(item.type)
                    ? logoEmso
                    : renderImgAvatar(item)
                }
                avatarObj={renderAvatarObj(item)}
              />
              <SocialCutImage
                image={noti}
                item={renderImage(item)}
                style={{ position: 'absolute', top: 52, right: 2 }}
                zoom={0.68}
              />
            </ListItemAvatar>
            <ListItemText
              style={
                !item?.read
                  ? { marginTop: 0, marginLeft: 10 }
                  : {
                      color: '#65676B',
                      marginTop: 0,
                      marginLeft: 10
                    }
              }
              primaryTypographyProps={{
                style: {
                  color: theme.palette.mode === 'dark' ? '#fff' : '#050505'
                }
              }}
              primary={renderPrimary(item)}
              secondary={
                <>
                  <Typography
                    className={classes.subText}
                    style={
                      !item?.read
                        ? {
                            fontWeight: 500,
                            color: buttonColor.backgroundColor
                          }
                        : { color: '#65676B' }
                    }
                  >
                    {handleTimeShow(item.created_at)}
                  </Typography>
                </>
              }
            />
            {!item?.read ? (
              <ListItemIcon
                style={{
                  paddingTop: 35,
                  paddingRight: 12,
                  minWidth: 5,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    backgroundColor: buttonColor.backgroundColor,
                    width: 12,
                    height: 12,
                    borderRadius: 6
                  }}
                ></div>
              </ListItemIcon>
            ) : null}
          </ListItem>
        </NavLink>
        {item?.type === 'family_member' || item?.type === 'account_relationship'
          ? renderButton()
          : null}
      </Box>
    );
  };

  const renderDialog = item => {
    return (
      item?.type === 'bad_status' &&
      openDialogViolate && (
        <DialogViolate
          item={item}
          open={openDialogViolate}
          setOpenDialogViolate={setOpenDialogViolate}
        />
      )
    );
  };

  return (
    <>{item?.type === 'bad_status' ? renderDialog(item) : renderBlock(item)} </>
  );
};

export default CommonNoti;

//comment bài viết láy id bài viết
