import { Button, Switch, Theme, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { getEventLifeApi } from 'src/apis/socialPost.api';
import { updateLifeEventAccount } from 'src/apis/socialUser';
import { convertISO8086toLDate } from 'src/common/string';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { buttonColor, buttonStyle } from 'src/constants/styles';
import ButtonOption from 'src/components/Button/ButtonOption';
import CUPost from 'src/components/CUPost';
import DialogEventLife from 'src/components/Dialog/DialogEventLife/DialogEventLife';
import DialogLocation from 'src/components/Dialog/DialogLocation';
import Visibility from 'src/components/Visibility';
import { listVisibility } from 'src/constants/common';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: '500 !important',
    fontSize: '17px !important',
    marginTop: '15px !important'
  },
  iconButton: {
    '&:hover': {
      cursor: 'pointer'
    },
    '& i': {
      color: buttonColor.backgroundColor
    }
  },
  wrapContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '20px 5px ',
    '& i': {
      fontSize: '15px !important'
    }
  },
  wrapContentIcon: {
    display: 'flex',
    alignItems: 'center',
    '& i': {
      color:
        theme.palette.mode === 'dark' ? buttonColor.backgroundColor : '#65676b',
      width: 24
    }
  },
  wrapAddAction: {
    display: 'flex',
    color: buttonColor.backgroundColor,
    alignItems: 'center',
    padding: 10,
    marginTop: '10px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  description: {
    fontSize: '14px !important',
    fontWeight: '400 !important'
  },
  wrapSocial: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '10px !important'
  },
  wrapWebLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  infoWeb: {
    display: 'flex',
    alignItems: 'center'
  },
  itemFlex: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  lineClamp: {
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word'
  },
  otherName: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '5px'
  },
  itemSocial: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px'
  },
  textInfo: {
    fontWeight: 600
  }
}));

const Introduce = props => {
  const {
    aboutUser,
    dataUpdate,
    setDataUpdate,
    setVisibility,
    visibility,
    lifeEvents,
    setIsError,
    buttonSocial,
    userInfo,
    setOpenEdit,
    setOpenDialog
  } = props;

  const classes = useStyles();
  const [openLocation, setOpenLocation] = React.useState(false);
  const [type, setType] = React.useState<any>('place_live_id');
  const [errorText, setErrorText] = React.useState<any>({
    phone_number: {
      value: false,
      text: ''
    },
    other_name: false
  });
  const [openDialogLifeEvent, setOpenDialogLifeEvent] = React.useState(false);
  const [openCUPost, setOpenCUPost] = React.useState(false);
  const [eventLife, setEventLife] = React.useState<any>(null);
  const [lifeEvent, setLifeEvent] = React.useState<any>();
  const [files, setFiles] = React.useState<any>([]);
  const [lifeEventUser, setLifeEventUser] = React.useState<any>([]);
  const [valueEvent, setValueEvent] = React.useState<any>('');
  const [locationSelected, setLocationSelected] = React.useState('');

  const theme = useTheme() as any;
  const history = useHistory();
  const [isUpdate, setIsUpdate] = React.useState<any>({
    isAddress: false,
    isGender: false,
    isOtherName: false,
    isBirthday: false,
    isPhoneNumber: false,
    isAccountWebLink: false,
    isAccountSocial: false
  });
  const [listWork, setListWork] = React.useState<any>([]);
  const [workVisibility, setWorkVisibility] = React.useState<any>([]);
  const [listHighSchool, setListHighSchool] = React.useState<any>([]);
  const [highSchoolVisibility, setHighSchoolVisibility] = React.useState<any>(
    []
  );
  const [listUniversity, setListUniversity] = React.useState<any>([]);
  const [universityVisibility, setUniversityVisibility] = React.useState<any>(
    []
  );
  let visibilityIntroduce: any;
  let role: any;

  const filterWork = () => {
    let arrayWork: any = [];
    let arrayHighSchool: any = [];
    let arrayUniversity: any = [];
    lifeEvents?.map((el: any) =>
      !el?.life_event?.school_type
        ? arrayWork.push(el)
        : el?.life_event?.school_type === 'UNIVERSITY'
        ? arrayUniversity.push(el)
        : el?.life_event?.school_type === 'HIGH_SCHOOL'
        ? arrayHighSchool.push(el)
        : null
    );
    setListWork(arrayWork);
    setWorkVisibility(
      arrayWork.map(el => ({
        id: el?.life_event?.id,
        visibility: el?.life_event?.visibility
      }))
    );
    setListUniversity(arrayUniversity);
    setUniversityVisibility(
      arrayUniversity.map(el => ({
        id: el?.life_event?.id,
        visibility: el?.life_event?.visibility
      }))
    );
    setListHighSchool(arrayHighSchool);
    setHighSchoolVisibility(
      arrayHighSchool.map(el => ({
        id: el?.life_event?.id,
        visibility: el?.life_event?.visibility
      }))
    );
  };

  React.useEffect(() => {
    filterWork();
  }, [lifeEvents]);
  const handleActionLocation = location => {
    if (type === 'place_live_id') {
      setDataUpdate(prev => ({
        ...prev,
        place_live: location
      }));
    } else if (type === 'hometown_id') {
      setDataUpdate(prev => ({
        ...prev,
        hometown: location
      }));
    }
  };
  const renderButtonAddWorkAndEducation = (type: any) => {
    return (
      <>
        <div
          onClick={() => handleClick(type, '')}
          className={classes.wrapAddAction}
          style={{ paddingTop: 0, marginTop: '20px' }}
        >
          <i
            style={{
              borderRadius: '50%',
              border: `1px solid ${buttonColor.backgroundColor}`,
              width: 20,
              height: 20,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            className="fa-solid fa-plus"
          ></i>{' '}
          &nbsp;
          <Typography style={{ fontWeight: 500 }}>
            Thêm thông tin mới
          </Typography>
        </div>
      </>
    );
  };
  const checkedVisibility = (type: any, id = null) => {
    if (type === 'work') {
      return workVisibility.some(
        (el: any) =>
          el.id === id &&
          (el.visibility === 'public' || el.visibility === 'friend')
      );
    } else if (type === 'highSchool') {
      return highSchoolVisibility.some(
        (el: any) =>
          el.id === id &&
          (el.visibility === 'public' || el.visibility === 'friend')
      );
    } else if (type === 'university') {
      return universityVisibility.some(
        (el: any) =>
          el.id === id &&
          (el.visibility === 'public' || el.visibility === 'friend')
      );
    } else if (type === 'birthday') {
      return visibility.some(
        (el: any) =>
          (el.field === 'birth_date' &&
            (el.visibility === 'public' || el.visibility === 'friend')) ||
          (el.field === 'birth_month' &&
            (el.visibility === 'public' || el.visibility === 'friend')) ||
          (el.field === 'birth_year' &&
            (el.visibility === 'public' || el.visibility === 'friend'))
      );
    } else {
      return visibility.some(
        (el: any) =>
          el.field === type &&
          (el.visibility === 'public' || el.visibility === 'friend')
      );
    }
  };

  const handleVisibility = (type: any, id = null) => {
    if (type === 'work') {
      setWorkVisibility(prev => {
        visibilityIntroduce = prev.filter((el: any) => el.id !== id);
        role = prev.some(
          (item: any) =>
            item.id === id &&
            (item.visibility === 'public' || item.visibility === 'friend')
        );
        return [
          ...visibilityIntroduce,
          {
            id: id,
            visibility: role ? 'private' : 'public'
          }
        ];
      });
    } else if (type === 'highSchool') {
      setHighSchoolVisibility(prev => {
        visibilityIntroduce = prev.filter((el: any) => el.id !== id);
        role = prev.some(
          (item: any) =>
            item.id === id &&
            (item.visibility === 'public' || item.visibility === 'friend')
        );
        return [
          ...visibilityIntroduce,
          {
            id: id,
            visibility: role ? 'private' : 'public'
          }
        ];
      });
    } else if (type === 'university') {
      setUniversityVisibility(prev => {
        visibilityIntroduce = prev.filter((el: any) => el.id !== id);
        role = prev.some(
          (item: any) =>
            item.id === id &&
            (item.visibility === 'public' || item.visibility === 'friend')
        );
        return [
          ...visibilityIntroduce,
          {
            id: id,
            visibility: role ? 'private' : 'public'
          }
        ];
      });
    } else if (type === 'birthday') {
      setVisibility(prev => {
        visibilityIntroduce = prev.filter(
          (el: any) =>
            !['birth_year', 'birth_date', 'birth_month'].includes(el.field)
        );
        role = prev.some(
          (item: any) =>
            (item.field === 'birth_date' &&
              (item.visibility === 'public' || item.visibility === 'friend')) ||
            (item.field === 'birth_month' &&
              (item.visibility === 'public' || item.visibility === 'friend')) ||
            (item.field === 'birth_year' &&
              (item.visibility === 'public' || item.visibility === 'friend'))
        );
        return [
          ...visibilityIntroduce,
          {
            field: 'birth_year',
            visibility: role ? 'private' : 'public'
          },
          {
            field: 'birth_date',
            visibility: role ? 'private' : 'public'
          },
          {
            field: 'birth_month',
            visibility: role ? 'private' : 'public'
          }
        ];
      });
    } else {
      setVisibility(prev => {
        visibilityIntroduce = prev.filter((el: any) => el.field !== type);
        role = prev.some(
          (item: any) =>
            item.field === type &&
            (item.visibility === 'public' || item.visibility === 'friend')
        );
        return [
          ...visibilityIntroduce,
          {
            field: type,
            visibility: role ? 'private' : 'public'
          }
        ];
      });
    }
  };
  const handleUpdateVisibility = async (type: any, id: any) => {
    let info: any;
    let formData: any;
    if (type === 'work') {
      role = workVisibility.find((el: any) => el.id === id);
      info = listWork.find((el: any) => el.life_event.id === id);
      formData = {
        company: info.life_event.company,
        life_event_category_id: 31,
        position: info.life_event.position,
        city: info.life_event.city,
        description: info.life_event.description
          ? info.life_event.description
          : '',
        currently: info.life_event.currently,
        start_date: info.life_event.start_date
          ? convertISO8086toLDate(info.life_event.start_date)
          : null,
        end_date: null,
        visibility: role.visibility === 'public' ? 'private' : 'public'
      };
      await updateLifeEventAccount(userInfo?.id, id, formData);
    } else if (type === 'highSchool') {
      role = highSchoolVisibility.find((el: any) => el.id === id);
      info = listHighSchool.find((el: any) => el.life_event.id === id);
      formData = {
        company: info.life_event.company,
        life_event_category_id: 36,
        school_type: 'HIGH_SCHOOL',
        description: info.life_event.description
          ? info.life_event.description
          : '',
        currently: info.life_event.currently,
        start_date: info.life_event.start_date
          ? convertISO8086toLDate(info.life_event.start_date)
          : null,
        end_date: info.life_event.end_date
          ? convertISO8086toLDate(info.life_event.end_date)
          : null,
        visibility: role.visibility === 'public' ? 'private' : 'public'
      };
      await updateLifeEventAccount(userInfo?.id, id, formData);
    } else if (type === 'university') {
      role = universityVisibility.find((el: any) => el.id === id);
      info = listUniversity.find((el: any) => el.life_event.id === id);
      formData = {
        company: info.life_event.company,
        life_event_category_id: 36,
        school_type: 'UNIVERSITY',
        currently: info.life_event.currently,
        start_date: info.life_event.start_date
          ? convertISO8086toLDate(info.life_event.start_date)
          : null,
        end_date: info.life_event.end_date
          ? convertISO8086toLDate(info.life_event.end_date)
          : null,
        description: info.life_event.description
          ? info.life_event.description
          : '',
        concentration1: info.life_event.concentration1
          ? info.life_event.concentration1
          : '',
        concentration2: info.life_event.concentration2
          ? info.life_event.concentration2
          : '',
        concentration3: info.life_event.concentration3
          ? info.life_event.concentration3
          : '',
        degree: info.life_event.degree ? info.life_event.degree : '',
        attended_for: info.life_event.attended_for,
        visibility: role.visibility === 'public' ? 'private' : 'public'
      };
      await updateLifeEventAccount(userInfo?.id, id, formData);
    }
  };
  const renderContent = (text, type: any, index?: any, id = null) => {
    return text ? (
      <div key={index} className={classes.wrapContent}>
        <div className={classes.wrapContentIcon}>
          <Switch
            checked={checkedVisibility(type, id)}
            onChange={() => {
              handleVisibility(type, id);
              handleUpdateVisibility(type, id);
            }}
            color="primary"
            sx={{
              width: '42px',
              height: '26px',
              padding: '0px',
              marginRight: '10px',
              '& .MuiSwitch-switchBase': {
                padding: '0px',
                margin: '2px',
                transitionDuration: '300ms',
                '&.Mui-checked': {
                  transform: 'translateX(16px)',
                  color: '#fff',
                  '& + .MuiSwitch-track': {
                    backgroundColor: buttonColor.backgroundColor,
                    opacity: 1,
                    border: 0
                  },
                  '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5
                  }
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                  color: '#33cf4d',
                  border: '6px solid #fff'
                },
                '&.Mui-disabled .MuiSwitch-thumb': {
                  color:
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[100]
                      : theme.palette.grey[600]
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
                }
              },
              '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                width: 22,
                height: 22
              },
              '& .MuiSwitch-track': {
                borderRadius: 26 / 2,
                backgroundColor: '#b8b8b8',
                opacity: 1,
                transition: theme.transitions.create(['background-color'], {
                  duration: 500
                })
              }
            }}
          />
          <Typography
            className={classes.lineClamp}
            sx={{
              fontSize: '15px !important',
              color: theme.palette.mode === 'dark' ? '#fff' : '#333',
              fontWeight: '400 !important'
            }}
          >
            {text}
          </Typography>
        </div>
        <div
          onClick={() => {
            if (
              type === 'work' ||
              type === 'university' ||
              type === 'highSchool' ||
              type === 'relationship'
            ) {
              setOpenDialog && setOpenDialog(false);
              setOpenEdit && setOpenEdit(false);
              history.push(
                `/user/${
                  userInfo?.username ? userInfo.username : userInfo.id
                }/intro/${
                  type === 'work' ||
                  type === 'university' ||
                  type === 'highSchool'
                    ? 'about_work_and_education'
                    : 'about_family_and_relationships'
                }`
              );
            } else {
              handleClick(type, text);
            }
          }}
          className={classes.iconButton}
        >
          <i className="fa-solid fa-pen"></i>
        </div>
      </div>
    ) : (
      <div
        key={index}
        onClick={() => handleClick(type, '')}
        className={classes.wrapAddAction}
      >
        <i
          style={{
            borderRadius: '50%',
            border: `1px solid ${buttonColor.backgroundColor}`,
            width: 20,
            height: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          className="fa-solid fa-plus"
        ></i>{' '}
        &nbsp;
        <Typography style={{ fontWeight: 500 }}>Thêm thông tin mới</Typography>
      </div>
    );
  };

  const handleClick = (type, text) => {
    setType(type);
    if (type === 'place_live_id') {
      setLocationSelected(text);
      setOpenLocation(true);
    } else if (type === 'hometown_id') {
      setLocationSelected(text);
      setOpenLocation(true);
    } else if (type === 'work') {
      setOpenDialogLifeEvent(true);
      setEventLife(lifeEventUser[3]);
      setValueEvent(null);
    } else if (type === 'highSchool') {
      setValueEvent({ type: 'HIGH_SCHOOL', title: 'Trung học' });
      setOpenDialogLifeEvent(true);
      setEventLife(lifeEventUser[10]);
    } else if (type === 'university') {
      setOpenDialogLifeEvent(true);
      setValueEvent({
        type: 'UNIVERSITY',
        title: 'Đại học'
      });
      setEventLife(lifeEventUser[10]);
    } else if (type === 'address') {
      setIsUpdate(prev => ({
        ...prev,
        isAddress: true
      }));
    } else if (type === 'gender') {
      setIsUpdate(prev => ({
        ...prev,
        isGender: true
      }));
    } else if (type === 'other_name') {
      setIsUpdate(prev => ({
        ...prev,
        isOtherName: true
      }));
    } else if (type === 'birthday') {
      setIsUpdate(prev => ({
        ...prev,
        isBirthday: true
      }));
    } else if (type === 'phone_number') {
      setIsUpdate(prev => ({
        ...prev,
        isPhoneNumber: true
      }));
    } else if (type === 'account_web_link') {
      setIsUpdate(prev => ({
        ...prev,
        isAccountWebLink: true
      }));
    } else if (type === 'account_social') {
      setIsUpdate(prev => ({
        ...prev,
        isAccountSocial: true
      }));
    } else if (type === 'relationship') {
      history.push(
        `/user/${
          userInfo?.username ? userInfo.username : userInfo?.id
        }/intro/about_family_and_relationships`
      );
      setOpenDialog && setOpenDialog(false);
      setOpenEdit && setOpenEdit(false);
    }
  };

  //BIRTHDAY

  let optionDate = Array.from(Array(31).keys())?.map((el: any, index) => ({
    id: index + 1,
    label: index + 1
  }));

  let optionMonth = Array.from(Array(12).keys())?.map((el: any, index) => ({
    id: index + 1,
    label: `Tháng ${index + 1}`
  }));

  let optionYear = Array.from(Array(new Date().getFullYear()).keys())
    ?.filter((el, index) => index >= 1904)
    ?.map((el: any) => ({
      id: el + 1,
      label: el + 1
    }));

  // render date options
  const renderDate = (month, year) => {
    let dateAmount;
    if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
      dateAmount = 31;
    } else if ([4, 6, 9, 11].includes(month)) dateAmount = 30;
    else if (month === 2) {
      if (year % 4 === 0) {
        dateAmount = 29;
      } else {
        dateAmount = 28;
      }
    }
    return Array.from(Array(dateAmount).keys())?.map((el: any, index) => ({
      id: index + 1,
      label: index + 1
    }));
  };

  const handleCheck = (key: any, typ: any) => {
    if (key === 'birthday') {
      if (
        (dataUpdate?.birth_date === null &&
          dataUpdate?.birth_month === null &&
          dataUpdate?.birth_year === null) ||
        (dataUpdate?.birth_date !== null &&
          dataUpdate?.birth_month !== null &&
          dataUpdate?.birth_year !== null)
      ) {
        setIsError(false);
        setIsUpdate(prev => ({ ...prev, [typ]: false }));
      } else {
        setIsError(true);
      }
    } else {
      setIsUpdate(prev => ({ ...prev, [typ]: false }));
    }
  };
  const renderButton = (key, isKey) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          onClick={() => {
            if (key === 'birthday') setIsError(false);

            setIsUpdate(prev => ({ ...prev, [isKey]: false }));
            setDataUpdate(prev =>
              key !== 'birthday'
                ? {
                    ...prev,
                    [key]: aboutUser?.general_information[key]
                  }
                : {
                    ...prev,
                    birth_date: aboutUser?.general_information?.birth_date,
                    birth_month: aboutUser?.general_information?.birth_month,
                    birth_year: aboutUser?.general_information?.birth_year
                  }
            );

            if (key === 'gender') {
              setVisibility(prev => [
                ...prev.slice(0, 5),
                {
                  field: 'gender',
                  visibility:
                    aboutUser.general_information?.account_display_fields?.[5]
                      ?.visibility
                },
                ...prev.slice(6)
              ]);
            } else if (key === 'birthday') {
              setVisibility(prev => [
                {
                  field: 'birth_year',
                  visibility:
                    aboutUser.general_information?.account_display_fields?.[0]
                      ?.visibility
                },
                {
                  field: 'birth_date',
                  visibility:
                    aboutUser.general_information?.account_display_fields?.[1]
                      ?.visibility
                },
                {
                  field: 'birth_month',
                  visibility:
                    aboutUser.general_information?.account_display_fields?.[2]
                      ?.visibility
                },
                ...prev.slice(3)
              ]);
            } else if (key === 'phone_number') {
              setVisibility(prev => [
                ...prev.slice(0, 7),
                {
                  field: 'phone_number',
                  visibility:
                    aboutUser.general_information?.account_display_fields?.[7]
                      ?.visibility
                },
                ...prev.slice(9, 11)
              ]);
            }
          }}
          style={{
            ...buttonStyle,
            textTransform: 'none',
            margin: '0px 0px 0px 5px'
          }}
        >
          Hủy
        </Button>
        <ButtonInherit
          label="Xong"
          disabled={
            (errorText.phone_number.value && isKey === 'isPhoneNumber') ||
            (errorText.other_name && isKey === 'isOtherName')
              ? true
              : false
          }
          action={() => handleCheck(key, isKey)}
          color={buttonColor.backgroundColor}
          style={{ margin: '0px 0px 0px 5px' }}
        />
      </div>
    );
  };

  const renderSocial = (data: any, type) => {
    const visibilitySocial = visibility?.find(
      item => item.field === type
    )?.visibility;
    return (
      <div style={{ marginTop: '10px' }}>
        <div className={classes.wrapWebLink}>
          <div>
            <Typography className={classes.title}>
              {type === 'account_web_link' ? 'Trang web' : 'Liên kết xã hội'}
            </Typography>
            <Typography className={classes.description}>
              Để hiển thị liên kết trên trang cá nhân, hãy đặt đối tượng thành
              <span style={{ fontWeight: '500' }}> Công khai.</span>
            </Typography>
          </div>
          <Visibility
            visibility={
              visibility?.find(item => item.field === type)?.visibility ??
              'public'
            }
            handleAction={item => {
              setVisibility(prev => {
                visibilityIntroduce = prev?.filter(el => el.field !== type);
                return [
                  ...visibilityIntroduce,
                  {
                    field: type,
                    visibility: item
                  }
                ];
              });
            }}
            listVisibility={listVisibility}
            styleVisibility={{
              width: '120px',
              backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#ccc',
              color: '#333 !important',
              fontWeight: '600'
            }}
            styleTextVisibility={{
              fontSize: '14px'
            }}
          />
        </div>
        <div style={{ display: 'block', alignItems: 'center', margin: 10 }}>
          {data[type]?.map((item: any, index) => (
            <div
              className={classes.itemFlex}
              style={{ alignItems: 'center', marginTop: '10px' }}
            >
              <div className={classes.itemSocial}>
                {type === 'account_social' ? (
                  <img
                    src={
                      buttonSocial?.find(
                        icon =>
                          icon.id ===
                          dataUpdate[type][index]?.social_category_id
                      )?.icon
                    }
                    alt="icon"
                    style={{ width: '30px' }}
                  />
                ) : (
                  <i className="fas fa-globe-asia fa-xl fa-lg"></i>
                )}
                <Typography
                  sx={{ marginLeft: '10px' }}
                  className={classes.lineClamp}
                >
                  {type === 'account_web_link'
                    ? dataUpdate[type].find(i => i?.id === item?.id)?.url
                    : dataUpdate[type].find(i => i?.id === item?.id)?.text}
                </Typography>
              </div>
              <div
                onClick={() => {
                  setOpenDialog && setOpenDialog(false);
                  setOpenEdit && setOpenEdit(false);
                  history.push(
                    `/user/${
                      userInfo?.username ? userInfo.username : userInfo.id
                    }/intro/${
                      type === 'work' ||
                      type === 'university' ||
                      type === 'highSchool'
                        ? 'about_work_and_education'
                        : type === 'account_web_link' ||
                          type === 'account_social'
                        ? 'about_contact_and_basic_info'
                        : 'about_family_and_relationships'
                    }`
                  );
                }}
                className={classes.iconButton}
              >
                <i className="fa-solid fa-pen"></i>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    async function getListMenuEventLife() {
      let res = await getEventLifeApi();
      if (res.status === 200) {
        setLifeEventUser(res.data);
      }
    }

    getListMenuEventLife();
  }, []);
  React.useEffect(() => {
    if (aboutUser && Object.keys(aboutUser).length) {
      let dataInformation = aboutUser.general_information;

      setDataUpdate({
        place_live: dataInformation?.place_live,
        hometown: dataInformation?.hometown,
        address: dataInformation?.address,
        gender: dataInformation?.gender,
        birth_date: dataInformation?.birth_date,
        birth_month: dataInformation?.birth_month,
        birth_year: dataInformation?.birth_year,
        other_name: dataInformation?.other_name,
        phone_number: dataInformation?.phone_number,
        relationship: aboutUser?.account_relationship,
        account_web_link: dataInformation?.account_web_link,
        account_social: dataInformation?.account_social
      });
      if (
        aboutUser.general_information?.account_display_fields &&
        Object.keys(aboutUser.general_information?.account_display_fields)
          .length
      ) {
        if (aboutUser?.account_relationship) {
          setVisibility([
            ...aboutUser.general_information?.account_display_fields,
            {
              field: 'relationship',
              visibility: aboutUser.account_relationship.visibility
            }
          ]);
        } else {
          setVisibility(aboutUser.general_information?.account_display_fields);
        }
      }
    }
  }, [JSON.stringify(aboutUser)]);

  return (
    <>
      <div style={{ padding: '10px 15px' }}>
        <div>
          <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
            Chỉnh sửa phần giới thiệu
          </Typography>
          <Typography sx={{ fontSize: '16px', opacity: '0.7' }}>
            Chi tiết bạn chọn sẽ hiển thị công khai.
          </Typography>
        </div>
        <Typography className={classes.title}>Công việc hiện tại</Typography>
        {listWork?.map((el: any, index) => {
          return el?.life_event
            ? renderContent(
                `${
                  el?.life_event?.position
                    ? el?.life_event?.position + ' tại '
                    : ''
                }` +
                  ` ${
                    el?.life_event?.company
                      ? el?.life_event?.company && el?.life_event?.city
                        ? `${el?.life_event?.company} tại ${el?.life_event?.city}`
                        : el?.life_event?.company
                      : el?.life_event?.name
                  }` +
                  `${
                    el?.life_event?.name && el?.life_event?.place
                      ? ` tại ${el?.life_event?.place?.title}`
                      : ''
                  }`,
                'work',
                index,
                el.life_event.id
              )
            : null;
        })}
        {renderButtonAddWorkAndEducation('work')}
        <Typography className={classes.title}>Trung Học</Typography>
        {listHighSchool?.map((el: any, index) => {
          return el?.life_event
            ? renderContent(
                `${
                  el?.life_event?.school_type === 'HIGH_SCHOOL'
                    ? el?.life_event?.currently
                      ? 'Đã học'
                      : el?.life_event?.name
                      ? `${el?.life_event?.name} `
                      : 'Học'
                    : null
                }` +
                  ' tại ' +
                  `${
                    el?.life_event?.company
                      ? el?.life_event?.company
                      : el?.life_event?.place
                      ? el?.life_event?.place?.title
                      : ''
                  }`,
                'highSchool',
                index,
                el.life_event.id
              )
            : null;
        })}
        {renderButtonAddWorkAndEducation('highSchool')}
        <Typography className={classes.title}>Đại học</Typography>
        {listUniversity?.map((el: any, index) => {
          return el?.life_event
            ? renderContent(
                `${
                  el?.life_event?.school_type === 'UNIVERSITY'
                    ? el?.life_event?.currently
                      ? 'Từng học'
                      : el?.life_event?.name
                      ? `${el?.life_event?.name} `
                      : 'Học'
                    : null
                }` +
                  ' tại ' +
                  `${
                    el?.life_event?.company
                      ? el?.life_event?.company
                      : el?.life_event?.place
                      ? el?.life_event?.place?.title
                      : ''
                  }`,
                'university',
                index,
                el.life_event.id
              )
            : null;
        })}
        {renderButtonAddWorkAndEducation('university')}
        <Typography className={classes.title}>
          Tỉnh/Thành phố hiện tại
        </Typography>
        {renderContent(dataUpdate?.place_live?.title, 'place_live_id')}
        <Typography className={classes.title}>Quê quán</Typography>
        {renderContent(dataUpdate.hometown?.title, 'hometown_id')}
        <Typography className={classes.title}>Mối quan hệ</Typography>
        {renderContent(
          dataUpdate?.relationship ? (
            dataUpdate?.relationship?.relationship_category?.name ===
            'Độc thân' ? (
              <span className={classes.textInfo}>Độc thân</span>
            ) : (
              <span>
                <span className={classes.textInfo}>
                  {dataUpdate?.relationship?.relationship_category?.name}
                </span>{' '}
                cùng với&nbsp;
                <span className={classes.textInfo}>
                  {dataUpdate?.relationship?.partner?.display_name}
                </span>{' '}
              </span>
            )
          ) : null,
          'relationship'
        )}
        {renderSocial(dataUpdate, 'account_web_link')}

        {renderSocial(dataUpdate, 'account_social')}
      </div>

      {openLocation && (
        <DialogLocation
          typePage="editProfile"
          setLocationSelected={setLocationSelected}
          locationSelected={locationSelected}
          open={openLocation}
          handleClose={() => setOpenLocation(false)}
          handleAction={handleActionLocation}
        />
      )}

      {openDialogLifeEvent && (
        <DialogEventLife
          open={openDialogLifeEvent}
          handleClose={() => {
            setOpenDialogLifeEvent(false);
            setValueEvent(null);
          }}
          files={files}
          setFiles={setFiles}
          setLifeEvent={setLifeEvent}
          eventLife={eventLife}
          setEventLife={setEventLife}
          setOpenCUPost={setOpenCUPost}
          valueEvent={valueEvent}
          type="introduce"
        />
      )}
      <CUPost
        type="header-post"
        openCUPost={openCUPost}
        setOpenCUPost={setOpenCUPost}
        handleFinal={() => setOpenCUPost(false)}
        newFiles={files}
        newLifeEvent={lifeEvent}
      />
    </>
  );
};

export default Introduce;
