import Box from '@mui/material/Box';
import CircularProgress, {
  CircularProgressProps
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';
import { useDispatch, useSelector } from 'react-redux';
import page_default from 'src/assets/images/page_default.png';
import group_cover from 'src/assets/images/group_cover.png';
import ButtonCustom from 'src/components/Button/Button';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import getCroppedImg from 'src/components/Dialog/DialogEditProfile/cropImage';
import { getColorImageReq } from 'src/store/action/settingAction';
import { uploadMediasSaga } from 'src/store/saga/socialPostSaga';
import WatchPlayer from '../VideoPlayer/WatchPlayer';
function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        {...props}
        size={35}
        thickness={5}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const ImageUploadProfile = props => {
  const {
    type,
    action,
    fileUpload,
    height,
    label,
    typeVideo,
    setCheckCreatePage,
    match,
    filedName,
    setType,
    setOpenSnackbar,
    setNoti,
    type_video,
    setLoadingImageUpload,
    setErrorImage,
    setErrorItem,
    setErrorMp3,
    coverImage,
    disableEditImageOrVideo,
    notSaveOldData //save data when component no unmount
  } = props;
  const useStyles = makeStyles((theme: any) => ({
    wrapHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      '& .reactEasyCrop_Container': {
        height: height ? `${height}px !important` : '250px !important',
        overflow: 'hidden',
        borderRadius: '10px'
      },
      '& .reactEasyCrop_CropAreaGrid': {
        height: height ? `${height}px !important` : '250px !important',
        width: coverImage ? '100% !important' : null,
        '&::before': {
          border: '0px !important'
        },
        '&::after': {
          border: '0px !important',
          width: '100% !important'
        }
      },
      '& .reactEasyCrop_CropArea': {
        color: 'rgba(255,255,255,0.7) !important'
      }
    },
    wrapUpload: {
      width: '100%',
      borderRadius: 10,
      height: '160px',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.button.custom.background,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.button.custom.hover
      }
    },
    text: {
      fontSize: '15px !important',
      fontWeight: '600 !important',
      padding: '0px 12px'
    },
    wrapRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    wrapText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }
  }));

  const classes = useStyles();
  const [files, setFiles] = React.useState<any>([]);
  const dispatch = useDispatch();
  const imageUpload = useSelector((state: any) => state?.settingReducer);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState<any>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<any>('');
  const [progress, setProgress] = React.useState<any>(0);
  const pdfIcon =
    'https://trial103.easyedu.vn/sites/all/themes/easyedu/images/pdf-large.png';

  const powerPointIcon =
    'https://trial103.easyedu.vn/sites/all/themes/easyedu/images/powerpoint-large.png';

  const onDropRejected = fileRejections => {
    if (type === 'file_mp3') {
      // Xử lý khi tệp bị từ chối (vượt quá kích thước)
      const errorMessage = `File quá lớn, vui lòng chọn tệp dưới 60MB`;
      setErrorMp3(errorMessage);
    }
  };
  const maxSize = type === 'file_mp3' ? 60 * 1024 * 1024 : undefined;
  const maxVideoDuration = 3600;

  const getVideoDuration = async file => {
    return new Promise(resolve => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.src = URL.createObjectURL(file);

      video.onloadedmetadata = () => {
        const duration = video.duration || 0;
        resolve(duration);
        URL.revokeObjectURL(video.src);
      };
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept:
      type === 'file_document'
        ? ' .pdf, .pptx, .ppt'
        : type === 'file'
        ? 'video/mp4'
        : type === 'file_mp3'
        ? '.ogg, .oga, .mp3, .wav, .flac, .opus, .aac, .m4a, .3gp, .wma'
        : 'image/jpeg,image/png,image/jpg',
    onDrop: async (acceptedFiles: any) => {
      if (type === 'file_mp3') {
        const validations = await Promise.all(
          acceptedFiles.map(async file => {
            const videoDuration: any = await getVideoDuration(file);

            if (videoDuration === Infinity) {
              setErrorMp3(
                'File tải lên bị lỗi.Vui lòng thử lại bằng file khác!'
              );
            } else if (videoDuration > maxVideoDuration) {
              return {
                isValid: false,
                fileName: file.name,
                error: true,
                type: 'duration'
              };
            }
          })
        );

        if (
          validations[0]?.error &&
          validations[0]?.type === 'duration' &&
          type === 'file_mp3'
        ) {
          setErrorMp3('Thời lượng bài hát cho phép tối đa 1 giờ.');
        }
      }
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
      setCheckCreatePage && setCheckCreatePage(false);
    },
    onDropRejected: onDropRejected,
    maxSize: maxSize
  });
  const getMediaFile = async () => {
    try {
      if (
        type === 'file' ||
        type === 'file_document' ||
        type === 'file_media' ||
        type === 'file_mp3'
      ) {
        setLoadingImageUpload(true);
        let response = await uploadMediasSaga(
          files,
          setProgress,
          'video',
          typeVideo ? 'course' : 'others'
        );
        if (response.length > 0 && response[0].id) {
          setLoadingImageUpload(false);
        }
        if (
          action &&
          files?.length &&
          files[0]?.type === 'video/mp4' &&
          type === 'file'
        ) {
          let data = {
            id: files[0]?.id ?? response[0]?.id,
            show_url: files[0]?.show_url
              ? files[0]?.show_url
              : files[0]?.preview,
            file: response[0]
          };
          action(data);
        } else if (
          action &&
          files?.length &&
          (type === 'file_document' ||
            type === 'file_media' ||
            type === 'file_mp3')
        ) {
          let data = {
            id: files[0]?.id ?? response[0]?.id,
            show_url: files[0]?.show_url,
            file: files[0]
          };
          action(data);
        }
      }
    } catch (error) {
      setLoadingImageUpload(false);
      setOpenSnackbar && setOpenSnackbar(true);
      setNoti &&
        setNoti({
          code: 400,
          message: 'Video tải lên bị lỗi.Vui lòng thử lại!'
        });
    }
  };

  React.useEffect(() => {
    if (files?.length && !files[0]?.file?.id) {
      getMediaFile();
    }
  }, [JSON.stringify(files)]);

  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showImage = async () => {
    let newFile = files[0];
    Object.assign(newFile, {
      show_url: files[0]?.preview
    });
    action && action({ file: newFile });
  };
  const showCroppedImage = React.useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        files[0]?.preview,
        croppedAreaPixels
      );
      let newFile = files[0];
      Object.defineProperty(newFile, 'show_url', {
        value: croppedImage,
        writable: true
      });
      setFiles([newFile]);
      if (newFile) {
        if (!newFile.id && Object?.keys(newFile?.file || {})?.length === 0) {
          dispatch(getColorImageReq(files, setProgress, window.location.href));
        } else {
          action && action(newFile);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [JSON.stringify(croppedAreaPixels)]);

  React.useEffect(() => {
    if (files?.length) {
      if (type !== 'file' && type !== 'file_media') {
        showCroppedImage();
      } else {
        showImage();
      }
    }
  }, [JSON.stringify(croppedAreaPixels)]);
  React.useEffect(() => {
    if (
      imageUpload?.id &&
      action &&
      files?.length &&
      type !== 'file' &&
      type !== 'file_document' &&
      type !== 'file_media'
    ) {
      let data = {
        id: imageUpload?.id,
        show_url: files[0]?.show_url,
        file: files[0]
      };
      action(data);
    } else if (
      action &&
      files?.length &&
      type === 'file' &&
      type_video !== 'file_video_intro' &&
      typeVideo !== 'file_course' &&
      files[0]?.type !== 'video/mp4'
    ) {
      let data = {
        id: files[0]?.id ?? null,
        show_url: files[0]?.show_url,
        file: files[0]
      };
      action(data);
    }
  }, [JSON.stringify(imageUpload), type, files?.length]);

  React.useEffect(() => {
    if (!fileUpload) {
      setFiles([]);
      return;
    }
    if (fileUpload && Object.keys(fileUpload).length && !files?.length) {
      setFiles([
        {
          ...fileUpload,
          preview: fileUpload.show_url || fileUpload.url
        }
      ]);
      return;
    }
    if (
      fileUpload &&
      Object.keys(fileUpload).length &&
      files?.length &&
      notSaveOldData
    ) {
      setFiles([
        {
          ...fileUpload,
          preview: fileUpload.show_url || fileUpload.url
        }
      ]);
      return;
    }
  }, [JSON.stringify(fileUpload)]);
  const renderContentFile = () => {
    if (files.length) {
      return (
        <div
          style={{
            width: '100%'
          }}
        >
          <div className={classes.wrapHeader}>
            {files[0]?.type?.includes('video') ||
            typeVideo ||
            files[0]?.file?.file ? (
              files[0]?.type?.includes('/mp4') || files[0]?.file?.file ? (
                <video
                  style={{
                    backgroundColor: '#000',
                    borderRadius: '10px'
                  }}
                  width="100%"
                  height={height ? height : '190px'}
                  src={files[0]?.preview}
                  controls
                />
              ) : (
                <WatchPlayer type="video" video={files[0]} />
              )
            ) : (
              <img
                src={files[0]?.preview ?? group_cover}
                alt="preview"
                width="100%"
                height="100%"
                onError={(e: any) => (e.target.src = group_cover)}
              />
            )}
          </div>
          {progress !== 0 && progress !== 100 && (
            <div
              style={{
                position: 'absolute',
                top: 5,
                left: 5,
                backgroundColor: '#f0f0f0',
                borderRadius: '50%',
                padding: '3px',
                height: '38px',
                width: '38px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgressWithLabel value={progress} />
            </div>
          )}
        </div>
      );
    }
  };
  const renderContent = () => {
    let typeDoc =
      files[0]?.path?.slice(-3) ?? files[0]?.file?.file?.path?.slice(-3);
    if (files.length) {
      return (
        <div style={{ position: 'relative' }}>
          {type === 'file_document' ? (
            <img
              src={typeDoc === 'pdf' ? pdfIcon : powerPointIcon}
              style={{
                width: '100%',
                height: height ? height : 250,
                borderRadius: 10,
                objectFit: 'contain'
              }}
              alt="file_docment"
              onError={(e: any) => (e.target.src = group_cover)}
            />
          ) : type === 'file_media' ? (
            <img
              src={
                files[0]?.file?.file?.preview
                  ? files[0]?.file?.file?.preview
                  : files[0]?.preview
                  ? files[0]?.preview
                  : type === 'banner_page'
                  ? page_default
                  : group_cover
              }
              alt="preview"
              width="100%"
              height={height ? height : '160px'}
              style={{ borderRadius: '10px', objectFit: 'cover' }}
              onError={(e: any) => (e.target.src = group_cover)}
            />
          ) : files.length > 0 &&
            (files[0]?.file?.file?.type?.includes('audio') ||
              files[0]?.type?.includes('audio')) ? (
            <audio controls style={{ marginTop: '10px' }}>
              <source
                src={
                  files[0]?.preview
                    ? files[0]?.preview
                    : files[0]?.file?.file?.preview
                }
              />
            </audio>
          ) : (
            <div className={classes.wrapHeader}>
              <Cropper
                image={files[0].preview}
                crop={crop}
                zoom={zoom}
                aspect={coverImage ? 6 / 3 : 3 / 3}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                zoomWithScroll={false}
                objectFit="vertical-cover"
              />
              )
            </div>
          )}
          {progress !== 0 && progress !== 100 && (
            <div
              style={{
                position: 'absolute',
                top: 5,
                left: 5,
                backgroundColor: '#f0f0f0',
                borderRadius: '50%',
                padding: '3px',
                height: '38px',
                width: '38px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgressWithLabel value={progress} />
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div style={{ position: 'relative' }}>
          <img
            src={type === 'banner_page' ? page_default : group_cover}
            style={{
              width: '100%',
              height: height ? height : 250,
              borderRadius: 10
            }}
            alt="banner"
            onError={(e: any) => (e.target.src = group_cover)}
          />
          {progress !== 0 && progress !== 100 && (
            <div
              style={{
                position: 'absolute',
                top: 5,
                left: 5,
                backgroundColor: '#f0f0f0',
                borderRadius: '50%',
                padding: '3px',
                height: '38px',
                width: '38px',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgressWithLabel value={progress} />
            </div>
          )}
        </div>
      );
    }
  };
  return (
    <>
      {type === 'file' ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          {files.length ? (
            <>
              {renderContentFile()}
              {files.length ? (
                <div
                  style={{
                    marginTop: 10
                  }}
                >
                  <ButtonInherit
                    style={{
                      display: disableEditImageOrVideo ? 'none' : 'inherit'
                    }}
                    disabled={disableEditImageOrVideo}
                    action={() => {
                      setFiles([]);
                      action && action(null);
                      setLoadingImageUpload(false);
                      setErrorMp3 && setErrorMp3('');
                    }}
                    label="Hủy"
                  />
                </div>
              ) : null}
            </>
          ) : (
            <Box
              sx={{ width: '100%', height: '100%', position: 'relative' }}
              onClick={() => setType(filedName)}
            >
              <div
                {...getRootProps()}
                className={classes.wrapUpload}
                style={{ height: height ? height : '160px' }}
              >
                <input {...getInputProps()} name={filedName} />
                <div className={classes.wrapRoot}>
                  <Box className={classes.wrapText}>
                    <Typography className={classes.text}>
                      Tải lên {label}
                    </Typography>
                  </Box>
                </div>
              </div>
              {match?.url === '/courses/create/offline' ? (
                <span style={{ fontSize: 13 }}>
                  Video tải lên không được quá 3 phút!
                </span>
              ) : (
                ''
              )}
            </Box>
          )}
        </div>
      ) : (
        <div style={{ position: 'relative', height: height ? height : 250 }}>
          {renderContent()}
          {files.length ? (
            <div
              style={{
                position: 'absolute',
                bottom: 10,
                right: 160,
                zIndex: 1000
              }}
            >
              <ButtonInherit
                disabled={disableEditImageOrVideo}
                style={{
                  display: disableEditImageOrVideo ? 'none' : 'inherit'
                }}
                action={() => {
                  action(null);
                  setFiles([]);
                  setErrorImage && setErrorImage('');
                  setErrorItem && setErrorItem(false);
                  setErrorMp3 && setErrorMp3('');
                  setLoadingImageUpload(false);
                }}
                label="Hủy"
              />
            </div>
          ) : null}
          <div
            style={{
              display: disableEditImageOrVideo ? 'none' : 'inherit',
              position: 'absolute',
              bottom: 10,
              right: 10,
              zIndex: 1000,
              width: 150
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} name={filedName ? filedName : type} />
            <div
              onClick={() => {
                setType(filedName);
                setErrorMp3 && setErrorMp3('');
              }}
            >
              <ButtonCustom
                icon={
                  type === 'file_document' ? (
                    <i className="fa-solid fa-file"></i>
                  ) : (
                    <i className="fa-solid fa-camera"></i>
                  )
                }
                style={{ backgroundColor: 'button.primary.background' }}
                label={
                  type === 'banner_page' || type === 'banner_group'
                    ? 'Thêm ảnh bìa'
                    : type === 'file_document' || type === 'file_mp3'
                    ? 'Thêm File'
                    : 'Thêm ảnh'
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageUploadProfile;
