import { useTheme } from '@mui/styles';
export const BREAKPOINT = { SM: 576, MD: 768, LG: 992, XL: 1200, XXL: 1400 };
export const COLOR = {
  BLUE: '#0d6efd',
  VIOLET: '#7165E0'
};

// from MUI
export const COLOR_STATE = {
  SUCCESS_LIGHT: '#1e4620',
  ERROR_LIGHT: '#5f2120',
  SUCCESS_DARK: '#cce8cd',
  ERROR_DARK: '#f4c7c7'
};

export const BUTTON_STYLE: any = {
  margin: 0.25,
  height: '32px',
  textTransform: 'none',
  padding: '8px 16px 8px 12px',
  borderRadius: '5px',
  '& span span i': {
    fontSize: '15px !important'
  },
  boxShadow: 0
};

export const boxShadow: any = {
  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2) !important',
  border: '1px solid rgba(0,0,0,0.10)',
  borderBottom: '0'
};

export const boxShadowCardEdit: any = {
  boxShadow: '0 6px 12px rgba(68, 73, 80, 0.35) !important',
  border: '1px solid rgba(0,0,0,0.10)',
  borderBottom: '0'
};

export const buttonStyle: any = {
  color: `#7165E0 !important`,
  marginRight: 10,
  fontSize: 15,
  fontWeight: 600
};

export const scrollStyle = {
  '&::-webkit-scrollbar': {
    width: '10px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#bdbdbd',
    borderRadius: 10
  }
};

export const scrollStyleNotrack = {
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#bdbdbd',
    borderRadius: 10
  }
};

export const modalBackdrop = {
  style: { backgroundColor: 'rgba(255,255,255,0.6)' }
};

export function lineClamp(maxLine) {
  return {
    display: '-webkit-box',
    WebkitLineClamp: maxLine || 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  };
}

export const tableHeader = {
  background:
    'linear-gradient(rgb(241, 242, 245) 0%, rgb(241, 242, 245) 100%, rgb(241, 242, 245) 100%, rgb(220, 220, 220) 100%)',
  border: '1px solid rgb(224 224 224)'
};

export const WebkitScrollbarRGB = props => {
  const borderRadiusTrack = props?.borderRadiusTrack;
  const theme: any = useTheme();

  return {
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c',
      borderRadius: borderRadiusTrack ? borderRadiusTrack : 'none'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
      borderRadius: '100px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundImage: 'linear-gradient(to bottom,#ffa400,#00aefd)'
    }
  };
};

export const buttonColor = {
  backgroundColor: '#7165E0',
  inheritBackground: '#e4e6eb',
  inheritText: '#333',
  newCheckboxColor: 'rgba(255, 145, 4, 1)',
  newBackgroundColor: 'rgba(255, 125, 4, 1)',
  inheritBackgroundColor: 'rgb(228, 230, 235)',
  darkInheritBackgroundColor: '#4b4b4b',
  newTextColor: 'rgba(255, 125, 4, 1)',
  deleteColor: '#eb2f06'
};
export const breadcrumbsStyle = {
  color: '#ffa023',
  blackColor: '#000000',
  whiteColor: '#ffffff'
};
export const colorBlue = {
  color: '#0084FF'
};
export const buttonColorHover = {
  backgroundColor: '#7d72df'
};

export const defaultStyleMention = {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal'
  },

  '&multiLine': {
    control: {
      fontFamily: 'monospace',
      minHeight: 63
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent'
    },
    input: {
      padding: 9,
      border: '1px solid silver'
    }
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent'
    },
    input: {
      padding: 1,
      border: '2px inset'
    }
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5'
      }
    }
  }
};
