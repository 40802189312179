import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Theme,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createStyles, makeStyles } from '@mui/styles';
import { requestFriendApi } from 'src/apis/socialUser';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import Button from 'src/components/Button/Button';
import bannerDefault from 'src/assets/images/user_banner.png';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DialogUserChatGroup(props) {
  const { open, setOpen, userSelectedData, conversation, userAboutData } =
    props;
  const [isAddFriend, setIsAddFriend] = React.useState<any>();
  const handleClose = () => {
    setOpen(false);
  };

  const useStyles = makeStyles(
    (theme: Theme) =>
      createStyles({
        iconClose: {
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f1f2f5',
          borderRadius: '50%',
          padding: 1,
          height: 20,
          width: 20,
          right: -5,
          top: 0,
          zIndex: 40,
          border: '2px solid rgba(0,0,0,0.1)',
          '&:hover': {
            cursor: 'pointer'
          }
        },
        arrow: {
          backgroundColor: '#ffffff',
          padding: '8px 12px',
          borderRadius: '100%',
          transform: 'rotate(45deg)'
        },
        countConversation: {
          width: '100%',
          height: '100%',
          backgroundColor: '#33333375',
          borderRadius: '50%',
          position: 'absolute',
          top: 0,
          right: 0,
          zIndex: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fafafa',
          fontWeight: 600,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#33333350'
          }
        },
        customScrollbar: {
          '&::-webkit-scrollbar': {
            width: '10px'
          },
          '&::-webkit-scrollbar-track': {
            background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
            borderRadius: '100px'
          }
        },
        styleComponent: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
          position: 'relative'
        }
      }),
    { index: 1 }
  );

  const classes = useStyles();

  const BannerAndAvatarUserComponent = () => {
    const handleAddFriend = async () => {
      try {
        const response = await requestFriendApi(userSelectedData.id);
        if (response.status === 200) {
          setIsAddFriend(response.data);
        }
      } catch (e) {
        console.log(e);
      }
    };

    return (
      <Box className={classes.styleComponent}>
        <img
          src={
            userSelectedData?.banner
              ? userSelectedData?.banner?.preview_url
              : bannerDefault
          }
          srcSet={
            userSelectedData?.banner
              ? userSelectedData?.banner?.url
              : bannerDefault
          }
          alt={userSelectedData?.banner?.id}
          style={{
            height: '150px',
            width: '100%',
            objectFit: 'cover'
          }}
        />
        <AvatarSocial
          type="feed"
          style={{
            height: 100,
            width: 100,
            top: -40,
            border: '1px solid #fafafa',
            borderRadius: '50%'
          }}
          avatarObj={
            userSelectedData?.avatar_media?.show_url ??
            userSelectedData?.avatar_media?.preview_url
          }
        />
        <Typography style={{ fontSize: '20px', fontWeight: 700, top: -20 }}>
          {conversation?.type !== 'chat_page'
            ? userSelectedData?.display_name
            : userSelectedData?.title}
        </Typography>
        {conversation?.type !== 'chat_page' &&
        userSelectedData?.relationships?.friendship_status === 'CAN_REQUEST' &&
        !isAddFriend ? (
          <Button
            style={{
              width: 'fit-content',
              padding: ' 0 12px',
              margin: '0 0 8px'
            }}
            label="Thêm bạn bè"
            action={() => handleAddFriend()}
          />
        ) : isAddFriend?.friendship_status === 'OUTGOING_REQUEST' ? (
          <Button
            style={{
              width: 'fit-content',
              padding: ' 0 12px',
              margin: '0 0 8px'
            }}
            label="Đã gửi lời mời kết bạn"
            disable={true}
          />
        ) : null}
      </Box>
    );
  };

  const InformationPersonal = () => {
    return (
      <List sx={{ width: '100%', padding: '10px 20px' }}>
        <ListItem
          disableGutters
          secondaryAction={
            <Typography sx={{ fontSize: '14px', opacity: 0.8 }}>
              {conversation?.type !== 'chat_page'
                ? `${
                    userAboutData?.general_information?.birth_date ?? `__`
                  } tháng ${
                    userAboutData.general_information?.birth_month ?? `__`
                  }, ${
                    userAboutData?.general_information?.birth_year ?? `____`
                  }`
                : userSelectedData?.email}
            </Typography>
          }
        >
          <ListItemText
            primary={
              conversation?.type !== 'chat_page' ? `Ngày sinh: ` : 'Email: '
            }
          />
        </ListItem>
        <ListItem
          disableGutters
          secondaryAction={
            (
              <Typography sx={{ fontSize: '14px', opacity: 0.8 }}>
                {conversation?.type !== 'chat_page'
                  ? `${
                      userAboutData?.general_information?.gender === 'male'
                        ? 'Nam'
                        : userAboutData?.general_information?.gender ===
                          'female'
                        ? 'Nữ'
                        : 'Chưa rõ'
                    }  `
                  : userSelectedData?.phone_number ?? 'chưa có thông tin'}
              </Typography>
            ) ?? 'chưa có thông tin'
          }
        >
          <ListItemText
            primary={
              conversation.type !== 'chat_page'
                ? `Giới tính: `
                : 'Số điện thoại: '
            }
          />
        </ListItem>
        <ListItem
          disableGutters
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'start',
            justifyContent: 'space-between'
          }}
        >
          <Typography sx={{ fontSize: '16px', width: '200px' }}>
            Địa chỉ:{' '}
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              opacity: 0.8,
              display: 'contents',
              alignContent: 'end'
            }}
          >
            {conversation?.type !== 'chat_page'
              ? userAboutData?.general_information?.address ??
                'chưa có thông tin'
              : userSelectedData?.address ?? 'chưa có thông tin'}
          </Typography>
        </ListItem>
        {conversation?.type === 'chat_page' && (
          <ListItem
            disableGutters
            style={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Typography sx={{ fontSize: '16px', width: '200px' }}>
              Mô tả:{' '}
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',

                opacity: 0.8,
                display: 'contents',
                alignContent: 'end'
              }}
            >
              {userSelectedData?.description ?? 'chưa có thông tin'}
            </Typography>
          </ListItem>
        )}
      </List>
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth={'xs'}
        fullWidth={true}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600
            }}
          >
            Thông tin tài khoản
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            height: '480px'
          }}
          className={classes.customScrollbar}
        >
          <BannerAndAvatarUserComponent />

          <Divider />
          <InformationPersonal />
          <Divider />
        </DialogContent>
      </Dialog>
    </div>
  );
}
