import axios, { AxiosRequestConfig } from 'axios';
import { USER_TOKEN } from 'src/util/config';
import { BASE_ROOT } from 'src/util/config';

const fetchApi = async (endPoint, method, params, data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data
  };

  let response = await axios(configs);
  return response;
};

const fetchApis = async (endPoint, method, params, data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v2/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data
  };

  let response = await axios(configs);
  return response;
};
const fetchApiRequest = async (endPoint, method, params, data, source) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    [`${source ? 'cancelToken' : ''}`]: source ? source.token : '',
    params,
    data
  };

  let response = await axios(configs);
  return response;
};

export const createUpdatePostApi = async (data: Object, id: any) => {
  return fetchApi(
    `statuses${id ? '/' + id : ''}`,
    id ? 'patch' : 'post',
    {},
    data
  );
};

export const getListPostApi = async (params: Object) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/timelines/home`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const getListMomentApi = async (params: Object) => {
  return fetchApi('timelines/moment', 'GET', params, {});
};

export const getListPostFriendDiscoverApi = async (params: Object) => {
  return fetchApi('timelines/friend', 'GET', params, {});
};

export const getListWatchApi = async (params: Object) => {
  return fetchApi('timelines/watch', 'GET', params, {});
};

export const getDiscoverMomentApi = async (params: Object) => {
  return fetchApi('suggestions/moment', 'GET', params, {});
};

export const getDiscoverWatchApi = async (params: Object) => {
  return fetchApi('suggestions/watch', 'GET', params, {});
};

export const postWatchHistoriesApi = async (data: Object) => {
  return fetchApi('watch_histories', 'POST', {}, data);
};

export const getMentionsPost = async id => {
  return fetchApi(`statuses/${id}/mentions`, 'GET', null, {});
};

export const getListPostTagApi = async (tag, params) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/timelines/tag/${tag}`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const getPreviewUrlApi = async (params: Object) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/fetch-url/grab`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const getPostMediaApi = async (id: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/media/${id}`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const getListPostAccountApi = async (id: any, params: Object) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/accounts/${id}/statuses`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params: {
      ...params,
      id: null
    }
  };

  let response = await axios(configs);
  return response;
};

export const deletePostApi = async (id: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/statuses/${id}`,
    method: 'delete',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const reactionPostApi = async (id: any, data: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/statuses/${id}/favourite`,
    method: 'post',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    data
  };

  let response = await axios(configs);
  return response;
};

export const getPostDetailApi = async (id: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/statuses/${id}`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const hiddenPostDetailApi = async (id: any, data: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/statuses/${id}`,
    method: 'patch',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    data
  };

  let response = await axios(configs);
  return response;
};

export const unReactionPostApi = async (id: any, data: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/statuses/${id}/unfavourite`,
    method: 'post',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    data
  };

  let response = await axios(configs);
  return response;
};

export const pinPostApi = async (id: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/statuses/${id}/pin`,
    method: 'post',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const unPinPostApi = async (id: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/statuses/${id}/unpin`,
    method: 'post',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const reblogPostApi = async (id: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/statuses/${id}/reblog`,
    method: 'post',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const getReactionById = async (id: string, data: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/statuses/${id}/reaction_post`,
    method: 'post',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    data
  };
  let response = await axios(configs);
  return response;
};
export const getReblogPostApi = async (id: any, params) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/statuses/${id}/reblog`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const getBookmarkPostApi = async params => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/bookmarks`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const bookmarkPostApi = async (id: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/statuses/${id}/bookmark`,
    method: 'post',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const unbookmarkPostApi = async (id: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/statuses/${id}/unbookmark`,
    method: 'post',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const mutePostApi = async (id: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/statuses/${id}/mute`,
    method: 'post',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const unmutePostApi = async (id: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/statuses/${id}/unmute`,
    method: 'post',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const embedPostApi = async (data: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/web/embed`,
    method: 'post',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    data
  };

  let response = await axios(configs);
  return response;
};

export const votePostApi = async (id: any, data: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/polls/${id}/votes`,
    method: 'POST',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    data
  };

  let response = await axios(configs);
  return response;
};

export const voteChangeApi = async (id: any, data: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/polls/${id}/votes/123`,
    method: 'PATCH',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    data
  };

  let response = await axios(configs);
  return response;
};

export const reportPostApi = async (data: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/reports`,
    method: 'post',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    data
  };

  let response = await axios(configs);
  return response;
};

export const getPeopleVotePostApi = async (id: any, params: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/polls/${id}/list_account`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const getCommentPostApi = async (id: any, params: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/comments/${id}`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const getReactionPeopleApi = async (id: any, params: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/statuses/${id}/favourited_by`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const getLocationApi = async (params: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/places`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const getCityApi = async (params: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/provinces`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};
export const getCityApiUpdate = async (params: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/list_provinces`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};
export const getEventLifeApi = async () => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/life_event_categories`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const getGifApi = async (params: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/gifts`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const getBgColorApi = async () => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/status_background_color`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const createMediaMastodon = async (params: any) => {
  return fetchApi(`sync_media`, 'POST', null, params);
};

export const getListPostTags = async (idUser: any, params: any) => {
  return fetchApi(`/accounts/${idUser}/mentions_statuses`, 'GET', params, null);
};
export const updatePostTags = async (idUser: any, params: any) => {
  return fetchApi(
    `/accounts/${idUser}/update_mentions_statuses`,
    'PATCH',
    params,
    null
  );
};
export const crudBookmarkApi = async (
  type,
  params: any,
  data: any,
  id,
  source: any
) => {
  return fetchApiRequest(
    `bookmarks${type === 'POST' || type === 'GET' ? '' : '/' + id}`,
    type,
    params,
    data,
    source
  );
};

export const crudBookmarkCollectionApi = async (type, id, data: any) => {
  return fetchApi(
    `bookmark_collections${type === 'POST' || type === 'GET' ? '' : '/' + id}`,
    type,
    null,
    data
  );
};

export const searchVideos = async (key: any, type: any) => {
  return fetchApis(
    `search?q=${key}&type[]=statuses&status_type=${type}`,
    'GET',
    null,
    null
  );
};
export const getSearchMoment = async (key: any) => {
  return fetchApis(
    `search?q=${key}&type[]=statuses&type[]=accounts&type[]=pages&type[]=groups&status_type=moment`,
    'GET',
    null,
    null
  );
};

export const searchGroupName = async (key: any, params: any) => {
  return fetchApis(`search?q=${key}&type=groups`, 'GET', params, null);
};

export const searchPostInGroup = async (key: any, params: any) => {
  return fetchApis(
    `search?q=${key}&type[]=statuses&in_group=true`,
    'GET',
    params,
    null
  );
};
export const getDetailBookmarkCollection = async (id: any) => {
  return fetchApi(`bookmark_collections/${id}`, 'GET', null, null);
};

export const hideTagUser = async (id: any) => {
  return fetchApi(`statuses/${id}/unmentions`, 'PATCH', null, null);
};

export const getListOfUsersCommentingOnPosts = async (id: any) => {
  return fetchApi(`statuses/${id}/comment_display_name`, 'GET', null, null);
};

export const getListWatchShows = async () => {
  return fetchApi('suggestions/series', 'GET', null, null);
};
export const getListWatchShowFriend = async () => {
  return fetchApi('series_friend_follow', 'GET', null, null);
};
export const getDetailWatchShow = async (id: any) => {
  return fetchApi(`series/${id}`, 'GET', null, null);
};
export const unFollowSeries = async (id: any) => {
  return fetchApi(`series/${id}/unfollow`, 'POST', null, null);
};
export const FollowSeries = async (id: any) => {
  return fetchApi(`series/${id}/follow`, 'POST', null, null);
};

export const shortcutListApi = async (method, data) => {
  return fetchApi(`shortcuts`, method, null, data);
};

export const getReactionsLogs = async (params: any) => {
  return fetchApi(`reactions_logs`, 'GET', params, null);
};

export const getStatusLogs = async (params: any) => {
  return fetchApi(`status_logs`, 'GET', params, null);
};
export const getBookMarkCollection = async (params: any) => {
  return fetchApi(`bookmark_collections_logs`, 'GET', params, null);
};

export const getStatusBookMarkLog = async (params: any) => {
  return fetchApi(`status_bookmarks_logs`, 'GET', params, null);
};

export const deleteBookMarkCollection = async (id: string) => {
  return fetchApi(`bookmark_collections/${id}`, 'DELETE', null, null);
};
export const getPageLogs = async (params: any) => {
  return fetchApi(`pages_logs`, 'GET', params, null);
};
