import {
  Button,
  CardMedia,
  Theme,
  Typography,
  CircularProgress
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import { updateGroupApi } from 'src/apis/socialGroup';
import { getGroupDetailReq } from 'src/store/action/socialGroupAction';
import {
  getColorImageReq,
  saveLinkBanner,
  saveFileBanner,
  resetRequest
} from 'src/store/action/settingAction';

import user_banner from 'src/assets/images/user_banner.png';
import page_default from 'src/assets/images/page_default.png';

import MenuAction from 'src/components/Menu';

import DialogLayoutProfile from 'src/components/Dialog/DialogEditProfile/DialogLayoutProfile';
import AvatarBanner from 'src/components/Dialog/DialogEditProfile/AvatarBanner';
import Cropper from 'react-easy-crop';
import getCroppedImg from 'src/components/Dialog/DialogEditProfile/cropImage';
import DialogPreviewMedia from 'src/components/Dialog/DialogPreviewMedia';
import avatarDefault from 'src/assets/images/avatar_default.jpg';
import { getPostDetailApi, getPostMediaApi } from 'src/apis/socialPost.api';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: '100%'
      },
      rootPage: {
        width: '100%',
        maxHeight: '500px',
        height: '100%'
      },
      rootEvent: {
        width: '100%',
        maxHeight: '350px',
        height: '100%'
      },
      rootCart: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',

        backgroundColor: 'transparent',
        border: 'none',
        '&:hover': {
          cursor: 'pointer'
        }
      },
      media: {
        maxWidth: '100%',
        height: '348.141px',
        width: '100%',
        borderRadius:
          '0px 0px max(0px, min(8px, ((100vw - 4px) - 100%) * 9999)) max(0px, min(8px, ((100vw - 4px) - 100%) * 9999)) / 0px 0px 8px 8px'
      },
      mediaPage: {
        maxWidth: '100%',
        width: '100%',
        paddingTop: '38.0488%',
        borderRadius:
          '0px 0px max(0px, min(8px, ((100vw - 4px) - 100%) * 9999)) max(0px, min(8px, ((100vw - 4px) - 100%) * 9999)) / 0px 0px 8px 8px'
      },
      rootBtnEditBanner: {
        position: 'absolute',
        bottom: 10,
        right: 10
      },
      btnEditBanner: {
        backgroundColor: 'rgba(0, 0, 0, 0.6) !important',
        textTransform: 'none !important' as any,
        fontWeight: 'bold !important',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.5) !important'
        }
      },
      wrapHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        '& .reactEasyCrop_Container': {
          overflow: 'hidden',
          height: '500px',
          position: 'relative',
          borderRadius:
            '0px 0px max(0px, min(8px, ((100vw - 4px) - 100%) * 9999)) max(0px, min(8px, ((100vw - 4px) - 100%) * 9999)) / 0px 0px 8px 8px',
          width: '100%'
        },
        '& .reactEasyCrop_CropAreaGrid': {
          maxHeight: '500px',
          height: 'calc(100% - 150px)',
          [theme.breakpoints.down('xl')]: {
            minHeight: '370px'
          },
          minHeight: '450px',
          width: '100% !important',
          '&::before': {
            border: '0px !important'
          },
          '&::after': {
            border: '0px !important'
          }
        },
        '& .reactEasyCrop_CropArea': {
          color: 'transparent'
        }
      }
    }),
  { index: 1 }
);

interface Props {
  profile?: any;
  page?: any;
  action?: any;
  bgColor?: any;
  loadingCard?: any;
  typeCheck?: string;
}

export default function Index(props: Props) {
  let { profile, page, action, bgColor, loadingCard, typeCheck } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [preview, setPreview] = React.useState<any>(null);
  const [keyTabAvatar, setKeyTabAvatar] = React.useState('');
  const [openAvatar, setOpenAvatar] = React.useState(false);
  const [files, setFiles] = React.useState<any>([]);
  const [typeForm, setTypeForm] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [listMenuProfile, setListMenuProfile] = React.useState<any>([]);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState<any>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
  const imageUpload = useSelector((state: any) => state?.settingReducer);
  const meSelected = useSelector((state: any) => state.meReducer.info);
  const userInfor = useSelector((state: any) => state?.userReducer.infor);
  const [open, setOpen] = React.useState(false);
  const [mediaSelected, setMediaSelected] = React.useState<any>(null);
  const [indexMedia, setIndexMedia] = React.useState(0);
  const [postMedia, setPostMedia] = React.useState<any>(null);
  const [changePosition, setChangePosition] = React.useState<any>(false);
  const rolePreviewEdit = useSelector(
    (state: any) => state.pageReducer.rolePreviewEdit.role
  );
  const [currentParams, setCurrentParams] = React.useState<any>('');
  const roleAdminGroup = useSelector(
    (state: any) => state.groupReducer.groupRelationShip
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCurrentParams(profile?.username ?? profile?.id);
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDrop: async (acceptedFiles: any) => {
      setPreview(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
      if (openAvatar) {
        if (page && page === 'user' && action) {
          action({
            header: acceptedFiles[0]
          });
        } else if (page && page === 'page' && action) {
          action({
            banner: acceptedFiles[0]
          });
        } else {
          createUpdateGroupAction({
            cover_image: acceptedFiles[0]
          });
        }
      } else {
        setFiles(
          acceptedFiles.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          )
        );
        handleCloseMenu();
      }
    }
  });

  let listMenu = [
    {
      key: 'image-page-group',
      icon: 'fa-light fa-image',
      label:
        page === 'page'
          ? 'Chọn từ ảnh của trang'
          : page === 'event'
          ? 'Chọn từ ảnh của sự kiện'
          : 'Chọn từ ảnh của nhóm',
      action: () => {
        setOpenAvatar(true);
        handleCloseMenu();
        setTypeForm(page);
      }
    },
    {
      key: 'image-user',
      icon: 'fa-light fa-folder-user',
      label: 'Chọn từ ảnh của bạn',
      action: () => {
        setTypeForm('');
        setOpenAvatar(true);
        handleCloseMenu();
      }
    },
    {
      key: 'image-position',
      icon: 'fa-light fa-up-down-left-right',
      label: 'Đặt lại vị trí',
      action: () => {
        setChangePosition(true);
        handleCloseMenu();
      }
    },
    {
      key: 'image-upload',
      icon: 'fa-light fa-upload',
      label: (
        <Typography {...getRootProps()} style={{ fontWeight: 500 }}>
          Tải ảnh lên từ thiết bị <input {...getInputProps()} />
        </Typography>
      ),
      action: () => {}
    }
  ];

  useEffect(() => {
    if (
      !(currentParams === profile?.username || currentParams === profile?.id)
    ) {
      dispatch(resetRequest());
      setFiles([]);
    }
  }, [profile?.id]);

  useEffect(() => {
    if (profile?.banner?.id && changePosition) {
      setFiles([{ ...profile.banner, preview: profile.banner.url }]);
      setChangePosition(prev => !prev);
    }
  }, [changePosition, JSON.stringify(profile?.banner)]);

  const createUpdateGroupAction = async data => {
    await updateGroupApi(profile.id, data);
    dispatch(getGroupDetailReq(profile.id, ''));
  };

  const renderImage = page => {
    if (page === 'page') {
      return profile?.banner
        ? profile?.banner?.show_url ?? profile?.banner?.url
        : profile?.banner_url ?? page_default;
    } else if (
      page === 'group' ||
      page === 'user' ||
      page === 'groupActivitiesUser'
    ) {
      return preview ? preview[0]?.preview : null;
    } else if (
      page === 'grow' ||
      page === 'recruit' ||
      page === 'course' ||
      page === 'event'
    ) {
      return profile?.banner?.url
        ? profile?.banner.url
        : profile?.banner?.show_url
        ? profile?.banner.show_url
        : profile?.banner?.file
        ? profile?.banner?.file?.preview
        : profile?.banner?.preview_url
        ? profile?.banner.preview_url
        : avatarDefault;
    }
  };

  const renderBackground = (profile: any, page: string, upload?: boolean) => {
    return files.length ? (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          minHeight: '48.0488vh'
        }}
      >
        <div
          className={classes.wrapHeader}
          style={{ opacity: loadingCard ? '0.5' : '1' }}
        >
          {upload ? (
            <div
              style={{ position: 'relative', height: '100%', width: '100%' }}
            >
              <CardMedia
                className={classes.mediaPage}
                image={files[0].preview}
                style={{
                  paddingTop:
                    page === 'event'
                      ? '28%'
                      : page === 'grow' ||
                        page === 'recruit' ||
                        page === 'course'
                      ? '38%'
                      : '38.0488%',
                  maxWidth: page === 'event' ? '900px' : '100%'
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  alignItems: 'center',
                  justifyContent: 'center',
                  top: '100px',
                  width: '100%',
                  height: '100%'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <CircularProgress color="inherit" />
                  <Typography style={{ fontSize: 20 }}>Đang đăng</Typography>
                </div>
              </div>
            </div>
          ) : files.length ? (
            <Cropper
              image={files[0].preview}
              crop={crop}
              zoom={zoom}
              aspect={7.885 / 3}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              zoomWithScroll={false}
              objectFit="horizontal-cover"
            />
          ) : null}
        </div>
      </div>
    ) : (
      <CardMedia
        onClick={handleSelectedBanner}
        className={classes.mediaPage}
        image={renderImage(page)}
        style={{
          paddingTop:
            page === 'event'
              ? '28%'
              : page === 'grow' || page === 'recruit' || page === 'course'
              ? '38%'
              : '38.0488%',
          maxWidth: page === 'event' ? '900px' : '100%'
        }}
      />
    );
  };

  const showCroppedImage = React.useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        files[0]?.preview,
        croppedAreaPixels
      );
      let newFile = files[0];
      Object.defineProperty(newFile, 'avatar_show_url', {
        value: croppedImage,
        writable: true
      });
      setFiles([newFile]);
      dispatch(saveLinkBanner(croppedImage, page));
    } catch (e) {
      console.error(e);
    }
  }, [JSON.stringify(croppedAreaPixels)]);

  const getPostMediaDetail = async () => {
    let res = await getPostMediaApi(mediaSelected);

    if (res.status === 200) {
      const postId = res.data.id;
      let response = await getPostDetailApi(postId);
      if (response.status === 200) {
        setPostMedia({
          ...res.data,
          comment_moderation: response.data.comment_moderation,
          off_comment: response.data.off_comment
        });
      }
    }
  };

  const handleSelectedBanner = () => {
    if (profile?.banner?.id) {
      setMediaSelected(profile?.banner?.id);
    }
  };

  React.useEffect(() => {
    if (mediaSelected) {
      setOpen(true);

      getPostMediaDetail();
    }
  }, [mediaSelected]);

  React.useEffect(() => {
    if (profile && Object.keys(profile).length) {
      setPreview([
        {
          preview: profile.banner
            ? profile.banner.show_url ?? profile.banner.url
            : profile.banner_url ?? user_banner
        }
      ]);
    }
  }, [JSON.stringify(profile)]);

  React.useEffect(() => {
    if (page === 'page' || page === 'group') {
      setListMenuProfile(listMenu);
    } else {
      setListMenuProfile(listMenu.slice(1));
    }
  }, [page]);

  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  React.useEffect(() => {
    if (croppedAreaPixels) {
      showCroppedImage();
    }
  }, [JSON.stringify(croppedAreaPixels)]);

  React.useEffect(() => {
    if (Object.keys(imageUpload).length === 0) {
      setFiles([]);
    }
  }, [JSON.stringify(imageUpload)]);

  React.useEffect(() => {
    if (files.length === 0) {
      if (Object.keys(profile).length > 0) {
        setPreview([
          {
            preview: profile.banner?.show_url
              ? profile.banner.show_url
              : user_banner
          }
        ]);
      }
    }

    if (openAvatar && files.length > 0) {
      setOpenAvatar(false);
    }

    if (files.length) {
      if (!files[0].id) {
        dispatch(getColorImageReq(files, null, window.location.href));
      } else {
        dispatch(saveFileBanner(files[0]));
      }
    }
  }, [files.length]);

  return (
    <div
      style={{
        position: 'relative',
        background: bgColor?.background,
        display: 'flex',
        justifyContent: 'center',
        height: page !== 'page' && page !== 'group' ? '500px' : 'auto'
      }}
      className={
        page === 'event' ||
        page === 'grow' ||
        page === 'recruit' ||
        page === 'course'
          ? classes.rootEvent
          : page !== 'page'
          ? classes.root
          : classes.rootPage
      }
    >
      {imageUpload.isLoading && page !== 'user' ? (
        <div
          className={classes.rootCart}
          style={{
            maxWidth: page === 'group' ? 1250 : 1320
          }}
        >
          {renderBackground(profile, page, true)}
        </div>
      ) : (
        <div
          className={classes.rootCart}
          style={{
            maxWidth: page === 'group' ? 1250 : 1320
          }}
        >
          {renderBackground(profile, page, false)}
          {(meSelected?.id === userInfor?.id ||
            (page === 'group' && roleAdminGroup?.admin) ||
            (page === 'page' && rolePreviewEdit === 'admin')) &&
            typeCheck !== 'preview' && (
              <div className={classes.rootBtnEditBanner}>
                <Button onClick={handleClick} className={classes.btnEditBanner}>
                  <i
                    className="fas fa-camera-alt"
                    style={{ marginRight: '5px', color: '#fff' }}
                  ></i>
                  <p style={{ color: '#fff' }}>Chỉnh sửa ảnh bìa</p>
                </Button>
              </div>
            )}
        </div>
      )}

      {open && postMedia && Object.keys(postMedia).length ? (
        <DialogPreviewMedia
          open={open}
          setOpen={setOpen}
          indexMedia={indexMedia}
          post={{
            media_attachments: postMedia?.media_attachments ?? []
          }}
          setIndexMedia={setIndexMedia}
          setMediaSelected={setMediaSelected}
          postMedia={postMedia}
          setPostMedia={setPostMedia}
        />
      ) : null}

      {anchorEl && (
        <MenuAction
          anchorEl={anchorEl}
          openPopper={openPopper}
          setOpenPopper={setOpenPopper}
          handleCloseMenu={handleCloseMenu}
          listMenu={listMenuProfile}
        />
      )}
      {openAvatar && (
        <DialogLayoutProfile
          open={openAvatar}
          keyTab={keyTabAvatar}
          handleClose={() => {
            setOpenAvatar(false);
            setKeyTabAvatar('');
            setFiles([]);
          }}
          type="banner"
          files={files}
          title="Cập nhật ảnh bìa"
          children={
            <AvatarBanner
              type="banner"
              typeForm={typeForm}
              setKeyTabAvatar={setKeyTabAvatar}
              files={files}
              setFiles={setFiles}
            />
          }
        />
      )}
    </div>
  );
}
