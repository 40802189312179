import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery
} from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import React, { useState } from 'react';
import ButtonCustom from 'src/components/Button/Button';
import ButtonInherit from 'src/components/Button/ButtonInherit';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';

import { groupCategoryApi } from 'src/apis/socialGroup';
import { getCalendarGrowApi } from 'src/apis/socialGrow.api';
import avatarDefault from 'src/assets/images/avatar_default.jpg';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import DialogVerificationUser from 'src/components/Dialog/DialogVerificationUser';
import InputSearch from 'src/components/Input/SocialInput/InputSearch';
import ListButton from 'src/components/List/ListButton';
import Loading from 'src/components/Loading/Loading';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import StepperSocial from 'src/components/Stepper';
import { buttonColor, buttonColorHover } from 'src/constants/styles';
import SearchGlobalLeft from 'src/pages/SeachGlobal/SeachGlobalLeft';
import {
  getGrowDetailRequest,
  resetGrow
} from 'src/store/action/socialGrowAction';
import { getListMyPageReq } from 'src/store/action/socialPageAction';
import GrowCreateUpdate from '../GrowCreateUpdate/GrowCreateUpdate';
import IconFavorite from 'src/components/IconFavorite';
const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      root: {
        height: '100%',
        width: '310px',
        backgroundColor: theme.palette.background.primary,
        position: 'fixed',
        boxShadow: '1px 0px 5px 0.4px rgb(0 0 0 / 10%)'
      },
      headerPage: {
        minHeight: 50,
        margin: '10px 0px 0px'
      },
      footerPage: {
        position: 'absolute',
        bottom: '55px',
        left: 0,
        width: '100%',
        zIndex: 1200
      },
      rootPage: {
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        '&:hover': {
          overflowY: 'auto'
        },
        '&:hover&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#bdbdbd',
          borderRadius: 10
        }
      },
      notData: {
        paddingLeft: '10px'
      },
      highlight: {
        backgroundColor: theme.palette.background.rgba
      },
      button: {
        marginTop: '12px !important',
        width: '70% !important'
      }
    }),
  { index: 1 }
);

const GrowLeft = () => {
  const classes = useStyles();
  const history = useHistory();
  const match: any = useRouteMatch();
  const dispatch = useDispatch();
  const theme: any = useTheme();
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const growInfo = useSelector((state: any) => state.growReducer.info);
  const [keyword, setKeyword] = React.useState<any>('');

  const [indexCategories, setIndexCategories] = React.useState<any>(null);

  const [openDialogVerificationUser, setOpenDialogVerificationUser] =
    React.useState<any>(false);
  const pagesAdmin = useSelector(
    (state: any) => state.pageReducer.my_pages
  )?.map((el: any) => ({
    id: el.id,
    username: el.username,
    avatar_icon: el.avatar_media
      ? el.avatar_media.show_url ??
        el.avatar_media.preview_url ??
        el.avatar_media.url
      : avatarDefault,
    action: () => {
      let data = {
        page_owner_id: el.id,
        title: el.title,
        avatar_icon: el.avatar_media
          ? el.avatar_media.show_url ??
            el.avatar_media.preview_url ??
            el.avatar_media.url
          : avatarDefault,
        description: 'Bên tổ chức - Trang của bạn'
      };
      setGrowOwner(data);
    },
    label: el.title,
    styleLabel: { width: '228px' }
  }));
  const [keyParams, setKeyParams] = React.useState<any>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<any>('home');
  const hasMore = useSelector((state: any) => state.pageReducer.hasMore);
  const matchesCreate = useMediaQuery('(max-width:1000px)');
  const [categories, setCategories] = React.useState<any>([]);
  const [createGrow, setCreateGrow] = useState(false);
  const [updateGrow, setUpdateGrow] = useState<any>(false);
  const [growOwner, setGrowOwner] = React.useState<any>({});
  const [listGrowMe, setListGrowMe] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<any>(true);
  const [listDiscoverGrow, setListDiscoverGrow] = React.useState<any>([]);
  const [checkCreateUpdate, setCheckCreateUpdate] = React.useState<any>(false);
  const [confirmIdentity_verifications, setConfirmIdentity_verifications] =
    React.useState<any>(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  const [activeStep, setActiveStep] = React.useState(0);
  const [checkStep, setCheckStep] = React.useState<any>({
    step1: false,
    step2: false,
    step3: false,
    step4: false
  });
  // const [openDialogMoreRole, setOpenDialogMoreRole] = React.useState(false);
  const steps = 5;
  const listLinkFileTerms = [
    'https://s3-hn-2.cloud.cmctelecom.vn/sn-web/helper/HO%CC%9B%CC%A3P%20%C4%90O%CC%82%CC%80NG%20HO%CC%9B%CC%A3P%20TA%CC%81C%20KINH%20DOANH%20.docx',
    'https://s3-hn-2.cloud.cmctelecom.vn/sn-web/helper/%C4%90e%CC%82%CC%80%20A%CC%81n%20va%CC%80%20Ba%CC%89n%20Cam%20Ke%CC%82%CC%81t%20Ma%CC%82%CC%83u.docx'
  ];
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const fetchCategories = async () => {
    let response = await groupCategoryApi();
    if (response.status === 200) {
      setCategories(response.data);
    }
  };
  const downLoadFile = async (url: string, index) => {
    try {
      let fileName: string =
        index === 1
          ? 'Đề án và bản cam kết mẫu'
          : 'HỢP ĐỒNG HỢP TÁC KINH DOANH';

      const response = await fetch(url);
      const blob = await response.blob(); //object container file docx
      const aTag = document.createElement('a');
      const objectURL = URL.createObjectURL(blob); //save url temporary

      aTag.href = objectURL;
      aTag.setAttribute('download', fileName);

      document.body.appendChild(aTag);
      aTag.click(); //download
      document.body.removeChild(aTag); //remove element a

      URL.revokeObjectURL(objectURL); //release URL temporary (release memories)
    } catch (error) {}
  };
  React.useEffect(() => {
    fetchCategories();
  }, []);

  React.useEffect(() => {
    if (match.path === PATHS.GROW_CREATE) {
      dispatch(resetGrow());
    }
  }, [match.path]);
  React.useEffect(() => {
    setGrowOwner({
      id: meInfo.id,
      title: meInfo.display_name,
      avatar_icon: meInfo.avatar_media?.show_url
        ? meInfo.avatar_media.show_url
        : avatarDefault,
      description: 'Người tổ chức - Trang cá nhân của bạn'
    });
  }, [JSON.stringify(meInfo)]);

  const fetchCalendarGrow = async (params: any, setData: any) => {
    let response = await getCalendarGrowApi(params);
    if (response.status === 200) {
      setData(response.data);
      setIsLoading(false);
    }
  };

  const listMenuGrow = [
    {
      id: 'grow_home',
      title: 'Khám phá',
      styleTitle: { fontWeight: '600' },
      styleButton: { width: '96%' },
      icon: (
        <i
          className="fa-light fa-calendar-days"
          style={{ fontSize: '20px' }}
        ></i>
      ),
      action: () => {
        history.push(PATHS.GROW);
      },
      style: { height: 36, width: 36 },
      position: 'top'
    },
    {
      id: 'calendar',
      title: 'Dự án của bạn',
      styleTitle: { fontWeight: '600' },
      styleButton: { marginTop: '10px', borderTopRightRadius: '18px' },
      icon: <i className="fa-light fa-user" style={{ fontSize: '20px' }}></i>,
      action: () => {
        history.push('/grows/hosting');
      },
      accordion: true,
      style: { height: 36, width: 36 },
      position: 'top',
      itemChildren: [
        {
          id: 'hosting',
          title: 'Tổ chức',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-house"
            ></i>
          ),
          action: () => {
            history.push(`/grows/hosting`);
          }
        },
        {
          id: 'going',
          title: 'Đã ủng hộ',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-circle-check"
            ></i>
          ),
          action: () => {
            history.push(`/grows/going`);
          }
        },
        {
          id: 'invites',
          title: 'Lời mời',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-envelope"
            ></i>
          ),
          action: () => {
            history.push(`/grows/invites`);
          }
        },
        {
          id: 'interested',
          title: 'Quan tâm',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-star"
            ></i>
          ),
          action: () => {
            history.push(`/grows/interested`);
          }
        },
        {
          id: 'past',
          title: 'Dự án đã gọi vốn',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-arrow-rotate-left"
            ></i>
          ),
          action: () => {
            history.push(`/grows/past`);
          }
        }
      ]
    },
    {
      id: 'infor_bonus',
      title: 'Thông tin thêm',
      styleTitle: { fontWeight: '600' },
      styleButton: { marginTop: '10px', borderTopRightRadius: '18px' },
      icon: (
        <i className="fa-solid fa-paperclip" style={{ fontSize: '20px' }}></i>
      ),
      action: () => {
        history.push('/grows/calendar?q=going');
      },
      accordion: true,
      style: { height: 36, width: 36 },
      position: 'top',
      itemChildren: [
        {
          id: 'policy_grows_community',
          title: 'Quy chế gọi vốn',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          action: () => {
            history.push(`/terms_item/policy_grows_community?helpref=hc_enav`);
          }
        },
        {
          id: 'deal_grows_community',
          title: 'Thoả thuận gọi vốn',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          action: () => {
            history.push(`/terms_item/deal_grows_community?helpref=hc_enav`);
          }
        },
        {
          id: 'someFiles',
          title: 'Các biểu mẫu',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-file"
            ></i>
          ),
          action: () => {
            listLinkFileTerms.forEach((url, index) => {
              downLoadFile(url, index);
            });
          }
        }
      ]
    }
  ];

  React.useEffect(() => {
    let { id, key, tab } = match?.params;
    switch (match.path) {
      case PATHS.GROW:
        setSelectedIndex('grow_home');
        break;
      case PATHS.GROW_MINE: {
        setKeyParams('key');
        if (id) {
          setSelectedIndex(id);
        }
        if (key) {
          setSelectedIndex(key);
        }
        if (tab) {
          setSelectedIndex(tab);
        }
        break;
      }
      case PATHS.GROW_CATEGORY: {
        setSelectedIndex(id);
        break;
      }
      case PATHS.GROW_CREATE: {
        setSelectedIndex('create_grow');
        break;
      }
      case PATHS.GROW_NOTI_IDENTITY: {
        setSelectedIndex('indenity');
        break;
      }
      case PATHS.GROW_DETAIL: {
        setSelectedIndex('');
        break;
      }
    }
  }, [match.path, JSON.stringify(match.params)]);

  React.useEffect(() => {
    if (match.params.key === 'edit') {
      setActiveStep(0);
    }
  }, []);
  React.useEffect(() => {
    if (
      (match.path === PATHS.GROW_MINE || match.path === PATHS.GROW_DETAIL) &&
      !listGrowMe.length &&
      !listDiscoverGrow.length
    ) {
      fetchCalendarGrow(
        {
          limit: 5,
          status: 'approved',
          time: 'upcoming'
        },
        setListGrowMe
      );
      fetchCalendarGrow(
        {
          exclude_current_user: 'true',
          status: 'approved',
          time: 'upcoming',
          limit: 5
        },
        setListDiscoverGrow
      );
    }
  }, [match.path]);

  const renderListGrow = (grow: any) => {
    return (
      <Card
        sx={{
          boxShadow: 'none !important',
          borderRadius: '10px !important'
        }}
      >
        <CardActionArea
          onClick={() => {
            dispatch(getGrowDetailRequest(grow.id));
            history.push(`/grow/${grow.id}/about`);
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CardMedia
              component="img"
              height="50px"
              sx={{
                maxWidth: '80px',
                marginLeft: '12px',
                borderRadius: '8px'
              }}
              image={grow?.banner ? grow.banner.preview_url : ''}
            />
            <CardContent
              sx={{
                width: '100%',
                paddingRight: '8px'
              }}
            >
              <Typography fontSize={12} color="#f3425f">
                {grow?.start_time}
              </Typography>
              <Typography gutterBottom fontSize={16} fontWeight={600}>
                {grow?.title}
              </Typography>
            </CardContent>
          </Box>
        </CardActionArea>
      </Card>
    );
  };

  const loadActivity = maxId => {
    dispatch(
      getListMyPageReq({
        max_id: maxId,
        limit: 10
      })
    );
  };

  const funcLoad = () => {
    let pageLast = [...pagesAdmin].pop();
    let maxId = pageLast?.id;
    loadActivity(maxId);
  };

  const renderHeader = () => {
    let { key, id } = match.params;

    switch (match.path) {
      case PATHS.GROW_CREATE: {
        switch (key) {
          case 'online':
            return (
              <Grid container>
                <Grid container item xs={12}>
                  <IconButton
                    sx={{
                      backgroundColor: '#999999',
                      color: '#fff',
                      height: 35,
                      width: 35,
                      margin: '0 5px'
                    }}
                    onClick={() => history.push(PATHS.GROW_CREATE)}
                  >
                    <ClearIcon />
                  </IconButton>
                  &nbsp;
                  <Typography style={{ fontWeight: 'bold' }} variant="h5">
                    Chi tiết dự án
                  </Typography>
                </Grid>
              </Grid>
            );
          case 'offline':
            return (
              <Grid container>
                <Grid container item xs={12}>
                  <IconButton
                    sx={{
                      backgroundColor: '#999999',
                      color: '#fff',
                      height: 35,
                      width: 35,
                      margin: '0 5px'
                    }}
                    onClick={() => history.push(PATHS.GROW)}
                  >
                    <ClearIcon />
                  </IconButton>
                  &nbsp;
                  <Typography style={{ fontWeight: 'bold' }} variant="h5">
                    Chi tiết dự án
                  </Typography>
                </Grid>
              </Grid>
            );
          default:
            return (
              <Grid container>
                <Grid container item xs={12}>
                  <IconButton
                    sx={{
                      backgroundColor: '#b4b3b3',
                      color: '#fff',
                      height: 35,
                      width: 35,
                      margin: '0 5px'
                    }}
                    onClick={() => history.push(PATHS.GROW)}
                  >
                    <ClearIcon />
                  </IconButton>
                  &nbsp;
                  <Typography style={{ fontWeight: 'bold' }} variant="h5">
                    Tạo dự án gọi vốn
                  </Typography>
                </Grid>
              </Grid>
            );
        }
      }
      case PATHS.GROW_DETAIL: {
        switch (id) {
          case 'search':
            return null;
        }
        switch (key) {
          case 'edit':
            return (
              <Grid container>
                <Grid container item xs={12}>
                  <IconButton
                    sx={{
                      backgroundColor: '#999999',
                      color: '#fff',
                      height: 35,
                      width: 35,
                      margin: '0 5px'
                    }}
                    onClick={() => history.push(`/grow/${id}/about`)}
                  >
                    <ClearIcon />
                  </IconButton>
                  &nbsp;
                  <Typography style={{ fontWeight: 'bold' }} variant="h5">
                    Chỉnh sửa dự án
                  </Typography>
                </Grid>
              </Grid>
            );

          default:
            return (
              <Grid container spacing={2}>
                <Grid
                  container
                  item
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    style={{ margin: '8px 14px 8px 3px', fontWeight: 'bold' }}
                    variant="h5"
                  >
                    Dự án
                  </Typography>
                  <div style={{ margin: '8px 14px 8px 3px', width: '100%' }}>
                    <InputSearch
                      keyword={keyword}
                      setKeyword={setKeyword}
                      action={() => history.push(`/grow/search?q=${keyword}`)}
                      label="Tìm kiếm dự án"
                    />
                  </div>
                </Grid>
              </Grid>
            );
        }
      }
      case PATHS.GROW_MINE: {
        switch (key) {
          case 'search':
            return null;

          default:
            return (
              <Grid container spacing={2}>
                <Grid
                  container
                  item
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    style={{ margin: '8px 14px 8px 3px', fontWeight: 'bold' }}
                    variant="h5"
                  >
                    Gọi vốn cộng đồng
                  </Typography>
                  <IconFavorite
                    styleContainer={{ marginRight: '10px' }}
                    type="grows"
                  />
                  <div style={{ margin: '8px 14px 8px 3px', width: '100%' }}>
                    <InputSearch
                      keyword={keyword}
                      setKeyword={setKeyword}
                      action={() => history.push(`/grow/search?q=${keyword}`)}
                      label="Tìm kiếm dự án"
                    />
                  </div>
                </Grid>
              </Grid>
            );
        }
      }
      case PATHS.GROW_NOTI_IDENTITY: {
        return null;
      }
      default:
        return (
          <Grid container spacing={2}>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 'bold', margin: '8px 14px 3px 3px' }}
                variant="h5"
              >
                Gọi vốn cộng đồng
              </Typography>
              <IconFavorite
                styleContainer={{ marginRight: '10px' }}
                type="grows"
              />
              <div style={{ margin: '8px 14px 8px 3px', width: '100%' }}>
                <InputSearch
                  keyword={keyword}
                  setKeyword={setKeyword}
                  action={() => history.push(`/grow/search?q=${keyword}`)}
                  label="Tìm kiếm dự án"
                />
              </div>
            </Grid>
          </Grid>
        );
    }
  };

  const renderCategory = () => {
    return (
      <div
        style={{
          width: '300px',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '105px'
        }}
      >
        <Divider sx={{ margin: '0px 0px 5px' }} />
        <div
          style={{
            margin: '8px 8px 0px'
          }}
        >
          <Typography fontSize={16} fontWeight={600} gutterBottom>
            Hạng mục
          </Typography>
        </div>
        {categories?.map((item: any) => (
          <>
            <ListItem
              key={item.id}
              disablePadding
              onClick={() => setIndexCategories(item?.id)}
              className={
                item && item?.id === indexCategories ? classes.highlight : ''
              }
            >
              <ListItemButton
                style={{ paddingLeft: 20 }}
                onClick={() => history.push(`/grows/categories/${item.id}`)}
              >
                <ListItemText
                  primary={
                    <Typography fontSize={15} fontWeight={600}>
                      {item.text}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          </>
        ))}
      </div>
    );
  };

  const renderBody = () => {
    let { key, id } = match?.params;
    switch (match.path) {
      case PATHS.GROW_CREATE:
        switch (key) {
          case 'online':
            return {
              bodyTop: null,
              bodyCenter: <div></div>,
              bodyFooter: null
            };
          case 'offline':
            return {
              bodyTop: null,
              bodyCenter: (
                <div style={{ margin: '0px 10px 160px 0px' }}>
                  <IconButtonOptions
                    name={growOwner.title}
                    styleNameButton={{
                      width: '190px',
                      color: 'text.primary'
                    }}
                    avatar={growOwner.avatar_icon}
                    description={growOwner.description}
                    styleAvatar={{ height: 36, width: 36, marginRight: '12px' }}
                    icon={
                      <i
                        style={{ fontSize: '16px' }}
                        className="fa-solid fa-caret-down"
                      ></i>
                    }
                    openPopup={true}
                    style={{
                      margin: '0px 10px',
                      width: matchesCreate ? '100%' : '330px',
                      minHeight: '64px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: 'button.primary'
                    }}
                    options={[
                      [
                        { title: 'Hồ sơ' },
                        {
                          id: meInfo.id,
                          label: meInfo.display_name,
                          avatar_icon: meInfo.avatar_media
                            ? meInfo.avatar_media.show_url ??
                              meInfo.avatar_media.preview_url ??
                              meInfo.avatar_media.url
                            : avatarDefault,
                          checkbox: true,
                          action: () => {
                            setGrowOwner({
                              id: meInfo.id,
                              title: meInfo.display_name,
                              avatar_icon: meInfo.avatar_media
                                ? meInfo.avatar_media.show_url ??
                                  meInfo.avatar_media.preview_url ??
                                  meInfo.avatar_media.url
                                : avatarDefault,
                              description:
                                'Người tổ chức - Trang cá nhân của bạn'
                            });
                          },
                          styleLabel: { width: '228px' }
                        }
                      ],
                      [{ title: 'Trang' }].concat(
                        pagesAdmin.map((el: any) => ({
                          ...el,
                          checkbox: true
                        }))
                      )
                    ]}
                    styleListMenu={{
                      minWidth: '320px',
                      height: '250px',
                      overflowY: 'scroll'
                    }}
                    stylePopup={{
                      zIndex: '1001',
                      width: matchesCreate ? '98%' : '330px'
                    }}
                    searchFeature={true}
                    actionSearch={el => {
                      let data = {
                        page_owner_id: el.id,
                        title: el.title,
                        avatar_icon: el.avatar_media?.show_url
                          ? el.avatar_media.show_url
                          : avatarDefault,
                        description: 'Bên tổ chức - Trang của bạn'
                      };
                      setGrowOwner(data);
                    }}
                    infiniteScroll={true}
                    funcLoad={funcLoad}
                    hasMore={hasMore}
                  />
                  <GrowCreateUpdate
                    setCheckCreateUpdate={setCheckCreateUpdate}
                    organization={growOwner}
                    createGrow={createGrow}
                    setCreateGrow={setCreateGrow}
                    categories={categories}
                    fetchCategories={fetchCategories}
                    activeStep={activeStep}
                    setCheckStep={setCheckStep}
                    checkStep={checkStep}
                    setNoti={setNoti}
                    setOpenSnackbar={setOpenSnackbar}
                  />
                </div>
              ),
              bodyFooter: null
            };
          default:
            return {
              bodyTop: null,
              bodyCenter: (
                <div style={{ margin: '0px 10px 0px 0px', padding: '0px 5px' }}>
                  <IconButtonOptions
                    name={growOwner.title}
                    styleNameButton={{
                      width: '190px',
                      color: 'text.primary'
                    }}
                    avatar={growOwner.avatar_icon}
                    description={growOwner.description}
                    styleAvatar={{ height: 36, width: 36, marginRight: '12px' }}
                    icon={
                      <i
                        style={{ fontSize: '16px' }}
                        className="fa-solid fa-caret-down"
                      ></i>
                    }
                    openPopup={true}
                    style={{
                      margin: '0px 10px',
                      width: '330px',
                      minHeight: '64px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: 'button.primary'
                    }}
                    options={[
                      [
                        { title: 'Hồ sơ' },
                        {
                          id: meInfo.id,
                          label: meInfo.display_name,
                          avatar_icon: meInfo.avatar_media
                            ? meInfo.avatar_media.show_url ??
                              meInfo.avatar_media.preview_url ??
                              meInfo.avatar_media.url
                            : avatarDefault,
                          checkbox: true,
                          action: () => {
                            setGrowOwner({
                              id: meInfo.id,
                              title: meInfo.display_name,
                              avatar_icon: meInfo.avatar_media
                                ? meInfo.avatar_media.show_url ??
                                  meInfo.avatar_media.preview_url ??
                                  meInfo.avatar_media.url
                                : avatarDefault,
                              description:
                                'Người tổ chức - Trang cá nhân của bạn'
                            });
                          },
                          styleLabel: { width: '228px' }
                        }
                      ],
                      [{ title: 'Trang' }].concat(
                        pagesAdmin.map((el: any) => ({
                          ...el,
                          checkbox: true
                        }))
                      )
                    ]}
                    styleListMenu={{
                      minWidth: '320px',
                      height: '250px',
                      overflowY: 'scroll'
                    }}
                    stylePopup={{ zIndex: '1001', width: '330px' }}
                    searchFeature={true}
                    actionSearch={el => {
                      let data = {
                        page_owner_id: el.id,
                        title: el.title,
                        avatar_icon: el.avatar_media?.show_url
                          ? el.avatar_media.show_url
                          : avatarDefault,
                        description: 'Bên tổ chức - Trang của bạn'
                      };
                      setGrowOwner(data);
                    }}
                    infiniteScroll={true}
                    funcLoad={funcLoad}
                    hasMore={hasMore}
                  />
                  <ListButton
                    item={[
                      {
                        id: 'create_grow',
                        title: 'Tạo dự án gọi vốn',
                        styleTitle: { fontWeight: '600' },
                        icon: (
                          <i
                            className="fa-solid fa-user-group"
                            style={{ fontSize: '18px' }}
                          ></i>
                        ),
                        style: { height: 36, width: 36 },
                        position: 'top'
                      }
                    ]}
                    selectedItem={selectedIndex}
                    width={'340px'}
                    typeAction={'action'}
                  />
                </div>
              ),
              bodyFooter: null
            };
        }

      case PATHS.GROW:
        return {
          bodyTop: (
            <>
              <ListButton
                item={listMenuGrow.filter(el => el.position === 'top')}
                selectedItem={selectedIndex}
                styleAccordionDetail={{ padding: '3px' }}
                styleBtnGrow={{ marginTop: '10px' }}
                styleBtnMyGrow={{
                  padding: '0px 16px 0px 0px !important',
                  margin: '0px'
                }}
                typeAction={'action'}
              />
              <div style={{ padding: '0px 10px', width: 300 }}>
                <ButtonCustom
                  label="Tạo dự án gọi vốn mới"
                  action={() => {
                    if (meInfo.identity_verification) {
                      dispatch(resetGrow());
                      setActiveStep(0);
                      history.push('/grows/create/offline');
                    } else {
                      setConfirmIdentity_verifications(true);
                      // setOpenDialogVerificationUser(true);
                    }
                  }}
                  solid={true}
                  style={{
                    color: '#e7f3ff',
                    backgroundColor: buttonColor.backgroundColor
                  }}
                  icon={<i className="far fa-plus"></i>}
                />
              </div>
            </>
          ),
          bodyCenter: null,
          bodyFooter: renderCategory()
        };
      case PATHS.GROW_CATEGORY:
        return {
          bodyTop: (
            <>
              <ListButton
                item={listMenuGrow.filter(el => el.position === 'top')}
                selectedItem={selectedIndex}
                styleAccordionDetail={{ padding: '3px' }}
                styleBtnGrow={{ marginTop: '10px' }}
                styleBtnMyGrow={{
                  padding: '0px 16px 0px 0px !important',
                  margin: '0px'
                }}
                typeAction={'action'}
              />
              <div style={{ padding: '0px 10px', width: 300 }}>
                <ButtonCustom
                  label="Tạo dự án gọi vốn mới"
                  action={() => {
                    if (meInfo.identity_verification) {
                      dispatch(resetGrow());
                      setActiveStep(0);
                      history.push('/grows/create/offline');
                    } else {
                      setConfirmIdentity_verifications(true);
                      // setOpenDialogVerificationUser(true);
                    }
                  }}
                  solid={true}
                  style={{
                    color: '#e7f3ff',
                    backgroundColor: buttonColor.backgroundColor
                  }}
                  icon={<i className="far fa-plus"></i>}
                />
              </div>
            </>
          ),
          bodyCenter: null,
          bodyFooter: renderCategory()
        };
      case PATHS.GROW_MINE:
      case PATHS.GROW_DETAIL:
        switch (key) {
          case 'edit':
            return {
              bodyTop: (
                <div style={{ margin: '0px 10px 160px 0px' }}>
                  <IconButtonOptions
                    name={growInfo?.page_owner?.title ?? growOwner.title}
                    avatar={
                      growInfo?.page_owner?.avatar_icon ?? growOwner.avatar_icon
                    }
                    description={
                      growInfo?.page_owner?.description ?? growOwner.description
                    }
                    styleAvatar={{
                      height: 36,
                      width: 36,
                      marginRight: '12px'
                    }}
                    style={{
                      margin: '0px 0px',
                      width: '100%',
                      minHeight: '64px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: '#fff'
                    }}
                    disabled
                    stylePopup={{ zIndex: '1001' }}
                  />
                  <GrowCreateUpdate
                    setCheckCreateUpdate={setCheckCreateUpdate}
                    updateGrow={updateGrow}
                    idUpdate={id}
                    setUpdateGrow={setUpdateGrow}
                    categories={categories}
                    fetchCategories={fetchCategories}
                    activeStep={activeStep}
                    setCheckStep={setCheckStep}
                    setNoti={setNoti}
                    setOpenSnackbar={setOpenSnackbar}
                    isUpdateGrow={true}
                  />
                </div>
              ),
              bodyCenter: null,
              bodyFooter: null
            };

          default:
            break;
        }
        switch (id) {
          case 'search':
            return {
              bodyTop: null,
              bodyCenter: (
                <div>
                  <SearchGlobalLeft
                    type="grow"
                    styleGui={{
                      height: '100%',
                      width: 310,
                      backgroundColor: theme.palette.background.primary,
                      position: 'none',
                      overflowY: 'hidden'
                    }}
                  />
                  ;
                </div>
              ),
              bodyFooter: null
            };

          default:
            return {
              bodyTop: (
                <>
                  <ListButton
                    item={listMenuGrow.filter(el => el.position === 'top')}
                    selectedItem={selectedIndex}
                    styleAccordionDetail={{ padding: '8px 5px 5px' }}
                    styleBtnGrow={{ marginTop: '10px' }}
                    styleBtnMyGrow={{
                      padding: '0px 16px 0px 0px !important',
                      margin: '0px'
                    }}
                    typeAction={'action'}
                  />
                  <div style={{ padding: '0px 10px', width: 300 }}>
                    <ButtonCustom
                      label="Tạo dự án gọi vốn mới"
                      action={() => {
                        if (meInfo.identity_verification) {
                          dispatch(resetGrow());
                          setActiveStep(0);
                          history.push('/grows/create/offline');
                        } else {
                          setConfirmIdentity_verifications(true);
                          // setOpenDialogVerificationUser(true);
                        }
                      }}
                      solid={true}
                      style={{
                        color: '#e7f3ff',
                        backgroundColor: buttonColor.backgroundColor
                      }}
                      icon={<i className="far fa-plus"></i>}
                    />
                  </div>
                </>
              ),
              bodyCenter: (
                <div
                  style={{
                    width: '300px',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Divider />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '8px'
                      }}
                    >
                      <Typography fontSize={16} fontWeight={600}>
                        Dự án gọi vốn sắp tới
                      </Typography>
                      <Typography
                        onClick={() => history.push('/grows/calendar')}
                        fontSize={14}
                        color={buttonColor.backgroundColor}
                        sx={{ cursor: 'pointer' }}
                      >
                        Xem tất cả
                      </Typography>
                    </div>
                    {isLoading ? (
                      <Loading />
                    ) : listGrowMe.length ? (
                      listGrowMe.map((el: any) => renderListGrow(el))
                    ) : (
                      <span className={classes.notData}>Không có dữ liệu</span>
                    )}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Divider />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '8px'
                      }}
                    >
                      <Typography fontSize={16} fontWeight={600}>
                        Dự án đề xuất
                      </Typography>
                      <Typography
                        onClick={() => history.push('/grows/home')}
                        fontSize={14}
                        color={buttonColor.backgroundColor}
                        sx={{ cursor: 'pointer' }}
                      >
                        Xem tất cả
                      </Typography>
                    </div>
                    {isLoading ? (
                      <Loading />
                    ) : listDiscoverGrow.length ? (
                      listDiscoverGrow.map((el: any) => renderListGrow(el))
                    ) : (
                      <span className={classes.notData}>Không có dữ liệu</span>
                    )}
                  </div>
                </div>
              ),
              bodyFooter: renderCategory()
            };
        }
      default:
        return { bodyTop: null, bodyCenter: null, bodyFooter: null };
    }
  };

  const renderFooter = () => {
    let { key, id } = match.params;
    switch (match.path) {
      case PATHS.GROW_CREATE: {
        if (key) {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '12px',
                backgroundColor: 'background.primary',
                marginBottom: '-8px'
              }}
            >
              <StepperSocial activeStep={activeStep} steps={steps} />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <ButtonInherit
                    label="Quay lại"
                    action={() => {
                      handleBack();
                    }}
                    textColor={buttonColor.backgroundColor}
                    style={{ marginTop: '12px' }}
                  />
                )}
                {activeStep === steps - 1 ? (
                  <ButtonInherit
                    loading={createGrow}
                    label="Gửi phê duyệt dự án"
                    color={buttonColor.backgroundColor}
                    textColor="#fff"
                    style={{ marginTop: '12px', width: '70%' }}
                    action={() => {
                      setCreateGrow(true);
                    }}
                    disabled={!checkCreateUpdate}
                  />
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    className={classes.button}
                    sx={{
                      height: '36px',
                      color: 'white',
                      backgroundColor: buttonColor.backgroundColor,
                      '&:hover': {
                        backgroundColor: buttonColorHover.backgroundColor
                      }
                    }}
                    disabled={
                      activeStep === 0
                        ? !checkStep.step1
                        : activeStep === 1
                        ? !checkStep.step2
                        : activeStep === 2
                        ? !checkStep.step3
                        : !checkStep.step4
                    }
                  >
                    Tiếp
                  </Button>
                )}
              </Box>
            </Box>
          );
        } else return null;
      }
      case PATHS.GROW_CATEGORY:
      case PATHS.GROW_MINE:
        return null;
      case PATHS.GROW_DETAIL:
        if (key === 'edit') {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '12px',
                backgroundColor: 'background.primary',
                marginBottom: '-5px'
              }}
            >
              <StepperSocial activeStep={activeStep} steps={steps} />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <ButtonInherit
                    label="Quay lại"
                    action={() => {
                      handleBack();
                    }}
                    textColor={buttonColor.backgroundColor}
                    style={{ marginTop: '12px' }}
                  />
                )}
                {activeStep === steps - 1 ? (
                  <ButtonInherit
                    loading={updateGrow}
                    label="Cập nhật dự án"
                    color={buttonColor.backgroundColor}
                    textColor="#fff"
                    style={{ marginTop: '12px', width: '70%' }}
                    action={() => {
                      setUpdateGrow(true);
                    }}
                    disabled={!checkCreateUpdate}
                  />
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    className={classes.button}
                    disabled={
                      activeStep === 0
                        ? !checkStep.step1
                        : activeStep === 1
                        ? !checkStep.step2
                        : activeStep === 2
                        ? !checkStep.step3
                        : !checkStep.step4
                    }
                    sx={{
                      height: '36px',
                      color: 'white',
                      backgroundColor: buttonColor.backgroundColor,
                      '&:hover': {
                        backgroundColor: buttonColorHover.backgroundColor
                      }
                    }}
                  >
                    Tiếp
                  </Button>
                )}
              </Box>
            </Box>
          );
        }

        break;

      default:
        return null;
    }
  };

  return (
    <Box
      className={classes.root}
      sx={{
        position:
          renderBody().bodyTop ||
          renderBody().bodyCenter ||
          renderBody().bodyFooter
            ? 'fixed'
            : 'static',

        width:
          match.path === PATHS.GROW_CREATE ||
          ((match.params?.key === 'edit' || match.params?.key === 'offline') &&
            !!match.params?.id)
            ? matchesCreate
              ? '100%'
              : 370
            : renderBody().bodyTop ||
              renderBody().bodyCenter ||
              renderBody().bodyFooter
            ? 310
            : 0,
        margin:
          renderBody().bodyTop ||
          renderBody().bodyCenter ||
          renderBody().bodyFooter
            ? 0
            : '5px 10px 40px -20px',
        padding:
          match.path === PATHS.GROW_CREATE ||
          (match.params?.key === 'edit' && !!match.params?.id)
            ? '5px 5px 40px 5px'
            : '5px 0px 40px 3px',
        backgroundColor: 'background.primary'
      }}
    >
      {match?.params?.id !== 'search' && (
        <div className={classes.headerPage}>{renderHeader()}</div>
      )}

      <div className={classes.rootPage}>
        {renderBody().bodyTop}
        {match.path !== PATHS.PAGE_CREATE && match?.params?.id !== 'search' && (
          <div style={{ height: 10 }}></div>
        )}
        {renderBody().bodyCenter}
        {match.path === PATHS.GROW_DETAIL ||
        match.path === PATHS.GROW_MINE ||
        match.path === PATHS.GROW ||
        match.path === PATHS.GROW_CATEGORY
          ? renderBody().bodyFooter
          : null}
      </div>

      <div className={classes.footerPage}>
        {match.path !== PATHS.PAGE_CREATE &&
          match.path !== PATHS.PAGE &&
          renderFooter() && <Divider />}
        {renderFooter()}
      </div>
      <SnackbarNotification
        style={{ marginLeft: '40px' }}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        code={noti?.code}
        message={noti?.message}
      />
      {openDialogVerificationUser && (
        <DialogVerificationUser
          open={openDialogVerificationUser}
          setOpen={setOpenDialogVerificationUser}
          type="VerificationUserGrow"
          setOpenSnackbar={setOpenSnackbar}
          setNoti={setNoti}
          itemSelected={''}
        />
      )}
      {confirmIdentity_verifications && (
        <Box>
          <Dialog
            open={confirmIdentity_verifications}
            onClose={() => {
              setConfirmIdentity_verifications(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent sx={{ padding: '20px 0px 0px 0px' }}>
              <DialogTitle id="alert-dialog-title">
                Bạn cần xác minh danh tính để tạo dự án gọi vốn mới.
              </DialogTitle>
            </DialogContent>
            <DialogActions>
              <ButtonInherit
                label="Huỷ"
                action={() => {
                  setConfirmIdentity_verifications(false);
                }}
              ></ButtonInherit>
              <ButtonInherit
                label="Xác minh danh tính"
                variant="contained"
                action={() => {
                  setConfirmIdentity_verifications(false);
                  setOpenDialogVerificationUser(true);
                }}
              ></ButtonInherit>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </Box>
  );
};

export default GrowLeft;
