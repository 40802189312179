import React from 'react';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import { Theme, IconButton, useMediaQuery } from '@mui/material';
import MenuHeader from './MenuHeader';
import menuglobal from 'src/assets/images/menuglobal.png';

export default function DropdownGlobalContainer(props) {
  const { handleClose } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const matches = useMediaQuery('(min-width:1200px)');
  const theme: any = useTheme();

  const useStyles = makeStyles(
    (theme: Theme) =>
      createStyles({
        btnRightHeader: {
          margin: '0 3px !important',
          width: theme.spacing(5),
          height: theme.spacing(5),
          fontSize: '17px'
        },
        menuHeader: {
          position: 'absolute',
          top: 50,
          right: -140,
          zIndex: '1000'
        }
      }),
    { index: 1 }
  );

  const classes = useStyles();
  return (
    <div style={{ position: 'relative' }}>
      <IconButton
        onClick={() => setIsOpen(prev => !prev)}
        className={classes.btnRightHeader}
        sx={{
          backgroundColor: 'button.secondary.background',
          '&:hover': {
            backgroundColor: 'button.primary.hover'
          }
        }}
      >
        {matches ? (
          <div
            style={{
              height: '40px',
              width: '40px',
              display: 'flex',
              position: 'relative',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                height: '25px',
                width: '25px',
                overflow: 'hidden'
              }}
            >
              <img
                style={{
                  width: '49px',
                  height: '1050px',
                  transform: `translate(${-6}px, ${
                    theme.palette.mode === 'light' ? -81 : -105
                  }px)`
                }}
                src={menuglobal}
                alt="menu global"
              ></img>
            </div>
          </div>
        ) : (
          <i
            className={'fa-solid fa-plus'}
            style={
              isOpen
                ? { color: '#f3802e', fontSize: 21 }
                : {
                    color:
                      theme.palette.mode === 'light' ? '#050505' : '#fafafa',
                    fontSize: 21
                  }
            }
          ></i>
        )}
      </IconButton>

      <div className={classes.menuHeader}>
        <MenuHeader
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          matches={matches}
          handleClose={handleClose}
        />
      </div>
    </div>
  );
}
