import * as React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Theme,
  Typography
} from '@mui/material';
import { boxShadow } from 'src/constants/styles';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import SocialPost from 'src/components/SocialPost';
import { useRouteMatch } from 'react-router-dom';
import { getListStatusUserInGroupRequest } from 'src/store/action/socialGroupAction';
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '15px'
      },
      bodyLeft: {
        width: '45%',
        backgroundColor: '#f1f2f5'
      },
      bodyRight: {
        width: '50%',
        backgroundColor: '#f1f2f5'
      },
      boxIntro: {
        padding: '15px',
        borderRadius: '5px',
        backgroundColor: '#fff',
        marginBottom: '5px',
        ...boxShadow
      },
      textIntro: {
        fontWeight: 'bold !important',
        fontSize: '20px !important'
      },
      boxListStatus: {}
    }),
  { index: 1 }
);
const PostInGroup = props => {
  const classes = useStyles();
  const activities = useSelector(
    (status: any) => status.groupReducer.activitiesUserInGroup
  );
  const match: any = useRouteMatch();
  const dispatch = useDispatch();
  const renderLoadPost = () => {
    return (
      <Card sx={{ width: '100%', marginTop: '20px', ...boxShadow }}>
        <CardHeader
          avatar={
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
              // sx={{ bgcolor: '#f0f0f0' }}
            />
          }
          title={
            <Skeleton
              animation="wave"
              height={20}
              width="27%"
              // sx={{ bgcolor: '#f0f0f0' }}
            />
          }
          subheader={
            <Skeleton
              animation="wave"
              height={20}
              width="30%"
              // sx={{ bgcolor: '#f0f0f0' }}
            />
          }
        />
        {
          <Skeleton
            sx={{ height: '380px' }}
            animation="wave"
            variant="rectangular"
          />
        }
        <CardContent sx={{ display: 'flex', justifyContent: 'space-around' }}>
          <React.Fragment>
            <Skeleton
              animation="wave"
              height={20}
              width="18%"
              // sx={{ bgcolor: '#f0f0f0' }}
            />
            <Skeleton
              animation="wave"
              height={20}
              width="18%"
              // sx={{ bgcolor: '#f0f0f0' }}
            />
            <Skeleton
              animation="wave"
              height={20}
              width="18%"
              // sx={{ bgcolor: '#f0f0f0' }}
            />
          </React.Fragment>
        </CardContent>
      </Card>
    );
  };
  const { groupRelationShip, groupInfo } = props;
  const funcLoad = (limit = 3) => {
    let postLast = [...activities.listStatus].pop();
    let maxId = postLast?.id;
    if (!activities.isLoading) {
      dispatch(
        getListStatusUserInGroupRequest(match.params.groupId, {
          account_id: match.params.userId,
          sort_by: 'new_post',
          exclude_replies: true,
          limit: limit,
          max_id: maxId
        })
      );
    }
  };
  const renderShowRole = (groupRelationShip: any) => {
    if (groupRelationShip.admin) {
      return 'Quản trị viên';
    } else if (groupRelationShip.moderator) {
      return 'Kiểm duyệt viên';
    } else {
      return 'Thành viên';
    }
  };
  return (
    <React.Fragment>
      <Box className={classes.root}>
        <Box className={classes.bodyLeft}>
          <Box className={classes.boxIntro}>
            <Typography className={classes.textIntro} variant="h5">
              Giới thiệu
            </Typography>
            <Box style={{ display: 'flex', marginTop: '15px' }}>
              <i className="fa-solid fa-users"></i>
              <Typography style={{ marginLeft: '10px' }}>
                {renderShowRole(groupRelationShip)} của {groupInfo.title}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.bodyRight}>
          <Box className={classes.boxIntro}>
            <Typography variant="h5" className={classes.textIntro}>
              Bài viết trong nhóm
            </Typography>
          </Box>
          <Box className={classes.boxListStatus}>
            <InfiniteScroll
              dataLength={activities.listStatus.length}
              next={funcLoad}
              hasMore={activities.hasMore}
              loader={renderLoadPost()}
              style={{ overflow: 'hidden' }}
              scrollThreshold={0.8}
              endMessage={
                <Box style={{ textAlign: 'center' }}>Không còn dữ liệu mới</Box>
              }
            >
              {activities.listStatus.map(status => {
                return (
                  <SocialPost
                    key={status.id}
                    post={status}
                    type={'stream_group'}
                  />
                );
              })}
            </InfiniteScroll>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default PostInGroup;
