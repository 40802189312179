import * as React from 'react';import { styled } from '@mui/material/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Skeleton,
  Typography
} from '@mui/material';

import InfiniteScroll from 'react-infinite-scroll-component';
import { getMentionsPost } from 'src/apis/socialPost.api';

import CloseIcon from '@mui/icons-material/Close';
import { modalBackdrop } from 'src/constants/styles';
import UserTagItem from './UserTagItem';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs(props) {
  const { open, setOpen, idPost } = props;
  const [hasMore, setHasMore] = React.useState(true);
  const [listMention, setListMention] = React.useState<any>([]);

  React.useEffect(() => {
    if (idPost) {
      getPostMentions(idPost);
    }
  }, []);

  const getPostMentions = async id => {
    let response;
    response = await getMentionsPost(id);
    if (response.status === 200) {
      if (response.data.length) {
        setListMention(response.data);
      }
      if (!response.data.length) {
        setHasMore(false);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const funcLoad = () => {
    let maxId = [...listMention].pop().id;
    let params = {
      max_id: maxId
    };

    getPostMentions(params);
  };

  const userSkeleton = (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
      <Skeleton
        variant="circular"
        animation="wave"
        width={40}
        height={40}
        sx={{ marginLeft: 2, marginTop: 1, marginRight: 2 }}
      />
      <Skeleton
        animation="wave"
        width={'50%'}
        variant="text"
        sx={{ fontSize: '1.2rem', marginTop: 1 }}
      />
    </div>
  );
  const loaderSkeleton = (
    <>
      {userSkeleton}
      {userSkeleton}
      {userSkeleton}
      {userSkeleton}
      {userSkeleton}
      {userSkeleton}
    </>
  );

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: 20,

              padding: 10
            }}
            textAlign={'center'}
          >
            Mọi người
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent sx={{ height: 364 }} dividers>
          <InfiniteScroll
            dataLength={listMention?.length ?? 0} //This is important field to render the next data
            next={funcLoad}
            hasMore={hasMore}
            loader={loaderSkeleton}
            style={{ overflow: 'hidden' }}
            scrollThreshold={1}
          >
            {listMention &&
              listMention?.map((el, index) => (
                <UserTagItem
                  item={el}
                  index={index}
                  handleClose={handleClose}
                />
              ))}
          </InfiniteScroll>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
