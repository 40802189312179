import { call, put, takeEvery } from 'redux-saga/effects';import * as actions from 'src/store/action/settingAction';
import * as types from 'src/constants/store/setting';
import { uploadMediaApi } from 'src/apis/commonSocialApi';
const typesSetting: any = types;

export const getColorImageSagas = function* ({ payload }) {
  try {
    const { params, setState, href, typeEndpoint } = payload;
    yield put(actions.checkSetLoading(true));
    let response = yield call(
      uploadMediaApi,
      params[0],
      setState,
      null,
      typeEndpoint
    );
    if (response.status === 200) {
      let { data } = response;
      yield put(actions.getColorImageSuccess(data, href));
      yield put(actions.checkSetLoading(false));
    }
  } catch (error) {
    yield put(actions.checkSetLoading(false));
  }
};

export function* watchGetSettingAsync() {
  yield takeEvery(typesSetting.GET_COLOR_IMAGE_REQ, getColorImageSagas);
}
