import axios, { AxiosRequestConfig } from 'axios';
import { USER_TOKEN } from 'src/util/config';
import { BASE_ROOT, BASE_ROOT_MARKET } from 'src/util/config';

const fetchApiRequest = async (
  endPoint,
  method,
  params,
  _data,
  typeEndpoint = ''
) => {
  let configs: AxiosRequestConfig = {
    url: `${
      typeEndpoint === 'course' ? BASE_ROOT_MARKET : BASE_ROOT
    }/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

const fetchApiRequestMarket = async (endPoint, method, params, _data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT_MARKET}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

export const getBalance = async () => {
  return fetchApiRequest(`transactions`, 'GET', null, null);
};

export const getMerchantPayment = async params => {
  return fetchApiRequestMarket(
    'merchant_payment_requests',
    'GET',
    params,
    null
  );
};

export const createMerchantPaymentApi = async (data: any) => {
  return fetchApiRequestMarket('merchant_payment_requests', 'POST', null, data);
};

export const getRefundPayment = async params => {
  return fetchApiRequestMarket('refund_requests', 'GET', params, null);
};

export const cancelRefundPayment = async (id, params) => {
  return fetchApiRequestMarket(
    `refund_requests/${id}/buyer_cancel`,
    'POST',
    null,
    params
  );
};

export const createRefundPaymentApi = async (data: any) => {
  return fetchApiRequestMarket('refund_requests', 'POST', null, data);
};

export const getHistoryPaymentCoin = async (params: any) => {
  return fetchApiRequest(`transaction_history`, 'GET', params, null);
};

export const getHistoryPaymentMoney = async (params: any) => {
  return fetchApiRequestMarket(`transaction_history`, 'GET', params, null);
};

export const rechargePayment = async (
  params: any,
  type,
  typeEndpoint?: string
) => {
  return fetchApiRequest(
    `${type === 'vtcpay' ? 'create_vtcpay_url' : 'create_payment_url'}`,
    'GET',
    params,
    null,
    typeEndpoint
  );
};

export const getPaymentCoin = async (params: any) => {
  return fetchApiRequest(`vnpay_return`, 'GET', params, null);
};

export const postDonateAccountUser = async (data: any) => {
  const formData = new FormData();
  if (data.amount) {
    formData.append('amount', data.amount);
  }
  if (data.target_account_id) {
    formData.append('target_account_id', data.target_account_id);
  }
  return fetchApiRequest('account_donate', 'POST', null, formData);
};

export const postDonatePage = async (data: any) => {
  const formData = new FormData();
  if (data.amount) {
    formData.append('amount', data.amount);
  }
  if (data.page_id) {
    formData.append('page_id', data.page_id);
  }
  if (data.entity_id) {
    formData.append('entity_id', data.entity_id);
  }
  if (data.entity_type) {
    formData.append('entity_type', data.entity_type);
  }
  return fetchApiRequest('page_donate', 'POST', null, formData);
};

export const getListBanks = async () => {
  return fetchApiRequest('bank_lists', 'GET', null, null);
};
export const getListBranchesBank = async params => {
  return fetchApiRequest('bank_branches_lists', 'GET', params, null);
};
export const createUpdatAddBank = async (data: any, type, bankId) => {
  const formData = new FormData();
  if (data.title) {
    formData.append('title', data.title);
  }
  if (data.identitycard_number) {
    formData.append('identity_verification_number', data.identitycard_number);
  }
  if (data.bank_id) {
    formData.append('bank_id', data.bank_id);
  }
  if (data.bank_branch_id) {
    formData.append('bank_branch_id', data.bank_branch_id);
  }
  if (data.bank_account_number) {
    formData.append('bank_account_number', data.bank_account_number);
  }
  if (data.full_name_in_bank) {
    formData.append('full_name_in_bank', data.full_name_in_bank);
  }
  formData.append('default', data.default);
  formData.append('bank_type', 'bank_account');
  return fetchApiRequest(
    `bank_accounts/${type === 'update' ? bankId : ''}`,
    `${type === 'update' ? 'PATCH' : 'POST'}`,
    null,
    formData
  );
};
export const getBankAccount = async () => {
  return fetchApiRequest('bank_accounts', 'GET', null, null);
};
export const deleteBankAccount = async bankId => {
  return fetchApiRequest(`bank_accounts/${bankId}`, 'DELETE', null, null);
};
