import { Box, Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createUpdateRecruitApi } from 'src/apis/socialRecruit.api';
import { RegText, convertISO8086toLDate } from 'src/common/string';
import CustomPrompt from 'src/components/CustomPrompt';
import {
  gender,
  level,
  privacyOptions,
  workExperience,
  workingForm
} from 'src/constants/common';
import {
  getRecruitDetailRequest,
  recruitPreview
} from 'src/store/action/socialRecruitAction';
import BasicRecruitInfomation from './StepCURecruit/BasicRecruitInfomation';
import CandidateInformation from './StepCURecruit/CandidateInformation';
import DescriptionRecruit from './StepCURecruit/DescriptionRecruit';
import OtherInformation from './StepCURecruit/OtherInformation';
import RecruitMedia from './StepCURecruit/RecruitMedia';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '5px 10px 20px 10px',
    borderRadius: 10,
    width: '100%'
  }
}));

interface RecruitCreateUpdate {
  infoUpdate?: any;
  createRecruit?: any;
  setCreateRecruit?: React.Dispatch<React.SetStateAction<any>> | any;
  updateRecruit?: any;
  setUpdateRecruit?: React.Dispatch<React.SetStateAction<any>> | any;
  idUpdate?: String | any;
  organization?: any;
  setCheckCreateUpdate?: React.Dispatch<React.SetStateAction<any>> | any;
  activeStep?: any;
  setActiveStep?: React.Dispatch<React.SetStateAction<any>>;
  setCheckStep?: React.Dispatch<React.SetStateAction<any>>;

  setOpenSnackBar?: React.Dispatch<React.SetStateAction<boolean>> | any;
  setNoti?: any;
}

const RecruitCreateUpdate: React.FC<RecruitCreateUpdate> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const recruitInfo = useSelector((state: any) => state.recruitReducer.info);
  const {
    infoUpdate,
    createRecruit,
    setCreateRecruit,
    updateRecruit,
    setUpdateRecruit,
    idUpdate,
    organization,
    setCheckCreateUpdate,
    activeStep,
    setActiveStep,
    setCheckStep,
    setOpenSnackBar,
    setNoti
  } = props;
  const matchesCreate = useMediaQuery('(max-width:1000px)');
  const [locationSelected, setLocationSelected] = React.useState<any>(null);
  const [notificationLeave, setNotificationLeave] =
    React.useState<boolean>(false);
  const [openDialogUpdateLocation, setOpenDialogUpdateLocation] =
    React.useState<any>(false);
  const validationSchema = Yup.object({
    recruitments_count: Yup.mixed()
      .test('is-mixed', 'Giá trị không hợp lệ', function (value) {
        if (!value) {
          return true;
        }

        return /^[0-9]+$/.test(value) && !/\s|[^0-9]/.test(value);
      })
      .test('less-than', 'Số lượng tuyển dụng không quá 1000 người', value => {
        if (!value) {
          return true;
        }
        return value <= 1000;
      })
      .test('more-than', 'Số lượng tuyển dụng phải lớn hơn 0', value => {
        if (!value) {
          return true;
        }
        return value > 0;
      })
      .nullable(),
    salary_min: Yup.number()
      .integer('Lương phải là số tự nhiên')
      .typeError('Dữ liệu nhập không hợp lệ')
      .positive('Lương phải lớn hơn 0')
      .min(1000, 'Mức lương thấp nhất là 1000 VND')
      .max(1000000000, 'Mức tuyển dụng không quá 9 chữ số'),
    salary_max: Yup.number()
      .integer('Lương phải là số tự nhiên')
      .typeError('Dữ liệu nhập không hợp lệ')
      .positive('Lương phải lớn hơn 0')
      .min(1000, 'Mức lương thấp nhất là 1000 VND')
      .max(1000000000, 'Mức lương tuyển dụng không quá 9 chữ số')
      .moreThan(
        Yup.ref('salary_min'),
        `Lương nhập phải lớn hơn lương khởi điểm`
      ),
    title: Yup.string()
      .min(50, 'Tên tiêu đề tối thiểu 50 ký tự')
      .max(100, 'Tên tiêu đề không vượt quá 100 ký tự')
      .matches(RegText, 'Tên người dùng không được chứa ký tự đặc biệt')
      .nullable(),
    due_date: Yup.date()
      .nullable()
      .transform((currentValue, originalValue) => {
        if (!originalValue || isNaN(originalValue.getTime())) {
          return null;
        }
        return currentValue;
      })
      .min(new Date(), 'Ngày bắt đầu lớn hớn hoặc bằng ngày hôm nay'),
    banner: Yup.mixed().test(
      'imageDimensions',
      'Kích thước ảnh không hợp lệ',
      value => {
        if (!value) {
          return true;
        }

        if (value?.file?.file) {
          const image: any = new Image();
          image.src = value?.file?.file
            ? URL.createObjectURL(value.file.file)
            : null;

          return new Promise(resolve => {
            image.onload = () => {
              const width = image.width;
              const height = image.height;
              if (width >= 450 && height >= 150) {
                resolve(true);
              } else {
                resolve(false);
              }
            };
          });
        }

        return true;
      }
    )
  });

  React.useEffect(() => {
    if (Object.keys(recruitInfo).length !== 0 && recruitInfo?.id !== '') {
      let tempData: any = {
        title: recruitInfo?.title,
        due_date: recruitInfo?.due_date
          ? convertISO8601toTime(recruitInfo.due_date, 'data_object')
          : null,
        banner: recruitInfo?.banner,
        work_experience: workExperience?.find(
          (el: any) => el.id === recruitInfo?.work_experience
        ),
        working_form: workingForm?.find(
          (el: any) => el.id === recruitInfo?.working_form
        ),
        level: level?.find((el: any) => el.id === recruitInfo?.level),
        recruitments_count: recruitInfo?.recruitments_count,
        recruit_category_id: recruitInfo?.recruit_category,
        gender: gender?.find((el: any) => el.id === recruitInfo?.gender),
        visibility: privacyOptions?.find(
          (el: any) => el.id === recruitInfo?.visibility
        ),
        salary_min: recruitInfo?.salary_min,
        salary_max: recruitInfo?.salary_max,
        job_description: recruitInfo?.job_description,
        company_description: recruitInfo?.company_description,
        requirement: recruitInfo?.requirement,
        benefits: recruitInfo?.benefits,
        location: null,
        address: recruitInfo?.address
      };
      formik.setValues(tempData);
    }
  }, [JSON.stringify(recruitInfo), workExperience]);

  const handleCreateUpdateRecruit = async (type: any) => {
    try {
      let response = await createUpdateRecruitApi(
        {
          banner: formik.values.banner
            ? {
                file: formik.values.banner?.file?.file ?? null,
                id: formik.values.banner?.id ?? null,
                show_url: formik.values.banner.show_url
              }
            : null,
          title: formik.values.title || null,
          due_date: !infoUpdate
            ? `${convertISO8086toLDate(formik.values.due_date)}`
            : null,
          page_owner_id:
            type === 'create' && organization?.page_owner_id
              ? organization.page_owner_id
              : null,
          page_id:
            type === 'create' && organization?.page_owner_id
              ? organization.page_owner_id
              : null,
          work_experience: formik.values.work_experience.name,
          working_form: formik.values.working_form.id,
          level: formik.values.level.id,
          recruitments_count: formik.values.recruitments_count,
          recruit_category_id: formik.values.recruit_category_id.id,
          gender: formik.values.gender.id,
          location: formik.values.location || null,
          address: formik.values.address || null,
          visibility: formik.values.visibility.id,
          salary_min: formik.values.salary_min,
          salary_max: formik.values.salary_max,
          job_description: formik.values.job_description,
          company_description: formik.values.company_description,
          requirement: formik.values.requirement,
          benefits: formik.values.benefits
        },
        type
      );
      if (response.status === 200) {
        setNotificationLeave(false);
        if (createRecruit) {
          setCreateRecruit(false);
          setOpenSnackBar(true);
          setNoti({
            message: 'Đăng tin tuyển dụng thành công',
            code: 200
          });
          history.push(`/recruit/${response.data.id}/about`);
          setActiveStep && setActiveStep(0);
        }
        if (updateRecruit) {
          setUpdateRecruit(false);
          dispatch(getRecruitDetailRequest(type));
          setOpenSnackBar(true);
          setNoti({
            message: 'Cập nhập tin tuyển dụng thành công',
            code: 200
          });
          history.push(`/recruit/${type}/about`);
          setActiveStep && setActiveStep(0);
        }
      }
    } catch (error: any) {
      setOpenSnackBar(true);
      setNoti({
        code: 500,
        message: createRecruit
          ? 'Đăng tin tuyển dụng không thành công'
          : 'Cập nhập tin tuyển dụng không thành công'
      });
    }
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      title: '',
      due_date: null as any,
      banner: null as any,
      visibility: privacyOptions[0],
      location: null,
      address: null as any,
      recruit_category_id: null as any,
      work_experience: null as any,
      working_form: null as any,
      level: null as any,
      recruitments_count: '',
      gender: null as any,
      salary_min: '',
      salary_max: '',
      job_description: '',
      company_description: '',
      requirement: '',
      benefits: ''
    },
    onSubmit: values => {
      try {
        if (createRecruit && values.title) {
          handleCreateUpdateRecruit('create');
        }
        if (updateRecruit && idUpdate) {
          handleCreateUpdateRecruit(idUpdate);
        }
      } catch (error) {
        console.log(error);
      }
    }
  });

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <BasicRecruitInfomation
            formik={formik}
            infoUpdate={infoUpdate}
            setCheckStep={setCheckStep}
            setNotificationLeave={setNotificationLeave}
          />
        );

      case 1:
        if (
          workingForm?.length &&
          workExperience?.length &&
          level?.length &&
          gender?.length
        ) {
          return (
            <CandidateInformation
              formik={formik}
              infoUpdate={infoUpdate}
              workingForm={workingForm}
              workExperience={workExperience}
              level={level}
              gender={gender}
              setCheckStep={setCheckStep}
              setNotificationLeave={setNotificationLeave}
            />
          );
        }
        break;

      case 2:
        if (privacyOptions?.length) {
          return (
            <OtherInformation
              formik={formik}
              infoUpdate={infoUpdate}
              privacyOptions={privacyOptions}
              setCheckStep={setCheckStep}
              setNotificationLeave={setNotificationLeave}
            />
          );
        }
        break;

      case 3:
        return (
          <RecruitMedia
            formik={formik}
            infoUpdate={infoUpdate}
            recruitInfo={recruitInfo}
            setCheckStep={setCheckStep}
            locationSelected={locationSelected}
            setLocationSelected={setLocationSelected}
            openDialogUpdateLocation={openDialogUpdateLocation}
            setOpenDialogUpdateLocation={setOpenDialogUpdateLocation}
            setNotificationLeave={setNotificationLeave}
          />
        );

      case 4:
        return (
          <DescriptionRecruit
            formik={formik}
            infoUpdate={infoUpdate}
            recruitInfo={recruitInfo}
            setNotificationLeave={setNotificationLeave}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  }

  React.useEffect(() => {
    formik.handleSubmit();
  }, [createRecruit, updateRecruit, idUpdate]);

  React.useEffect(() => {
    if (!infoUpdate) {
      dispatch(
        recruitPreview({
          title: formik.values.title,
          due_date: formik.values.due_date,
          banner: formik.values.banner?.file
            ? formik.values.banner?.file
            : formik.values.banner,
          work_experience: formik.values.work_experience,
          working_form: formik.values.working_form,
          level: formik.values.level,
          recruitments_count: formik.values.recruitments_count,
          recruit_category_id: formik.values.recruit_category_id,
          gender: formik.values.gender,
          visibility: formik.values.visibility,
          salary_min: formik.values.salary_min,
          salary_max: formik.values.salary_max,
          location: formik.values.location,
          address: formik.values.address,
          job_description: formik.values.job_description,
          company_description: formik.values.company_description,
          requirement: formik.values.requirement,
          benefits: formik.values.benefits
        })
      );
    }
    if (setCheckCreateUpdate) {
      if (
        formik.values.title &&
        formik.values.due_date &&
        formik.values.banner &&
        formik.values.work_experience &&
        formik.values.working_form &&
        formik.values.level &&
        formik.values.recruitments_count &&
        formik.values.recruit_category_id &&
        formik.values.gender &&
        formik.values.visibility &&
        formik.values.salary_min &&
        formik.values.salary_max &&
        formik.values.job_description &&
        formik.values.company_description &&
        formik.values.requirement &&
        formik.values.benefits
      ) {
        setCheckCreateUpdate(true);
      } else {
        setCheckCreateUpdate(false);
      }
    }
  }, [JSON.stringify(formik.values)]);

  const convertISO8601toTime = (ISOTime: any, convertTo: any) => {
    var d = new Date(ISOTime),
      year = d.getFullYear(),
      month = d.getMonth() + 1,
      day = d.getDate(),
      hour = d.getHours(),
      minute = d.getMinutes();
    let secondTime: any = hour * 3600 + minute * 60;
    switch (convertTo) {
      case 'second':
        return {
          id: secondTime,
          value: secondTime,
          label: `${
            (hour < 10 ? '0' + hour + ':' : hour + ':') +
            (minute < 10 ? '0' + minute : minute)
          }`
        };
      case 'data_object':
        return d;
      case 'date':
        return `${year}/${month}/${day}`;
      default:
        break;
    }
  };
  const handleOnChangeWarning = (event: React.FormEvent) => {
    const formData: any = new FormData(event.currentTarget as HTMLFormElement);
    const values = Array.from(formData.values());
    const changedFields = values.filter(
      (value: any) => value.length || (value as File).size
    );
    setNotificationLeave(changedFields.length > 0);
  };

  return (
    <Box
      className={classes.root}
      sx={{
        width: infoUpdate || matchesCreate ? '100%' : '350px'
      }}
    >
      <CustomPrompt
        when={notificationLeave}
        navigate={path => {
          history.push(path);
        }}
        shouldBlockNavigation={() => {
          return notificationLeave;
        }}
        title={'Rời khỏi trang?'}
        content={'Thay đổi chưa lưu của bạn sẽ bị mất nếu bạn rời khỏi trang.'}
        optionDialog={2}
        labelButton={['Ở lại', 'Rời khỏi']}
      />
      <form onChange={handleOnChangeWarning}>{getStepContent(activeStep)}</form>
    </Box>
  );
};

export default RecruitCreateUpdate;
