import axios, { AxiosRequestConfig } from 'axios';import { USER_TOKEN } from 'src/util/config';
import { BASE_ROOT } from 'src/util/config';

const fetchApi = async (endPoint, method, params, data = null) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data
  };

  let response = await axios(configs);
  return response;
};
export const getNotificationsApi = async () => {
  return fetchApi(`markers?timeline[]=notifications`, 'GET', null, null);
};
export const postSeenNotiApi = async (data: any) => {
  return fetchApi(`markers`, 'POST', null, data);
};
export const getDetailNotification = async (id: any) => {
  return fetchApi(`notifications/${id}`, 'GET', null, null);
};
export const postReadNotiApi = async (id: any) => {
  return fetchApi(`notifications/${id}/read`, 'POST', null, null);
};
