import * as types from 'src/constants/store/socialPost';
export const getPostRequestAction = (params: any, type: string, path) => ({
  type: types.GET_POST_REQUEST,
  payload: {
    params,
    type,
    path
  }
});

export const getPostSuccessAction = (activities: any, type, path) => ({
  type: types.GET_POST_SUCCESS,
  payload: {
    activities,
    type,
    path
  }
});

export const resetActivities = (type: any = null) => ({
  type: types.RESET_ACTIVITIES,
  payload: type
});

export const resetPinnedPost = (type: any = null) => ({
  type: types.RESET_PINNED_POST,
  payload: type
});

export const updatePath = path => ({
  type: types.UPDATE_PATH,
  payload: {
    path
  }
});

export const resetPostLoad = () => ({
  type: types.RESET_POST_LOAD
});

export const resetFalsePostLoad = () => ({
  type: types.RESET_FALSE_POST_LOAD
});

export const createPostRequestAction = (
  data: any,
  type: string,
  place: any = null,
  placeCreatePost: any,
  setState: any = null
) => {
  return {
    type: types.CREATE_POST_REQUEST,
    payload: {
      data,
      type,
      place,
      placeCreatePost,
      setState
    }
  };
};

export const createPostSuccessAction = (data: any) => ({
  type: types.CREATE_POST_SUCCESS,
  payload: {
    data
  }
});

export const updatePostRequestAction = (data: any) => ({
  type: types.UPDATE_POST_REQUESTED,
  payload: {
    data
  }
});

export const updatePostSuccessAction = (data: any, hiddenPost: any = null) => ({
  type: types.UPDATE_POST_SUCCESS,
  payload: {
    data,
    hiddenPost
  }
});

export const commentTotal = (data: any, idPost) => ({
  type: types.COMMENT_TOTAL,
  payload: { data, idPost }
});

export const deleteOldPostSuccess = (id: any) => ({
  type: types.DELETE_OLD_POST_SUCCESS,
  payload: { id }
});

export const deletePostRequest = (type: string, postId: string) => ({
  type: types.DELETE_POST_REQUESTED,
  payload: {
    type,
    postId
  }
});

export const deletePostSuccess = (data: any, postNew: any = null) => ({
  type: types.DELETE_POST_SUCCESS,
  payload: {
    data,
    postNew
  }
});

export const getListPinPostSuccess = (data: any) => ({
  type: types.GET_PIN_POST_SUCCESS,
  payload: {
    data
  }
});

export const getListPinPostReq = (id: any, type: string, path) => ({
  type: types.GET_PIN_POST_REQ,
  payload: {
    id,
    type,
    path
  }
});

export const getListBookmarkCollectionReq = () => ({
  type: types.GET_LIST_BOOKMARK_COLLECTION_REQ
});

export const getListBookmarkCollectionSuccess = (data: Object) => ({
  type: types.GET_LIST_BOOKMARK_COLLECTION_SUCCESS,
  payload: {
    data
  }
});

export const pinPostSuccess = (id: any, data: any) => ({
  type: types.PIN_POST_SUCCESS,
  payload: {
    id,
    data
  }
});

export const unpinPostSuccess = (id: any, data: any) => ({
  type: types.UNPIN_POST_SUCCESS,
  payload: {
    id,
    data
  }
});

export const bookmarkPostSuccess = (id: any, data: any) => ({
  type: types.SAVE_POST_SUCCESS,
  payload: {
    id,
    data
  }
});

export const unbookmarkPostSuccess = (id: any, data: any) => ({
  type: types.UNSAVE_POST_SUCCESS,
  payload: {
    id,
    data
  }
});

export const unTagMention = (id: string) => ({
  type: types.UN_TAG_MENTION,
  payload: id
});
export const mutePostSuccess = (id: any, data: any) => ({
  type: types.MUTE_POST_SUCCESS,
  payload: {
    id,
    data
  }
});
export const addPostFromDiary = (data: any) => ({
  type: types.ADD_POST_FROM_DIARY,
  payload: data
});
export const unmutePostSuccess = (id: any, data: any) => ({
  type: types.UNMUTE_POST_SUCCESS,
  payload: {
    id,
    data
  }
});

export const errorPost = (data: any) => ({
  type: types.ERROR_POST,
  payload: {
    data
  }
});

export const resetErrorPost = () => ({
  type: types.RESET_ERROR_POST
});

export const getSchedulePostReq = (id: any, type: any) => ({
  type: types.GET_SCHEDULED_POST_REQ,
  payload: {
    id,
    type
  }
});

export const getSchedulePostSuccess = (data: any) => ({
  type: types.GET_SCHEDULED_POST_SUCCESS,
  payload: { data }
});

export const schedulePostSuccess = (id: any) => ({
  type: types.SCHEDULED_POST_SUCCESS,
  payload: { id }
});

export const loadingPostScheduled = (type: string) => ({
  type: types.LOADING_POST_SCHEDULED,
  payload: { type }
});

export const reactionPost = (
  id: any,
  favourites: any,
  viewer_reaction: any,
  reactions: any
) => ({
  type: types.REACTIONS_POST,
  payload: { id, favourites, viewer_reaction, reactions }
});

export const updateReactionsPost = (data: any,) => ({
  type: types.UPDATE_REACTIONS_POST,
  payload: {data}
})

export const listPostTags = listPosts => ({
  type: types.LIST_POST_TAGS,
  payload: listPosts
});

export const updatePostsTagsUsers = idPostTags => ({
  type: types.UPDATE_POST_TAGS,
  payload: idPostTags
});
export const updateIsLoad = (value: any) => ({
  type: types.UPDATE_IS_LOAD,
  payload: value
});

export const resetNotiGroup = () => ({
  type: types.RESET_NOTI_GROUP
});
export const notiPost = (data: any) => ({
  type: types.NOTI_POST,
  payload: data
});
export const resetNotiPost = () => ({
  type: types.RESET_NOTI_POST
});
export const resetNotiScheduledPostGroup = () => ({
  type: types.RESET_NOTI_SCHEDULED_POST_GROUP
});

export const isUpdatingPost = (value: any) => ({
  type: types.IS_UPDATING_POST,
  payload: value
});

export const updateFollowPage = (data: any, type) => ({
  type: types.UPDATE_FOLLOW_SUCCESS,
  payload: { data, type }
});
export const notiSharePost = (data: any) => ({
  type: types.NOTI_SHARE_POST,
  payload: data
});
export const resetNotiSharePost = () => ({
  type: types.RESET_NOTI_SHARE_POST
});
export const updateIdsWatchHistory = (id: any) => ({
  type: types.UPDATE_ID_WATCH_HISTORY,
  payload: id
});

export const statusProcessing = type => ({
  type: types.STATUS_PROCESSING_POST,
  payload: type
});

export const addPostToView = (data: any) => ({
  type: types.ADD_POST_TO_VIEW,
  payload: data
});

export const removePostToView = (data: any) => ({
  type: types.REMOVE_POST_TO_VIEW,
  payload: data
});
