import { Box, CardMedia, Grid, ListItemText, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { buttonColor } from 'src/constants/styles';

const useStyles = makeStyles((theme: any) => ({
  wrapBox: {
    width: '80px',
    height: '80px',
    border: '1px dashed',
    borderColor: theme.palette.divider,
    borderRadius: '10px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  imgUpdate: {
    '&:hover .hidden-btn': {
      display: 'flex'
    }
  },
  textDescription: {
    [theme.breakpoints.down('xl')]: {
      marginLeft: '40px'
    }
  }
}));
const UploadMultipleFiles = props => {
  const {
    type,
    action,
    fileUpload,
    heightBoxWrap,
    widthBoxWrap,
    paddingBoxWrap,
    showIconEdit,
    optionIcon,
    handleIcon,
    height,
    label,
    validationSchema,
    formik,
    field,
    setErrorMedia,
    disabled,
    maxImage,
    showRenderTextDescription = true,
    disabledAction = false
  } = props;
  const classes = useStyles();
  const [files, setFiles] = React.useState<any>([]);
  const [progress, setProgress] = React.useState<any>(0);

  const { getRootProps, getInputProps } = useDropzone({
    accept:
      type === 'file_image'
        ? 'image/jpeg,image/png,image/jpg'
        : 'video/mp4,video/avi,video/quicktime,video/Ogg,video/wmv,video/mov',
    multiple: type === 'file_image' ? true : false,
    // maxFiles: type === 'file_image' ? maxImage : 1,
    onDrop: async (acceptedFiles: any) => {
      try {
        let filesState = [...files];
        let fileAccepts = acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        );
        let newFiles = fileAccepts.concat(filesState);

        if (type === 'file_image') {
          if (newFiles?.length <= maxImage) {
            setFiles(newFiles);
          } else {
            setFiles(newFiles);
            formik.setFieldError(
              field,
              `Bạn chỉ có thể tải lên không quá ${maxImage} ảnh`
            );
          }
        } else {
          // Kiểm tra kích thước video
          if (newFiles?.length && newFiles[0].size > 30000000) {
            setErrorMedia('Dung lượng video quá lớn (Tối đa 30MB)');
          }

          // Kiểm tra độ phân giải video
          const videoElement = document.createElement('video');
          videoElement.src = URL.createObjectURL(newFiles[0]);
          videoElement.onloadedmetadata = (event: any) => {
            const width = videoElement.videoWidth;
            const height = videoElement.videoHeight;
            if (width > 1280 || height > 1280) {
              setErrorMedia('Độ phân giải video quá cao (tối đa 1280x1280)');
            }
            // Kiểm tra độ dài video
            if (newFiles[0] && event.target.duration > 61) {
              setErrorMedia('Video quá dài (tối đa 60s)');
            } else if (newFiles[0] && event.target.duration < 10) {
              setErrorMedia('Video quá ngắn (tối thiểu 10s)');
            }
          };

          // Kiểm tra định dạng video
          if (newFiles[0] && newFiles[0].type !== 'video/mp4') {
            setErrorMedia('Định dạng video không hợp lệ (phải là MP4)');
          }
          setFiles(newFiles);
        }
      } catch (error) {}
    }
  });

  const renderAdd = () => {
    return disabled ? null : (
      <Grid item xs={1.5}>
        <Box
          className={classes.wrapBox}
          {...getRootProps()}
          sx={{
            height: heightBoxWrap,
            width: widthBoxWrap,
            padding: paddingBoxWrap
          }}
        >
          <input {...getInputProps()} />
          <i
            className={
              type === 'file_image'
                ? 'fa-regular fa-image'
                : 'fa-regular fa-video-plus'
            }
            style={{ fontSize: '20px', color: buttonColor.backgroundColor }}
          ></i>
          <Typography
            fontSize={12}
            align="center"
            color={buttonColor.backgroundColor}
          >
            {type === 'file_image'
              ? `Thêm hình ảnh (${files.length ? files.length : 0}/${maxImage})`
              : 'Thêm video'}
          </Typography>
        </Box>
      </Grid>
    );
  };
  React.useEffect(() => {
    if (
      action &&
      files?.length &&
      (type === 'file_image' || type === 'file_video')
    ) {
      action(files);
    }
  }, [files?.length]);

  React.useEffect(() => {
    if (fileUpload?.length) {
      setFiles(() => fileUpload);
    }
  }, [fileUpload?.length]);

  //update list images when have image change by function edit
  React.useEffect(() => {
    if (formik && type === 'file_image') setFiles(formik.values[field]);
  }, [formik]);

  const listTextDescription = [
    'Kích thước: Tối đa 30Mb, độ phân giải không vượt quá 1280x1280px',
    'Độ dài: 10s-60s',
    'Định dạng: MP4 (không hỗ trợ vp9)',
    'Lưu ý: sản phẩm có thể hiển thị trong khi video đang được xử lý. Video sẽ tự động hiển thị sau khi đã xử lý thành công.'
  ];

  const renderTextDescription = () => {
    return (
      <Box sx={{ marginTop: '-4px' }} className={classes.textDescription}>
        {listTextDescription.map((line, index) => (
          <ListItemText
            sx={{
              '&.MuiListItemText-root': {
                margin: '0px'
              }
            }}
            primary={
              <Typography fontSize={12} color="#999">
                {line}
              </Typography>
            }
            key={index}
          />
        ))}
      </Box>
    );
  };

  const optionTwoIconInImage = (index, el) => {
    return (
      <Grid key={index} item className={showIconEdit ? classes.imgUpdate : ''}>
        <Box
          sx={{
            width: widthBoxWrap || '80px',
            height: heightBoxWrap || '80px',
            borderRadius: '10px',
            position: 'relative',
            border: '1px solid #eee'
          }}
        >
          <CardMedia
            component="img"
            src={el.preview ?? el.preview_url}
            alt=""
            sx={{ width: '100%', height: '100%', borderRadius: '10px' }}
          />
          {!disabledAction && (
            <Box
              sx={{ position: 'absolute', bottom: 0, right: 0, left: 0 }}
              display={'flex'}
              justifyContent={'space-around'}
            >
              {/* iconEdit */}
              <Box
                className="hidden-btn"
                sx={{
                  display: showIconEdit || 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#eaeaea',
                  opacity: 0.7,
                  width: '50%',
                  height: '30px',
                  borderRight: '1px solid rgba(0, 0, 0, 0.54)'
                }}
              >
                <i
                  className="fa-solid fa-pencil"
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    handleIcon.edit(index);
                  }}
                ></i>
              </Box>
              {/* iconDelete */}
              <Box
                className="hidden-btn"
                sx={{
                  display: showIconEdit || 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#eaeaea',
                  opacity: 0.7,
                  width: '50%',
                  height: '30px'
                }}
              >
                <i
                  className="fa-regular fa-xmark"
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleClickIconDeleteInImage(el, index)}
                ></i>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    );
  };

  const handleClickIconDeleteInImage = (el, indexSelected) => {
    if (formik) formik.setFieldTouched(field, true);

    const updatedFiles = files.filter((item, index) =>
      item?.id ? item.id !== el?.id : index !== indexSelected
    );

    setFiles(updatedFiles);
    if (formik) formik.setFieldValue(field, updatedFiles);
  };

  return (
    <Grid container spacing={1} sx={{ width: '100%' }}>
      {files?.length ? (
        type === 'file_image' ? (
          files.map((el, index) => {
            if (optionIcon === 2) {
              return optionTwoIconInImage(index, el);
            }
            return (
              <Grid
                key={index}
                item
                sx={{
                  position: 'relative'
                }}
              >
                <Box
                  sx={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '10px',
                    position: 'relative',
                    border: '1px solid #eee'
                  }}
                >
                  <CardMedia
                    component="img"
                    src={el.preview ?? el.preview_url}
                    alt=""
                    sx={{ width: '100%', height: '100%', borderRadius: '10px' }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      backgroundColor: '#eaeaea',
                      opacity: 0.7,
                      borderRadius: '50%',
                      width: '20px',
                      height: '20px',
                      top: '5px',
                      right: '5px'
                    }}
                  >
                    <i
                      className="fa-regular fa-xmark"
                      style={{
                        color: 'rgba(0, 0, 0, 0.54)',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleClickIconDeleteInImage(el, index)}
                    ></i>
                  </Box>
                </Box>
              </Grid>
            );
          })
        ) : (
          <>
            <Grid item xs={1.5}>
              <Box
                sx={{
                  width: widthBoxWrap || '80px',
                  height: heightBoxWrap || '80px',
                  borderRadius: '10px',
                  position: 'relative',
                  border: '1px solid #eee'
                }}
              >
                <video
                  src={files[0]?.preview ?? files[0]?.url}
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '10px'
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    backgroundColor: '#eaeaea',
                    opacity: 0.7,
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    top: '5px',
                    right: '5px'
                  }}
                >
                  <i
                    className="fa-regular fa-xmark"
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setFiles([]);
                      setErrorMedia(null);
                      formik.setFieldValue(field, null);
                    }}
                  ></i>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={10.5}>
              {showRenderTextDescription && renderTextDescription()}
            </Grid>
          </>
        )
      ) : null}
      {type === 'file_image'
        ? files?.length < maxImage && renderAdd()
        : !files?.length && (
            <>
              {renderAdd()}
              <Grid item xs={10.5}>
                {showRenderTextDescription && renderTextDescription()}
              </Grid>
            </>
          )}
    </Grid>
  );
};

export default UploadMultipleFiles;
