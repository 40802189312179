import React, { useState } from 'react';import { Typography, Link, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import avatarDefault from 'src/assets/images/avatar_default.jpg';

const useStyles = makeStyles((theme: Theme) => ({
  statusBackground: {
    minHeight: '400px',
    maxHeight: '500px',
    overflow: 'auto',
    width: '100%',
    backgroundRepeat: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundPosition: '50%'
  },
  textLineClamp: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    wordBreak: 'break-all',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  }
}));

function NewEventCreated(props) {
  const history = useHistory();
  const classes = useStyles();
  const { post } = props;
  const dispatch = useDispatch();
  return (
    <div style={{ position: 'relative' }}>
      {(post.event?.banner?.url
        ? post.event?.banner?.url
        : post.project?.banner?.url
        ? post.project?.banner?.url
        : post.recruit?.banner?.url
        ? post.recruit?.banner?.url
        : post.course?.banner?.url
        ? post.course?.banner?.url
        : avatarDefault) && (
        <div
          className={classes.statusBackground}
          style={{
            alignItems: 'end',
            backgroundImage: `url(${
              post.event?.banner?.url
                ? post.event?.banner?.url
                : post.project?.banner?.url
                ? post.project?.banner?.url
                : post.recruit?.banner?.url
                ? post.recruit?.banner?.url
                : post.course?.banner?.url
                ? post.course?.banner?.url
                : avatarDefault
            })`,
            cursor: 'pointer'
          }}
          onClick={() =>
            history.push(
              post.event
                ? `/event/${post.event.username ?? post.event.id}/about`
                : post.project
                ? `/project/${post.project.username ?? post.project.id}/about`
                : `/recruit/${post.recruit.username ?? post.recruit.id}/about`
            )
          }
        ></div>
      )}
      <div style={{ position: 'relative', display: 'flex' }}>
        <Link
          href={
            post.event
              ? `/event/${post.event.username ?? post.event.id}/about`
              : post.project
              ? `/project/${post.project.username ?? post.project.id}/about`
              : post.recruit
              ? `/recruit/${post.recruit.username ?? post.recruit.id}/about`
              : `/course/${post.course.username ?? post.course.id}/about`
          }
          underline="none"
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            height: '100%',
            backgroundColor: 'background.primary',
            cursor: 'pointer'
          }}
          onClick={() =>
            history.push(
              post.event
                ? `/event/${post.event.username ?? post.event.id}/about`
                : post.project
                ? `/project/${post.project.username ?? post.project.id}/about`
                : post.recruit
                ? `/recruit/${post.recruit.username ?? post.recruit.id}/about`
                : `/course/${post.course.username ?? post.course.id}/about`
            )
          }
        >
          <div
            style={{
              width: '86px',
              height: '86px',
              borderRadius: '8px',
              backgroundColor: '#fff',
              border: '1px solid rgba(0,0,0,0.10)',
              boxShadow: '0px 0px 8px rgba(176, 174, 174, 0.35)',
              zIndex: 30,
              margin: '5px 5px 5px 8px'
            }}
          >
            <div
              style={{
                height: '20px',
                backgroundColor: '#f3425f',
                borderTopRightRadius: '8px 8px',
                borderTopLeftRadius: '8px 8px'
              }}
            ></div>
            <Typography
              alignItems="center"
              sx={{
                fontWeight: '700',
                fontSize: 39,
                width: '86px',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              {post.event?.start_time?.slice(8, 10) ??
                post.project?.start_date?.slice(8, 10)}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'end',
              margin: '6px 12px'
            }}
          >
            <Typography
              sx={{
                color: '#f3425f',
                fontSize: 13,
                marginTop: '12px',
                fontWeight: 'bold'
              }}
              variant="h4"
            >
              {post.event?.start_time ?? post.project?.start_date}
              {post.event
                ? post.event?.end_time
                : post.project
                ? post.project?.due_date
                : post?.recruit?.due_date}
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography
                className={classes.textLineClamp}
                sx={{
                  fontWeight: 'bold !important',
                  fontSize: 16
                }}
              >
                {post.event
                  ? post.event.title
                  : post.project
                  ? post.project.title
                  : post.recruit
                  ? post.recruit.title
                  : post.course.title}
              </Typography>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default NewEventCreated;
