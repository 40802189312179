import axios, { AxiosRequestConfig } from 'axios';
import { USER_TOKEN } from 'src/util/config';
import { BASE_ROOT } from 'src/util/config';

const fetchApi = async (endPoint, method, params, data = null) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data
  };

  let response = await axios(configs);
  return response;
};

export const requestFriendApi = async (userId: string) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/accounts/${userId}/friendship_requests`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };
  let response = await axios(configs);
  return response;
};

export const listRequestFriendApi = async params => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/friendship_requests`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };
  let response = await axios(configs);
  return response;
};

export const listRequestToFriendApi = async params => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/requested_friendships`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };
  let response = await axios(configs);
  return response;
};

export const approveRequestFriendApi = async (userId: string) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/accounts/${userId}/approve_friendship`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };
  let response = await axios(configs);
  return response;
};

export const deleteRequestFriendApi = async (userId: string) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/accounts/${userId}/reject_friendship`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };
  let response = await axios(configs);
  return response;
};

export const cancelRequestFriendApi = async (userId: string) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/accounts/${userId}/cancel_friendship_requests`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };
  let response = await axios(configs);
  return response;
};

export const getInforUser = async (userId: string, source) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/accounts/${userId}`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    [`${source ? 'cancelToken' : ''}`]: source ? source.token : ''
  };
  let response = await axios(configs);
  return response;
};

export const getMediaUser = async (
  userId: string,
  params: any,
  source: any
) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/accounts/${userId}/media_attachments`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    [`${source ? 'cancelToken' : ''}`]: source ? source.token : '',
    params
  };
  let response = await axios(configs);
  return response;
};

export const getFriendUser = async (
  userId: string,
  params: any = null,
  source: any
) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/accounts/${userId}/friendships`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    [`${source ? 'cancelToken' : ''}`]: source ? source.token : '',
    params
  };
  let response = await axios(configs);
  return response;
};

export const searchFriendUser = async (userId: string, params: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/friendships`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };
  let response = await axios(configs);
  return response;
};

export const unFriendUser = async (userId: string) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/accounts/${userId}/unfriend`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };
  let response = await axios(configs);
  return response;
};

export const followUser = async (userId: string) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/accounts/${userId}/follow`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };
  let response = await axios(configs);
  return response;
};

export const unFollowUser = async (userId: string) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/accounts/${userId}/unfollow`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };
  let response = await axios(configs);
  return response;
};

export const updateCredentialsUser = async data => {
  var formdata = new FormData();
  if (data.avatar) {
    if (data.avatar.file) {
      formdata.append('avatar[file]', data.avatar.file);
    }
    if (data.avatar.id) {
      formdata.append('avatar[id]', data.avatar.id);
    }
    if (data.avatar.show_url) {
      formdata.append('avatar[show_url]', data.avatar.show_url);
    }
    formdata.append('avatar[status]', data.avatar.status ?? '');
    formdata.append('avatar[frame_id]', data.avatar.frame_id ?? '');
  }
  if (data.header) {
    if (data.header.file) {
      formdata.append('header[file]', data.header.file);
    }
    if (data.header.id) {
      formdata.append('header[id]', data.header.id);
    }
    formdata.append('header[show_url]', data.header.show_url ?? '');
    formdata.append('header[status]', data.header.status ?? '');
  }

  if (data.hobby_ids) {
    formdata.append('hobby_ids', data.hobby_ids);
  }
  if (data.display_name) {
    formdata.append('display_name', data.display_name);
  }
  if (data.username) {
    formdata.append('username', data.username);
  }
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/accounts/update_credentials`,
    method: 'patch',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    data: formdata
  };
  let response = await axios(configs);
  return response;
};

export const followingUser = async (userId: string, params) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/accounts/${userId}/followings`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };
  let response = await axios(configs);
  return response;
};

export const followerUser = async (userId: string, params) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/accounts/${userId}/followers`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };
  let response = await axios(configs);
  return response;
};

export const getAboutUser = async (userId: string, source: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/accounts/${userId}/abouts`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    [`${source ? 'cancelToken' : ''}`]: source ? source.token : ''
  };
  let response = await axios(configs);
  return response;
};

export const getRelationshipCategories = async () => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/relationship_categories`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };
  let response = await axios(configs);
  return response;
};

export const getRelationshipFamily = async () => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/family_relationship_categories`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };
  let response = await axios(configs);
  return response;
};

export const CRUDRelationshipFamily = async () => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/family_relationships`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };
  let response = await axios(configs);
  return response;
};

export const getListInvitedAdminPage = async () => {
  return fetchApi(`page_invitations`, 'GET', null);
};

export const getStatusUser = async () => {
  return fetchApi(`account_settings`, 'GET', null);
};

export const getFriendDiscover = async params => {
  return fetchApi(`friend_suggestions`, 'GET', params, null);
};

export const postStatusUser = async data => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/account_settings`,
    method: 'post',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params: null,
    data
  };
  let response = await axios(configs);
  return response;
};

export const getListObjectBlock = async (object: any, params: any) => {
  return fetchApi(`${object}`, 'GET', params);
};
export const addObejctBlock = async (object: any, id: any) => {
  return fetchApi(`${object}`, 'POST', null, id);
};
export const removeObjectBlock = async (object: any, id: any) => {
  return fetchApi(`${object}/${id}`, 'DELETE', null, null);
};
export const postUpdateInfoUser = async (data: any) => {
  return fetchApi(`account_general_infomation`, 'PATCH', null, data);
};

export const getAccountGeneralInformation = async () => {
  return fetchApi(`account_general_infomation`, 'GET', null, null);
};

export const postUpdateRelationshipUser = async (data: any, type, about) => {
  return fetchApi(
    type === 'create'
      ? 'account_relationships'
      : `account_relationships/${about.id}`,
    type === 'create' ? 'POST' : 'PATCH',
    null,
    data
  );
};
export const deleteFamilyRelationship = async (id: any) => {
  return fetchApi(`family_relationships/${id}`, 'DELETE', null);
};
export const addFamilyRelationship = async (data: any) => {
  return fetchApi(`family_relationships`, 'POST', null, data);
};
export const updateFamilyUser = async (data: any, id: any) => {
  return fetchApi(`family_relationships/${id}`, 'PATCH', null, data);
};
export const updateHobbyUser = async (data: any) => {
  return fetchApi(`accounts/update_credentials`, 'PATCH', null, data);
};
export const getHobbyUser = async () => {
  return fetchApi(`accounts/update_credentials`, 'GET', null, null);
};
export const changePassword = async (data: any) => {
  return fetchApi(`change_password`, 'POST', null, data);
};
export const checkPasswordUser = async (data: any) => {
  return fetchApi(`valid_password`, 'POST', null, data);
};
export const getLifeEventUser = async (userId: any) => {
  return fetchApi(`accounts/${userId}/life_events`, 'GET', null, null);
};
export const updateLifeEventAccount = async (userId, idLifeEvent, data) => {
  return fetchApi(
    `accounts/${userId}/life_events/${idLifeEvent}`,
    'PATCH',
    null,
    data
  );
};
export const deleteLifeEventAccount = async (userId: any, idLifeEvent) => {
  return fetchApi(
    `accounts/${userId}/life_events/${idLifeEvent}`,
    'DELETE',
    null,
    null
  );
};

export const deleteRelationship = async (relationshipId: any) => {
  return fetchApi(
    `account_relationships/${relationshipId}`,
    'DELETE',
    null,
    null
  );
};

export const blockPages = async (data: any) => {
  return fetchApi(`block_pages`, 'POST', null, data);
};
export const getListBlockPages = async (params: any) => {
  return fetchApi('block_pages', 'GET', params, null);
};
export const getListCategoriesReport = async () => {
  return fetchApi('report_categories', 'GET', null, null);
};
export const sendReport = async (data: any) => {
  return fetchApi('report_violations', 'POST', null, data);
};
export const reportPost = async (params: any) => {
  return fetchApi('reports', 'POST', null, params);
};

export const getListNoticeableCollection = async (userId: any) => {
  return fetchApi(`accounts/${userId}/featured_contents`, 'GET', null, null);
};

export const getListMediaCollection = async (
  userId: any,
  collectionId: any,
  params: any
) => {
  return fetchApi(
    `accounts/${userId}/featured_contents/${collectionId}/media_attachments`,
    'GET',
    null,
    params
  );
};

export const CUNoticeableCollection = async (data, id) => {
  return fetchApi(
    `account_featured_contents${id ? '/' + id : ''}`,
    id ? 'PATCH' : 'POST',
    null,
    data
  );
};

export const deleteNoticeableCollection = async (id: any) => {
  return fetchApi(`account_featured_contents/${id}`, 'DELETE', null, null);
};

export const searchPostUser = async (userId: any, params: any) => {
  return fetchApi(`accounts/${userId}/statuses/search`, 'GET', params, null);
};
export const getHistorysearch = async (params: any) => {
  return fetchApi(`search_histories`, 'GET', params, null);
};
export const deleteHistorySearch = async (id: any) => {
  return fetchApi(`search_histories/${id}`, 'DELETE', null, null);
};
export const deleteAllHistorySearch = async () => {
  return fetchApi(`all_search_histories`, 'DELETE', null, null);
};
export const getHistoryPosts = async (params: any) => {
  return fetchApi(`watch_histories`, 'GET', params, null);
};
export const deleteHistoryPost = async (id: any) => {
  return fetchApi(`watch_histories/${id}`, 'DELETE', null, null);
};

export const completeTargetUser = async (id: any) => {
  return fetchApi(`statuses/${id}/complete_target`, 'POST', null, null);
};

export const turnOnNotification = async (data: any) => {
  return fetchApi(`allow_notification_posts`, 'POST', null, data);
};
export const socialCategories = async source => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/social_categories`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    [`${source ? 'cancelToken' : ''}`]: source ? source.token : ''
  };
  let response = await axios(configs);
  return response;
};

export const getPinnedPostUser = async (idUser: any, params: any) => {
  return fetchApi(`accounts/${idUser}/pin`, 'GET', params, null);
};

export const getListAccountsSuggest = async (idUser: any, params: any) => {
  return fetchApi(
    `accounts/${idUser}/follow_moments/suggest_moments`,
    'GET',
    params,
    null
  );
};

export const getListAccountsFollow = async (idUser: any, params: any) => {
  return fetchApi(`accounts/${idUser}/follow_moments`, 'GET', params, null);
};
export const unBlockPages = async (idPage: any) => {
  return fetchApi(`block_pages/${idPage}`, 'DELETE', null, null);
};

export const getListPageLikes = async (idUser: any, params: any) => {
  return fetchApi(`accounts/${idUser}/page_likes`, 'GET', params, null);
};

export const getListHashTagMoment = async (params: any) => {
  return fetchApi(`trends/tags`, 'GET', params, null);
};

export const requestBadgeApi = async (data: any) => {
  return fetchApi(`account_request_certifications`, 'POST', null, data);
};

export const fetchFamilyRelationship = async (id: any, data: any) => {
  return fetchApi(`response_family_relationship/${id}`, 'PATCH', null, data);
};

export const fetchAccountRelationship = async (id: any, data: any) => {
  return fetchApi(`response_account_relationship/${id}`, 'PATCH', null, data);
};

export const verificationsUser = async (data: any, type: any) => {
  const formData: any = new FormData();

  formData.append('name', data.name);
  formData.append('phone_number', data.phone_number);

  if (data.identity_card_back) {
    formData.append('identity_card_back[id]', data.identity_card_back.id);
  }
  if (data.identity_card_front) {
    formData.append('identity_card_front[id]', data.identity_card_front.id);
  }
  if (data.identity_verification_video) {
    formData.append(
      'identity_verification_video[id]',
      data.identity_verification_video.id
    );
  }

  return fetchApi(
    `identity_verifications/${type === 'update' ? '1' : ''}`,
    `${type === 'create' ? 'POST' : 'PATCH'}`,
    null,
    formData
  );
};

export const getListVerificationUser = () => {
  return fetchApi(`identity_verifications`, 'GET', null, null);
};
export const getVerificationUser = (id: any) => {
  return fetchApi(`identity_verifications/${id}`, 'GET', null, null);
};
export const getVerificationPage = (id: any) => {
  return fetchApi(`page_identity_verifications/${id}`, 'GET', null, null);
};
export const openCriteriaMoney = data => {
  return fetchApi(`earn_moneys`, 'POST', null, data);
};
export const updateCriteriaMoney = (id: any) => {
  return fetchApi(`earn_moneys/${id}`, 'PATCH', null, null);
};
// export const checkStatusEarnMoney = data => {
//   return fetchApi(`check_status`, 'POST', null, data);
// };
export const disabledUser = () => {
  return fetchApi(`suspend`, 'POST', null, null);
};

export const deactivateUser = data => {
  return fetchApi(`deactivation`, 'POST', null, data);
};
export const getCategoriesFeedback = () => {
  return fetchApi('feedback_categories', 'GET', null, null);
};
export const sendFeedback = data => {
  return fetchApi('feedbacks', 'POST', null, data);
};
