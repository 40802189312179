import { Menu, MenuItem, Typography } from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deletePostApi, getPostDetailApi } from 'src/apis/socialPost.api';
import DialogConfirmDelete from 'src/components/Dialog/DialogConfirmDelete';
import DialogReport from 'src/components/Dialog/DialogReport';
import DialogReportAdminGroup from 'src/components/Dialog/DialogReportAdminGroup';
import { commentTotal } from 'src/store/action/socialPostAction';

export default function BasicMenu(props) {
  const {
    meInfo,
    info,
    anchorEl,
    setAnchorEl,
    setPostComment,
    setIsUpdate,
    type,
    post,
    setCommentInfo
  } = props;

  const open = Boolean(anchorEl);

  const [openDelete, setOpenDelete] = React.useState(false);
  const [openDialogReport, setOpenDialogReport] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openDialogAdminReport, setOpenDialogAdminReport] =
    React.useState(false);
  const ReportInfo = useSelector(
    (state: any) => state.socialReportReducer.listReport
  );
  const adminsGroup =
    useSelector((state: any) => state?.groupReducer?.adminsGroup?.admins) ?? {};

  const moderatorsGroup =
    useSelector(
      (state: any) => state?.groupReducer?.moderatorsGroup?.moderators
    ) ?? {};

  const dispatch = useDispatch();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkRoleGroup = () => {
    let resultAdmin = adminsGroup?.find(
      (el: any) => el?.account?.id === meInfo.id
    );
    let resultModerator = moderatorsGroup?.find(
      (el: any) => el?.account?.id === meInfo.id
    );
    if (!!resultAdmin === false && !!resultModerator === false) {
      return false;
    } else return true;
  };
  const handleDelete = async () => {
    let res = await deletePostApi(info.id);
    if (res.status === 200) {
      let response = await getPostDetailApi(res?.data?.in_reply_to_id);
      if (response.status === 200) {
        setCommentInfo(prev => ({
          ...prev,
          commentTotal: response.data.replies_total
        }));
        dispatch(commentTotal(response.data, post.id));
      }
      setLoading(false);
      setPostComment(null);
      setOpenDelete(false);
    }
  };

  const listActionComment = [
    {
      id: 1,
      nameAction: 'Chỉnh sửa bình luận',
      action: () => {
        setIsUpdate(true);
        handleClose();
      },
      visible: meInfo?.id === info?.account?.id ? true : false
    },
    {
      id: 2,
      nameAction: 'Xóa bình luận',
      action: () => {
        setOpenDelete(true);
        handleClose();
      },
      visible:
        meInfo?.id === info?.account?.id ||
        (meInfo?.id === post?.account?.id &&
          type !== 'stream_group' &&
          type !== 'all_group')
          ? true
          : false
    },
    // {
    //   id: 3,
    //   nameAction: 'Ẩn bình luận',
    //   action: () => {
    //     // setOpenDelete(true);
    //     handleClose();
    //   },
    //   visible:
    //     meInfo?.id !== info?.account?.id ||
    //     (meInfo?.id !== info?.account?.id && meInfo?.id === post?.account?.id)
    //       ? true
    //       : false
    // },
    {
      id: 4,
      nameAction: 'Báo cáo bình luận',
      action: () => {
        setOpenDialogReport(true);
        handleClose();
      },
      visible:
        (meInfo?.id !== info?.account?.id &&
          type !== 'stream_group' &&
          type !== 'all_group') ||
        (meInfo?.id !== info?.account?.id &&
          (type === 'stream_group' || type === 'all_group') &&
          checkRoleGroup() === false)
          ? true
          : false
    },
    {
      id: 5,
      nameAction: 'Gỡ bình luận',
      action: () => {
        setOpenDelete(true);
        handleClose();
      },
      visible:
        meInfo?.id !== info?.account?.id &&
        checkRoleGroup() === true &&
        (type === 'stream_group' || type === 'all_group')
          ? true
          : false
    },
    {
      id: 6,
      nameAction: 'Báo cáo bình luận với quản trị viên',
      action: () => {
        setOpenDialogAdminReport(true);
        handleClose();
      },
      visible:
        (meInfo?.id !== info?.account?.id ||
          (checkRoleGroup() === true && meInfo?.id !== info?.account?.id)) &&
        (type === 'stream_group' || type === 'all_group')
          ? true
          : false
    }
  ];
  const renderAction = (el: any, index) => {
    return (
      <MenuItem key={index} onClick={el?.action}>
        <Typography sx={{ fontWeight: 600 }} variant="body1">
          {el?.nameAction}
        </Typography>
      </MenuItem>
    );
  };
  return (
    <div>
      <Menu
        id="simple-menu2"
        anchorEl={anchorEl}
        open={open}
        keepMounted
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '.MuiList-root': {
            width: 'auto !important'
          }
        }}
      >
        {listActionComment
          ?.filter((el: any) => el.visible === true)
          ?.map((item: any, index) => renderAction(item, index))}
      </Menu>

      {openDelete && (
        <DialogConfirmDelete
          open={openDelete}
          handleClose={() => setOpenDelete(false)}
          title="Xác nhận xóa"
          text="Bạn có chắc chắn muốn xóa bình luận này không?"
          action={() => {
            handleDelete();
            setLoading(true);
          }}
          loading={loading}
          setLoading={setLoading}
        />
      )}
      {openDialogReport && (
        <DialogReport
          open={openDialogReport}
          handleClose={() => setOpenDialogReport(false)}
          title={'Báo cáo'}
          headerText={'Tìm hỗ trợ hoặc báo cáo bình luận'}
          headerSubText={'Bạn có thể báo cáo bình luận sau khi chọn vấn đề.'}
          listCategoriesReport={ReportInfo}
          info={info}
        />
      )}
      {openDialogAdminReport && (
        <DialogReportAdminGroup
          open={openDialogAdminReport}
          handleClose={() => setOpenDialogAdminReport(false)}
          info={info}
          // typeReport={typeReport}
        />
      )}
    </div>
  );
}
