import axios, { AxiosRequestConfig } from 'axios';
import { BASE_ROOT_MARKET, USER_TOKEN } from 'src/util/config';
import { BASE_ROOT } from 'src/util/config';

const fetchApi = async (endPoint, method, params, formdata) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: formdata
  };

  let response = await axios(configs);
  return response;
};
const fetchApiRequest = async (endPoint, method, params, _data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

const fetchApiRequestMarket = async (endPoint, method, params, _data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT_MARKET}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

export const getCalendarGrowApi = async (params: any) => {
  return fetchApiRequest('projects', 'GET', params, null);
};
export const getHighlightedProject = async (params: any) => {
  return fetchApiRequest('top_projects', 'GET', params, null);
};

export const createUpdateGrowApi = async (data: any, type: any) => {
  var formdata = new FormData();
  formdata.append('title', data.title);
  data.project_host_added.forEach(item => {
    formdata.append('project_host_added[]', item);
  });
  data.project_host_removed.forEach(item => {
    formdata.append('project_host_removed[]', item);
  });
  if (data.banner) {
    if (data.banner.file && !data.banner.id) {
      formdata.append('banner[id]', data.banner.file);
    }
    if (data.banner.id) {
      formdata.append('banner[id]', data.banner.id);
      if (data.banner.show_url) {
        formdata.append('banner[show_url]', data.banner.show_url);
      }
    }
  }
  if (data.commitment_form) {
    if (data.commitment_form.file && data.commitment_form.id) {
      formdata.append('commitment_form[id]', data.commitment_form.id);
    }
  }
  if (data.business_license) {
    if (data.business_license.file && data.business_license.id) {
      formdata.append('business_license[id]', data.business_license.id);
    }
  }
  if (data.pitchdeck) {
    if (data.pitchdeck.file && data.pitchdeck.id) {
      formdata.append('pitchdeck[id]', data.pitchdeck.id);
    }
  }

  if (data.introduction_video) {
    if (data.introduction_video.file && data.introduction_video.id) {
      formdata.append('introduction_video[id]', data.introduction_video.id);
    }
  }
  if (data.location) {
    formdata.append('location[lat]', data.location.lat);
    formdata.append('location[lng]', data.location.lng);
  }
  if (data.phone_number) {
    formdata.append('phone_number', data.phone_number);
  }
  if (data.refund_policy) {
    formdata.append('refund_policy', data.refund_policy);
  }
  if (data.risks) {
    formdata.append('risks', data.risks);
  }
  if (data.visibility) {
    formdata.append('visibility', data.visibility);
  }
  if (data.description_project) {
    formdata.append('description_project', data.description_project);
  }
  if (data.description_company) {
    formdata.append('description_company', data.description_company);
  }
  if (data.plan) {
    formdata.append('plan', data.plan);
  }
  if (data.address) {
    formdata.append('address', data.address);
  }
  if (data.category_id) {
    formdata.append('category_id', data.category_id);
  }
  if (data.target_value) {
    formdata.append('target_value', data.target_value);
  }
  if (data.start_date) {
    formdata.append('start_date', data.start_date);
  }
  if (data.due_date) {
    formdata.append('due_date', data.due_date);
  }
  if (data.page_owner_id) {
    formdata.append('page_owner_id', data.page_owner_id);
  }
  if (data.page_id) {
    formdata.append('page_id', data.page_id);
  }

  return fetchApi(
    type === 'create' ? 'projects' : `projects/${type}`,
    type === 'create' ? 'POST' : 'PATCH',
    null,
    formdata
  );
};

export const getDetailGrowApi = async (idGrow: any) => {
  return fetchApi(`projects/${idGrow}`, 'GET', null, null);
};

export const deleteGrowApi = async (id: any) => {
  return fetchApi(`projects/${id}`, 'DELETE', null, null);
};

// export const shareGrowApi = async (params: any, idGrow: any) => {
//   return fetchApi(`projects/${idGrow}/share`, 'POST', params, null);
// };

export const getTimelineGrowApi = async (idGrow: any, params: any) => {
  return fetchApi(`timelines/project/${idGrow}`, 'GET', params, null);
};

export const createInviteGrow = async (idGrow: any, params: any) => {
  return fetchApi(`projects/${idGrow}/invitations`, 'POST', null, {
    target_account_ids: params
  });
};

export const interesteGrowApi = async (idGrow: any) => {
  return fetchApiRequest(
    `projects/${idGrow}/project_followers`,
    'POST',
    null,
    null
  );
};
export const noInteresteGrowApi = async (idGrow: any) => {
  return fetchApiRequest(
    `projects/${idGrow}/project_followers`,
    'DELETE',
    null,
    null
  );
};

export const getListInvitation = async (params: any) => {
  return fetchApiRequest('project_invitations', 'GET', params, null);
};

export const respondInvitationGrow = async (idGrow: any, params: any) => {
  return fetchApiRequest(
    `projects/${idGrow}/invitations/invitations_respond`,
    'POST',
    null,
    params
  );
};

export const getListHostOfGrow = async (idGrow: any, params: any) => {
  return fetchApiRequest(
    `projects/${idGrow}/invitation_hosts`,
    'GET',
    params,
    null
  );
};

export const respondInvitationHostGrow = async (idGrow: any, data: any) => {
  return fetchApiRequest(
    `projects/${idGrow}/invitation_hosts/invitations_respond`,
    'POST',
    null,
    data
  );
};
export const investmentRequest = async (idGrow: any, data: any) => {
  return fetchApiRequest(
    `projects/${idGrow}/request_invests`,
    'POST',
    null,
    data
  );
};

export const getListHostGrow = async (idGrow: any, params: any) => {
  return fetchApiRequest(`projects/${idGrow}/hosts`, 'GET', params, null);
};

export const getListFaqs = async (idGrow: any) => {
  return fetchApiRequest(`projects/${idGrow}/faqs`, 'GET', null, null);
};

export const CRUDFaqs = async (type, idGrow: any, params: any, data) => {
  return fetchApiRequest(
    `projects/${idGrow}/faqs${
      type === 'patch' || type === 'delete' ? '/' + params : ''
    }`,
    type,
    null,
    data
  );
};

export const getListInvitationHost = async (params: any) => {
  return fetchApiRequest('project_invitation_hosts', 'GET', params, null);
};

export const postRepostGrow = async (data: any) => {
  var formdata = new FormData();

  formdata.append('note', data.note);
  if (data.report_category_id) {
    formdata.append('report_category_id', data.report_category_id);
  }
  if (data.entity_id) {
    formdata.append('entity_id', data.entity_id);
  }
  if (data.entity_type) {
    formdata.append('entity_type', data.entity_type);
  }

  return fetchApi(`report_violations`, 'POST', null, formdata);
};
export const verificationsUser = async (data: any) => {
  var formdata = new FormData();

  if (data.idCardBack) {
    formdata.append('identity_card_back[id]', data.idCardBack);
  }
  if (data.idCardFont) {
    formdata.append('identity_card_front[id]', data.idCardFont);
  }
  if (data.idVideo) {
    formdata.append('identity_verification_video[id]', data.idVideo);
  }

  return fetchApi(`identity_verifications`, 'POST', null, formdata);
};

export const postPaymentGrow = async (data: any, idGrow) => {
  var formdata = new FormData();
  formdata.append('amount', data.amount);
  formdata.append('detail_type', data.detail_type);

  return fetchApi(`projects/${idGrow}/transaction`, 'POST', null, formdata);
};

export const getAccountBacker = (idGrow: any) => {
  return fetchApi(`projects/${idGrow}/list_backer`, 'GET', null, null);
};
export const getMediaGrow = (growId: any, params: any) => {
  return fetchApi(`projects/${growId}/media_attachments`, 'GET', params, null);
};

export const reportCourseApi = (data: any) => {
  return fetchApiRequestMarket(`report_violations`, 'POST', null, data);
};
