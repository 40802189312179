import { Box, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import DialogEditProfile from 'src/components/Dialog/DialogEditProfile/DialogEditProfile';
import ProfileCardImage from 'src/components/ProfileCardImage/Index';
import { useRouteMatch } from 'react-router-dom';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import { useTheme } from '@mui/material/styles';
import { useMatchMedia } from 'src/common/useMatchMedia';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import SocialHeaderTab from 'src/components/SocialHeaderTab';
import DialogConfirmation from 'src/components/Dialog/DialogConfirmDelete';
import { deleteGroupApi, deleteMemberGroupApi } from 'src/apis/socialGroup';
import {
  getGroupAccountReq,
  getRemoveAccountReq,
  groupRelationShipReq
} from 'src/store/action/socialGroupAction';
const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      width: '100% !important',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative'
    },
    headerProfile: {
      // maxWidth: 1320,
      width: '100%'
    },
    avatar: {
      width: '168px !important',
      height: '168px !important',
      margin: '0px 20px',
      marginTop: -35,
      zIndex: 15,
      border: '5px solid #fff',
      cursor: 'pointer',
      backgroundColor: theme.palette.button.primary.hover
    },
    headerProfileDetail: {
      display: 'flex',
      alignItems: 'flex-end'
    },
    cardContent: {
      width: '70%',
      overflow: 'hidden',
      paddingLeft: '10px'
    },
    textName: {
      fontSize: '32px !important',
      fontWeight: '700 !important'
    },
    profileMenu: {
      width: '95%',
      maxWidth: 1200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    wrapAvatar: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      paddingBottom: 20,
      maxWidth: 1200,
      width: '95%',
      marginTop: '20px'
    },
    divAvatar: {
      position: 'relative'
    },
    textFriend: {
      display: 'flex',
      fontSize: '17px !important',
      color: '#65676B',
      fontWeight: '500 !important'
    }
  })
);

const iconStyle = { fontSize: '20px' };

export default function Index({
  keyTab,
  setKeyTab,
  aboutUser,
  setAboutUser,
  lifeEvents,
  groupSelected,
  groupRelationShip
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const match: any = useRouteMatch();
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const adminsGroup =
    useSelector((state: any) => state?.groupReducer.adminsGroup.admins) ?? {};
  const moderatorsGroup =
    useSelector(
      (state: any) => state?.groupReducer.moderatorsGroup.moderators
    ) ?? {};
  const membersGroup =
    useSelector((state: any) => state?.groupReducer.membersGroup.members) ?? {};
  const friendsGroup =
    useSelector((state: any) => state?.groupReducer.friendsGroup.friends) ?? {};
  const buttonSocial = useSelector(
    (state: any) => state.userReducer.listSocial
  );
  const isMobile = useMatchMedia('(min-width:500px)', true);
  const isButtonResolution = useMatchMedia('(min-width:910px)', true);
  const noneMobile = useMatchMedia('(min-width:410px)', true);
  const [openSnackbar, setOpenSnackbar] = useState<any>(false);
  const [noti, setNoti] = useState<any>({
    code: 200,
    message: ''
  });
  const [openDialogEditProfile, setOpenDialogEditProfile] =
    React.useState(false);
  const [openDialogOutGroup, setOpenDialogOutGroup] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const history = useHistory();
  const getMemberGroup = useCallback(
    (id, params, type) => {
      dispatch(getGroupAccountReq(id, params, type));
    },
    [dispatch]
  );
  const funcLoad = type => {
    let params = {
      role: type
    } as any;

    if (type === 'friend') {
      params.role = 'member';
      params.include_friend = true;
    } else if (type === 'member') {
      params.exclude_friend = true;
    }

    getMemberGroup(
      groupSelected.id,
      {
        ...params
      },
      type
    );
  };
  React.useEffect(() => {
    // get list member for list avatar in profileHeader group
    if (groupSelected.id) {
      if (!adminsGroup.length) {
        funcLoad('admin');
      }
      if (!moderatorsGroup.length) {
        funcLoad('moderator');
      }
      if (!friendsGroup.length) {
        funcLoad('friend');
      }
      if (!membersGroup.length) {
        funcLoad('member');
      }
    }
    if (
      groupSelected.id &&
      (groupRelationShip.admin || groupRelationShip.moderator)
    )
      dispatch(getRemoveAccountReq(groupSelected.id));
  }, [JSON.stringify(groupSelected), match.params.groupId]);
  const listOptions = [
    [
      {
        id: 'outGroup',
        label: 'Rời khỏi nhóm',
        icon: 'fa-solid fa-arrow-right-from-bracket',
        styleIcon: iconStyle,
        action: () => {
          setOpenDialogOutGroup(true);
        }
      }
    ]
  ];
  const listButton: any = [
    {
      id: 'postInGroup',
      label: 'Bài viết trong nhóm',
      action: () => {
        setKeyTab({ id: 'postInGroup' });
        history.push(
          `/groups/${match.params.groupId}/user/${match.params.userId}`
        );
      },
      minWidthShowTab: useMatchMedia('(min-width:540px)', true)
    },
    {
      id: 'yourContribute',
      label: 'Đóng góp của bạn',
      action: () => {
        setKeyTab({ id: 'yourContribute' });
        history.push(
          `/groups/${match.params.groupId}/user/${match.params.userId}/yourContribute`
        );
      },
      minWidthShowTab: useMatchMedia('(min-width:640px)', true)
    }
  ];
  const renderTextConfirm = () => {
    if (
      groupSelected?.group_relationship?.admin &&
      adminsGroup.length < 2 &&
      !moderatorsGroup.length &&
      !membersGroup.length &&
      !friendsGroup.length
    ) {
      return 'Xóa nhóm bằng cách rời đi?';
    } else {
      return 'Rời khỏi nhóm ?';
    }
  };
  const renderSubText = () => {
    if (
      groupSelected?.group_relationship?.admin &&
      !membersGroup.length &&
      adminsGroup.length < 2 &&
      !moderatorsGroup.length &&
      !friendsGroup.length
    ) {
      return `Bạn có chắn chắn muốn rời khỏi nhóm ${groupSelected?.title} không?
       Vì bạn là thành viên cuối cùng nên nếu rời khỏi bây giờ thì nhóm cũng sẽ bị xóa.`;
    } else if (
      groupSelected?.group_relationship?.admin &&
      (membersGroup.length || moderatorsGroup.length) &&
      adminsGroup.length < 2
    ) {
      return 'Bạn là quản trị viên cuối cùng, hãy trao quyền quản trị viên cho thành viên khác trước khi rời nhóm.';
    } else {
      return `Bạn có muốn rời khỏi ${groupSelected?.title} không ?
      Bạn sẽ không thể quản lý hoặc tương tác với nhóm này nữa.`;
    }
  };
  const renderLabelButton = () => {
    if (
      groupSelected?.group_relationship?.admin &&
      adminsGroup.length < 2 &&
      !moderatorsGroup.length &&
      !membersGroup.length &&
      !friendsGroup.length
    ) {
      return 'Xóa nhóm ';
    } else {
      return 'Rời khỏi nhóm';
    }
  };
  const handleDeleteGroup = async () => {
    try {
      let response = await deleteGroupApi(groupSelected.id);
      if (response.status === 200) {
        setIsLoading(false);
        setOpenDialogOutGroup(false);
        history.push('/groups/timeline');
      }
    } catch (err: any) {
      setOpenSnackbar(true);
      setNoti({
        code: err.response.status,
        message: 'Có lỗi xảy ra trong quá trình gửi.Vui lòng thử lại sau!'
      });
    }
  };
  const handleOutGroup = async () => {
    try {
      let response = await deleteMemberGroupApi(groupSelected?.id);
      if (response.status === 200) {
        dispatch(groupRelationShipReq([response.data]));
        setIsLoading(false);
        setOpenDialogOutGroup(false);
      }
    } catch (err: any) {
      setOpenSnackbar(true);
      setNoti({
        code: err.response.status,
        message: 'Có lỗi xảy ra trong quá trình gửi.Vui lòng thử lại sau!'
      });
    }
  };
  return (
    <Box
      className={classes.root}
      sx={{ backgroundColor: 'background.primary' }}
    >
      <Box
        className={classes.headerProfile}
        style={{ justifyContent: isButtonResolution ? 'center' : 'start' }}
        id="scroll-top"
        sx={{ backgroundColor: 'background.primary' }}
      >
        <ProfileCardImage profile={meInfo} page={'groupActivitiesUser'} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: isButtonResolution ? 'center' : 'start'
          }}
        >
          <div
            className={classes.wrapAvatar}
            style={{
              flexDirection: isButtonResolution ? 'row' : 'column',
              justifyContent: isButtonResolution ? 'space-between' : 'start',
              alignItems: isButtonResolution ? 'end' : 'start',
              paddingLeft: isButtonResolution ? '0px' : '20px',
              paddingBottom: '30px',
              flexWrap: 'wrap'
            }}
          >
            <div
              className={classes.headerProfileDetail}
              style={{
                flexDirection: isMobile ? 'row' : 'column',
                width: isMobile ? undefined : '100%',
                alignItems: isMobile ? 'flex-end' : 'center'
              }}
            >
              <div
                className={classes.divAvatar}
                style={{
                  justifyContent: isMobile ? 'start' : 'center'
                }}
              >
                <AvatarSocial
                  type="avatar-user"
                  avatarObj={meInfo.avatar_media}
                  style={{
                    width: 168,
                    height: 168,
                    margin: '-35px 10px 0px 0px'
                  }}
                />
              </div>
              <div
                className={classes.cardContent}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: isMobile ? 'start' : 'center'
                }}
              >
                <Typography
                  className={classes.textName}
                  variant="h4"
                  style={{ textAlign: isMobile ? 'start' : 'center' }}
                >
                  {meInfo.display_name}
                </Typography>
                <Typography
                  className={classes.textFriend}
                  style={{ textAlign: isMobile ? 'start' : 'center' }}
                >
                  {meInfo.friends_count} người bạn
                </Typography>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              {meInfo?.id && (
                <div
                  style={{
                    display: 'flex',
                    margin: isButtonResolution ? 0 : '12px 20px 12px 0px',
                    flexDirection: noneMobile ? 'row' : 'column'
                  }}
                >
                  <ButtonInherit
                    label="Chỉnh sửa trang cá nhân"
                    icon={
                      <i className="fa fa-pen" style={{ fontSize: '16px' }}></i>
                    }
                    action={() => {
                      setOpenDialogEditProfile(true);
                    }}
                    style={{
                      marginRight: 0,
                      padding: '5px 10px'
                    }}
                  />
                  <ButtonInherit
                    label="Xem trang cá nhân"
                    icon={
                      <i
                        className="fa-solid fa-circle-user"
                        style={{ fontSize: '16px' }}
                      ></i>
                    }
                    action={() => {
                      history.push(`/user/${match.params.userId}`);
                    }}
                    style={{
                      marginRight: 0,
                      padding: '5px 10px',
                      marginLeft: '8px'
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Box>
      <div className={classes.profileMenu}>
        <SocialHeaderTab
          keyTab={keyTab}
          setKeyTab={setKeyTab}
          listButton={listButton}
        />

        <div>
          <IconButtonOptions
            icon={
              <i
                style={{ fontSize: '16px', fontWeight: '500' }}
                className="fa-solid fa-ellipsis"
              ></i>
            }
            openPopup={true}
            style={{ margin: '0px 0px 0px 7px' }}
            options={listOptions}
          />
        </div>
      </div>
      {openDialogOutGroup && (
        <DialogConfirmation
          title={renderTextConfirm()}
          text={renderSubText()}
          open={openDialogOutGroup}
          handleClose={() => {
            setOpenDialogOutGroup(false);
            setIsLoading(false);
          }}
          action={() => {
            if (
              groupSelected?.group_relationship?.admin &&
              !membersGroup.length &&
              adminsGroup.length < 2 &&
              !moderatorsGroup.length &&
              !friendsGroup.length
            ) {
              handleDeleteGroup();
            } else {
              handleOutGroup();
            }
            setIsLoading(true);
          }}
          alignText={'center'}
          labelButton={renderLabelButton()}
          loading={isLoading}
        />
      )}
      {openDialogEditProfile && (
        <DialogEditProfile
          open={openDialogEditProfile}
          setOpen={setOpenDialogEditProfile}
          aboutUser={aboutUser}
          setAboutUser={setAboutUser}
          lifeEvents={lifeEvents}
          buttonSocial={buttonSocial}
        />
      )}
      {openSnackbar && (
        <SnackbarNotification
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          code={noti?.code}
          message={noti?.message}
        />
      )}
    </Box>
  );
}
