import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';

import SocialFieldUpdate from 'src/components/SocialFieldUpdate/SocialFieldUpdate';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '5px 10px 20px 10px',
    borderRadius: 10,
    width: '100%'
  }
}));

interface DescriptionRecruit {
  infoUpdate?: any;
  formik?: any;
  recruitInfo?: any;
  setNotificationLeave?: React.Dispatch<React.SetStateAction<any>> | any;
}

const DescriptionRecruit: React.FC<DescriptionRecruit> = props => {
  const classes = useStyles();
  const { infoUpdate, formik, recruitInfo, setNotificationLeave } = props;
  const [loading, setLoading] = React.useState<any>('');
  const [verify, setVerify] = React.useState<any>(false);

  let fieldUpdate: any = [
    {
      field_type: 'textField',
      name: 'company_description',
      label: 'Mô tả doanh nghiệp',
      descriptionLabel: 'Bắt buộc*',
      placeholder: 'Thêm mô tả',
      rows: 4,
      descriptionTitle:
        'Cung cấp thêm thông tin về doanh nghiệp để người ứng tuyển biết nên mong đợi điều gì.',
      onChange: true,
      defaultValue: formik.values.company_description
    },
    {
      field_type: 'textField',
      name: 'job_description',
      label: 'Mô tả công việc',
      descriptionLabel: 'Bắt buộc*',
      placeholder: 'Thêm mô tả',
      rows: 4,
      descriptionTitle:
        'Cung cấp thêm thông tin về công việc tuyển dụng để người ứng tuyển biết nên mong đợi điều gì.',
      onChange: true,
      defaultValue: formik.values.job_description
    },
    {
      field_type: 'textField',
      name: 'requirement',
      label: 'Yêu cầu ứng viên',
      descriptionLabel: 'Bắt buộc*',
      placeholder: 'Thêm mô tả',
      rows: 4,
      descriptionTitle:
        'Cung cấp thêm thông tin về kỹ năng nghề nghiệp cần để các ứng viên tham khảo.',
      onChange: true,
      defaultValue: formik.values.requirement
    },
    {
      field_type: 'textField',
      name: 'benefits',
      label: 'Quyền lợi',
      descriptionLabel: 'Bắt buộc*',
      placeholder: 'Thêm mô tả',
      rows: 4,
      onChange: true,
      defaultValue: formik.values.benefits
    }
  ];

  return (
    <Box className={classes.root}>
      <SocialFieldUpdate
        setVerify={setVerify}
        verify={verify}
        setLoading={setLoading}
        formik={formik}
        fieldUpdate={fieldUpdate}
        infoUpdate={infoUpdate}
        spacing={0}
        setNotificationLeave={setNotificationLeave}
      />
    </Box>
  );
};

export default DescriptionRecruit;
