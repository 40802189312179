import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from 'src/store/action/socialGrowAction';
import * as types from 'src/constants/store/growSocial';
import { getDetailGrowApi } from 'src/apis/socialGrow.api';
const typesSetting: any = types;

export const getGrowDetailSagas = function* ({ payload }) {
  try {
    yield put(actions.loadingGrow(true));
    const { idGrow } = payload;
    let response = yield call(getDetailGrowApi, idGrow);
    if (response.status === 200) {
      yield put(actions.loadingGrow(false));
      yield put(actions.getGrowDetailSuccess(response.data));
      yield put(actions.CheckEmptyLayoutGrow(false));
    }
  } catch (error: any) {
    yield put(actions.loadingGrow(false));
    yield put(actions.getGrowDetailError(error.response));
    yield put(actions.CheckEmptyLayoutGrow(true));
  }
};

export function* watchGrowSocialAsync() {
  yield takeEvery(typesSetting.GET_GROW_DETAIL_REQUEST, getGrowDetailSagas);
}
