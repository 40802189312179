import * as types from 'src/constants/store/pageSocial';
const _ = require('lodash');

const initialState = {
  info: {},
  rolePreviewEdit: {
    role: undefined,
    idPage: null,
    avatar_media: null,
    title: null,
    emojiReactId: -1
  },
  hasMore: true,
  error: null,
  my_pages: [],
  loading: false,
  settingPage: null,
  no_myPages: false,
  totalCensorshipDiary: 0,
  pageNameNotiVerify: ''
};

export const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PAGE_PREVIEW_INFO: {
      let { data } = action.payload;
      let newState = _.cloneDeep(data);
      return { ...state, ...newState };
    }

    case types.ROLE_EDIT_PREVIEW_DETAIL_PAGE: {
      let { data } = action.payload;
      let newState = _.cloneDeep(data);
      return {
        ...state,
        rolePreviewEdit: { ...state.rolePreviewEdit, ...newState }
      };
    }

    case types.GET_LIST_MY_PAGE_SUCCESS: {
      let { data } = action.payload;
      let newState = _.cloneDeep(data);
      return {
        ...state,
        my_pages: _.uniqBy([...state.my_pages.concat(newState)], 'id'),
        no_myPages:
          [...state.my_pages.concat(newState)].length > 0 ? false : true
      };
    }

    case types.FILTER_LIST_MY_PAGE: {
      let { data } = action.payload;
      return {
        ...state,
        my_pages: data
      };
    }

    case types.GET_PAGE_DETAIL_SUCCESS: {
      let { data } = action.payload;
      return {
        ...state,
        info: { ...state.info, ...data },
        isLoading: false
      };
    }
    case types.GET_PAGE_DETAIL_ERROR: {
      return { ...state, error: true };
    }

    case types.GET_SETTING_PAGE_SUCCESS: {
      let { data } = action.payload;
      let newState = _.cloneDeep(data);
      return { ...state, settingPage: newState };
    }

    case types.RESET_PAGE: {
      return {
        ...state,
        info: {},
        rolePreviewEdit: { role: undefined },
        error: null
      };
    }

    case types.RESET_LIST_PAGE: {
      return {
        ...state,
        my_pages: [],
        no_myPages: false
      };
    }

    case types.UPDATE_LOCATION_PAGE: {
      let { data } = action.payload;
      return {
        ...state,
        info: { ...state.info, location: data.location, address: data.address }
      };
    }
    case types.UPDATE_HAS_MORE: {
      let { value } = action.payload;
      return { ...state, hasMore: value };
    }
    case types.LOADING_LIST_PAGE: {
      let { value } = action.payload;
      return { ...state, loading: value };
    }
    case types.GET_TOTAL_CENSORSHIP_DIARY: {
      return {
        ...state,
        totalCensorshipDiary: action.payload
      };
    }
    case types.UPDATE_TOTAL_CENSORSHIP_DIARY: {
      let numberUpdate = state.totalCensorshipDiary - action.payload;
      return {
        ...state,
        totalCensorshipDiary: numberUpdate
      };
    }
    case types.UPDATE_PAGE_NAME_NOTIFY_VERIFY: {
      let pageId = action.payload;
      return {
        ...state,
        pageNameNotiVerify: pageId
      };
    }
    default:
      return state;
  }
};
