export const RECRUIT_PREVIEW_INFO = 'RECRUIT_PREVIEW_INFO';
export const GET_RECRUIT_DETAIL_SUCCESS = 'GET_RECRUIT_DETAIL_SUCCESS';
export const GET_RECRUIT_DETAIL_ERROR = 'GET_RECRUIT_DETAIL_ERROR';
export const GET_RECRUIT_DETAIL_REQUEST = 'GET_RECRUIT_DETAIL_REQUEST';

export const UPDATE_PAGE_OWNER = 'UPDATE_PAGE_OWNER';
export const RESET_RECRUIT = 'RESET_RECRUIT';
export const SUBMIT_RECRUIT = 'SUBMIT_RECRUIT';
export const RESET_ERROR_RECRUIT = 'RESET_ERROR_RECRUIT';
export const LOADING_RECRUIT = 'LOADING_RECRUIT';
export const NOTIMOBILE = 'NOTIMOBILE';
export const LOADING_SEARCH_RECRUIT = 'LOADING_SEARCH_RECRUIT';
export const OPENCV = 'OPENCV';
export const UPDATE_FOLLOW_RECRUIT = 'UPDATE_FOLLOW_RECRUIT';

export const GET_RECRUIT_CATEGORIES_REQ = 'GET_RECRUIT_CATEGORIES_REQ';
export const GET_RECRUIT_CATEGORIES_SUCCESS = 'GET_RECRUIT_CATEGORIES_SUCCESS';
