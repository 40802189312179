import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { scrollStyle, modalBackdrop, buttonColor } from 'src/constants/styles';
import CloseIcon from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { getListProvincesApi } from 'src/apis/socialLocation';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Location from 'src/components/SocialFieldUpdate/location';
import _ from 'lodash';
import ButtonInherit from '../Button/ButtonInherit';

const useStyles = makeStyles((theme: any) => ({
  wrapNoMedia: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: ' center',
    flexDirection: 'column',
    width: '100%',
    margin: '20px 0px'
  },

  divWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 10,
    boxSizing: 'border-box',
    padding: 10,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.button.primary.hover
    }
  },
  divWrapSelected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 10,
    boxSizing: 'border-box',
    padding: 10,
    backgroundColor: theme.palette.background.hover,
    '& *': {
      color: `${buttonColor.backgroundColor} !important`
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  divWrapImage: {
    display: 'flex',
    alignItems: 'center'
  },
  iconLocation: {
    fontSize: '30px !important',
    backgroundColor: '#4D4D4D',
    color: ' #fff',
    borderRadius: '4px'
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    borderBottom: '0',
    ...scrollStyle,
    padding: '8px 16px',
    overflowX: 'hidden'
  },
  '& .MuiPaper-root': {
    width: 700,
    height: 700
  },
  '& .MuiDialogTitle-root': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  }
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs(props) {
  const {
    open,
    handleClose,
    handleAction,
    locationSelected,
    setLocationSelected,
    typePage
  } = props;
  const classes = useStyles();
  const [listLocation, setListLocation] = React.useState<any>([]);
  const [locationUserChoose, setLocationUserChoose] = React.useState<any>();
  const [loadingLocation, setLoadingLocation] = React.useState(false);
  const [address, setAddress] = React.useState<any>();

  const checkTypeLocation = () => {
    if (typePage === 'editProfile') {
      return { location: locationUserChoose, title: address };
    } else {
      if (locationUserChoose) {
        return { location: locationUserChoose, title: address };
      } else {
        return { ...locationSelected, title: address };
      }
    }
  };
  React.useEffect(() => {
    const createData = async () => {
      setLoadingLocation(true);
      let response = await getListProvincesApi();
      if (response.status === 200) {
        const formData = response.data.map(item => {
          return {
            title: item.province_name,
            location: item.center_point
          };
        });
        setListLocation(formData);
        setLoadingLocation(false);
      }
    };
    createData();
  }, []);
  React.useEffect(() => {
    setAddress(locationSelected?.title);
  }, [locationSelected?.title]);

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      BackdropProps={modalBackdrop}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography
          style={{ fontWeight: 'bold', fontSize: 20 }}
          textAlign={'center'}
        >
          Tìm kiếm vị trí
        </Typography>
      </BootstrapDialogTitle>

      <DialogContent>
        <Typography fontSize="13px" sx={{ margin: '10px 0px 5px 0px' }}>
          Tìm kiếm theo tỉnh/thành phố, khu vực hoặc tên địa điểm để di chuyển
          bản đồ.
        </Typography>
        <Autocomplete
          options={listLocation}
          onChange={(e, value) => {
            setLocationSelected(value);
          }}
          defaultValue={{
            title: locationSelected
              ? locationSelected.title || 'Chọn vị trí của tôi'
              : 'Chọn vị trí của tôi',
            address: ''
          }}
          noOptionsText={
            loadingLocation ? (
              <CircularProgress size={20} />
            ) : (
              'Không có dữ liệu...'
            )
          }
          autoHighlight
          getOptionLabel={(option: any) => option.title}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <div className={classes.divWrapImage}>
                <LocationOnIcon className={classes.iconLocation} />
                &nbsp;&nbsp;
                <div>
                  <Typography style={{ fontWeight: 500, whiteSpace: 'nowrap' }}>
                    {option?.title}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: '12px',
                      color: '666666',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {option?.address?.length >= 65
                      ? `${option.address.slice(0, 65)}...`
                      : option?.address}
                  </Typography>
                </div>
              </div>
            </Box>
          )}
          renderInput={params => (
            <TextField
              {...params}
              label="Chọn vị trí của bạn"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password' // disable autocomplete and autofill
              }}
            />
          )}
        />
        <Location
          isEdit={'yes'}
          setLocationUserChoose={
            typePage === 'editProfile' ? null : setLocationUserChoose
          }
          setLocationSelected={setLocationSelected}
          locationSelected={locationSelected?.location}
          styleMap={{ height: '350px', marginTop: '10px' }}
        />
        <Box sx={{ display: 'flex', marginTop: '10px' }}>
          <LocationOnIcon />
          <Typography>
            {locationUserChoose
              ? `Vị trí: ${locationUserChoose?.lat}, ${locationUserChoose?.lng}`
              : locationSelected?.location?.lat
              ? `Vị trí: ${locationSelected?.location?.lat}, ${locationSelected?.location?.lng}`
              : 'Nhấp vào bản đồ để chọn vị trí cụ thể.'}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'end',
            marginTop: '80px'
          }}
        >
          <ButtonInherit
            label={'Hủy'}
            style={{ backgroundColor: 'silver', color: '#fff' }}
            action={() => {
              handleClose();
              setLocationSelected(null);
            }}
          />
          <ButtonInherit
            label={'Lưu'}
            disabled={!address}
            style={{
              marginRight: '0px',
              backgroundColor: buttonColor.backgroundColor,
              color: '#fff'
            }}
            action={() => {
              handleClose();
              setLocationSelected(checkTypeLocation());
              handleAction && handleAction(checkTypeLocation());
            }}
          />
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
}
