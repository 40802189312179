import React from 'react';
import Media from './Media';
import SocialPostInformation from 'src/components/SocialPost/SocialPostInformartion';
import SocialContent from 'src/components/SocialPost/SocialContent';
import FeedMoment from 'src/pages/Moment/FeedMoment';
const Share = props => {
  const { post, type } = props;
  let postReblog = type ? post : post.reblog;

  const renderMedia = () => {
    if (
      postReblog.media_attachments.length &&
      postReblog?.post_type !== 'moment'
    ) {
      return (
        <Media
          key={`media-reblog-${post.id}`}
          post={postReblog}
          postShare={post?.reblog}
          type="share_post"
          styleBox={{
            border: '1px solid #ccc',
            borderTopRightRadius: '3px',
            borderTopLeftRadius: '3px'
          }}
          styleImage={{
            borderTop: 'unset !important',
            borderBottom: 'unset !important'
          }}
        />
      );
    } else if (postReblog?.post_type === 'moment') {
      // ui share moment
      return <FeedMoment moment={postReblog} typeShare={'postShareMoment'} />;
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: type && type === 'share' ? '470px' : '100% !important'
      }}
    >
      {renderMedia()}
      <div
        style={{
          width: type ? '100%' : '95%',
          height: 'auto',
          borderRadius: !postReblog.media_attachments.length
            ? '10px'
            : '0px 0 10px 10px',
          border: '1px solid rgba(0,0,0,0.2)',
          borderTop: postReblog.media_attachments.length
            ? 0
            : '1px solid rgba(0,0,0,0.2)',
          marginTop: '0px'
        }}
      >
        <SocialPostInformation
          key={`${postReblog.id}-postInformation`}
          post={postReblog}
          type="sharePost"
        />
        <SocialContent
          key={`${postReblog.id}-socialContent`}
          post={postReblog}
          type="sharePost"
        />
      </div>
    </div>
  );
};

export default Share;
