export const GET_SETTING_REQ = 'GET_SETTING_REQ';export const GET_SETTING_SUCCESS = 'GET_SETTING_SUCCESS';

export const GET_COLOR_IMAGE_REQ = 'GET_COLOR_IMAGE_REQ';
export const GET_COLOR_IMAGE_SUCCESS = 'GET_COLOR_IMAGE_SUCCESS';

export const RESET_REQ = 'RESET_REQ';
export const SAVE_LINK_BANNER = 'SAVE_LINK_BANNER';
export const SAVE_FILE_BANNER = 'SAVE_FILE_BANNER';

export const UPLOAD_FILE_BANNER = 'UPLOAD_FILE_BANNER';
export const CHECK_HAS_SCROLL = 'CHECK_HAS_SCROLL';

export const SET_LOADING_REQ = 'SET_LOADING_REQ';
