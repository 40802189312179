import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as React from 'react';
import { updateGroupApi } from 'src/apis/socialGroup';
import { updatePageApi } from 'src/apis/socialPages.api';
import {
  getInforUser,
  postUpdateInfoUser,
  postUpdateRelationshipUser,
  updateCredentialsUser
} from 'src/apis/socialUser';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import {
  buttonColor,
  buttonStyle,
  modalBackdrop,
  scrollStyle
} from 'src/constants/styles';
import { getGroupDetailReq } from 'src/store/action/socialGroupAction';
import {
  getListMyPageReq,
  getPageDetailReq
} from 'src/store/action/socialPageAction';
import { getInfoMeReq } from 'src/store/action/userAction';
import Introduce from './Introduce';

import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  getInforlUserReq,
  getListLifeEventsRes
} from 'src/store/action/socialUserAction';
import { getProductOwner } from 'src/store/action/socialProductAction';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    borderBottom: '0',
    ...scrollStyle,
    overflow: 'hidden',
    overflowY: 'auto',
    padding: '0px !important'
  },
  '& .MuiPaper-root': {
    overflow: 'hidden',
    maxWidth: '850px',
    borderRadius: '10px'
  },
  '& .MuiDialogTitle-root': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  }
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs(props) {
  const {
    open,
    setOpen,
    keyTab,
    handleClose,
    title,
    children,
    labelButton,
    action,
    type,
    hasAction,
    files,
    dataUpdate,
    visibility,
    aboutUser,
    setAboutUser,
    setDataUpdate,
    setVisibility,
    editType,
    isError,
    setIsError,
    buttonSocial,
    lifeEvents,
    userInfo
  } = props;

  const dispatch = useDispatch();
  const match = useRouteMatch();
  const groupSelected = useSelector(
    (state: any) => state.groupReducer.groupSelected
  );
  const pagesOwner = useSelector((state: any) => state.pageReducer.my_pages);
  const pageId = useSelector((state: any) => state.pageReducer.info.id);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingIntro, setIsLoadingIntro] = React.useState(false);
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const history = useHistory();
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  const handleUpdateUserCredential = async data => {
    try {
      let response;
      if (match.path.includes('/group/')) {
        response = await updateGroupApi(groupSelected?.id, {
          cover_image: data.header
        });
      } else if (pageId) {
        response = await updatePageApi(
          pageId,
          type === 'avatar'
            ? {
                avatar: data.avatar
              }
            : {
                banner: data.header
              }
        );
      } else {
        response = await updateCredentialsUser(data);
      }
      if (response.status === 200) {
        if (match.path.includes('/group/')) {
          dispatch(getGroupDetailReq(groupSelected.id, ''));
        } else if (pageId) {
          dispatch(getPageDetailReq(pageId));
          dispatch(
            getProductOwner({
              ...pagesOwner[0],
              page_owner_id: pagesOwner[0]?.id,
              title: pagesOwner[0]?.title,
              avatar_icon: response.data.avatar_media.show_url,
              description: 'Shop - Trang của bạn',
              ward: pagesOwner[0]?.ward,
              username: pagesOwner[0]?.username
            })
          );
          dispatch(getListMyPageReq({ maxId: null, limit: 10 }));
        } else {
          dispatch(getInfoMeReq());
          const userId = userInfo?.id ?? userInfo?.infor?.id;
          if (userId) {
            let res = await getInforUser(userId, '');
            if (res.status === 200) {
              dispatch(getInforlUserReq(res.data));
            }
          }

          let dataStorage: any = localStorage.getItem('dataUser');
          let dataUser: any = JSON.parse(dataStorage) ?? [];
          let index = dataUser?.findIndex((el: any) => el.id === meInfo?.id);

          let token: any = localStorage.getItem('token');

          if (index >= 0) {
            dataUser = [
              ...dataUser.slice(0, index),
              {
                id: meInfo?.id,
                name: meInfo?.display_name,
                show_url: data?.avatar?.show_url,
                token: token,
                username: meInfo?.username,
                darkThemeUser: meInfo?.theme
              },
              ...dataUser.slice(index + 1)
            ];
            localStorage.setItem('darkThemeUser', meInfo?.theme);
            localStorage.setItem(
              'dataUser',
              JSON.stringify(
                _.uniqBy(
                  dataUser?.filter((el: any) => el.id),
                  'id'
                )
              )
            );
          }
        }
        setIsLoading(false);
        handleClose();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateUser = async () => {
    let response;
    let res;
    try {
      let formDataUpdate = {
        hometown_id: dataUpdate?.hometown?.id,
        place_live_id: dataUpdate?.place_live?.id,
        address: dataUpdate?.address,
        gender: dataUpdate?.gender,
        other_name: dataUpdate?.other_name,
        phone_number: dataUpdate?.phone_number,
        display_name: children?.props?.aboutUser?.display_name,
        account_web_link: dataUpdate?.account_web_link?.filter(iw => iw?.url),
        account_social: dataUpdate?.account_social?.filter(is => is?.text),
        username: children?.props?.aboutUser?.username,
        account_display_fields: [
          {
            field: 'birth_year',
            visibility: visibility?.length
              ? visibility?.find(item => item.field === 'birth_year')
                  ?.visibility
              : 'public'
          },
          {
            field: 'birth_date',
            visibility: visibility?.length
              ? visibility?.find(item => item.field === 'birth_date')
                  ?.visibility
              : 'public'
          },
          {
            field: 'birth_month',
            visibility: visibility?.length
              ? visibility?.find(item => item.field === 'birth_month')
                  ?.visibility
              : 'public'
          },
          {
            field: 'other_name',
            visibility: visibility?.length
              ? visibility?.find(item => item.field === 'other_name')
                  ?.visibility
              : 'public'
          },
          {
            field: 'place_live_id',
            visibility: visibility?.length
              ? visibility?.find(item => item.field === 'place_live_id')
                  ?.visibility
              : 'public'
          },
          {
            field: 'gender',
            visibility: visibility?.length
              ? visibility?.find(item => item.field === 'gender')?.visibility
              : 'public'
          },
          {
            field: 'hometown_id',
            visibility: visibility?.length
              ? visibility?.find(item => item.field === 'hometown_id')
                  ?.visibility
              : 'public'
          },
          {
            field: 'phone_number',
            visibility: visibility?.length
              ? visibility?.find(item => item.field === 'phone_number')
                  ?.visibility
              : 'public'
          },
          {
            field: 'account_web_link',
            visibility: visibility?.length
              ? visibility?.find(item => item.field === 'account_web_link')
                  ?.visibility ?? 'public'
              : 'public'
          },
          {
            field: 'account_social',
            visibility: visibility?.length
              ? visibility?.find(item => item.field === 'account_social')
                  ?.visibility ?? 'public'
              : 'public'
          },
          {
            field: 'address',
            visibility: visibility?.length
              ? visibility?.find(item => item.field === 'address')
                  ?.visibility ?? 'public'
              : 'public'
          }
        ]
      };
      response = await postUpdateInfoUser(
        !dataUpdate?.birth_date ||
          !dataUpdate?.birth_month ||
          !dataUpdate?.birth_year
          ? formDataUpdate
          : {
              ...formDataUpdate,
              birth_date: dataUpdate?.birth_date,
              birth_month: dataUpdate?.birth_month,
              birth_year: dataUpdate?.birth_year
            }
      );
      if (aboutUser?.account_relationship) {
        let params = {
          relationship_category_id:
            aboutUser?.account_relationship?.relationship_category?.id,
          visibility: visibility?.length
            ? visibility?.find(item => item.field === 'relationship').visibility
            : 'public',
          partner_id: aboutUser?.account_relationship?.partner?.id,
          start_date: new Date(
            new Date(aboutUser?.account_relationship?.start_date).getDate(),
            new Date(aboutUser?.account_relationship?.start_date).getMonth() +
              1,
            new Date(aboutUser?.account_relationship?.start_date).getDate()
          )
        };
        res = await postUpdateRelationshipUser(
          params,
          '',
          aboutUser?.account_relationship
        );
        if (res.status === 200) {
          setAboutUser(prev => ({
            ...prev,
            account_relationship: res.data
          }));
        }
      }

      if (response.status === 200) {
        handleClose();
        dispatch(getListLifeEventsRes(userInfo));
        setAboutUser &&
          setAboutUser(prev => ({
            ...prev,
            general_information: response.data
          }));
        setIsLoadingIntro(false);
      }

      setIsError && setIsError(false);
    } catch (error: any) {
      setOpenSnackbar(true);
      setNoti({
        code: 400,
        message: 'Cập nhật thông tin thất bại.Vui lòng thử lại sau!'
      });
      setIsError && setIsError(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      description: ''
    },
    onSubmit: values => {
      if (files.length) {
        let newFile = files[0];
        Object.defineProperty(newFile, 'description', {
          value: values.description,
          writable: true
        });

        let dataUpdate = {
          [type === 'avatar' ? 'avatar' : 'header']: {
            file: newFile.id ? null : newFile,
            status: newFile.description,
            show_url: newFile.avatar_show_url,
            id: newFile.id ? newFile.id : null,
            frame_id: newFile.frame_id
          }
        };
        handleUpdateUserCredential(dataUpdate);
        setIsLoading(true);
      }
    }
  });

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      scroll="paper"
      BackdropProps={modalBackdrop}
      maxWidth={keyTab === 'frames' ? 'md' : 'sm'}
    >
      {isLoading ? (
        <Box
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            zIndex: 2000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
          sx={{ backgroundColor: 'background.primary' }}
        >
          <CircularProgress color="inherit" />
          <Typography style={{ fontSize: 20 }}>Đang đăng</Typography>
        </Box>
      ) : null}

      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography
          style={{ fontWeight: 'bold', fontSize: 20 }}
          textAlign={'center'}
        >
          {title}
        </Typography>
      </BootstrapDialogTitle>

      <DialogContent id="scrollDialogTarget">
        {editType === 'introduce' ? (
          <Introduce
            aboutUser={aboutUser}
            dataUpdate={dataUpdate}
            setDataUpdate={setDataUpdate}
            visibility={visibility}
            setVisibility={setVisibility}
            isError={isError}
            setIsError={setIsError}
            buttonSocial={buttonSocial}
            lifeEvents={lifeEvents}
            userInfo={userInfo}
            setOpenDialog={setOpen}
          />
        ) : (
          React.cloneElement(children, { formik: formik })
        )}
      </DialogContent>

      {keyTab === 'frames' || hasAction || files?.length ? (
        <>
          <Divider />
          <DialogActions
            sx={{
              justifyContent:
                keyTab === 'frames' || type !== 'introduce'
                  ? 'end'
                  : 'space-between',
              padding: '8px 15px'
            }}
          >
            {!keyTab && type === 'introduce' ? (
              <Box>
                <Button
                  onClick={() => {
                    history.push(
                      `/user/${
                        userInfo?.username ? userInfo.username : userInfo.id
                      }/intro/about_overview`
                    );
                  }}
                  style={{
                    ...buttonStyle,
                    textTransform: 'none',
                    color: buttonColor.backgroundColor
                  }}
                >
                  Cập nhật thông tin
                </Button>
              </Box>
            ) : null}
            <Box>
              {keyTab !== 'frames' ? (
                <Button
                  onClick={handleClose}
                  style={{ ...buttonStyle, textTransform: 'none' }}
                >
                  Hủy
                </Button>
              ) : null}
              <ButtonInherit
                solid={true}
                action={() => {
                  if (files?.length) {
                    formik.submitForm();
                  }
                  if (type === 'introduce' || type === 'relationship') {
                    setIsLoadingIntro(true);
                    handleUpdateUser();
                  }
                }}
                color={buttonColor.backgroundColor}
                label={labelButton ? labelButton : 'Lưu '}
                loading={isLoadingIntro}
              />
            </Box>
          </DialogActions>
        </>
      ) : null}
      {openSnackbar && (
        <SnackbarNotification
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          code={noti?.code}
          message={noti?.message}
        />
      )}
    </BootstrapDialog>
  );
}
