import * as types from 'src/constants/store/socialSaveUser';export const saveUserInfo = (userInfo: any) => ({
  type: types.SAVE_CACHE_USER_INFO,
  payload: userInfo
});
export const userBlock = (block: any) => ({
  type: types.SAVE_BLOCK_USER,
  payload: block
});
export const saveMeInfor = (meInfor: any) => ({
  type: types.SAVE_CACHE_ME_INFO,
  payload: meInfor
});
export const saveAboutUser = (aboutUser: any) => ({
  type: types.SAVE_ABOUT_USER,
  payload: aboutUser
});
export const saveLifeEventsUser = (lifeEventsUser: any) => ({
  type: types.SAVE_LIFE_EVENTS_USER,
  payload: lifeEventsUser
});
export const saveListFriend = (listFriend: any) => ({
  type: types.SAVE_LIST_FRIEND,
  payload: listFriend
});
export const saveStatusFriend = (statusFriend: any) => ({
  type: types.SAVE_STATUS_FRIEND,
  payload: statusFriend
});
export const saveSocialCategory = (socialCategory: any) => ({
  type: types.SAVE_SOCIAL_CATEGORY,
  payload: socialCategory
});
export const saveNotiListCollection = (listCollection: any) => ({
  type: types.SAVE_LIST_LISTNOTION_COLLECTION,
  payload: listCollection
});
export const saveMediaUser = (mediaUser: any) => ({
  type: types.SAVE_MEDIA_USER,
  payload: mediaUser
});
export const savePinUser = (pinUser: any) => ({
  type: types.SAVE_PIN_USER,
  payload: pinUser
});
export const saveFriendSuggestUser = (friendSuggestUser: any) => ({
  type: types.SAVE_FRIEND_SUGGEST_USER,
  payload: friendSuggestUser
});
