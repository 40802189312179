import _ from 'lodash';import * as types from 'src/constants/store/socialSaveUser';
const initialState = {
  userInfo: [],
  meInfo: null,
  aboutUser: [],
  lifeEvents: [],
  listFriends: [],
  statusFriends: [],
  socialCategory: null,
  listCollection: [],
  mediaUser: [],
  pinUser: [],
  friendSuggestUser: []
};
export const socialSaveUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SAVE_CACHE_USER_INFO: {
      const dataUsers = action.payload;
      let newState = _.cloneDeep(state);
      return {
        ...newState,
        userInfo: [dataUsers, ...newState.userInfo].filter(
          (item, index, self) =>
            self.findIndex(value => value.id === item.id) === index
        )
      };
    }
    case types.SAVE_CACHE_ME_INFO: {
      return { ...state, meInfo: action.payload };
    }
    case types.SAVE_ABOUT_USER: {
      const aboutUsers = action.payload;
      let newState = _.cloneDeep(state);
      return {
        ...newState,
        aboutUser: [aboutUsers, ...newState.aboutUser].filter(
          (item, index, self) =>
            self.findIndex(value => value.id === item.id) === index
        )
      };
    }
    case types.SAVE_BLOCK_USER: {
      return {
        ...state,
        blockUser: action.payload
      };
    }
    case types.SAVE_LIFE_EVENTS_USER: {
      const lifeEventUsers = action.payload;
      let newState = _.cloneDeep(state);
      return {
        ...newState,
        lifeEvents: [lifeEventUsers, ...newState.lifeEvents]
      };
    }
    case types.SAVE_LIST_FRIEND: {
      const listFriendUsers = action.payload;
      let newState = _.cloneDeep(state);
      return {
        ...newState,
        listFriends: [listFriendUsers, ...newState.listFriends]
      };
    }
    case types.SAVE_STATUS_FRIEND: {
      const statusFriendUsers = action.payload;
      let newState = _.cloneDeep(state);
      return {
        ...newState,
        statusFriends: [statusFriendUsers, ...newState.statusFriends]
      };
    }
    case types.SAVE_SOCIAL_CATEGORY: {
      return { ...state, socialCategory: action.payload };
    }
    case types.SAVE_LIST_LISTNOTION_COLLECTION: {
      const listCollectionUser = action.payload;
      let newState = _.cloneDeep(state);
      return {
        ...newState,
        listCollection: [listCollectionUser, ...newState.listCollection]
      };
    }
    case types.SAVE_MEDIA_USER: {
      const mediaUsers = action.payload;
      let newState = _.cloneDeep(state);
      return { ...newState, mediaUser: [mediaUsers, ...newState.mediaUser] };
    }
    case types.SAVE_PIN_USER: {
      const pinUsers = action.payload;
      let newState = _.cloneDeep(state);
      return { ...newState, pinUser: [pinUsers, ...newState.pinUser] };
    }
    case types.SAVE_FRIEND_SUGGEST_USER: {
      const friendSuggestUsers = action.payload;
      let newState = _.cloneDeep(state);
      return {
        ...newState,
        friendSuggestUser: [friendSuggestUsers, ...newState.friendSuggestUser]
      };
    }
    default: {
      return state;
    }
  }
};
