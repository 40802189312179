/* eslint-disable react-hooks/exhaustive-deps */ import {
  List,
  Typography,
  useTheme
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useRef } from 'react';
import Comment from './Comment';
import CommentChild from './CommentChild';

const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      root: {},
      loadMore: {
        '&:hover': {
          textDecoration: 'underline'
        },
        cursor: 'pointer',
        marginLeft: 10,
        fontWeight: '500 !important',
        color: theme.palette.text.customColor
      },
      itemFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      },
      buttonCollapseItems: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }
    }),
  { index: 1 }
);

interface Props {
  listComment?: any;
  info?: any;
  type?: String;
  createComment?: any;
  handleGetComment?: any;
  visibility?: any;
  commentTotal?: any;
  setListComment?: React.Dispatch<React.SetStateAction<any>> | any;
  handleBackClick?: any;
  setGifSelected?: React.Dispatch<React.SetStateAction<any>>;
  gifSelected?: any;
  stickySelected?: any;
  setStickySelected?: React.Dispatch<React.SetStateAction<any>>;
  styleWatch?: React.CSSProperties | any;
  setCommentInfo?: React.Dispatch<React.SetStateAction<any>>;
  hasMore?: boolean | any;
  setHasMore?: React.Dispatch<React.SetStateAction<boolean>> | any;
}

const PostListComment = React.memo((props: Props) => {
  const classes = useStyles();
  const theme: any = useTheme();
  const {
    listComment,
    info,
    type,
    createComment,
    handleGetComment,
    visibility,
    commentTotal,
    setListComment,
    handleBackClick,
    setGifSelected,
    gifSelected,
    stickySelected,
    setStickySelected,
    styleWatch,
    setCommentInfo,
    hasMore,
    setHasMore
  } = props;

  const [displayComments, setDisplayComment] = React.useState({
    count: 0,
    afterCount: 0,
    lastTimeLoad: ''
  });
  // user, who be responsed in comment
  const [respondent, setRespondent] = React.useState({});
  const getComments = () => {
    let maxId;
    let params;
    if (info?.replies?.length > 0 && listComment?.length === 1) {
      params = {
        sort_by: visibility
      };
    } else {
      maxId = [...listComment]?.pop()?.id;
      params = {
        sort_by: visibility,
        max_id: maxId
      };
    }

    if (info?.id) {
      handleGetComment(info.id, params, setListComment, setHasMore);
    }
  };

  React.useEffect(() => {
    if (info) {
      let commentCount = info?.replies_count ?? 0;
      let after_count = commentCount - 3;

      setDisplayComment({
        count: commentCount,
        afterCount: after_count,
        lastTimeLoad: ''
      });
      if (commentCount < 10 && info?.replies?.length < 0) setHasMore(false);
      else if (info?.replies?.length > 0 && commentCount > 1) setHasMore(true);
    }
  }, [info?.id]);
  let commentItems = listComment.map((item: any, index) => {
    return item ? (
      <Comment
        post={info}
        commentElement={info}
        key={item.uuid}
        keyId={item.id}
        info={item}
        type={type}
        createComment={createComment}
        setListComment={setListComment}
        gifSelected={gifSelected}
        setGifSelected={setGifSelected}
        stickySelected={stickySelected}
        setStickySelected={setStickySelected}
        setCommentInfo={setCommentInfo}
        setRespondent={setRespondent}
        children={
          <CommentChild
            post={info}
            commentElement={info}
            type={type}
            info={item}
            updateComment={createComment}
            handleGetComment={handleGetComment}
            gifSelected={gifSelected}
            setGifSelected={setGifSelected}
            stickySelected={stickySelected}
            setStickySelected={setStickySelected}
            setCommentInfo={setCommentInfo}
            setRespondent={setRespondent}
            respondent={respondent}
          />
        }
      />
    ) : null;
  });

  React.useEffect(() => {
    if (info?.replies?.length) {
      setListComment(info.replies);
    } else if (
      type === 'previewMedia' ||
      type === 'watch-selected' ||
      type === 'post-detail'
    ) {
      getComments();
    }
  }, [type]);

  const totalCommentRender = (listComment: any) => {
    const total = listComment?.reduce((accumulator: number, comment: any) => {
      return accumulator + (comment?.replies_count + 1);
    }, 0);
    return total;
  };

  const renderComment = () => {
    if (styleWatch?.styleComment) {
      return (
        <div
          style={{
            width: '100%',
            ...styleWatch.styleComment,
            overflow: 'hidden auto'
          }}
        >
          {commentItems}
        </div>
      );
    } else {
      return <List>{commentItems}</List>;
    }
  };
  return (
    <>
      {listComment.length ? renderComment() : null}

      <div
        className={classes.itemFlex}
        style={{ alignItems: listComment.length > 10 ? 'baseline' : 'center' }}
      >
        {hasMore ? (
          <div className={classes.itemFlex} style={{ width: '100%' }}>
            <Typography
              className={classes.loadMore}
              onClick={() => getComments()}
            >
              Xem thêm bình luận
            </Typography>
            {listComment.length === 10 ? (
              <Typography
                sx={{ marginRight: '20px' }}
                className={classes.loadMore}
              >
                {totalCommentRender(listComment)}/{commentTotal}
              </Typography>
            ) : null}
          </div>
        ) : null}
        {listComment.length > 10 ? (
          <div
            className={classes.buttonCollapseItems}
            style={{
              flexDirection: type === 'watch-selected' ? 'row' : 'column'
            }}
          >
            <div
              onClick={() => {
                handleGetComment(
                  info.id,
                  {
                    sort_by: visibility
                  },
                  setListComment,
                  null
                );
                setHasMore(true);
              }}
              style={{ width: '90px', cursor: 'pointer' }}
            >
              <Typography
                sx={{
                  fontWeight: '500',
                  color: theme.palette.text.customColor
                }}
              >
                Thu gọn
              </Typography>
            </div>

            {hasMore && (
              <Typography
                sx={{ marginRight: '20px' }}
                className={classes.loadMore}
              >
                {totalCommentRender(listComment)}/{commentTotal}
              </Typography>
            )}
          </div>
        ) : null}
      </div>

      {listComment.length > 3 ? (
        <Typography onClick={handleBackClick} className={classes.loadMore}>
          Viết bình luận...
        </Typography>
      ) : null}
    </>
  );
});

export default PostListComment;
