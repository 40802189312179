import axios, { AxiosRequestConfig } from 'axios';
import { USER_TOKEN } from 'src/util/config';
import { BASE_ROOT } from 'src/util/config';

const fetchApi = async (endPoint, method, params, formdata) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: formdata
  };

  let response = await axios(configs);
  return response;
};

export const createInviteGroup = async (idGroup: any, data: any) => {
  return fetchApi(`groups/${idGroup}/invitations`, 'POST', null, data);
};

export const getListGroupApi = async (params: Object) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const getWaitingPostApi = async (id, params) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/pending_statuses`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const updateWaitingPostApi = async (id, params, data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/pending_statuses`,
    method: 'patch',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data
  };

  let response = await axios(configs);
  return response;
};

export const updateWaitingMemberApi = async (id, data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/join_requests/respond`,
    method: 'post',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    data
  };

  let response = await axios(configs);
  return response;
};

export const getPostAllGroupApi = async (params: Object) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/timelines/group_collection`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const deleteGroupApi = async id => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}`,
    method: 'delete',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const getGroupAccountApi = async (id: any, params: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/accounts`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const searchGroupAccountApi = async (id, params) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/member_search`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const searchGroupByCategoryApi = async (id, params) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/_/category/${id}`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const addMemberGroupApi = async (id, data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/accounts`,
    method: 'post',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    data
  };

  let response = await axios(configs);
  return response;
};

export const getListGroupResponseApi = async (params: Object) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/group_invitations`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const getListGroupRequestApi = async (params: Object) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/group_join_requests`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const deleteMemberGroupApi = async id => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/leave`,
    method: 'delete',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const updatePermissionAccountGroupApi = async (id, params, data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/accounts`,
    method: 'patch',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data
  };

  let response = await axios(configs);
  return response;
};

export const deleteAccountGroupApi = async (id, params) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/removed_accounts`,
    method: 'delete',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const CUDRuleGroupApi = async (type, id, data, params) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/rules`,
    method:
      type === 'create'
        ? 'post'
        : type === 'update'
        ? 'patch'
        : type === 'get'
        ? 'get'
        : 'delete',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    data,
    params
  };

  let response = await axios(configs);
  return response;
};

export const requestListAccountGroupApi = async (id, params) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/join_requests`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const responseRequestListAccountGroupApi = async (id, data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/invitations/respond`,
    method: 'post',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    data
  };

  let response = await axios(configs);
  return response;
};

export const getListAccountRemoveGroupApi = async id => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/removed_accounts`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const removeAccountGroupApi = async (id, params, type = 'post') => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/removed_accounts`,
    method: type as any,
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const groupRelationshipApi = async (data: Object) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/group_relationships`,
    method: 'post',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    data
  };

  let response = await axios(configs);
  return response;
};

export const groupCategoryApi = async (params = null) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/categories`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const groupTimelineApi = async (id, params) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/timelines/group/${id}`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const searchFilterPostInGroup = async (id, params) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/timelines/group/${id}/search`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};
export const deleteStatusGroupApi = async id => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/1/statuses/${id}`,
    method: 'delete',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const getGroupDetailApi = async id => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const updateSettingGroupApi = async (id, data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/settings`,
    method: 'patch',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    data
  };

  let response = await axios(configs);
  return response;
};

export const getReportPostGroupApi = async (id, params) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/reported_statuses`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const reportPostGroupApi = async (id, data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/reported_statuses`,
    method: 'post',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    data
  };

  let response = await axios(configs);
  return response;
};

export const deleteReportPostGroupApi = async (id, params) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/reported_statuses`,
    method: 'delete',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const deleteReportAndPostGroupApi = async (id, statusId) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/reported_statuses/${statusId}/approve`,
    method: 'post',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const deleteSchedulePostGroupApi = async (id, params) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/scheduled_statuses`,
    method: 'delete',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const CRUDMemberQuestionGroupApi = async (type, id, params, data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/member_questions`,
    method: type,
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data
  };

  let response = await axios(configs);
  return response;
};

export const publishSchedulePostGroupApi = async (id, statusId) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/scheduled_statuses/${statusId}/public`,
    method: 'post',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const updateSchedulePostGroupApi = async (id, params, data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/scheduled_statuses`,
    method: 'patch',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data
  };

  let response = await axios(configs);
  return response;
};

export const getSchedulePostGroupApi = async id => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/scheduled_statuses`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const getMediaGroupApi = async (id, params) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/media_attachments`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const CRUDActivityLogApi = async (type, id, params, data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/activity_logs`,
    method: type,
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data
  };

  let response = await axios(configs);
  return response;
};

export const CRUDReportCensorshipGroupApi = async (
  type,
  id,
  params,
  data,
  link
) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/status_alerts${
      link ? '/' + link : ''
    }`,
    method: type,
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data
  };

  let response = await axios(configs);
  return response;
};

export const createGroupApi = async (data: any) => {
  var formdata = new FormData();
  if (data.cover_image) {
    if (data.cover_image.file) {
      formdata.append('cover_image[file]', data.cover_image.file);
    }
    if (data.cover_image.id) {
      formdata.append('cover_image[id]', data.cover_image.id);
    }
    formdata.append('cover_image[show_url]', data.cover_image.show_url);
  }
  formdata.append('title', data.title);
  if (data.description) {
    formdata.append('description', data.description);
  }
  if (data.group_category_id) {
    formdata.append('category_id', data.group_category_id);
  } else formdata.append('category_id', '');
  if (data.is_private) {
    formdata.append('is_private', data.is_private);
  }
  formdata.append('is_visible', data.is_visible);
  if (data.tags) {
    formdata.append('tags', data.tags);
  }
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups`,
    method: 'post',
    headers: {
      // 'content-type': 'multipart/form-data',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    data: formdata
  };

  let response = await axios(configs);
  return response;
};

export const updateGroupApi = async (id: any, data: any) => {
  var formdata = new FormData();
  if (data.cover_image) {
    if (data.cover_image.file) {
      formdata.append('cover_image[file]', data.cover_image.file);
    }
    if (data.cover_image.id) {
      formdata.append('cover_image[id]', data.cover_image.id);
    }
    formdata.append('cover_image[show_url]', data.cover_image.show_url);
    formdata.append('cover_image[status]', data.cover_image.status);
  }
  if (data.title) {
    formdata.append('title', data.title);
  }
  formdata.append('description', data.description);
  if (data.group_category_id) {
    formdata.append('category_id', data.group_category_id);
  }
  if (data.tags) {
    formdata.append('tags', data.tags);
  }
  if (data.monitored_keywords) {
    data.monitored_keywords.forEach((el: any) => {
      formdata.append('monitored_keywords[]', el);
    });
  }
  if (data.inactivate_reason) {
    formdata.append(
      'inactivate_reason',
      data.inactivate_reason === 'active' ? '' : data.inactivate_reason
    );
  }
  if (data.username) {
    formdata.append('username', data.username);
  }
  if (data.is_private !== undefined && data.is_private !== null) {
    formdata.append('is_private', data.is_private);
  }
  if (data.is_visible !== undefined && data.is_visible !== null) {
    formdata.append('is_visible', data.is_visible);
  }
  if (data.post_approval_setting) {
    formdata.append('post_approval_setting', data.post_approval_setting);
  }
  if (data.post_permission_setting) {
    formdata.append('post_permission_setting', data.post_permission_setting);
  }

  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}`,
    method: 'patch',
    headers: {
      // 'content-type': 'multipart/form-data',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    data: formdata
  };

  let response = await axios(configs);
  return response;
};

export const getListInvitationGroupApi = async (id, params) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/invitations`,
    method: 'get',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };

  let response = await axios(configs);
  return response;
};

export const deleteInvitationGroupApi = async (id, invitationId) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/groups/${id}/invitations/${invitationId}`,
    method: 'delete',
    headers: {
      //   'content-type': 'application/vnd.api+json',
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const searchGroupApi = async (id, params) => {
  return fetchApi(`timelines/group/${id}/search`, 'GET', params, null);
};

export const getSuggestionGroup = async (params: any) => {
  return fetchApi(`suggestions/group`, 'GET', params, null);
};

export const blockAndRemovePostGroup = async (id: any, params: any) => {
  return fetchApi(`groups/${id}/block_and_remove_status`, 'POST', null, params);
};

export const getPinnedPostGroup = async (idGroup: any, params: any) => {
  return fetchApi(`groups/${idGroup}/pins`, 'GET', params, null);
};

export const removeGroupAdmin = async (idGroup: any, idAdmin: any) => {
  return fetchApi(
    `groups/${idGroup}/accounts/${idAdmin}/remove_admin`,
    'PATCH',
    null,
    null
  );
};

export const searchGroupActivityLogs = async (groupId, params: any) => {
  return fetchApi(`groups/${groupId}/activity_logs`, 'GET', params, null);
};

export const searchMemberJoinedGroup = async (idGroup: any, params: any) => {
  return fetchApi(`groups/${idGroup}/join_requests`, 'GET', params, null);
};
