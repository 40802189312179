import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { buttonColor, buttonStyle, modalBackdrop } from 'src/constants/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function DialogOptionChange(props) {
  const {
    title,
    text,
    open,
    handleClose,
    action,
    actionCancel,
    labelButton,
    colorButton,
    loading,
    setLoading,
    contentDialog,
    alignText,
    styleDialogContent,
    maxWidth,
    notCancel
  } = props;

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={maxWidth ?? undefined}
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20 }}
            textAlign="center"
          >
            {title}
          </Typography>
        </BootstrapDialogTitle>
        <Divider />
        <DialogContent style={{ ...styleDialogContent }}>
          <Typography style={{ fontSize: 15 }}>{text}</Typography>
          {contentDialog || null}
        </DialogContent>
        <DialogActions>
          <ButtonInherit
            action={() => {
              handleClose();
              actionCancel();
            }}
            style={{
              ...buttonStyle,
              textTransform: 'none',
              color: '#7165E0',
              backgroundColor: 'transparent'
            }}
            label={notCancel ? notCancel : 'Hủy'}
          />

          <ButtonInherit
            action={() => {
              action();
              setLoading && setLoading(true);
            }}
            color={colorButton || buttonColor.backgroundColor}
            style={{
              fontSize: '14px',
              width: '160px',
              '&:hover': {
                backgroundColor: '#7165E0'
              }
            }}
            label={labelButton ? labelButton : 'Xóa'}
            loading={loading}
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
