import { makeStyles } from '@mui/styles';
import { NovuProvider, useSocket } from '@novu/notification-center';
import React from 'react';
import { getDetailNotification, postReadNotiApi } from 'src/apis/socialNoti';
import { scrollStyle } from 'src/constants/styles';
import { Theme } from '@mui/material';
import { urlNotificationApi, urlNotificationSocket } from 'src/util/config';
import NotificationUi from './NotificationUi';
import CommonNoti from './commonNoti';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 'calc(100vh - 75px)',
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
    borderTop: '1px solid rgba(0,0,0,0.10)',
    width: 360,
    position: 'absolute',
    top: 50,
    right: 20,
    padding: '12px',
    overflow: 'hidden auto',
    zIndex: '1000',
    ...scrollStyle
  },
  listItem: {
    padding: '6px !important',
    alignItems: 'flex-start !important',
    '&:hover': {
      borderRadius: '10px !important'
    }
  },
  text: {
    fontSize: '14px',

    fontWeight: '500 !important'
  },
  subText: {
    color: '#65676b',
    fontSize: '13px !important'
  },
  avatar: {
    width: '28px !important',
    height: '28px !important',
    position: 'absolute',
    top: 33,
    right: 2
  },

  itemText: {
    marginTop: 0
  },
  lineClamp: {
    display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden'
  }
}));

interface Props {
  noti?: any;
  setNoti?: React.Dispatch<React.SetStateAction<any>> | any;
  userId?: String | any;
}

function App(props: Props) {
  const { noti, setNoti, userId } = props;
  const [openNoti, setOpenNoti] = React.useState(false);
  const [openDialogViolate, setOpenDialogViolate] = React.useState(false);

  const classes = useStyles();
  const handleGetDetailNoti = async (id: any) => {
    let res = await getDetailNotification(id);
    if (res.status === 200) {
      setNoti(res.data);
      setOpenNoti(true);
      setOpenDialogViolate(true);
    }
  };
  const handleReadNoti = async (id: any) => {
    let res = await postReadNotiApi(id);
    if (res.status === 200) {
      setOpenNoti(false);
    }
  };

  function CustomNotificationCenter() {
    const { socket } = useSocket();

    React.useEffect(() => {
      if (socket) {
        socket.on('unseen_count_changed', data => {
          handleGetDetailNoti(data?.id);
        });
      }
      return () => {
        if (socket) {
          socket.off('unseen_count_changed');
        }
      };
    }, [socket]);

    return <></>;
  }

  return (
    <div className="noti-layout">
      <NovuProvider
        backendUrl={urlNotificationApi}
        socketUrl={urlNotificationSocket}
        subscriberId={userId}
        applicationIdentifier={'NQdDh27Faz0F'}
      >
        <CustomNotificationCenter />
      </NovuProvider>

      {openNoti && !noti?.read && (
        <NotificationUi
          openNoti={openNoti}
          setOpenNoti={setOpenNoti}
          data={noti}
          component={() => (
            <CommonNoti
              item={noti}
              openDialogViolate={openDialogViolate}
              setOpenDialogViolate={setOpenDialogViolate}
              setOpen={() => handleReadNoti(noti?.id)}
              type="notiSoket"
            />
          )}
        />
      )}
    </div>
  );
}
export default App;
