import { all, call, delay, put, takeEvery } from 'redux-saga/effects';
import * as types from 'src/constants/store/socialChat';
import Dexie, { Table } from 'dexie';
import {
  getListConversationSuccess,
  eventReceiveSubscriptionRoom,
  getListConversationsChatPageSuccess
} from '../action/socialChatAction';
import {
  getSubscriptions,
  getListMessage,
  getListConversationsChatPage,
  getSubscriptionsPinned,
  getSubscriptionsAll,
  sendMessageApi
} from 'src/apis/socialChat.apis';
import { urlRocketChat } from 'src/util/config';
import store from 'src/store/store';

const typesChat: any = types;

export const getListConversationSaga = function* ({ payload }) {
  const meInfo = store.getState().meReducer ?? '';
  const rocketId = meInfo?.info.id;
  try {
    const { limit, lastUpdated } = payload;
    const _response = yield call(getSubscriptions, null, limit, lastUpdated);
    if (_response.status === 200) {
      const listSubscription = JSON.parse(_response.data.message)?.result
        ?.update;
      if (listSubscription && listSubscription.length) {
        const listRooms = listSubscription
          .map(el => {
            if (el.t === 'cp' && el.RoomsList) {
              el.RoomsList[0].label = [];
              el.RoomsList[0].notes = [];
              el.RoomsList[0].user_chat_page = {};
              el.RoomsList[0].is_user_chat = null;
            }
            let membersGroups;
            if (el.t === 'p' || el.t === 'c') {
              membersGroups = el?.members.filter(item => {
                if (el?.members.length > 2) {
                  return item._id !== rocketId;
                } else {
                  return el;
                }
              });
            }

            el.type =
              el?.t === 'd' ? 'user' : el?.t === 'cp' ? 'chat_page' : 'group';
            el.avatar_media =
              el?.t === 'd'
                ? {
                    show_url: `${urlRocketChat}/avatar/${
                      el?.name ?? el.room?.lastMessage?.u?.username
                    }`
                  }
                : el?.t === 'p' || el?.t === 'c'
                ? {
                    avatar_top: {
                      show_url: `${urlRocketChat}/avatar/${membersGroups[0]?.username}`
                    },
                    avatar_bottom: {
                      show_url: `${urlRocketChat}/avatar/${membersGroups[1]?.username}`
                    }
                  }
                : el?.t === 'cp'
                ? {
                    show_url: el?.RoomsList[0]?.avatarUrl
                  }
                : null;
            el.lastMessage = el.RoomsList?.[0]?.lastMessage;
            el.usersCount = el.room?.usersCount;
            el.topic = el.topic || el?.RoomsList?.[0]?.topic || '';
            el.ro = el?.room?.ro;

            el.userId =
              el?.t === 'd'
                ? el.room?.uids?.filter(el => el !== rocketId)[0]
                : null;
            el.displayName =
              el?.t === 'cp'
                ? el?.RoomsList[0]?.real_name ??
                  el?.RoomsList[0]?.display_name ??
                  el?.name
                : el.fname;
            el.username = el?.name;

            return el;
          })
          .filter(el => el.f !== true);
        const _responsePinned = yield call(getSubscriptionsPinned, lastUpdated);
        if (_responsePinned.status === 200) {
          const listSubscriptionPinned = JSON.parse(
            _responsePinned.data.message
          )?.result?.update;
          if (listSubscriptionPinned) {
            const listRoomsPinned = listSubscriptionPinned
              .map(el => {
                if (el.t === 'cp' && el.RoomsList) {
                  el.RoomsList[0].label = [];
                  el.RoomsList[0].notes = [];
                  el.RoomsList[0].user_chat_page = {};
                  el.RoomsList[0].is_user_chat = null;
                }
                let membersGroups;
                if (el.t === 'p' || el.t === 'c') {
                  membersGroups = el?.members.filter(item => {
                    if (el?.members.length > 2) {
                      return item._id !== rocketId;
                    } else {
                      return el;
                    }
                  });
                }

                el.type =
                  el?.t === 'd'
                    ? 'user'
                    : el?.t === 'cp'
                    ? 'chat_page'
                    : 'group';
                el.avatar_media =
                  el?.t === 'd'
                    ? {
                        show_url: `${urlRocketChat}/avatar/${
                          el?.name ?? el.room?.lastMessage?.u?.username
                        }`
                      }
                    : el?.t === 'p' ?? el?.t === 'c'
                    ? {
                        avatar_top: {
                          show_url: `${urlRocketChat}/avatar/${membersGroups?.[0]?.username}`
                        },
                        avatar_bottom: {
                          show_url: `${urlRocketChat}/avatar/${membersGroups?.[1]?.username}`
                        }
                      }
                    : el?.t === 'cp'
                    ? {
                        show_url: el?.RoomsList?.[0]?.avatarUrl
                      }
                    : null;
                el.displayName = el?.fname ?? el.name;
                el.ro = el?.room?.ro;

                if (el.type === 'chat_page' ?? el.t === 'cp') {
                  el.displayName = el.real_name;
                  el.avatar_media = {
                    show_url: el.avatarUrl
                  };
                }
                el.topic = el.topic || el?.RoomsList?.[0]?.topic || '';
                el.lastMessage = el?.room?.lastMessage;
                el.RoomsList = [el?.room];
                el.usersCount = el.room?.usersCount;
                el.userId =
                  el?.t === 'd'
                    ? el.room?.uids?.filter(el => el !== rocketId)[0]
                    : null;

                el.username = el?.name;
                // el.room = null;

                return el;
              })
              .filter(el => el.f === true);
            yield put(
              getListConversationSuccess([...listRoomsPinned, ...listRooms])
            );
          }
        }
      }
    }
    const isDBLocalExists = yield Dexie.exists(`chat_emso_db_${rocketId}`);
    if (isDBLocalExists) {
      // console.log('exists, updated db');
      const db: any = yield new Dexie(`chat_emso_db_${rocketId}`);
      db.version(1).stores({
        conversations: 'rid',
        conversations_pinned: 'rid'
      });

      const conversations = yield db.conversations.toArray();
      const listRooms = conversations.sort(
        (a, b) => b?._updatedAt?.$date - a?._updatedAt?.$date
      );
      const updatedAt = listRooms[0]?._updatedAt?.$date;
      const response = yield call(getSubscriptionsAll, updatedAt, lastUpdated);
      const listSubscription = JSON.parse(response.data.message)?.result
        ?.update;
      if (listSubscription && listSubscription.length > 0) {
        const listRooms = listSubscription.map(el => {
          if (el.t === 'cp' && el.RoomsList) {
            el.RoomsList[0].label = [];
            el.RoomsList[0].notes = [];
            el.RoomsList[0].user_chat_page = {};
            el.RoomsList[0].is_user_chat = null;
          }
          let membersGroups;
          if (el.t === 'p' || el.t === 'c') {
            membersGroups = el?.members.filter(item => {
              if (el?.members.length > 2) {
                return item._id !== rocketId;
              } else {
                return el;
              }
            });
          }

          if (!el.disableNotifications) {
            el.disableNotifications = false;
          }
          if (!el.blocker) {
            el.blocker = false;
          }

          if (!el.blocked) {
            el.blocked = false;
          }

          el.type =
            el?.t === 'd' ? 'user' : el?.t === 'cp' ? 'chat_page' : 'group';
          el.avatar_media =
            el?.t === 'd'
              ? {
                  show_url: `${urlRocketChat}/avatar/${
                    el?.username ??
                    el?.name ??
                    el.room?.lastMessage?.u?.username
                  }`
                }
              : el?.t === 'p' ?? el?.t === 'c'
              ? {
                  avatar_top: {
                    show_url: `${urlRocketChat}/avatar/${membersGroups?.[0]?.username}`
                  },
                  avatar_bottom: {
                    show_url: `${urlRocketChat}/avatar/${membersGroups?.[1]?.username}`
                  }
                }
              : el?.t === 'cp'
              ? {
                  show_url: el?.avatarUrl ?? el?.room?.avatarUrl
                }
              : null;
          el.lastMessage = el.room?.lastMessage;
          el.usersCount = el.room?.usersCount;

          el.userId =
            el?.t === 'd'
              ? el.room?.uids?.filter(el => el !== rocketId)?.[0]
              : null;
          el.displayName =
            el?.t === 'cp'
              ? el?.real_name ??
                el?.RoomsList?.[0]?.real_name ??
                el?.RoomsList?.[0]?.display_name ??
                el?.name
              : el.fname;
          el.username = el?.name;
          if (!el.topic) {
            el.topic = el.room.topic;
          }

          return el;
        });
        db.conversations.bulkPut(listRooms);
      }
    } else {
      const db = yield new Dexie(`chat_emso_db_${rocketId}`);
      db.version(1).stores({
        conversations: '++rid',
        conversations_pinned: '++rid'
      });
      const response = yield call(getSubscriptions, null, null, lastUpdated);
      if (response.status === 200) {
        const listSubscription = JSON.parse(response.data.message)?.result
          ?.update;
        if (listSubscription && listSubscription.length > 0) {
          const listRooms = listSubscription.map(el => {
            let membersGroups;
            if (el.t === 'p' || el.t === 'c') {
              membersGroups = el?.members.filter(item => {
                if (el?.members.length > 2) {
                  return item._id !== rocketId;
                } else {
                  return el;
                }
              });
            }

            if (el.t === 'cp' && el.RoomsList) {
              el.RoomsList[0].label = [];
              el.RoomsList[0].notes = [];
              el.RoomsList[0].user_chat_page = {};
              el.RoomsList[0].is_user_chat = null;
            }

            el.type =
              el?.t === 'd' ? 'user' : el?.t === 'cp' ? 'chat_page' : 'group';
            el.avatar_media =
              el?.t === 'd'
                ? {
                    show_url: `${urlRocketChat}/avatar/${
                      el?.name ?? el.room?.lastMessage?.u?.username
                    }`
                  }
                : el?.t === 'p' ?? el?.t === 'c'
                ? {
                    avatar_top: {
                      show_url: `${urlRocketChat}/avatar/${membersGroups?.[0]?.username}`
                    },
                    avatar_bottom: {
                      show_url: `${urlRocketChat}/avatar/${membersGroups?.[1]?.username}`
                    }
                  }
                : el?.t === 'cp'
                ? {
                    show_url: el?.RoomsList?.[0]?.avatarUrl
                  }
                : null;
            el.lastMessage = el.RoomsList?.[0]?.lastMessage;
            el.usersCount = el.room?.usersCount;
            if (!el.disableNotifications) {
              el.disableNotifications = false;
            }
            if (!el.blocker) {
              el.blocker = false;
            }
            if (!el.blocked) {
              el.blocked = false;
            }
            el.ro = el?.room?.ro;
            el.userId =
              el?.t === 'd'
                ? el.room?.uids?.filter(el => el !== rocketId)?.[0]
                : null;
            el.displayName =
              el?.t === 'cp'
                ? el?.RoomsList?.[0]?.real_name ??
                  el?.RoomsList?.[0]?.display_name ??
                  el?.name
                : el.fname;
            el.username = el?.name;
            // el.room = null;
            if (!el.topic) {
              el.topic = el.room.topic;
            }
            return el;
          });
          yield db.conversations.bulkAdd(listRooms);
        }
      }
    }
  } catch (err: any) {
    console.log(err);
  }
};
let lastTimeAction = null;
export const getMessageSaga = function* ({ payload }) {
  try {
    const { conversation, lastTime } = payload;
    lastTimeAction = lastTime;
    const userId = localStorage.getItem('userId');
    const db: any = yield new Dexie(`chat_emso_db_${userId}`);
    db.version(1).stores({
      conversations: 'rid',
      conversations_pinned: 'rid'
    });
    const updatedAt = Date.now();
    const _response = yield call(getListMessage, conversation.rid, 1, lastTime);
    if (_response.status === 200) {
      const data = JSON.parse(_response.data.message);
      if (data?.result?.messages[0] && !!data?.result?.messages[0]) {
        const roomId = conversation.rid || conversation._id;
        const conversationDBLocal = yield db.conversations.get(roomId);
        if (conversation?.blocked !== conversationDBLocal?.blocked) {
          db.conversations.update(roomId, {
            blocked: conversation.blocked,
            '_updatedAt.$date': updatedAt
          });
        }
        if (conversation?.blocker !== conversationDBLocal?.blocker) {
          db.conversations.update(roomId, {
            blocker: conversation.blocker,
            '_updatedAt.$date': updatedAt
          });
        }
        if (conversation?.f !== conversationDBLocal?.f) {
          db.conversations.update(roomId, {
            f: conversation.f,
            '_updatedAt.$date': updatedAt
          });
        }
        const message = data.result.messages[0];
        yield delay(1000);
        if (lastTimeAction === lastTime) {
          yield put(eventReceiveSubscriptionRoom(conversation, message)); // trách dispath quá nhiều action khi spam tin nhắn
        }
      }
    }
  } catch (err: any) {
    console.log('tin nhắn lỗi không thể hiển thị', err);
  }
};

export const getConversationChatPage = function* ({ payload }) {
  try {
    const { idPage, count, offset, label }: any = payload;

    const _response = yield call(getListConversationsChatPage, {
      roomId: idPage,
      count,
      offset,
      label: JSON.stringify(label)
    });
    if (_response.status === 200) {
      const listRoomsChatPage = _response.data.discussions
        .filter(el => el !== null)
        .map(el => {
          el.type = 'chat_page';
          el.avatar_media = {
            show_url: `${urlRocketChat}/avatar/${el?.u.username}`
          };

          // el.lastMessage = el.lastMessage;
          // el.usersCount = el.room?.usersCount;
          el.userId = null;
          el.displayName = el?.user_chat_page?.name;
          // el.username = el?.name;
          el.user = el.u;
          el.room = null;
          el.rid = el?._id;
          el.is_user_chat = true;
          if (el.lastMessage && el.lastMessage.unread) {
            el.unread =
              el.lastMessage.unread &&
              el.lastMessage.u._id !== el.user_chat_page._id
                ? 0
                : el.lastMessage?.type !== 'automatic_reply'
                ? 1
                : 0;
          }
          return el;
        });
      yield put(
        getListConversationsChatPageSuccess(listRoomsChatPage, idPage, label)
      );
      // const data = JSON.parse(_response.data.message);
      // if (data.result.messages[0] && !!data.result.messages[0]) {
      //   yield put(eventReceiveSubscriptionRoom(conversation));
      // }
    }
  } catch (err: any) {}
};
export function* sendMessageNetWordRestored({ payload }) {
  const { message } = payload;
  try {
    for (let i = 0; i < message.length; i++) {
      const el = message[i];
      yield call(sendMessageApi, el.msg, el.rid, undefined, undefined, el._id);
    }
  } catch (error) {
    console.log('🚀 ~ error:', error);
  }
}

export function* watchSocialChatAsync() {
  yield takeEvery(typesChat.GET_LIST_CONVERSATION_REQ, getListConversationSaga);
  yield takeEvery(typesChat.GET_MESSAGE_REQ, getMessageSaga);
  yield takeEvery(typesChat.NETWORK_RESTORED, sendMessageNetWordRestored);
  yield takeEvery(
    typesChat.GET_LIST_CONVERSATION_CHAT_PAGE_REQ,
    getConversationChatPage
  );
}
