import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  Typography
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as React from 'react';
import { changePassword } from 'src/apis/socialUser';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import { buttonColor, modalBackdrop } from 'src/constants/styles';
import * as Yup from 'yup';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '0px 16px !important'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',

      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface Props {
  title?: String;
  open: boolean;
  handleClose?: any;
  colorButton?: String | any;
}

export default function CustomizedDialogs(props: Props) {
  const { title, open, handleClose, colorButton } = props;
  const [loading, setLoading] = React.useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [passwordNewVisible, setPasswordNewVisible] = React.useState(false);
  const [createPass, setCreatePass] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });

  const handleCreatePassword = async data => {
    try {
      let response = await changePassword({
        new_password: data.new_password,
        new_password_confirmation: data.new_password_confirmation,
        revoke_token: false
      });
      if (response.status === 200) {
        handleClose();
        setLoading(false);
        setOpenSnackbar(true);
        setNoti({
          code: 200,
          message: 'Thay đổi mật khẩu thành công'
        });
      }
    } catch (err) {
      handleClose();
      setLoading(false);
      setOpenSnackbar(true);
      setNoti({
        code: 400,
        message: 'Có lỗi trong quá trình thay đổi mật khẩu.Vui lòng thử lại sau'
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      new_password: '',
      new_password_confirmation: ''
    },
    validationSchema: Yup.object({
      new_password: Yup.string()
        .matches(/^\S*$/, 'Không được chứa khoảng trắng')
        .required('Mật khẩu không được để trống')
        .min(9, 'Mật khẩu phải tối thiểu 9 kí tự.'),
      new_password_confirmation: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Mật khẩu phải trùng khớp nhau')
        .matches(/^\S*$/, 'Không được chứa khoảng trắng')
        .required('Mật khẩu không được để trống')
        .min(9, 'Mật khẩu phải tối thiểu 9 kí tự.')
    }),
    onSubmit: values => {
      try {
        if (values) {
          handleCreatePassword(values);
        }
      } catch (e) {
        console.log(e);
      }
    }
  });

  React.useEffect(() => {
    if (
      formik.values.new_password &&
      formik.values.new_password_confirmation &&
      Object.keys(formik.errors).length === 0
    ) {
      setCreatePass(true);
    } else {
      setCreatePass(false);
    }
  }, [JSON.stringify(formik.values), JSON.stringify(formik.errors)]);

  const renderAlert = (nameError: string) => {
    return (
      <Grid item xs={12} style={{ paddingTop: 10 }}>
        <Alert sx={{ width: '100% !important' }} severity="error">
          <Typography style={{ fontSize: 15, fontStyle: 'italic' }}>
            {nameError}
          </Typography>
        </Alert>
      </Grid>
    );
  };

  const renderShowHidePass = (action, type) => {
    let checkPass =
      type === 'new_password' ? passwordVisible : passwordNewVisible;
    return (
      <Box
        onClick={() => {
          action();
        }}
        sx={{
          position: 'absolute',
          right: '12px',
          top: '34px',
          cursor: 'pointer'
        }}
      >
        {checkPass ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
      </Box>
    );
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20 }}
            textAlign={'center'}
          >
            {title}
          </Typography>
        </BootstrapDialogTitle>
        <Divider />
        <DialogContent>
          <Typography
            sx={{
              fontSize: '12px',
              fontStyle: 'italic',
              padding: '10px 0px 5px'
            }}
          >
            Bạn cần đặt mật khẩu mới cho tài khoản EMSO của mình khi đăng
            ký/đăng nhập từ google để tăng độ bảo mật và đăng nhập dễ dàng hơn.
          </Typography>
          <FormControl
            style={{ width: '100%', marginBottom: 5 }}
            variant="standard"
          >
            <Typography>Nhập mật khẩu mới</Typography>
            <BootstrapInput
              fullWidth
              placeholder="Nhập mật khẩu mới..."
              name="new_password"
              type={passwordVisible ? 'text' : 'password'}
              value={formik.values.new_password}
              onChange={e =>
                formik.setFieldValue('new_password', e.target.value)
              }
            />

            {renderShowHidePass(
              () => setPasswordVisible(!passwordVisible),
              'new_password'
            )}
            {formik.errors.new_password &&
              renderAlert(formik.errors.new_password)}
          </FormControl>
          <FormControl
            style={{ width: '100%', marginTop: '10px' }}
            variant="standard"
          >
            <Typography>Nhập lại mật khẩu mới</Typography>
            <BootstrapInput
              fullWidth
              placeholder="Nhập lại mật khẩu mới..."
              name="new_password_confirmation"
              type={passwordNewVisible ? 'text' : 'password'}
              value={formik.values.new_password_confirmation}
              onChange={e =>
                formik.setFieldValue(
                  'new_password_confirmation',
                  e.target.value
                )
              }
            />
            {renderShowHidePass(
              () => setPasswordNewVisible(!passwordNewVisible),
              'new_password_confirmation'
            )}
            {formik.errors.new_password_confirmation &&
              renderAlert(formik.errors.new_password_confirmation)}
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', padding: '10px' }}>
          <ButtonInherit
            action={() => {
              setLoading(true);
              formik.handleSubmit();
            }}
            color={colorButton || buttonColor.backgroundColor}
            style={{ fontSize: '14px', width: '250px' }}
            label={'Đặt mật khẩu'}
            loading={loading}
            disabled={createPass ? false : true}
          />
        </DialogActions>
      </BootstrapDialog>
      <SnackbarNotification
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        code={noti?.code}
        message={noti?.message}
      />
    </div>
  );
}
