import VerifiedIcon from '@mui/icons-material/Verified';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  Theme,
  Tooltip,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useRef } from 'react';
import ClickOutHandler from 'react-onclickout';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCategoriesFeedback, sendFeedback } from 'src/apis/socialUser';
import { updateThemeApi } from 'src/apis/user.api';
import logo from 'src/assets/images/LogoEmso.svg';
import Footer from 'src/components/Footer';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import { PATHS } from 'src/constants/paths';
import { buttonColor, buttonColorHover } from 'src/constants/styles';
import { ColorModeContext } from 'src/context/ThemeContext';
import { loginReq } from 'src/store/action/login';
import { notiSharePost } from 'src/store/action/socialPostAction';
import { getInfoMeReq } from 'src/store/action/userAction';
import { uploadMediaSaga } from 'src/store/saga/socialPostSaga';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    right: 20,
    top: 50,
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2) !important',
    borderRadius: 10,
    width: 360,
    padding: 12
  },
  wrapAvatar: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    borderRadius: 10,
    padding: 4,
    marginBottom: 4,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0,0,0,0.07)'
    }
  },
  wrapUserBlock: {
    margin: '8px 4px 12px 4px',
    padding: '4px',
    borderRadius: 10,
    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.2)'
  },
  wrapText: {
    height: 46,
    borderRadius: 10,
    padding: 4,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0,0,0,0.07)'
    },
    marginTop: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  subText: {
    height: 18,
    width: 18,
    borderRadius: '50%',
    backgroundColor: 'red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  wrapTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapIcon: {
    '&:hover': {
      cursor: 'pointer'
    },
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  rootListButton: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent !important'
    },
    marginLeft: 10
  },
  radio: {
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#4b4b4b' : '#f4f4f4'
    },
    padding: '4px',
    borderRadius: '6px',
    margin: '5px 0px'
  },

  iconClose: {
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#4b4b4b' : '#f4f4f4',
      borderRadius: '100%',
      cursor: 'pointer'
    },
    background: theme.palette.mode === 'dark' ? '#3A3B3C' : '#FAFAFB',
    borderRadius: '100%'
  },
  textItem: {
    '& .MuiTypography-body1': {
      fontWeight: 600
    }
  },
  dialog: {
    '& .MuiPaper-root': {
      borderRadius: '8px'
    }
  },
  itemBtn: {
    '& .MuiListItemButton-root': {
      '&:hover': {
        borderRadius: '8px'
      }
    },
    '& .MuiBox-root': {
      width: '60px',
      height: '60px',
      background: theme.palette.mode === 'dark' ? '#3A3B3C' : '#D8DADF',
      borderRadius: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '12px'
    }
  },
  iconBack: {
    width: '48px',
    height: '48px',
    background: theme.palette.mode === 'dark' ? '#3A3B3C' : '#FAFAFB',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#4b4b4b' : '#f4f4f4',
      borderRadius: '100%',
      cursor: 'pointer'
    }
  },
  textara: {
    '&:focus': {
      outline: 'none'
    },
    border: '1px solid #C4C4C4',
    width: '100%',
    borderRadius: '8px',
    padding: '5px',
    background: '#F0F2F5',
    fontFamily: 'Segoe UI,Tahoma,Geneva,Verdana,sans-serif'
  },
  linkMedia: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    marginTop: '15px',
    padding: '0px 10px',
    height: '36px',
    borderRadius: '6px',
    position: 'relative'
  },
  helpCenter: {
    textDecoration: 'none !important',
    color: `${buttonColor.backgroundColor} !important`,
    '&:hover': {
      textDecoration: 'underline !important',
      textDecorationColor: 'rgba(144, 202, 249, 0.4) !important'
    }
  },
  customTooltip: {
    '& .MuiTooltip-tooltip': {
      fontSize: '14px',
      padding: '8px'
    }
  },
  boxListUser: {
    maxHeight: '280px',
    overflowY: 'auto'
  }
}));
interface FeedBack {
  id: number;
  text: string;
}

const DropdownArrow = props => {
  const { setOpen, handleLinkToProfile, handleClosePreview } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const [listCategoriesFeedback, setListCategoriesFeedBack] = React.useState(
    []
  );
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const [listUserLogin, setListUserLogin] = React.useState<any>([]);
  const [isTransferAccount, setIsTransferAccount] = React.useState(false);
  const [themeSetup, setThemeSetup] = React.useState<boolean>(false);
  const [opinions, setOpinions] = React.useState<boolean>(false);
  const [filesOpinions, setFilesOpinions] = React.useState<any>([]);
  const [showFormOpinions, setShowFormOpinions] =
    React.useState<boolean>(false);
  const [choseProduct, setChoseProduct] = React.useState<any>('');
  const [description, setDescription] = React.useState<any>('');
  const [themeDisplay, setThemeDisplay] = React.useState<any>(
    localStorage.getItem('darkThemeUser')
  );
  const [isLoadingUpload, setIsLoadingupload] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  const [listIdFile, setListIdFile] = React.useState<string[]>([]);
  const [type, setType] = React.useState<string>('contribution');
  const filterListUserLogin = listUserLogin?.filter(
    (item: any) => item.id !== meInfo.id
  );

  const handleChangeProduct = (event: any) => {
    setChoseProduct(event.target.value as string);
  };
  const [textError, setTextError] = React.useState('');
  const inputRef = useRef(null);
  const userSendFeedBack = async (description, listIdFile, type) => {
    try {
      let response;
      response = await sendFeedback({
        feedback_category_id: choseProduct,
        feedback_type: type,
        description: description,
        media_attachment_ids: listIdFile
      });
      if (response.status === 200) {
        setShowFormOpinions(false);
        handleCloseOpinions();
        setFilesOpinions([]);
        setDescription('');
        setChoseProduct('');
        setOpenSnackbar(true);
        setNoti({
          code: 200,
          message:
            type === 'contribution'
              ? 'Gửi đóng góp thành công.'
              : 'Gửi báo lỗi thành công'
        });
      }
    } catch (err: any) {
      setOpenSnackbar(true);
      setNoti({
        code: err.response.status,
        message: 'Xảy ra quá trình lỗi khi gửi đóng góp.Vui lòng thử lại sau!'
      });
    }
  };
  function handleLogOut() {
    localStorage.removeItem('isFastLogin');
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    handleClosePreview && handleClosePreview();
    history.push(`${PATHS.LOGIN}`);
    const channel = new BroadcastChannel('tokenChannel');
    channel.postMessage(null);
  }
  const handleDeleteFile = (indexFile, inputRef) => {
    const newListFileIds = listIdFile.filter(
      (item, index) => index !== indexFile
    );
    const newListFileOpinions = filesOpinions.filter(
      (item, index) => index !== indexFile
    );
    if (newListFileIds.length === 0 || newListFileOpinions.length === 0) {
      inputRef.current.value = '';
    }
    setFilesOpinions(newListFileOpinions);
    setListIdFile(newListFileIds);
  };

  const handleClickLoginUser = user => {
    let userSelected = listUserLogin.find((el: any) => +el.id === +user.id);
    if (userSelected) {
      if (userSelected.isFastLogin === 'no') {
        handleLogOut();
        localStorage.setItem(
          'openLogin',
          JSON.stringify({
            open: true,
            id: userSelected.id
          })
        );
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        dispatch(loginReq(null, null, userSelected.token));
      }
    }
  };
  const handleDeleteSignIn = user => {
    let newListUserLogin: any = listUserLogin?.filter(
      (el: any) => +el.id !== +user.id
    );
    setListUserLogin(newListUserLogin);
    localStorage.setItem('dataUser', JSON.stringify(newListUserLogin));
  };

  const listMenu = [
    {
      id: 'setting',
      label: 'Cài đặt và quyền riêng tư',
      icon: 'fa-solid fa-gear',
      action: () => {
        handleClosePreview && handleClosePreview();
        history.push('/settings/common');
      },
      type: 'menu'
    },
    {
      id: 'helpers',
      label: 'Trợ giúp và hỗ trợ',
      icon: 'fa-solid fa-circle-info',
      action: () => {
        history.push('/help');
      },
      type: 'helpers'
    },
    {
      id: 'display',
      label: 'Màn hình',
      icon:
        theme.palette.mode === 'dark' ? 'fa-light fa-sun' : 'fa-solid fa-moon',
      action: () => {
        setThemeSetup(true);
      },
      type: 'setting'
    },
    {
      id: 'opinions',
      label: 'Đóng góp ý kiến',
      icon: 'fa-solid fa-message-exclamation fa-sm',
      action: () => {
        setOpinions(true);
      },
      type: 'opinions'
    },
    {
      id: 'logout',
      label: 'Đăng xuất',
      icon: 'fas fa-sign-out-alt',
      action: handleLogOut,
      type: 'menu'
    }
  ];

  const listMenuAccount = [
    {
      id: 'login-new',
      label: 'Đăng nhập bằng tài khoản khác',
      icon: 'fa-solid fa-circle-plus',
      action: () => {
        handleLogOut();
      }
    }
  ];
  React.useEffect(() => {
    const getCategories = async () => {
      let res = await getCategoriesFeedback();
      if (res.status === 200) {
        setListCategoriesFeedBack(res.data);
      }
    };
    getCategories();
  }, []);
  React.useEffect(() => {
    let listUserLoginStorage: any = localStorage.getItem('dataUser') ?? '[]';
    setListUserLogin(JSON.parse(listUserLoginStorage));
  }, []);
  const validateFilesUpload = (files: any) => {
    if (files.length > 6) {
      return setTextError('Bạn chỉ được tải lên tối đa 6 files');
    }
    const allowedTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/msword',
      'video/mp4'
    ];
    files.forEach(file => {
      if (!allowedTypes.includes(file.type)) {
        return setTextError(
          'Bạn chỉ được tải lên các tệp theo định dạng (image/jpeg, image/jpg, image/png, docx, video/mp4, sheet)'
        );
      }
    });
    return true;
  };
  const handleFileChange = async (event: any) => {
    setIsLoadingupload(true);
    try {
      setTextError('');
      setFilesOpinions([]);
      let filesObject: Object = event.target.files;
      const filesArray = Object.keys(filesObject).map(key => filesObject[key]);
      
      if (validateFilesUpload(filesArray)) {
        setFilesOpinions(filesArray);
        let resFiles = await uploadMediaSaga(filesArray, type);
        const _files = [...resFiles];
        setListIdFile(_files);
      }
    } catch (error) { 
      dispatch(
        notiSharePost({
          code: 400,
          msg: 'Đã có lỗi xảy ra. Vui lòng thử lại sau!'
        })
      );
      setFilesOpinions([]);
     setListIdFile([])
    } finally {
      setIsLoadingupload(false);
    }
  };
  const renderListAction = (item, type) => {
    return (
      <ListItem
        key={item.id}
        style={{ borderRadius: 10, padding: '4px 0px' }}
        button
        dense={true}
        secondaryAction={
          type === 'account' ? (
            <div
              onClick={() => handleDeleteSignIn(item)}
              className={classes.wrapIcon}
            >
              <i className="fa-solid fa-xmark"></i>
            </div>
          ) : null
        }
      >
        <ListItemButton
          onClick={() => {
            if (type === 'account') {
              handleClickLoginUser(item);
              setOpen(false);
            }
            if (type === 'setting' || type === 'opinions') {
              item.action && item.action();
            } else {
              item.action && item.action();
              setOpen(false);
            }
          }}
          classes={{ root: classes.rootListButton }}
          dense
        >
          {type === 'account' ? (
            <ListItemAvatar>
              <AvatarSocial
                type={'feed'}
                src={item?.show_url}
                style={{ width: 40, height: 40 }}
              />
            </ListItemAvatar>
          ) : (
            <ListItemIcon>
              <IconButton
                style={{
                  width: 36,
                  height: 36
                }}
                sx={{
                  backgroundColor: 'button.primary.background',
                  '&:hover': {
                    backgroundColor: 'none'
                  }
                }}
              >
                <i
                  style={{
                    color: theme.palette.mode === 'dark' ? '#e4e6ea' : '#000'
                  }}
                  className={item.icon}
                ></i>
              </IconButton>
            </ListItemIcon>
          )}
          <ListItemText
            primary={
              <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                {type === 'account' ? item.name : item.label}
              </Typography>
            }
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setThemeDisplay((event.target as HTMLInputElement).value);
    localStorage.setItem(
      'darkThemeUser',
      (event.target as HTMLInputElement).value
    );
  };

  const handleUpdateTheme = async (theme: any) => {
    let response = await updateThemeApi({ theme: theme });
    if (response.status === 200) {
      dispatch(getInfoMeReq());
    }
  };

  useEffect(() => {
    if (themeDisplay) {
      colorMode.toggleColorMode(themeDisplay);
      handleUpdateTheme(themeDisplay);
    }
  }, [themeDisplay]);
  const handleCloseOpinions = () => {
    setOpinions(false);
  };

  return (
    <ClickOutHandler
      onClickOut={() => (opinions ? setOpen(true) : setOpen(false))}
    >
      <Box
        className={classes.root}
        sx={{
          backgroundColor: 'background.primary'
        }}
      >
        {isTransferAccount ? (
          <div>
            <div className={classes.wrapTitle}>
              <IconButton
                style={{
                  width: 36,
                  height: 36
                }}
                onClick={() => setIsTransferAccount(false)}
              >
                <i
                  style={{
                    color: theme.palette.mode === 'dark' ? '#fff' : '#000'
                  }}
                  className="fa-solid fa-arrow-left"
                ></i>
              </IconButton>
              <Typography
                style={{ fontSize: 20, fontWeight: 600, marginLeft: 8 }}
              >
                Chuyển tài khoản
              </Typography>
            </div>
            <Typography
              style={{ fontSize: 15, color: '#65676b', margin: '8px 0px' }}
            >
              Bạn sẽ được đăng xuất trước. Sau đó, người khác có thể đăng nhập
              hoặc thêm tài khoản của họ
            </Typography>

            <Divider />
            <Box className={classes.boxListUser}>
              {filterListUserLogin.map((el: any) =>
                renderListAction(el, 'account')
              )}
            </Box>

            {filterListUserLogin.length > 1 ? (
              <>
                <div style={{ height: 8 }}></div>
                <Divider />
                <div style={{ height: 8 }}></div>
              </>
            ) : null}
            {listMenuAccount?.map((el: any) => renderListAction(el, 'menu'))}
          </div>
        ) : themeSetup ? (
          <div>
            <div className={classes.wrapTitle}>
              <IconButton
                style={{
                  width: 36,
                  height: 36
                }}
                onClick={() => setThemeSetup(false)}
              >
                <i
                  style={{ color: '#7b7b7b' }}
                  className="fa-solid fa-arrow-left"
                ></i>
              </IconButton>
              <Typography
                style={{ fontSize: 20, fontWeight: 600, marginLeft: 8 }}
              >
                Chế độ màn hình
              </Typography>
            </div>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ margin: '8px 8px 0px 3px ' }}>
                <Box
                  sx={{
                    backgroundColor: 'background.default',
                    borderRadius: '50%',
                    height: '40px',
                    width: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'text.primary'
                  }}
                >
                  <i
                    className={`fa-${
                      theme.palette.mode === 'dark' ? 'light' : 'solid'
                    } fa-moon`}
                    style={{ fontSize: 25 }}
                  ></i>
                </Box>
              </Box>
              <Box>
                <Typography style={{ fontSize: 15, margin: '8px 0px' }}>
                  Điều chỉnh giao diện của EMSO để phù hợp với đôi mắt của mình.
                </Typography>

                <Divider />
                <FormControl>
                  <RadioGroup onChange={handleChange} value={themeDisplay}>
                    <FormControlLabel
                      className={classes.radio}
                      value="light"
                      control={<Radio />}
                      labelPlacement="start"
                      label={
                        <Typography fontWeight={600} sx={{ width: '100%' }}>
                          Tắt
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      className={classes.radio}
                      value="dark"
                      control={<Radio />}
                      labelPlacement="start"
                      label={
                        <Typography fontWeight={600} sx={{ width: '100%' }}>
                          Bật
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      className={classes.radio}
                      value="system"
                      control={<Radio />}
                      labelPlacement="start"
                      label={
                        <Box>
                          <Typography fontWeight={600} sx={{ width: '100%' }}>
                            Tự động
                          </Typography>
                          <Typography fontSize={12}>
                            Chúng tôi sẽ tự động điều chỉnh màn hình theo cài
                            đặt hệ thống trên thiết bị của bạn
                          </Typography>
                        </Box>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </div>
        ) : opinions ? (
          <Box>
            <Dialog
              className={classes.dialog}
              fullWidth
              maxWidth={'sm'}
              open={opinions}
              onClose={handleCloseOpinions}
            >
              <Box
                sx={{
                  background: theme.palette.mode === 'dark' ? '#242526' : null
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <DialogTitle
                    sx={{
                      fontWeight: 700,
                      position: 'relative'
                    }}
                  >
                    {type === 'contribution'
                      ? ' Đóng góp ý kiến cho EMSO'
                      : 'Đã xảy ra lỗi'}
                  </DialogTitle>
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    right: 20,
                    top: 10
                  }}
                  onClick={handleCloseOpinions}
                  className={classes.iconClose}
                >
                  <i
                    className="fa-duotone fa-circle-xmark"
                    style={{
                      '--fa-primary-color':
                        theme.palette.mode === 'dark' ? '#A8ABAF' : '#606770',
                      '--fa-primary-opacity': 0.6,
                      '--fa-secondary-color':
                        theme.palette.mode === 'dark' ? '#3A3B3C' : '#E4E6EB',
                      '--fa-secondary-opacity': 0.2,
                      fontSize: '3rem'
                    }}
                  ></i>
                </Box>
                {showFormOpinions ? (
                  <Box
                    sx={{
                      position: 'absolute',
                      left: 20,
                      top: 10
                    }}
                    onClick={() => {
                      setShowFormOpinions(false);
                    }}
                    className={classes.iconBack}
                  >
                    <i
                      style={{ color: '#7b7b7b' }}
                      className="fa-solid fa-arrow-left fa-xl"
                    ></i>
                  </Box>
                ) : null}

                <Divider />
              </Box>
              {showFormOpinions ? (
                <Box
                  sx={{
                    padding: '15px',
                    background: theme.palette.mode === 'dark' ? '#242526' : null
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: 700 }}>
                    Chúng tôi có thể cải thiện như thế nào?
                  </Typography>
                  <Box>
                    <FormControl fullWidth sx={{ margin: '15px 0px' }}>
                      <InputLabel id="demo-simple-select-label">
                        Chọn sản phẩm
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={choseProduct}
                        label="Chọn sản phẩm"
                        onChange={handleChangeProduct}
                        MenuProps={{
                          PaperProps: { style: { maxHeight: 300 } }
                        }}
                      >
                        {listCategoriesFeedback.map((feedBack: FeedBack) => {
                          return (
                            <MenuItem value={feedBack.id}>
                              {feedBack.text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ fontWeight: 600 }}
                      >
                        Chi tiết
                      </Typography>
                      <TextareaAutosize
                        placeholder="Vui lòng chia sẻ chi tiết nhất có thể..."
                        name="description"
                        onChange={e => setDescription(e.target.value)}
                        minRows={4}
                        value={description}
                        className={classes.textara}
                        style={{
                          color:
                            theme.palette.mode === 'dark' ? '#fff' : '#000',
                          background:
                            theme.palette.mode === 'dark' ? '#242526' : '#FFF'
                        }}
                      />
                    </FormControl>
                    <Box
                      className={classes.linkMedia}
                      sx={{
                        background:
                          theme.palette.mode === 'dark' ? '#3A3B3C' : '#E4E6EB'
                      }}
                    >
                      <i className="fa-solid fa-link"></i>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          paddingLeft: '5px'
                        }}
                      >
                        Thêm video hoặc ảnh chụp màn hình (đề xuất)
                      </Typography>
                      <input
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        ref={inputRef}
                        accept=".jpg, .jpeg, .png, .docx, .xlsx, .mp4"
                        style={{
                          position: 'absolute',
                          width: '100%',
                          opacity: 0,
                          cursor: 'pointer'
                        }}
                      />
                    </Box>
                    {filesOpinions.length !== 0 && !textError ? (
                      <Box
                        sx={{
                          width: '100%',
                          height: '77px',
                          margin: '15px 10px 0px 0px',
                          display: 'flex'
                        }}
                      >
                        {filesOpinions.map((fileOpinion, index) => {
                          return fileOpinion.type === 'image/jpeg' ||
                            fileOpinion.type === 'image/png' ||
                            fileOpinion.type === 'image/jpg' ? (
                            <Box
                              style={{
                                width: '80px',
                                height: '100%',
                                borderRadius: '8px',
                                backgroundColor: '#E4E6EB',
                                padding: '0px 10px',
                                position: 'relative',
                                marginRight: '8px'
                              }}
                            >
                              <img
                                key={fileOpinion}
                                src={URL.createObjectURL(fileOpinion)}
                                alt=""
                                style={{
                                  maxHeight: '100%',
                                  maxWidth: '100%',
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%,-50%)'
                                }}
                              />
                              <i
                                className="fa-solid fa-circle-xmark"
                                style={{
                                  position: 'absolute',
                                  top: '3px',
                                  right: '0px',
                                  color: '#606770',
                                  cursor: 'pointer'
                                }}
                                onClick={() =>
                                  handleDeleteFile(index, inputRef)
                                }
                              ></i>
                            </Box>
                          ) : (
                            <Box
                              style={{
                                width: '80px',
                                height: '100%',
                                borderRadius: '8px',
                                backgroundColor: '#E4E6EB',
                                padding: '0px 10px',
                                position: 'relative',
                                marginRight: '8px'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '10px',
                                  textTransform: 'uppercase',
                                  position: 'absolute',
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                  textAlign: 'center',
                                  width: '60px',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  color: 'black'
                                }}
                              >
                                {fileOpinion.type}
                              </Typography>
                              <i
                                className="fa-solid fa-circle-xmark"
                                style={{
                                  position: 'absolute',
                                  top: '3px',
                                  right: '0px',
                                  color: '#606770',
                                  cursor: 'pointer'
                                }}
                                onClick={() =>
                                  handleDeleteFile(index, inputRef)
                                }
                              ></i>
                            </Box>
                          );
                        })}
                      </Box>
                    ) : null}
                    {textError && (
                      <Typography sx={{ color: '#FF0000' }}>
                        {textError}
                      </Typography>
                    )}
                    <Box
                      sx={{
                        background:
                          theme.palette.mode === 'dark' ? '#3A3B3C' : '#E4E6EB',
                        marginTop: '15px',
                        borderRadius: '8px'
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ paddingLeft: '5px', margin: '15px 20px' }}
                      >
                        Nếu bạn có ý tưởng để cải thiện sản phẩm thì hãy cho
                        chúng tôi biết nhé. Còn nếu cần trợ giúp để khắc phục
                        vấn đề cụ thể, hãy truy cập{' '}
                        <Link href="#" className={classes.helpCenter}>
                          {' '}
                          Trung tâm trợ giúp.
                        </Link>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'end',
                        marginTop: '30px'
                      }}
                    >
                      <Button
                        variant="text"
                        onClick={() => setShowFormOpinions(false)}
                        style={{ textTransform: 'none' }}
                      >
                        Huỷ
                      </Button>
                      <Button
                        variant="contained"
                        disabled={
                          !description ||
                          textError ||
                          !choseProduct ||
                          isLoadingUpload
                            ? true
                            : false
                        }
                        sx={{
                          width: '120px',
                          marginLeft: '15px',
                          backgroundColor: buttonColor.backgroundColor,
                          '&:hover': {
                            backgroundColor: buttonColorHover.backgroundColor
                          },
                          textTransform: 'none'
                        }}
                        onClick={() =>
                          userSendFeedBack(description, listIdFile, type)
                        }
                      >
                        Gửi
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <List
                    sx={{
                      width: '100%',
                      background:
                        theme.palette.mode === 'dark' ? '#242526' : null
                    }}
                  >
                    <ListItem
                      sx={{ padding: '0px 8px' }}
                      className={classes.itemBtn}
                    >
                      <ListItemButton
                        onClick={() => {
                          setShowFormOpinions(true);
                          setType('contribution');
                        }}
                      >
                        <Box>
                          <Avatar
                            src={logo}
                            sx={{ width: '36px', height: '36px' }}
                          />
                        </Box>
                        <ListItemText
                          className={classes.textItem}
                          primary="Chung tay cải thiện EMSO"
                          secondary="Đóng góp ý kiến về trải nghiệm sử dụng EMSO của bạn."
                        />
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      sx={{ padding: '0px 8px' }}
                      className={classes.itemBtn}
                    >
                      <ListItemButton
                        onClick={() => {
                          setShowFormOpinions(true);
                          setType('error');
                        }}
                      >
                        <Box>
                          <i className="fa-solid fa-triangle-exclamation fa-xl"></i>
                        </Box>
                        <ListItemText
                          className={classes.textItem}
                          primary="Đã xảy ra lỗi"
                          secondary="Hãy cho chúng tôi biết về tính năng bị lỗi."
                        />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Box>
              )}
            </Dialog>
          </Box>
        ) : (
          <>
            <div className={classes.wrapUserBlock}>
              <div
                onClick={() => {
                  handleLinkToProfile();
                  setOpen(false);
                }}
                className={classes.wrapAvatar}
              >
                <AvatarSocial
                  type="feed"
                  avatarObj={meInfo?.avatar_media ?? meInfo?.avatar_static}
                  style={{ width: 40, height: 40, marginRight: 8 }}
                />
                <Typography style={{ fontSize: 17, fontWeight: 500 }}>
                  {meInfo?.display_name}{' '}
                  {meInfo?.certified ? (
                    <span>
                      <Tooltip
                        placement="top-start"
                        title="Emso đã xác nhận tài khoản này đã được xác minh danh tính và có tác động tích cực đến cộng đồng"
                        arrow
                        PopperProps={{
                          sx: {
                            '& .MuiTooltip-tooltip': {
                              fontSize: '14px',
                              padding: '10px'
                            }
                          }
                        }}
                      >
                        <VerifiedIcon
                          // titleAccess="Tài khoản đã xác minh"
                          fontSize="small"
                          viewBox="0 -2 24 24"
                          sx={{
                            color: buttonColor.backgroundColor,
                            fontSize: '14px',
                            '&:hover': {
                              color: buttonColor.backgroundColor
                            }
                          }}
                        />
                      </Tooltip>
                    </span>
                  ) : null}
                  {!meInfo.certified && meInfo.identity_verification ? (
                    <span>
                      <Tooltip
                        placement="top-start"
                        title="Emso đã xác nhận tài khoản này đã được xác minh danh tính"
                        arrow
                        PopperProps={{
                          sx: {
                            '& .MuiTooltip-tooltip': {
                              fontSize: '14px',
                              padding: '10px'
                            }
                          }
                        }}
                      >
                        <VerifiedIcon
                          // titleAccess="Tài khoản đã xác minh"
                          fontSize="small"
                          viewBox="0 -2 24 24"
                          sx={{
                            color: 'hsla(0, 0%, 50%)',
                            fontSize: '14px',
                            '&:hover': {
                              color: 'hsla(0, 0%, 60%)'
                            }
                          }}
                        />
                      </Tooltip>
                    </span>
                  ) : null}
                </Typography>
              </div>
              <Divider />
              <div
                onClick={() => {
                  setIsTransferAccount(true);
                }}
                className={classes.wrapText}
              >
                <Typography
                  style={{
                    color: buttonColor.backgroundColor,
                    fontSize: 15,
                    fontWeight: 500
                  }}
                >
                  Chuyển sang tài khoản khác
                </Typography>
                {filterListUserLogin.length ? (
                  <div className={classes.subText}>
                    <Typography
                      style={{ color: '#fff', fontSize: 15, fontWeight: 500 }}
                    >
                      {filterListUserLogin.length}
                    </Typography>
                  </div>
                ) : null}
              </div>
            </div>
            {listMenu?.map((el: any) => renderListAction(el, el.type))}
            <Footer />
          </>
        )}
      </Box>
      {openSnackbar && (
        <SnackbarNotification
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          code={noti?.code}
          message={noti?.message}
        />
      )}
    </ClickOutHandler>
  );
};

export default DropdownArrow;
