import * as types from 'src/constants/store/groupSocial';
import { cloneDeep, uniqBy } from 'lodash';
import _ from 'lodash';

const initialState = {
  groupSelected: {},
  membersGroup: {
    members: [],
    loaded: true
  },
  friendsGroup: {
    friends: [],
    loaded: true
  },
  adminsGroup: {
    admins: [],
    loaded: true
  },
  moderatorsGroup: {
    moderators: [],
    loaded: true
  },
  accountsRemove: [],
  waitingPost: [],
  waitingMember: [],
  reportPosts: [] as any,
  ruleGroups: [],
  memberQuestions: [] as any,
  schedulePosts: [] as any,
  censorShipReports: [],
  activities: [],
  loadActivities: true,
  groupRelationShip: {},
  groupPreview: {},
  invitations: {
    invitationsAdmin: [],
    invitationsModerator: [],
    loaded: true
  },
  error: false,
  groupSearch: null as any,
  isLoading: true,
  isLoadingMember: true,
  loadingActivityLog: false,
  statuses: [],
  loadingSearchGroup: false,
  activitiesUserInGroup: {
    listStatus: [],
    hasMore: true,
    isLoading: false
  }
};

export const groupReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_GROUP_DETAIL_SUCCESS: {
      let { data } = action.payload;
      return {
        ...state,
        groupSelected: data,
        membersGroup: {
          ...state.membersGroup,
          members: []
        },
        friendsGroup: {
          ...state.friendsGroup,
          friends: []
        },
        adminsGroup: {
          ...state.adminsGroup,
          admins: []
        },
        groupRelationShip: data.group_relationship,
        error: false,
        isLoading: false
      };
    }
    case types.GET_GROUP_ACCOUNT_SUCCESS: {
      let { data, type } = action.payload;
      let hasMoreMember = true;
      let hasMoreAdmin = true;
      let hasMoreFriend = true;
      let hasMoreModerator = true;

      if (data.length && type === 'admin') {
        hasMoreAdmin = true;
      } else if (data.length && type === 'member') {
        hasMoreMember = true;
      } else if (data.length && type === 'friend') {
        hasMoreFriend = true;
      } else if (data.length && type === 'moderator') {
        hasMoreModerator = true;
      } else {
        hasMoreFriend = false;
        hasMoreAdmin = false;
        hasMoreMember = false;
        hasMoreModerator = false;
      }

      switch (type) {
        case 'member': {
          return {
            ...state,
            membersGroup: {
              ...state.membersGroup,
              members: uniqBy(
                [...state.membersGroup.members, ...data],
                'account.id'
              ),
              loaded: hasMoreMember
            }
          };
        }

        case 'friend': {
          return {
            ...state,
            friendsGroup: {
              ...state.friendsGroup,
              friends: uniqBy(
                [...state.friendsGroup.friends, ...data],
                'account.id'
              ),
              loaded: hasMoreFriend
            }
          };
        }

        case 'admin': {
          return {
            ...state,
            adminsGroup: {
              ...state.adminsGroup,
              admins: uniqBy(
                [...state.adminsGroup.admins, ...data],
                'account.id'
              ),
              loaded: hasMoreModerator
            }
          };
        }

        case 'moderator': {
          return {
            ...state,
            moderatorsGroup: {
              ...state.moderatorsGroup,
              moderators: uniqBy(
                [...state.moderatorsGroup.moderators, ...data],
                'account.id'
              ),
              loaded: hasMoreAdmin
            }
          };
        }

        case ['admin', 'moderator']: {
          return {
            ...state,
            moderatorsGroup: {
              ...state.moderatorsGroup,
              moderators: uniqBy(
                [...state.moderatorsGroup.moderators, ...data],
                'account.id'
              ).filter(el => el.role === 'moderator'),
              loaded: hasMoreAdmin
            },
            adminsGroup: {
              ...state.adminsGroup,
              admins: uniqBy(
                [...state.adminsGroup.admins, ...data],
                'account.id'
              ).filter(el => el.role === 'admin'),
              loaded: hasMoreModerator
            }
          };
        }
        case 'removeAdmin': {
          return {
            ...state,
            adminsGroup: {
              ...state.adminsGroup,
              admins: data.filter(el => el.role === 'admin'),
              loaded: hasMoreAdmin
            }
          };
        }

        default:
          return { ...state };
      }
    }

    case types.DELETE_ACCOUNT_SUCCESS: {
      let { id, type } = action.payload;
      let listMembers: any = state.membersGroup.members;
      if (type === 'member') {
        let indexId = listMembers?.findIndex((item: any) => item.id === id);
        listMembers = [
          ...listMembers.slice(0, indexId),
          ...listMembers(indexId)
        ];
      }
      return {
        ...state,
        membersGroup: {
          ...state.membersGroup,
          members: listMembers
        }
      };
    }

    case types.GET_WAITING_POST_SUCCESS: {
      let { data } = action.payload;
      return {
        ...state,
        waitingPost: data
      };
    }

    case types.GET_WAITING_MEMBER_SUCCESS: {
      let { data } = action.payload;
      return {
        ...state,
        waitingMember: data
      };
    }

    case types.UPDATE_WAITTING_MEMBER: {
      let { data } = action.payload;
      return {
        ...state,
        waitingMember: data
      };
    }

    case types.GET_ACCOUNT_REMOVE_SUCCESS: {
      let { data } = action.payload;
      return {
        ...state,
        accountsRemove: data
      };
    }

    case types.GET_REPORT_POST_SUCCESS: {
      let { data } = action.payload;
      return {
        ...state,
        reportPosts: data.filter(post => post.status)
      };
    }

    case types.UPDATE_REPORT_POST: {
      let { postId } = action.payload;
      let newState = cloneDeep(state);
      return {
        ...newState,
        reportPosts: newState.reportPosts.filter(
          item => item?.status?.id !== postId
        )
      };
    }

    case types.GET_RULE_GROUP_SUCCESS: {
      let { data } = action.payload;
      return {
        ...state,
        ruleGroups: data
      };
    }

    case types.GET_MEMBER_QUESTION_SUCCESS: {
      let { data } = action.payload;
      return {
        ...state,
        memberQuestions: data
      };
    }

    case types.CREATE_MEMBER_QUESTION: {
      let { data } = action.payload;
      let newState = cloneDeep(state);
      return {
        ...newState,
        memberQuestions: [data, ...newState.memberQuestions]
      };
    }

    case types.UPDATE_MEMBER_QUESTION: {
      let { data } = action.payload;
      let newState = cloneDeep(state);

      let indexQuestion = newState.memberQuestions.findIndex(
        item => item.id === data.id
      );
      return {
        ...newState,
        memberQuestions: [
          ...newState.memberQuestions.slice(0, indexQuestion),
          data,
          ...newState.memberQuestions.slice(indexQuestion + 1)
        ]
      };
    }

    case types.RESET_MEMBER_QUESTION: {
      return {
        ...state,
        memberQuestions: []
      };
    }

    case types.GET_SCHEDULE_POST_SUCCESS: {
      let { data } = action.payload;
      return {
        ...state,
        schedulePosts: data,
        loadingSchedule: false
      };
    }

    case types.APPROVED_GROUP_SCHEDULE: {
      let { postId } = action.payload;
      const newState = cloneDeep(state);
      const listSchedulePost: any = newState.schedulePosts;

      return {
        ...newState,
        schedulePosts: listSchedulePost.filter(item => item.id !== postId)
      };
    }

    case types.CREATE_SCHEDULE_GROUP: {
      let { data } = action.payload;
      const newState = cloneDeep(state);
      return {
        ...newState,
        schedulePosts: [data.post, ...newState.schedulePosts]
      };
    }

    case types.UPDATE_SCHEDULE_GROUP: {
      let { data } = action.payload;
      let postId = data.id;
      const newState = cloneDeep(state);
      const listSchedulePost: any = newState.schedulePosts;
      let updateSchedulePost;

      if (listSchedulePost.length > 0) {
        updateSchedulePost = listSchedulePost.map(e => {
          if (e.status.id === postId) {
            return {
              ...e,
              status: {
                ...e.status,
                ...data,
                bookmarked: data.bookmarked
              }
            };
          } else {
            return e;
          }
        });
      }

      return {
        ...newState,
        schedulePosts: updateSchedulePost
      };
    }

    case types.CHANGE_SCHEDULE_GROUP: {
      let { data } = action.payload;
      let postId = data.id;
      const newState = cloneDeep(state);
      const listSchedulePost: any = newState.schedulePosts;
      let updateSchedulePost;

      if (listSchedulePost.length > 0) {
        updateSchedulePost = listSchedulePost.map(e => {
          if (e.id === postId) {
            return {
              ...e,
              scheduled_at: data.scheduled_at,
              status: {
                ...e.status,
                ...data.status
              }
            };
          } else {
            return e;
          }
        });
      }

      return {
        ...newState,
        schedulePosts: updateSchedulePost
      };
    }

    case types.GROUP_RELATIONSHIP: {
      let { data } = action.payload;
      return {
        ...state,
        groupRelationShip: data[0]
      };
    }

    case types.UPDATE_GROUP_RELATIONSHIP: {
      let { type } = action.payload;
      return {
        ...state,
        groupRelationShip: {
          ...state.groupRelationShip,
          invited: false,
          follow: type === 'rejected' ? false : true,
          member: type === 'rejected' ? false : true
        }
      };
    }

    case types.GET_CENSORSHIP_SUCCESS: {
      let { data } = action.payload;
      return {
        ...state,
        censorShipReports: data
      };
    }

    case types.GET_ACTIVITY_LOG_SUCCESS: {
      let { data } = action.payload;
      let newState: any = { ...state };
      if (data.length) {
        newState.activities = [...data];
      } else {
        newState.loadActivities = false;
        newState.activities = [...data];
      }
      return newState;
    }

    case types.NOTE_ACTIVITY_LOG_SUCCESS: {
      let { data } = action.payload;
      let index = state.activities.findIndex((el: any) => el.id === data.id);
      return {
        ...state,
        activities: [
          ...state.activities.slice(0, index),
          data,
          ...state.activities.slice(index + 1)
        ]
      };
    }

    case types.GROUP_PREVIEW: {
      let { data } = action.payload;
      return {
        ...state,
        groupPreview: {
          ...data,
          member_count: 1
        }
      };
    }

    case types.GET_INVITATION_SUCCESS: {
      let { data, role } = action.payload;
      switch (role) {
        case 'admin': {
          return {
            ...state,
            invitations: {
              ...state.invitations,
              invitationsAdmin: uniqBy(data, 'target_account.id')
            }
          };
        }
        case 'moderator': {
          return {
            ...state,
            invitations: {
              ...state.invitations,
              invitationsModerator: uniqBy(data, 'target_account.id')
            }
          };
        }
      }

      return {
        ...state
      };
    }

    case types.GET_GROUP_DETAIL_ERROR: {
      return { ...state, error: true };
    }

    case types.RESET_GROUP: {
      return initialState;
    }

    case types.GROUP_SEARCH:
      return {
        ...state,
        groupSearch: action.payload
      };

    case types.UPDATE_GROUP_SEARCH: {
      let { data } = action.payload;
      let newState = _.cloneDeep(state);
      let updateData;
      if (newState?.groupSearch?.data?.length) {
        updateData = newState.groupSearch.data.map(item => {
          if (item.id === data.id) {
            return {
              ...item,
              group_relationship: {
                ...item.group_relationship,
                requested: data.requested,
                follow: data.follow
              }
            };
          } else {
            return item;
          }
        });
      }
      return {
        ...newState,
        groupSearch: {
          ...newState.groupSearch,
          data: updateData
        }
      };
    }

    case types.IS_LOADING_GROUP_MEMBER:
      return {
        ...state,
        isLoadingMember: action.payload.value
      };

    case types.DELETE_MEMBER_SUCCESS: {
      const { idMember } = action.payload;
      const { membersGroup, friendsGroup } = state;

      const filteredMembers = membersGroup.members.filter(
        (member: any) => member?.account?.id !== idMember
      );

      const filteredFriends = friendsGroup.friends.filter(
        (member: any) => member?.account?.id !== idMember
      );

      return {
        ...state,
        membersGroup: {
          ...membersGroup,
          members: filteredMembers
        },
        friendsGroup: {
          ...friendsGroup,
          friends: filteredFriends
        }
      };
    }
    case types.GET_SEARCH_POST_IN_GROUP_ALL: {
      return {
        ...state,
        statuses: action.payload
      };
    }
    case types.LOADING_ACTIVITY_LOG: {
      const { boolean } = action.payload;
      return {
        ...state,
        loadingActivityLog: boolean
      };
    }
    case types.LOADING_SEARCH_GROUP: {
      const type = action.payload;
      return {
        ...state,
        loadingSearchGroup: type === 'loading' ? true : false
      };
    }
    case types.GET_ACTIVITIES_USER_IN_GROUP_SUCCESS: {
      const {
        data: { listStatus, hasMore, isLoading }
      } = action.payload;
      return {
        ...state,
        activitiesUserInGroup: {
          listStatus: [
            ...state.activitiesUserInGroup.listStatus,
            ...listStatus
          ],
          hasMore: hasMore,
          isLoading: isLoading
        }
      };
    }
    case types.CHANGE_ISLOADING_ACTIVITIES_LOG: {
      return {
        ...state,
        activitiesUserInGroup: {
          ...state.activitiesUserInGroup,
          isLoading: action.payload
        }
      };
    }
    default:
      return { ...state };
  }
};
