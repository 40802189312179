import * as React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import ProfileHeader from './ProfileHeader/index';
import { useSelector } from 'react-redux';
import ProfileBody from './ProfileBody/index';
import { useHistory, useRouteMatch } from 'react-router-dom';
import logo from 'src/assets/images/LogoEmso.svg';
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#F1F2F5',
        position: 'relative'
      },
      profileBody: {
        maxWidth: 1200,
        width: '95%',
        height: '100%'
      },
      rootBox: {
        display: 'flex',
        backgroundColor: '#F1F2F5'
      },
      styleLogo: {
        cursor: 'pointer',
        width: '45px',
        height: 'auto',
        marginLeft: '10px',
        borderRadius: '50%'
      },
      styleBoxFixed: {
        position: 'fixed',
        top: '20px',
        left: '15px',
        display: 'flex'
      }
    }),
  { index: 1 }
);
const GroupActivitiesLogUser = ({ groupSelected, groupRelationShip }) => {
  const classes = useStyles();
  const [keyTab, setKeyTab] = React.useState({ id: 'postInGroup' });
  const history = useHistory();
  const listLifeEvent = useSelector(
    (state: any) => state?.userReducer.listLifeEvents
  );
  const match: any = useRouteMatch();
  React.useEffect(() => {
    if (match.params.tab) {
      setKeyTab({ id: match.params.tab });
    }
  }, []);
  return (
    <React.Fragment>
      <div className={classes.root}>
        <ProfileHeader
          keyTab={keyTab}
          setKeyTab={setKeyTab}
          setAboutUser={() => {}}
          aboutUser={{}}
          lifeEvents={listLifeEvent}
          groupSelected={groupSelected}
          groupRelationShip={groupRelationShip}
        />
        <div className={classes.profileBody}>
          <ProfileBody keyTab={keyTab} setKeyTab={setKeyTab} />
        </div>
      </div>
      <div className={classes.styleBoxFixed}>
        <i
          className="fa-solid fa-arrow-left"
          onClick={() => {
            history.push(`/group/${match.params.groupId}`);
          }}
          style={{
            padding: '15px',
            borderRadius: '50%',
            backgroundColor: '#CBCED1',
            cursor: 'pointer'
          }}
        ></i>
        <img
          onClick={() => {
            history.push('/');
          }}
          className={classes.styleLogo}
          alt="Emso"
          src={logo}
        />
      </div>
    </React.Fragment>
  );
};

export default GroupActivitiesLogUser;
