import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from 'src/store/action/socialReportAction';
import * as types from 'src/constants/store/report';
import { getListCategoriesReport } from 'src/apis/socialUser';
const typesSetting: any = types;

export const getListReportSagas = function* () {
  try {
    let response = yield call(getListCategoriesReport);
    if (response.status === 200) {
      yield put(actions.getListReport(response.data));
    }
  } catch (error: any) {}
};

export function* watchSocialReportAsync() {
  yield takeEvery(typesSetting.GET_LIST_REPORT_REQ, getListReportSagas);
}
