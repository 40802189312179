import moment from 'moment';

let dayOfWeekArray: any = [
  { eng: 'Mon', vi: 'Thứ hai' },
  { eng: 'Tue', vi: 'Thứ ba' },
  { eng: 'Wed', vi: 'Thứ tư' },
  { eng: 'Thu', vi: 'Thứ năm' },
  { eng: 'Fri', vi: 'Thứ sáu' },
  { eng: 'Sat', vi: 'Thứ bảy' },
  { eng: 'Sun', vi: 'Chủ nhật' }
];
let monOfYear: any = [
  { eng: 'Jan', num: 1 },
  { eng: 'Feb', num: 2 },
  { eng: 'Mar', num: 3 },
  { eng: 'Apr', num: 4 },
  { eng: 'May', num: 5 },
  { eng: 'Jun', num: 6 },
  { eng: 'Jul', num: 7 },
  { eng: 'Aug', num: 8 },
  { eng: 'Sep', num: 9 },
  { eng: 'Oct', num: 10 },
  { eng: 'Nov', num: 11 },
  { eng: 'Dec', num: 12 }
];
const days = [
  'CHỦ NHẬT',
  'THỨ HAI',
  'THỨ BA',
  'THỨ TƯ',
  'THỨ NĂM',
  'THỨ SÁU',
  'THỨ BẢY'
];
const months = [
  'THÁNG 1',
  'THÁNG 2',
  'THÁNG 3',
  'THÁNG 4',
  'THÁNG 5',
  'THÁNG 6',
  'THÁNG 7',
  'THÁNG 8',
  'THÁNG 9',
  'THÁNG 10',
  'THÁNG 11',
  'THÁNG 12'
];

export const RegText = /^[^!@#$%^&*()?":{}|<>\/'\\;[\]=+_\-]*$/;

export const upCaseFirstString = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const formatTimeToUTC = (value: string) => {
  try {
    let time = moment(value).toISOString();
    let strTime = time.slice(0, -5);
    let timeFormat = strTime + 'Z';
    return timeFormat;
  } catch (error) {
    return '';
  }
};
export const formatNumberVND = (price: number): any => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND'
  }).format(price);
};
export const collapseString = (text: string, value: number) => {
  let strCollapse = text;
  if (text?.length > value) {
    strCollapse = text.slice(0, value) + '...';
  }
  return strCollapse;
};

export const formatDateTimeToDatePicker = (values: any) => {
  var d = new Date(values),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};
export const formatDateTimeToDate = (values: any) => {
  var d = new Date(values),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return `${day} Tháng ${month}, ${year}`;
};
export const formatDateTimeToStringDateTime = (values: any, addTime: any) => {
  if (values) {
    var d = new Date(values),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    var time: any = values.toString().slice(11, 16);

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${
      addTime === true ? time + ', ' : ''
    }${day} tháng ${month}, ${year}`;
  }
};

export const formatDateTimeToStringDateTimeYear = (values: any) => {
  var d = new Date(values),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return `${day} tháng ${month}`;
};

export function convertISO8086toLDate(dateISO8086) {
  return moment(dateISO8086).format('DD-MM-YYYY');
}

export function convertISO8086toLTime(dateISO8086) {
  return moment(dateISO8086).format('HH:mm');
}

export function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

export function convertISO8601toStringDay(ISOTime: any) {
  let dayWeek: any = dayOfWeekArray.find(
    (el: any) => el.eng === ISOTime?.toString()?.slice(0, 3)
  )?.vi;

  var d = new Date(ISOTime),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (dayWeek) {
    return `${dayWeek}, ${day} tháng ${month}, ${year}`;
  } else {
    return ` ${day} tháng ${month}, ${year}`;
  }
}
export function convertISO8601toStringOnlyDay(ISOTime: any) {
  var d = new Date(ISOTime),
    day = '' + d.getDate();

  return `${day} `;
}
//Fri Sep 30 2022 06:30:00 GMT+0700 (Giờ Đông Dương) to Thứ 5, 14 tháng 5, 2022
export function convertISO8601RegionToStringDay(date: any) {
  let dayWeek: any = dayOfWeekArray.find(
    (el: any) => el.eng === date.slice(0, 3)
  )?.vi;
  let day: any = date.slice(8, 10);
  let month: any = monOfYear.find(
    (el: any) => el.eng === date.slice(4, 7)
  )?.num;
  let year: any = date.slice(11, 15);
  return `${dayWeek}, ${day}/${month}/${year}`;
}
//2022-09-30T06:30:00.000+07:00 to 30/09/2022
export function convertISO8601StandardToStringDay(date: any) {
  let dayWeek: any = dayOfWeekArray.find(
    (el: any) => el.eng === date.slice(0, 3)
  )?.vi;
  let day: any = date.slice(8, 10);
  let month: any = monOfYear.find(
    (el: any) => el.eng === date.slice(4, 7)
  )?.num;
  let year: any = date.slice(11, 15);
  return `${dayWeek}, ${day}/${month}/${year}`;
}

export function convertISO8601StringDayToDate(date: any) {
  let hours: any = Number(date.slice(11, 13)) + 7;
  let minutes: string = date.slice(14, 16);
  let day: string = date.slice(8, 10);
  let month: string = date.slice(5, 7);
  let year: string = date.slice(0, 4);
  const dateObject: any = moment(date);
  if (dateObject.isSame(moment(), 'day')) {
    return `${hours > 12 ? hours - 12 : hours} : ${minutes} ${
      hours > 12 ? 'PM' : 'AM'
    } `;
  }
  if (dateObject.isSame(moment(), 'year')) {
    return `${day} Tháng ${month}`;
  }
  return `${day} Tháng ${month}, ${year}`;
}

export function convertISOToFormattedDate(isoString) {
  var date = new Date(isoString);

  var hours = date.getHours();
  var minutes = date.getMinutes().toString().padStart(2, '0');
  var day = date.getDate().toString().padStart(2, '0');
  var month = (date.getMonth() + 1).toString().padStart(2, '0');
  var year = date.getFullYear();

  var formattedDate =
    hours + ':' + minutes + ' ' + day + '/' + month + '/' + year;

  return formattedDate;
}

// 2023-10-17T12:00:00.000+07:00 => "THỨ BA, 17 THÁNG 10, 2023 VÀO 12:00"
export function formatDateTimeDetailCampaign(inputDateTime) {
  const date = new Date(inputDateTime);
  const dayOfWeek = days[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${dayOfWeek}, ${dayOfMonth} ${month}, ${year} VÀO ${hours
    .toString()
    .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

// futureTime: "2023-10-19T20:57:00.000+07:00" | "2023-10-19 20:57"
// Nhận vào thời gian futureTime => số ngày và giờ từ hiện tại đến futureTime
export function formatTimeDifference(futureTime) {
  if (!moment(futureTime, moment.ISO_8601, true).isValid()) {
    return 'Thời gian không hợp lệ';
  }

  const currentTime = moment();
  const future = moment(futureTime);
  const duration = moment.duration(future.diff(currentTime));

  const days = Math.floor(duration.asDays());
  const hours = duration.hours();

  return `${days} ngày ${hours} giờ`;
}
