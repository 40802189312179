export const GET_LIST_MUSIC = 'GET_LIST_MUSIC';
export const ACTION_MUSIC = 'ACTION_MUSIC';
export const ACTION_ADD_MUSIC = 'ACTION_ADD_MUSIC';
export const ACTION_OPEN_NOTI = 'ACTION_OPEN_NOTI';
export const DETAIL_ALBUM = 'DETAIL_ALBUM';
export const UPDATE_ALBUM_MUSICSPACE = 'UPDATE_ALBUM_MUSICSPACE';
export const RESET_ALBUM_MUSICSPACE = 'RESET_ALBUM_MUSICSPACE';
export const GET_TOP_MUSIC = 'GET_TOP_MUSIC';
export const SHOW_MUSIC_PLAYER = 'SHOW_MUSIC_PLAYER';
export const VOLUME_MUSIC = 'VOLUME_MUSIC';
export const CHECK_PATH_MUSIC = 'CHECK_PATH_MUSIC';
export const GET_DATA_PLAYLIST = 'GET_DATA_PLAYLIST';
export const SHOW_MINI_AUDIO = 'SHOW_MINI_AUDIO';
export const ACTIVE_MUSIC = 'ACTIVE_MUSIC';
export const RANDOM_MUSIC = 'RANDOM_MUSIC';
export const RESET_MUSIC_DATA = 'RESET_MUSIC_DATA';
