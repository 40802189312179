import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  TextField,
  Typography
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  addUserSearchHistory,
  deleteUserSearchHistory,
  searchUserGlobal,
  userSearchHistory
} from 'src/apis/socialSearch.api';
import logo from 'src/assets/images/LogoEmso.svg';
import clock from 'src/assets/images/clock.png';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import Loading from 'src/components/Loading/Loading';
import { PATHS } from 'src/constants/paths';
import { buttonColor } from 'src/constants/styles';
import {
  keywordSearchGlobalReq,
  setAnchorElDialogGlobalSearch
} from 'src/store/action/socialSearchGlobalAction';
const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      root: {
        display: 'flex'
      },
      inputSearch: {
        borderRadius: '40px !important',
        '&:hover': {
          cursor: 'text'
        }
      },
      cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
          borderColor: theme.palette?.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        '& fieldset': {
          display: 'none'
        },
        '&:hover': {
          backgroundColor:
            theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C',
        borderRadius: '40px !important',
        width: '100%'
      },
      noneBoxShadow: {
        boxShadow:
          theme.palette.mode === 'dark'
            ? '0px 6px 5px #3a3a3a !important'
            : '0px 8px 5px #e6e6e6 !important'
      },
      notchedOutline: {
        border: 'none !important',
        borderRadius: '40px'
      },
      multilineColor: {
        fontSize: 13
      },
      searchIcon: {
        marginRight: '10px',
        marginLeft: '-5px',
        opacity: '0.5',
        cursor: 'pointer',
        fontSize: '21px !important'
      },
      noOptionsText: {
        fontSize: '15px',
        margin: '5px',
        display: 'flex',
        justifyContent: 'center',
        color: '#8a8d91'
      },
      paper: {
        width: 400,
        height: 600,
        backgroundColor: '#d5d5d5'
      },
      truncateText: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflowWrap: 'break-word',
        overflow: 'hidden'
      },
      focusItemStyle: {
        backgroundColor: theme.palette.button.primary.hover,
        width: '100%',
        borderRadius: '8px',
        marginTop: '8px',
        color: `${theme.palette.text.primary} !important`,
        marginLeft: '-8px'
      },
      unFocusItemStyle: {
        color: `${theme.palette.text.primary} !important`,
        width: '100%',
        borderRadius: '8px',
        '&:hover': {
          backgroundColor: theme.palette.button.primary.hover,
          cursor: 'pointer'
        },
        marginTop: '8px',
        marginLeft: '-8px'
      },
      boxSearch: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '10px'
      }
    }),
  { index: 1 }
);

interface PropType {
  handleLinkToHome: Function;
  matches: Boolean;
}

function LogoAndSearch(props: PropType) {
  const classes = useStyles(props);
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const [focusItem, setFocusItem] = React.useState(-1);
  const [focusData, setFocusData] = React.useState<any>([]);

  const keyword = useSelector(
    (state: any) => state.searchGlobalReducer.keyword
  );
  const { handleLinkToHome } = props;
  const [optionSearch, setOptionSearch] = React.useState<any>([]);
  const [listSearchHistory, setListSearchHistory] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const anchorElDialogSearch = useSelector(
    (state: any) => state.searchGlobalReducer.anchorElDialogSearch
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setAnchorElDialogGlobalSearch(event.currentTarget));
  };

  const handleClose = () => {
    dispatch(setAnchorElDialogGlobalSearch(null));
    setFocusData([]);
  };

  const open = Boolean(anchorElDialogSearch);
  const id = open ? 'simple-popover' : undefined;

  const debounceSearchGlobal = useCallback(
    _.debounce(async function (q) {
      let response = await searchUserGlobal({
        type: ['accounts', 'pages', 'groups'],
        q,
        limit: 3
      });

      if (response.status === 200) {
        let accounts: any = response.data.accounts?.map((el: any) => ({
          ...el,
          path: `/user/${el?.username || el?.id}`
        }));
        let pages: any = response.data.pages?.map((el: any) => {
          const isAdminPageSeller =
            el?.page_purpose === 'sell_products' &&
            el?.page_relationship?.role === 'admin';

          return {
            ...el,
            path: isAdminPageSeller
              ? `/marketplace/shop/home?page_id=${el?.id}`
              : `/page/${el?.id}`
          };
        });
        let groups: any = response.data.groups?.map((el: any) => ({
          ...el,
          path: `/group/${el?.id}`
        }));
        if (
          accounts.length === 0 &&
          pages.length === 0 &&
          groups.length === 0
        ) {
          setOptionSearch([]);
        } else {
          let optionNewSearch: any = [...accounts, ...pages, ...groups];
          setOptionSearch(
            optionNewSearch.map((el: any, index: any) => ({
              ...el,
              id_index: index
            }))
          );
        }
      }
    }, 400),
    []
  );

  const fetchUserSearchHistory = async () => {
    let response;
    response = await userSearchHistory({
      page: 1,
      limit: 6,
      global: true
    });
    if (response.status === 200) {
      setListSearchHistory(response.data);
      setIsLoading(false);
    }
  };

  const handleAddUserSearchHistory = async params => {
    let response = await addUserSearchHistory(params);
    if (response.status === 200) {
      fetchUserSearchHistory();
    }
  };

  const handleSearch = keyword => {
    debounceSearchGlobal(keyword);
  };

  const handleDelete = async id => {
    let response = await deleteUserSearchHistory(id);
    if (response.status === 200) fetchUserSearchHistory();
  };

  const handleOnKeyDown = (event, options) => {
    let nextItemCount = 0;
    if (event.keyCode === 40) {
      nextItemCount = (focusItem + 1) % options?.length;
      setFocusData(options[nextItemCount]);
    }
    if (event.keyCode === 38) {
      nextItemCount = (focusItem + options?.length - 1) % options?.length;
      setFocusData(options[nextItemCount]);
    }
    if (event.keyCode === 8) {
      setFocusItem(-1);
      setFocusData([]);
    }
    setFocusItem(nextItemCount);
  };
  function checkHashtagsStr(str: any) {
    const regex = /#(\S+)/g;
    return regex.test(str);
  }

  React.useEffect(() => {
    if (anchorElDialogSearch) {
      fetchUserSearchHistory();
    }
  }, [anchorElDialogSearch]);

  React.useEffect(() => {
    const handlePopState = event => {
      setOptionSearch([]);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  React.useEffect(() => {
    if (!keyword.length) {
      setOptionSearch([]);
    }
  }, [keyword]);

  return (
    <div
      className={classes.root}
      style={{
        width: '296px',
        justifyContent: 'space-between'
      }}
      onKeyDown={e => {
        optionSearch && optionSearch.length > 0
          ? handleOnKeyDown(e, optionSearch)
          : handleOnKeyDown(e, listSearchHistory);
      }}
      onBlur={() => {
        setFocusData([]);
        setFocusItem(-1);
      }}
    >
      {!open && (
        <div className={classes.root} style={{ width: '100%' }}>
          <Avatar
            style={{
              cursor: 'pointer'
            }}
            onClick={() => handleLinkToHome()}
            alt="logo"
            src={logo}
          />

          <TextField
            classes={{ root: classes.inputSearch }}
            size="small"
            variant="outlined"
            placeholder="Tìm kiếm trên EMSO"
            id="button_global_search"
            value={keyword}
            onClick={(e: any) => {
              handleClick(e);
            }}
            InputProps={{
              startAdornment: (
                <SearchIcon classes={{ root: classes.searchIcon }} />
              ),
              classes: {
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline
              },
              className: classes.multilineColor
            }}
            sx={{ marginLeft: '10px' }}
          ></TextField>
        </div>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorElDialogSearch}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: '-16px 0px 0px -16px',
          '&.MuiPopover-root': {
            boxShadow: 'none'
          },
          '&.MuiPopover-paper': {
            boxShadow: 'none'
          },
          '&.MuiPaper-root': {
            backgroundColor: '#905151',
            boxShadow: 'none',
            '&.MuiPopover-paper': {
              boxShadow: 'none !important'
            }
          }
        }}
        classes={{
          paper: classes.noneBoxShadow
        }}
      >
        <div
          style={{
            width: '328px',
            display: 'flex',
            flexDirection: 'column',
            padding: '4px 3px 12px 10px'
          }}
        >
          <div
            style={{
              display: 'flex',
              marginBottom: '8px',
              alignItems: 'center'
            }}
          >
            <IconButtonOptions
              style={{
                width: '36px',
                height: '36px',
                margin: '0px 10px 0px 0px',
                borderRadius: '20px !important',
                backgroundColor: 'transparent'
              }}
              startIcon="fal fa-arrow-left"
              startIconStyle={{
                fontSize: 17,
                fontWeight: 600,
                color: '#4e4e4e'
              }}
              action={handleClose}
            />
            <TextField
              autoFocus
              classes={{ root: classes.inputSearch }}
              size="small"
              variant="outlined"
              placeholder="Tìm kiếm trên EMSO "
              onChange={(event: any) => {
                handleSearch(event.target.value);
                dispatch(keywordSearchGlobalReq(event.target.value));
                setFocusItem(-1);
                setFocusData([]);
              }}
              value={keyword ? keyword : focusData?.keyword}
              sx={{ width: '100%', borderRadius: '40px', marginRight: '12px' }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  notchedOutline: classes.notchedOutline
                },
                className: classes.multilineColor,
                onKeyDown: e => {
                  if (e.key === 'Enter') {
                    if (checkHashtagsStr(keyword)) {
                      history.push(`/hashtag/${keyword.slice(1)}`);
                    } else if (e.currentTarget.value === '') {
                      handleClose();
                    } else if (focusData?.keyword) {
                      handleClose();
                      focusData?.entity_id
                        ? history.push(
                            `/${
                              focusData?.entity_type === 'Account'
                                ? 'user'
                                : focusData?.entity_type === 'Page'
                                ? 'page'
                                : 'group'
                            }/${focusData?.username || focusData?.entity_id}`
                          )
                        : history.push(`/search/all?q=${focusData?.keyword}`);
                    } else if (focusData?.id) {
                      handleClose();
                      setOptionSearch([]);
                      history.push(
                        `/${focusData?.path}/${
                          focusData?.path === 'page' ||
                          focusData?.path === 'user'
                            ? focusData?.username
                              ? focusData?.username
                              : focusData?.id
                            : focusData?.id
                        }`
                      );
                    } else {
                      handleAddUserSearchHistory({ keyword });
                      dispatch(setAnchorElDialogGlobalSearch(null));
                      if (match.path !== PATHS.SEARCH_GLOBAL) {
                        history.push(
                          `/search/all?q=${encodeURIComponent(keyword)}`
                        );
                      } else {
                        history.push(`?q=${encodeURIComponent(keyword)}`);
                      }
                    }
                  }
                }
              }}
            ></TextField>
          </div>
          {keyword === '' && !optionSearch?.length ? (
            isLoading ? (
              <Loading />
            ) : listSearchHistory?.length ? (
              <>
                <div className={classes.boxSearch}>
                  <Typography
                    fontSize="17px"
                    fontWeight="600"
                    sx={{ paddingLeft: '10px' }}
                  >
                    Tìm kiếm gần đây
                  </Typography>
                </div>
                {listSearchHistory?.map((el: any, index) => {
                  return (
                    <Link
                      key={index}
                      onClick={() => {
                        handleClose();
                        if (el.entity_id) {
                          return history.push(
                            `/${
                              el.entity_type === 'Account'
                                ? 'user'
                                : el.entity_type === 'Page'
                                ? 'page'
                                : 'group'
                            }/${el.username || el.entity_id}`
                          );
                        } else {
                          return history.push(`/search/all?q=${el.keyword}`);
                        }
                      }}
                      underline="none"
                      className={
                        index === focusItem
                          ? classes.focusItemStyle
                          : classes.unFocusItemStyle
                      }
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <ListItem
                          sx={{ padding: '8px 8px' }}
                          secondaryAction={
                            <IconButton
                              onClick={e => {
                                handleDelete(el.id);
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                              sx={{
                                color: 'button.primary',
                                width: '30px',
                                height: '30px'
                              }}
                            >
                              <CloseIcon
                                style={{ width: '16px', height: '16px' }}
                              />
                            </IconButton>
                          }
                        >
                          <ListItemAvatar>
                            {el.entity_id ? (
                              <Avatar
                                alt="Search"
                                src={el.image_url}
                                sx={{
                                  width: 36,
                                  height: 36
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  borderRadius: '50%',
                                  width: '36px',
                                  height: '36px',
                                  backgroundColor: '#f0f2f5',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <Avatar
                                  alt="Search"
                                  src={clock}
                                  sx={{
                                    width: 20,
                                    height: 20,
                                    opacity: '70%'
                                  }}
                                />
                              </div>
                            )}
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography sx={{ width: '86%' }}>
                                {el.name ?? el.keyword}
                              </Typography>
                            }
                            className={classes.truncateText}
                          />
                        </ListItem>
                      </div>
                    </Link>
                  );
                })}
              </>
            ) : (
              <Typography
                sx={{
                  fontSize: 15,
                  fontWeight: 400,
                  color: '#8a8d91',
                  textAlign: 'center',
                  margin: '5px 0px'
                }}
              >
                Không có tìm kiếm nào gần đây
              </Typography>
            )
          ) : (
            optionSearch?.map((el: any, index: any) => (
              <Link
                key={index}
                onClick={() => {
                  handleClose();
                  setOptionSearch([]);
                  history.push(el.path);
                }}
                underline="none"
                className={
                  index === focusItem
                    ? classes.focusItemStyle
                    : classes.unFocusItemStyle
                }
              >
                <Box
                  sx={{ width: '360px', display: 'flex' }}
                  onClick={() => {
                    handleAddUserSearchHistory({
                      keyword: el.display_name ?? el.title,
                      entity_type: el.display_name
                        ? 'Account'
                        : el.page_categories
                        ? 'Page'
                        : 'Group',
                      entity_id: el.id
                    });
                  }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        alt="search"
                        sx={{
                          height: 36,
                          width: 36
                        }}
                        src={
                          el?.path === 'group'
                            ? el?.banner?.show_url
                            : el?.avatar_media?.show_url ??
                              el?.avatar_media?.preview_url
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{ width: '84%' }}
                          className={classes.truncateText}
                        >
                          {el.display_name || el.title}
                        </Typography>
                      }
                      secondary={
                        <Typography sx={{ fontSize: 13 }}>
                          {el?.relationships?.friendship_status ===
                          'ARE_FRIENDS'
                            ? 'Bạn bè'
                            : null}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Box>
              </Link>
            ))
          )}

          {keyword !== '' && (
            <Link
              onClick={() => {
                handleAddUserSearchHistory({ keyword });
                handleClose();
                history.push(`/search/all?q=${keyword}`);
              }}
              underline="none"
              sx={{
                color: buttonColor.backgroundColor,
                width: '360px',
                marginLeft: '-10px',
                '&:hover': {
                  backgroundColor: 'button.primary.background',
                  cursor: 'pointer'
                },
                borderRadius: '8px',
                padding: '8px'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <i
                  className="fa-light fa-magnifying-glass"
                  style={{
                    backgroundColor: buttonColor.backgroundColor,
                    color: '#fff',
                    fontWeight: 500,
                    fontSize: 16,
                    borderRadius: '50%',
                    marginRight: '10px',
                    marginLeft: '10px',
                    padding: '10px'
                  }}
                ></i>

                <Typography sx={{ fontSize: 15, overflowWrap: 'break-word' }}>
                  Tìm kiếm <span style={{ fontWeight: 600 }}>{keyword}</span>
                </Typography>
              </div>
            </Link>
          )}
          {keyword !== '' &&
            (optionSearch === null ? (
              <span
                style={{
                  textAlign: 'center',
                  marginTop: '5px',
                  fontWeight: 500,
                  color: '#676767',
                  fontSize: 14
                }}
              >
                Không có kết quả nào
              </span>
            ) : (
              !optionSearch.length && (
                <div style={{ textAlign: 'center', marginTop: '5px' }}>
                  <CircularProgress sx={{ color: '#b4b4b4' }} size="25px" />
                </div>
              )
            ))}
        </div>
      </Popover>
    </div>
  );
}

LogoAndSearch.propTypes = {};

export default LogoAndSearch;
