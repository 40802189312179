import React, { useEffect } from 'react';
import { getNotificationApi } from 'src/apis/commonSocialApi';
import { postReadNotiApi } from 'src/apis/socialNoti';
import { makeStyles } from '@mui/styles';

import { Typography, Theme, Box, Skeleton, Avatar } from '@mui/material';
import ButtonCustom from 'src/components/Button/Button';
import CommonNoti from 'src/Notification/commonNoti';
import InfiniteScroll from 'react-infinite-scroll-component';
import { buttonColor, scrollStyle } from 'src/constants/styles';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 'auto',
    borderRadius: 10,
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
    borderTop: '1px solid rgba(0,0,0,0.10)',
    width: 700,
    margin: '0 auto',
    marginTop: '20px',
    padding: '12px',
    ...scrollStyle
  },
  rootNoti: {
    display: 'flex',
    maxWidth: '100%',
    flexWrap: 'wrap'
  },
  listItem: {
    padding: '6px !important',
    alignItems: 'flex-start !important',
    '&:hover': {
      borderRadius: '10px !important'
    }
  },
  text: {
    fontSize: '15px',
    color: '#050505',
    fontWeight: '500 !important'
  },
  subText: {
    color: '#65676b',
    fontSize: '13px !important'
  },
  avatar: {
    width: '28px !important',
    height: '28px !important',
    position: 'absolute',
    top: 33,
    right: 2
  },
  titleNoti: {
    fontWeight: '600 !important',
    paddingLeft: '12px',
    paddingTop: '10px',
    paddingBottom: '5px'
  }
}));

function ShowNotifications() {
  const classes = useStyles();
  const [notifications, setNotifications] = React.useState<any>([]);
  const [newNotis, setNewNotis] = React.useState<any>([]);
  const [oldNotis, setOldNotis] = React.useState<any>([]);
  const [allNoti, setAllNoti] = React.useState<any>(true);
  const currentTime = new Date().getHours() * 60 + new Date().getMinutes();
  const currentDay = new Date().getDay();
  const [hasMore, setHasMore] = React.useState(true);
  const loaderRef: any = React.useRef();

  const funcLoad = () => {
    let postLast = [...notifications].pop();
    let maxId = postLast?.id;
    fetchNotification({ max_id: maxId, limit: 20 });
  };

  const fetchNotification = async params => {
    let response = await getNotificationApi(params);
    if (response.status === 200) {
      if (response.data.length) {
        setNotifications(prev => [...prev, ...response.data]);
      } else {
        setHasMore(false);
      }
    }
  };

  useEffect(() => {
    funcLoad();
  }, []);

  useEffect(() => {
    setNewNotis(
      notifications?.filter(
        (el: any) =>
          currentTime -
            (new Date(el?.created_at).getHours() * 60 +
              new Date(el?.created_at).getMinutes()) <
            90 &&
          currentDay - new Date(el?.created_at).getDay() === 0 &&
          el
      )
    );
    setOldNotis(
      notifications?.filter(
        (el: any) =>
          currentTime -
            (new Date(el?.created_at).getHours() * 60 +
              new Date(el?.created_at).getMinutes()) >
            90 ||
          (currentDay - new Date(el?.created_at).getDay() > 0 && el)
      )
    );
  }, [JSON.stringify(notifications)]);
  const handleReadNoti = async (id: any) => {
    try {
      let res = await postReadNotiApi(id);
      if (res.status === 200) {
      }
    } catch (error) {}
  };
  const loaderSkeleton = () =>
    Array.from(Array(2).keys()).map(item => (
      <Box
        sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
        ref={loaderRef}
      >
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>
        <Skeleton width="100%" style={{ marginLeft: '12px' }}>
          <Typography>.</Typography>
        </Skeleton>
      </Box>
    ));
  return (
    <Box
      id="scrollableShowNotification"
      className={classes.root}
      sx={{ backgroundColor: 'background.primary' }}
    >
      <Typography
        style={{ fontSize: 24, fontWeight: 700, padding: '0px 8px 8px 8px' }}
      >
        Thông báo
      </Typography>
      <InfiniteScroll
        dataLength={notifications.length}
        next={funcLoad}
        hasMore={hasMore}
        loader={loaderSkeleton()}
        style={{ overflow: 'hidden' }}
        scrollThreshold={0.5}
      >
        <div>
          <div className={classes.rootNoti}>
            <ButtonCustom
              style={{
                margin: '3px 5px',
                maxWidth: '60px',
                borderRadius: '20px',
                fontSize: '15px',
                backgroundColor: allNoti ? '#e7f3ff' : 'background.secondary',
                color: allNoti ? buttonColor.backgroundColor : 'text.primary'
              }}
              label="Tất cả"
              action={() => setAllNoti(true)}
            ></ButtonCustom>
            <ButtonCustom
              style={{
                margin: '3px 5px',
                maxWidth: '80px',
                borderRadius: '20px',
                fontSize: '15px',
                backgroundColor: !allNoti ? '#e7f3ff' : 'background.secondary',
                color: !allNoti ? buttonColor.backgroundColor : 'text.primary'
              }}
              label="Chưa đọc"
              action={() => setAllNoti(false)}
            ></ButtonCustom>
          </div>
          {newNotis?.length > 0 ? (
            <div>
              <Typography className={classes.titleNoti}>Mới</Typography>

              {allNoti
                ? newNotis?.map((el: any, index) =>
                    el?.type || !!el?.family_member ? (
                      <React.Fragment key={index}>
                        <CommonNoti
                          item={el}
                          setOpen={() => handleReadNoti(el?.id)}
                        />
                      </React.Fragment>
                    ) : null
                  )
                : newNotis?.map((el: any, index) =>
                    el?.read === false && (el?.type || !!el?.family_member) ? (
                      <React.Fragment key={index}>
                        <CommonNoti
                          item={el}
                          setOpen={() => handleReadNoti(el?.id)}
                        />
                      </React.Fragment>
                    ) : null
                  )}
            </div>
          ) : null}

          <div>
            <Typography className={classes.titleNoti}>Trước đó</Typography>

            {allNoti
              ? oldNotis?.map((el: any, index) =>
                  el?.type || !!el?.family_member ? (
                    <React.Fragment key={index}>
                      <CommonNoti
                        item={el}
                        setOpen={() => handleReadNoti(el?.id)}
                      />
                    </React.Fragment>
                  ) : null
                )
              : oldNotis?.map((el: any, index) =>
                  el?.read === false && (el?.type || !!el?.family_member) ? (
                    <React.Fragment key={index}>
                      <CommonNoti
                        item={el}
                        setOpen={() => handleReadNoti(el?.id)}
                      />
                    </React.Fragment>
                  ) : null
                )}
          </div>
        </div>
      </InfiniteScroll>
    </Box>
  );
}

export default ShowNotifications;
