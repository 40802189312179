import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useInView } from 'react-hook-inview';
import { useHistory } from 'react-router-dom';
import DialogPreviewMedia from '../Dialog/DialogPreviewMedia';
import VideoJs from './VideoJs';

interface Props {
  video?: any;
  videoPost?: any;
  action?: any;
  typePage?: String | any;
  style?: React.CSSProperties | any;
  type?: String | any;
  typeWatch?: String | any;
  typeLive?: String | any;
  typePost?: String | any;
  styleVideo?: React.CSSProperties | any;
  handleClose?: any;
  setPostData?: any;
  openPreview?: boolean | any;
  setOpenPreview?: React.Dispatch<React.SetStateAction<boolean>> | any;
  autoPlay?: boolean | any;
}

const WatchPlayer = (props: Props) => {
  const {
    video,
    videoPost,
    action,
    typePage,
    style,
    type,
    typeWatch,
    typeLive,
    typePost,
    styleVideo,
    handleClose,
    setPostData,
    openPreview,
    setOpenPreview,
    autoPlay = false
  } = props;
  const [isClickAction, setIsClickAction] = React.useState(false);
  const [idViewPort, setIdViewPort] = React.useState(null as any);
  const history = useHistory();

  const [ref, isVisible] = useInView({
    threshold: 1
  });
  const useStyles = makeStyles((theme: Theme) => ({
    wrapPlayer: {
      display: 'flex',
      width: '100%',
      position: 'relative',
      justifyContent: 'center',
      '&>.plyr': {
        width: '100%',
        ...style
      }
    },
    videoPlayer: {
      display: 'flex',
      width: '100%',
      height: typePost === 'pinned' ? '93px !important' : 'auto',
      position: 'relative',
      justifyContent: 'center',
      cursor: 'pointer',
      '&>.plyr': {
        width: '100%',
        ...style
      }
    }
  }));
  const classes = useStyles();

  React.useEffect(() => {
    if (isVisible) {
      setIdViewPort(video?.id);
    } else {
      setIdViewPort(null);
    }

    return () => {
      setIdViewPort(null);
    };
  }, [isVisible]);

  return (
    <>
      <div
        ref={ref}
        className={classes.wrapPlayer}
        style={{
          height:
            type === 'watch-screen'
              ? '100%'
              : typePage === 'preview_live'
              ? 'calc(100% - 110px)'
              : 'calc(100% - 45px)'
        }}
      >
        <div className={classes.videoPlayer} style={{ ...style }}>
          <VideoJs
            src={
              video?.remote_url || video?.url
                ? video?.remote_url ?? video?.url
                : videoPost?.media_attachments[0]?.remote_url ??
                  videoPost?.media_attachments[0]?.url
            }
            previewMedia={
              video?.preview_url
                ? video?.preview_url ?? video?.preview_remote_url
                : videoPost?.media_attachments[0]?.preview_url
            }
            id={video?.id}
            action={() => {
              if (typeLive) {
                history.push(
                  `/live/detail/${videoPost?.id}?host=${videoPost?.account?.username}&postMediaId=${videoPost?.id}&mediaId=${videoPost?.media_attachments[0]?.id}`
                );
                setOpenPreview(true);
              } else {
                action && action();
              }
            }}
            idViewPort={idViewPort}
            typePage={typePage}
            visibleVideo={isVisible}
            isClickAction={isClickAction}
            type={typeWatch}
            typeLive={typeLive}
            autoPlay={autoPlay}
            styleVideo={styleVideo}
            styleJs={style}
            handleClose={handleClose}
          />
        </div>
      </div>
      {openPreview && Object.keys(videoPost).length && (
        <DialogPreviewMedia
          open={openPreview}
          setOpen={setOpenPreview}
          post={videoPost}
          postMedia={videoPost}
          setPostMedia={setPostData}
          setMediaSelected={setPostData}
          typePreview="preview_live"
        />
      )}
    </>
  );
};

export default WatchPlayer;
