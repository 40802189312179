import * as types from 'src/constants/store/musicSocial';
const _ = require('lodash');
const listMusicSpace: any = localStorage.getItem('listMusic');

const initialState = {
  listMusic: JSON.parse(listMusicSpace) || [],
  paused: false,
  playAudio: false,
  audioIndex: 0,
  detailAlbum: null,
  topMusic: [],
  openMiniMusicPlayer: false,
  volumeMusic: 0.3,
  checkPathOutMusic: false,
  dataDetailPlaylist: [],
  isShowMiniAudio: false,
  activeMusic: null,
  randomMusic: false
};

export const musicReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_MUSIC: {
      let { data } = action.payload;

      let newState = _.cloneDeep(data.data);

      return {
        ...state,
        listMusic: newState,
        audioIndex: data.index,
        playAudio: data.playAudio
      };
    }
    case types.ACTION_MUSIC: {
      let { audioIndex, playAudio, paused } = action.payload;
      return {
        ...state,
        paused: paused,
        playAudio: playAudio,
        audioIndex: audioIndex
      };
    }
    case types.DETAIL_ALBUM: {
      return {
        ...state,
        detailAlbum: action.payload
      };
    }
    case types.UPDATE_ALBUM_MUSICSPACE: {
      const { data } = action.payload;
      return {
        ...state,
        detailAlbum: data
      };
    }

    case types.RESET_ALBUM_MUSICSPACE: {
      return {
        ...state,
        detailAlbum: null
      };
    }

    case types.GET_TOP_MUSIC: {
      return {
        ...state,
        topMusic: action.payload
      };
    }
    case types.SHOW_MUSIC_PLAYER: {
      return {
        ...state,
        openMiniMusicPlayer: action.payload
      };
    }
    case types.VOLUME_MUSIC: {
      return {
        ...state,
        volumeMusic: action.payload
      };
    }
    case types.CHECK_PATH_MUSIC: {
      return {
        ...state,
        checkPathOutMusic: action.payload
      };
    }
    case types.GET_DATA_PLAYLIST: {
      return {
        ...state,
        dataDetailPlaylist: action.payload
      };
    }
    case types.SHOW_MINI_AUDIO: {
      return {
        ...state,
        isShowMiniAudio: action.payload
      };
    }
    case types.ACTIVE_MUSIC: {
      const item = action.payload;
      return {
        ...state,
        activeMusic: item
      };
    }
    case types.RANDOM_MUSIC: {
      const type = action.payload;
      return {
        ...state,
        randomMusic: type
      };
    }

    case types.RESET_MUSIC_DATA: {
      return {
        ...state,
        topMusic: []
      };
    }

    default:
      return state;
  }
};
