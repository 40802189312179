import * as React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme,
  Typography
} from '@mui/material';
import { boxShadow } from 'src/constants/styles';
import ListButton from 'src/components/List/ListButton';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import CUPost from 'src/components/CUPost';
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '15px'
      },
      bodyLeft: {
        width: '45%',
        backgroundColor: '#f1f2f5'
      },
      bodyRight: {
        width: '50%',
        backgroundColor: '#f1f2f5'
      },
      boxIntro: {
        padding: '15px',
        borderRadius: '5px',
        backgroundColor: '#fff',
        marginBottom: '5px',
        ...boxShadow
      },
      textIntro: {
        fontWeight: 'bold !important',
        fontSize: '20px !important'
      }
    }),
  { index: 1 }
);
const YourContribute = props => {
  const classes = useStyles();
  const { groupInfo } = props;
  const [openDialogCUPost, setOpenDialogCUPost] =
    React.useState<boolean>(false);
  const listQuestion = [
    {
      id: 'contribute',
      title: 'Đóng góp là gì?',
      styleTitle: { fontWeight: '600' },
      action: () => {},
      accordion: true,
      style: { height: 36, width: 36 },
      position: 'top',
      itemChildren: [
        {
          id: 'content_contribute',
          title:
            'Việc tích cực đóng góp trong nhóm sẽ mang lại phần thưởng cho bạn! Nếu tham gia nhóm, bạn sẽ đủ điều kiện để nhận các huy hiệu khác.',
          styleTitle: { fontWeight: '400', '-webkit-line-clamp': '9' }
        }
      ]
    },
    {
      id: 'most_person_contribute',
      title: 'Cách kiếm huy hiệu người đóng góp nhiều nhất',
      styleTitle: { fontWeight: '600' },
      action: () => {},
      accordion: true,
      style: { height: 36, width: 36 },
      position: 'top',
      itemChildren: [
        {
          id: 'content_most_person_contribute',
          title: (
            <>
              <p>
                Bạn có thể nhận được huy hiệu người đóng góp nhiều nhất theo 2
                cách:
              </p>
              <ul>
                <li>
                  <i className="fa-regular fa-comment"></i>
                  <span style={{ marginLeft: '10px' }}>
                    Tham gia nhóm bằng cách đăng bài hoặc bình luận
                  </span>
                </li>
                <li>
                  <i className="fa-regular fa-star"></i>
                  <span style={{ marginLeft: '10px' }}>
                    Xung phong làm Quản trị viên hoặc Người kiểm duyệt
                  </span>
                </li>
              </ul>
            </>
          ),
          styleTitle: { fontWeight: '400', '-webkit-line-clamp': '9' }
        }
      ]
    },
    {
      id: 'value_keep_badge',
      title: 'Giá trị của việc giữ huy hiệu là gì?',
      styleTitle: { fontWeight: '600' },
      action: () => {},
      accordion: true,
      style: { height: 36, width: 36 },
      position: 'top',
      itemChildren: [
        {
          id: 'content_value_keep_badge',
          title:
            'Khi bạn nhận được huy hiệu, những người khác sẽ nhận ra bạn là người đóng góp nhiều nhất trong nhóm! Điều này chứng tỏ bạn thường xuyên đóng góp cho nhóm bằng cách tạo bài viết hoặc bình luận.',
          styleTitle: { fontWeight: '400', '-webkit-line-clamp': '9' }
        }
      ]
    },
    {
      id: 'others_badge',
      title:
        'Những huy hiệu này có chuyển sang các nhóm khác của tôi được không?',
      styleTitle: { fontWeight: '600' },
      action: () => {},
      accordion: true,
      style: { height: 36, width: 36 },
      position: 'top',
      itemChildren: [
        {
          id: 'content_others_badge',
          title: 'Không, mỗi huy hiệu chỉ áp dụng cho một nhóm.',
          styleTitle: { fontWeight: '400', '-webkit-line-clamp': '9' }
        }
      ]
    }
  ];
  return (
    <React.Fragment>
      <Box className={classes.root}>
        <Box className={classes.bodyLeft}>
          <Box className={classes.boxIntro}>
            <Typography className={classes.textIntro} variant="h5">
              Câu hỏi thường gặp
            </Typography>
            <ListButton
              item={listQuestion.filter(el => el.position === 'top')}
              width={'100%'}
              styleAccordionDetail={{
                padding: '3px'
              }}
              styleAccordion={{ margin: '0px !important' }}
              typeAction={'action'}
            />
          </Box>
        </Box>
        <Box className={classes.bodyRight}>
          <Box className={classes.boxIntro}>
            <Typography variant="h5" className={classes.textIntro}>
              Cách để nhận được huy hiệu Người đóng góp nhiều nhất
            </Typography>
            <ListItem>
              <ListItemAvatar style={{ minWidth: '30px' }}>
                <i className="fa-regular fa-comment"></i>
              </ListItemAvatar>
              <ListItemText
                primary="Bắt đầu cuộc trò chuyện"
                secondary="Tạo bài viết và bình luận để chia sẻ suy nghĩ của bạn với các thành viên khác"
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar style={{ minWidth: '30px' }}>
                <i className="fa-regular fa-star"></i>
              </ListItemAvatar>
              <ListItemText
                primary="Đảm nhiệm vai trò"
                secondary="Xung phong làm Quản trị viên hoặc Người kiểm duyệt"
              />
            </ListItem>
            <ButtonInherit
              action={() => {
                setOpenDialogCUPost(true);
              }}
              label="Tạo bài viết"
              style={{ width: '100%', marginRight: 0 }}
            />
          </Box>
        </Box>
      </Box>
      {openDialogCUPost && (
        <CUPost
          type="header-post"
          groupShared={groupInfo}
          openCUPost={openDialogCUPost}
          setOpenCUPost={setOpenDialogCUPost}
        />
      )}
    </React.Fragment>
  );
};

export default YourContribute;
