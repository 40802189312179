import React, { useRef } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import {
  Theme,
  Slide,
  Popper,
  Paper,
  Fade,
  Box,
  useMediaQuery
} from '@mui/material';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import './Emoji.css';
import { EnumEmojiReactType } from 'src/constants/enum/emojiReact';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      button: {
        borderRadius: '0px',
        width: '30%',
        padding: 0,
        margin: 2
      },
      text: {
        fontSize: '.875rem',
        marginLeft: 5
      },
      paper: {
        borderRadius: '50px !important',
        overflow: 'visible !important',
        padding: '10px 8px 0px 8px !important',
        // border: '1px solid black!important',
        // boxShadow: '2px 2px 10px grey !important',
        marginLeft: '-60px !important'

        // height: '30px !important'
      }
    }),
  { index: 1 }
);

function Index(props) {
  let { children, onChange, isComment, style, closePopper, setContentTooltip } =
    props;
  const classes = useStyles();
  const [openPopper, setOpenPopper] = React.useState(false);
  const [isMounted, setIsMounted] = React.useState(true);
  const containerRef = React.useRef(null);
  const closeRef = useRef<any>();
  const openRef = useRef<any>();
  const theme = useTheme();
  const matches = useMediaQuery('(max-width:600px)');

  const handleClickReactEmoji = (id: number) => {
    openRef.current && clearTimeout(openRef.current);
    onChange(id);
    setOpenPopper(false);
  };

  const handleMouseEnter = event => {
    if (isMounted) {
      closeRef.current && clearTimeout(closeRef.current);
      openRef.current = setTimeout(() => {
        setOpenPopper(true);
      }, 500);
      openRef.current = setTimeout(() => {
        setContentTooltip && setContentTooltip('');
      }, 1000);
    }
  };

  const handleMouseLeave = () => {
    if (isMounted) {
      openRef.current && clearTimeout(openRef.current);
      closeRef.current = setTimeout(() => {
        setOpenPopper(false);
        setContentTooltip && setContentTooltip('Bày tỏ cảm xúc');
      }, 800);
    }
  };

  const clearOpen = () => {
    openRef.current && clearTimeout(openRef.current);
  };

  const spanTextEmoji = text => {
    return (
      <span
        className="reactionLabel"
        style={{
          marginBottom: '-5px'
        }}
      >
        {text}
      </span>
    );
  };

  React.useEffect(() => {
    return () => {
      setIsMounted(false);
      clearOpen();
      closeRef.current && clearTimeout(closeRef.current);
      openRef.current && clearTimeout(openRef.current);
    };
  }, []);

  return (
    <PopupState variant="popper" popupId="demoPopper">
      {popupState => (
        <div
          aria-owns="mouse-over-popper"
          aria-haspopup="true"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleMouseEnter}
          onTouchEnd={handleMouseLeave}
          style={{
            width: isComment ? '' : '100%',
            display: 'inherit',
            ...style
          }}
        >
          {children(popupState, bindHover, clearOpen)}
          <Popper
            {...bindPopper(popupState)}
            open={openPopper}
            placement="top-start"
            disablePortal={false}
            modifiers={[
              {
                name: 'flip',
                enabled: false
              }
            ]}
            transition
            style={{ zIndex: 1300 }}
          >
            {({ TransitionProps }) => (
              <Fade in={openPopper} {...{ timeout: 300 }}>
                <Paper
                  className={classes.paper}
                  sx={{
                    backgroundColor: 'transparent',
                    boxShadow: 'unset',
                    backgroundImage: 'none',
                    marginBottom: '-18px',
                    transform: matches ? 'scale(0.7)' : 'scale(0.8) !important'
                  }}
                >
                  <Box
                    sx={{
                      overflow: 'hidden',
                      '&:hover': { overflow: 'visible' },
                      display: 'flex',
                      alignItems: 'end'
                    }}
                  >
                    <Box
                      ref={containerRef}
                      sx={{
                        height: '90px',
                        display: 'flex',
                        alignItems: 'end',
                        backgroundColor: 'transparent',
                        padding: '5px 10px 5px 10px'
                      }}
                    >
                      {closePopper && setOpenPopper(false)}

                      <Slide
                        {...TransitionProps}
                        direction="up"
                        exit={false}
                        in={openPopper}
                        unmountOnExit={true}
                        container={containerRef.current}
                      >
                        <Box
                          sx={{
                            height: '60px',
                            display: 'flex',
                            alignItems: 'end',
                            borderRadius: '30px !important',
                            backgroundColor: 'background.primary',
                            padding: '3px 0px 1px 0px',
                            marginBottom: '5px',
                            border:
                              theme.palette.mode === 'dark'
                                ? '1px solid #3e4042'
                                : '1px solid #ced0d4'
                          }}
                        >
                          <div
                            className="emoji"
                            onClick={() => {
                              handleClickReactEmoji(EnumEmojiReactType.like);
                              popupState.close();
                            }}
                          >
                            {spanTextEmoji('Thích')}
                            <div
                              style={{
                                overflow: 'hidden',
                                height: '50px',
                                width: '50px',
                                margin: '5px -2px 3px 5px'
                              }}
                            >
                              <div style={{ height: '100%', display: 'flex' }}>
                                <div
                                  style={{
                                    display: 'inline-block',
                                    height: '50px',
                                    width: '50px',
                                    transform: 'rotate(-90deg)'
                                  }}
                                >
                                  <div className="emoji-like"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="emoji"
                            onClick={() => {
                              handleClickReactEmoji(EnumEmojiReactType.love);
                              popupState.close();
                            }}
                            style={{ width: '68px', height: '68px' }}
                          >
                            {spanTextEmoji('Yêu Thích')}
                            <div
                              style={{
                                overflow: 'hidden',
                                height: '80px',
                                width: '80px'
                              }}
                            >
                              <div
                                style={{
                                  display: 'inline-block',
                                  height: '80px',
                                  width: '80px',
                                  transform: 'rotate(-90deg) translateX(5px)'
                                }}
                              >
                                <div className="emoji-love"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="emoji"
                            onClick={() => {
                              handleClickReactEmoji(EnumEmojiReactType.yay);
                              popupState.close();
                            }}
                          >
                            {spanTextEmoji('Tự Hào')}
                            <div
                              style={{
                                overflow: 'hidden',
                                height: '90px',
                                width: '90px',
                                marginBottom: '-8px',
                                marginLeft: '-12px',
                                marginRight: '-12px'
                              }}
                            >
                              <div
                                style={{
                                  display: 'inline-block',
                                  height: '90px',
                                  width: '90px',
                                  transform: 'rotate(-90deg)'
                                }}
                              >
                                <div className="emoji-yay"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="emoji"
                            onClick={() => {
                              handleClickReactEmoji(EnumEmojiReactType.haha);
                              popupState.close();
                            }}
                          >
                            {spanTextEmoji('Haha')}
                            <div
                              style={{
                                overflow: 'hidden',
                                height: '65px',
                                width: '65px',
                                margin: '0px -4px -3px'
                              }}
                            >
                              <div
                                style={{
                                  display: 'inline-block',
                                  height: '65px',
                                  width: '65px',
                                  transform: 'rotate(-90deg) translateX(1px)'
                                }}
                              >
                                <div className="emoji-haha"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="emoji"
                            onClick={() => {
                              handleClickReactEmoji(EnumEmojiReactType.wow);
                              popupState.close();
                            }}
                          >
                            {spanTextEmoji('Bất ngờ')}
                            <div
                              style={{
                                overflow: 'hidden',
                                height: '56px',
                                width: '56px',
                                marginBottom: '0px'
                              }}
                            >
                              <div
                                style={{
                                  display: 'inline-block',
                                  height: '56px',
                                  width: '56px',
                                  transform: 'rotate(-90deg)  translateX(3px)'
                                }}
                              >
                                <div className="emoji-wow"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="emoji"
                            onClick={() => {
                              handleClickReactEmoji(EnumEmojiReactType.sad);
                              popupState.close();
                            }}
                          >
                            {spanTextEmoji('Buồn')}
                            <div
                              style={{
                                overflow: 'hidden',
                                height: '56px',
                                width: '55px'
                              }}
                            >
                              <div
                                style={{
                                  display: 'inline-block',
                                  height: '56px',
                                  width: '56px',
                                  transform: 'rotate(-90deg) translateX(4px)'
                                }}
                              >
                                <div className="emoji-sad"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="emoji"
                            onClick={() => {
                              handleClickReactEmoji(EnumEmojiReactType.angry);
                              popupState.close();
                            }}
                          >
                            {spanTextEmoji('Phẫn Nộ')}
                            <div
                              style={{
                                overflow: 'hidden',
                                height: '52px',
                                width: '52px',
                                margin: '0px 3px 2px 0px '
                              }}
                            >
                              <div
                                style={{
                                  display: 'inline-block',
                                  height: '52px',
                                  width: '52px',
                                  transform: 'rotate(-90deg) translateX(3px)'
                                }}
                              >
                                <div className="emoji-angry"></div>
                              </div>
                            </div>
                          </div>
                        </Box>
                      </Slide>
                    </Box>
                  </Box>
                </Paper>
              </Fade>
            )}
          </Popper>
        </div>
      )}
    </PopupState>
  );
}

export default Index;
