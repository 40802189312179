import { Box, Slider, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React from 'react';
import { useInView } from 'react-hook-inview';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { postWatchHistoriesApi } from 'src/apis/socialPost.api';

interface Props {
  video?: any;
  action?: any;
  typePage?: String;
  openPreview?: any;
  pageMoment?: any;
  isVolume?: boolean;
  isShare?: String;
  indexMedia?: number;
  videoRef?: any;
  isFullScreen?: boolean;
  mutedVideo?: boolean;
  setMutedVideo?: React.Dispatch<React.SetStateAction<boolean>> | any;
}

const MomentVideo = (props: Props) => {
  const {
    video,
    action,
    typePage,
    openPreview,
    pageMoment,
    isVolume,
    isShare,
    indexMedia,
    videoRef,
    isFullScreen,
    mutedVideo,
    setMutedVideo
  } = props;
  let stylePointer = {
    '&:hover': {
      cursor: 'pointer'
    }
  };
  const useStyles = makeStyles((theme: Theme) => ({
    wrapControl: {
      width: '100%',
      display: 'inline-flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      position: 'absolute',
      padding: '12px 20px',
      bottom: 30,
      left: 0,
      zIndex: 100
    },
    wrapSlider: {
      display: 'flex',
      width: '100%',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      padding: '0px 15px'
    },
    wrapText: {
      width: 70,
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      bottom: '5px',
      position: 'absolute',
      left: '35px'
    },
    wrapVolume: {
      width: 35,
      height: 82,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between'
    },

    wrapIcon: {
      marginBottom: '-10px',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    wrapVideo: {
      width: '100%',
      maxWidth: '100%',
      [theme.breakpoints.down('xl')]: {
        height: typePage === 'preview-moment' ? '100vh' : 500
      },
      height: typePage === 'preview-moment' ? '100vh' : 600,
      borderRadius:
        typePage === 'preview-moment' || pageMoment === 'momentInFeed' ? 0 : 12,
      ...stylePointer
    },
    wrapVideoHorizon: {
      [theme.breakpoints.down('xl')]: {
        width: typePage === 'preview-moment' ? 'auto' : 515
      },
      width: typePage === 'preview-moment' ? 'auto' : 560,
      maxWidth: '100%',
      height: isFullScreen ? 'calc(100vh - 100px)' : '100%',
      borderRadius:
        typePage === 'preview-moment' || pageMoment === 'momentInFeed' ? 0 : 12,
      ...stylePointer
    },
    wrapVideoSquare: {
      [theme.breakpoints.down('xl')]: {
        height: typePage === 'preview-moment' ? '100vh' : 400,
        width: typePage === 'preview-moment' ? 'auto' : 400
      },
      width: typePage === 'preview-moment' ? '100vh' : 500,
      borderRadius:
        typePage === 'preview-moment' || pageMoment === 'momentInFeed' ? 0 : 12,
      height: typePage === 'preview-moment' ? '100vh' : 500,
      ...stylePointer
    }
  }));
  const classes = useStyles();
  const [position, setPosition] = React.useState(0);
  const [volume, setVolume] = React.useState(0.5);
  const [isShowVolume, setIsShowVolume] = React.useState(false);
  const [isShowControl, setIsShowControl] = React.useState(false);
  const [isPlay, setIsPlay] = React.useState(true);
  const [countPlay, setCountPlay] = React.useState({
    view3s: 0,
    view60s: 0
  });
  const [videoFocus, setVideoFocus] = React.useState<any>();

  const [ref, isVisible] = useInView({
    threshold: 1
  });
  let query = useQuery();
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const queryMoment = query.get('mediaId');
  let videoDOM: any = document.getElementById(`${video.id}${typePage}`);

  const playVideo = () => {
    if (videoRef?.current?.paused) {
      setIsPlay(true);
      videoRef?.current?.play();
    } else {
      setIsPlay(false);
      videoRef?.current?.pause();
    }
  };

  function formatDuration(value: number) {
    const minute = Math.floor(value / 60);
    const secondLeft = value - minute * 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }

  const updateVideoHistories = React.useCallback(
    _.debounce(async data => {
      await postWatchHistoriesApi(data);
    }, 800),
    []
  );

  const timeUpdateFunc = () => {
    if (videoRef?.current?.currentTime) {
      setPosition(videoRef.current.currentTime);
    }
  };

  const handleChangeVolume = value => {
    setVolume(value);
    videoDOM.volume = value;
    setMutedVideo(false);
  };

  let videoDOMPreview: any = document.getElementById(
    `${video.id}preview-moment`
  );
  let videoDOMMoment: any = document.getElementById(`${video.id}feed_moment`);

  React.useEffect(() => {
    if (openPreview === 'undefined') return;

    if (openPreview) {
      setIsPlay(false);
      videoDOMMoment?.pause();

      if (
        videoDOMPreview &&
        videoDOMMoment &&
        isFinite(videoDOMMoment?.currentTime)
      ) {
        videoDOMPreview.currentTime = videoDOMMoment?.currentTime;
      }
    } else if (openPreview === false) {
      setIsPlay(true);
      if (videoDOMMoment) {
        videoDOMMoment.play().catch(error => {
          // Xử lý lỗi khi play() bị gián đoạn
        });
      }
      if (
        videoDOMMoment &&
        videoDOMPreview &&
        isFinite(videoDOMPreview?.currentTime)
      ) {
        videoDOMMoment.currentTime = videoDOMPreview?.currentTime;
      }
    }
  }, [openPreview, videoDOMPreview, videoDOMMoment]);

  React.useEffect(() => {
    if (video) {
      if (Math.ceil(position) === 3 && countPlay.view3s < 1) {
        updateVideoHistories({
          action_type: 'view_3s',
          status_ids: [video.status_id]
        });

        setCountPlay(prev => ({
          ...prev,
          view3s: prev.view3s + 1
        }));
      } else if (Math.ceil(position) === 60 && countPlay.view60s < 1) {
        updateVideoHistories({
          action_type: 'view_60s',
          status_ids: [video.status_id]
        });
        setCountPlay(prev => ({
          ...prev,
          view60s: prev.view60s + 1
        }));
      }
    }
  }, [position]);

  React.useEffect(() => {
    if (pageMoment && pageMoment === 'momentInFeed' && videoDOM) {
      if (isVolume) {
        handleChangeVolume(1);
      } else {
        handleChangeVolume(0);
      }
    }
  }, [pageMoment, isVolume, videoDOM]);

  React.useEffect(() => {
    if (typePage === 'preview-moment') {
      videoRef?.current?.focus();
      setIsPlay(true);
    }
  }, [indexMedia, videoRef]);

  const stopOtherVideos = (vd: any) => {
    const videos = document.getElementsByTagName('video');
    for (let i = 0; i < videos.length; i++) {
      if (videos[i] !== vd && !videos[i].paused) {
        videos[i].pause();
      }
    }
  };

  React.useEffect(() => {
    let tempVideo: any;
    if (typePage === 'preview-moment') {
      stopOtherVideos(videoDOMMoment);
    } else {
      if (isVisible) {
        let videoDOM: any = document.getElementsByClassName('isVisible');
        if (videoDOM.length > 0) {
          tempVideo = 0;
          setVideoFocus(videoDOM[tempVideo]);
        }
      }
    }
  }, [isVisible, videoRef, typePage]);

  React.useEffect(() => {
    if (videoFocus && typePage !== 'preview-moment') {
      videoFocus.play().catch(error => {});
      stopOtherVideos(videoFocus);
    }

    return () => {};
  }, [videoRef, videoFocus]);

  React.useEffect(() => {
    // Đăng ký event listener cho sự kiện khi tab được chuyển
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      // Hủy đăng ký event listener khi component bị unmount
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const handleVisibilityChange = () => {
    // Kiểm tra nếu tab bị chuyển đi (trở thành "hidden" state)
    if (document.visibilityState === 'hidden') {
      // Kiểm tra nếu video đang phát, thì tạm dừng nó
      if (!videoRef.current.paused) {
        videoRef.current.pause();
      }
    } else {
      if (queryMoment) {
        let videoDOMPreview: any = document.getElementById(
          `${video.id}preview-moment`
        );
        if (videoDOMPreview) {
          videoDOMPreview?.play();
        }
      } else {
        let videoDOMMoment: any = document.getElementById(
          `${video.id}feed_moment`
        );
        if (videoDOMMoment) {
          videoDOMMoment?.play();
        }
      }
    }
  };

  React.useEffect(() => {
    const handleLoad = () => {
      setMutedVideo(true);
      videoRef?.current?.play();
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <div
      ref={ref}
      onMouseEnter={() => setIsShowControl(true)}
      onMouseLeave={() => setIsShowControl(false)}
      style={{
        position: 'relative',
        maxWidth: isFullScreen ? '90%' : '100%'
      }}
    >
      <video
        id={`${video.id}${typePage}`}
        onTimeUpdate={timeUpdateFunc}
        ref={videoRef}
        autoPlay
        preload="auto"
        playsInline
        loop
        muted={mutedVideo}
        onCanPlayThrough={() => {
          videoDOM.play();
        }}
        controls={isShare === 'yes' ? true : false}
        onKeyPress={(el: any) => {
          if (
            el.code === 'Space' &&
            isPlay === true &&
            videoRef?.current?.played
          ) {
            setIsPlay(false);
            videoRef?.current?.pause();
          } else {
            setIsPlay(true);
            videoRef?.current?.play();
          }
        }}
        onClick={() => {
          if (isPlay === true && videoRef?.current?.played) {
            setIsPlay(false);
            videoRef?.current?.pause();
            setVideoFocus(videoRef?.current);
          } else {
            setIsPlay(true);
            videoRef?.current?.play();
            stopOtherVideos(videoRef?.current);
          }
          action && action(position);
        }}
        src={video.url}
        className={`${
          video?.meta?.small?.aspect > 1
            ? classes.wrapVideoHorizon
            : video?.meta?.small?.aspect === 1
            ? classes.wrapVideoSquare
            : classes.wrapVideo
        } ${isVisible ? 'isVisible' : ''}`}
      />

      {pageMoment !== 'momentInFeed'
        ? isShowControl && (
            <div className={classes.wrapControl}>
              <div className={classes.wrapIcon}>
                <i
                  onClick={playVideo}
                  className={`fa-solid fa-${
                    videoRef?.current?.paused ? 'play' : 'pause'
                  }`}
                  style={{ color: '#fff', fontSize: 18 }}
                ></i>
              </div>
              <div className={classes.wrapSlider}>
                <div className={classes.wrapText}>
                  <Typography style={{ color: '#fff', fontSize: 13 }}>
                    {formatDuration(Math.ceil(position))}
                  </Typography>
                  <Typography style={{ color: '#fff', fontSize: 13 }}>
                    /
                    {formatDuration(Math.ceil(video?.meta?.original?.duration))}
                  </Typography>
                </div>
                <Slider
                  aria-label="time-indicator"
                  size="small"
                  value={position}
                  min={0}
                  step={1}
                  max={video?.meta?.original?.duration}
                  onChange={(_, value) => {
                    setPosition(value as number);
                    videoDOM.currentTime = value;
                  }}
                  sx={{
                    color: '#fff',
                    height: 4,
                    padding: 0,
                    width: 'calc(100% - 70px) !important',
                    '& .MuiSlider-thumb': {
                      width: 8,
                      height: 8,
                      transition: '0.3s cubic-bezier(.47,1.64,.41,.8)'
                    }
                  }}
                />
              </div>

              <div
                onMouseEnter={() => setIsShowVolume(true)}
                onMouseLeave={() => setIsShowVolume(false)}
                className={classes.wrapVolume}
              >
                {isShowVolume ? (
                  <Slider
                    aria-label="Volume"
                    value={volume}
                    orientation="vertical"
                    max={1}
                    step={0.1}
                    min={0}
                    onChange={(_, value) => handleChangeVolume(value)}
                    sx={{
                      color: '#fff',
                      height: 60,
                      marginBottom: 1,
                      '& .MuiSlider-track': {
                        border: 'none'
                      },
                      '& .MuiSlider-thumb': {
                        width: 12,
                        height: 12,
                        backgroundColor: '#fff',
                        '&:before': {
                          boxShadow: '0 4px 8px rgba(0,0,0,0.4)'
                        },
                        '&:after': {
                          width: 12,
                          height: 12
                        },
                        '&:hover, &.Mui-focusVisible, &.Mui-active': {
                          boxShadow: 'none'
                        }
                      }
                    }}
                  />
                ) : (
                  <div></div>
                )}
                {volume && !mutedVideo ? (
                  <div
                    onClick={() => handleChangeVolume(0)}
                    className={classes.wrapIcon}
                  >
                    <i
                      style={{ color: '#fff', fontSize: 18 }}
                      className="fa-solid fa-volume"
                    ></i>
                  </div>
                ) : (
                  <div
                    onClick={() => handleChangeVolume(0.5)}
                    className={classes.wrapIcon}
                  >
                    <i
                      style={{ color: '#fff', fontSize: 18 }}
                      className="fa-solid fa-volume-xmark"
                    ></i>
                  </div>
                )}
              </div>
            </div>
          )
        : null}

      {isShowControl && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 5,
            width: '100%',
            height: '100px',
            backgroundImage: isShowControl
              ? 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0,0.7))'
              : null,
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px'
          }}
        ></Box>
      )}
    </div>
  );
};

export default MomentVideo;
