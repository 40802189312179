import * as React from 'react';import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Chip,
  Theme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { buttonColor, buttonStyle, modalBackdrop } from 'src/constants/styles';
import { updateGroupApi } from 'src/apis/socialGroup';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupDetailReq } from 'src/store/action/socialGroupAction';
import { listMenu } from 'src/constants/common';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const stylesChip = {
  margin: '5px 10px 5px 0 !important',
  fontSize: 14,
  fontWeight: 600
};

const useStyles = makeStyles((theme: Theme) => ({
  chip: stylesChip,
  chipSelected: {
    ...stylesChip,
    backgroundColor: '#E7F3FF !important',
    color: `${buttonColor.backgroundColor} !important`
  }
}));

export default function CustomizedDialogs({ open, setOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const groupSelected =
    useSelector((state: any) => state.groupReducer.groupSelected) || {};

  const handleClose = () => {
    setOpen(false);
    setChipSelected(1);
  };
  const [chipSelected, setChipSelected] = React.useState<any>(1);

  let handleUpdate = async () => {
    let data = {
      inactivate_reason: chipSelected
    };
    let res = await updateGroupApi(groupSelected.id, data);
    if (res.status === 200) {
      dispatch(getGroupDetailReq(groupSelected.id, ''));
      handleClose();
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{ fontWeight: 'bold', fontSize: 21 }}
            textAlign={'center'}
          >
            Tạm dừng nhóm
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography style={{ fontWeight: 'bold', fontSize: 21 }}>
            Tại sao bạn muốn tạm dừng nhóm này?
          </Typography>
          <div style={{ margin: '10px 0px' }}>
            {listMenu?.map((el: any, index) => (
              <Chip
                key={el.id}
                className={
                  chipSelected === el.id ? classes.chipSelected : classes.chip
                }
                onClick={() => {
                  setChipSelected(el.id);
                }}
                label={el.value}
              />
            ))}
          </div>
          <Typography>
            Khi bạn tạm dừng nhóm, mọi người sẽ chỉ đọc được nội dung trong đó.
            Một số công cụ có thể bị hạn chế đối với quản trị viên và người kiểm
            duyệt khi nhóm tạm dừng. Bạn có thể tiếp tục bất cứ lúc nào.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ ...buttonStyle, textTransform: 'none' }}
          >
            Hủy
          </Button>
          <ButtonInherit
            action={handleUpdate}
            color={buttonColor.backgroundColor}
            label="Tạm dừng"
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
