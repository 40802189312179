import React, { useEffect, useRef } from 'react';
import { Badge, Box, Theme, Tooltip, Typography } from '@mui/material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { urlRocketChat } from 'src/util/config';
import { collapseString } from 'src/common/string';

import {
  selectedUserBoxChat,
  removeUserBoxChat
} from 'src/store/action/socialChatAction';
import { getUser } from 'src/apis/socialChat.apis';
import { subRoom } from 'src/socket/RocketChatSubscriptions';

import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { buttonColor } from 'src/constants/styles';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      iconClose: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f1f2f5',
        borderRadius: '50%',
        padding: 1,
        height: 20,
        width: 20,
        right: -5,
        top: 0,
        zIndex: 40,
        border: '2px solid rgba(0,0,0,0.1)',
        '&:hover': {
          cursor: 'pointer'
        }
      },
      arrow: {
        padding: '8px 12px',
        borderRadius: '100%',
        transform: 'rotate(45deg)',
        backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#1c1e21'
      }
    }),
  { index: 1 }
);
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className, arrow: useStyles().arrow }}
    placement="left"
    arrow
    ref={props.ref}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#1c1e21',
    color: theme.palette.mode === 'dark' ? '#ffffff' : '#1c1e21',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1),0 12px 28px rgba(0, 0, 0, 0.2)',
    padding: '10px',
    minWidth: '150px',
    maxWidth: '300px',
    borderRadius: '12px',
    lineHeight: '1.34'
  }
}));

const renderMessage = (conversation, sender, newText) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <b style={{ fontSize: '14px' }}>{sender}</b>
      <span>
        {conversation?.alert && (
          <i
            className="fa-solid fa-circle"
            style={{
              fontSize: '10px',
              color: buttonColor.backgroundColor,
              marginRight: '8px'
            }}
          ></i>
        )}
        {newText}
      </span>
    </Box>
  );
};
function BadgeChat({ conversation, countUnread }) {
  const [userChatData, setUserChatData] = React.useState<any>(null);
  const [membersData, setMembersData] = React.useState<any>([]);

  const fetchUserData = async () => {
    if (conversation?.type === 'user') {
      const response = await getUser(userId);
      try {
        if (response.status === 200) {
          const data = response.data.user;
          setUserChatData(data);
        }
      } catch (error) {
        // console.log('[getUser]', error);
      }
    } else if (conversation?.type === 'group') {
      setMembersData(conversation?.members);
    }
  };
  React.useEffect(() => {
    fetchUserData();
  }, [conversation?.userId]);
  const userId = conversation.userId;
  const statusUser = userChatData?.status;
  let statusGroup;
  if (
    (membersData !== null || membersData !== undefined) &&
    membersData?.length > 0
  ) {
    statusGroup = membersData
      .map((member: any) => member.status)
      .some((status: any) => status === 'online');
  }

  const rocketId = useSelector((state: any) => state.meReducer.info).id || '';

  const classes = useStyles();
  const dispatch = useDispatch();

  const [showClose, setShowClose] = React.useState<boolean>(false);
  const [hoverAction, setHoverAction] = React.useState(false);

  const handleClick = () => {
    if (!hoverAction) {
      dispatch(selectedUserBoxChat(conversation));
      subRoom(conversation.rid);
    }
  };

  const handleCloseBadge = () => {
    dispatch(removeUserBoxChat(conversation));
  };

  const mainTopic =
    conversation?.topic?.colors || conversation?.RoomsList?.[0]?.topic?.[0];

  const renderLastMessage = () => {
    let sender;
    if (conversation?.type === 'group') {
      if (conversation?.lastMessage?.u?._id === rocketId) {
        sender = 'Bạn';
      } else {
        sender = `${conversation?.lastMessage?.u?.name}`;
      }
    } else {
      if (conversation?.lastMessage?.u?._id === rocketId) {
        sender = 'Bạn';
      } else {
        sender = '';
      }
    }
    let content: any;
    const conditionTypeFile =
      conversation?.lastMessage?.file?.type?.split('/')?.[0];

    // if (conversation?.lastMessage?.t) {
    //   content = collapseString(conversation?.lastMessage?.msg, 15);
    //   return `${sender}${content}`;
    // }
    if (conversation?.lastMessage?.colors) {
      content = collapseString(
        `Đã đổi chủ đề cuộc trò chuyện thành ${conversation?.lastMessage?.title}`,
        16
      );

      return renderMessage(conversation, sender, content);
    }
    if (
      (conversation?.lastMessage?.md?.length > 0 &&
        conversation?.lastMessage?.md[0]?.value?.[0]?.type ===
          'MENTION_USER') ||
      (conversation?.lastMessage?.mentions &&
        conversation?.lastMessage?.mentions.length > 0)
    ) {
      const lastMessageMentions = conversation?.lastMessage?.msg;
      const regexp = /@\[([\w\s]+)\]/g;
      let textRender;
      if (lastMessageMentions === '@[all]') {
        textRender = '@mọi người';
      } else {
        textRender = lastMessageMentions.replace(regexp, username => {
          const objectMentions = conversation?.members?.find(
            el => `@[${el._id}]` === username
          );

          if (objectMentions) {
            return `@${objectMentions.name}`;
          }
        });
      }

      let newText = textRender.replace(/\([^)]*\)/g, '');

      return renderMessage(conversation, sender, newText);
    } else if (
      typeof conversation?.lastMessage?.msg === 'string' &&
      (conversation?.lastMessage?.msg.startsWith(':like_small:') ||
        conversation?.lastMessage?.msg.startsWith(':like_big:') ||
        conversation?.lastMessage?.msg.startsWith(':like_largest:'))
    ) {
      content = conversation?.customFields?.icon ? (
        <span style={{ fontSize: 15 }}>{conversation?.customFields?.icon}</span>
      ) : (
        <i
          className="fa-sharp fa-solid fa-thumbs-up"
          style={{
            color: mainTopic ? mainTopic[1] : buttonColor.backgroundColor,
            fontSize: 15
          }}
        ></i>
      );
      return renderMessage(conversation, sender, content);
    } else if (conversation?.lastMessage?.emoticon?.icon) {
      content = collapseString(
        `Đã đặt biểu tượng cảm xúc thành ${conversation?.lastMessage?.msg}`,
        18
      );
      return renderMessage(conversation, sender, content);
    }
    content = conversation?.lastMessage?.msg
      ? conversation.lastMessage.msg.startsWith(`[ ](${urlRocketChat}`)
        ? collapseString(conversation.lastMessage.msg.split(') ')[1], 15)
        : conversation.lastMessage.msg.startsWith(`https://sdk.mojitok.im/`)
        ? collapseString('Đã gửi một nhãn dãn', 15)
        : conversation.lastMessage.msg.startsWith(`https://media`)
        ? collapseString('Đã gửi một gif', 15)
        : conversation?.lastMessage?.type === 'forward'
        ? collapseString('Đã chuyển tiếp một tin nhắn', 15)
        : conditionTypeFile
        ? `Đã gửi một ${
            conditionTypeFile === 'image'
              ? 'ảnh'
              : conditionTypeFile === 'application' ||
                conditionTypeFile === 'text'
              ? 'file'
              : conditionTypeFile
          }`
        : collapseString(conversation?.lastMessage?.msg, 15)
      : '';
    return renderMessage(conversation, sender, content);
  };

  const urlAvatarGroup = `${urlRocketChat}/avatar/room/${
    conversation?.rid
  }?etag=${conversation.avatarETag ?? conversation.RoomsList?.[0]?.avatarETag}`;
  const urlAvatarMemberGroup = member => {
    return `${urlRocketChat}/avatar/${conversation?.members[member]?.username}`;
  };
  return (
    <>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                gap: '10px'
              }}
            >
              <Typography style={{ fontSize: '13px', fontWeight: '400' }}>
                {renderLastMessage()}
              </Typography>
            </Box>
          </React.Fragment>
        }
      >
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          variant="dot"
          onClick={handleClick}
          onMouseEnter={(event: any) => {
            event.preventDefault();
            setShowClose(true);
          }}
          onMouseLeave={() => {
            setShowClose(false);
          }}
          sx={{
            ...((statusGroup || statusUser === 'online') && {
              '& .MuiBadge-badge': {
                backgroundColor: '#44b700',
                color: '#44b700',
                '&::after': {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  animation: 'ripple 1.2s infinite ease-in-out',
                  border: '1px solid currentColor',
                  content: '""'
                }
              },
              '@keyframes ripple': {
                '0%': {
                  transform: 'scale(.8)',
                  opacity: 1
                },
                '100%': {
                  transform: 'scale(2.4)',
                  opacity: 0
                }
              },
              borderRadius: '50%',
              marginBottom: '10px'
            })
          }}
        >
          {conversation?.type === 'group' ? (
            !conversation.avatarETag &&
            !conversation.RoomsList?.[0]?.avatarETag ? (
              <Box
                sx={{
                  width: '45px',
                  height: '45px',
                  position: 'relative',
                  backgroundColor: 'transparent'
                }}
              >
                <AvatarSocial
                  type="feed"
                  style={{
                    width: 30,
                    height: 30,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    border: 'none !important'
                  }}
                  avatarObj={urlAvatarMemberGroup(0)}
                />
                <AvatarSocial
                  type="feed"
                  style={{
                    width: 30,
                    height: 30,
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    border: 'none !important'
                  }}
                  avatarObj={urlAvatarMemberGroup(1)}
                />
              </Box>
            ) : (
              <AvatarSocial
                type="feed"
                style={{
                  width: 45,
                  height: 45,
                  border: 'none !important'
                }}
                avatarObj={urlAvatarGroup}
              />
            )
          ) : (
            <AvatarSocial
              type="feed"
              style={{
                width: 45,
                height: 45,
                border: 'none !important'
              }}
              avatarObj={conversation?.avatar_media?.show_url}
            />
          )}
          {showClose || countUnread !== 0 ? (
            <Box
              className={classes.iconClose}
              sx={{
                backgroundColor: countUnread !== 0 ? '#d32f2f' : undefined
              }}
              onClick={() => {
                handleCloseBadge();
              }}
              onMouseEnter={(event: any) => {
                event.preventDefault();
                setHoverAction(true);
              }}
              onMouseLeave={() => {
                setHoverAction(false);
              }}
            >
              {countUnread !== 0 ? (
                <p
                  style={{
                    fontSize: '12px',
                    fontWeight: '500',
                    color: '#fafafa'
                  }}
                >
                  {countUnread}
                </p>
              ) : (
                <i
                  style={{
                    fontSize: 12,
                    color: '#212121'
                  }}
                  className="fa-solid fa-xmark"
                ></i>
              )}
            </Box>
          ) : null}
        </Badge>
      </HtmlTooltip>
    </>
  );
}

export default BadgeChat;
