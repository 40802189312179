import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';

import SocialFieldUpdate from 'src/components/SocialFieldUpdate/SocialFieldUpdate';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '5px 10px -10px 10px',
    borderRadius: 10,
    width: '100%'
  }
}));

const OtherInformation = (props: any) => {
  const classes = useStyles();
  const {
    infoUpdate,
    formik,
    privacyOptions,
    setCheckStep,
    setNotificationLeave
  } = props;
  const [loading, setLoading] = React.useState<any>('');
  const [verify, setVerify] = React.useState<any>(false);

  React.useEffect(() => {
    if (setCheckStep) {
      if (
        Object.keys(formik.errors).length === 0 &&
        formik.values.visibility &&
        formik.values.salary_min &&
        formik.values.salary_max
      ) {
        setCheckStep(prev => ({ ...prev, step3: true }));
      } else {
        setCheckStep(prev => ({ ...prev, step3: false }));
      }
    }
  }, [JSON.stringify(formik.values), JSON.stringify(formik.errors)]);

  let fieldUpdate: any = [
    {
      field_type: 'typography',
      text: 'Mức lương',
      style: { fontSize: 15, width: '100%', fontWeight: 700 },
      description: 'Bắt buộc*',
      styleDescription: { fontWeight: 600 }
    },
    {
      field_type: 'current_value',
      name: 'salary_min',
      placeholder: '8000000 VNĐ',
      label: 'Từ',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      maxRows: 1
    },
    {
      field_type: 'current_value',
      name: 'salary_max',
      placeholder: '20000000 VNĐ',
      label: 'Đến',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      maxRows: 1
    },
    {
      field_type: 'autocomplete',
      name: 'visibility',
      options: privacyOptions.slice(0, 1),
      multiple: false,
      placeholder: 'Quyền riêng tư',
      descriptionLabel: 'Bắt buộc*',
      label: 'Quyền riêng tư'
    }
  ];

  return (
    <Box className={classes.root}>
      <SocialFieldUpdate
        setVerify={setVerify}
        verify={verify}
        setLoading={setLoading}
        formik={formik}
        fieldUpdate={fieldUpdate}
        infoUpdate={infoUpdate}
        spacing={0}
        setNotificationLeave={setNotificationLeave}
      />
    </Box>
  );
};

export default OtherInformation;
