import { Avatar, Box, Divider, Link, Typography } from '@mui/material';import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { sendFeedback } from 'src/apis/socialUser';
import { formatDateTimeToStringDateTime } from 'src/common/string';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import DialogComplain from 'src/components/Dialog/DialogComplain';
import { buttonColor } from 'src/constants/styles';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '60px'
  },
  infoCamplain: {
    width: '100%',
    height: 'auto',
    backgroundColor: '#fff',
    borderRadius: '10px',
    border: '4px solid #cccccc54',
    padding: '10px 20px 24px'
  },
  boxTop: {
    textAlign: 'center'
  },
  nameUser: {
    fontWeight: '500 !important',
    fontSize: '18px !important',
    padding: '10px 0px'
  },
  dateCamplain: {
    fontSize: '14px !important',
    fontWeight: '500 !important',
    color: 'red'
  }
}));

const Complain = () => {
  const classes = useStyles();
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const [openDialogConfirm, setOpenDialogConfirm] = React.useState(false);
  const [loadingConfirm, setLoadingConfirm] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const userSendFeedBack = async value => {
    try {
      let response;
      response = await sendFeedback({
        feedback_category_id: 1,
        feedback_type: 'contribution',
        description: value
      });
      if (response.status === 200) {
        setLoadingConfirm(false);
        setOpenSnackbar(true);
        setNoti({
          code: 200,
          message: 'Gửi khiếu lại thành công.'
        });
        setOpenDialogConfirm(false);
      }
    } catch (err: any) {
      setOpenSnackbar(true);
      setNoti({
        code: err.response.status,
        message: 'Xảy ra quá trình lỗi khi gửi khiếu lại.Vui lòng thử lại sau!'
      });
      setLoadingConfirm(false);
    }
  };

  return (
    <Box className={classes.root}>
      <Box sx={{ maxWidth: '600px' }}>
        <Box className={classes.infoCamplain}>
          <Box className={classes.boxTop}>
            <Avatar
              sx={{ margin: '0 auto' }}
              alt="avatar"
              src={meInfo?.avatar_media?.show_url}
            />
            <Typography className={classes.nameUser}>
              {meInfo?.display_name}
            </Typography>
            <Typography sx={{ fontWeight: '700', fontSize: '22px' }}>
              Chúng tôi đã tạm ngừng tài khoản của bạn
            </Typography>
            <Typography
              className={classes.dateCamplain}
              sx={{ textTransform: 'uppercase' }}
            >
              Vào {formatDateTimeToStringDateTime(meInfo?.suspended_at, false)}
            </Typography>
            <Typography sx={{ fontSize: '20px', paddingTop: '5px' }}>
              Tài khoản của bạn hoặc hoạt động trên đó vi phạm tiêu chuẩn cộng
              đồng của chúng tôi
            </Typography>
            <Typography
              sx={{ fontSize: '14px', color: '#333', opacity: '0.8' }}
            >
              Chúng tôi sễ ẩn tài khoản của bạn với mọi người trên Emso và bạn
              cũng không thể sử dụng tài khoản của mình
            </Typography>
          </Box>
          <Divider sx={{ margin: '10px 0px' }} />
          <Box>
            <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
              Còn 30 ngày để phản đối quyết định này
            </Typography>
            <Typography sx={{ fontWeight: '500' }}>
              Nếu bạn cho rằng việc tạm ngừng tài khoản là nhầm lẫn,chúng tôi có
              thể hướng dẫn bạn một số bước để phản đối quyết định này.
            </Typography>
            <Typography sx={{ fontWeight: '500', marginTop: '10px' }}>
              Sau khi chúng tôi xử lý tài khoản của bạn lần đầu,bạn có 30 ngày
              để phản đối ,nêu không thì tài khoản sẽ bị vô hiệu hóa vĩnh
              viễn.Bạn còn 30 ngày.
            </Typography>
            <Typography sx={{ fontWeight: '500', margin: '10px 0px' }}>
              Lưu ý: Đội ngũ xét duyệt chúng tôi hiện có ít nhân lực hơn do ảnh
              hưởng của đại dịch virus corona(COVID-19). Vì vậy,chúng tôi có thể
              không xét duyệt được mọi yêu cầu và cách chúng tôi xử lý quá trình
              xem xét lại đã thay đổi. Chúng tôi sẽ hướng dẫn một số bước để bạn
              có thể yêu cầu xem xét lại.
            </Typography>
            <Link
              href="/help"
              underline="hover"
              sx={{ fontWeight: '500', fontSize: '14px' }}
            >
              Tìm hiểu thêm về Tiêu chuẩn cộng đồng của chúng tôi
            </Link>
          </Box>
        </Box>
        <Box>
          <ButtonInherit
            label="Phản đối quyết định"
            style={{
              width: '100%',
              backgroundColor: buttonColor.backgroundColor,
              color: '#fff',
              marginTop: '20px'
            }}
            action={() => setOpenDialogConfirm(true)}
          />
        </Box>
      </Box>
      {openDialogConfirm && (
        <DialogComplain
          open={openDialogConfirm}
          handleClose={() => {
            setOpenDialogConfirm(false);
            setLoadingConfirm(false);
          }}
          title="Phản hồi quyết định"
          action={e => {
            userSendFeedBack(e);
            setLoadingConfirm(true);
          }}
          loading={loadingConfirm}
        />
      )}
      {openSnackbar && (
        <SnackbarNotification
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          code={noti?.code}
          message={noti?.message}
        />
      )}
    </Box>
  );
};

export default Complain;
