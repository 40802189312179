import { Box, Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  createUpdateEventApi,
  getEventCategories,
  getListHostOfEvent
} from 'src/apis/socialEvent.api';
import { searchFriendUser } from 'src/apis/socialUser';
import { RegText, convertISO8086toLDate } from 'src/common/string';
import DialogLocation from 'src/components/Dialog/DialogLocation';
import SocialFieldUpdate from 'src/components/SocialFieldUpdate/SocialFieldUpdate';
import {
  eventPreview,
  getEventDetailRequest
} from 'src/store/action/socialEventAction';
import CustomPrompt from 'src/components/CustomPrompt';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '5px 10px 20px 10px',
    borderRadius: 10,
    width: '100%'
  }
}));

interface EventCreateUpdate {
  infoUpdate?: any;
  createEvent?: any;
  setCreateEvent?: React.Dispatch<React.SetStateAction<any>> | any;
  updateEvent?: any;
  setUpdateEvent?: React.Dispatch<React.SetStateAction<any>> | any;
  idUpdate?: string | any;
  organization?: any;
  setCheckCreateUpdate?: React.Dispatch<React.SetStateAction<any>> | any;
  setOpenSnackBar?: React.Dispatch<React.SetStateAction<boolean>> | any;
  setNoti?: any;
}

const EventCreateUpdate: React.FC<EventCreateUpdate> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const match: any = useRouteMatch();
  let { key } = match.params;
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const eventInfo = useSelector((state: any) => state.eventReducer.info);
  const matchesCreate = useMediaQuery('(max-width:1000px)');
  const {
    infoUpdate,
    createEvent,
    setCreateEvent,
    updateEvent,
    setUpdateEvent,
    idUpdate,
    organization,
    setCheckCreateUpdate,
    setOpenSnackBar,
    setNoti
  } = props;
  const [privacyOptions, setPrivacyOptions] = React.useState([] as any);
  const [hostEventAdd, setHostEventAdd] = React.useState([] as any);
  const [loading, setLoading] = React.useState<any>('');
  const [verify, setVerify] = React.useState<any>(false);
  const [addEndEvent, setAddEndEvent] = React.useState<any>(false);
  const [openDialogUpdateLocation, setOpenDialogUpdateLocation] =
    React.useState<any>(false);
  const [currentHost, setCurrentHost] = React.useState<any>([]);
  const [locationSelected, setLocationSelected] = React.useState<any>(null);
  const [categories, setCategories] = React.useState<any>([]);
  const validationSchema = Yup.object({
    title: Yup.string()
      .test(
        'len',
        'Tên sự kiện phải có ít hơn 50 ký tự',
        (val: any) =>
          val === '' || val === null || val === undefined || val?.length < 250
      )
      .matches(RegText, 'Tên tiêu đề không được chứa ký tự đặc biệt')
      .nullable(),
    end_date: Yup.date()
      .min(
        Yup.ref('start_date'),
        'Ngày kết thúc phải lớn hơn hoặc bằng ngày bắt đầu'
      )
      .nullable(),
    start_date: Yup.date()
      .min(
        new Date().toISOString().slice(0, 10),
        'Ngày bắt đầu lớn hớn hoặc bằng ngày hôm nay'
      )
      .nullable(),
    banner: Yup.mixed().test(
      'imageDimensions',
      'Kích thước ảnh không hợp lệ',
      value => {
        if (!value) {
          return true;
        }

        if (value?.file?.file) {
          const image: any = new Image();
          image.src = value?.file?.file
            ? URL.createObjectURL(value.file.file)
            : null;

          return new Promise(resolve => {
            image.onload = () => {
              const width = image.width;
              const height = image.height;
              if (width >= 450 && height >= 150) {
                resolve(true);
              } else {
                resolve(false);
              }
            };
          });
        }

        return true;
      }
    )
  });
  const [notificationLeave, setNotificationLeave] =
    React.useState<boolean>(false);
  React.useEffect(() => {
    setPrivacyOptions([
      {
        id: 'public',
        name: 'Công khai',
        icon: 'fa-solid fa-earth-asia',
        description: 'Tất cả mọi người'
      },
      {
        id: 'private',
        name: 'Riêng tư',
        icon: 'fa-solid fa-lock',
        description: 'Chỉ những người được mời'
      },
      {
        id: 'friend',
        name: 'Bạn bè',
        icon: 'fa-solid fa-user-group',
        description: 'Bạn bè của bạn'
      }
    ]);
  }, []);
  const fetchCategories = async () => {
    let response = await getEventCategories();
    if (response.status === 200) {
      setCategories(response.data);
    }
  };

  React.useEffect(() => {
    fetchCategories();
  }, []);
  React.useEffect(() => {
    if (eventInfo?.id) {
      fetchInviteHostEvent(eventInfo.id);
    }
  }, [JSON.stringify(eventInfo)]);

  const handleCreateUpdateEvent = async (type: any) => {
    try {
      let response = await createUpdateEventApi(
        {
          banner: formik.values.banner
            ? {
                file: formik.values.banner?.file
                  ? formik.values.banner?.file.file
                  : formik.values.banner,
                id: formik.values.banner?.id ?? null,
                show_url:
                  formik.values.banner.show_url ??
                  formik.values.banner.preview_url
              }
            : null,
          title: formik.values.title || null,
          start_time: !infoUpdate
            ? `${convertISO8086toLDate(formik.values.start_date)} ${
                formik.values.start_time?.label
              }:00`
            : null,
          end_time: !infoUpdate
            ? addEndEvent &&
              `${convertISO8086toLDate(formik.values.end_date)} ${
                formik.values.end_time?.label
              }:00`
            : null,
          page_owner_id:
            type === 'create' && organization?.page_owner_id
              ? organization.page_owner_id
              : null,
          page_id:
            type === 'create' && organization?.page_owner_id
              ? organization.page_owner_id
              : null,
          visibility: formik.values.visibility.id,
          category_id: formik.values.category_id.id,
          location: formik.values.location || null,
          description: formik.values.description,
          event_type: type !== 'create' ? eventInfo?.event_type : key,
          event_host_added:
            formik.values.event_host_added
              .filter(
                (item: any) =>
                  !currentHost.some(
                    (elCurrent: any) => elCurrent.id === item.id
                  )
              )
              .map((el: any) => el?.id) || null,
          event_host_removed:
            formik.values.event_host_removed.map((el: any) => el?.id) || null
        },
        type
      );
      if (response.status === 200) {
        setNotificationLeave(false);
        if (createEvent) {
          setCreateEvent(false);
          setOpenSnackBar && setOpenSnackBar(true);
          setNoti &&
            setNoti({
              code: 200,
              message: 'Đăng sự kiện thành công'
            });
          history.push(`/event/${response.data.id}/about`);
        }
        if (updateEvent) {
          setUpdateEvent(false);
          setOpenSnackBar && setOpenSnackBar(true);
          setNoti &&
            setNoti({
              code: 200,
              message: 'Cập nhật sự kiện thành công'
            });
          dispatch(getEventDetailRequest(type));
          history.push(`/event/${type}/about`);
        }
      }
    } catch (error) {
      if (createEvent) {
        setOpenSnackBar && setOpenSnackBar(true);
        setNoti &&
          setNoti({
            code: 500,
            message: 'Đăng sự kiện không thành công'
          });
      }
      if (updateEvent) {
        setOpenSnackBar && setOpenSnackBar(true);
        setNoti &&
          setNoti({
            code: 500,
            message: 'Cập nhật sự kiện không thành công'
          });
      }
    }
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      title: '',
      start_date: null,
      start_time: null as any,
      end_date: null,
      end_time: null as any,
      visibility: null as any,
      category_id: null as any,
      banner: null as any,
      location: null,
      description: '',
      event_host_added: [],
      event_host_removed: []
    },
    onSubmit: values => {
      try {
        if (createEvent && values.title) {
          handleCreateUpdateEvent('create');
        }
        if (updateEvent && idUpdate) {
          handleCreateUpdateEvent(idUpdate);
        }
      } catch (error) {
        console.log(error);
      }
    }
  });

  const fetchInviteHostEvent = async (id: any) => {
    let response: any = await getListHostOfEvent(id, null);
    if (response.status === 200) {
      let dataDefault: any = response.data
        .filter(item => item?.status !== 'rejected')
        .map((el: any) => ({
          id: el?.target_account?.id,
          display_name: el?.target_account?.display_name,
          avatar: el?.target_account?.avatar_media?.show_url
            ? el?.target_account?.avatar_media?.show_url
            : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png',
          status: el?.status,
          description:
            el?.status === 'pending'
              ? 'Đang chờ'
              : el?.status === 'rejected'
              ? 'Đã từ chối'
              : el?.status === 'approved'
              ? 'Đã đồng ý'
              : null
        }));
      setCurrentHost(dataDefault);
      formik.setFieldValue('event_host_added', dataDefault);
    }
  };

  React.useEffect(() => {
    formik.handleSubmit();
  }, [createEvent, updateEvent, idUpdate]);
  React.useEffect(() => {
    if (!infoUpdate) {
      dispatch(
        eventPreview({
          title: formik.values.title,
          start_date: formik.values.start_date,
          start_time: formik.values.start_time?.label,
          end_date: formik.values.end_date,
          end_time: formik.values.end_time?.label,
          visibility: formik.values.visibility,
          category_id: formik.values.category_id,
          banner: formik.values.banner?.file,
          location: formik.values.location,
          description: formik.values.description,
          event_host_added: formik.values.event_host_added,
          event_host_removed: formik.values.event_host_removed,
          pageInfo: organization?.page_owner_id ? { ...organization } : null
        })
      );
    }
    if (setCheckCreateUpdate) {
      if (
        !Object.keys(formik.errors).length &&
        formik.values.title &&
        formik.values.start_date &&
        formik.values.start_time &&
        formik.values.visibility &&
        formik.values.banner &&
        formik.values.description &&
        formik.values.category_id
      ) {
        setCheckCreateUpdate(true);
      } else {
        setCheckCreateUpdate(false);
      }
    }
  }, [
    JSON.stringify(formik.values),
    JSON.stringify(formik.errors),
    JSON.stringify(organization)
  ]);

  const convertISO8601toTime = (ISOTime: any, convertTo: any) => {
    var d = new Date(ISOTime),
      year = d.getFullYear(),
      month = d.getMonth() + 1,
      day = d.getDate(),
      hour = d.getHours(),
      minute = d.getMinutes();
    let secondTime: any = hour * 3600 + minute * 60;
    switch (convertTo) {
      case 'second':
        return {
          id: secondTime,
          value: secondTime,
          label: `${
            (hour < 10 ? '0' + hour + ':' : hour + ':') +
            (minute < 10 ? '0' + minute : minute)
          }`
        };
      case 'data_object':
        return d;
      case 'date':
        return `${year}/${month}/${day}`;
      default:
        break;
    }
  };

  React.useEffect(() => {
    if (eventInfo) {
      let tempData: any = {
        title: eventInfo?.title,
        start_date: eventInfo?.start_time
          ? convertISO8601toTime(eventInfo.start_time, 'data_object')
          : null,
        start_time: eventInfo?.start_time
          ? convertISO8601toTime(eventInfo.start_time, 'second')
          : null,
        end_date: eventInfo?.end_time
          ? convertISO8601toTime(eventInfo.end_time, 'data_object')
          : null,
        end_time: eventInfo?.end_time
          ? convertISO8601toTime(eventInfo.end_time, 'second')
          : null,
        visibility: privacyOptions?.find(
          (el: any) => el.id === eventInfo?.visibility
        ),
        category_id: eventInfo.category,
        banner: eventInfo.banner,
        location: null,
        description: eventInfo?.description,
        event_host_added: eventInfo?.event_host_added || [],
        event_host_removed: eventInfo?.event_host_removed || []
      };
      formik.setValues(tempData);
      if (eventInfo.end_time) {
        setAddEndEvent(true);
      }
    } else {
      formik.setFieldValue('visibility', {
        id: 'public',
        name: 'Công khai',
        icon: 'fa-solid fa-earth-asia',
        description: 'Tất cả mọi người'
      });
    }
  }, [JSON.stringify(eventInfo), JSON.stringify(privacyOptions)]);

  const fetchHostEvent = async (keyword: any) => {
    let response = await searchFriendUser(meInfo?.id, {
      keyword: keyword === '' ? ' ' : keyword
    });
    if (response.status === 200) {
      setHostEventAdd(
        response.data?.data.map((el: any) => ({
          id: el.id,
          display_name: el.display_name,
          avatar: el.avatar_media?.show_url
            ? el.avatar_media?.show_url
            : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png',
          status: 'pending',
          description: 'Đang chờ'
        }))
      );
    }
  };

  React.useEffect(() => {
    if (eventInfo?.location) {
      setLocationSelected({ location: eventInfo.location });
    }
  }, [JSON.stringify(eventInfo?.location)]);

  React.useEffect(() => {
    if (locationSelected) {
      formik.setFieldValue('location', locationSelected);
    }
  }, [JSON.stringify(locationSelected)]);

  let day = new Date(),
    hour = day.getHours(),
    minute = day.getMinutes();

  let fieldUpdate: any = [
    {
      field_type: 'textField',
      name: 'title',
      placeholder: 'Tên sự kiện',
      label: 'Tên sự kiện',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      maxRows: 3,
      defaultValue: formik.values.title
    },
    {
      field_type: 'typography',
      text: 'Thời gian diễn ra sự kiện',
      style: { fontSize: 15, width: '100%', fontWeight: 700 },
      description: 'Bắt buộc*',
      styleDescription: { fontWeight: 600 }
    },
    {
      field_type: 'dateTime',
      name: 'start_date',
      placeholder: 'Ngày bắt đầu',
      label: 'Ngày bắt đầu',
      descriptionLabel: '*',
      type: 'date',
      minDay: day,
      maxDay: addEndEvent ? formik.values.end_date : undefined,
      span: 6
    },
    {
      field_type: 'dateTime',
      name: 'start_time',
      placeholder: 'Giờ bắt đầu',
      label: 'Giờ bắt đầu',
      title: <div></div>,
      type: 'time',
      minTime:
        convertISO8601toTime(day, 'date') ===
        convertISO8601toTime(formik.values.start_date, 'date')
          ? hour * 3600 + minute * 60 + 600
          : null,
      maxTime:
        convertISO8601toTime(formik.values.start_date, 'date') ===
          convertISO8601toTime(formik.values.end_date, 'date') &&
        formik.values.end_time
          ? formik.values.end_time?.value
          : null,
      span: 6
    },
    {
      field_type: addEndEvent ? 'dateTime' : null,
      name: 'end_date',
      placeholder: 'Ngày kết thúc',
      label: 'Ngày kết thúc',
      type: 'date',
      minDay: addEndEvent
        ? formik.values.start_date
          ? formik.values.start_date && day
          : day
        : undefined,
      span: 6
    },
    {
      field_type: addEndEvent ? 'dateTime' : null,
      name: 'end_time',
      placeholder: 'Giờ kết thúc',
      label: 'Giờ kết thúc',
      type: 'time',
      minTime: addEndEvent
        ? convertISO8601toTime(formik.values.start_date, 'date') ===
          convertISO8601toTime(formik.values.end_date, 'date')
          ? formik.values.start_time?.value + 600
          : null
        : null,
      span: 6
    },
    {
      field_type: 'button_add',
      label: 'Ngày và giờ kết thúc',
      icon: !addEndEvent ? (
        <i className="fa-solid fa-plus" style={{ margin: '5px' }}></i>
      ) : (
        <i className="fa-solid fa-hyphen" style={{ margin: '5px' }}></i>
      ),
      action: () => {
        if (addEndEvent === false) {
          setAddEndEvent(true);
        } else {
          setAddEndEvent(false);
          formik.setFieldValue('end_date', null);
          formik.setFieldValue('end_time', null);
        }
      }
    },
    {
      field_type: 'autocomplete',
      name: 'visibility',
      options: privacyOptions,
      multiple: false,
      setOptions: setPrivacyOptions,
      placeholder: 'Quyền riêng tư',
      descriptionLabel: 'Bắt buộc*',
      label: 'Quyền riêng tư',
      disabled: eventInfo?.visibility ? true : false
    },
    {
      field_type: 'autocomplete',
      name: 'category_id',
      options: categories,
      multiple: false,
      setOptions: setCategories,
      placeholder: 'Hạng mục',
      descriptionLabel: 'Bắt buộc*',
      label: 'Hạng mục',
      typeCategory: 'category'
    },
    {
      field_type: locationSelected ? 'map' : 'autocomplete',
      name: 'location',
      label: 'Vị trí',
      placeholder: 'Vị trí',
      descriptionTitle:
        'Thêm vị trí thực để mọi người tham gia sự kiện của bạn.',
      endButton: {
        icon: 'fa-solid fa-location-dot'
      },
      disabled: true,
      action: () => setOpenDialogUpdateLocation(true),
      style: { margin: '8px 0px 0px' },
      styleLabel: { margin: '8px 0px 0px' }
    },
    {
      field_type: locationSelected ? 'button_add' : null,
      name: 'remove_location',
      label: 'Chọn lại vị trí sự kiện',
      action: () => {
        setLocationSelected(null);
        formik.setFieldValue('location', null);
      }
    },
    {
      field_type: 'textField',
      name: 'description',
      label: 'Mô tả',
      descriptionLabel: 'Bắt buộc*',
      placeholder: 'Thêm mô tả ngắn',
      rows: 4,
      descriptionTitle:
        'Cung cấp thêm thông tin về sự kiện để khách mời biết nên mong đợi điều gì.',
      onChange: true,
      defaultValue: formik.values.description
    },
    {
      field_type: 'upload_media',
      name: 'banner',
      label: 'Ảnh bìa',
      type: 'file_media',
      height: 160,
      descriptionLabel: 'Bắt buộc*',
      default_media: eventInfo?.banner
    },
    {
      field_type: 'autocomplete',
      name: 'event_host_added',
      options: hostEventAdd,
      multiple: true,
      fetchData: fetchHostEvent,
      search: true,
      placeholder: 'Người đồng tổ chức',
      label: 'Người đồng tổ chức',
      renderListTag: true,
      distributeStatus: true
    }
  ];
  const handleOnChangeWarning = (event: React.FormEvent) => {
    const formData: any = new FormData(event.currentTarget as HTMLFormElement);
    const values = Array.from(formData.values());
    const changedFields = values.filter(
      (value: any) => value.length || (value as File).size
    );
    setNotificationLeave(changedFields.length > 0);
  };
  // const handleKeyDown = event => {
  //   if (formik.values.title.length >= 100 && event.key !== 'Backspace') {
  //     event.preventDefault();
  //     formik.setFieldError('title', 'Tiêu đề chỉ được nhập tối đa 100 ký tự');
  //   }
  // };
  // const handlePaste = event => {
  //   const pastedData = event.clipboardData.getData('text/plain');
  //   if (pastedData.length + formik.values.title.length > 100) {
  //     event.preventDefault();
  //     formik.setFieldError('title', 'Tiêu đề chỉ được nhập tối đa 100 ký tự');
  //   }
  // };
  return (
    <Box
      className={classes.root}
      sx={{
        width: infoUpdate || matchesCreate ? '100%' : '350px'
      }}
    >
      <CustomPrompt
        when={notificationLeave}
        navigate={path => {
          history.push(path);
        }}
        shouldBlockNavigation={() => {
          return notificationLeave;
        }}
        title={'Rời khỏi trang?'}
        content={'Thay đổi chưa lưu của bạn sẽ bị mất nếu bạn rời khỏi trang.'}
        optionDialog={2}
        labelButton={['Ở lại', 'Rời khỏi']}
      />
      <form onChange={handleOnChangeWarning}>
        <SocialFieldUpdate
          setVerify={setVerify}
          verify={verify}
          setLoading={setLoading}
          formik={formik}
          fieldUpdate={fieldUpdate}
          infoUpdate={infoUpdate}
          spacing={0}
          locationSelected={locationSelected}
          currentHostAdd={currentHost}
          setNotificationLeave={setNotificationLeave}
        />
      </form>
      <DialogLocation
        open={openDialogUpdateLocation}
        handleClose={() => {
          setOpenDialogUpdateLocation(false);
        }}
        locationSelected={locationSelected}
        setLocationSelected={setLocationSelected}
        type={'event'}
      />
    </Box>
  );
};

export default EventCreateUpdate;
