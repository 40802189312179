import * as React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Theme,
  styled,
  Avatar,
  FormGroup,
  FormControlLabel,
  Divider,
  Radio,
  Checkbox
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InputText from 'src/components/Input/SocialInput/InputText';

import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import 'react-perfect-scrollbar/dist/css/styles.css';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { getGroupDetailReq } from 'src/store/action/socialGroupAction';
import { buttonColor, buttonStyle, modalBackdrop } from 'src/constants/styles';
import { cloneDeep } from 'lodash';
import _ from 'lodash';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  divCreateRule: {
    marginBottom: 20
  },
  subText: { fontSize: '14px !important', color: '#65676B', fontWeight: 500 }
}));

interface Answer {
  question_id?: any;
  answer?: any;
}

interface propsDialog {
  open: boolean;
  handleClose: () => void;
  action: (data) => void;
  group?: any;
  dataGroup?: any;
}

export default function CustomizedDialogs(props: propsDialog) {
  const classes = useStyles();
  const { open, handleClose, action, group, dataGroup } = props;
  const dispatch = useDispatch();
  const memberQuestions = useSelector(
    (state: any) => state.groupReducer.memberQuestions
  );

  const groupSelected = useSelector(
    (state: any) => state.groupReducer.groupSelected
  );

  const [answersCheckbox, setAnswersCheckbox] = React.useState<Answer[]>([]);
  const [answerChoice, setAnswerChoice] = React.useState<Answer[]>([]);
  const [text, setText] = React.useState<Answer[]>([]);

  const [disableButton, setDisableButton] = React.useState<any>(true);

  React.useEffect(() => {
    const valueDisableList = [...answersCheckbox, ...answerChoice, ...text];

    setDisableButton(valueDisableList.length === 0);
  }, [answerChoice, answersCheckbox, text]);

  const renderTypeGroup = type => {
    let result: any = null;
    switch (type) {
      case false:
        result = (
          <React.Fragment>
            <Typography className={classes.subText}>
              <i className="far fa-globe-asia"></i> Nhóm công khai
            </Typography>
          </React.Fragment>
        );
        break;
      case true:
        result = (
          <React.Fragment>
            <Typography className={classes.subText}>
              <i className="fas fa-lock"></i> Nhóm riêng tư
            </Typography>
          </React.Fragment>
        );
        break;
    }
    return result;
  };

  const handleAnswer = React.useCallback(
    (type: string, id: number, text: string) => {
      if (type === 'checkboxes') {
        const updatedAnswers = [...answersCheckbox];
        const existingAnswer = updatedAnswers.find(
          answer => answer.question_id === id
        );

        if (existingAnswer) {
          existingAnswer.answer = [text]; // Chỉ cho phép chọn một tùy chọn duy nhất
        } else {
          updatedAnswers.push({ question_id: id, answer: [text] });
        }

        setAnswersCheckbox(updatedAnswers);
      } else if (type === 'multiple_choice') {
        setAnswerChoice(prevAnswerChoice => {
          const updatedAnswerChoice: any = [...prevAnswerChoice];
          const existingAnswerIndex: any = updatedAnswerChoice.findIndex(
            answer => answer.question_id === id
          );

          // Kiểm tra xem câu trả lời đã được chọn trước đó hay chưa
          if (existingAnswerIndex >= 0) {
            // Kiểm tra xem câu trả lời đã được chọn hay chưa
            const isAnswerSelected =
              updatedAnswerChoice[existingAnswerIndex].answer.includes(text);

            // Nếu đã được chọn, loại bỏ khỏi mảng answerChoice
            if (isAnswerSelected) {
              updatedAnswerChoice[existingAnswerIndex].answer =
                updatedAnswerChoice[existingAnswerIndex].answer.filter(
                  selectedAnswer => selectedAnswer !== text
                );

              // Kiểm tra xem mảng câu trả lời đã rỗng hay chưa
              if (
                updatedAnswerChoice[existingAnswerIndex].answer.length === 0
              ) {
                updatedAnswerChoice.splice(existingAnswerIndex, 1);
              }
            } else {
              // Nếu chưa được chọn, thêm vào mảng answerChoice
              updatedAnswerChoice[existingAnswerIndex].answer.push(text);
            }
          } else {
            // Nếu câu hỏi chưa tồn tại trong mảng answerChoice, thêm mới
            updatedAnswerChoice.push({
              question_id: id,
              answer: [text]
            });
          }

          return updatedAnswerChoice;
        });
      }
    },
    [answersCheckbox]
  );

  const updateAnswer = React.useCallback(
    _.debounce((question_id: number, newAnswer: string): void => {
      const updateAnswerList: Answer[] = cloneDeep(text);

      const existingAnswerIndex = updateAnswerList.findIndex(
        answer => answer.question_id === question_id
      );

      if (newAnswer.trim() === '') {
        if (existingAnswerIndex >= 0) {
          updateAnswerList.splice(existingAnswerIndex, 1);
        }
      } else if (existingAnswerIndex >= 0) {
        updateAnswerList[existingAnswerIndex].answer = [newAnswer];
      } else {
        updateAnswerList.push({ question_id, answer: [newAnswer] });
      }

      setText(updateAnswerList);
    }, 200),
    [text]
  );

  const handleAction = () => {
    let data: any = {
      answers: []
    };

    if (answersCheckbox.length > 0) {
      data = { answers: [...data.answers, ...answersCheckbox] };
    }
    if (answerChoice.length > 0) {
      data = { answers: [...data.answers, ...answerChoice] };
    }
    if (text.length > 0) {
      data = { answers: [...data.answers, ...text] };
    }

    data.answers.length && action(data);
  };

  React.useEffect(() => {
    if (group && group.id) {
      dispatch(getGroupDetailReq(group.id, ''));
    }
  }, [group?.id]);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        BackdropProps={modalBackdrop}
        scroll="body"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{ fontWeight: 'bold', fontSize: 21 }}
            textAlign={'center'}
          >
            Trả lời câu hỏi
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div className={classes.wrapHeader}>
            <div>
              <Avatar
                variant="rounded"
                src={groupSelected?.banner?.url ?? dataGroup?.banner?.url}
              />
            </div>
            <div style={{ marginLeft: 15 }}>
              <Typography style={{ fontSize: 17, fontWeight: 500 }}>
                {groupSelected.title ?? dataGroup?.title}
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {renderTypeGroup(
                  groupSelected.is_private ?? dataGroup?.is_private
                )}
                &nbsp;-&nbsp;
                <Typography className={classes.subText}>
                  {groupSelected.member_count ?? dataGroup?.member_count} thành
                  viên
                </Typography>
              </div>
            </div>
          </div>

          <div style={{ margin: '12px 0px' }}>
            <div
              style={{
                backgroundColor: 'rgba(0,0,0,0.05)',
                padding: '8px 12px',
                borderRadius: 8
              }}
            >
              <Typography>
                Yêu cầu tham gia của bạn đang chờ phê duyệt. Hãy trả lời những
                câu hỏi sau của quản trị viên nhóm để họ có thể xem xét yêu cầu
                tham gia của bạn. Câu trả lời của bạn sẽ chỉ hiển thị với quản
                trị viên và người kiểm duyệt.
              </Typography>
            </div>
            <div style={{ height: 12 }}></div>
            {memberQuestions?.map((item: any, index: any) => (
              <div key={index} className={classes.divCreateRule}>
                <Typography style={{ fontWeight: 500, fontSize: 17 }}>
                  Câu hỏi {index + 1}. {item.question_text}
                </Typography>
                <FormGroup>
                  {item.question_type === 'multiple_choice' ? (
                    item?.options?.map((el: any, i) => (
                      <React.Fragment key={i}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={answerChoice?.answer?.includes(el)}
                              onChange={() =>
                                handleAnswer('multiple_choice', item.id, el)
                              }
                            />
                          }
                          label={el}
                        />
                        {i + 1 !== item.options.length ? <Divider /> : null}
                      </React.Fragment>
                    ))
                  ) : item.question_type === 'checkboxes' ? (
                    item?.options?.map((el: any, i) => (
                      <React.Fragment key={i}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={answersCheckbox.some(
                                answer =>
                                  answer.question_id === item.id &&
                                  answer.answer.includes(el)
                              )}
                              onChange={() =>
                                handleAnswer('checkboxes', item.id, el)
                              }
                            />
                          }
                          label={el}
                        />
                        {i + 1 !== item.options.length ? <Divider /> : null}
                      </React.Fragment>
                    ))
                  ) : (
                    <InputText
                      key={item.id}
                      multiline
                      rows={3}
                      fullWidth
                      placeholder="Nhập câu trả lời..."
                      action={(value: string) => updateAnswer(item.id, value)}
                    />
                  )}
                </FormGroup>
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <Typography style={{ fontSize: 13, color: '#65676b' }}>
                Không nhập mật khẩu hoặc thông tin nhạy cảm khác tại đây, ngay
                cả khi quản trị viên nhóm{' '}
                {groupSelected.title ?? dataGroup?.title} yêu cầu.
              </Typography>
            </div>
            <div style={{ width: 140, display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={handleClose}
                style={{ ...buttonStyle, textTransform: 'none' }}
              >
                Hủy
              </Button>
              <ButtonInherit
                disabled={disableButton}
                action={handleAction}
                color={buttonColor.backgroundColor}
                label={'Gửi'}
              />
            </div>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
