import { cloneDeep } from 'lodash';
import * as types from 'src/constants/store/livestream';
const _ = require('lodash');
const initialState = {
  inforLiveStream: {
    connectOBS: false,
    isLiveStream: false,
    videoUUID: null,
    textContentLive: null
  },
  type: null,
  openDialog: false,
  liveStreamSocket: {}
};

export const socialLiveStreamReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_INFOR_LIVESTREAM:
      let { data } = action.payload;
      return {
        ...state,
        inforLiveStream: { ...state.inforLiveStream, ...data },
        liveStreamSocket: data?.post ? data.post : null
      };

    case types.RESET_LIVESTREAM:
      return {
        ...state,
        inforLiveStream: {
          connectOBS: false,
          isLiveStream: false,
          shortUUID: null,
          textContentLive: null
        }
      };

    case types.TYPE_LIVESTREAM:
      return {
        ...state,
        type: action.payload
      };

    case types.OPEN_DIALOG_POST:
      const type = action.payload;
      return {
        ...state,
        openDialog: type === 'open' ? true : false
      };

    case types.ADD_LIVESTREAM_SOCKET: {
      let post = action.payload;
      return {
        ...state,
        liveStreamSocket: post
      };
    }

    case types.UPDATE_USER_JOIN_LIVESTREAM: {
      let data = action.payload;

      let newState = _.cloneDeep(state);

      if (newState.liveStreamSocket.id === data.statusId) {
        newState.liveStreamSocket['userJoin'] = data.data;
      }
      return newState;
    }

    case types.FOLLOW_PAGE_LIVESTREAM: {
      let data = action.payload;
      let newState = _.cloneDeep(state);
      if (data?.account) {
        newState.liveStreamSocket['follow'] = data.account;
      }
      return newState;
    }

    case types.REACTIONS_POST_LIVESTREAM: {
      let { data } = action.payload;
      return {
        ...state,
        liveStreamSocket: data
      };
    }

    case types.UPDATE_REACTIONS_LIVESTREAM: {
      let { data } = action.payload;
      const newState = cloneDeep(state);
      const dataLive: any = newState.liveStreamSocket;

      return {
        ...newState,
        liveStreamSocket: {
          ...dataLive,
          favourites: [
            {
              id: data.id,
              custom_vote_type: data.custom_vote_type,
              account: data.account,
              page: data.page,
              status_id: data.status.id
            },
            ...dataLive.favourites
          ],
          favourites_count: data.status.favourites_count,
          reactions: data.status.reactions
        }
      };
    }

    case types.UPDATE_EYE_LIVESTREAM: {
      let count = action.payload;
      let newState = _.cloneDeep(state);
      if (newState.liveStreamSocket) {
        newState.liveStreamSocket['eye'] = count;
      }
      return newState;
    }

    case types.CREATE_NEW_COMMENT_LIVESTREAM: {
      let data = action.payload;

      let newState = _.cloneDeep(state);
      if (newState.liveStreamSocket.id === data.in_reply_to_id) {
        newState.liveStreamSocket['newComment'] = data;
      }

      return newState;
    }

    case types.CREATE_NEW_COMMENT: {
      let data = action.payload.data;

      let newState = _.cloneDeep(state);

      let status = newState?.activities?.find(
        (el: any) => el.id === data.in_reply_to_id
      );

      if (status) {
        status['newComment'] = data;
      }
      return newState;
    }

    case types.UPDATE_TYPING_COMMENT: {
      let data = action.payload.data;
      let newState = _.cloneDeep(state);
      let status = newState.activities.find((el: any) => el.id === data.id);
      if (status) {
        status['isTyping'] = data.isTyping;
      }
      return newState;
    }

    case types.RESET_LIVESTREAM_SOCKET: {
      return {
        ...state,
        liveStreamSocket: {}
      };
    }

    default:
      return state;
  }
};
