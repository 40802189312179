import { Autocomplete, Chip, FormControl, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { findIndex } from 'lodash';
import React from 'react';

const useStyles = makeStyles(
  (theme: Theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: '100%'
    }
  }),
  { index: 1 }
);

export default function SelectAutocomplete(props) {
  const classes = useStyles();
  const {
    formik,
    field,
    label,
    options,
    variant,
    disabled,
    multiple,
    enableClear,
    fixedOptions,
    required,
    disabledOptions = [],
    setNotificationLeave
  } = props;
  return (
    <>
      <FormControl className={classes.formControl}>
        <Autocomplete
          disabled={disabled}
          multiple={multiple}
          limitTags={5}
          disableClearable={!enableClear}
          size="small"
          value={formik.values[field]}
          id={field}
          componentName={field}
          onChange={(_, value) => {
            formik.setFieldValue(field, value);
            setNotificationLeave && setNotificationLeave(true);
          }}
          options={options.map(option => option.id)}
          getOptionLabel={(optionId: any) =>
            options.filter(option => option.id === optionId)[0]?.value
          }
          getOptionDisabled={(optionId: any) => {
            return disabledOptions.includes(optionId);
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((optionId, index) => (
              <Chip
                label={
                  options.filter(option => option.id === optionId)[0]?.value
                }
                {...getTagProps({ index })}
                disabled={
                  findIndex(
                    fixedOptions || [],
                    (option: any) => option.id === optionId
                  ) !== -1
                }
              />
            ))
          }
          filterSelectedOptions
          renderInput={params => (
            <TextField
              required={required}
              {...params}
              variant={variant}
              label={label}
              error={formik.touched[field] && Boolean(formik.errors[field])}
              helperText={formik.touched[field] && formik.errors[field]}
            />
          )}
        />
      </FormControl>
    </>
  );
}
