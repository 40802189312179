import React from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton, TextField, Theme } from '@mui/material';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
const useStyles = makeStyles((theme: Theme) => ({
  inputSearch: {
    width: '100%'
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
    },
    '& fieldset': {
      display: 'none'
    },
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
    },
    backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C',
    borderRadius: '30px !important',
    height: 35,
    padding: '7px !important',
    margin: 'auto'
  },
  input: {
    '&::placeholder': {
      fontSize: 15
    }
  },
  notchedOutline: {
    border: `1px solid ${theme.palette.myBackgroundColor?.main} !important`
  },
  multilineColor: {
    fontSize: 12
  }
}));

const InputSearch = props => {
  const classes = useStyles();
  const {
    label,
    action,
    keyword,
    setKeyword,
    styleInput,
    actionBlur,
    actionFocus,
    noAutoFocus,
    handleKeywordChange
  } = props;

  return (
    <TextField
      classes={{ root: classes.inputSearch }}
      value={keyword}
      style={{
        ...styleInput,
        fontFamily: 'Segoe UI, Roboto, Helvetica, Arial, sans-serif'
      }}
      size="small"
      fullWidth
      autoFocus={noAutoFocus ? false : true}
      variant="outlined"
      placeholder={label}
      InputLabelProps={{ shrink: false }}
      InputProps={{
        startAdornment: (
          <i
            style={{
              marginRight: 5,
              padding: 5,
              fontWeight: 500,
              color: '#7b7b7b'
            }}
            className="fa-light fa-magnifying-glass"
          ></i>
        ),
        endAdornment:
          keyword?.length > 0 ? (
            <IconButton
              aria-label="deleteKeyWord"
              onClick={() => {
                setKeyword('');
                handleKeywordChange && handleKeywordChange('');
              }}
            >
              <CloseIcon sx={{ width: '15px', height: '15px' }} />
            </IconButton>
          ) : null,
        classes: {
          root: classes.cssOutlinedInput,
          notchedOutline: classes.notchedOutline,
          input: classes.input
        },
        className: classes.multilineColor
      }}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          action && action();
        }
      }}
      onChange={event => {
        setKeyword(event.target.value);
        handleKeywordChange && handleKeywordChange(event.target.value);
      }}
      onFocus={() => actionFocus && actionFocus()}
      onBlur={() => actionBlur && actionBlur()}
    />
  );
};

export default InputSearch;
