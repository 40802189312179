import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';

import SocialFieldUpdate from 'src/components/SocialFieldUpdate/SocialFieldUpdate';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '5px 10px -10px 10px',
    borderRadius: 10,
    width: '100%'
  }
}));

interface BasicProjectInfomation {
  infoUpdate?: any;
  categories?: any;
  fetchCategories?: any;
  formik?: any;
  setCheckStep?: React.Dispatch<React.SetStateAction<any>> | any;
  hostGrowAdd?: any;
  growInfo?: any;
  fetchHostGrow?: any;
  imageBanner?: any;
  setImageBanner?: React.Dispatch<React.SetStateAction<any>> | any;
  isUpdateGrow?: string | any;
  currentListHostProject?: any;
  setNotificationLeave?: React.Dispatch<React.SetStateAction<any>> | any;
}

const BasicProjectInfomation: React.FC<BasicProjectInfomation> = props => {
  const classes = useStyles();
  const {
    infoUpdate,
    categories,
    fetchCategories,
    formik,
    setCheckStep,
    hostGrowAdd,
    growInfo,
    fetchHostGrow,
    imageBanner,
    setImageBanner,
    isUpdateGrow,
    currentListHostProject,
    setNotificationLeave
  } = props;
  const [loading, setLoading] = React.useState<any>('');
  const [verify, setVerify] = React.useState<any>(false);
  React.useEffect(() => {
    if (setCheckStep) {
      if (
        isUpdateGrow
          ? Object.keys(formik.errors).length === 0
          : Object.keys(formik.errors).length < 2 &&
            formik.values.title &&
            formik.values.start_date &&
            formik.values.due_date &&
            formik.values.target_value &&
            formik.values.category_id &&
            formik.values.banner
      ) {
        setCheckStep(prev => ({ ...prev, step1: true }));
      } else {
        setCheckStep(prev => ({ ...prev, step1: false }));
      }
    }
  }, [JSON.stringify(formik.values), JSON.stringify(formik.errors)]);

  let day = new Date();
  function getMaxDayDueDate(startDate: any) {
    let currentDate = new Date();
    let checkDate = startDate ? startDate : currentDate;
    let futureDate = new Date(
      checkDate.getFullYear(),
      checkDate.getMonth() + 12,
      checkDate.getDate()
    );
    return futureDate;
  }
  let fieldUpdate: any = [
    {
      field_type: 'textField',
      name: 'title',
      placeholder: 'Tên dự án',
      label: 'Tên dự án',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      maxRows: 3,
      defaultValue: formik.values.title
    },
    {
      field_type: 'typography',
      text: 'Thời gian gọi vốn ',
      maxTimeNoti: 'Ngày kết thúc tối đa 12 tháng tính từ ngày bắt đầu',
      style: { fontSize: 15, width: '100%', fontWeight: 700 },
      description: 'Bắt buộc*',
      styleDescription: { fontWeight: 600 }
    },
    {
      field_type: 'dateTime',
      name: 'start_date',
      placeholder: 'Ngày bắt đầu',
      label: 'Ngày bắt đầu',
      descriptionLabel: '*',
      type: 'date',
      minDay: day.setDate(day.getDate() + 1),
      maxDay: formik.values.due_date,
      span: 6
    },
    {
      field_type: 'dateTime',
      name: 'due_date',
      placeholder: 'Ngày kết thúc',
      label: 'Ngày kết thúc',
      type: 'date',
      minDay: formik.values.start_date
        ? formik.values.start_date
        : day.setDate(day.getDate()),
      maxDay: getMaxDayDueDate(formik.values.start_date),
      span: 6
    },
    {
      field_type: 'current_value',
      name: 'target_value',
      placeholder: 'Số vốn kêu gọi',
      label: 'Số vốn kêu gọi',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      maxRows: 1
    },
    {
      field_type: 'upload_media',
      name: 'banner',
      label: 'Ảnh bìa',
      type: 'file_media',
      height: 160,
      descriptionLabel: 'Bắt buộc*',
      default_media: growInfo?.banner ? growInfo?.banner : formik.values.banner
    },
    {
      field_type: 'autocomplete',
      name: 'category_id',
      options: categories,
      multiple: false,
      setOptions: fetchCategories,
      placeholder: 'Nhấn để chọn',
      descriptionLabel: 'Bắt buộc*',
      label: 'Danh mục',
      renderListTag: true,
      typeCategory: 'category'
    },
    {
      field_type: 'autocomplete',
      name: 'project_host_added',
      options: hostGrowAdd,
      multiple: true,
      fetchData: fetchHostGrow,
      search: true,
      placeholder: 'Co-Founder',
      label: 'Co-Founder',
      renderListTag: true,
      distributeStatus: true
    }
  ];

  return (
    <Box className={classes.root}>
      <SocialFieldUpdate
        setVerify={setVerify}
        verify={verify}
        setLoading={setLoading}
        formik={formik}
        fieldUpdate={fieldUpdate}
        infoUpdate={infoUpdate}
        spacing={0}
        currenHostAddProject={currentListHostProject}
        imageBanner={imageBanner}
        setImageBanner={setImageBanner}
        setNotificationLeave={setNotificationLeave}
      />
    </Box>
  );
};

export default BasicProjectInfomation;
