import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles, useTheme } from '@mui/styles';
import { debounce } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { buttonColor, modalBackdrop } from 'src/constants/styles';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const useStyles = makeStyles({
  styleInput: {
    width: '100%',
    margin: '24px 0px 10px !important',
    'MuiInputBase-root': {
      height: '65px '
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: buttonColor.backgroundColor
      }
    }
  }
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '10px 16px 0px !important'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    paddingBottom: '40px !important',
    justifyContent: 'center !important'
  },
  '& .MuiPaper-root': {
    maxWidth: '450px !important',
    borderRadius: '10px'
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
};

export default function CustomizedDialogs(props) {
  const { open, action, loading, handleClose, setPassword } = props;
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const classes = useStyles();
  const theme: any = useTheme();

  const handleChangeText = debounce(e => {
    setPassword(e.target.value);
  }, 200);
  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        onClose={handleClose}
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle id="customized-dialog-title">
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20 }}
            textAlign={'center'}
          >
            Truy cập vào tính năng
          </Typography>
        </BootstrapDialogTitle>
        <Divider />
        <DialogContent>
          <Typography style={{ fontSize: '16px', fontWeight: '700' }}>
            Vì lý do bảo mật, vui lòng nhập lại mật khẩu của bạn để tiếp tục
          </Typography>

          <TextField
            id="outlined-basic"
            type={passwordVisible ? 'text' : 'password'}
            label="Nhập mật khẩu"
            variant="outlined"
            autoComplete="new-password"
            className={classes.styleInput}
            onChange={e => handleChangeText(e)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                action();
              }
            }}
          />
          <Box
            onClick={() => {
              setPasswordVisible(!passwordVisible);
            }}
            sx={{
              position: 'absolute',
              right: '26px',
              top: '162px',
              cursor: 'pointer'
            }}
          >
            {passwordVisible ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
          </Box>
        </DialogContent>
        <DialogActions>
          <ButtonInherit
            action={() => {
              handleClose();
            }}
            style={{
              textTransform: 'none',
              width: '140px',
              height: '36px',
              borderRadius: '12px',
              color: '#333'
            }}
            color={'#e4e6eb'}
            label="Hủy"
          />
          <ButtonInherit
            action={() => {
              action();
            }}
            style={{
              fontSize: '14px',
              width: '140px',
              borderRadius: '12px',
              marginRight: '30px',
              color: 'white',
              backgroundColor: buttonColor.backgroundColor
            }}
            label="Truy cập"
            loading={loading}
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
