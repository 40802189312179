import React, { useEffect } from 'react';
import {
  Theme,
  Autocomplete,
  TextField,
  Box,
  TextFieldProps,
  OutlinedInputProps,
  InputAdornment
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { cloneDeep } from 'lodash';
import { makeStyles } from '@mui/styles';
const RedditTextField = styled((props: TextFieldProps) => (
  <TextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : 'transparent',
    padding: '16px 8px 2px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color'
      // 'box-shadow'
    ]),
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#4f4f4f'
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#4f4f4f',
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    },
    '& .MuiFilledInput-input': {
      padding: '8px 4px'
    }
  },

  '& .MuiFormLabel-root': {
    marginLeft: '37px'
  }
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiAutocomplete-endAdornment': {
      top: '20px'
    }
  }
}));

function AutocompleteTimeOfDay(props) {
  const {
    formik,
    name,
    placeholder,
    styleTextField,
    dayId,
    keyField,
    type,
    label,
    minTime,
    maxTime,
    time,
    setTime
  } = props;
  const [options, setOptions] = React.useState<any>([]);
  const classes = useStyles();
  useEffect(() => {
    let listTime: any = [];

    for (var i = 0; i < 86400 / 60 / 15; i++) {
      listTime.push({
        id: i * 15 * 60,
        value: i * 15 * 60,
        label: `${
          (i * 15) / 60 < 10
            ? '0' + Math.floor((i * 15) / 60)
            : Math.floor((i * 15) / 60).toString()
        }:${
          (i * 15) % 60 < 10
            ? '0' + ((i * 15) % 60)
            : ((i * 15) % 60).toString()
        }`
      });
    }
    setOptions(listTime);
  }, []);
  return (
    <Box>
      {options?.length && (
        <Autocomplete
          classes={classes}
          key={keyField}
          disablePortal
          popupIcon={''}
          value={
            dayId
              ? formik.values[name][dayId].find(
                  (el: any, index: any) => index === keyField
                )[type] !== null
                ? {
                    id: Number(
                      formik.values[name][dayId].find(
                        (el: any, index: any) => index === keyField
                      )[type]
                    ),
                    value: Number(
                      formik.values[name][dayId].find(
                        (el: any, index: any) => index === keyField
                      )[type]
                    )
                  }
                : { id: null, value: null }
              : time
              ? time
              : formik
              ? formik.values[name]
              : null
          }
          multiple={false}
          options={options}
          style={props.style}
          onChange={(event: any, values: any) => {
            if (dayId && formik) {
              let currentFormikValue: any = cloneDeep(formik.values[name]);
              let newValue: any = dayId
                ? currentFormikValue[dayId].map((el: any, index: any) =>
                    index === keyField
                      ? {
                          ...el,
                          open: type === 'open' ? values.value : el.open,
                          close: type === 'close' ? values.value : el.close
                        }
                      : el
                  )
                : null;
              formik.setFieldValue(name, {
                ...currentFormikValue,
                [dayId]: newValue
              });
            } else {
              if (setTime) {
                setTime(values);
              } else if (formik) {
                formik.setFieldValue(name, values);
              }
            }
          }}
          getOptionLabel={(optionId: any) => {
            return (
              options.find((option: any) => option?.id === optionId.id)
                ?.label || ''
            );
          }}
          renderOption={(props: any, option: any) => {
            return (
              <Box {...props}>
                <Box>{option.label}</Box>
              </Box>
            );
          }}
          getOptionDisabled={(option: any) =>
            dayId
              ? type === 'close'
                ? option.value <
                  Number(
                    formik.values[name][dayId].find(
                      (el: any, index: any) => index === keyField
                    ).open
                  )
                : type === 'open' &&
                  formik.values[name][dayId].find(
                    (el: any, index: any) => index === keyField
                  ).close
                ? option.value >
                  Number(
                    formik.values[name][dayId].find(
                      (el: any, index: any) => index === keyField
                    ).close
                  )
                : false
              : minTime
              ? option.value < minTime
              : maxTime
              ? option.value > maxTime
              : false
          }
          renderInput={params => (
            <RedditTextField
              {...params}
              variant="filled"
              label={label}
              placeholder={placeholder}
              name={name}
              style={{
                width: '100%',
                fontWeight: '700',
                borderRadius: '12px',
                ...styleTextField
              }}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <i
                      className="fa-light fa-clock-five"
                      style={{ marginTop: '-16px', marginLeft: '8px' }}
                    ></i>
                  </InputAdornment>
                )
              }}
              error={
                minTime
                  ? time
                    ? time < minTime
                    : formik
                    ? formik.values[name]?.value < minTime
                    : false
                  : maxTime
                  ? time
                    ? time > maxTime
                    : formik
                    ? formik.values[name]?.value > maxTime
                    : false
                  : false
              }
              helperText={
                minTime
                  ? time
                    ? time < minTime
                    : formik
                    ? formik.values[name]?.value < minTime
                    : false
                  : maxTime
                  ? time
                    ? time > maxTime
                    : formik
                    ? formik.values[name]?.value > maxTime
                    : false
                  : false
              }
            />
          )}
        />
      )}
    </Box>
  );
}

export default AutocompleteTimeOfDay;
