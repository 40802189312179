import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { buttonColor, buttonStyle } from 'src/constants/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
const CarouselV2 = props => {
  const {
    id,
    iconTitle,
    title,
    component,
    data,
    action,
    actionChildren,
    elOfPage,
    styleRoot,
    styleComponent,
    itemComponentStyle,
    styleWrapTitle,
    styleTitle,
    styleCarouselDiv,
    styleButtonLeft,
    styleButtonRight,
    typeCarousel,
    styleIcon,
    typeButton,
    matches,
    typeShowAll,
    styleIconAll,
    styleSubTitle,
    styleDivider,
    styleText,
    itemSelectedIndex = 0
  } = props;

  const match = useRouteMatch();
  const elementCarouselDiv = document.getElementById(`CarouselDiv-${id}`);
  let widthCarousel: any = elementCarouselDiv?.offsetWidth;

  let ratioTranslate = 100 / data?.length;
  let numberOfPage = Math.ceil(data?.length / elOfPage);

  const theme = useTheme();
  const [translate, setTranslate] = React.useState(
    0 - itemSelectedIndex * ratioTranslate
  );
  const [time, setTime] = React.useState(0);
  let remainingItem = data?.length - elOfPage * (numberOfPage - 1);
  const [lengthPreData, setLengthPreData] = useState(0);

  const useStyles = makeStyles((theme: any) => ({
    root: {
      minHeight:
        typeCarousel === 'topSearch_market' || typeCarousel === 'playList'
          ? '350px'
          : typeCarousel === 'Sale_market'
          ? '150px'
          : typeCarousel === 'rankMusic'
          ? '230px'
          : typeCarousel === 'detailProduct_market'
          ? '470px'
          : '470px',
      width: '100%',
      margin: '20px 0px',
      borderRadius: '10px',
      // boxShadow:
      //   typeCarousel === 'Sale_market' ||
      //   typeCarousel === 'carousel_media' ||
      //   typeCarousel === 'carousel_image' ||
      //   typeCarousel === 'carousel_button' ||
      //   typeCarousel === 'playList' ||
      //   typeCarousel === 'rankMusic' ||
      //   typeCarousel === 'detailProduct_market'
      //     ? 'unset'
      //     : theme.palette.boxShadow.primary,
      borderBottom: '0',
      border: 0,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      overflowX: 'hidden',
      overflowY: 'hidden'
    },
    wrapTitle: {
      padding: '12px 16px 4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    item: {
      width:
        typeCarousel === 'category_market'
          ? matches
            ? '130px'
            : '132px'
          : typeCarousel === 'market_rating'
          ? '450px'
          : `${widthCarousel / elOfPage - 10}px`,
      maxWidth:
        typeCarousel === 'category_market'
          ? matches
            ? '130px'
            : '132px'
          : typeCarousel === 'market_rating'
          ? '445px'
          : `${widthCarousel / elOfPage - 10}px`,
      height:
        typeCarousel === 'pinnedPost'
          ? '460px'
          : typeCarousel === 'category_market'
          ? '51vh'
          : typeCarousel === 'topSearch_market' ||
            typeCarousel === 'Sale_market' ||
            typeCarousel === 'carousel_media' ||
            typeCarousel === 'carousel_image' ||
            typeCarousel === 'carousel_button' ||
            typeCarousel === 'friend_group' ||
            typeCarousel === 'playList' ||
            typeCarousel === 'rankMusic' ||
            typeCarousel === 'detailProduct_market'
          ? 'unset'
          : '40vh',
      minHeight:
        typeCarousel === 'topSearch_market' ||
        typeCarousel === 'Sale_market' ||
        typeCarousel === 'playList' ||
        typeCarousel === 'rankMusic' ||
        typeCarousel === 'pinnedPost' ||
        typeCarousel === 'detailProduct_market'
          ? 'unset'
          : typeCarousel === 'carousel_media'
          ? '340px'
          : typeCarousel === 'carousel_image'
          ? '430px'
          : '360px',
      maxHeight:
        typeCarousel === 'category_market'
          ? '284px'
          : typeCarousel === 'topSearch_market' ||
            typeCarousel === 'Sale_market' ||
            typeCarousel === 'pinnedPost' ||
            typeCarousel === 'rankMusic'
          ? 'unset'
          : '460px',
      borderRadius:
        typeCarousel === 'topSearch_market' ||
        typeCarousel === 'Sale_market' ||
        typeCarousel === 'detailProduct_market'
          ? 'unset'
          : 12,
      display: 'inline-block',
      margin: '10px 5px'
    },
    wrapContent: {
      display: 'flex',
      position: 'absolute',
      transition: 'ease-in-out 0.3s'
    },
    wrapIcon: {
      position: 'absolute',
      height: 44,
      width: 44,
      top:
        typeCarousel === 'topSearch_market'
          ? '170px'
          : typeCarousel === 'Sale_market'
          ? '70px'
          : typeCarousel === 'playList'
          ? '130px'
          : typeCarousel === 'rankMusic'
          ? '115px'
          : '220px',
      borderRadius: '50%',
      border: '1px solid rgba(0,0,0,0.12)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.mode === 'dark' ? '#3f3f3f' : '#fff',
      color: theme.palette.mode === 'dark' ? '#d6d6d6' : '#2e2e2e',
      cursor: 'pointer',
      // '&:hover': {
      //   cursor: 'pointer',
      //   backgroundColor:
      //     typeCarousel === 'category_market' && theme.palette.mode === 'light'
      //       ? '#fff'
      //       : theme.palette.mode === 'dark'
      //       ? '#525252'
      //       : '#eaeaea',
      //   width:
      //     typeCarousel === 'category_market'
      //       ? '42px !important'
      //       : typeCarousel === 'topSearch_market' ||
      //         typeCarousel === 'Sale_market'
      //       ? '30px'
      //       : '44px !important',
      //   height:
      //     typeCarousel === 'category_market'
      //       ? '42px !important'
      //       : typeCarousel === 'topSearch_market' ||
      //         typeCarousel === 'Sale_market'
      //       ? '30px'
      //       : '44px !important',
      //   top: typeCarousel === 'category_market' ? '32% !important' : undefined
      //   // right: typeCarousel === 'category_market' ? '90px' : '25px'
      // },
      zIndex:
        typeCarousel === 'category_market'
          ? '10'
          : typeCarousel === 'rankMusic'
          ? '0'
          : '1000'
    }
  }));
  const classes = useStyles();

  useEffect(() => {
    if (data?.length) {
      setTranslate(0 - itemSelectedIndex * ratioTranslate);
    }
  }, [JSON.stringify(match.params)]);

  useEffect(() => {
    if (data?.length > 2 && data?.length !== lengthPreData) {
      setTranslate(() => 0 - itemSelectedIndex * ratioTranslate);
      setLengthPreData(data?.length);
    } else {
      setLengthPreData(data?.length);
    }
  }, [data?.length]);

  const handleActionSlide = type => {
    if (type === 'left') {
      setTime(prev => prev - 1);
      if (time === numberOfPage - 1) {
        setTranslate(prev => prev + ratioTranslate * remainingItem);
      } else {
        setTranslate(prev => prev + ratioTranslate * elOfPage);
      }
    } else {
      setTime(prev => prev + 1);
      if (time === numberOfPage - 2) {
        setTranslate((prev: any) => prev - ratioTranslate * remainingItem);
      } else {
        setTranslate((prev: any) => prev - ratioTranslate * elOfPage);
      }
    }
  };

  const renderButtonLeft = () => {
    return (
      data &&
      data?.length >= 2 &&
      translate < 0 &&
      translate + ratioTranslate * elOfPage > -100 && (
        <Grid
          onClick={() => handleActionSlide('left')}
          style={{
            left:
              typeCarousel === 'category_market'
                ? '-14px'
                : typeCarousel === 'topSearch_market'
                ? '0px'
                : '25px'
          }}
          sx={{
            ...styleButtonLeft
          }}
          className={classes.wrapIcon}
        >
          <i
            className="fa-light fa-angle-left fa-xl"
            style={{ ...styleIcon }}
          ></i>
        </Grid>
      )
    );
  };

  const renderButtonRight = () => {
    return (
      data &&
      data?.length >= 2 &&
      translate > -(ratioTranslate * (data?.length - elOfPage)) &&
      data?.length > elOfPage && (
        <Grid
          onClick={() => handleActionSlide('right')}
          style={{
            right:
              typeCarousel === 'category_market'
                ? '-10px'
                : typeCarousel === 'topSearch_market'
                ? '0px'
                : '25px'
          }}
          sx={{
            ...styleButtonRight
          }}
          className={classes.wrapIcon}
        >
          <i
            className="fa-light fa-angle-right fa-xl"
            style={{ ...styleIcon }}
          ></i>
        </Grid>
      )
    );
  };

  return (
    <Box sx={{ position: 'relative' }}>
      {typeButton === 'out_box' && renderButtonLeft()}
      {typeButton === 'out_box' && renderButtonRight()}

      <Box
        key={id}
        className={classes.root}
        sx={{
          backgroundColor:
            typeCarousel === 'Sale_market'
              ? '#FDF9E0'
              : typeCarousel === 'carousel_media' ||
                typeCarousel === 'carousel_image' ||
                typeCarousel === 'carousel_button' ||
                typeCarousel === 'rankMusic' ||
                typeCarousel === 'detailProduct_market'
              ? 'transparent'
              : 'background.primary',
          padding: '0px 6px',
          ...styleRoot
        }}
      >
        {title && (
          <Box className={classes.wrapTitle} style={{ ...styleWrapTitle }}>
            {typeShowAll ? (
              <Box sx={{ width: '100%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginBottom: 2
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 17,
                      fontWeight: 500,
                      textTransform: 'upperCase',
                      color:
                        typeCarousel === 'topSearch_market' ? '#EE4D2E' : '',
                      ...styleTitle
                    }}
                  >
                    {title}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                    onClick={actionChildren}
                  >
                    <Typography
                      sx={{
                        fontSize: 17,
                        fontWeight: 500,
                        color: '#EE4D2E',
                        ...styleSubTitle
                      }}
                    >
                      Xem tất cả
                    </Typography>
                    <NavigateNextIcon
                      sx={{ color: '#EE4D2E', ...styleIconAll }}
                    />
                  </Box>
                </Box>
                {typeCarousel === 'topSearch_market' && (
                  <Divider sx={{ ...styleDivider }} />
                )}
              </Box>
            ) : (
              <Box style={{ width: '100%', ...styleText }}>
                {iconTitle}
                <Typography
                  style={{
                    fontSize: 17,
                    fontWeight: 500,
                    marginLeft: 10,
                    ...styleTitle
                  }}
                >
                  {title}
                </Typography>
              </Box>
            )}
          </Box>
        )}
        <Box
          id={`CarouselDiv-${id}`}
          style={{ width: '100%', ...styleCarouselDiv }}
        >
          {
            <Box
              className={classes.wrapContent}
              style={{
                transform: `translate(${translate}%, 0)`,
                ...styleComponent
              }}
            >
              {data?.map((el: any, index) => (
                <Box
                  id={`ChildrenCarouselDiv-${id}-${index}`}
                  key={index}
                  className={classes.item}
                  style={{
                    overflow: 'hidden',
                    ...itemComponentStyle
                    // boxShadow:
                    //   theme.palette.mode === 'dark'
                    //     ? typeCarousel === 'rankMusic'
                    //       ? 'unset'
                    //       : '0 1px 2px rgba(147, 146, 146, 0.6)'
                    //     : typeCarousel === 'topSearch_market' ||
                    //       typeCarousel === 'Sale_market' ||
                    //       typeCarousel === 'carousel_button' ||
                    //       typeCarousel === 'carousel_media' ||
                    //       typeCarousel === 'carousel_image' ||
                    //       typeCarousel === 'rankMusic'
                    //     ? 'unset'
                    //     : '0 2px 3px rgba(0, 0, 0, 0.031)',

                    // border:
                    //   typeCarousel === 'category_market' &&
                    //   theme.palette.mode === 'light'
                    //     ? '1px solid #f5f5f5'
                    //     : typeCarousel === 'topSearch_market' ||
                    //       typeCarousel === 'Sale_market' ||
                    //       typeCarousel === 'carousel_button' ||
                    //       typeCarousel === 'carousel_media' ||
                    //       typeCarousel === 'carousel_image' ||
                    //       typeCarousel === 'rankMusic' ||
                    //       typeCarousel === 'detailProduct_market'
                    //     ? 'unset'
                    //     : theme.palette.mode === 'dark'
                    //     ? '1px solid #aaaaaa18'
                    //     : '1px solid #e9e9e9'
                  }}
                >
                  {component && component(el, index)}
                </Box>
              ))}
            </Box>
          }

          {typeButton !== 'out_box' ? renderButtonLeft() : null}
          {typeButton !== 'out_box' ? renderButtonRight() : null}
        </Box>

        {action && (
          <Box
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              padding: 10
            }}
          >
            <Button
              onClick={() => action()}
              fullWidth
              sx={{
                ...buttonStyle,
                textTransform: 'none'
              }}
            >
              <Typography
                style={{
                  fontSize: 15,
                  fontWeight: 500
                }}
              >
                Xem thêm
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CarouselV2;
