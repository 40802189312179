import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  ListItemAvatar,
  ListItemText,
  Rating,
  Tooltip,
  Typography
} from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import React, { useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  convertMoneyToVietnameseDongString,
  handleTimeShow
} from 'src/helpers/string';
import { ListItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { addUserSearchHistory } from 'src/apis/socialSearch.api';
import { followUser, unFollowUser } from 'src/apis/socialUser';
import avatar_default from 'src/assets/images/avatar_default.jpg';
import productCoverDefault from 'src/assets/images/group_cover.png';
import {
  formatDateTimeToStringDateTime,
  formatNumberVND
} from 'src/common/string';
import ButtonCustom from 'src/components/Button/Button';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import DialogConfirm from 'src/components/Dialog/DialogConfirmDelete';
import MenuAction from 'src/components/Menu';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import NameSocial from 'src/components/ProfileCardImage/NameSocial';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import SocialPost from 'src/components/SocialPost';
import { PATHS } from 'src/constants/paths';
import { buttonColor } from 'src/constants/styles';
import { resetProduct } from 'src/store/action/socialProductAction';
import LoadMoreDataSearchGlobal from './LoadMoreDataSearchGlobal';
import { getMemberQuestionReq } from 'src/store/action/socialGroupAction';
const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      root: {
        display: 'flex',
        marginTop: 20,
        marginBottom: 20,
        width: '100%',
        justifyContent: 'center'
      },
      coverWidgets: {
        width: '100%',
        height: '100%'
      },
      rootListRecruit: {
        border: theme.palette.borderColor.color,
        boxShadow: 'none !important',
        borderRadius: '10px !important',
        margin: '10px',
        position: 'relative',
        minWidth: '620px',
        cursor: 'pointer'
      },
      rootListCourse: {
        border: '1px solid rgba(0,0,0,0.1)',
        boxShadow: 'none !important',
        borderRadius: '10px !important',
        margin: '10px',
        position: 'relative',
        minWidth: '620px',
        cursor: 'pointer'
      },
      rootListGrow: {
        border: theme.palette.borderColor.color,
        boxShadow: 'none !important',
        borderRadius: '10px !important',
        margin: '10px',
        position: 'relative',
        minWidth: '620px',
        cursor: 'pointer'
      },
      rootListEventSearch: {
        border: '1px solid rgba(0,0,0,0.1)',
        boxShadow: 'none !important',
        borderRadius: '10px !important',
        margin: '10px',
        position: 'relative',
        minWidth: '620px',
        cursor: 'pointer'
      },
      feed: {
        width: '66%',
        padding: '0px 10px',
        [theme.breakpoints.down('xl')]: {
          width: '66%'
        },
        borderRadius: '10px'
      },
      feedPreview: {
        width: '65%',
        padding: '0px 10px',
        [theme.breakpoints.down('xl')]: {
          width: '90%'
        },
        background: '#fff',
        borderRadius: '10px'
      },
      pageList: {
        width: '100%',
        [theme.breakpoints.down('xl')]: {
          // margin: '0px 45px 0px 50px'
        }
      },
      pageDiscover: {
        width: '100%',
        margin: '0px 50px',
        [theme.breakpoints.down('xl')]: {
          margin: '0px 50px 0px 50px'
        }
      },
      lineClamp: {
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden'
      },
      pageInvites: {
        width: '45vw',
        padding: '0px 10px',
        [theme.breakpoints.down('xl')]: {
          width: '60%'
        },
        display: 'flex',
        flexDirection: 'column'
      },
      topVisibility: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '10px 0'
      },
      rootListEvent: {
        boxShadow: 'none !important',
        borderRadius: '10px !important',
        margin: '10px 0 10px 0',
        position: 'relative',
        width: '100%',
        padding: '2px 0px',
        height: '100%'
      },
      nameSocial: {
        fontSize: '17px!important',
        fontWeight: '600!important',

        '$:hover': {
          textDecoration: 'underline!important',
          cursor: 'pointer!important'
        }
      },
      cardMedia: {
        maxWidth: '162px',
        marginLeft: '12px',
        borderRadius: '8px'
      },
      cardContent: {
        marginTop: '12px',
        width: '100%'
      },
      divFlex: {
        display: 'flex'
      },
      divFlexAlign: {
        display: 'flex',
        alignItems: 'center'
      },
      wrapItem: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.primary,
        borderRadius: '8px',
        margin: '6px 0px 0px 1px',
        transition: '0.3s',
        '&:hover': {
          transform: 'translateY(-3px)'
        }
      },
      rootCard: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        cursor: 'pointer',
        transition: 'all 0.3s liner',
        border: `1px solid rgb(0 0 0 / 10%)`,
        '&:hover': {
          border: `1px solid ${buttonColor.newTextColor}`
        }
      },
      divBox: {
        height: '100%'
      },
      divWrap: {
        // height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      },
      wrapBanner: {
        minHeight: '186px'
        // borderBottom: '1px solid rgb(0 0 0 / 10%)'
      },
      wrapContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        // padding: '0px 0px 0px 8px',
        width: '100%',
        height: '100px'
      },
      secondaryText: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      },
      wrapPrice: {
        display: 'flex',
        justifyContent: 'space-between'
      },
      lineClampName: {
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden'
      }
    }),
  { index: 1 }
);

const SearchGlobalCenter = (props: any) => {
  const { refScroll } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme: any = useTheme();
  const match = useRouteMatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [isFollow, setIsFollow] = React.useState(false);
  const itemWidth: any = useRef();
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  const [groupSelected, setGroupSelected] = React.useState<any>(null);
  const [flag, setFlag] = React.useState(1);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleAddUserSearchHistory = async params => {
    let response = await addUserSearchHistory(params);
  };

  React.useEffect(() => {
    setIsLoading(true);
  }, [JSON.stringify(match)]);

  const handleAction = async (type, idUser) => {
    try {
      let response;
      if (type === 'unFollow') {
        response = await unFollowUser(idUser);
      } else if (type === 'follow') {
        response = await followUser(idUser);
      }
      if (response.status === 200) {
        setOpenSnackbar(true);
        if (type === 'unFollow') {
          setIsFollow(false);
          setNoti({
            code: 200,
            message: 'Đã bỏ theo dõi'
          });
        } else if (type === 'follow') {
          setIsFollow(true);
          setNoti({
            code: 200,
            message: 'Đang theo dõi'
          });
        }
      }
    } catch (err: any) {
      setOpenSnackbar(true);
      setNoti({
        code: err.response.status,
        message: 'Có lỗi xảy ra trong quá trình phản hồi.Vui lòng thử lại sau!'
      });
    }
  };
  const renderButton = (item: any, onButtonClick) => {
    if (item?.relationships?.friendship_status === 'ARE_FRIENDS') {
      return (
        <div onClick={handleClick}>
          <ButtonInherit
            label="Bạn bè"
            icon={
              <i
                style={{ fontSize: 15 }}
                className="fa-solid fa-user-check"
              ></i>
            }
            style={{
              backgroundColor: '#e7f3ff',
              color: buttonColor.backgroundColor,
              fontSize: 15
            }}
          />
          {anchorEl && (
            <MenuAction
              anchorEl={anchorEl}
              openPopper={openPopper}
              setOpenPopper={setOpenPopper}
              handleCloseMenu={handleCloseMenu}
              listMenu={[
                {
                  key: 'unFollow',
                  icon: 'fa-light fa-square-xmark',
                  label: isFollow ? 'Bỏ theo dõi' : 'Theo dõi',
                  action: () => {
                    handleAction(isFollow ? 'unFollow' : 'follow', item.id);
                    handleCloseMenu();
                  }
                },
                {
                  key: 'unFriend',
                  icon: 'fa-light fa-user-xmark',
                  label: 'Hủy kết bạn',
                  action: () => {
                    setOpenConfirm(true);
                    handleCloseMenu();
                  }
                }
              ]}
            />
          )}
          {openConfirm && (
            <DialogConfirm
              open={openConfirm}
              handleClose={() => setOpenConfirm(false)}
              title={`Hủy kết bạn với ${item.display_name}`}
              text={`Bạn có chắc chắn muốn xóa ${item.display_name} khỏi danh sách bạn bè không?`}
              labelButton="Xác nhận"
              action={() => {
                onButtonClick();
                setOpenConfirm(false);
              }}
            />
          )}
        </div>
      );
    } else if (item?.relationships?.friendship_status === 'OUTGOING_REQUEST') {
      return (
        <ButtonInherit
          label="Hủy yêu cầu"
          icon={
            <i style={{ fontSize: 15 }} className="fa-solid fa-user-xmark"></i>
          }
          style={{
            backgroundColor:
              theme.palette.mode === 'dark' ? '#263951' : '#E7F3FF',
            color: buttonColor.backgroundColor,
            fontSize: 15
          }}
          action={onButtonClick}
        />
      );
    } else if (item?.relationships?.friendship_status === 'CAN_REQUEST') {
      return (
        <ButtonInherit
          label="Thêm bạn bè"
          icon={
            <i style={{ fontSize: 15 }} className="fa-solid fa-user-plus"></i>
          }
          style={{
            backgroundColor:
              theme.palette.mode === 'dark' ? '#263951' : '#E7F3FF',
            color: buttonColor.backgroundColor,
            fontSize: 15
          }}
          action={onButtonClick}
        />
      );
    } else if (item?.relationships?.friendship_status === 'INCOMING_REQUEST') {
      return (
        <ButtonInherit
          label="Xác nhận lời mời"
          icon={
            <i style={{ fontSize: 15 }} className="fa-solid fa-user-plus"></i>
          }
          style={{
            backgroundColor:
              theme.palette.mode === 'dark' ? '#263951' : '#E7F3FF',
            color: buttonColor.backgroundColor,
            fontSize: 15
          }}
          action={onButtonClick}
        />
      );
    } else if (item?.relationships === null) {
      return (
        <ButtonInherit
          label="Xem trang cá nhân"
          style={{
            backgroundColor:
              theme.palette.mode === 'dark' ? '#263951' : '#E7F3FF',
            color: buttonColor.backgroundColor,
            fontSize: 15
          }}
          action={() => history.push(`/user/${item?.username ?? item?.id}`)}
        />
      );
    } else if (item?.page_categories) {
      return (
        <ButtonCustom
          label={item.page_relationship.like ? 'Đã thích' : 'Thích'}
          icon={
            <i
              style={{
                color: item.page_relationship.like
                  ? buttonColor.backgroundColor
                  : theme.palette.mode === 'dark'
                  ? '#fff'
                  : '#050505'
              }}
              className="fa-solid fa-thumbs-up"
            ></i>
          }
          style={{
            backgroundColor:
              theme.palette.mode === 'dark'
                ? item.page_relationship.like
                  ? '#263951'
                  : '#4B4B4B'
                : item.page_relationship.like
                ? '#E7F3FF'
                : '#E4E6EB',
            color: item.page_relationship.like
              ? buttonColor.backgroundColor
              : theme.palette.mode === 'dark'
              ? '#fff'
              : '#050505',
            width: '130px'
          }}
          action={() =>
            item?.page_relationship?.like
              ? history.push(`/page/${item?.id}`)
              : onButtonClick()
          }
        />
      );
    } else if (!item?.display_name && !item?.page_categories) {
      if (
        item?.group_relationship?.admin ||
        item?.group_relationship?.moderator ||
        item?.group_relationship?.member
      ) {
        return (
          <ButtonCustom
            label={'Truy cập'}
            style={{
              backgroundColor:
                theme.palette.mode === 'dark' ? '#263951' : '#E7F3FF',
              color: buttonColor.backgroundColor,
              width: '130px',
              fontWeight: '600'
            }}
            action={() => {
              history.push(`/group/${item.username ?? item.id}`);
            }}
          />
        );
      } else if (item.group_relationship?.requested) {
        return (
          <ButtonCustom
            label={'Hủy yêu cầu'}
            style={{
              backgroundColor:
                theme.palette.mode === 'dark' ? '#263951' : '#E7F3FF',
              color: buttonColor.backgroundColor,
              width: '130px',
              fontWeight: '600'
            }}
            action={() => {
              onButtonClick();
              setGroupSelected(item);
              setFlag(1);
            }}
          />
        );
      } else {
        return (
          <ButtonCustom
            label={'Tham gia'}
            style={{
              backgroundColor:
                theme.palette.mode === 'dark' ? '#4B4B4B' : '#E4E6EB',
              color: theme.palette.mode === 'dark' ? '#fff' : '#050505',
              width: '130px',
              fontWeight: '600'
            }}
            action={() => {
              onButtonClick();
              dispatch(getMemberQuestionReq('get', item.id));
              setGroupSelected(item);
              setFlag(2);
            }}
          />
        );
      }
    }
  };

  const renderAction = (item: any, onButtonAction) => {
    let { key }: any = match.params;
    if (key === 'events' && meInfo?.id !== item?.account?.id) {
      return (
        <ButtonInherit
          label="Quan tâm"
          icon={
            <i
              style={{
                fontSize: '16px',
                color:
                  item?.event_relationship?.status !== ''
                    ? buttonColor.backgroundColor
                    : theme.palette.text.primary
              }}
              className={
                item?.event_relationship?.status !== ''
                  ? 'fa-solid fa-star'
                  : 'fa-light fa-star'
              }
            ></i>
          }
          style={{
            backgroundColor:
              theme.palette.mode === 'dark'
                ? item?.event_relationship?.status !== ''
                  ? '#263951'
                  : '#4B4B4B'
                : item?.event_relationship?.status !== ''
                ? '#E7F3FF'
                : '#E4E6EB',
            color:
              item?.event_relationship?.status !== ''
                ? buttonColor.backgroundColor
                : theme.palette.text.primary,
            width: '131.5px',
            fontSize: 15,
            position: 'absolute',
            top: '70px',
            right: '15px',
            whiteSpace: 'nowrap'
          }}
          action={onButtonAction}
        />
      );
    } else if (key === 'recruits' && meInfo?.id !== item?.account?.id) {
      return (
        <ButtonInherit
          label="Quan tâm"
          icon={
            <i
              style={{
                fontSize: '16px',
                color: item?.recruit_relationships?.follow_recruit
                  ? buttonColor.backgroundColor
                  : theme.palette.text.primary
              }}
              className={
                item?.recruit_relationships?.follow_recruit
                  ? 'fa-solid fa-star'
                  : 'fa-light fa-star'
              }
            ></i>
          }
          style={{
            backgroundColor:
              theme.palette.mode === 'dark'
                ? item?.recruit_relationships?.follow_recruit
                  ? '#263951'
                  : '#4B4B4B'
                : item?.recruit_relationships?.follow_recruit
                ? '#E7F3FF'
                : '#E4E6EB',
            color: item?.recruit_relationships?.follow_recruit
              ? buttonColor.backgroundColor
              : theme.palette.text.primary,
            width: '131.5px',
            fontSize: 15,
            position: 'absolute',
            top: '70px',
            right: '15px',
            whiteSpace: 'nowrap'
          }}
          action={onButtonAction}
        />
      );
    } else if (key === 'courses' && meInfo?.id !== item?.account?.id) {
      return (
        <ButtonInherit
          label="Quan tâm"
          icon={
            <i
              style={{
                fontSize: '16px',
                color: item?.course_relationships?.follow_course
                  ? buttonColor.backgroundColor
                  : theme.palette.text.primary
              }}
              className={
                item?.course_relationships?.follow_course
                  ? 'fa-solid fa-star'
                  : 'fa-light fa-star'
              }
            ></i>
          }
          style={{
            backgroundColor:
              theme.palette.mode === 'dark'
                ? item?.course_relationships?.follow_course
                  ? '#263951'
                  : '#4B4B4B'
                : item?.course_relationships?.follow_course
                ? '#E7F3FF'
                : '#E4E6EB',
            color: item?.course_relationships?.follow_course
              ? buttonColor.backgroundColor
              : theme.palette.text.primary,
            width: '131.5px',
            fontSize: 15,
            position: 'absolute',
            top: '70px',
            right: '15px',
            whiteSpace: 'nowrap'
          }}
          action={onButtonAction}
        />
      );
    } else if (key === 'grows' && meInfo?.id !== item?.account?.id) {
      return (
        <ButtonInherit
          label="Quan tâm"
          icon={
            <i
              style={{
                fontSize: '16px',
                color: item?.project_relationship?.follow_project
                  ? buttonColor.backgroundColor
                  : theme.palette.text.primary
              }}
              className={
                item?.project_relationship?.follow_project
                  ? 'fa-solid fa-star'
                  : 'fa-light fa-star'
              }
            ></i>
          }
          style={{
            backgroundColor:
              theme.palette.mode === 'dark'
                ? item?.project_relationship?.follow_project
                  ? '#263951'
                  : '#4B4B4B'
                : item?.project_relationship?.follow_project
                ? '#E7F3FF'
                : '#E4E6EB',
            color: item?.project_relationship?.follow_project
              ? buttonColor.backgroundColor
              : theme.palette.text.primary,
            width: '131.5px',
            fontSize: 15,
            position: 'absolute',
            top: '70px',
            right: '15px',
            whiteSpace: 'nowrap'
          }}
          action={onButtonAction}
        />
      );
    }
  };

  const renderLink = (item: any) => {
    let { key }: any = match.params;
    let link: any = '';
    switch (key) {
      case 'all':
        if (item.page_relationship)
          return (link = `/page/${item.username || item.id}`);
        else if (item.group_relationship)
          return (link = `/group/${item.username || item.id}`);
        else return (link = `/user/${item.username || item.id}`);
      case 'pages':
        return (link = `/page/${item.username || item.id}`);
      case 'accounts':
        return (link = `/user/${item.username || item.id}`);
      case 'groups':
        return (link = `/group/${item.id}`);
      default:
        break;
    }
    return link;
  };

  const renderListRecruit = (recruit: any) => {
    return (
      <div className={classes.rootListRecruit}>
        <div onClick={() => history.push(`/recruit/${recruit.id}/about`)}>
          <Box className={classes.divFlexAlign}>
            <CardMedia
              component="img"
              height="108"
              className={classes.cardMedia}
              image={
                recruit?.banner ? recruit.banner.preview_url : avatar_default
              }
            />
            <CardContent className={classes.cardContent}>
              <Typography
                variant="body2"
                fontWeight={500}
                color="text.secondary"
              >
                {!!recruit?.start_date && handleTimeShow(recruit?.start_date)}
              </Typography>
              <Tooltip title={recruit?.title}>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  className={classes.lineClamp}
                >
                  {recruit?.title}
                </Typography>
              </Tooltip>
              <Typography variant="body2">
                {recruit?.page?.title ?? recruit?.account?.display_name}
              </Typography>
              <div className={classes.divFlex}>
                <Typography variant="body2">
                  {`${recruit?.salary_min} VNĐ - ${recruit?.salary_max} VNĐ`}
                </Typography>
              </div>
            </CardContent>
          </Box>
        </div>
      </div>
    );
  };

  const renderListCourse = (course: any) => {
    return (
      <div className={classes.rootListCourse}>
        <div onClick={() => history.push(`/course/${course.id}/about`)}>
          <Box className={classes.divFlexAlign}>
            <CardMedia
              component="img"
              height="108"
              className={classes.cardMedia}
              image={course?.banner ? course.banner.preview_url : ''}
            />
            <CardContent className={classes.cardContent}>
              <Typography
                variant="body2"
                fontWeight={500}
                color="text.secondary"
              >
                {!!course?.start_date && handleTimeShow(course?.start_date)}
              </Typography>
              <Tooltip title={course?.title}>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  className={classes.lineClamp}
                >
                  {course?.title}
                </Typography>
              </Tooltip>
              <Typography gutterBottom variant="body2">
                {course?.page?.title ?? course?.account?.display_name} ·{' '}
                {course?.followers_count} người quan tâm
              </Typography>

              <Chip
                size="small"
                color="secondary"
                label={
                  parseInt(course?.price) === 0
                    ? 'Miễn phí'
                    : formatNumberVND(course?.price)
                }
              />
            </CardContent>
          </Box>
        </div>
      </div>
    );
  };

  const renderListEvent = (event: any) => {
    return (
      <div className={classes.rootListGrow}>
        <div onClick={() => history.push(`/event/${event.id}/about`)}>
          <Box className={classes.divFlexAlign}>
            <CardMedia
              component="img"
              height="108"
              className={classes.cardMedia}
              image={event?.banner ? event.banner.preview_url : avatar_default}
            />
            <CardContent className={classes.cardContent}>
              <Typography
                variant="body2"
                fontWeight={500}
                color="text.secondary"
              >
                {!!event?.start_time &&
                  formatDateTimeToStringDateTime(event?.start_time, true)}
              </Typography>
              <Tooltip title={event?.title}>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  className={classes.lineClamp}
                >
                  {event?.title}
                </Typography>
              </Tooltip>
              <div style={{ display: 'flex' }}>
                <Typography variant="body2">
                  {`${event?.users_going_count} người quan tâm · ${event?.users_interested_count} người sẽ tham gia`}
                </Typography>
                <Typography variant="body2"></Typography>
              </div>
            </CardContent>
          </Box>
        </div>
      </div>
    );
  };

  const renderListGrow = (grow: any) => {
    return (
      <div className={classes.rootListEventSearch}>
        <div onClick={() => history.push(`/grow/${grow.id}/about`)}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CardMedia
              component="img"
              height="108"
              sx={{
                maxWidth: '162px',
                marginLeft: '12px',
                borderRadius: '8px'
              }}
              image={grow?.banner ? grow.banner.preview_url : ''}
            />
            <CardContent
              sx={{
                marginTop: '12px',
                width: '100%',
                paddingRight: '230px'
              }}
            >
              <Typography
                variant="body2"
                fontWeight={500}
                color="text.secondary"
              >
                {!!grow?.start_date && handleTimeShow(grow?.start_date)}
              </Typography>
              <Tooltip title={grow?.title}>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  className={classes.lineClamp}
                >
                  {grow?.title}
                </Typography>
              </Tooltip>
              <Typography variant="body2">
                {`Cam kết mục tiêu ${formatNumberVND(grow?.target_value)}`}
              </Typography>
              <div style={{ display: 'flex' }}>
                <Typography variant="body2">
                  {`${grow?.followers_count} người quan tâm · ${grow?.backers_count} người ủng hộ`}
                </Typography>
                <Typography variant="body2"></Typography>
              </div>
            </CardContent>
          </Box>
        </div>
      </div>
    );
  };

  const renderCardProduct = (product: any) => {
    const priceRange: any = product.product_variants?.map(item => item.price);
    return (
      <div className={classes.wrapItem} ref={itemWidth}>
        <div className={classes.rootCard}>
          <Box className={classes.divBox}>
            <div
              className={classes.divWrap}
              onClick={() => {
                dispatch(resetProduct());
                history.push(`/product/${product.id}/about`);
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: itemWidth.current?.clientWidth
                    ? itemWidth.current?.clientWidth
                    : 'auto'
                }}
              >
                <CardMedia
                  component="img"
                  height={itemWidth.current?.clientWidth}
                  image={
                    product?.product_image_attachments?.length
                      ? product.product_image_attachments[0]?.attachment
                          ?.preview_url
                      : productCoverDefault
                  }
                  className={classes.wrapBanner}
                  // sx={{ height: itemWidth.current?.clientWidth }}
                />
              </Box>

              <CardContent className={classes.cardContent}>
                <div className={classes.wrapContent}>
                  <div className={classes.secondaryText}>
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="text.secondary"
                    >
                      {!!product?.start_time &&
                        formatDateTimeToStringDateTime(
                          product?.start_time,
                          true
                        )}
                    </Typography>
                    <Tooltip title={product?.title}>
                      <Typography
                        gutterBottom
                        component="div"
                        className={classes.lineClamp}
                        style={{
                          fontSize: '14px',
                          fontWeight: 500,
                          width: '100%',
                          height: '42px'
                        }}
                      >
                        {product?.title.charAt(0).toUpperCase() +
                          product?.title.slice(1)}
                      </Typography>
                    </Tooltip>
                  </div>
                  {priceRange ? (
                    <Typography
                      variant="body2"
                      gutterBottom
                      sx={{
                        fontSize: '16px',
                        color: '#ee4d2d',
                        fontWeight: 400
                      }}
                    >
                      {Math.min(...priceRange) === Math.max(...priceRange)
                        ? `${convertMoneyToVietnameseDongString(
                            Math.min(...priceRange)
                          )}`
                        : `${convertMoneyToVietnameseDongString(
                            Math.min(...priceRange)
                          )} - ${convertMoneyToVietnameseDongString(
                            Math.max(...priceRange)
                          )}`}
                    </Typography>
                  ) : null}
                  <Box className={classes.wrapPrice}>
                    <Rating
                      name="read-only"
                      precision={0.5}
                      value={product?.rating}
                      size="small"
                      readOnly
                    />
                    <Typography
                      variant="body2"
                      gutterBottom
                      sx={{
                        fontSize: '13px',
                        fontWeight: 400
                      }}
                    >
                      Đã bán {product?.sold}
                    </Typography>
                  </Box>
                </div>
              </CardContent>
            </div>
          </Box>
        </div>
      </div>
    );
  };

  const renderItemList = (
    item: any,
    aboutUser: any,
    keyItem: any,
    type = 'accounts',
    onButtonClick
  ) => {
    let { key }: any = match.params;
    return item?.id && keyItem ? (
      <Card
        key={keyItem}
        classes={{ root: classes.rootListEvent }}
        sx={{
          backgroundColor: 'background.primary',
          backgroundImage: 'none',
          overflow: 'visible'
        }}
      >
        <ListItem
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            padding: key === 'all' ? '2px 0px' : '10px 10px'
          }}
        >
          <ListItemAvatar>
            {type !== 'groups' ? (
              <AvatarSocial
                type="feed"
                avatarObj={
                  !!item.avatar_media ? item.avatar_media : avatar_default
                }
                style={{
                  width: '60px',
                  height: '60px',
                  minWidth: '60px',
                  margin: '10px 10px'
                }}
                isPopup="true"
                object={item}
                action={() => {
                  handleAddUserSearchHistory({
                    keyword: item.display_name ?? item.title,
                    entity_id: item.id,
                    entity_type: item.display_name ? 'Account' : 'Page'
                  });
                }}
              />
            ) : (
              <AvatarSocial
                type="feed"
                avatarObj={item.banner}
                style={{
                  width: '60px',
                  height: '60px',
                  minWidth: '60px',
                  margin: '10px 10px'
                }}
                object={item}
                isBanner
                isPopup
                action={() => {
                  handleAddUserSearchHistory({
                    keyword: item.title,
                    entity_id: item.id,
                    entity_type: 'Group'
                  });
                }}
              />
            )}
          </ListItemAvatar>
          <ListItemText
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
            primary={
              <Typography sx={{ width: '55%' }}>
                <NameSocial
                  name={
                    item?.title ??
                    `${item?.display_name} ${
                      aboutUser?.[0]?.general_information?.other_name
                        ? `(${aboutUser?.[0]?.general_information?.other_name})`
                        : ''
                    }`
                  }
                  isPopup
                  style={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    '-webkit-line-clamp': '1',
                    '-webkit-box-orient': 'vertical'
                  }}
                  object={item}
                  className={classes.nameSocial}
                  action={() => {
                    handleAddUserSearchHistory({
                      keyword: item.display_name ?? item.title,
                      entity_type: item.display_name
                        ? 'Account'
                        : item.page_categories
                        ? 'Page'
                        : 'Group',
                      entity_id: item.id
                    });
                  }}
                />
                <Typography
                  fontSize="15px"
                  fontWeight="350"
                  sx={{
                    color: '#65676b',
                    wordWrap: 'break-word'
                  }}
                  className={classes.lineClampName}
                >
                  {type === 'accounts'
                    ? `${
                        item?.relationships?.friendship_status === 'ARE_FRIENDS'
                          ? 'Bạn bè'
                          : ''
                      }
                    ${
                      item?.relationships?.friendship_status === 'ARE_FRIENDS'
                        ? ' · '
                        : ''
                    }
                    ${
                      item?.followers_count
                        ? item?.followers_count + ' người theo dõi'
                        : ''
                    }
                    ${
                      (item?.relationships?.friendship_status ===
                        'ARE_FRIENDS' ||
                        item?.followers_count) &&
                      (aboutUser?.[0]?.general_information?.hometown?.name ||
                        aboutUser?.[0]?.general_information?.address)
                        ? ' · '
                        : ''
                    }
                    ${
                      aboutUser?.[0]?.general_information?.hometown?.name ||
                      aboutUser?.[0]?.general_information?.address
                        ? ` Sống tại ${
                            aboutUser?.[0]?.general_information?.hometown
                              ?.name ??
                            aboutUser?.[0]?.general_information?.address
                          }`
                        : ''
                    } `
                    : type === 'groups'
                    ? `${item?.is_private ? 'Riêng tư' : 'Công khai'} · ${
                        item?.member_count
                      } thành viên · ${item?.average_status} bài viết/tháng`
                    : type === 'pages'
                    ? `${item?.page_categories
                        ?.map(el => el?.text)
                        .join(' · ')} ${
                        item?.like_count >= item?.follow_count
                          ? '· ' + item?.like_count + ' người thích'
                          : '· ' + item?.follow_count + ' người theo dõi'
                      } ${
                        item?.rating > 3
                          ? '· ' + item?.rating + '/5'
                          : item?.hours_type === '· ALWAYS_OPEN'
                          ? '· Luôn mở cửa'
                          : item?.hours_type === '· CLOSED'
                          ? '· Đang đóng cửa'
                          : item?.review_count
                          ? item?.review_count + '· đánh giá'
                          : ''
                      }`
                    : null}
                </Typography>
                <span>
                  {item?.relationships?.mutual_friend_count
                    ? `${item?.relationships?.mutual_friend_count} bạn chung`
                    : ''}
                </span>
                <div style={{ display: 'flex' }}>
                  <Typography variant="body2">
                    {keyItem === 'people' ? 'Bạn bè' : null}
                  </Typography>
                </div>
              </Typography>
            }
            secondary={
              <>
                <CardActions
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  {renderButton(item, onButtonClick)}
                </CardActions>
              </>
            }
          />
        </ListItem>
      </Card>
    ) : null;
  };

  const renderItems = (el, onButtonAction) => {
    let { key }: any = match.params;
    const renderDate = el => {
      if (key === 'events') {
        return formatDateTimeToStringDateTime(el?.start_time, true);
      } else if (key === 'recruits' || key === 'courses') {
        return formatDateTimeToStringDateTime(el?.created_at, true);
      } else if (key === 'grows') {
        return formatDateTimeToStringDateTime(el?.start_date, true);
      }
    };
    const renderText = el => {
      if (key === 'events') {
        return `${el?.users_interested_count} người quan tâm · ${el?.address}`;
      } else if (key === 'recruits' || key === 'courses' || key === 'grows') {
        return `${el?.followers_count} người quan tâm`;
      }
    };
    return (
      <Box sx={{ position: 'relative', width: '100%' }}>
        <Card
          classes={{ root: classes.rootListEvent }}
          sx={{
            backgroundColor: 'background.primary',
            cursor: 'pointer'
          }}
          onClick={() => {
            if (key === 'events') {
              history.push(`/event/${el.id}/about`);
            } else if (key === 'recruits') {
              history.push(`/recruit/${el.id}/about`);
            } else if (key === 'courses') {
              history.push(`/course/${el.id}/about`);
            } else if (key === 'grows') {
              history.push(`/grow/${el.id}/about`);
            }
          }}
        >
          <Box className={classes.divFlexAlign}>
            <CardMedia
              component="img"
              height="108"
              className={classes.cardMedia}
              image={el?.banner ? el.banner.preview_url : avatar_default}
            />
            <CardContent className={classes.cardContent}>
              <Typography
                variant="body2"
                fontWeight={500}
                color="text.secondary"
              >
                {renderDate(el)}
              </Typography>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{ width: '60%' }}
                className={classes.lineClampName}
              >
                {el.title}
              </Typography>
              <div style={{ display: 'flex' }}>
                <Typography variant="body2">{renderText(el)}</Typography>
              </div>
            </CardContent>
          </Box>
        </Card>
        {renderAction(el, onButtonAction)}
      </Box>
    );
  };

  const renderItemPost = (item: any, key: any) => {
    if (!item.id) return;
    return (
      <div key={key}>
        <SocialPost key={item.id} post={item} type={'feed'} />
      </div>
    );
  };

  const renderPageCenter = () => {
    switch (match.path) {
      case PATHS.SEARCH_GLOBAL: {
        let { key }: any = match.params;
        switch (key) {
          case 'all': {
            return (
              <div className={classes.pageDiscover}>
                <LoadMoreDataSearchGlobal
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  renderItemList={renderItemList}
                  renderItemPost={renderItemPost}
                  groupSelected={groupSelected}
                  setGroupSelected={setGroupSelected}
                  flag={flag}
                  setFlag={setFlag}
                />
              </div>
            );
          }

          case 'groups':
          case 'pages':
          case 'statuses':
          case 'accounts':
          case 'events':
          case 'recruits':
          case 'courses':
          case 'grows':
          case 'products': {
            return (
              <div className={classes.pageDiscover}>
                <LoadMoreDataSearchGlobal
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  renderItemList={renderItemList}
                  renderItemPost={renderItemPost}
                  renderItems={renderItems}
                  renderCardProduct={renderCardProduct}
                  groupSelected={groupSelected}
                  setGroupSelected={setGroupSelected}
                  flag={flag}
                  setFlag={setFlag}
                />
              </div>
            );
          }

          default: {
            return (
              <div className={classes.pageDiscover}>
                <Typography
                  variant="h5"
                  fontWeight={700}
                  sx={{ marginLeft: '20px' }}
                >
                  Gợi ý cho bạn
                </Typography>
              </div>
            );
          }
        }
      }

      case PATHS.GROUP_DETAIL: {
        return (
          <div className={classes.pageDiscover}>
            <LoadMoreDataSearchGlobal
              type="group"
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              renderItemList={renderItemList}
              renderItemPost={renderItemPost}
              groupSelected={groupSelected}
              setGroupSelected={setGroupSelected}
              flag={flag}
              setFlag={setFlag}
            />
          </div>
        );
      }

      case PATHS.PAGE_DETAIL: {
        return (
          <div className={classes.pageDiscover}>
            <LoadMoreDataSearchGlobal
              type="page"
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              renderItemList={renderItemList}
              renderItemPost={renderItemPost}
            />
          </div>
        );
      }
      case PATHS.RECRUIT_DETAIL: {
        return (
          <div className={classes.pageDiscover}>
            <LoadMoreDataSearchGlobal
              type="recruit"
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              renderItemList={renderItemList}
              renderItemPost={renderItemPost}
              renderListRecruit={renderListRecruit}
            />
          </div>
        );
      }
      case PATHS.COURSE_DETAIL: {
        return (
          <div className={classes.pageDiscover}>
            <LoadMoreDataSearchGlobal
              type="course"
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              renderItemList={renderItemList}
              renderItemPost={renderItemPost}
              renderListCourse={renderListCourse}
            />
          </div>
        );
      }
      case PATHS.EVENT_DETAIL: {
        return (
          <div className={classes.pageDiscover}>
            <LoadMoreDataSearchGlobal
              type="event"
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              renderItemList={renderItemList}
              renderItemPost={renderItemPost}
              renderListEvent={renderListEvent}
            />
          </div>
        );
      }
      case PATHS.GROW_DETAIL: {
        return (
          <div className={classes.pageDiscover}>
            <LoadMoreDataSearchGlobal
              type="grow"
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              renderItemList={renderItemList}
              renderItemPost={renderItemPost}
              renderListGrow={renderListGrow}
            />
          </div>
        );
      }

      case PATHS.PRODUCT_DETAIL: {
        return (
          <div className={classes.pageDiscover}>
            <LoadMoreDataSearchGlobal
              type="product"
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              renderItemList={renderItemList}
              renderItemPost={renderItemPost}
              renderCardProduct={renderCardProduct}
            />
          </div>
        );
      }
      case PATHS.USER: {
        return (
          <div className={classes.pageDiscover}>
            <LoadMoreDataSearchGlobal
              type="user"
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              renderItemList={renderItemList}
              renderItemPost={renderItemPost}
            />
          </div>
        );
      }
    }
  };

  return (
    <div className={classes.root}>
      {renderPageCenter()}
      {openSnackbar && (
        <SnackbarNotification
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          code={200}
          message={noti?.message}
        />
      )}
    </div>
  );
};

export default SearchGlobalCenter;
