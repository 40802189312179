import axios, { AxiosRequestConfig } from 'axios';
import { USER_TOKEN } from 'src/util/config';
import { BASE_ROOT } from 'src/util/config';

const fetchApi = async (endPoint, method, params, formdata) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: formdata
  };

  let response = await axios(configs);
  return response;
};
const fetchApiRequest = async (endPoint, method, params, _data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

export const getCalendarRecruitApi = async (params: any) => {
  return fetchApiRequest('recruits', 'GET', params, null);
};
export const getListApplyRecruit = async (params: any) => {
  return fetchApiRequest('recruit_applies', 'GET', params, null);
};

export const createUpdateRecruitApi = async (data: any, type: any) => {
  var formdata = new FormData();
  formdata.append('title', data.title);
  if (data.banner) {
    if (data.banner.file?.path) {
      formdata.append('banner[file]', data.banner.file);
    }

    // if (data.banner.id) {
    //   formdata.append('banner[id]', data.banner.id);
    //   formdata.append('banner[show_url]', data.banner.show_url);
    // }
  }
  if (data.work_experience) {
    formdata.append('work_experience', data.work_experience);
  }
  if (data.working_form) {
    formdata.append('working_form', data.working_form);
  }
  if (data.level) {
    formdata.append('level', data.level);
  }
  if (data.recruitments_count) {
    formdata.append('recruitments_count', data.recruitments_count);
  }
  if (data.gender) {
    formdata.append('gender', data.gender);
  }
  if (data.visibility) {
    formdata.append('visibility', data.visibility);
  }
  if (data.recruit_category_id) {
    formdata.append('recruit_category_id', data.recruit_category_id);
  }
  if (data.salary_min) {
    formdata.append('salary_min', data.salary_min);
  }
  if (data.salary_max) {
    formdata.append('salary_max', data.salary_max);
  }
  if (data.job_description) {
    formdata.append('job_description', data.job_description);
  }
  if (data.company_description) {
    formdata.append('company_description', data.company_description);
  }
  if (data.requirement) {
    formdata.append('requirement', data.requirement);
  }
  if (data.benefits) {
    formdata.append('benefits', data.benefits);
  }
  if (data.due_date) {
    formdata.append('due_date', data.due_date);
  }
  if (data.address) {
    formdata.append('address', data.address);
  }
  if (data.location) {
    formdata.append('location[lat]', data.location.lat);
    formdata.append('location[lng]', data.location.lng);
  }
  if (data.page_owner_id) {
    formdata.append('page_owner_id', data.page_owner_id);
  }
  if (data.page_id) {
    formdata.append('page_id', data.page_id);
  }
  return fetchApi(
    type === 'create' ? 'recruits' : `recruits/${type}`,
    type === 'create' ? 'POST' : 'PATCH',
    null,
    formdata
  );
};

export const getDetailRecruitApi = async (idRecruit: any) => {
  return fetchApi(`recruits/${idRecruit}`, 'GET', null, null);
};

export const getRecruitCategories = async () => {
  return fetchApi('recruit_categories', 'GET', null, null);
};

export const deleteRecruitApi = async (id: any) => {
  return fetchApi(`recruits/${id}`, 'DELETE', null, null);
};

// export const shareRecruitApi = async (params: any, idRecruit: any) => {
//   return fetchApi(`recruits/${idRecruit}/share`, 'POST', params, null);
// };

export const getTimelineRecruitApi = async (idRecruit: any, params: any) => {
  return fetchApi(`timelines/recruit/${idRecruit}`, 'GET', params, null);
};

export const createInviteRecruit = async (idRecruit: any, params: any) => {
  return fetchApi(`recruits/${idRecruit}/invitations`, 'POST', null, {
    target_account_ids: params
  });
};

export const interesteRecruitApi = async (idRecruit: any) => {
  return fetchApiRequest(
    `recruits/${idRecruit}/recruit_followers`,
    'POST',
    null,
    null
  );
};
export const noInteresteRecruitApi = async (idRecruit: any) => {
  return fetchApiRequest(
    `recruits/${idRecruit}/recruit_followers`,
    'DELETE',
    null,
    null
  );
};

export const getListInvitation = async (params: any) => {
  return fetchApiRequest('recruit_invitations', 'GET', params, null);
};

export const respondInvitationRecruit = async (idRecruit: any, params: any) => {
  return fetchApiRequest(
    `recruits/${idRecruit}/invitations/invitations_respond`,
    'POST',
    null,
    params
  );
};

export const getListHostOfRecruit = async (idRecruit: any, params: any) => {
  return fetchApiRequest(
    `/recruits/${idRecruit}/invitation_hosts`,
    'GET',
    params,
    null
  );
};

export const respondInvitationHostRecruit = async (
  idRecruit: any,
  data: any
) => {
  return fetchApiRequest(
    `recruits/${idRecruit}/invitation_hosts/invitations_respond`,
    'POST',
    null,
    data
  );
};

export const getListHostRecruit = async (idRecruit: any, params: any) => {
  return fetchApiRequest(`recruits/${idRecruit}/hosts`, 'GET', params, null);
};

export const getListFaqs = async (idRecruit: any) => {
  return fetchApiRequest(`recruits/${idRecruit}/faqs`, 'GET', null, null);
};

export const CRUDFaqs = async (type, idRecruit: any, params: any, data) => {
  return fetchApiRequest(
    `recruits/${idRecruit}/faqs${
      type === 'patch' || type === 'delete' ? '/' + params : ''
    }`,
    type,
    null,
    data
  );
};

export const createUpdateCV = async (data: any, idUser: any, type: any) => {
  return fetchApi(
    type === 'create'
      ? `accounts/${idUser}/account_resumes`
      : `accounts/${idUser}/account_resumes/${type}`,
    type === 'create' ? 'POST' : 'PATCH',
    null,
    data
  );
};

export const getListCV = async (idUser: any) => {
  return fetchApiRequest(
    `accounts/${idUser}/account_resumes`,
    'GET',
    null,
    null
  );
};

export const deleteCV = async (idUser: any, idCV: any) => {
  return fetchApi(
    `accounts/${idUser}/account_resumes/${idCV}`,
    'DELETE',
    null,
    null
  );
};

export const getListUserApplyRecruit = async (
  idRecruit: any,
  action: any,
  params = null
) => {
  return fetchApiRequest(
    `recruits/${idRecruit}/recruit_applies`,
    action,
    null,
    action === 'POST' ? params : null
  );
};
