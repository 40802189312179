import React from 'react';
import { Box, Tooltip, IconButton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { urlRocketChat } from 'src/util/config';
import { useTheme } from '@mui/material/styles';
import { collapseString } from 'src/common/string';
import 'src/pages/Chat/BoxChat/style.css';
import DetailMessageVideo from './DetailMessageVideo';
import DetailMessageImage from './DetailMessageImage';
import DetailMessageAudio from './DetailMessageAudio';
import DetailMessageFile from './DetailMessageFiles';
import { loadSurroundingMessageReply } from 'src/apis/socialChat.apis';
import { getMesseageReq } from 'src/store/action/socialChatAction';
import { checkMentions } from 'src/pages/Chat/BoxChat/Footer';
import parse from 'html-react-parser';
import { buttonColor } from 'src/constants/styles';
import { useRouteMatch } from 'react-router-dom';
const senderMessageStyle: any = {
  box: {
    marginRight: '5px !important',
    marginLeft: '0px',
    fontSize: 15,
    width: 'fit-content',
    backgroundColor: '#e4e6eb',
    borderRadius: '16px',
    color: '#040404',
    padding: '7px 12px 8px 12px',
    wordBreak: 'break-word',
    whiteSpace: 'normal'
  },
  boxReply: {
    height: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '4px'
  },
  messageReplied: {
    backgroundColor: '#f6f9fa',
    borderRadius: '16px 16px 16px 6px',
    justifyContent: 'flex-start',
    display: 'flex',
    padding: '6px 8px 25px 8px',
    marginTop: '2px',
    marginLeft: '8px',
    color: '#65676b',
    fontSize: '13px',
    fontWeight: '400'
  }
};

const myMessageStyle: any = {
  box: {
    marginLeft: '5px !important',
    borderRadius: '16px',
    color: 'white',
    fontSize: 15,
    width: 'fit-content',
    padding: '7px 12px 8px 12px',
    wordBreak: 'break-word',
    whiteSpace: 'normal'
  },
  boxReply: {
    height: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '4px',
    paddingBottom: '10px'
  },
  messageReplied: {
    backgroundColor: '#f6f9fa',
    borderRadius: '16px 16px 6px 16px',
    justifyContent: 'space-between',
    display: 'flex',
    padding: '6px 8px 10px 8px',
    marginTop: '2px',
    marginRight: '2px',
    color: '#65676b',
    fontSize: '13px',
    fontWeight: '400'
  }
};

const checkMentionsReply = (message, conversation) => {
  try {
    if (
      conversation.type === 'group' &&
      conversation?.members &&
      conversation?.members.length > 0
    ) {
      const regexp = /@\[([\w\s]+)\]/g;
      const text = message.replace(regexp, username => {
        const objectMentions = conversation.members.find(
          el => `@[${el._id}]` === username
        );

        if (objectMentions) {
          if (objectMentions.username !== 'all') {
            return `<span style="color:inherit;text-decoration:none; font-weight:'400px'"><b>@${objectMentions.name}</b></span>`;
          } else return `<b>@mọi người</b>`;
        } else {
          const regex = /@\[(\d+)\]\(([^)]+)\)/g;
          const result = message.replace(regex, '@$2');
          return result;
        }
      });
      let newText = text.replace(/\([^)]*\)/g, '');
      let result = '';
      if (newText) {
        result = newText?.replace(/\n/g, '<br/>');
      }

      return parse(`<p>${result}</p>`);
    } else {
      return message;
    }
  } catch (error) {
    console.log('[checkMentions]', error);
  }
};
function DetailMessageReply(props) {
  const {
    message,
    conversation,
    borderTopMessage,
    borderBottomMessage,
    showTimeSend,
    nextMessageShowTimeSend,
    renderPostReactEmojis,
    handleClickActionMessage,
    showAction,
    styleDivShowActionItem,
    renderToolTipEmoji,
    listActionMessages,
    setShowAction,
    anchorElMore,
    anchorElReact,
    typeBoxChat,
    mainTopic,
    repliedMessage,
    isPinnedMessage,
    getBorderRadius,
    isHighlightedMessage
  } = props;
  const isMessageChanged = message?.editedBy?.username;
  const userInfo = useSelector((state: any) => state.meReducer.info) || {};
  const rocketId = userInfo?.id;
  const username = userInfo?.username;
  const theme = useTheme();
  const match: any = useRouteMatch();

  const dispatch = useDispatch();
  const renderContentMessageReplies = message => {
    const messageContentReplied = message.split(') ')[1];
    if (message.attachments?.[0]?.text.startsWith(':like_')) {
      return (
        <Box
          sx={
            message.u?._id === rocketId
              ? myMessageStyle.messageReplied
              : senderMessageStyle.messageReplied
          }
          style={{
            background:
              message.attachments?.[0]?.text
                .startsWith(':like_')
                .startsWith(':like_') || containsEmoji(messageContentReplied)
                ? 'transparent'
                : isPinnedMessage
                ? theme.palette.mode === 'light'
                  ? '#E4E6EB'
                  : '#3e4042'
                : message.u?._id === rocketId
                ? mainTopic
                  ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
                  : buttonColor.backgroundColor
                : theme.palette.mode === 'light'
                ? '#E4E6EB'
                : '#3e4042',
            color: theme.palette.mode === 'light' ? '#65676B' : '#B0B3B8'
          }}
        >
          {conversation?.customFields?.icon ? (
            <span style={{ fontSize: 30 }}>
              {conversation?.customFields?.icon}
            </span>
          ) : (
            <i
              className="fa-sharp fa-solid fa-thumbs-up"
              style={{
                color: mainTopic ? mainTopic?.[1] : buttonColor.backgroundColor,
                fontSize: 30
              }}
            ></i>
          )}
        </Box>
      );
    }
    if (messageContentReplied.startsWith(':like_')) {
      return (
        <Box
          sx={
            message.u?._id === rocketId
              ? myMessageStyle.messageReplied
              : senderMessageStyle.messageReplied
          }
          style={{
            background:
              messageContentReplied.startsWith(':like_') ||
              containsEmoji(messageContentReplied)
                ? 'transparent'
                : isPinnedMessage
                ? theme.palette.mode === 'light'
                  ? '#E4E6EB'
                  : '#3e4042'
                : message.u?._id === rocketId
                ? mainTopic
                  ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
                  : buttonColor.backgroundColor
                : theme.palette.mode === 'light'
                ? '#E4E6EB'
                : '#3e4042',
            color: theme.palette.mode === 'light' ? '#65676B' : '#B0B3B8'
          }}
        >
          {conversation?.customFields?.icon ? (
            <span style={{ fontSize: 30 }}>
              {conversation?.customFields?.icon}
            </span>
          ) : (
            <i
              className="fa-sharp fa-solid fa-thumbs-up"
              style={{
                color: mainTopic ? mainTopic?.[1] : buttonColor.backgroundColor,
                fontSize: 30
              }}
            ></i>
          )}
        </Box>
      );
    } else if (
      messageContentReplied.startsWith(`https://sdk.mojitok.im/`) ||
      message.startsWith(`https://media`) ||
      messageContentReplied.startsWith(`https://media`)
    ) {
      return (
        <img
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            borderRadius: getBorderRadius(
              conversation,
              message,
              rocketId,
              borderTopMessage,
              borderBottomMessage,
              nextMessageShowTimeSend,
              showTimeSend
            )
          }}
          src={messageContentReplied}
          alt={messageContentReplied}
        />
      );
    } else if (containsEmoji(messageContentReplied)) {
      return (
        <span style={{ fontSize: 30 }}>{conversation?.customFields?.icon}</span>
      );
    } else {
      return collapseString(
        checkMentionsReply(messageContentReplied, conversation),
        60
      );
    }
  };
  const renderRepliedMessage = () => {
    const attachmentsMessageReplied = message?.attachments.find(
      el => el.attachments
    )?.attachments?.[0];

    const messageReplied = message?.attachments.find(
      el => el.message_link
    )?.text;
    const messageRepliedId = message?.attachments
      .find(el => el.message_link)
      ?.message_link.split(`msg=`)[1];
    let previousElementScroll;

    if (messageReplied.startsWith(':like_')) {
      return (
        <Box
          sx={
            message.u?._id === rocketId
              ? myMessageStyle.messageReplied
              : senderMessageStyle.messageReplied
          }
          style={{
            background:
              messageReplied.startsWith(':like_') ||
              containsEmoji(messageReplied)
                ? 'transparent'
                : isPinnedMessage
                ? theme.palette.mode === 'light'
                  ? '#E4E6EB'
                  : '#3e4042'
                : message.u?._id === rocketId
                ? mainTopic
                  ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
                  : buttonColor.backgroundColor
                : theme.palette.mode === 'light'
                ? '#E4E6EB'
                : '#3e4042',
            color: theme.palette.mode === 'light' ? '#65676B' : '#B0B3B8'
          }}
        >
          {conversation?.customFields?.icon ? (
            <span style={{ fontSize: 30 }}>
              {conversation?.customFields?.icon}
            </span>
          ) : (
            <i
              className="fa-sharp fa-solid fa-thumbs-up"
              style={{
                color: mainTopic ? mainTopic?.[1] : buttonColor.backgroundColor,
                fontSize: 30
              }}
            ></i>
          )}
        </Box>
      );
    }

    const handleScrollRepliedMessage = async () => {
      let elementScroll = await document.getElementById(`${messageRepliedId}`);
      if (elementScroll) {
        if (previousElementScroll) {
          await previousElementScroll.classList.remove(
            theme.palette.mode === 'light'
              ? `borderRepliedMessageLight`
              : 'borderRepliedMessageDark'
          );
        }
        await elementScroll.classList.add(
          theme.palette.mode === 'light'
            ? `borderRepliedMessageLight`
            : 'borderRepliedMessageDark'
        );
        await elementScroll.scrollIntoView({
          behavior: 'smooth',
          inline: 'center'
        });
        previousElementScroll = await elementScroll;
      } else {
        try {
          const response = await loadSurroundingMessageReply(
            message.attachments.find(el => el.text)
          );
          if (response.status === 200) {
            const data = JSON.parse(response.data.message).result.messages;
            if (data && data.length > 0) {
              const lastUpdated = data[1]._updatedAt.$date;
              dispatch(getMesseageReq(conversation, lastUpdated));
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    return (
      <Box
        style={{
          cursor: 'pointer',
          zIndex: 0
        }}
        onClick={handleScrollRepliedMessage}
      >
        {attachmentsMessageReplied ? (
          attachmentsMessageReplied.image_url ? (
            <img
              style={{
                maxWidth: '120px',
                height: 'auto',
                width: 'auto',
                borderRadius: '16px',
                opacity: 0.8,
                filter: 'grayscale(10%)'
              }}
              src={`${urlRocketChat}${attachmentsMessageReplied.image_url}`}
              alt={`${urlRocketChat}${attachmentsMessageReplied.image_url}`}
            />
          ) : attachmentsMessageReplied.video_url ? (
            <Box
              sx={
                message.u?._id === rocketId
                  ? myMessageStyle.messageReplied
                  : senderMessageStyle.messageReplied
              }
              style={{
                backgroundColor:
                  theme.palette.mode === 'light' ? '#F6F9FA' : '#4E4F50',
                color: theme.palette.mode === 'light' ? '#65676B' : '#B0B3B8'
              }}
            >
              <video
                src={attachmentsMessageReplied?.AmazonS3?.url}
                style={{
                  maxWidth: '168px',
                  maxHeight: '100px',
                  borderRadius: '16px',
                  opacity: 0.7,
                  filter: 'grayscale(10%)'
                }}
              />
            </Box>
          ) : attachmentsMessageReplied.audio_url ? (
            <Box
              sx={
                message.u?._id === rocketId
                  ? myMessageStyle.messageReplied
                  : senderMessageStyle.messageReplied
              }
              style={{
                backgroundColor:
                  theme.palette.mode === 'light' ? '#F6F9FA' : '#4E4F50',
                color: theme.palette.mode === 'light' ? '#65676B' : '#B0B3B8'
              }}
            >
              <div>
                <Typography
                  style={{
                    fontSize: 13,
                    fontWeight: '400'
                  }}
                >
                  File đính kèm
                  <i
                    style={{ marginLeft: '3px' }}
                    className="fa-light fa-paperclip"
                  ></i>
                </Typography>
              </div>
            </Box>
          ) : attachmentsMessageReplied.title_link ? (
            <Box
              sx={
                message.u?._id === rocketId
                  ? myMessageStyle.messageReplied
                  : senderMessageStyle.messageReplied
              }
              style={{
                backgroundColor:
                  theme.palette.mode === 'light' ? '#F6F9FA' : '#4E4F50',
                color: theme.palette.mode === 'light' ? '#65676B' : '#B0B3B8'
              }}
            >
              <div>
                <Typography
                  style={{
                    fontSize: 13,
                    fontWeight: '400'
                  }}
                >
                  File đính kèm
                  <i
                    style={{ marginLeft: '3px' }}
                    className="fa-light fa-paperclip"
                  ></i>
                </Typography>
              </div>
            </Box>
          ) : messageReplied.startsWith(`[ ](${urlRocketChat}/`) ? (
            <Box
              sx={
                isPinnedMessage
                  ? senderMessageStyle.messageReplied
                  : (message.u?._id !== rocketId && conversation.t !== 'cp') ||
                    (message &&
                      conversation.t === 'cp' &&
                      message.isPage === false &&
                      match?.params?.key !== 'chat') ||
                    (message &&
                      conversation.t === 'cp' &&
                      message.isPage &&
                      match?.params?.key === 'chat')
                  ? myMessageStyle.messageReplied
                  : senderMessageStyle.messageReplied
              }
              style={{
                backgroundColor:
                  theme.palette.mode === 'light' ? '#F6F9FA' : '#4E4F50',
                color: theme.palette.mode === 'light' ? '#65676B' : '#B0B3B8',
                maxWidth: typeBoxChat === 'fullScreen' ? '100%' : '150px',
                justifyContent: 'end',
                wordBreak: 'break-word'
              }}
            >
              {/* {collapseString(messageReplied.split(') ')[1], 60)} */}
              {renderContentMessageReplies(messageReplied)}
            </Box>
          ) : (
            <Box
              sx={
                message.u?._id === rocketId
                  ? myMessageStyle.messageReplied
                  : senderMessageStyle.messageReplied
              }
              style={{
                backgroundColor:
                  theme.palette.mode === 'light' ? '#F6F9FA' : '#4E4F50',
                color: theme.palette.mode === 'light' ? '#65676B' : '#B0B3B8'
              }}
            >
              <div>
                <Typography
                  style={{
                    fontSize: 13,
                    fontWeight: '400'
                  }}
                >
                  File đính kèm
                  <i
                    style={{ marginLeft: '3px' }}
                    className="fa-light fa-paperclip"
                  ></i>
                </Typography>
              </div>
            </Box>
          )
        ) : messageReplied.startsWith(`[ ](${urlRocketChat}/`) ? (
          <Box
            sx={
              isPinnedMessage
                ? senderMessageStyle.messageReplied
                : message.u?._id === rocketId
                ? myMessageStyle.messageReplied
                : senderMessageStyle.messageReplied
            }
            style={{
              backgroundColor:
                theme.palette.mode === 'light' ? '#F6F9FA' : '#4E4F50',
              color: theme.palette.mode === 'light' ? '#65676B' : '#B0B3B8',
              maxWidth: typeBoxChat === 'fullScreen' ? '100%' : '150px'
            }}
          >
            {/* {collapseString(messageReplied.split(') ')[1], 60)} */}
            {renderContentMessageReplies(messageReplied)}
          </Box>
        ) : messageReplied.startsWith('https://media') ? (
          <Box
            sx={
              message.u?._id === rocketId
                ? myMessageStyle.messageReplied
                : senderMessageStyle.messageReplied
            }
            style={{
              backgroundColor:
                theme.palette.mode === 'light' ? '#F6F9FA' : '#4E4F50',
              color: theme.palette.mode === 'light' ? '#65676B' : '#B0B3B8'
            }}
          >
            <img
              src={messageReplied}
              alt="gif"
              style={{
                width: '140px',
                height: '120px',
                borderRadius: '16px',
                opacity: 0.7,
                filter: 'grayscale(10%)'
              }}
            />
          </Box>
        ) : messageReplied.startsWith('https://sdk.mojitok.im/') ? (
          <img
            style={{
              maxWidth: '80px',
              height: 'auto',
              width: 'auto',
              borderRadius: '16px'
            }}
            src={`${messageReplied}`}
            alt={`${messageReplied}`}
          />
        ) : (
          <Box
            sx={
              isPinnedMessage
                ? senderMessageStyle.messageReplied
                : message.u?._id === rocketId
                ? myMessageStyle.messageReplied
                : senderMessageStyle.messageReplied
            }
            style={{
              backgroundColor:
                theme.palette.mode === 'light' ? '#F6F9FA' : '#4E4F50',
              color: theme.palette.mode === 'light' ? '#65676B' : '#B0B3B8',
              width: 'fit-content',
              maxWidth: typeBoxChat === 'fullScreen' ? '100%' : '150px'
            }}
          >
            {messageReplied.includes('@[') ? (
              collapseString(
                checkMentionsReply(messageReplied, conversation),
                60
              )
            ) : (
              <p style={{ wordBreak: 'break-word' }}>
                {collapseString(messageReplied, 60)}
              </p>
            )}
          </Box>
        )}
      </Box>
    );
  };

  const getName = user => {
    if (user && user.username === username) {
      return 'Bạn';
    }
    return user?.name?.split(' ').slice(-1);
  };

  const getAllContentMessage = () => {
    const { md, msg } = message;

    if (md?.[2]?.value?.length > 1) {
      const content = `${md[0]?.value?.[1]?.value}${md[2]?.value[0]?.value}${md[2]?.value[1]?.value?.src?.value}`;
      return content;
    }

    if (message.attachments.length > 1) {
      const typeAttachment = message.attachments.find(el => el.type === 'file');
      if (typeAttachment && typeAttachment.image_type) {
        return (
          <DetailMessageImage
            message={message}
            borderTopMessage={borderTopMessage}
            borderBottomMessage={borderBottomMessage}
            showTimeSend={showTimeSend}
            nextMessageShowTimeSend={nextMessageShowTimeSend}
            renderPostReactEmojis={renderPostReactEmojis}
            meInfo={userInfo}
            conversation={conversation}
            getBorderRadius={getBorderRadius}
          />
        );
      } else if (typeAttachment && typeAttachment.video_type) {
        return (
          <DetailMessageVideo
            message={message}
            conversation={conversation}
            renderPostReactEmojis={renderPostReactEmojis}
            type={typeBoxChat}
          />
        );
      } else if (typeAttachment && typeAttachment.audio_type) {
        return (
          <DetailMessageAudio
            message={message}
            // reactions={reactions}
            // totalReactions={totalReactions}
            // classes={classes}
            renderPostReactEmojis={renderPostReactEmojis}
            mainTopic={mainTopic}
            theme={theme}
            conversation={conversation}
          />
        );
      } else {
        return (
          <DetailMessageFile
            message={message}
            borderTopMessage={borderTopMessage}
            borderBottomMessage={borderBottomMessage}
            showTimeSend={showTimeSend}
            nextMessageShowTimeSend={nextMessageShowTimeSend}
            renderPostReactEmojis={renderPostReactEmojis}
            conversation={conversation}
            mainTopic={mainTopic}
            getBorderRadius={getBorderRadius}
            theme={theme}
          />
        );
      }
    }

    if (msg.startsWith(`[ ](${urlRocketChat}`)) {
      const messageContentReplied = msg.split(') ')[1];
      if (messageContentReplied.startsWith(':like_')) {
        return (
          <i
            className="fa-sharp fa-solid fa-thumbs-up"
            style={{
              color: mainTopic ? mainTopic?.[1] : buttonColor.backgroundColor,
              fontSize: 30
            }}
          ></i>
        );
      } else if (
        messageContentReplied.startsWith(`https://sdk.mojitok.im/`) ||
        messageContentReplied.startsWith(`https://media`)
      ) {
        let style;
        if (messageContentReplied.startsWith('https://sdk.mojitok.im')) {
          style = {
            width: '64px',
            height: '64px'
          };
        } else {
          style = {
            width: '160px',
            height: 'auto'
          };
        }
        return (
          <img
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              borderRadius: getBorderRadius(
                conversation,
                message,
                rocketId,
                borderTopMessage,
                borderBottomMessage,
                nextMessageShowTimeSend,
                showTimeSend
              ),
              ...style
            }}
            src={messageContentReplied}
            alt={messageContentReplied}
          />
        );
      } else if (containsEmoji(messageContentReplied)) {
        return (
          <span style={{ fontSize: 30 }}>
            {conversation?.customFields?.icon}
          </span>
        );
      } else {
        return messageContentReplied;
      }
    }

    return msg;
  };
  function containsEmoji(text) {
    const emojiRegex = /[\u{1F600}-\u{1F6FF}]/u; // Biểu thức chính quy để phân biệt biểu tượng cảm xúc
    return emojiRegex.test(text);
  }

  return (
    <Box
      id={message?._id}
      onMouseEnter={(event: any) => {
        event.preventDefault();
        setShowAction(true);
      }}
      onMouseLeave={() => {
        if (Boolean(anchorElReact) || Boolean(anchorElMore)) {
          return;
        } else {
          setShowAction(false);
        }
      }}
      style={
        isPinnedMessage
          ? senderMessageStyle.boxReply
          : (message.u?._id === rocketId && conversation.t !== 'cp') ||
            (message &&
              conversation.t === 'cp' &&
              message.isPage === false &&
              match?.params?.key !== 'chat') ||
            (message &&
              conversation.t === 'cp' &&
              message.isPage &&
              match?.params?.key === 'chat')
          ? myMessageStyle.boxReply
          : senderMessageStyle.boxReply
      }
    >
      <Box
        sx={{
          height: '21px',
          width: 'auto',
          fontSize: '12px',
          fontWeight: '400',
          position: 'relative',
          color: '#8f9296'
        }}
      >
        <i style={{ marginRight: '2px' }} className="fa-solid fa-reply"></i>

        {message.attachments?.[0]?.author_name === userInfo?.username ||
        message.attachments?.[0]?.author_name === message?.u?.username // check who send message is replied
          ? message.attachments?.[0]?.author_name === message?.u?.username
            ? `${getName(message.u)} đã trả lời chính mình`
            : `${getName(message.u)} đã trả lời bạn`
          : `${getName(message.u)} đã trả lời ${
              message.attachments
                .find(el => el.author_display_name)
                ?.author_display_name.split(' ')
                .slice(-1) || message?.u.name.split(' ').slice(-1)
            }`}
      </Box>
      {renderRepliedMessage()}
      <div style={{ position: 'relative' }}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: isPinnedMessage
              ? 'row'
              : (message.u?._id === rocketId && conversation.t !== 'cp') ||
                (message &&
                  conversation.t === 'cp' &&
                  message.isPage === false &&
                  match?.params?.key !== 'chat') ||
                (message &&
                  conversation.t === 'cp' &&
                  message.isPage &&
                  match?.params?.key === 'chat')
              ? 'row-reverse'
              : 'row',
            marginTop:
              message?.msg.includes(`https://sdk.mojitok.im/`) ||
              message?.msg.includes(`https://media`)
                ? '-5px'
                : '-25px'
          }}
        >
          <Box
            sx={
              isPinnedMessage
                ? senderMessageStyle.box
                : (message.u?._id !== rocketId && conversation.t !== 'cp') ||
                  (message &&
                    conversation.t === 'cp' &&
                    message.isPage === false &&
                    match?.params?.key !== 'chat') ||
                  (message &&
                    conversation.t === 'cp' &&
                    message.isPage &&
                    match?.params?.key === 'chat')
                ? myMessageStyle.box
                : senderMessageStyle.box
            }
            style={{
              display: 'flex',
              flex: 1,
              background:
                message?.msg.split(') ')[1].startsWith(':like_') ||
                containsEmoji(message?.msg) ||
                containsEmoji(message?.msg.split(') ')[1]) ||
                message?.msg.includes(`https://sdk.mojitok.im/`) ||
                message?.msg.includes(`https://media`) ||
                message.attachments.length > 1 ||
                message.attachments.length > 1
                  ? 'transparent'
                  : isPinnedMessage
                  ? theme.palette.mode === 'light'
                    ? '#E4E6EB'
                    : '#3e4042'
                  : (message.u?._id === rocketId && conversation.t !== 'cp') ||
                    (message &&
                      conversation.t === 'cp' &&
                      message.isPage === false &&
                      match?.params?.key !== 'chat') ||
                    (message &&
                      conversation.t === 'cp' &&
                      message.isPage &&
                      match?.params?.key === 'chat')
                  ? mainTopic
                    ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
                    : buttonColor.backgroundColor
                  : theme.palette.mode === 'light'
                  ? '#E4E6EB'
                  : '#3e4042',
              position: 'relative',
              color:
                message.u?._id === rocketId
                  ? '#fff'
                  : theme.palette.mode === 'light'
                  ? '#050505'
                  : '#fff',
              borderRadius: borderTopMessage
                ? getBorderRadius(
                    conversation,
                    message,
                    rocketId,
                    borderTopMessage,
                    borderBottomMessage,
                    nextMessageShowTimeSend,
                    showTimeSend
                  )
                : '12px',
              maxWidth: typeBoxChat === 'fullScreen' ? '560px' : 'unset',
              padding:
                message?.msg.includes(`https://sdk.mojitok.im/`) ||
                message?.msg.includes(`https://media`)
                  ? 'unset'
                  : '7px 12px 8px 12px'
            }}
          >
            {isMessageChanged ? (
              <Tooltip
                title={`Đã chỉnh sửa`}
                arrow
                placement="top"
                sx={{
                  maxWidth: '120px'
                }}
              >
                <Box>
                  <i
                    style={{
                      fontSize: '12px',
                      opacity: 0.75,
                      padding: '0 4px'
                    }}
                    className="fa-light fa-pen"
                  ></i>
                </Box>
              </Tooltip>
            ) : null}
            {conversation &&
            conversation.type === 'group' &&
            message &&
            message.mentions &&
            message.mentions.length > 0 ? (
              checkMentions(message)
            ) : (
              <Box
                sx={{
                  background: isHighlightedMessage ? '#fafafa' : '',
                  color: isHighlightedMessage ? '#373536' : '',
                  whiteSpace: 'pre-line'
                }}
              >
                {getAllContentMessage()}
              </Box>
            )}
            {message.reactions ? renderPostReactEmojis() : null}
          </Box>

          {showAction ? (
            <Box
              className={styleDivShowActionItem}
              style={{ width: '30px !important', height: '30px' }}
            >
              {renderToolTipEmoji()}
              {listActionMessages.reverse().map((el, index) => {
                return (
                  <Tooltip title={el.title} placement="top" key={index}>
                    <IconButton
                      sx={{ width: '14px', height: '14px' }}
                      onClick={e => handleClickActionMessage(el.id, e)}
                    >
                      <i
                        style={{ fontSize: '15px', color: '#8f9296' }}
                        className={el.icon}
                      />
                    </IconButton>
                  </Tooltip>
                );
              })}
            </Box>
          ) : (
            <Box sx={{ height: '30px', width: '60px' }}></Box>
          )}
        </Box>
      </div>
    </Box>
  );
}

export default DetailMessageReply;
