/* eslint-disable jsx-a11y/alt-text */ import React from 'react';
import { Typography, ListItem, Box, Tooltip, Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { PATHS } from 'src/constants/paths';
import { useTheme } from '@mui/material/styles';

import { collapseString } from 'src/common/string';
import { useSelector } from 'react-redux';
import DetailMessageImage from './DetailMessageImage';
import DetailMessageVideo from './DetailMessageVideo';
import DetailMessageFile from './DetailMessageFiles';
import DetailMessageAudio from './DetailMessageAudio';
import DetailMessageReply from './DetailMessageReply';

import { handleTimeShow } from 'src/helpers/string';

import RenderReactionImg from 'src/components/ReactEmoji/RenderReactionImg';
import parse from 'html-react-parser';
import { classifyTypeMessage } from '../chatCommon';
import { useRouteMatch } from 'react-router-dom';
import { urlify } from 'src/helpers/common';
import { checkMentions } from 'src/pages/Chat/BoxChat/Footer';
import moment from 'moment';
import { DetailMessageVoted } from './DetailMessageVoted';
import { buttonColor } from 'src/constants/styles';
const senderMessageStyle: any = {
  item: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: 'auto',
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    padding: '2px 0px 0px 8px'
  },
  box: {
    marginRight: '5px !important',
    marginLeft: '0px',
    fontSize: 15,
    width: 'fit-content',
    backgroundColor: '#e4e6eb',
    borderRadius: '16px 16px 16px 16px',
    color: '#040404',
    padding: '7px 12px 8px 12px',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    textAlign: 'inherit',
    position: 'relative'
  },
  hideText: {
    maxWidth: 220,
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden'
  },
  forwardMessage: {
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',

    gap: 1,
    fontSize: 10
  }
};

const myMessageStyle: any = {
  item: {
    float: 'right',
    justifyContent: 'flex-end',
    display: 'flex',
    width: 'fit-content !100%',
    height: '100%',
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    padding: '10px 0px 10px 0px',
    border: 'none',
    marginRight: '10px'
  },
  box: {
    // backgroundColor: buttonColor.backgroundColor,
    borderRadius: '16px 16px 16px 16px',
    color: 'white',

    fontSize: 15,
    width: 'fit-content',
    padding: '7px 12px 8px 12px',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    textAlign: 'inherit',
    position: 'relative'
  },
  forwardMessage: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',

    gap: 1,
    fontSize: 10
  }
};

const reactConvert = [
  {
    id: 0, // like
    type: 'like',
    name: ':thumbsup:'
  },
  {
    id: 1, // tim
    type: 'love',
    name: ':heart:'
  },
  {
    id: 2, // haha
    type: 'haha',
    name: ':laughing:'
  },
  {
    id: 3, // thuong thuong
    type: 'yay',
    name: ':relaxed:'
  },
  {
    id: 4, // wow
    type: 'wow',
    name: ':scream:'
  },
  {
    id: 5, // buon
    type: 'sad',
    name: ':cry:'
  },
  {
    id: 6, // phan no
    type: 'angry',
    name: ':rage:'
  }
];

interface MessageProps {
  message: any;
  messageNext?: any;
  showAvatar?: Boolean;
  conversation?: any;
  isFirstIndex?: boolean;
  borderTopMessage?: any;
  borderBottomMessage?: any;
  showTimeSend?: any;
  nextMessageShowTimeSend?: any;
  repliedMessage?: any;
  // isChatPage?: string | undefined;
}

const Index: React.FC<MessageProps> = React.memo(
  ({
    message,
    messageNext,
    conversation,
    borderBottomMessage,
    showTimeSend,
    nextMessageShowTimeSend
  }) => {
    // const mainTopic = conversation?.RoomsList?.[0]?.topic;
    const mainTopic = conversation?.topic
      ? conversation?.topic?.colors
      : conversation?.RoomsList?.[0]?.topic?.colors || [
          buttonColor.backgroundColor,
          buttonColor.backgroundColor
        ];
    const match: any = useRouteMatch();
    const theme = useTheme();

    const useStyles = makeStyles(
      (theme: Theme) =>
        createStyles({
          reactionCount: {
            position: 'absolute',
            bottom: '-15px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'transparent',
            borderRadius: 10,
            '&:hover': {
              cursor: 'pointer'
            },
            visibility: 'visible',
            color: '#000',
            paddingRight: '2px'
          },
          styleDivLink: {
            width: '100%',
            height: 'auto',
            padding: '12px',
            backgroundColor:
              theme.palette.mode === 'light' ? '#f0f2f5' : '#3a3b3c',
            borderRadius: '0px 0px 8px 8px'
          },
          styleDivShowAction: {
            width: '60px !important',
            marginBottom: '2px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            border: 'none'
          },
          styleDivShowAction_item: {
            width: '60px',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },
          styleDivAvatar: {
            width: '20px',
            display: 'flex',
            flexDirection: 'column-reverse',
            border: 'none'
          },
          styleDivIconCircle: { width: '20px', height: '30px' },
          styleShowAvatarSender: {
            height: '100%',
            display: 'flex',
            alignItems: 'flex-end',
            border: 'none'
          },
          styleDivConversation: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            position: 'relative'
          },
          styleDefaultSender: {
            width: '60px !important',
            // height: '100%',
            marginRight: '5px',
            marginBottom: '2px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            border: 'none'
          },
          styleForwardMessage: {
            color: theme.palette.mode === 'light' ? '#20262E' : '#E9E8E8',
            fontSize: '10px',
            display: 'flex',
            gap: 2
          },
          styleButtonActionMessage: {
            width: '100%',
            height: '36px',
            padding: 0
          },
          styleTitleActionMessage: {
            fontSize: '14px',
            fontWeight: '700'
          }
        }),
      { index: 1 }
    );
    let reactions;
    let totalReactions = 0;
    if (!!message?.reactions) {
      reactions = [
        {
          type: 'like',
          likes_count:
            message.reactions[
              `${reactConvert.find(el => el.type === 'like')?.name}`
            ]?.usernames?.length ?? 0,
          username:
            message.reactions[
              `${reactConvert.find(el => el.type === 'like')?.name}`
            ]?.names.map((item: any) => item.name) ?? []
        },
        {
          type: 'haha',
          hahas_count:
            message.reactions[
              `${reactConvert.find(el => el.type === 'haha')?.name}`
            ]?.usernames?.length ?? 0,
          username:
            message.reactions[
              `${reactConvert.find(el => el.type === 'haha')?.name}`
            ]?.names.map((item: any) => item.name) ?? []
        },
        {
          type: 'angry',
          angrys_count:
            message.reactions[
              `${reactConvert.find(el => el.type === 'angry')?.name}`
            ]?.usernames?.length ?? 0,
          username:
            message.reactions[
              `${reactConvert.find(el => el.type === 'angry')?.name}`
            ]?.names.map((item: any) => item.name) ?? []
        },
        {
          type: 'love',
          loves_count:
            message.reactions[
              `${reactConvert.find(el => el.type === 'love')?.name}`
            ]?.usernames?.length ?? 0,
          username:
            message.reactions[
              `${reactConvert.find(el => el.type === 'love')?.name}`
            ]?.names.map((item: any) => item.name) ?? []
        },
        {
          type: 'sad',
          sads_count:
            message.reactions[
              `${reactConvert.find(el => el.type === 'sad')?.name}`
            ]?.usernames?.length ?? 0,
          username:
            message.reactions[
              `${reactConvert.find(el => el.type === 'sad')?.name}`
            ]?.names.map((item: any) => item.name) ?? []
        },
        {
          type: 'wow',
          wows_count:
            message.reactions[
              `${reactConvert.find(el => el.type === 'wow')?.name}`
            ]?.usernames?.length ?? 0,
          username:
            message.reactions[
              `${reactConvert.find(el => el.type === 'wow')?.name}`
            ]?.names.map((item: any) => item.name) ?? []
        },
        {
          type: 'yay',
          yays_count:
            message.reactions[
              `${reactConvert.find(el => el.type === 'yay')?.name}`
            ]?.usernames?.length ?? 0,
          username:
            message.reactions[
              `${reactConvert.find(el => el.type === 'yay')?.name}`
            ]?.names.map((item: any) => item.name) ?? []
        }
      ];
      Object.values(message.reactions).map((el: any) => {
        totalReactions += el.usernames.length;
      });
    }

    const classes = useStyles();

    const isForward = message?.type === 'forward';

    const [isShowIframe, setIsShowIframe] = React.useState(false);
    const meInfo = useSelector((state: any) => state.meReducer.info) || {};
    const rocketId = meInfo?.id || '';

    const isMessageChanged = message?.editedBy?.username;
    const type = classifyTypeMessage(message);
    const subjectName =
      message?.u?.username === meInfo?.username
        ? 'Bạn'
        : message?.u?.name.split(' ')[0];

    const renderLinkMess = (urls: any, message: any) => {
      let newText = message.msg.replace(/@\[([\w\s]+)\]|\([^)]*\)/g, '');
      urls.forEach((url: any) => {
        newText = newText.replace(
          new RegExp(url.url, 'g'),
          `<a href="${url.url}" target="_blank" style="text-decoration: underline; font-weight: 500; cursor: pointer; color:unset ">${url.url}</a>`
        );
      });

      return parse(newText);
    };
    const renderIconLike = (fontSize: any) => {
      return (
        <div>
          {conversation?.customFields?.icon ? (
            <Typography style={{ fontSize: fontSize }}>
              {conversation?.customFields?.icon}
            </Typography>
          ) : (
            <i
              className="fa-sharp fa-solid fa-thumbs-up"
              style={{
                color: mainTopic ? mainTopic[0] : buttonColor.backgroundColor,
                fontSize: fontSize
              }}
            ></i>
          )}
        </div>
      );
    };
    const renderPostReactEmojis = () => {
      return (
        <div
          className={classes.reactionCount}
          style={
            message.u?._id === rocketId ? { left: '10px' } : { right: '10px' }
          }
        >
          <span>
            <RenderReactionImg listReaction={reactions} isMessage />
          </span>
          {totalReactions > 1 && (
            <span
              style={{
                fontSize: '12px',
                paddingLeft: '2px',
                color: theme.palette.mode === 'dark' ? '#ADAFB5' : '#757575'
              }}
            >
              {totalReactions}
            </span>
          )}
        </div>
      );
    };
    // const getBorderRadius = (
    //   conversation,
    //   message,
    //   rocketId,
    //   borderTopMessage,
    //   borderBottomMessage,
    //   nextMessageShowTimeSend,
    //   showTimeSend
    // ) => {
    //   const isCpConversation =
    //     conversation &&
    //     !conversation.user_chat_page &&
    //     conversation?.t !== 'cp';
    //   const isMyMessage =
    //     conversation && !conversation.user_chat_page
    //       ? message.u?._id === rocketId
    //       : message?.u._id !== conversation?.user_chat_page?._id;
    //   const isMessageFromOtherUser =
    //     conversation &&
    //     conversation.user_chat_page &&
    //     message.u?._id !== conversation?.user_chat_page?._id;
    //   const isGrConversation = conversation?.type !== 'group';
    //   const isUserConversation = conversation?.type === ' user';
    //   if (isCpConversation && isGrConversation && isUserConversation) {
    //     return isMessageFromOtherUser
    //       ? getMyMessageBorderRadius(
    //           borderTopMessage,
    //           borderBottomMessage,
    //           nextMessageShowTimeSend,
    //           showTimeSend
    //         )
    //       : getOtherUserMessageBorderRadius(
    //           borderTopMessage,
    //           borderBottomMessage,
    //           nextMessageShowTimeSend,
    //           showTimeSend
    //         );
    //   }
    //   return isMyMessage
    //     ? getMyMessageBorderRadius(
    //         borderTopMessage,
    //         borderBottomMessage,
    //         nextMessageShowTimeSend,
    //         showTimeSend
    //       )
    //     : getOtherUserMessageBorderRadius(
    //         borderTopMessage,
    //         borderBottomMessage,
    //         nextMessageShowTimeSend,
    //         showTimeSend
    //       );
    // };

    // const getMyMessageBorderRadius = (
    //   borderTopMessage,
    //   borderBottomMessage,
    //   nextMessageShowTimeSend,
    //   showTimeSend
    // ) => {
    //   if (borderTopMessage || showTimeSend) {
    //     if (borderBottomMessage || nextMessageShowTimeSend) {
    //       return '16px'; // my message border top + bottom
    //     }

    //     return '16px 16px 4px 16px'; // my message border only top
    //   }

    //   if (borderBottomMessage || nextMessageShowTimeSend) {
    //     return '16px 4px 16px 16px'; // my message border only bottom
    //   }

    //   return '16px 4px 4px 16px'; // my message not border
    // };

    // const getOtherUserMessageBorderRadius = (
    //   borderTopMessage,
    //   borderBottomMessage,
    //   nextMessageShowTimeSend,
    //   showTimeSend
    // ) => {
    //   if (borderTopMessage || showTimeSend) {
    //     if (borderBottomMessage || nextMessageShowTimeSend) {
    //       return '16px'; // sender message border top + bottom
    //     }

    //     return '16px 16px 16px 4px'; // sender message border only top
    //   }

    //   if (borderBottomMessage || nextMessageShowTimeSend) {
    //     return '4px 16px 16px 16px'; // sender message border only bottom
    //   }

    //   return '4px 16px 16px 4px'; // sender message not border
    // };

    const renderContentMessage = message => {
      const typeMessage = classifyTypeMessage(message);

      switch (typeMessage) {
        case 'message': {
          if (message?.msg === ':like_small:') {
            return renderIconLike(30);
          } else if (message?.msg === ':like_big:') {
            return renderIconLike(60);
          } else if (message?.msg === ':like_largest:') {
            return renderIconLike(100);
          } else
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems:
                    message?.u?._id === rocketId ? 'flex-end' : 'flex-start',
                  flexDirection: 'column'
                }}
              >
                <Box
                  id={message?._id}
                  sx={senderMessageStyle.box}
                  style={{
                    position: 'relative',
                    width: 'fit-content',
                    backgroundColor:
                      theme.palette.mode === 'light' ? '#e4e6eb' : '#3e4042',
                    color: theme.palette.mode === 'light' ? '#050505' : '#fff',

                    display: 'flex',
                    justifyContent: 'center',
                    gap: 2,
                    alignItems: 'center',
                    opacity: isForward ? 0.9 : 1
                  }}
                >
                  {isForward ? <i className="fa-solid fa-tally-1"></i> : null}
                  {isMessageChanged ? (
                    <Tooltip
                      title={`Đã chỉnh sửa`}
                      arrow
                      placement="top"
                      sx={{
                        maxWidth: '120px'
                      }}
                    >
                      <Box>
                        <i
                          style={{
                            fontSize: '12px',
                            opacity: 0.75,
                            padding: '0 4px'
                          }}
                          className="fa-light fa-pen"
                        ></i>
                      </Box>
                    </Tooltip>
                  ) : null}
                  <div>
                    {message?.msg}
                    {message?.reactions &&
                    ![
                      'image',
                      'video',
                      'gif',
                      'reply',
                      'link',
                      'audio'
                    ].includes(classifyTypeMessage(message))
                      ? renderPostReactEmojis()
                      : null}
                  </div>
                </Box>
              </div>
            );
        }

        case 'image': {
          return (
            <DetailMessageImage
              message={message}
              showTimeSend={showTimeSend}
              // nextMessageShowTimeSend={nextMessageShowTimeSend}
              renderPostReactEmojis={renderPostReactEmojis}
              meInfo={meInfo}
              conversation={conversation}
            />
          );
        }

        case 'video': {
          return (
            <DetailMessageVideo
              message={message}
              conversation={conversation}
              renderPostReactEmojis={renderPostReactEmojis}
            />
          );
        }

        case 'text':
        case 'application': {
          return (
            <DetailMessageFile
              message={message}
              classes={classes}
              borderBottomMessage={borderBottomMessage}
              showTimeSend={showTimeSend}
              // nextMessageShowTimeSend={nextMessageShowTimeSend}
              renderPostReactEmojis={renderPostReactEmojis}
              conversation={conversation}
              mainTopic={mainTopic}
              theme={theme}
            />
          );
        }

        case 'audio': {
          return (
            <DetailMessageAudio
              message={message}
              reactions={reactions}
              totalReactions={totalReactions}
              classes={classes}
              renderPostReactEmojis={renderPostReactEmojis}
              mainTopic={mainTopic}
              theme={theme}
              conversation={conversation}
            />
          );
        }

        case 'link': {
          const imageUrl =
            message?.metaEmso && Object.keys(message?.metaEmso).length > 0
              ? message?.metaEmso?.response?.image_url
              : message.urls[0]?.meta?.ogImage ??
                message.urls[0]?.meta?.oembedThumbnailUrl;
          return (
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              id={message?._id}
            >
              <Box
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  // borderRadius: '8px 8px 8px 8px',
                  width: match?.path === PATHS.CHAT ? '300px' : '206px',
                  position: 'relative'
                }}
                sx={{
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
                onClick={() => {
                  window.open(message.urls[0].url, '_blank');
                }}
              >
                {/* header */}
                <div
                  style={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor:
                      message?.u?._id === rocketId
                        ? mainTopic
                          ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
                          : buttonColor.backgroundColor
                        : theme.palette.mode === 'light'
                        ? '#f0f2f5'
                        : '#3e4042',
                    padding: '8px 12px',
                    color:
                      theme.palette.mode === 'light' ? '#000000' : '#e4e6eb',
                    borderRadius: '8px 8px 0px 0px',
                    fontWeight: '400'
                  }}
                >
                  <Typography fontSize="15px" fontWeight="500" textAlign="left">
                    {renderLinkMess(message?.urls, message)}
                  </Typography>
                </div>
                {/* image body */}
                <img
                  style={{
                    width: match?.path === PATHS.CHAT ? '300px' : '206px',
                    height: 'auto'
                  }}
                  src={imageUrl}
                  alt={message.urls[0]?.meta?.ogImage}
                />
                {/* link footer */}
                <div className={classes.styleDivLink}>
                  <Typography
                    textAlign="left"
                    fontSize="14px"
                    fontWeight="600"
                    sx={{
                      lineHeight: '1.333333',
                      wordBreak: 'break-word',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    {collapseString(
                      message?.urls[0]?.meta?.ogTitle ??
                        message?.urls[0]?.meta?.pageTitle,
                      70
                    )}
                  </Typography>
                </div>
                {message?.reactions ? renderPostReactEmojis() : null}
              </Box>
            </div>
          );
        }

        case 'vote': {
          return (
            <DetailMessageVoted
              message={message}
              conversation={conversation}
              myMessageStyle={myMessageStyle}
              senderMessageStyle={senderMessageStyle}
              mainTopic={mainTopic}
              renderPostReactEmojis={renderPostReactEmojis}
              // typeBoxChat={typeBoxChat}
            />
          );
        }

        case 'reply': {
          return (
            <DetailMessageReply
              message={message}
              conversation={conversation}
              reactions={reactions}
              totalReactions={totalReactions}
              classes={classes}
              checkMentions={checkMentions}
              showTimeSend={showTimeSend}
              // nextMessageShowTimeSend={nextMessageShowTimeSend}
              renderPostReactEmojis={renderPostReactEmojis}
              // styleDivShowAction={classes.styleDivShowAction}
              // styleDefaultSender={classes.styleDefaultSender}
              // styleDivShowActionItem={classes.styleDivShowAction_item}
              // listActionMessages={listActionMessages}
              mainTopic={mainTopic}
              isPinnedMessage={true}
            />
          );
        }

        case 'gif': {
          let style;
          if (message.msg.startsWith('https://sdk.mojitok.im')) {
            style = {
              width: '64px',
              height: '64px'
            };
          } else {
            style = {
              width: '160px',
              height: 'auto'
            };
          }
          return (
            <Box
              sx={{
                padding: 0,
                marginTop: '2px',
                position: 'relative'
              }}
              style={style}
              id={message?._id}
            >
              <img
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%'
                }}
                src={message.msg}
                alt={message.msg}
              />
              {message.reactions ? renderPostReactEmojis() : null}
            </Box>
          );
        }

        default: {
          return (
            <Box
              style={{
                display: 'flex',
                alignItems:
                  message.u?._id === rocketId ? 'flex-end' : 'flex-start',
                flexDirection: 'column'
              }}
            >
              <Box
                id={message?._id}
                sx={senderMessageStyle.box}
                style={{
                  position: 'relative',
                  width: 'fit-content',
                  backgroundColor:
                    message.u?._id === rocketId
                      ? mainTopic
                        ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
                        : buttonColor.backgroundColor
                      : theme.palette.mode === 'light'
                      ? '#e4e6eb'
                      : '#3e4042',
                  color:
                    message.u?._id === rocketId
                      ? '#fff'
                      : theme.palette.mode === 'light'
                      ? '#050505'
                      : '#fff',

                  display: 'flex',
                  justifyContent: 'center',
                  gap: 2,
                  alignItems: 'center',
                  opacity: isForward ? 0.9 : 1
                }}
              >
                {/* <a
                onMouseEnter={() => setIsShowIframe(true)}
                onMouseLeave={() => setIsShowIframe(false)}
                href={`${urlify(message.msg)}`}
                target="_blank"
                rel="noreferrer"
              > */}
                <a
                  href={message?.msg}
                  style={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                    color:
                      theme.palette.mode === 'light' ? '#373536' : '#e4e6eb'
                  }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {message?.msg}
                </a>
                {/* </a> */}
                {isShowIframe && (
                  <iframe
                    src={`${urlify(message?.msg)}`}
                    width="300px"
                    height="300px"
                    style={{
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      zIndex: 10
                    }}
                    title={message?.msg}
                  />
                )}
              </Box>
              {message?.reactions ? renderPostReactEmojis() : null}
            </Box>
          );
        }
      }
    };

    // const statusMessageSend = message?.

    const timeShowMessage = ` ${
      message?.editedAt
        ? handleTimeShow(message?.editedAt?.$date)
        : handleTimeShow(message?.ts?.$date) ??
          moment(message.ts).format('HH:mm DD/MM/YYYY')
    }`;

    return (
      <>
        <Box
          style={{
            width: '75%',
            height: '100%',
            float: 'right',
            justifyContent: 'space-between',
            display: 'flex',
            marginTop: '0px',
            marginBottom: message?.reactions ? '15px' : undefined
          }}

          // ref={refTxt}
          // id="BoxMessage"
        >
          <ListItem
            sx={senderMessageStyle.item}
            style={{
              display: 'flex',
              flexDirection: 'column',
              // justifyContent: 'flex-start',
              alignItems: 'flex-end',
              padding:
                conversation?.t !== 'cp'
                  ? messageNext?.u?._id === rocketId
                    ? '2px 0px 0px'
                    : '10px 0px 0px 0px'
                  : messageNext?.u?._id !== conversation?.user_chat_page?._id
                  ? '2px 0px 0px'
                  : '10px 0px 0px 0px'
            }}
          >
            {isForward ? (
              <Box
                sx={senderMessageStyle.forwardMessage}
                style={{
                  fontSize: '10px',
                  color:
                    theme.palette.mode === 'light' ? '#20262E60' : '#E9E8E860',
                  width: 'full',
                  position: 'relative',
                  right: 0
                  // margin: '0 20px'
                }}
              >
                <i className="fa-solid fa-right"></i>
                <Typography fontSize={12}>
                  {' '}
                  {subjectName} đã chuyển tiếp 1 tin nhắn
                </Typography>
              </Box>
            ) : null}
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                // justifyContent: 'flex-end',
                position: 'relative'
              }}
            >
              <Tooltip
                title={timeShowMessage}
                arrow
                sx={{
                  maxWidth: '227px'
                }}
              >
                {renderContentMessage(message)}
              </Tooltip>
            </div>
          </ListItem>
        </Box>
      </>
    );
  }
);

export default Index;
