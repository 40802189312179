import * as types from 'src/constants/store/report';
const _ = require('lodash');
const initialState = {
  listReport: []
};

export const socialReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_REPORT_SUCCESS:
      let { data } = action.payload;
      let newState = _.cloneDeep(data);
      return { ...state, listReport: newState };

    default:
      return state;
  }
};
