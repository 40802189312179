import _ from 'lodash';
import {
  saveSettingRoom,
  createDirectMessage,
  blockRoom,
  getRoomRoles,
  unBlockRoom,
  outGroupChat,
  handleCleanHistory,
  getSubscriptionRoom,
  outChannelChat,
  blockPage,
  unBlockPage
} from 'src/apis/socialChat.apis';
import { PATHS } from 'src/constants/paths';
import { unSubRoom } from 'src/socket/RocketChatSubscriptions';

import {
  removeUserBoxChat,
  deleteConversation,
  deletePopupChat,
  selectedUserBoxChat,
  leaveConversation
} from 'src/store/action/socialChatAction';
import { urlRocketChat } from 'src/util/config';
import Dexie from 'dexie';
import store from 'src/store/store';
import { addObejctBlock, removeObjectBlock } from 'src/apis/socialUser';
import { buttonColor } from 'src/constants/styles';

// transition router to messages
export const handleSelectedChatUser = async (
  userSelected: any,
  match: any,
  history: any,
  dispatch: any,
  setIsToastMessage: any,
  setOpen?: any
) => {
  try {
    const response = await createDirectMessage(userSelected.username);

    if (response.status === 200) {
      const roomId = JSON.parse(response.data.message)?.result?.rid;
      setOpen && setOpen(false);
      const convertStationRoom = JSON.parse(response.data.message)?.result;
      if (match.path === PATHS.CHAT) {
        await getSubscriptionRoom(roomId);
        await dispatch(
          selectedUserBoxChat({
            rid:
              convertStationRoom?.rid ?? JSON.parse(response.data.message).rid,
            displayName: userSelected?.name ?? userSelected?.displayName,
            avatar_media: {
              show_url: `${urlRocketChat}/avatar/${userSelected.username}`
            },
            type: 'user',
            username: userSelected.username,
            userId: userSelected._id,
            ...convertStationRoom
          })
        );
        history.push(`/messages/${roomId}`);
      } else {
        if (roomId) {
          await getSubscriptionRoom(roomId);
          const conversation = {
            displayName: userSelected.displayName,
            rid: roomId,
            avatar_media: `${urlRocketChat}/avatar/${userSelected.username}`,
            username: userSelected.username,
            userId: userSelected._id,
            t: 'd'
          };
          await dispatch(selectedUserBoxChat(conversation));

          history.push(`/messages/${roomId}`);
        }
      }
    }
  } catch (error) {
    console.log('[handleChatNow]', error);
    setIsToastMessage(true);
  }
};

export const fetchListAdmin = async (
  conversation,
  setCurrentRole,
  setListAdmin,
  rocketId
) => {
  const response = await getRoomRoles(conversation?.rid);

  if (response.status === 200) {
    const listAdmin = JSON.parse(response.data.message).result;
    if (listAdmin && listAdmin.map(el => el.u._id).includes(rocketId)) {
      setCurrentRole('admin');
    }
    setListAdmin(listAdmin ?? []);
  }
};

export const getSubscription = async (conversation, dispatch) => {
  const roomId = conversation?.rid;
  if (roomId) {
    const response = await getSubscriptionRoom(roomId);
    if (response.status === 200) {
      let newConversation = _.cloneDeep(conversation);
      newConversation.disableNotifications =
        response?.data?.subscription?.disableNotifications || false;
      newConversation.blocker = response?.data?.subscription?.blocker || false;
      newConversation.blocked = response?.data?.subscription?.blocked || false;

      await dispatch(selectedUserBoxChat(newConversation));
    }
  }
};

export const handleActionCleanHistory = async (conversation, dispatch) => {
  const temp_rid = conversation?.rid;

  let res = await handleCleanHistory({
    roomId: conversation.rid,
    latest: '9999-12-31T23:59:59.000Z',
    oldest: '0001-01-01T00:00:00.000Z',
    inclusive: false,
    limit: 2000,
    excludePinned: false,
    filesOnly: false,
    ignoreDiscussion: false,
    ignoreThreads: false,
    users: []
  });
  if (res.status === 200) {
    dispatch(deletePopupChat(temp_rid));
    dispatch(deleteConversation(temp_rid, conversation.type));
  }
};

export const removeBoxChat = (subId, conversation, dispatch) => {
  unSubRoom(subId);
  dispatch(removeUserBoxChat(conversation));
};

export const handleOutGroupChat = async (
  subId: any,
  conversation: any,
  dispatch: any,
  history: any,
  match: any,
  setIsToastMessage: any,
  setTitleToastMessage: any,
  rocketId: any,
  setCodeMessage: any
) => {
  try {
    const roomId = conversation.rid || conversation._id;
    if (conversation.t === 'p') {
      const response = await outGroupChat(roomId);

      if (response.status === 200) {
        if (rocketId) {
          const db: any = await new Dexie(`chat_emso_db_${rocketId}`);
          db.version(1).stores({
            conversations: '++rid'
          });
          await db.conversations.delete(roomId);
        }
        removeBoxChat(subId, conversation, dispatch);
        dispatch(leaveConversation(roomId));
      }
    } else if (conversation.t === 'c') {
      const response = await outChannelChat(conversation.rid);

      if (response.status === 200) {
        if (rocketId) {
          const db: any = await new Dexie(`chat_emso_db_${rocketId}`);
          db.version(1).stores({
            conversations: '++rid'
          });
          await db.conversations.delete(roomId);
        }
        removeBoxChat(subId, conversation, dispatch);
        // dispatch(leaveConversation(roomId));
      }
    }
    if (match.path === PATHS.CHAT) {
      history.push('/messages');
    }
  } catch (error) {
    // console.log(error);
    setTitleToastMessage(
      'Vui lòng chọn một quản trị viên mới trước khi rời khỏi nhóm.'
    );
    setIsToastMessage(true);
    setCodeMessage(400);
  }
};

export const handleSettingNotification = async (
  conversation: any,
  setIsToastMessage: any,
  rocketId: any,
  isSkipConversation?: boolean
) => {
  try {
    const roomId = conversation.rid;
    const updatedAt = Date.now();

    const settings = () => {
      if (isSkipConversation || conversation?.disableNotifications) {
        return { disableNotifications: '0' };
      } else if (conversation?.disableNotifications === false) {
        return { disableNotifications: '1' };
      }
      return { disableNotifications: '1' };
    };

    const response = await saveSettingRoom(roomId, settings());
    if (response.status === 200) {
      const disableNotifications =
        response.data.notifications.disableNotifications === '1' ? true : false;
      if (rocketId) {
        const db: any = await new Dexie(`chat_emso_db_${rocketId}`);
        db.version(1).stores({
          conversations: '++rid',
          conversations_pinned: '++rid'
        });
        db.conversations.update(roomId, {
          disableNotifications: disableNotifications,
          '_updatedAt.$date': updatedAt
        });
      }
    }
  } catch (error) {
    setIsToastMessage(true);
  }
};

export const handleBlockRoom = async (
  conversation,
  userSelected,
  setIsToastMessage,
  dispatch,
  rocketId
) => {
  const updatedAt = Date.now();
  const roomId = conversation.rid;
  const userId =
    conversation?.type === 'group' ? userSelected._id : conversation.userId;
  try {
    let response;
    const db: any = await new Dexie(`chat_emso_db_${rocketId}`);
    db.version(1).stores({
      conversations: '++rid',
      conversations_pinned: '++rid'
    });
    if (
      conversation?.blocker ||
      (conversation?.t === 'cp' &&
        (conversation?.userBlock === true || conversation?.pageBlock === true))
    ) {
      console.log('check', conversation);

      if (
        conversation?.userBlock === true ||
        conversation?.pageBlock === true
      ) {
        await unBlockPage(
          roomId,
          conversation?.user_chat_page &&
            conversation?.user_chat_page?._id !== userId
            ? true
            : false
        );
      } else {
        response = await unBlockRoom(roomId, userId);
        if (response.status === 200) {
          db.conversations
            .update(roomId, {
              blocker: false,
              '_updatedAt.$date': updatedAt
            })
            .then(update => {
              if (update) {
              }
            });
          await removeObjectBlock('block_messages', userId);
        }
      }
    } else {
      if (conversation.t === 'cp') {
        response = await blockPage(
          roomId,
          conversation?.user_chat_page &&
            conversation?.user_chat_page?._id !== userId
            ? true
            : false
        );
      } else {
        response = await blockRoom(roomId, userId);
        if (response.status === 200) {
          db.conversations
            .update(roomId, {
              blocker: true,
              '_updatedAt.$date': updatedAt
            })
            .then(update => {
              if (update) {
              }
            });
        }
        const tempParams = {
          target_account_id: userId
        };
        await addObejctBlock('block_messages', tempParams);
      }
    }

    if (response.status === 200) {
      // console.log(response.data);
      // getSubscription(conversation, dispatch);
    }
  } catch (error) {
    setIsToastMessage(true);
  }
};

// handle transition url page
export const handleLinkToUserPage = (conversation, history) => {
  if (
    (conversation && conversation.type === 'user') ||
    (conversation &&
      conversation.type === 'chat_page' &&
      conversation?.user_chat_page)
  ) {
    history.push(
      `/user/${
        conversation?.username ?? conversation?.user_chat_page?.username
      }`
    );
  }
  if (
    conversation &&
    conversation.type === 'chat_page' &&
    !conversation?.user_chat_page
  ) {
    history.push(`/page/${conversation?.prid}`);
  }
};

export const handleChatNow = async (conversation, rocketId, dispatch) => {
  try {
    const response = await createDirectMessage(conversation.username);
    if (response.status === 200) {
      const roomId = JSON.parse(response.data.message).result.rid;
      const db: any = await new Dexie(`chat_emso_db_${rocketId}`);
      db.version(1).stores({
        conversations: '++rid',
        conversations_pinned: '++rid'
      });

      const conversationDBLocal = await db.conversations.get(roomId);
      if (conversationDBLocal) {
        dispatch(selectedUserBoxChat(conversationDBLocal));
      } else {
        const conversationSelected = {
          displayName: conversation.display_name,
          rid: roomId,
          avatar_media:
            conversation.avatar_media ||
            `${urlRocketChat}/avatar/${conversation?.username}`,
          username: conversation.username,
          userId: conversation.id,
          t: 'd',
          type: 'user',
          topic: buttonColor.backgroundColor,
          customFields: { icon: '' }
        };
        await db.conversations;

        dispatch(selectedUserBoxChat(conversationSelected));
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const getConversationDBLocal = async roomId => {
  const meInfo = store.getState().meReducer ?? '';
  const rocketId = meInfo?.info.id || '';
  const db: any = await new Dexie(`chat_emso_db_${rocketId}`);
  db.version(1).stores({
    conversations: '++rid',
    conversations_pinned: '++rid'
  });
  const result = await db.conversations.get(roomId);
  return result;
};
