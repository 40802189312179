import React from 'react';
import { Slider, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { urlRocketChat } from 'src/util/config';
import { buttonColor } from 'src/constants/styles';

const useStyles = makeStyles((theme: Theme) => ({
  Box: {
    height: '36px',
    borderRadius: '18px',
    width: '206px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    zIndex: 20
  },
  leftIcon: {
    backgroundColor: 'white',
    width: '26px',
    height: '26px',
    borderRadius: '15px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '3px',
    zIndex: 20
  },
  sliderCenter: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: '50px',
    overflow: 'hidden',
    zIndex: 10
  },
  textRight: {
    width: '48px',
    height: '24px',
    borderRadius: '15px',
    marginRight: '3px',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

function DetailMessageAudio(props) {
  const {
    message,
    renderPostReactEmojis,
    mainTopic,
    theme,
    conversation,
    typeReplied
  } = props;

  const _classes = useStyles();
  const [position, setPosition] = React.useState(0);
  const meInfo = useSelector((state: any) => state.meReducer.info) || {};
  const rocketId = meInfo?.id || '';

  const audioRef: any = React.useRef();

  const playAudio = (audioId: any) => {
    const arrAudio: any = document.getElementsByTagName('audio');
    const audioArray = Array.from(arrAudio);
    audioArray.forEach((audio: any) => {
      if (audio.id === audioId && audioRef?.current?.paused) {
        audio.play();
      } else {
        audio.pause();
      }
    });
  };

  function formatDuration(value: number) {
    const minute = Math.floor(value / 60);
    const secondLeft = value - minute * 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }

  const timeUpdateFunc = () => {
    if (audioRef?.current?.currentTime) {
      const timeCurrent =
        (audioRef.current.currentTime / audioRef.current.duration) * 100;
      setPosition(timeCurrent);
    }
  };

  const renderTime = (messageAudio: any, audioRefMessage: any) => {
    if (!audioRef?.current?.paused) {
      return audioRefMessage?.current?.currentTime;
    } else {
      if (messageAudio?.file?.time) {
        return messageAudio?.file?.time;
      } else {
        return audioRefMessage?.current?.currentTime;
      }
    }
  };
  return (
    <>
      <Box
        id={message?._id}
        className={_classes.Box}
        sx={{
          // width: '100%',
          background: typeReplied
            ? '#e4e6eb'
            : conversation?.t !== 'cp'
            ? message?.u?._id === rocketId
              ? mainTopic
                ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
                : buttonColor.backgroundColor
              : theme.palette.mode === 'light'
              ? '#e4e6eb'
              : '#3e4042'
            : message?.u?._id !== conversation?.user_chat_page?._id
            ? mainTopic
              ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
              : buttonColor.backgroundColor
            : theme.palette.mode === 'light'
            ? '#e4e6eb'
            : '#3e4042',
          zIndex: typeReplied ? 0 : 1000
        }}
      >
        <audio
          muted={false}
          ref={audioRef}
          src={
            message?.typeForwards
              ? message?.msg
              : message.attachments[0]?.preview
              ? message.attachments[0]?.preview
              : `${urlRocketChat}${
                  message.attachments?.[0]?.title_link ||
                  message.attachments.find(el => el?.title_link)?.title_link ||
                  message.attachments?.[0]?.attachments.find(
                    el => el?.title_link
                  )?.title_link
                }`
          }
          id={`audio${message._id}`}
          onTimeUpdate={timeUpdateFunc}
        />
        <div
          className={_classes.leftIcon}
          style={{
            color:
              message?.u?._id === rocketId
                ? mainTopic
                  ? mainTopic[0]
                  : buttonColor.backgroundColor
                : '#000000'
          }}
          onClick={() => playAudio(`audio${message._id}`)}
        >
          {!audioRef?.current?.paused ? (
            <i
              className="fa-solid fa-pause fa-lg"
              style={{ fontSize: '16px' }}
            ></i>
          ) : (
            <i
              className="fa-solid fa-play fa-lg"
              style={{ fontSize: '16px' }}
            ></i>
          )}
        </div>

        <div className={_classes.sliderCenter}>
          <Slider
            size="small"
            value={position}
            min={0}
            step={0.5}
            max={100}
            onChange={(_, value) => {
              const newPosition = value as number;
              setPosition(newPosition);
              const mediaElement = audioRef.current;
              if (mediaElement) {
                const newTime = (newPosition / 100) * mediaElement.duration;
                if (!isNaN(newTime) && isFinite(newTime)) {
                  mediaElement.currentTime = newTime;
                }
              }
            }}
            sx={{
              color: message?.u?._id === rocketId ? '#fafafa30' : '#00000020',
              width: '100%%',
              height: 40,
              paddingRight: '10px',
              zIndex: 0
            }}
          />
        </div>
        <div
          className={_classes.textRight}
          style={{
            color:
              message?.u?._id === rocketId
                ? mainTopic
                  ? mainTopic[0]
                  : buttonColor.backgroundColor
                : '#000000',
            fontSize: '14px'
          }}
        >
          {formatDuration(
            Math.ceil(
              isNaN(renderTime(message, audioRef))
                ? 0
                : renderTime(message, audioRef)
            )
          )}
        </div>
        {message.reactions ? renderPostReactEmojis() : null}
      </Box>
    </>
  );
}

export default React.memo(DetailMessageAudio);
