import axios, { AxiosRequestConfig } from 'axios';
import moment from 'moment';

import { BASE_ROOT, BASE_ROOT_MARKET, USER_TOKEN } from 'src/util/config';

interface ListProductApproval {
  product_ids: Array<String | Number>;
}

const fetchApi = async (endPoint, method, params, formdata) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT_MARKET}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: formdata
  };

  let response = await axios(configs);
  return response;
};

const fetchApiRequest = async (endPoint, method, params, _data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT_MARKET}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

const fetchApiSNRequest = async (endPoint, method, params, _data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

const fetchApiRequestV2 = async (endPoint, method, params, _data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT_MARKET}/api/v2/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

const fetchApiRequestCmcSnV2 = async (endPoint, method, params, _data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v2/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

const fetchApiRequestAdmin = async (endPoint, method, params, _data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT_MARKET}/api/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

export const getCalendarMarketplaceApi = async (params: any) => {
  return fetchApiRequest('product_search', 'GET', params, null);
};

export const hiddenProductApi = async (pageId, productId, data) => {
  return fetchApiRequest(
    `sellers/${pageId}/product_managements/${productId}/hidden_products`,
    'PATCH',
    null,
    data
  );
};

export const fastUpdate = async (idProduct, idVariant, data) => {
  return fetchApiRequest(
    `/products/${idProduct}/product_variants/${idVariant}`,
    'PATCH',
    null,
    data
  );
};

export const getSuggestionsProductApi = async params => {
  return fetchApi('suggestions/product', 'GET', params, null);
};

export const createUpdateProductShopApi = async (
  data: any,
  type: any,
  idPage: string
) => {
  return fetchApi(
    type === 'create'
      ? `sellers/${idPage}/product_managements`
      : `sellers/${idPage}/product_managements/${type}`,
    type === 'create' ? 'POST' : 'PATCH',
    null,
    data
  );
};

export const sendApprovalProduct = async (
  data: ListProductApproval,
  idPage: string,
  idProduct: any
) => {
  return fetchApi(
    `sellers/${idPage}/product_managements/${idProduct}/send_approval_products`,
    'POST',
    null,
    data
  );
};

export const checkPhoneNumberVerification = async params => {
  return fetchApi('check_phone_number_verification', 'POST', null, params);
};

export const getListPagesApi = (params: any) => {
  return fetchApi('pages', 'GET', params, null);
};

export const getListShopMallApi = (params: any) => {
  return fetchApiSNRequest('shop_malls', 'GET', params, null);
};

//danh sách sản phẩm của người bán
export const getProductManageShop = async (params, pageId) => {
  return fetchApi(`sellers/${pageId}/product_managements`, 'GET', params, null);
};

//danh sách sản phẩm của người mua (có trả total)
export const getListProductApi = async params => {
  return fetchApi(`list_products`, 'GET', params, null);
};

//danh sách sản phẩm của người mua (không trả total)
export const getProductApi = async params => {
  return fetchApi('products', 'GET', params, null);
};

// danh sách sản phẩm flash sale của shop
export const getProductFlashSaleShopApi = async params => {
  return fetchApi('product_shop_flash_sale_now', 'GET', params, null);
};

export const confirmPhoneNumber = async params => {
  return fetchApi('confirm_phone_number', 'POST', null, params);
};

//chi tiết sản phẩm của người bán
export const getDetailProductShopApi = async (idProduct: any, pageId) => {
  return fetchApi(
    `sellers/${pageId}/product_managements/${idProduct}`,
    'GET',
    null,
    null
  );
};

//fetch landing pages
export const fetchLandingPages = async params => {
  return fetchApi('landing_pages', 'GET', params, null);
};

// chi tiết sản phẩm của người mua
export const getDetailProductApi = async (idProduct: any) => {
  return fetchApi(`products/${idProduct}`, 'GET', null, null);
};

export const getSuggestionProductsApi = async (idProduct: any, prams: any) => {
  return fetchApi(
    `products/${idProduct}/suggestion_products`,
    'GET',
    prams,
    null
  );
};

export const getProductCategories = async () => {
  return fetchApi('product_categories', 'GET', null, null);
};

export const deleteProductApi = async (id: any, idPage: string) => {
  return fetchApi(
    `sellers/${idPage}/product_managements/${id}`,
    'DELETE',
    null,
    null
  );
};

export const createInviteProduct = async (idProduct: any, idInvite: any) => {
  return fetchApi(`products/${idProduct}/invitations`, 'POST', null, {
    target_account_ids: idInvite
  });
};

export const interesteProductApi = async (idProduct: any) => {
  return fetchApiRequest(
    `products/${idProduct}/product_followers`,
    'POST',
    null,
    null
  );
};
export const noInteresteProductApi = async (
  idProduct: any,
  idFollowers: any
) => {
  return fetchApiRequest(
    `products/${idProduct}/product_followers/${idFollowers}`,
    'DELETE',
    null,
    null
  );
};

export const getListInvitation = async (params: any) => {
  return fetchApiRequest('product_invitations', 'GET', params, null);
};

export const respondInvitationProduct = async (idProduct: any, params: any) => {
  return fetchApiRequest(
    `products/${idProduct}/invitations/invitations_respond`,
    'POST',
    null,
    params
  );
};

export const getListHostOfProduct = async (idProduct: any, params: any) => {
  return fetchApiRequest(
    `/products/${idProduct}/invitation_hosts`,
    'GET',
    params,
    null
  );
};

export const CRUDCart = async (type, params: any, data) => {
  return fetchApiRequest(
    `shopping_carts${
      type === 'patch' || type === 'delete' ? '/' + params : ''
    }`,
    type,
    null,
    data
  );
};
export const getListCartApi = async (params: any) => {
  return fetchApiRequest('shopping_carts', 'GET', params, null);
};

export const getTotalProductInCarts = async () => {
  return fetchApiRequest('count_product_in_shopping_carts', 'GET', null, null);
};

export const paymentProduct = async (idProduct: any) => {
  return fetchApiRequest(
    `products/${idProduct}/payment_product`,
    'POST',
    null,
    {
      detail_type: 'payment_product'
    }
  );
};

export const sendApproval = async (idProduct: any) => {
  return fetchApiRequest(
    `products/${idProduct}/send_approval`,
    'POST',
    null,
    null
  );
};

export const getBalance = async () => {
  return fetchApiRequest(`transactions`, 'GET', null, null);
};

export const rechargeEcoin = async (data: any) => {
  return fetchApiRequest(`transfer`, 'POST', null, data);
};

export const ratingProduct = async (idProduct: any, data: any, type) => {
  return fetchApiRequest(
    type === 'create'
      ? `orders/${idProduct}/product_rating`
      : `orders/${idProduct}/product_rating/${type}`,
    type === 'create' ? 'POST' : 'PATCH',
    null,
    data
  );
};

export const getListRating = async (idProduct: any, params: any) => {
  return fetchApiRequest(
    `products/${idProduct}/list_rating`,
    'GET',
    params,
    null
  );
};

export const getListPaid = async idProduct => {
  return fetchApiRequest(
    `products/${idProduct}/list_participants`,
    'GET',
    null,
    null
  );
};

export const CRUDAddress = async (type, idAddress: any, params: any, data) => {
  return fetchApiRequest(
    `delivery_addresses${
      type === 'patch' || type === 'delete' ? '/' + idAddress : ''
    }`,
    type,
    params,
    data
  );
};

export const paymentOrder = async data => {
  return fetchApiRequest('orders', 'POST', null, data);
};

export const listOrders = async params => {
  return fetchApiRequest('orders', 'GET', params, null);
};

export const getOrderPage = async (idPage: any, params: any) => {
  return fetchApiRequest(
    `sellers/${idPage}/order_managements`,
    'GET',
    params,
    null
  );
};

// chi tiết order của người bán
export const detailOrder = async (idPage, idOrder: any) => {
  return fetchApiRequest(
    `sellers/${idPage}/order_managements/${idOrder}`,
    'GET',
    null,
    null
  );
};

//chi tiết order của người mua
export const detailOrderBuyer = async (idOrder: any) => {
  return fetchApiRequest(`orders/${idOrder}`, 'GET', null, null);
};

export const deleteOrderSeller = async (idPage, idOrder: any, data) => {
  return fetchApiRequest(
    `sellers/${idPage}/order_managements/${idOrder}`,
    'DELETE',
    null,
    data
  );
};

export const deleteOrdersBuyer = async (idOrder: any, data) => {
  return fetchApiRequest(`orders/${idOrder}`, 'DELETE', null, data);
};

export const shopListCancelReasonsApi = async (pageId, orderId) => {
  return fetchApiRequest(
    `sellers/${pageId}/order_managements/${orderId}/shop_list_cancel_reasons`,
    'GET',
    null,
    null
  );
};

export const patchOrder = async (pageId, idOrder: any, data) => {
  return fetchApiRequest(
    `sellers/${pageId}/order_managements/${idOrder}`,
    'PATCH',
    null,
    data
  );
};

export const patchOrderBuyer = async (idOrder: any, data) => {
  return fetchApiRequest(`orders/${idOrder}`, 'PATCH', null, data);
};

export const shopOrderStatusLogsApi = async (pageId, idOrder) => {
  return fetchApiRequest(
    `sellers/${pageId}/order_managements/${idOrder}/shop_order_status_logs`,
    'GET',
    null,
    null
  );
};

export const buyerOrderStatusLogApi = async idOrder => {
  return fetchApiRequest(
    `orders/${idOrder}/order_status_logs`,
    'GET',
    null,
    null
  );
};

export const paymentConfirm = async (idOrder: any) => {
  return fetchApiRequest(`orders/${idOrder}/payment_order`, 'POST', null, null);
};

export const paymentMethods = async (params: any, type) => {
  return fetchApiRequest(
    `${type === 'vtcpay' ? 'create_vtcpay_url' : 'create_payment_url'}`,
    'GET',
    params,
    null
  );
};

export const updateStatusOrder = async (pageId, idOrder: any, data: any) => {
  return fetchApiRequest(
    `sellers/${pageId}/order_managements/${idOrder}`,
    'PATCH',
    null,
    data
  );
};

export const searchProduct = async (params: any) => {
  return fetchApiRequest(`product_search`, 'GET', params, null);
};

export const searchNameShop = async (params: any) => {
  return fetchApiRequestV2(`search`, 'GET', params, null);
};

export const searchShopRelatedProducts = async (params: any) => {
  return fetchApiRequest(`search_pages`, 'GET', params, null);
};

export const searchShopRelatedProductsV2 = async (params: any) => {
  return fetchApiRequestCmcSnV2(`search`, 'GET', params, null);
};

export const verifyDelivered = async (idOrder: any) => {
  return fetchApiRequest(`orders/${idOrder}/verify_delivered`, 'POST', null, {
    status: 'finish'
  });
};

export const countOrders = async () => {
  return fetchApiRequest(`count_orders`, 'GET', null, null);
};

export const productCategories = async (id: any, params) => {
  return fetchApiRequest(
    `product_categories${id ? '/' + id : ''}`,
    'GET',
    params,
    null
  );
};

export const listCategories = async (
  idParent: string | number,
  levelChildren: number
) => {
  return fetchApiRequest(
    `list_categories/${idParent}`,
    'GET',
    { level_children: levelChildren },
    null
  );
};

export const productSubCategories = async idCategory => {
  return fetchApiRequest(`product_categories/${idCategory}`, 'GET', null, null);
};

export const getListProductInterested = async (params: any) => {
  return fetchApiRequest(`products`, 'GET', params, null);
};

export const getListReportCategories = async (params: any) => {
  return fetchApiRequest('report_categories', 'GET', params, null);
};

export const sendReportCategories = async (data: any) => {
  return fetchApiSNRequest('report_violations', 'POST', null, data);
};

export const reportProductApi = async (data: any) => {
  return fetchApiRequest('report_violations', 'POST', null, data);
};

export const searchHistory = async (params: any) => {
  return fetchApiRequest('search_histories', 'GET', params, null);
};

export const saveSearchHistory = async (data: any) => {
  return fetchApiRequest('search_histories', 'POST', null, data);
};

export const getListCampaignsApi = async (params: any = {}) => {
  return fetchApiRequest('campaigns', 'GET', params, null);
};

export const getDetailCampaignsApi = async (id: any) => {
  return fetchApiRequest(`campaigns/${id}`, 'GET', null, null);
};

export const getCampaignProductsApi = async (id: any, params) => {
  return fetchApiRequest(`campaigns/${id}/products`, 'GET', params, null);
};

export const getProductsFlashSale = async (params: any) => {
  return fetchApiRequest('campaigns', 'GET', params, null);
};

export const getDetailsFlashSales = async (idCampain: any) => {
  return fetchApiRequest(`campaigns/${idCampain}`, 'GET', null, null);
};

export const getListCancelReasonApi = async params => {
  return fetchApiRequest('list_cancel_reasons', 'GET', params, null);
};

export const getTopSeachApi = async () => {
  return fetchApiRequest('top_search_products', 'GET', null, null);
};

export const getSuggestCategoriesApi = async params => {
  return fetchApiRequest('product_keywords', 'GET', params, null);
};

export const getSearchCategoriesApi = async params => {
  return fetchApiRequest('product_categories', 'GET', params, null);
};

export const suggestCategoriesApi = async params => {
  return fetchApiRequest('product_category_search', 'GET', params, null);
};

export const productCategoryParentSearchApi = async params => {
  return fetchApiRequest('product_category_parent_search', 'GET', params, null);
};

export const getAllAttributeApi = async () => {
  return fetchApiRequest('product_attributes', 'GET', null, null);
};

export const addValueAttributeApi = async (id, data) => {
  return fetchApiRequest(
    `product_attributes/${id}/custom_attribute_value`,
    'POST',
    null,
    data
  );
};

export const removeValueAttributeApi = async (id, data) => {
  return fetchApiRequest(
    `product_attributes/${id}/delete_attribute_value`,
    'DELETE',
    null,
    data
  );
};

export const updateValueAttributeApi = async (id, data) => {
  return fetchApiRequest(
    `product_attributes/${id}/update_attribute_value`,
    'PATCH',
    null,
    data
  );
};

export const createTransport = async data => {
  return fetchApiRequest(`shippings`, 'POST', null, data);
};

export const createServices = async data => {
  return fetchApiRequest(`available_services`, 'POST', null, data);
};

export const leadTime = async data => {
  return fetchApiRequest(`leadtime`, 'POST', null, data);
};

export const cancelShipping = async data => {
  return fetchApiRequest(`cancel_shipping`, 'POST', null, data);
};

export const returnShipping = async data => {
  return fetchApiRequest(`return_shipping`, 'POST', null, data);
};

export const printShippingOrder = async data => {
  return fetchApiRequest(`gen_token`, 'POST', null, data);
};

export const getDetailShipping = async data => {
  return fetchApiRequest(`detail_shipping`, 'POST', null, data);
};

export const printOrderTransport = async data => {
  return fetchApiRequest(`gen_token`, 'POST', null, data);
};

export const getCategoryGuideApi = async params => {
  return fetchApiRequest(`product_category_guide`, 'GET', params, null);
};

export const getBrandApi = async params => {
  return fetchApiRequest(`list_brand_by_product_category`, 'GET', params, null);
};

export const getShippingFeeApi = async data => {
  return fetchApiRequest(`shipping_fee`, 'POST', null, data);
};

export const createVoucherShopApi = async (data, pageId) => {
  return fetchApiRequest(
    `sellers/${pageId}/voucher_managements`,
    'POST',
    null,
    data
  );
};

export const endVoucherShopApi = async (id, pageId) => {
  return fetchApiRequest(
    `sellers/${pageId}/voucher_managements/${id}`,
    'PATCH',
    null,
    {
      end_time: moment().format()
    }
  );
};

export const deleteVoucherShopApi = async (id, pageId) => {
  return fetchApiRequest(
    `sellers/${pageId}/voucher_managements/${id}`,
    'DELETE',
    null,
    null
  );
};

// danh sách voucher cho người bán
export const getListVoucherShopApi = async (pageId, params) => {
  return fetchApiRequest(
    `sellers/${pageId}/voucher_managements`,
    'GET',
    params,
    null
  );
};
// danh sách voucher cho người mua
export const getListVoucherApi = async params => {
  return fetchApiRequest(`vouchers`, 'GET', params, null);
};

export const getListValidEmsoVoucherApi = async data => {
  return fetchApiRequest(`valid_emso_vouchers`, 'POST', null, data);
};

export const getListVoucherValidApi = async data => {
  return fetchApiRequest(`valid_page_vouchers`, 'POST', null, data);
};

export const getListVoucherApiEmso = async params => {
  return fetchApiRequestAdmin(`admin/vouchers`, 'GET', params, null);
};

export const saveVoucherApi = async data => {
  return fetchApiRequest(`my_vouchers`, 'POST', null, data);
};

export const deleteMyVoucherApi = async id => {
  return fetchApiRequest(`my_vouchers/${id}`, 'DELETE', null, null);
};

export const listVoucherApi = async params => {
  return fetchApiRequest(`my_vouchers`, 'GET', params, null);
};

export const listProductReportReasons = async params => {
  return fetchApiRequest(`report_reasons`, 'GET', params, null);
};

export const reportProductRating = async data => {
  return fetchApiRequest(`product_rating_reports`, 'POST', null, data);
};

export const getAndUpdateDetailVoucherShopApi = async (
  id,
  method,
  data,
  pageId
) => {
  return fetchApiRequest(
    `sellers/${pageId}/voucher_managements/${id}`,
    method,
    null,
    data
  );
};

export const getDetailVoucherShopApi = async (id, params, pageId) => {
  return fetchApiRequest(
    `sellers/${pageId}/voucher_managements/${id}`,
    'GET',
    params,
    null
  );
};

export const registerVoucherEMSOShopApi = async (id, data, pageId) => {
  return fetchApiRequest(
    `sellers/${pageId}/voucher_managements/${id}/register_voucher`,
    'POST',
    null,
    data
  );
};

export const getListFlashSaleApi = async (params: any) => {
  return fetchApiRequest('flash_sales', 'GET', params, null);
};

export const getShopReportListReasons = async () => {
  return fetchApiRequest('list_shop_report_categories', 'GET', null, null);
};

export const reportShop = async (payload: any) => {
  return fetchApiRequest('shop_reports', 'POST', null, payload);
};

export const getListSizeChartCategoryApi = async () => {
  return fetchApiRequest('size_chart_category', 'GET', null, null);
};

export const getMeasurementsDetailAdminApi = async (params: any) => {
  return fetchApiRequestAdmin('admin/measurements', 'GET', params, null);
};

export const getListSizeChartOfPageApi = async params => {
  return fetchApiRequest('size_charts', 'GET', params, null);
};

export const createFlashSaleApi = async (data: any) => {
  return fetchApiRequest('flash_sales', 'POST', null, data);
};

export const getDetailFlashSaleApi = async (id: any) => {
  return fetchApiRequest(`flash_sales/${id}`, 'GET', null, null);
};

export const getListProdutcsOfFlashSaleApi = async (id: any) => {
  return fetchApiRequest(`flash_sales/${id}/products`, 'GET', null, null);
};

export const updateFlashSaleApi = async (id: any, data: any) => {
  return fetchApiRequest(`flash_sales/${id}`, 'PATCH', null, data);
};
export const deleteFlashSaleApi = async (id: any) => {
  return fetchApiRequest(`flash_sales/${id}`, 'DELETE', null, null);
};

export const getListProductFlashSaleNow = async (params: any = {}) => {
  return fetchApiRequest(`products_flash_sale_now`, 'GET', params, null);
};

export const followProductListApi = async data => {
  return fetchApiRequest('follow_products', 'POST', null, data);
};

export const getCategoriesOfPage = async params => {
  return fetchApiRequest('list_page_categories', 'GET', params, null);
};

export const getPageDetailProductCategories = async params => {
  return fetchApiRequest('page_detail_product_categories', 'GET', params, null);
};

export const createSizeChartsApi = async data => {
  return fetchApiRequest('size_charts', 'POST', null, data);
};

export const deleteSizeChartApi = async id => {
  return fetchApiRequest(`size_charts/${id}`, 'DELETE', null, null);
};

export const updateSizeChartApi = async (id, data) => {
  return fetchApiRequest(`size_charts/${id}`, 'PATCH', null, data);
};

export const getMyOrderRating = async id => {
  return fetchApiRequest(`orders/${id}/product_rating`, 'GET', null, null);
};

export const getDetailSizeChartApi = async id => {
  return fetchApiRequest(`size_charts/${id}`, 'GET', null, null);
};

export const getListCombosPromotionApi = async (params: any) => {
  return fetchApiRequest('bundle_deals', 'GET', params, null);
};

export const createCombosPromotionApi = async (data: any) => {
  return fetchApiRequest('bundle_deals', 'POST', null, data);
};

export const getDetailCombosPromotionApi = async (id: any) => {
  return fetchApiRequest(`bundle_deals/${id}`, 'GET', null, null);
};

export const updateCombosPromotionApi = async (id: any, data: any) => {
  return fetchApiRequest(`bundle_deals/${id}`, 'PATCH', null, data);
};

export const deleteCombosPromotionApi = async (id: any) => {
  return fetchApiRequest(`bundle_deals/${id}`, 'DELETE', null, null);
};

export const createShopProgramApi = async (data: any) => {
  return fetchApiRequest(`campaigns`, 'POST', null, data);
};

export const getListShopProgramApi = async (params: any) => {
  return fetchApiRequest(`campaigns`, 'GET', params, null);
};

export const getDetailShopProgramApi = async (id: any) => {
  return fetchApiRequest(`campaigns/${id}`, 'GET', null, null);
};

export const updateShopProgramApi = async (id: any, data: any) => {
  return fetchApiRequest(`campaigns/${id}`, 'PATCH', null, data);
};

export const deleteShopProgramApi = async (id: any) => {
  return fetchApiRequest(`campaigns/${id}`, 'DELETE', null, null);
};

export const totalPriceInCheckoutApi = async (data: any) => {
  return fetchApiRequest(`total_payments`, 'POST', null, data);
};

export const totalPriceInCartApi = async (data: any) => {
  return fetchApiRequest(`total_payments_in_shopping_cart`, 'POST', null, data);
};

export const getListCampaignEmsoApi = async (param: any) => {
  return fetchApiRequest(`campaigns`, 'GET', param, null);
};

export const getDetailCampaignEmsoApi = async (id: any) => {
  return fetchApiRequest(`campaigns/${id}`, 'GET', null, null);
};

export const getChildCampaignsShopFlashSaleApi = async (param: any) => {
  return fetchApiRequest(`child_campaigns_shop_flash_sale`, 'GET', param, null);
};

export const getTotalFramesTimeFlashSale = async (param: any) => {
  return fetchApiRequest(
    `total_frames_time_shop_flash_sale`,
    'GET',
    param,
    null
  );
};

export const registerProductCampFlashSaleEmsoApi = async (data: any) => {
  return fetchApiRequest(`flash_sales`, 'POST', null, data);
};

export const getListSaleProductVariantsApi = async (params: any) => {
  return fetchApiRequest(`list_sale_product_variants`, 'GET', params, null);
};

export const updateFlashSaleEmsoApi = async (id, data) => {
  return fetchApiRequest(`flash_sales/${id}`, 'PATCH', null, data);
};

export const deleteSaleProductVariantApi = async params => {
  return fetchApiRequest(
    `destroy_sale_product_variant`,
    'DELETE',
    params,
    null
  );
};

export const updateSaleProductVariantApi = async params => {
  return fetchApiRequest(`update_sale_product_variant`, 'PATCH', params, null);
};

export const getSaleProductVariantsShopFlashSaleApi = async params => {
  return fetchApiRequest(
    `sale_product_variants_shop_flash_sale`,
    'GET',
    params,
    null
  );
};

export const getShopFlashSales = async params => {
  return fetchApiRequest(`shop_flash_sales`, 'GET', params, null);
};

export const getShopTransactionHistory = async params => {
  return fetchApiRequest(`shop_transaction_history`, 'GET', params, null);
};

export const getShopBalance = async params => {
  return fetchApiRequest(`shop_balance`, 'GET', params, null);
};

export const getComplainReasonsApi = async (params: Object) => {
  return fetchApiRequest(`complain_reasons`, 'GET', params, null);
};

export const createOrderComplaintApi = async data => {
  return fetchApiRequest(`complains`, 'POST', null, data);
};

export const getDetailRefundRequestApi = async id => {
  return fetchApiRequest(`refund_requests/${id}`, 'GET', null, null);
};

export const approveOrRejectRefundRequestApi = async (id, status, data) => {
  return fetchApiRequest(`refund_requests/${id}/${status}`, 'POST', null, data);
};

export const createCommentRefundApi = async data => {
  return fetchApiRequest(`statuses`, 'POST', null, data);
};

export const getCommentListApi = async (id, params) => {
  return fetchApiRequest(`refund_requests/${id}/statuses`, 'GET', params, null);
};

export const getRatingListApi = async (idPage, params) => {
  return fetchApiRequest(
    `sellers/${idPage}/product_ratings`,
    'GET',
    params,
    null
  );
};

export const responseRatingApi = async (idPage, idRating, data) => {
  return fetchApiRequest(
    `sellers/${idPage}/product_ratings/${idRating}/response_rating`,
    'POST',
    null,
    data
  );
};

export const getDetailPageMarketApi = async (pageId: any) => {
  return fetchApiRequest(`pages/${pageId}`, 'GET', null, null);
};
