import axios, { AxiosRequestConfig } from 'axios';
import { BASE_ROOT, USER_TOKEN } from 'src/util/config';
import { BASE_ROOT_MARKET } from 'src/util/config';

const fetchApi = async (endPoint, method, params, formdata) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT_MARKET}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: formdata
  };

  let response = await axios(configs);
  return response;
};
const fetchApiRequest = async (endPoint, method, params, _data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT_MARKET}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};
const fetchApiSN = async (endPoint, method, params, formdata) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: formdata
  };

  let response = await axios(configs);
  return response;
};
export const getCalendarCourseApi = async (params: any) => {
  return fetchApiRequest('courses', 'GET', params, null);
};

export const getListCourseJoined = async (params: any) => {
  return fetchApiRequest('list_joined_course', 'GET', params, null);
};

export const createUpdateCourseApi = async (data: any, type: any) => {
  var formdata = new FormData();
  formdata.append('benefits', data.benefits);
  formdata.append('target_audience', data.target_audience);
  formdata.append('address', data.address);
  formdata.append('allow_discussion', data.allow_discussion);
  if (data.title) {
    formdata.append('title', data.title);
  }
  if (data.course_category_id) {
    formdata.append('course_category_id', data.course_category_id);
  }
  if (data.banner) {
    if (data.banner.file?.id) {
      formdata.append('banner[id]', data.banner.file?.id);
    }
    if (data.banner.id) {
      formdata.append('banner[id]', data.banner.id);
      formdata.append('banner[show_url]', data.banner.show_url ?? null);
    }
  }
  if (data.introduction_video) {
    if (data.introduction_video.file) {
      formdata.append(
        'introduction_video[id]',
        data.introduction_video.file?.id
      );
    }
    if (data.introduction_video.id) {
      formdata.append('introduction_video[id]', data.introduction_video.id);
      formdata.append(
        'introduction_video[show_url]',
        data.introduction_video.show_url
      );
    }
  }
  if (data.description_course) {
    formdata.append('description_course', data.description_course);
  }
  if (data.description_company) {
    formdata.append('description_company', data.description_company);
  }
  if (data.refund_policy) {
    formdata.append('refund_policy', data.refund_policy);
  }
  formdata.append('price', data.price === null ? 0 : data.price);
  formdata.append('free', data.free);
  if (data.course_host_added) {
    for (var i = 0; i < data.course_host_added.length; i++) {
      formdata.append('course_host_added[]', data.course_host_added[i]);
    }
  }
  if (data.phone_number) {
    formdata.append('phone_number', data.phone_number);
  }
  if (data.age_restrictions) {
    formdata.append('age_restrictions', data.age_restrictions);
  }
  if (data.visibility) {
    formdata.append('visibility', data.visibility);
  }
  if (data.page_owner_id) {
    formdata.append('page_owner_id', data.page_owner_id);
  }

  return fetchApi(
    type === 'create' ? 'courses' : `courses/${type}`,
    type === 'create' ? 'POST' : 'PATCH',
    null,
    formdata
  );
};

export const getDetailCourseApi = async (idCourse: any) => {
  return fetchApi(`courses/${idCourse}`, 'GET', null, null);
};

export const getCourseCategories = async () => {
  return fetchApi('course_categories', 'GET', null, null);
};

export const deleteCourseApi = async (id: any) => {
  return fetchApi(`courses/${id}`, 'DELETE', null, null);
};

// export const shareCourseApi = async (params: any, idCourse: any) => {
//   return fetchApi(`courses/${idCourse}/share`, 'POST', params, null);
// };
export const getTimelineCourseApi = async (idCourse: any, params: any) => {
  return fetchApiSN(`timelines/course/${idCourse}`, 'GET', params, null);
};

export const createInviteCourse = async (idCourse: any, params: any) => {
  return fetchApi(`courses/${idCourse}/invitations`, 'POST', null, {
    target_account_ids: params
  });
};

export const interesteCourseApi = async (idCourse: any) => {
  return fetchApiRequest(
    `courses/${idCourse}/course_followers`,
    'POST',
    null,
    null
  );
};
export const noInteresteCourseApi = async (idCourse: any) => {
  return fetchApiRequest(
    `courses/${idCourse}/course_followers`,
    'DELETE',
    null,
    null
  );
};

export const getListInvitation = async (params: any) => {
  return fetchApiRequest('course_invitations', 'GET', params, null);
};

export const getListInvitationHostCourse = async (params: any) => {
  return fetchApiRequest('course_invitation_hosts', 'GET', params, null);
};
export const getSuggestionsCourse = async (params: any) => {
  return fetchApiRequest('suggestions/course', 'GET', params, null);
};

export const respondInvitationCourse = async (idCourse: any, params: any) => {
  return fetchApiRequest(
    `courses/${idCourse}/invitations/invitations_respond`,
    'POST',
    null,
    params
  );
};

export const getListHostOfCourse = async (idCourse: any, params: any) => {
  return fetchApiRequest(
    `courses/${idCourse}/invitation_hosts`,
    'GET',
    params,
    null
  );
};

export const respondInvitationHostCourse = async (idCourse: any, data: any) => {
  return fetchApiRequest(
    `courses/${idCourse}/invitation_hosts/invitation_respond`,
    'POST',
    null,
    data
  );
};

export const getListHostCourse = async (idCourse: any, params: any) => {
  return fetchApiRequest(`courses/${idCourse}/hosts`, 'GET', params, null);
};

export const getListFaqs = async (idCourse: any) => {
  return fetchApiRequest(`courses/${idCourse}/course_faqs`, 'GET', null, null);
};

export const CRUDFaqs = async (type, idCourse: any, params: any, data) => {
  return fetchApiRequest(
    `courses/${idCourse}/course_faqs${
      type === 'patch' || type === 'delete' ? '/' + params : ''
    }`,
    type,
    null,
    data
  );
};

export const getListUserApplyCourse = async (
  idCourse: any,
  action: any,
  params = null
) => {
  return fetchApiRequest(
    `courses/${idCourse}/course_applies`,
    action,
    null,
    action === 'POST' ? params : null
  );
};

export const getCourseContentLibrary = async (param: any) => {
  return fetchApiRequest('course_content_library', 'GET', param, null);
};

export const getDetailLesson = async (idLesson: any) => {
  return fetchApiRequest(`course_lessons/${idLesson}`, 'GET', null, null);
};

export const CRUDChapters = async (
  type,
  idCourse: any,
  idChapter: any,
  data
) => {
  return fetchApiRequest(
    `courses/${idCourse}/course_chapters${
      type === 'patch' || type === 'delete' ? '/' + idChapter : ''
    }`,
    type,
    data,
    null
  );
};

export const CRUDLessons = async (type, idLesson: any, params: any, data) => {
  return fetchApiRequest(
    `course_lessons${
      type === 'patch' || type === 'delete' ? '/' + idLesson : ''
    }`,
    type,
    params,
    data
  );
};

export const paymentCourse = async (idCourse: any) => {
  return fetchApiRequest(`courses/${idCourse}/payment_course`, 'POST', null, {
    detail_type: 'payment_course'
  });
};

export const sendApproval = async (idCourse: any) => {
  return fetchApiRequest(
    `courses/${idCourse}/send_approval`,
    'POST',
    null,
    null
  );
};

export const getBalance = async () => {
  return fetchApiRequest(`transactions`, 'GET', null, null);
};

export const rechargeEcoin = async (data: any) => {
  return fetchApiRequest(`transfer`, 'POST', null, data);
};

export const ratingCourse = async (idCourse: any, data: any, type: any) => {
  return fetchApi(
    type === 'create'
      ? `courses/${idCourse}/course_rating`
      : `courses/${idCourse}/course_rating/${type}`,
    type === 'create' ? 'POST' : 'PATCH',
    null,
    data
  );
};

export const listRatingCourse = async (idCourse: any) => {
  return fetchApiRequest(
    `courses/${idCourse}/course_rating`,
    'GET',
    null,
    null
  );
};

export const delRatingCourse = async (idCourse: string, idRating: string) => {
  return fetchApiRequest(
    `courses/${idCourse}/course_rating/${idRating}`,
    'DELETE',
    null,
    null
  );
};

export const getListPaid = async idCourse => {
  return fetchApiRequest(
    `courses/${idCourse}/list_participants`,
    'GET',
    null,
    null
  );
};
