import {  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';

const useStyles = makeStyles((theme: Theme) => ({
  lineClamp: {
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden'
  }
}));

const DataItem = props => {
  const { listItem } = props;
  const classes = useStyles();
  return (
    <List sx={{ width: '100%' }}>
      {listItem?.map((item: any) => (
        <React.Fragment key={item.id}>
          <Typography style={{ fontWeight: 500, fontSize: 17 }}>
            {item.title}
          </Typography>
          <ListItem
            key={item.id}
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: item.typeIcon === 'image' ? '8px' : '16px'
            }}
          >
            {!item?.icon && item?.keyword !== 'special' && (
              <AvatarSocial
                type="feed"
                avatarObj={item?.avatar}
                style={{ width: 40, height: 40, marginRight: 15 }}
              />
            )}
            {item?.icon && (
              <ListItemIcon style={{ minWidth: 40 }}>
                {item?.typeIcon === 'image' ? (
                  <img
                    src={item.icon}
                    alt="icon"
                    style={{
                      width: '40px',
                      height: '40px',
                      marginRight: '10px'
                    }}
                  />
                ) : (
                  <i
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: 40,
                      color: '#727B87'
                    }}
                    className={item.icon}
                  ></i>
                )}
              </ListItemIcon>
            )}
            <ListItemText
              primary={
                item?.text && item?.keyword !== 'special' ? (
                  <div>
                    <Typography className={classes.lineClamp}>
                      {item.text}
                    </Typography>
                    <Typography style={{ fontSize: 12 }}>
                      {item?.subText}
                    </Typography>
                  </div>
                ) : (
                  'Không có dữ liệu để hiển thị'
                )
              }
            />
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  );
};

export default DataItem;
