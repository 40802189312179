import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import * as React from 'react';

import { makeStyles } from '@mui/styles';

import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useRouteMatch } from 'react-router-dom';
import { listMenuPost } from 'src/constants/common';
import { PATHS } from 'src/constants/paths';
import { buttonColor, modalBackdrop, scrollStyle } from 'src/constants/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '8px 12px',
    // alignItems: 'center',
    // display: 'flex',
    // justifyContent: 'center',
    ...scrollStyle
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiPaper-root': {
    width: '500px !important'
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: '0px !important',
    margin: '0px !important'
  },
  icon: {
    width: 38,
    height: 38,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '0px 2px'
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: 10,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.button.primary.hover
    },
    padding: '6px !important',
    width: 232,
    margin: 3
  },
  gridItemSelected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: 10,
    '&:hover': {
      cursor: 'pointer'
    },
    backgroundColor: theme.palette.button.primary.background,
    padding: '6px !important',
    width: 232,
    margin: 3
  },

  gridDisabled: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: 10,
    pointerEvents: 'none',
    opacity: '0.5',
    padding: '6px !important',
    width: 232,
    margin: 3,
    '& i': {
      color: 'rgba(0,0,0,1) !important'
    }
  },

  text: {
    fontWeight: '500 !important',
    fontSize: '17px !important',

    marginLeft: '-5px !important'
  }
}));

interface Props {
  open: boolean;
  post: any;
  setOpen: (e) => void;
  handleOther: () => void;
  handleActionKey: (e) => void;
  menuSelected: any;
  listKeyDisabled: any;
  setListKeyDisabled: (e) => void;
  idRecruit: string;
  idProject: string;
}

export default function CustomizedDialogs(props: Props) {
  const classes = useStyles();
  const match: any = useRouteMatch();
  const {
    open,
    post,
    setOpen,
    handleOther,
    handleActionKey,
    menuSelected,
    listKeyDisabled,
    setListKeyDisabled,
    idRecruit,
    idProject
  } = props;

  React.useEffect(() => {
    if (idRecruit || idProject || post?.reblog) {
      return setListKeyDisabled([
        'media',
        'live',
        'life-event',
        'gif',
        'answer',
        'target'
      ]);
    } else if (post?.group) {
      return setListKeyDisabled([
        'live',
        'life-event',
        'gif',
        'answer',
        'target',
        'poll'
      ]);
    }
  }, []);
  const handleClose = () => {
    setOpen(false);
    handleOther && handleOther();
  };

  const filterListMenuPost = el => {
    if (match.path === PATHS.PAGE_DETAIL) {
      return (
        el.key !== 'life-event' && el.key !== 'moment' && el.key !== 'poll'
      );
    } else if (match.path === PATHS.GROUP_DETAIL) {
      return el.key !== 'moment';
    } else {
      return el.key !== 'moment' && el.key !== 'poll';
    }
  };

  const renderListMenu = el => {
    return (
      <>
        {el.icon ? (
          <div
            key={el.key}
            style={{ color: el.color }}
            className={classes.icon}
          >
            <i className={`${el.icon} fa-lg`}></i>
          </div>
        ) : el.image ? (
          <img
            src={el.image}
            alt=""
            style={{
              height: 23,
              width: 23,
              marginRight: '15px',
              ...el.styleImage
            }}
          ></img>
        ) : (
          <></>
        )}

        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography className={classes.text}>{el.label}</Typography>
          {menuSelected.includes(el.key) ? (
            <i
              style={{
                color: buttonColor.backgroundColor,
                fontWeight: 500
              }}
              className="fal fa-check fa-lg"
            ></i>
          ) : null}
        </div>
      </>
    );
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        //TransitionComponent={Transition}
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{ fontWeight: '700', fontSize: 20 }}
            textAlign={'center'}
          >
            Thêm vào bài viết
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid className={classes.root} container>
            {listMenuPost
              .slice(0, listMenuPost.length - 1)
              ?.filter((el: any) => filterListMenuPost(el))
              ?.map((el: any) => (
                <Box
                  key={el.key}
                  className={
                    menuSelected.includes(el.key)
                      ? classes.gridItemSelected
                      : listKeyDisabled.includes(el.key)
                      ? classes.gridDisabled
                      : classes.gridItem
                  }
                  onClick={() => {
                    handleActionKey(el.key);
                    ![
                      'emoji-activity',
                      'tag-people',
                      'checkin',
                      'life-event'
                    ].includes(el.key)
                      ? handleClose()
                      : setOpen(false);
                  }}
                >
                  {listKeyDisabled.includes(el.key) ? (
                    <Tooltip title={el.note}>{renderListMenu(el)}</Tooltip>
                  ) : (
                    renderListMenu(el)
                  )}
                </Box>
              ))}
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
