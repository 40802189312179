import { Box, Button, Divider, Link, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useInView } from 'react-hook-inview';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { likeFollowPage } from 'src/apis/socialPages.api';
import {
  createUpdatePostApi,
  deletePostApi,
  getListMomentApi,
  getListPostTagApi,
  postWatchHistoriesApi,
  reactionPostApi,
  unReactionPostApi
} from 'src/apis/socialPost.api';
import { followUser, unFollowUser } from 'src/apis/socialUser';
import { shortenLargeNumber } from 'src/common/number';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import CUPost from 'src/components/CUPost';
import DialogConfirmDelete from 'src/components/Dialog/DialogConfirmDelete';
import DialogVisibility from 'src/components/Dialog/DialogPostVisibility';
import DialogPreviewMedia from 'src/components/Dialog/DialogPreviewMedia';
import DialogShareToGroup from 'src/components/Dialog/DialogShare/DialogShareToGroup';
import DialogShareToMessenger from 'src/components/Dialog/DialogShare/DialogShareToMessenger';
import MenuAction from 'src/components/Menu';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import NameSocial from 'src/components/ProfileCardImage/NameSocial';
import TextCollapse from 'src/components/TextCollapse/Index';
import MomentVideo from 'src/components/VideoPlayer/MomentVideo';
import { listVisibility } from 'src/constants/common';
import { EnumEmojiReactType } from 'src/constants/enum/emojiReact';
import { PATHS } from 'src/constants/paths';
import { buttonStyle } from 'src/constants/styles';
import { handleTimeShow } from 'src/helpers/string';
import { updateCachePage } from 'src/store/action/saveCacheAction';
import {
  addPostToView,
  deleteOldPostSuccess,
  getPostRequestAction,
  notiSharePost,
  reactionPost,
  removePostToView,
  updatePostSuccessAction
} from 'src/store/action/socialPostAction';

interface Props {
  moment?: any;
  type?: string;
  typeShare?: string;
  setPostData?: any;
  listData?: any;
  setListData?: any;
}

const FeedMoment = (props: Props) => {
  const { moment, type, typeShare, setPostData, listData, setListData } = props;
  const match: any = useRouteMatch();
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      width: '100%',
      display: 'flex',
      padding: '20px 12px'
    },
    wrapAvatar: {
      width: '56px'
    },
    wrapMedia: {
      width: 'calc(100% - 56px)'
    },
    wrapInformation: {
      marginLeft: 15,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between'
    },
    wrapBody: {
      fontSize: '15px !important',
      wordBreak: 'break-word',
      '& a.hashtag': {
        textDecoration: 'none',
        color: match.path.includes('moment')
          ? theme.palette.text.primary
          : '#fff',
        fontWeight: '500 !important'
      }
    },
    wrapContent: {
      marginLeft: 15,
      display: 'inline-flex',
      alignItems: 'flex-end',
      position: 'relative'
    },
    wrapVideo: {
      [theme.breakpoints.down('xl')]: {
        height: 500
      },
      height: 600,
      borderRadius: match.path.includes('moment') ? 12 : 0
    },
    wrapVideoHorizon: {
      [theme.breakpoints.down('xl')]: {
        width: typeShare === 'postShareMoment' ? '100%' : '515px'
      },
      width: typeShare === 'postShareMoment' ? '100%' : '560px',
      borderRadius: match.path.includes('moment') ? 12 : 0
    },
    wrapVideoSquare: {
      [theme.breakpoints.down('xl')]: {
        height: 400,
        width: 400
      },
      width: 500,
      borderRadius: match.path.includes('moment') ? 12 : 0,
      height: 500
    },
    wrapReaction: {
      width: 48,
      height: 48,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        cursor: 'pointer'
      },
      '&> i': {
        fontSize: 22,
        color: !match.path.includes('moment') ? '#fff' : 'undefined'
      }
    },
    wrapReactionLove: {
      width: 48,
      height: 48,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        cursor: 'pointer'
      },
      '&> i': {
        fontSize: 22,
        color: 'rgb(254, 44, 85) !important'
      }
    },
    wrapListReaction: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 20
    },

    wrapReactionInfor: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 10
    },
    wrapButtonMore: {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    colorTag: {
      color: '#fff',
      fontWeight: 700
    },
    boxHeaderMoment: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%'
    },
    headerMomentLeft: {},
    headerMomentRight: {},
    textFollow: {
      fontSize: '16px',
      color: 'rgb(113, 101, 224)',
      padding: '5px 20px',
      border: '1px solid rgb(113, 101, 224)',
      borderRadius: '2px',
      fontWeight: '500',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#e2deff'
      }
    }
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  let params: any = match.params;
  const [postMoment, setPostMoment] = React.useState<any>(moment);
  const [listPostMoment, setListPostMoment] = React.useState<any>([]);
  const meReducer = useSelector((state: any) => state.meReducer.info);
  const rolePage = useSelector(
    (state: any) => state.pageReducer.rolePreviewEdit
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openDialogVisibility, setOpenDialogVisibility] = React.useState(false);
  const [openPreview, setOpenPreview] = React.useState<any>(false);
  const [indexMedia, setIndexMedia] = React.useState(-1);
  const [flagCount, setFlagCount] = React.useState(1);
  const [isVolume, setIsVolume] = React.useState(false);
  const [isLoadMore, setIsLoadMore] = React.useState(false);
  const [paramScroll, setParamScroll] = React.useState<any>();
  const [momentRenderDialog, setMomentRenderDialog] =
    React.useState<any>(moment);
  const [isAddMoment, setIsAddMoment] = React.useState<any>(true);
  const [openCUPost, setOpenCUPost] = React.useState(false);
  const [sharePostNow, setSharePostNow] = React.useState(false);
  const [openDialogShareToGroup, setOpenDialogShareToGroup] =
    React.useState(false);
  const [openDialogShareToMessenger, setOpenDialogShareToMessenger] =
    React.useState(false);
  const [shareTo, setShareTo] = React.useState('');
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const [isReload, setIsReload] = React.useState(false);
  const videoRef: any = React.useRef();
  const [isFollowMoment, setIsFollowMoment] = React.useState(false);
  const [mutedVideo, setMutedVideo] = React.useState(false);
  let status_tags = postMoment?.status_tags;
  let activitiesCache =
    useSelector((state: any) => {
      return state.saveCacheReducer.activitiesCache;
    }) || [];
  let activities =
    useSelector((state: any) => {
      return state.socialPostReducer.activities;
    }) || [];

  const handleFollowMoment = async (action, postMoment) => {
    try {
      let response: any;
      if (action === 'unfollows' && !postMoment.page) {
        response = await unFollowUser(postMoment.account.id);
      } else if (action === 'follows' && !postMoment.page) {
        response = await followUser(postMoment.account.id);
      } else if (action && postMoment.page) {
        response = await likeFollowPage(postMoment.page.id, action);
      }
      if (response.status === 200) {
        setIsReload(true);
        setIsFollowMoment(prev => !prev);
      }
    } catch (error) {}
  };
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  let typePreview: any = query.get('mediaId');

  let optionsShare: any = [
    [
      {
        id: 0,
        key: 'share_now',
        label: 'Chia sẻ ngay',
        icon: 'fal fa-share',
        styleIcon: { fontSize: '20px' },
        action: () => {
          setSharePostNow(true);
        }
      },
      {
        id: 1,
        key: 'share',
        label: 'Chia sẻ lên bảng tin',
        icon: 'fal fa-edit',
        styleIcon: { fontSize: '20px' },
        action: () => {
          setOpenCUPost(true);
        }
      },
      {
        id: 2,
        key: 'sendMessenger',
        label: 'Gửi bằng Emso chat',
        icon: 'fal fa-comment',
        styleIcon: { fontSize: '20px' },
        action: () => {
          setOpenDialogShareToMessenger(true);
        }
      },
      {
        id: 3,
        key: 'shareToGroup',
        label: 'Chia sẻ lên cộng đồng',
        icon: 'fal fa-users',
        styleIcon: { fontSize: '20px' },
        action: () => {
          setShareTo('group');
          setOpenDialogShareToGroup(true);
        }
      },
      {
        id: 4,
        key: 'shareToPage',
        label: 'Chia sẻ lên Trang',
        icon: 'fa-light fa-flag',
        styleIcon: { fontSize: '20px' },
        action: () => {
          setShareTo('page');
          setOpenDialogShareToGroup(true);
        }
      },
      {
        id: 5,
        key: 'shareToFriend',
        label: 'Chia sẻ lên trang cá nhân của bạn bè',
        icon: 'fal fa-user-friends',
        styleIcon: { fontSize: '20px' },
        action: () => {
          setShareTo('friend');
          setOpenDialogShareToGroup(true);
        }
      }
    ]
  ];

  const handleGetComment = async (maxId: any, action: any) => {
    let tag = match.params.tag;

    let response;
    if (type === 'page_hashtag' && match.path === PATHS.PAGE_HASHTAG) {
      response = await getListPostTagApi(tag, { max_id: maxId, limit: 1 });
    } else {
      response = await getListMomentApi({
        max_id: maxId,
        limit: 1
      });
    }

    if (response.status === 200) {
      let data;
      if (type === 'page_hashtag') {
        data = response.data.data;
      } else {
        data = response.data;
      }
      if (action === 'add') {
        if (data.length > 0) {
          if (type === 'page_hashtag' && match.path === PATHS.PAGE_HASHTAG) {
            setListData((prev: any) => ({
              ...response.data,
              data: [...prev.data, ...response.data.data]
            }));
          } else {
            setListPostMoment([...listPostMoment, data[0]]);
            setMomentRenderDialog(data[0]);
          }
        }
      } else {
        if (data.length > 0) {
          setIsAddMoment(true);
        } else {
          setIsAddMoment(false);
        }
      }
    }
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      let res = await deletePostApi(postMoment.id);
      if (res.status === 200) {
        let filterActivitiesCache = activitiesCache.filter(
          item => item.id !== postMoment.id
        );
        setPostMoment(null);
        dispatch(deleteOldPostSuccess(postMoment.id));
        dispatch(
          notiSharePost({
            code: 200,
            msg: 'Bài viết khoảnh khắc của bạn đã bị xóa.'
          })
        );
        setTimeout(() => {
          dispatch(updateCachePage(filterActivitiesCache));
        }, 1000);
        dispatch(deleteOldPostSuccess(postMoment.id));
      }
    } catch (err: any) {
      dispatch(
        notiSharePost({
          code: 400,
          msg: 'Có lỗi xảy ra vui lòng thử lại.'
        })
      );
    }
  };

  const handleUpdateVisibility = async key => {
    let res = await createUpdatePostApi(
      {
        visibility: key
      },
      postMoment.id
    );
    if (res.status === 200) {
      setPostMoment((prev: any) => ({
        ...prev,
        visibility: key
      }));
      setMomentRenderDialog((prev: any) => ({
        ...prev,
        visibility: key
      }));
      dispatch(updatePostSuccessAction(res.data));
    }
  };

  const handleCallAPIClickVote = async (idReaction: number, idPage: string) => {
    let response;
    let data = {
      custom_vote_type: EnumEmojiReactType[idReaction],
      page_id: rolePage?.role === 'admin' ? idPage : null
    };
    if (idReaction < 0) {
      response = await unReactionPostApi(postMoment.id, {
        page_id: rolePage?.role === 'admin' ? idPage : null
      });
      if (response.status === 200) {
        dispatch(
          reactionPost(
            postMoment.id,
            response.data.favourites,
            null,
            response.data.reactions
          )
        );
      }
    } else {
      response = await reactionPostApi(postMoment.id, data);
      if (response.status === 200) {
        dispatch(
          reactionPost(
            postMoment.id,
            response.data.favourites,
            response.data.viewer_reaction,
            response.data.reactions
          )
        );
      }
    }
  };

  const debounceClickPost = useCallback(
    _.debounce(idReaction => {
      handleCallAPIClickVote(idReaction, rolePage?.idPage);
    }, 500),
    [rolePage?.idPage]
  );

  const handleReactPost = async (idReaction: number) => {
    setMomentRenderDialog(prev => ({
      ...prev,
      reactions: [
        ...postMoment.reactions.slice(0, 3),

        {
          type: 'love',
          loves_count: postMoment?.viewer_reaction
            ? postMoment?.reactions?.[3]?.loves_count - 1
            : postMoment?.reactions?.[3]?.loves_count + 1
        },
        ...postMoment.reactions.slice(4)
      ],
      viewer_reaction: postMoment?.viewer_reaction ? null : 'love'
    }));
    setPostMoment(prev => ({
      ...prev,
      reactions: [
        ...postMoment.reactions.slice(0, 3),

        {
          type: 'love',
          loves_count: postMoment?.viewer_reaction
            ? postMoment?.reactions?.[3]?.loves_count - 1
            : postMoment?.reactions?.[3]?.loves_count + 1
        },
        ...postMoment.reactions.slice(4)
      ],
      viewer_reaction: postMoment?.viewer_reaction ? null : 'love'
    }));
    setPostData &&
      setPostData(prev => ({
        ...prev,
        reactions: [
          ...postMoment.reactions.slice(0, 3),

          {
            type: 'love',
            loves_count: postMoment?.viewer_reaction
              ? postMoment?.reactions?.[3]?.loves_count - 1
              : postMoment?.reactions?.[3]?.loves_count + 1
          },
          ...postMoment.reactions.slice(4)
        ],
        viewer_reaction: postMoment?.viewer_reaction ? null : 'love'
      }));
    debounceClickPost(idReaction);
  };

  const handleAction = type => {
    if (type === 'love') {
      if (postMoment?.viewer_reaction) {
        handleReactPost(EnumEmojiReactType.default);
      } else {
        handleReactPost(EnumEmojiReactType.love);
      }
    } else if (type === 'comment') {
      setOpenPreview(true);
      setIsLoadMore(true);
    }
  };

  let listMenu = [
    {
      key: 'edit-visibility',
      icon: 'fa-light fa-pen',
      label: 'Chỉnh sửa quyền riêng tư',
      action: () => {
        setOpenDialogVisibility(true);
        handleCloseMenu();
      },
      visiable:
        postMoment?.course ||
        postMoment?.project ||
        postMoment?.event ||
        (postMoment?.page && postMoment?.post_type === 'moment')
    },
    {
      key: 'delete',
      icon: 'fa-light fa-trash',
      label: 'Xóa video',
      action: () => {
        handleCloseMenu();
        setOpenDelete(true);
      },
      visiable: false
    }
  ];

  const getBody = () => {
    let content = postMoment?.content ? postMoment.content : '';
    return content;
  };

  const renderReaction = (icon, count, type) => {
    return (
      <div className={classes.wrapReactionInfor}>
        <div
          onClick={() => handleAction(type)}
          className={
            type === 'love' && postMoment?.viewer_reaction
              ? classes.wrapReactionLove
              : classes.wrapReaction
          }
          style={{
            backgroundColor: !match.path.includes('moment')
              ? 'rgba(255,255,255,0.1)'
              : 'rgba(0,0,0,0.1)'
          }}
        >
          {icon}
        </div>
        <Typography
          style={{
            fontSize: 12,
            fontWeight: 500,
            color: match.path.includes('moment') ? '#65676b' : '#fff'
          }}
        >
          {shortenLargeNumber(count)}
        </Typography>
      </div>
    );
  };

  const renderVisibilityIcon = (color = null) => {
    let icons = listVisibility?.find(
      (el: any) => el.key === postMoment?.visibility
    )?.icon;

    return <i className={icons} style={{ color: color as any }}></i>;
  };

  const updateVideoHistories = React.useCallback(
    _.debounce(async data => {
      await postWatchHistoriesApi(data);
    }, 800),
    []
  );

  const [ref, isVisible] = useInView({
    threshold: 1
  });
  const inViewPosts =
    useSelector((state: any) => {
      return state.socialPostReducer.inViewPosts;
    }) || [];

  const toggleMute = () => {
    setIsVolume(!isVolume);
  };

  useEffect(() => {
    if (postMoment) {
      if (isVisible && !typePreview) {
        dispatch(addPostToView({ id: postMoment?.id, autoPlay: true }));
      } else {
        dispatch(removePostToView({ id: postMoment?.id, autoPlay: false }));
      }
    }
  }, [isVisible, JSON.stringify(postMoment)]);

  const renderActionReaction = () => {
    return (
      <div className={classes.wrapListReaction}>
        {renderReaction(
          <i
            className={`fa-solid fa-heart ${
              postMoment?.viewer_reaction ? 'fa-beat' : ''
            }`}
          ></i>,
          postMoment?.reactions[3].loves_count,
          'love'
        )}

        {renderReaction(
          <i className="fa-solid fa-comment-dots"></i>,
          postMoment?.replies_total,
          'comment'
        )}

        {renderReaction(
          <IconButtonOptions
            startIcon="fa-solid fa-share"
            startIconStyle={{
              fontSize: '22px'
            }}
            styleListMenu={{ minWidth: '300px' }}
            style={{
              color: match.path !== PATHS.MOMENT ? '#fff' : 'text.primary',
              borderRadius: '50%',
              height: '48px',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
            options={optionsShare}
            openPopup={true}
          />,
          postMoment?.reblogs_count,
          'share'
        )}
      </div>
    );
  };

  const renderAvatar = style => {
    return (
      <div
        className={classes.wrapAvatar}
        style={
          match.path.includes('moment')
            ? {}
            : {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }
        }
      >
        <AvatarSocial
          type="feed"
          style={{ width: 56, height: 56, cursor: 'pointer', ...style }}
          avatarObj={
            postMoment?.page?.avatar_media ?? postMoment?.account?.avatar_media
          }
          isPopup
          object={postMoment?.page ?? postMoment?.account}
        />
      </div>
    );
  };

  const renderTimeVisible = (color = null) => {
    return (
      <>
        <Link
          sx={{ color: color ? color : 'text.secondary', fontSize: 14 }}
          href={`/${postMoment.account.username}/posts/${postMoment.id}`}
          underline="hover"
        >
          {handleTimeShow(postMoment?.created_at)}&nbsp;
        </Link>
        <span style={{ textAlign: 'center' }}>
          &nbsp;
          {postMoment?.group ? (
            postMoment?.group?.is_private ? (
              <i className="fas fa-users"></i>
            ) : (
              <i className="fas fa-globe-asia"></i>
            )
          ) : (
            renderVisibilityIcon(color)
          )}
        </span>
      </>
    );
  };

  const renderContent = (pageMoment = null) => {
    return (
      <div
        className={classes.wrapContent}
        style={{
          marginTop: match.path.includes('moment') ? 15 : 0
        }}
        ref={ref}
      >
        <Box position="relative">
          <img
            onClick={() => {
              setOpenPreview(true);
              setIsLoadMore(true);
            }}
            alt="preview_moment"
            className={
              postMoment?.media_attachments?.[0]?.meta?.small?.aspect > 1
                ? classes.wrapVideoHorizon
                : postMoment?.media_attachments?.[0]?.meta?.small?.aspect === 1
                ? classes.wrapVideoSquare
                : classes.wrapVideo
            }
            src={
              postMoment?.media_attachments?.[0]?.show_url ??
              postMoment?.media_attachments?.[0]?.preview_url
            }
          />

          {inViewPosts[inViewPosts.length - 1]?.id === moment.id &&
            inViewPosts[inViewPosts.length - 1]?.autoPlay &&
            postMoment?.media_attachments[0] && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0
                }}
              >
                <MomentVideo
                  videoRef={videoRef}
                  isVolume={isVolume}
                  pageMoment={pageMoment}
                  openPreview={openPreview}
                  mutedVideo={mutedVideo}
                  setMutedVideo={setMutedVideo}
                  action={() => {
                    setOpenPreview(true);
                    setIsLoadMore(true);
                  }}
                  video={postMoment?.media_attachments[0]}
                  typePage="feed_moment"
                />
              </div>
            )}
        </Box>

        {match.path.includes('moment') ? renderActionReaction() : null}
      </div>
    );
  };

  const renderActionPost = () => {
    return meReducer?.id === postMoment?.account.id ||
      rolePage.role === 'admin' ||
      postMoment?.page?.page_relationship?.role === 'admin' ? (
      <div className={classes.wrapButtonMore} onClick={handleClick}>
        <i
          className="fa-solid fa-ellipsis-vertical"
          style={match.path.includes('moment') ? {} : { color: '#fff' }}
        ></i>
      </div>
    ) : postMoment.friendship_status !== 'ARE_FRIENDS' ? (
      ''
    ) : (
      <Button
        style={{
          ...buttonStyle,
          textTransform: 'none',
          width: '89px !important',
          marginRight: '0px',
          padding: '0px'
        }}
      >
        Thêm bạn bè
      </Button>
    );
  };
  React.useEffect(() => {
    if (activities.length) {
      setListPostMoment(
        activities?.filter((el: any) => el?.post_type === 'moment')
      );
    } else if (listData?.length && type === 'page_hashtag') {
      setListPostMoment(listData);
    }
  }, [JSON.stringify(activities), JSON.stringify(listData)]);

  React.useEffect(() => {
    if (listPostMoment.length && postMoment?.id) {
      let indexPost = listPostMoment?.findIndex(
        (el: any) => el?.id === momentRenderDialog?.id
      );
      setIndexMedia(indexPost);
    }
  }, [JSON.stringify(listPostMoment), postMoment?.id]);

  React.useEffect(() => {
    if (
      indexMedia <= -1 ||
      indexMedia >=
        (match.path === PATHS.MOMENT
          ? activities.length
          : listPostMoment.length) +
          1
    ) {
      return;
    }

    if (indexMedia === 0) {
      setMomentRenderDialog(
        match.path === PATHS.MOMENT
          ? activities[indexMedia]
          : listPostMoment[indexMedia]
      );
    }

    if (indexMedia - 1 >= 0) {
      if (
        indexMedia ===
          (match.path === PATHS.MOMENT
            ? activities.length - 1
            : listPostMoment.length) &&
        isLoadMore
      ) {
        if (params.key === undefined && match.path === PATHS.MOMENT) {
          dispatch(
            getPostRequestAction(
              {
                max_id: activities[activities.length - 1].score,
                limit: 3
              },
              'discover-moment',
              'update_moment'
            )
          );

          setMomentRenderDialog(activities[indexMedia]);
        } else if (
          params.key === 'video-follow' &&
          match.path === PATHS.MOMENT
        ) {
          dispatch(
            getPostRequestAction(
              {
                max_id: activities[activities.length - 1].score,
                limit: 3
              },
              'moment',
              'update_moment'
            )
          );
          setMomentRenderDialog(activities[indexMedia]);
        } else {
          handleGetComment(
            listPostMoment[indexMedia - 1]?.score ??
              listPostMoment[indexMedia - 1]?.id,
            'add'
          );
        }
      } else if (
        match.path === PATHS.PAGE_HASHTAG &&
        type === 'page_hashtag' &&
        indexMedia === listPostMoment.length - 1 &&
        isLoadMore
      ) {
        handleGetComment(
          listData[listData.length - 1]?.score ??
            listData[listData.length - 1]?.id,
          'add'
        );
        setMomentRenderDialog(listData[indexMedia]);
      } else if (
        indexMedia === listPostMoment.length - 1 &&
        !(match.path === PATHS.MOMENT) &&
        !(match.path === PATHS.PAGE_HASHTAG)
      ) {
        handleGetComment(
          listPostMoment[indexMedia]?.score ?? listPostMoment[indexMedia]?.id,
          'check'
        );
        setMomentRenderDialog(listPostMoment[indexMedia]);
      } else {
        setMomentRenderDialog(
          match.path === PATHS.MOMENT
            ? activities[indexMedia]
            : listPostMoment[indexMedia]
        );
      }
    }
  }, [indexMedia]);

  React.useEffect(() => {
    if (isVisible && postMoment?.id && flagCount === 1) {
      setFlagCount(prev => prev + 1);
      updateVideoHistories({
        action_type: 'impressions',
        status_ids: [postMoment.id]
      });
    }
  }, [isVisible, postMoment?.id]);

  React.useEffect(() => {
    if (momentRenderDialog?.id) {
      setParamScroll(momentRenderDialog?.id);
    }
  }, [momentRenderDialog?.id]);

  React.useEffect(() => {
    if (!typePreview) {
      setMomentRenderDialog(moment);
      setPostMoment(moment);
    }
  }, [typePreview]);

  useEffect(() => {
    if (postMoment?.account_relationships?.following && !postMoment.page) {
      setIsFollowMoment(true);
    } else if (
      postMoment?.page?.page_relationship?.following &&
      postMoment.page
    ) {
      setIsFollowMoment(true);
    } else {
      setIsFollowMoment(false);
    }
  }, []);

  const renderButtonFollow = itemMoment => {
    if (!isFollowMoment) {
      return (
        <Box
          className={classes.textFollow}
          onClick={() => {
            handleFollowMoment('follows', itemMoment);
          }}
        >
          Theo dõi
        </Box>
      );
    } else if (isFollowMoment && isReload) {
      return (
        <Box
          className={classes.textFollow}
          onClick={() => {
            handleFollowMoment('unfollows', itemMoment);
          }}
        >
          Đang theo dõi
        </Box>
      );
    } else if (!isReload) {
      return null;
    }
  };

  return (
    postMoment && (
      <>
        {match.path.includes('moment') ? (
          <div className={classes.root}>
            {renderAvatar({})}
            <div className={classes.wrapMedia}>
              <div className={classes.wrapInformation}>
                <div style={{ width: '100%' }}>
                  <div className={classes.boxHeaderMoment}>
                    <div className={classes.headerMomentLeft}>
                      <div style={{ display: 'block' }}>
                        <NameSocial
                          post={postMoment}
                          name={
                            postMoment.page?.title ??
                            postMoment.account?.display_name
                          }
                          isPopup
                          object={postMoment.page ?? postMoment?.account}
                        />
                      </div>
                      {match.url !== '/moment' && renderTimeVisible()}

                      <Typography component="div" className={classes.wrapBody}>
                        <TextCollapse
                          type={true}
                          data={getBody()}
                          status_tags={status_tags}
                          typeShare={typeShare}
                          info={postMoment}
                        />
                      </Typography>
                    </div>
                    <div className={classes.headerMomentRight}>
                      {(!postMoment?.page &&
                        postMoment?.account?.id !== meInfo?.id) ||
                      (postMoment?.page &&
                        postMoment?.page?.account?.id !== meInfo?.id)
                        ? renderButtonFollow(postMoment)
                        : null}
                    </div>
                  </div>
                  {postMoment?.song && (
                    <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                      <i className="fa-solid fa-music"></i> {postMoment?.song}
                    </Typography>
                  )}
                </div>

                {renderActionPost()}
              </div>

              {renderContent()}
            </div>
          </div>
        ) : (
          <>
            <div
              className={classes.root}
              style={{
                backgroundColor:
                  postMoment?.media_attachments?.[0]?.meta?.small
                    ?.average_color,
                borderRadius: 10,
                padding: '0 20px',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 20,
                position: 'relative',
                width: typeShare === 'postShareMoment' ? '94%' : '100%'
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  left: 0,
                  zIndex: 10,
                  backgroundImage:
                    'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0))',
                  padding: 15,
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderRadius: '10px 10px 0 0'
                }}
              >
                <div style={{ display: 'flex' }}>
                  {renderAvatar({ width: 38, height: 38 })}
                  <div>
                    <div style={{ display: 'block' }}>
                      <NameSocial
                        post={postMoment}
                        style={{
                          color: '#fff',
                          '&:hover': {
                            cursor: 'pointer'
                          }
                        }}
                        name={
                          postMoment?.page_owner?.title ??
                          postMoment.account?.display_name
                        }
                        isPopup
                        object={postMoment.page_owner ?? postMoment?.account}
                        styleTextHeader={
                          // { color: '#fff' }
                          typeShare === 'postShareMoment' ||
                          match.path !== PATHS.MOMENT
                            ? { color: '#fff' }
                            : null
                        }
                      />
                    </div>

                    {renderTimeVisible('#fff' as any)}
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div className={classes.wrapButtonMore}>
                    {isVolume ? (
                      <div
                        onClick={() => {
                          toggleMute();
                        }}
                      >
                        <i
                          className="fa-solid fa-volume-high"
                          style={{ color: '#fff' }}
                        ></i>
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          toggleMute();
                        }}
                      >
                        <i
                          className="fa-solid fa-volume-xmark"
                          style={{ color: '#fff' }}
                        ></i>
                      </div>
                    )}
                  </div>
                  <div style={{ width: 8 }}></div>
                  {renderActionPost()}
                </div>
              </div>
              <div
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  zIndex: 10,
                  display: 'flex',
                  padding: 15,
                  paddingRight: 100,
                  borderRadius: '0px 0px 10px 10px',
                  backgroundImage:
                    'linear-gradient( rgba(0,0,0,0),rgba(0,0,0,0.5))',
                  width: '100%'
                }}
              >
                <TextCollapse
                  type={true}
                  data={getBody()}
                  status_tags={status_tags}
                  typeShare={typeShare}
                  info={postMoment}
                  style={{
                    fontSize: '18px',
                    color: '#fff',
                    display: '-webkit-box',
                    '-webkit-line-clamp': '2',
                    '-webkit-box-orient': 'vertical',
                    overflow: 'hidden'
                  }}
                  styleHastag={{
                    color: '#fff'
                  }}
                />
              </div>
              {renderContent('momentInFeed' as any)}
              <div
                style={{
                  position: 'absolute',
                  bottom: 15,
                  right: 15,
                  zIndex: 15
                }}
              >
                {renderActionReaction()}
              </div>
            </div>
          </>
        )}
        <div style={{ padding: '0px 12px' }}>
          <Divider />
        </div>
        <CUPost
          post={moment}
          openCUPost={openCUPost}
          setOpenCUPost={setOpenCUPost}
          shareNow={sharePostNow}
        />
        {openDialogShareToGroup && (
          <DialogShareToGroup
            shareTo={shareTo}
            post={moment}
            open={openDialogShareToGroup}
            handleClose={() => {
              setOpenDialogShareToGroup(false);
            }}
            // pageInfo={null}
          />
        )}

        {openDialogShareToMessenger && (
          <DialogShareToMessenger
            open={openDialogShareToMessenger}
            handleClose={() => setOpenDialogShareToMessenger(false)}
            post={moment}
            meInfo={meInfo}
            type={type}
          />
        )}
        {anchorEl && (
          <MenuAction
            anchorEl={anchorEl}
            openPopper={openPopper}
            setOpenPopper={setOpenPopper}
            handleCloseMenu={handleCloseMenu}
            listMenu={listMenu.filter(item => !item.visiable)}
          />
        )}

        {openDelete && (
          <DialogConfirmDelete
            title="Xóa video"
            text="Bạn chắc chắn muốn xóa video này? Video sau khi xóa sẽ không thể hoàn tác"
            labelButton="Xóa"
            handleClose={() => setOpenDelete(false)}
            open={openDelete}
            action={handleDelete}
          />
        )}

        {openDialogVisibility && (
          <DialogVisibility
            value={postMoment.visibility}
            handleAction={key => {
              handleUpdateVisibility(key);
            }}
            open={openDialogVisibility}
            setOpen={setOpenDialogVisibility}
          />
        )}

        {openPreview && postMoment && (
          <DialogPreviewMedia
            open={openPreview}
            setOpen={setOpenPreview}
            indexMedia={indexMedia}
            post={{
              media_attachments: postMoment.media_attachments
            }}
            setIndexMedia={setIndexMedia}
            setMediaSelected={index => {}}
            postMedia={momentRenderDialog}
            setPostMedia={setMomentRenderDialog}
            listMoments={
              listData?.length && type === 'page_hashtag'
                ? listData
                : activities
            }
            listPostMoment={listPostMoment}
            isAddMoment={isAddMoment}
            setIsLoadMore={setIsLoadMore}
          />
        )}
      </>
    )
  );
};

export default FeedMoment;
