import { cloneDeep } from 'lodash';
import * as types from 'src/constants/store/growSocial';
const _ = require('lodash');

const initialState = {
  info: {} as any,
  rolePreviewEdit: { role: '' },
  error: false,
  tempInteract: [],
  infoPreview: null,
  isLoading: false,
  isCheckEmptyLayout: false
};

export const growReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GROW_PREVIEW_INFO: {
      let { data } = action.payload;
      let newState = _.cloneDeep(state);
      return { ...newState, infoPreview: data };
    }

    case types.GET_GROW_DETAIL_SUCCESS: {
      let { data } = action.payload;
      return {
        ...state,
        info: data,
        infoPreview: null
      };
    }

    case types.GET_GROW_DONATE: {
      let { data } = action.payload;
      const newState = cloneDeep(state);
      return {
        ...newState,
        info: {
          ...newState.info,
          donate_value: newState.info.donate_value + data
        },
        infoPreview: null
      };
    }

    case types.RESET_GROW: {
      return {
        info: {
          id: '',
          title: '',
          address: '',
          business_license: null,
          start_date: '',
          due_date: '',
          identity_card_back: null,
          identity_card_front: null,
          identity_verification_video: null,
          introduction_video: null,
          phone_number: '',
          refund_policy: '',
          risks: '',
          target_value: '',
          visibility: '',
          location: null,
          description_project: '',
          description_company: '',
          plan: '',
          page_category_id: [],
          banner: null
        },
        rolePreviewEdit: { role: '' },
        infoPreview: null
      };
    }

    case types.GET_GROW_DETAIL_ERROR: {
      return { ...state, error: true, infoPreview: null };
    }
    case types.LOADING_GROW: {
      return { ...state, isLoading: action.payload.value };
    }
    case types.CHECK_EMPTY_PROJECT: {
      let { isCheck } = action.payload;
      return {
        ...state,
        isCheckEmptyLayout: isCheck
      };
    }
    case types.UPDATE_FOLLOW_PROJECT: {
      let { data } = action.payload;
      const newState = cloneDeep(state);
      return {
        ...newState,
        info: {
          ...newState.info,
          project_relationship: {
            ...newState.info.project_relationship,
            follow_project: data
          }
        }
      };
    }
    case types.UPDATE_SHARE_COUNT_PROJECT: {
      return {
        ...state,
        info: {
          ...state.info,
          shares_count: state.info.shares_count + 1
        }
      };
    }
    case types.UPDATE_STATUS_COUNT_PROJECT: {
      return {
        ...state,
        info: {
          ...state.info,
          statuses_count: state.info.statuses_count + 1
        }
      };
    }
    default:
      return state;
  }
};
