import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getCategoriesMusic } from 'src/apis/SocialMusic.api';
import iconBXH from 'src/assets/images/Music/BXH.svg';
import iconAlbum from 'src/assets/images/Music/album.svg';
import iconArtist from 'src/assets/images/Music/artistMusic.svg';
import iconAdd from 'src/assets/images/Music/iconAdd.svg';
import iconAddWhite from 'src/assets/images/Music/iconAddWhite.svg';
import iconInvite from 'src/assets/images/Music/invite.svg';
import iconHome from 'src/assets/images/Music/khamPha.svg';
import iconPlaylist from 'src/assets/images/Music/playlistMusic.svg';
import iconSearch from 'src/assets/images/Music/searchMusic.svg';
import BaseLink from 'src/components/Link/BaseLink';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import { PATHS } from 'src/constants/paths';
import { buttonColor } from 'src/constants/styles';
import CreateAlbum from '../MusicSpaceHome/CreateAlbum';

const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      root: {
        height: 'calc(100vh - 140px)',
        width: 310,
        position: 'fixed',
        top: 50,
        marginBottom: 20,
        boxShadow: '1px 0px 5px 0.4px rgb(0 0 0 / 10%)'
      },
      rootMusic: {
        width: '100%',
        height: '100%',
        padding: '10px 0px'
      },

      avatar: {
        width: '56px !important',
        height: '56px !important',
        borderRadius: 10
      },
      list: {
        width: 'calc(100% - 12px)'
      },
      selected: {
        backgroundColor: '#cccccc52 !important'
      },
      textPrimary: {
        fontSize: '15px !important',

        fontWeight: '500 !important'
      },
      divider: {
        width: '90%',
        margin: '0 auto !important'
      },
      title: {
        fontSize: '18px !important',
        fontWeight: '500 !important',
        textTransform: 'uppercase',
        padding: '10px'
      }
    }),
  { index: 1 }
);

const MusicSpaceBar = props => {
  const classes = useStyles();
  const history = useHistory();
  const match: any = useRouteMatch();
  const [selectedIndex, setSelectedIndex] = React.useState<any>('feed');
  const [openDialogCreate, setOpenDialogCreate] = React.useState<any>(false);
  const [listCategory, setListCategory] = React.useState<any>([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  const theme: any = useTheme();
  const getCategoriesMusicSpace = async () => {
    try {
      let res = await getCategoriesMusic();
      if (res.status === 200) {
        setListCategory(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    getCategoriesMusicSpace();
  }, []);

  React.useEffect(() => {
    switch (match.path) {
      case PATHS.MUSICSPACE:
        setSelectedIndex('feed');
        break;
      case PATHS.MUSICSPACE_CHART:
        setSelectedIndex('music_chart');
        break;
      case PATHS.MUSICSPACE_ALBUM:
        setSelectedIndex('album');
        break;
      case PATHS.MUSICSPACE_SEARCH:
        setSelectedIndex('search_music');
        break;
      case PATHS.MUSICSPACE_MY_PLAYLIST:
        setSelectedIndex('my_Playlist');
        break;
      case PATHS.MUSICSPACE_UPLOADED:
        setSelectedIndex('uploaded_music');
        break;
      case PATHS.MUSICSPACE_DETAIL_PLAYLIST:
        setSelectedIndex('detailPlaylist');
        break;
      case PATHS.MUSICSPACE_TRACK:
        setSelectedIndex('track');
        break;
      case PATHS.MUSICSPACE_DETAIL_SEARCH:
        setSelectedIndex('detailSearch');
        break;
      case PATHS.MUSICSPACE_NEW_RELEASE_SONG:
        setSelectedIndex('newRelease');
        break;
      case PATHS.MUSICSPACE_NEW_RELEASE_ALBUM:
        setSelectedIndex('newReleaseAlbm');
        break;
      case PATHS.MUSICSPACE_CREATE_ALBUM:
        setSelectedIndex('create_Album');
        break;
      case PATHS.MUSICSPACE_ARTIST:
        setSelectedIndex('artist');
        break;
      case PATHS.MUSICSPACE_INVITE:
        setSelectedIndex('invite');
        break;
      case PATHS.MUSICSPACE_DETAIL:
        setSelectedIndex('detail');
        break;
      case PATHS.MUSICSPACE_DETAIL_ALBUM:
        setSelectedIndex('detail_album');
        break;
    }
  }, [match.path, JSON.stringify(match.params)]);

  const renderListMusics = music => {
    return (
      <List className={classes.list} component="nav" aria-label="sidebar">
        {music?.map((item: any, index) => (
          <BaseLink
            key={index}
            url={item?.url ? item?.url : null}
            component={
              <ListItem
                sx={{
                  maxHeight: item.icon ? '58px' : '72px',
                  padding: '5px',
                  margin: '5px 6px',
                  borderRadius: '8px',
                  background:
                    item.id === 'create_Album'
                      ? theme.palette.mode === 'dark'
                        ? '#E1E5EB'
                        : buttonColor.backgroundColor
                      : null,
                  color:
                    item.id === 'create_Album'
                      ? theme.palette.mode === 'dark'
                        ? '#000'
                        : '#fff'
                      : null,
                  '&:hover': {
                    background:
                      item.id === 'create_Album'
                        ? theme.palette.mode === 'dark'
                          ? '#E1E5EB'
                          : '#7D72DF'
                        : 'rgba(0, 0, 0, 0.04)'
                  }
                }}
                onClick={() => item?.action()}
                button
                key={item?.id}
                className={
                  selectedIndex === item?.id ? classes.selected : 'undefined'
                }
              >
                {item?.icon && (
                  <ListItemIcon sx={{ minWidth: 24 }}>
                    <img
                      style={{
                        margin: '0px 3px',
                        width: '22px',
                        height: '22px',
                        filter:
                          theme.palette.mode === 'dark'
                            ? 'invert(100%)'
                            : 'unset',
                        color:
                          item.id === 'create_Album'
                            ? theme.palette.mode === 'dark'
                              ? '#000'
                              : '#fff'
                            : 'unset'
                      }}
                      src={`${item.icon}`}
                      alt="icon"
                    />
                  </ListItemIcon>
                )}
                <ListItemText
                  style={{ marginLeft: 10 }}
                  primary={
                    <Typography className={classes.textPrimary}>
                      {item?.title}
                    </Typography>
                  }
                  secondary={
                    <Typography style={{ fontSize: 13, color: '#65676b' }}>
                      {item.subTitle}
                    </Typography>
                  }
                />
              </ListItem>
            }
          />
        ))}
      </List>
    );
  };

  const listMenuMusic = [
    {
      id: 'feed',
      title: 'Khám phá',
      icon: iconHome,
      action: () => {
        history.push(PATHS.MUSICSPACE);
      }
    },
    {
      id: 'music_chart',
      title: 'Bảng xếp hạng',
      icon: iconBXH,
      action: () => {
        history.push(PATHS.MUSICSPACE_CHART);
      }
    },
    {
      id: 'search_music',
      title: 'Tìm kiếm',
      icon: iconSearch,
      action: () => {
        history.push(PATHS.MUSICSPACE_SEARCH);
      }
    },
    {
      id: 'my_Playlist',
      title: 'Danh sách của tôi',
      icon: iconPlaylist,
      action: () => {
        history.push(PATHS.MUSICSPACE_MY_PLAYLIST);
      }
    },
    {
      id: 'uploaded_music',
      title: 'Đã tải lên',
      icon: iconPlaylist,
      action: () => {
        history.push('/music_space/music/song');
      }
    },
    {
      id: 'create_Album',
      title: 'Tạo mới Album/Bài hát',
      icon: theme.palette.mode === 'dark' ? iconAdd : iconAddWhite,
      action: () => {
        setOpenDialogCreate(true);
      }
    },
    {
      id: 'invite',
      title: 'Lời mời',
      icon: iconInvite,
      action: () => {
        history.push(PATHS.MUSICSPACE_INVITE);
      }
    },
    {
      id: 'artist',
      title: 'Nghệ sĩ',
      icon: iconArtist,
      action: () => {
        history.push(PATHS.MUSICSPACE_ARTIST);
      }
    },
    {
      id: 'album',
      title: 'Album của tôi',
      icon: iconAlbum,
      action: () => {
        history.push(PATHS.MUSICSPACE_ALBUM);
      }
    }
  ];

  return (
    <Box
      sx={{
        width: '240px',
        backgroundColor: 'background.primary'
      }}
      className={classes.root}
    >
      <div className={classes.rootMusic}>
        <Typography className={classes.title} sx={{ textTransform: 'none' }}>
          Không gian Âm nhạc
        </Typography>
        {renderListMusics(listMenuMusic)}
        <Divider className={classes.divider} />
      </div>
      {openDialogCreate && (
        <CreateAlbum
          setOpenDialogCreate={setOpenDialogCreate}
          openDialogCreate={openDialogCreate}
          listCategory={listCategory}
          getCategoriesMusicSpace={getCategoriesMusicSpace}
          setNoti={setNoti}
          setOpenSnackbar={setOpenSnackbar}
        />
      )}
      {openSnackbar && (
        <SnackbarNotification
          style={{ marginLeft: '30px', marginBottom: '70px' }}
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          code={noti?.code}
          message={noti?.message}
        />
      )}
    </Box>
  );
};

export default MusicSpaceBar;
