import * as types from 'src/constants/store/setting';
const _ = require('lodash');

const initialState = {
  loading: false
};

export const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COLOR_IMAGE_SUCCESS: {
      let { data, href } = action.payload;
      return {
        ...state,
        ...data,
        hrefCurrent: href
      };
    }
    case types.SET_LOADING_REQ: {
      return { ...state, loading: action.payload };
    }
    case types.RESET_REQ: {
      return {};
    }

    case types.SAVE_LINK_BANNER: {
      let { link, page } = action.payload;
      return {
        ...state,
        link,
        page
      };
    }

    case types.SAVE_FILE_BANNER: {
      let { file } = action.payload;
      return {
        ...state,
        ...file
      };
    }

    case types.UPLOAD_FILE_BANNER: {
      let { isLoading } = action.payload;

      return {
        ...state,
        isLoading: isLoading
      };
    }

    case types.CHECK_HAS_SCROLL: {
      let { hasScroll } = action.payload;
      return {
        ...state,
        hasScroll: hasScroll
      };
    }
    default:
      return state;
  }
};
