import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { makeStyles, useTheme } from '@mui/styles';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { shortcutListApi } from 'src/apis/socialPost.api';
import group_cover from 'src/assets/images/group_cover.png';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import {
  buttonColor,
  lineClamp,
  WebkitScrollbarRGB
} from 'src/constants/styles';
import ButtonOptions from '../Button/ButtonOptions';
import InputSearch from '../Input/SocialInput/InputSearch';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    width: '100%'
  },
  textPrimary: {
    fontSize: '15px !important',
    fontWeight: '500 !important',
    wordBreak: 'break-word',
    ...(lineClamp(1) as any)
  },
  avatar: {
    width: '36px !important',
    height: '36px !important',
    borderRadius: 8
  },
  link: {
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    textDecoration: 'none'
  }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = ({ children, onClose, ...other }) => {
  const theme: any = useTheme();

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, textAlign: 'center', fontWeight: 600 }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

const DialogShortcut = props => {
  const {
    open,
    handleClose,
    shortcutList,
    fetchShortcutList,
    setIsLoading,
    isLoading
  } = props;

  const classes = useStyles();
  const theme: any = useTheme();

  const [keyword, setKeyword] = useState('');
  const keywordLower = keyword.toLowerCase();

  const [newDataShortcuts, setNewDataShortcuts] = useState(shortcutList || []);

  const handleShortcutAction = (item, status) => {
    setNewDataShortcuts(() => {
      const newData = cloneDeep(newDataShortcuts);
      const index = newData.findIndex(
        prevShortcut => prevShortcut.id === item.id
      );
      newData[index].status = status;

      return newData;
    });
  };

  const handleSaveShortcuts = async () => {
    const filteredList = newDataShortcuts.filter(newShortcut => {
      return shortcutList.some(
        shortcut =>
          shortcut.id === newShortcut.id &&
          shortcut.status !== newShortcut.status
      );
    });

    const dataReplace = {
      shortcuts: filteredList.map(item => ({
        id: item.id,
        status: item.status
      }))
    };

    const res = await shortcutListApi('POST', dataReplace);
    if (res.status === 200) {
      fetchShortcutList();
    }
  };

  const renderShortcutItem = (item, index) => {
    const roleShortcuts = [
      {
        key: 'auto',
        icon: 'fa-solid fa-sparkles',
        label: 'Sắp xếp tự động',
        action: () => handleShortcutAction(item, 'auto')
      },
      {
        key: 'pinned',
        icon: 'fa-solid fa-thumbtack',
        label: 'Ghim lên đầu',
        action: () => handleShortcutAction(item, 'pinned')
      },
      {
        key: 'hide',
        icon: 'fa-solid fa-eye',
        label: 'Ẩn',
        action: () => handleShortcutAction(item, 'hide')
      }
    ];

    return (
      <Box key={index}>
        <ListItem
          style={{ borderRadius: 10, cursor: 'initial' }}
          button
          key={item?.id}
        >
          <ListItemIcon sx={{ minWidth: 40 }}>
            <Avatar
              variant="rounded"
              className={classes.avatar}
              src={
                item?.group
                  ? item?.group?.banner?.show_url
                  : item?.page
                  ? item?.page?.avatar_media?.show_url
                  : group_cover
              }
            ></Avatar>
          </ListItemIcon>

          <ListItemText
            style={{ marginLeft: 10 }}
            primary={
              <Typography className={classes.textPrimary}>
                {item?.group ? item.group.title : item.page.title}
              </Typography>
            }
          />

          <ButtonOptions
            options={roleShortcuts}
            endIcon={<ArrowDropDownIcon style={{ fontSize: '25px' }} />}
            width={'250px'}
            label={
              <ListItem sx={{ p: 0 }}>
                <ListItemIcon>
                  <i
                    style={{ fontSize: '20px' }}
                    className={
                      item.status === 'auto'
                        ? 'fa-solid fa-sparkles'
                        : item.status === 'pinned'
                        ? 'fa-solid fa-thumbtack'
                        : 'fa-solid fa-eye'
                    }
                  ></i>
                </ListItemIcon>
                <ListItemText
                  id="switch-list-label-bluetooth"
                  primary={
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: 1.2
                      }}
                    >
                      {item.status === 'auto'
                        ? 'Sắp xếp tự động'
                        : item.status === 'pinned'
                        ? 'Ghim lên đầu'
                        : 'Ẩn'}
                    </p>
                  }
                  sx={{ color: 'text.primary' }}
                />
              </ListItem>
            }
            style={{
              '&:hover': {
                backgroundColor: 'button.primary.hover'
              },
              backgroundColor:
                theme.palette.mode === 'dark' ? '#4b4b4b' : '#e4e6eb',
              borderRadius: '8px',
              margin: '0 0 0 12px',
              maxWidth: '250px',
              height: '46px !important',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0px 8px'
            }}
          />
        </ListItem>
      </Box>
    );
  };

  const filteredShortcuts = newDataShortcuts.filter(shortcut =>
    (shortcut?.page?.title || shortcut?.group?.title)
      ?.toLowerCase()
      ?.includes(keywordLower)
  );

  React.useEffect(() => {
    document.body.style.overflow = 'hidden !important';
  }, []);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Chỉnh sửa lối tắt
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <Typography
            style={{
              fontSize: 16,
              color: theme.palette.text.secondary,
              marginBottom: 10
            }}
          >
            Với lối tắt, bạn có thể nhanh chóng truy cập vào những việc mình hay
            làm nhất trên Emso. Tuy lối tắt được sắp xếp theo thứ tự của bạn
            nhưng bạn có thể điều chỉnh lối tắt bất kỳ lúc nào.
          </Typography>

          <Box style={{ marginBottom: 10 }}>
            <InputSearch
              keyword={keyword}
              setKeyword={setKeyword}
              label="Tìm kiếm Trang, nhóm,..."
            />
          </Box>

          <List className={classes.list}>
            {filteredShortcuts.map((shortcut, index) =>
              renderShortcutItem(shortcut, index)
            )}
          </List>
        </DialogContent>

        <DialogActions>
          <ButtonInherit action={handleClose} label="Huỷ" />
          <ButtonInherit
            loading={isLoading}
            action={() => {
              handleSaveShortcuts();
              setIsLoading(true);
            }}
            label={isLoading ? '' : 'Lưu'}
            style={{
              backgroundColor: buttonColor.backgroundColor,
              color: theme.palette.mode === 'light' ? '#e7f3ff' : '#e4e6eb'
            }}
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

// DialogShortcut.propTypes = {
//   open: PropTypes.bool.isRequired,
//   setOpenDialog: PropTypes.func.isRequired,
//   handleClickOpen: PropTypes.func.isRequired,
//   handleClose: PropTypes.func.isRequired,
//   shortcutList: PropTypes.array.isRequired
// };

export default DialogShortcut;
