import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery
} from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import moment from 'moment';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getListGroupApi } from 'src/apis/socialGroup';
import { searchGroupName, searchPostInGroup } from 'src/apis/socialPost.api';
import group_cover from 'src/assets/images/group_cover.png';
import ButtonCustom from 'src/components/Button/Button';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import ButtonOptions from 'src/components/Button/ButtonOptions';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import DialogPauseGroup from 'src/components/Dialog/DialogPauseGroup';
import TextFieldCustom from 'src/components/Input/SocialInput/InputSearch';
import BaseLink from 'src/components/Link/BaseLink';
import ListButton from 'src/components/List/ListButton';
import Loading from 'src/components/Loading/Loading';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { PATHS } from 'src/constants/paths';
import { buttonColor } from 'src/constants/styles';
import DialogCreateSocialChat from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogCreateSocialChat';
import Conversation from 'src/pages/Chat/GlobalPopupChat/Conversation';
import {
  getActivityLogReq,
  getCensorshipReq,
  getMemberQuestionReq,
  getReportPostReq,
  getRuleGroupReq,
  getSchedulePostReq,
  getSearchPostInGroupAll,
  getWaitingMemberReq,
  getWaitingPostReq,
  loadingSearchGroup,
  searchGroup
} from 'src/store/action/socialGroupAction';
import GroupCreateUpdate from '../GroupCreateUpdate';
const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      root: {
        height: 'calc(100% - 50px)',
        width: 310,
        position: 'fixed',
        top: 50,
        marginBottom: 20,
        boxShadow: '1px 0px 5px 0.4px rgb(0 0 0 / 10%)'
      },
      groupSearch: {
        height: 100,
        marginBottom: 20,
        padding: '5px 10px 0px 0px',
        position: 'relative'
      },
      rootGroup: {
        width: 'calc(100% - 1px)',
        padding: '5px 10px 0px 10px',
        height: 'calc(100% - 100px)',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: theme.palette.mode === 'light' ? '#f1f1f1' : '#2d2d2d',
          borderRadius: 100
        },
        '&::-webkit-scrollbar-thumb': {
          background: theme.palette.mode === 'light' ? '#bdbdbd' : '#555555',
          borderRadius: 100
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundImage: 'linear-gradient(to bottom,#ffa400,#00aefd)'
        }
      },
      inputSearch: {
        width: '100%'
      },
      cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
          borderColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        '& fieldset': {
          display: 'none'
        },
        '&:hover': {
          backgroundColor:
            theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C',
        borderRadius: '30px !important',
        height: 35,
        padding: '7px !important',
        margin: 'auto'
      },
      notchedOutline: {
        border: `1px solid ${theme.palette.myBackgroundColor?.main} !important`
      },
      multilineColor: {
        fontSize: 13
      },
      avatar: {
        width: '56px !important',
        height: '56px !important',
        borderRadius: 10
      },
      list: {
        width: '100%'
      },
      textPrimary: {
        fontSize: '15px !important',
        fontWeight: '500 !important',
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        wordBreak: 'break-word'
      },
      textSetting: {
        fontSize: '17px !important',
        fontWeight: '500 !important',
        margin: '8px 3px 0px !important'
      },
      divider: {
        width: 290
      },
      wrapIconCount: {
        height: 26,
        width: 26,
        borderRadius: '50%',
        backgroundColor: buttonColor.backgroundColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      SearchGroupResult: {
        position: 'relative',
        padding: '10px 0 0 10px',
        width: '100%',
        backgroundColor: theme.palette.mode === 'dark' ? '#242526' : '#fff',
        zIndex: 10,
        borderRadius: '0px 0px 7px 7px',
        boxShadow:
          theme.palette.mode === 'dark'
            ? '-2px 6px 5px #3a3a3a'
            : '0px 6px 5px #CCCCCC'
      }
    }),
  { index: 1 }
);

const toDay = moment(new Date()).format('DD/MM/YYY');

const Index = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme: any = useTheme();
  const matchCreate = useMediaQuery('(max-width:1000px)');
  const match: any = useRouteMatch();
  const [keyParams, setKeyParams] = React.useState<any>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<any>('feed');
  const [selectedFilterSearch, setSelectedFilterSearch] =
    React.useState<any>('group_all');
  const [groupsAdmin, setGroupsAdmin] = React.useState<any>([]);
  const [groupsParticipate, setGroupsParticipate] = React.useState<any>([]);
  const [groupsAdminRender, setGroupsAdminRender] = React.useState<any>([]);
  const [isLoadingGroup, setIsLoadingGroup] = React.useState(false);
  const [openDialogPauseGroup, setOpenDialogPauseGroup] = React.useState(false);
  const [keyword, setKeyword] = React.useState<string>('');
  const meInfo = useSelector((state: any) => state.meReducer.info) || {};
  const group = useSelector((state: any) => state?.groupReducer?.groupSelected);
  const [isLoading, setIsLoading] = React.useState<any>(true);
  const [actionPostRecent, setActionPostRecent] = React.useState<any>(false);
  const [openDialogCreateSocialChat, setOpenDialogCreateSocialChat] =
    React.useState<boolean>(false);
  const [checkEnterSearch, setCheckEnterSearch] =
    React.useState<boolean>(false);
  const [
    filterSearchPostInGroupCreatedAt,
    setFilterSearchPostInGroupCreatedAt
  ] = React.useState<any>({ label: 'Ngày đăng', id: null });
  const listWaitingMember = useSelector(
    (state: any) => state?.groupReducer?.waitingMember
  );
  const { waitingPost } = useSelector((state: any) => state?.groupReducer);

  const listCensorShipReport = useSelector(
    (state: any) => state?.groupReducer?.censorShipReports
  );
  const divSearchGroupRef = useRef<any>(null);
  const listReportPost = useSelector(
    (state: any) => state?.groupReducer?.reportPosts
  );
  const listSchedulePost = useSelector(
    (state: any) => state?.groupReducer?.schedulePosts
  );
  const [listRecommendSearch, setListRecommendSearch] = React.useState<any>([]);
  const [listSwitch, setListSwitch] = React.useState<any>([
    { id: 'public_group', checked: false },
    { id: 'your_group', checked: false },
    { id: 'post_recent', checked: false }
  ]);
  const yearCurrent = new Date().getFullYear();
  const [yearSelect, setYearSelect] = React.useState<any>(null);
  const [openPopup, setOpenPopup] = React.useState(false);

  const listFilterSearchPostInGroup = [
    {
      id: 'created_at',
      label: filterSearchPostInGroupCreatedAt.label,
      type: 'select',
      sub_type: 'time',
      options: [
        {
          id: yearCurrent.toString(),
          label: yearCurrent.toString(),
          action: () => {
            handleSearchAllPostInGroup('created_at', yearCurrent);
            dispatch(loadingSearchGroup('loading'));
          }
        },
        {
          id: (yearCurrent - 1).toString(),
          label: (yearCurrent - 1).toString(),
          action: () => {
            handleSearchAllPostInGroup('created_at', yearCurrent - 1);
            dispatch(loadingSearchGroup('loading'));
          }
        },
        {
          id: (yearCurrent - 2).toString(),
          label: (yearCurrent - 2).toString(),
          action: () => {
            handleSearchAllPostInGroup('created_at', yearCurrent - 2);
            dispatch(loadingSearchGroup('loading'));
          }
        },
        {
          id: (yearCurrent - 3).toString(),
          label: (yearCurrent - 3).toString(),
          action: () => {
            handleSearchAllPostInGroup('created_at', yearCurrent - 3);
            dispatch(loadingSearchGroup('loading'));
          }
        },
        {
          id: (yearCurrent - 4).toString(),
          label: (yearCurrent - 4).toString(),
          action: () => {
            handleSearchAllPostInGroup('created_at', yearCurrent - 4);
            dispatch(loadingSearchGroup('loading'));
          }
        }
      ]
    }
  ];
  const handleResetFilterSearchGroup = () => {
    setFilterSearchPostInGroupCreatedAt({ label: 'Ngày đăng', id: null });
    setYearSelect(null);
    handleSearchAllPostInGroup('reset_year', null);
    dispatch(loadingSearchGroup('loading'));
  };

  React.useEffect(() => {
    if (Object.keys(group).length && match.path !== PATHS.GROUP_SEARCH) {
      dispatch(getWaitingPostReq(group.id, {}));
      dispatch(getWaitingMemberReq(group.id, {}));
      dispatch(getReportPostReq(group.id, {}));
      dispatch(getRuleGroupReq(group.id));
      dispatch(getSchedulePostReq(group.id));
      dispatch(getMemberQuestionReq('get', group.id));
      dispatch(getCensorshipReq(group.id));
      dispatch(getActivityLogReq(group.id, { page: 1 }));
    }
  }, [group.id]);

  React.useEffect(() => {
    if (actionPostRecent) {
      let switchRecent = listSwitch.find(item => item.id === 'post_recent');
      if (switchRecent.checked) {
        handleSearchAllPostInGroup('post_recent', null);
      } else {
        hadleSearchPostGroup();
      }
    }
  }, [JSON.stringify(listSwitch)]);

  const renderListGroups = groups => {
    if (!groups.length) {
      return (
        <Typography style={{ margin: 10 }} variant="subtitle2">
          Không có nhóm nào
        </Typography>
      );
    }

    return (
      <List className={classes.list} component="nav" aria-label="sidebar">
        {groups?.map((item: any, index) => (
          <BaseLink
            key={index}
            url={item?.url ? item?.url : null}
            component={
              <ListItem
                sx={{
                  borderRadius: '10px',
                  maxHeight: item.icon ? '58px' : '72px',
                  padding: '6px',
                  backgroundColor:
                    selectedIndex === item?.id
                      ? 'button.secondary.background'
                      : ''
                }}
                button
                onClick={() =>
                  item?.action ? item?.action() : handleGroupDetail(item)
                }
                key={item?.id}
                secondaryAction={
                  item.count ? (
                    <Box className={classes.wrapIconCount}>
                      <Typography
                        sx={{
                          color: '#fff',
                          fontWeight: 600,
                          fontSize: 14
                        }}
                      >
                        {item.count}
                      </Typography>
                    </Box>
                  ) : null
                }
              >
                {item?.icon && (
                  <ListItemIcon sx={{ minWidth: 24 }}>
                    <i
                      style={{
                        margin: '0px 3px',
                        color: theme.palette.mode === 'dark' ? '#fff' : '#000'
                      }}
                      className={`${item.icon} fa-lg`}
                    ></i>
                  </ListItemIcon>
                )}

                {item?.cover_image_url && (
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <Avatar
                      variant="rounded"
                      className={classes.avatar}
                      src={item.banner ? item?.banner?.url : group_cover}
                    ></Avatar>
                  </ListItemIcon>
                )}
                <ListItemText
                  style={{ marginLeft: 10 }}
                  primary={
                    <Typography className={classes.textPrimary}>
                      {item?.title}
                    </Typography>
                  }
                  secondary={
                    item?.member_count ? (
                      `${item?.member_count} thành viên`
                    ) : item.subTitle ? (
                      <Typography style={{ fontSize: 13, color: '#65676b' }}>
                        {item.subTitle}
                      </Typography>
                    ) : null
                  }
                />
              </ListItem>
            }
          />
        ))}
      </List>
    );
  };
  const listMenuGroups = [
    {
      id: 'feed',
      title: 'Bảng tin của bạn',
      icon: 'fa-light fa-oven',
      action: () => {
        history.push(PATHS.GROUP);
      }
    },
    {
      id: 'discover',
      title: 'Khám phá',
      icon: 'fa-light fa-circle-location-arrow',
      action: () => {
        history.push(PATHS.GROUP_DISCOVER);
      }
    },
    {
      id: 'request',
      title: 'Mời tham gia nhóm',
      icon: 'fa-light fa-calendar-circle-user',
      action: () => {
        history.push(PATHS.GROUP_REQUEST);
      }
    }
  ];
  const handleSearchAllPostInGroup = async (type: string, createYear: any) => {
    let params: any;
    let res: any;
    if (type === 'post_recent') {
      params = {
        sort_by: 'most_recent',
        created_year: yearSelect ? yearSelect : null
      };
      res = await searchPostInGroup(keyword, params);
    }
    if (type === 'created_at') {
      params = {
        sort_by: listSwitch.find(item => item?.id === 'post_recent').checked
          ? 'most_recent'
          : null,
        created_year: createYear
      };
      res = await searchPostInGroup(keyword, params);
    }
    if (type === 'reset_year') {
      params = {
        sort_by: listSwitch.find(item => item?.id === 'post_recent').checked
          ? 'most_recent'
          : null,
        created_year: createYear === null ? createYear : yearSelect
      };
      res = await searchPostInGroup(keyword, params);
    }
    if (res.status === 200) {
      dispatch(getSearchPostInGroupAll(res.data.statuses));
      dispatch(loadingSearchGroup('success'));
      setActionPostRecent(false);
      if (createYear) {
        setFilterSearchPostInGroupCreatedAt({
          label: createYear.toString(),
          id: createYear.toString()
        });
        setYearSelect(createYear);
      }
    }
  };

  const hadleSearchPostGroup = async () => {
    let res = await searchPostInGroup(keyword, {
      created_year: yearSelect ? yearSelect : null
    });
    if (res.status === 200) {
      dispatch(getSearchPostInGroupAll(res.data.statuses));
      dispatch(loadingSearchGroup('success'));
    }
  };

  const listMenuSearchFilter = [
    {
      id: 'group_all',
      title: 'Tất cả',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i className="fa-solid fa-newspaper" style={{ fontSize: '20px' }}></i>
      ),
      action: () => {
        setSelectedFilterSearch('group_all');
      },
      style: { height: 36, width: 36 },
      position: 'top'
    },
    {
      id: 'group',
      title: 'Nhóm',
      styleTitle: { fontWeight: '600' },
      icon: <i className="fa-solid fa-users" style={{ fontSize: '20px' }}></i>,
      action: () => {
        setSelectedFilterSearch('group');
        dispatch(searchGroup(listRecommendSearch, keyword, 'groups'));
        dispatch(getSearchPostInGroupAll([]));
      },
      accordion: true,
      style: { height: 36, width: 36 },
      position: 'top',
      itemChildren: [
        {
          id: 'public_group',
          title: 'Nhóm công khai',
          styleTitle: { fontWeight: '400' },
          action: () => {
            handleSearchFilterGroup('public_group');
            dispatch(loadingSearchGroup('loading'));
          },
          switch: true
        },
        {
          id: 'your_group',
          title: 'Nhóm của tôi',
          styleTitle: { fontWeight: '400' },
          action: () => {
            handleSearchFilterGroup('your_group');
            dispatch(loadingSearchGroup('loading'));
          },
          switch: true
        }
      ]
    },
    {
      id: 'post_group',
      title: 'Bài viết nhóm',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i className="fa-solid fa-comments" style={{ fontSize: '20px' }}></i>
      ),
      action: async () => {
        setSelectedFilterSearch('post_group');
        setListSwitch([
          { id: 'public_group', checked: false },
          { id: 'your_group', checked: false },
          { id: 'post_recent', checked: false }
        ]);
        dispatch(searchGroup([], keyword, 'groups'));
        dispatch(loadingSearchGroup('loading'));
        hadleSearchPostGroup();
      },
      accordion: true,
      style: {
        height: 36,
        width: 36
      },
      position: 'top',
      itemChildren: [
        {
          id: 'post_recent',
          title: 'Bài viết gần đây',
          styleTitle: { fontWeight: '400' },
          action: () => {
            setActionPostRecent(true);
            dispatch(loadingSearchGroup('loading'));
          },
          switch: true
        },
        {
          id: 'create_day',
          title: (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end'
              }}
            >
              {filterSearchPostInGroupCreatedAt.id && (
                <ButtonInherit
                  action={handleResetFilterSearchGroup}
                  label={
                    <Typography
                      fontSize="13px"
                      fontWeight="400"
                      color={buttonColor.backgroundColor}
                    >
                      Đặt lại
                    </Typography>
                  }
                  style={{
                    width: '30px',
                    height: '25px',
                    backgroundColor: 'background.primary',
                    '&:hover': {
                      backgroundColor: 'button.primary.background'
                    }
                  }}
                />
              )}
              {listFilterSearchPostInGroup.map(
                el =>
                  el?.sub_type === 'time' && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                      }}
                    >
                      <ButtonOptions
                        options={el.options}
                        endIcon={
                          <ArrowDropDownIcon style={{ fontSize: '25px' }} />
                        }
                        width="249px"
                        color="white"
                        label={el.label}
                        style={{
                          backgroundColor:
                            theme.palette.mode === 'light' ? '#fff' : '#242526',
                          height: '35px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          '&:hover': {
                            backgroundColor:
                              theme.palette.mode === 'light'
                                ? '#F5F5F5'
                                : '#353637'
                          }
                        }}
                      />
                    </div>
                  )
              )}
            </div>
          ),
          styleTitle: {
            fontWeight: '400',
            height: '230px'
          },
          action: () => {}
        }
      ]
    }
  ];
  const listMenuAdmin = [
    {
      id: 'home',
      title: 'Trang chủ',
      icon: 'fa-light fa-house-chimney',
      action: () => {
        history.push(`/group/${group.id}`);
      }
    },
    // {
    //   id: 'chat_social',
    //   title: 'Chat cộng đồng',
    //   subTitle: `4 đoạn chat gợi ý`,
    //   // count: listWaitingMember.length,
    //   icon: 'fa-light fa-messages',
    //   action: () => {
    //     setOpenDialogCreateSocialChat(true);
    //   }
    // },
    {
      id: 'request_member',
      title: 'Yêu cầu làm thành viên',
      subTitle: `${
        listWaitingMember.filter(
          el => moment(el.created_at).format('DD/MM/YYY') === toDay
        ).length
      } yêu cầu mới hôm nay`,
      count: listWaitingMember.length,
      icon: 'fa-light fa-user-plus',
      action: () => {
        history.push(`/group/${group?.username ?? group.id}/request_member`);
      }
    },
    {
      id: 'waiting_post',
      title: 'Bài viết đang chờ',
      subTitle: `${
        waitingPost?.data?.filter(
          el => moment(el.created_at).format('DD/MM/YYY') === toDay
        ).length ?? 0
      } bài mới hôm nay`,
      count: waitingPost?.meta?.total,
      icon: 'fa-light fa-calendar-clock',
      action: () => {
        history.push(`/group/${group?.username ?? group.id}/waiting_post`);
      }
    },
    {
      id: 'schedule_post',
      title: 'Bài viết đã lên lịch',
      icon: 'fa-light fa-calendar-lines-pen',
      count: listSchedulePost.length,
      action: () => {
        history.push(`/group/${group?.username ?? group.id}/schedule_post`);
      }
    },
    {
      id: 'activity_log',
      title: 'Nhật ký hoạt động',
      icon: 'fa-light fa-clock',
      action: () => {
        history.push(`/group/${group?.username ?? group.id}/activity_log`);
      }
    },
    {
      id: 'rule_group',
      title: 'Quy tắc nhóm',
      icon: 'fa-light fa-memo-circle-info',
      action: () => {
        history.push(`/group/${group?.username ?? group.id}/rule_group`);
      }
    },
    {
      id: 'content_report',
      title: 'Nội dung thành viên báo cáo',
      subTitle: `${
        listReportPost.filter(
          el => moment(el.created_at).format('DD/MM/YYY') === toDay
        ).length
      } tin mới hôm nay`,
      count: listReportPost.length,
      icon: 'fa-light fa-square-info',
      action: () => {
        history.push(`/group/${group?.username ?? group.id}/content_report`);
      }
    },

    {
      id: 'report_censorship',
      title: 'Thông báo kiểm duyệt',
      subTitle: `${
        listCensorShipReport.filter(
          el => moment(el.created_at).format('DD/MM/YYY') == toDay
        ).length
      } thông báo mới hôm nay`,
      count: listCensorShipReport.length,
      icon: 'fa-light fa-clipboard-check',
      action: () => {
        history.push(`/group/${group?.username ?? group.id}/report_censorship`);
      }
    },

    {
      id: 'member_question',
      title: 'Câu hỏi chọn thành viên',
      icon: 'fa-light fa-comment-question',
      action: () => {
        history.push(`/group/${group?.username ?? group.id}/member_question`);
      }
    }
  ];

  const listSettingAdmin = [
    {
      id: 'setting_group',
      title: 'Cài đặt nhóm',
      icon: 'fa-light fa-gear',
      action: () => {
        history.push(`/group/${group?.username ?? group.id}/setting_group`);
      },
      hidden: group.inactivate_reason ? true : false
    }
  ];

  React.useEffect(() => {
    switch (match.path) {
      case PATHS.GROUP:
        setSelectedIndex('feed');
        break;
      case PATHS.GROUP_DISCOVER:
        setSelectedIndex('discover');
        break;
      case PATHS.GROUP_CATEGORY:
        setSelectedIndex('category');
        break;
      case PATHS.GROUP_REQUEST:
        setSelectedIndex('request');
        break;
      case PATHS.GROUP_DETAIL: {
        let { id, key } = match?.params;
        setKeyParams('key');
        if (id) {
          setSelectedIndex(id);
        }
        if (key) {
          setSelectedIndex(key);
        }
        break;
      }
    }
  }, [match.path, JSON.stringify(match.params)]);
  const fetchGroup = async (params, setStateFunction) => {
    let response = await getListGroupApi(params);
    if (response.status === 200) {
      setStateFunction(response.data);
      if (params.tab === 'admin') {
        setGroupsAdminRender([...response.data.slice(0, 3)]);
        setIsLoadingGroup(false);
      }
    }
  };
  const handleSearchFilterGroup = async (type: string) => {
    let params: any;
    let res: any;
    if (type === 'public_group') {
      params = {
        public_group: !listSwitch.find(item => item?.id === type).checked
          ? true
          : null,
        your_group: listSwitch.find(item => item?.id === 'your_group').checked
          ? true
          : null
      };
      res = await searchGroupName(keyword, params);
    }
    if (type === 'your_group') {
      params = {
        public_group: listSwitch.find(item => item?.id === 'public_group')
          .checked
          ? true
          : null,
        your_group: !listSwitch.find(item => item?.id === type).checked
          ? true
          : null
      };
      res = await searchGroupName(keyword, params);
    }
    if (res.status === 200) {
      dispatch(searchGroup(res.data.groups, keyword, 'groups'));
      dispatch(loadingSearchGroup('success'));
    }
  };
  const handleGroupDetail = item => {
    history.push(`/group/${item.username ?? item.id}`);
  };

  const handleSearch = React.useCallback(
    _.debounce(async keyword => {
      let response = await searchGroupName(keyword, null);
      if (response.status === 200) {
        setListRecommendSearch(response.data.groups);
        dispatch(searchGroup(response.data.groups, keyword, 'groups'));
        setIsLoading(false);
        dispatch(loadingSearchGroup('success'));
      }
    }, 800),
    []
  );
  React.useEffect(() => {
    if (keyword) {
      handleSearch(keyword);
      setIsLoading(true);
      dispatch(loadingSearchGroup('loading'));
    }
  }, [keyword]);

  React.useEffect(() => {
    setIsLoadingGroup(true);
    fetchGroup({ tab: 'admin', limit: 50 }, setGroupsAdmin);
    fetchGroup({ tab: 'member', limit: 50 }, setGroupsParticipate);
  }, []);

  const renderHeader = () => {
    switch (match.path) {
      case PATHS.GROUP_DETAIL:
      case PATHS.GROUP_POST_DETAIL:
        return (
          <Grid container>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 'bold', margin: '12px 0px 0px' }}
                variant="h5"
              >
                Quản lý nhóm
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {/* {renderListGroups([group])} */}
              <div
                style={{
                  margin: '8px 0px'
                }}
              >
                <IconButtonOptions
                  name={group.title}
                  styleText={{
                    width: '80%',
                    wordBreak: 'break-word'
                  }}
                  styleNameButton={{
                    color: 'text.primary'
                  }}
                  avatar={group.banner?.url ?? group_cover}
                  icon={
                    <i
                      style={{
                        fontSize: '16px'
                      }}
                      className="fa-solid fa-caret-down"
                    ></i>
                  }
                  stylePopup={{ width: '290px', height: '250px' }}
                  openPopup={true}
                  style={{
                    margin: '0px 0px',
                    width: '100%',
                    minHeight: '60px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: 'background.primary'
                  }}
                  options={[
                    groupsAdmin.map((el: any) => ({
                      ...el,
                      title: null,
                      label: el.title,
                      checkbox: true,
                      avatar_icon: el.banner?.url ?? group_cover,
                      action: () => {
                        history.push(`/group/${el?.username ?? el?.id}`);
                      }
                    }))
                  ]}
                  styleListMenu={{ minHeight: '250px' }}
                />
              </div>
            </Grid>
          </Grid>
        );

      case PATHS.GROUP_CREATE: {
        return (
          <Grid container>
            <Grid container item alignItems="center" sx={{ marginTop: '12px' }}>
              <IconButton
                sx={{
                  backgroundColor: '#999999',
                  color: '#fff',
                  height: 35,
                  width: 35
                }}
                onClick={() => history.push(PATHS.GROUP)}
              >
                <ClearIcon />
              </IconButton>
              &nbsp;
              <Typography
                style={{ fontWeight: 'bold', marginLeft: '12px' }}
                variant="h5"
              >
                Tạo nhóm
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ListItem
                sx={{
                  paddingLeft: 0
                }}
              >
                <ListItemAvatar>
                  <AvatarSocial
                    type="feed"
                    style={{
                      width: 40,
                      height: 40,
                      margin: '0px 8px 0px 3px'
                    }}
                    avatarObj={meInfo.avatar_media}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <span style={{ fontSize: 16, fontWeight: 600 }}>
                      {meInfo?.display_name}
                    </span>
                  }
                  secondary={
                    <span style={{ fontSize: 14 }}>Quản trị viên</span>
                  }
                />
              </ListItem>
            </Grid>
          </Grid>
        );
      }

      case PATHS.GROUP_SEARCH: {
        return (
          <Grid container>
            <Typography
              variant="h5"
              style={{ fontWeight: 'bold', marginBottom: 20 }}
            >
              Nhóm
            </Typography>
            <Grid item xs={12}>
              <TextFieldCustom
                label="Tìm kiếm nhóm..."
                keyword={keyword}
                setKeyword={setKeyword}
                actionBlur={() => {
                  if (divSearchGroupRef.current) {
                    divSearchGroupRef.current.style.display = 'none';
                  }
                }}
                actionFocus={() => {
                  if (divSearchGroupRef.current) {
                    divSearchGroupRef.current.style.display = 'block';
                  }
                }}
                action={() => {
                  setListSwitch([
                    { id: 'public_group', checked: false },
                    { id: 'your_group', checked: false },
                    { id: 'post_recent', checked: false }
                  ]);
                  setSelectedFilterSearch('group_all');
                }}
              />
            </Grid>
          </Grid>
        );
      }

      default:
        return (
          <Grid container spacing={2}>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
              sx={{ margin: '12px 0px 0px' }}
            >
              <Typography style={{ fontWeight: 'bold' }} variant="h5">
                Nhóm
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextFieldCustom
                label="Tìm kiếm..."
                keyword={keyword}
                setKeyword={setKeyword}
                action={() => {
                  setCheckEnterSearch(true);
                  history.push(PATHS.GROUP_SEARCH);
                  setOpenPopup(false);
                }}
                actionBlur={() => {
                  if (divSearchGroupRef.current) {
                    divSearchGroupRef.current.style.display = 'none';
                  }
                }}
                actionFocus={() => {
                  if (divSearchGroupRef.current) {
                    divSearchGroupRef.current.style.display = 'block';
                  }
                  setOpenPopup(true);
                }}
              />
            </Grid>
          </Grid>
        );
    }
  };

  const renderListGroupAdmin = () => {
    return (
      <>
        <Typography variant="h6">Nhóm bạn quản lý</Typography>
        {isLoadingGroup ? (
          <Loading style={{ margin: '10px 0px' }} />
        ) : (
          renderListGroups(groupsAdminRender)
        )}
        {groupsAdminRender.length !== groupsAdmin.length ? (
          <ButtonInherit
            style={{ margin: '0px 4px 8px 4px', width: '275px' }}
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: 500 }}>Xem thêm</Typography>
                &nbsp;
                <i className="fa-solid fa-angle-down"></i>
              </div>
            }
            action={() => {
              setGroupsAdminRender(groupsAdmin);
            }}
          />
        ) : null}
      </>
    );
  };

  const renderListGroupMember = () => {
    return (
      <>
        <Typography variant="h6">Nhóm bạn tham gia</Typography>
        {isLoadingGroup ? <Loading /> : renderListGroups(groupsParticipate)}
      </>
    );
  };

  const renderFormCreateGroup = () => {
    return (
      <>
        <Typography variant="h6">Thông tin nhóm mới</Typography>
        <GroupCreateUpdate />
      </>
    );
  };

  const renderBody = () => {
    switch (match.path) {
      case PATHS.GROUP_DETAIL:
      case PATHS.GROUP_POST_DETAIL:
        return {
          bodyTop: (
            <>
              <Typography
                className={classes.textSetting}
                sx={{ color: 'text.secondary' }}
              >
                Công cụ quản trị
              </Typography>
              {renderListGroups(listMenuAdmin)}
            </>
          ),
          bodyCenter: (
            <>
              {group.inactivate_reason ||
              group?.group_relationship?.moderator ? null : (
                <>
                  <Typography
                    className={classes.textSetting}
                    sx={{ color: 'text.secondary' }}
                  >
                    Cài đặt nhóm
                  </Typography>
                  {renderListGroups(listSettingAdmin)}
                </>
              )}
            </>
          ),
          bodyFooter: (
            <>
              {group.inactivate_reason ||
              group?.group_relationship?.moderator ? null : (
                <div
                  style={{
                    padding: '0px 5px',
                    marginBottom: '30px',
                    width: 290
                  }}
                >
                  <div style={{ height: 10 }}></div>
                  <ButtonCustom
                    label="Tạm dừng nhóm"
                    action={() => {
                      setOpenDialogPauseGroup(true);
                    }}
                    icon={null}
                  />
                </div>
              )}
            </>
          )
        };

      case PATHS.GROUP_CREATE: {
        return {
          bodyTop: null,
          bodyCenter: renderFormCreateGroup(),
          bodyFooter: null
        };
      }
      case PATHS.GROUP_SEARCH: {
        return {
          bodySearchFilterGroup: (
            <>
              <Typography
                variant="h6"
                sx={{ fontSize: '17px', marginLeft: '10px' }}
              >
                Bộ lọc
              </Typography>
              <div style={{ width: '100%' }}>
                <ListButton
                  item={listMenuSearchFilter.filter(
                    el => el.position === 'top'
                  )}
                  selectedItem={selectedFilterSearch}
                  width={'280px'}
                  styleAccordionDetail={{ padding: '8px 5px 5px' }}
                  stylePadding={{
                    padding: '0px !important',
                    margin: '6px 0'
                  }}
                  listSwitch={listSwitch}
                  setListSwitch={setListSwitch}
                  styleMargin={{ margin: '0px 5px 0px 0px !important' }}
                  typeAction={'action'}
                />
              </div>
            </>
          )
        };
      }

      default:
        return {
          bodyTop: (
            <>
              {renderListGroups(listMenuGroups)}
              <div style={{ width: 275, margin: '0px 4px' }}>
                <ButtonCustom
                  label="Tạo nhóm mới"
                  solid={true}
                  style={{
                    color: '#e7f3ff',
                    backgroundColor: buttonColor.backgroundColor
                  }}
                  action={() => {
                    history.push(PATHS.GROUP_CREATE);
                  }}
                  icon={<i className="far fa-plus"></i>}
                />
              </div>
            </>
          ),
          bodyCenter: renderListGroupAdmin(),
          bodyFooter: renderListGroupMember()
        };
    }
  };

  return (
    <Box
      sx={{
        width:
          match.path === PATHS.GROUP_CREATE && matchCreate
            ? '100%'
            : match.path === PATHS.GROUP_CREATE
            ? 360
            : 'undefined',
        backgroundColor: 'background.primary'
      }}
      className={classes.root}
    >
      <div className={classes.groupSearch}>
        <div style={{ paddingLeft: '10px' }}>{renderHeader()}</div>
        {keyword.length > 0 && openPopup ? (
          <Box className={classes.SearchGroupResult}>
            {isLoading ? (
              <Loading style={{ marginTop: '10px', paddingBottom: '10px' }} />
            ) : listRecommendSearch.length ? (
              listRecommendSearch.map((el, index) => {
                return (
                  <Box
                    key={index}
                    sx={{ width: '100%', height: 'auto' }}
                    onClick={() => {
                      dispatch(
                        searchGroup(listRecommendSearch, keyword, 'groups')
                      );
                      setOpenPopup(false);
                    }}
                  >
                    <Conversation type="groups" conversation={el} key={index} />
                  </Box>
                );
              })
            ) : (
              <div style={{ textAlign: 'center', padding: '10px 0px' }}>
                Không tìm thấy nhóm.
              </div>
            )}
          </Box>
        ) : null}
      </div>
      {match.path !== PATHS.GROUP_SEARCH ? (
        <div className={classes.rootGroup}>
          {match.path !== PATHS.GROUP_CREATE && (
            <Divider className={classes.divider} />
          )}
          {renderBody().bodyTop}
          {match.path !== PATHS.GROUP_CREATE && (
            <div style={{ height: 10 }}></div>
          )}
          {match.path !== PATHS.GROUP_SEARCH ||
            (match.path !== PATHS.GROUP_CREATE && (
              <Divider className={classes.divider} />
            ))}
          {renderBody().bodyCenter}
          {match.path !== PATHS.GROUP_SEARCH && (
            <Divider className={classes.divider} />
          )}
          {renderBody().bodyFooter}
        </div>
      ) : checkEnterSearch || match.path === PATHS.GROUP_SEARCH ? (
        renderBody().bodySearchFilterGroup
      ) : null}

      <DialogPauseGroup
        open={openDialogPauseGroup}
        setOpen={setOpenDialogPauseGroup}
      />
      {openDialogCreateSocialChat ? (
        <DialogCreateSocialChat
          open={openDialogCreateSocialChat}
          setOpen={setOpenDialogCreateSocialChat}
          group={group}
        />
      ) : null}
    </Box>
  );
};

export default Index;
