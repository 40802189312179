import axios, { AxiosRequestConfig } from 'axios';
import { cloneDeep } from 'lodash';
import { USER_TOKEN } from 'src/util/config';
import { BASE_ROOT } from 'src/util/config';

const fetchApi = async (endPoint, method, params, formdata) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: formdata
  };

  let response = await axios(configs);
  return response;
};
const fetchApiRequest = async (endPoint, method, params, _data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

export const createPageApi = async (data: any) => {
  var formdata = new FormData();
  formdata.append('title', data.title);
  formdata.append('description', data.description);
  formdata.append('page_type', data?.page_type?.id);
  formdata.append('page_purpose', data.page_purpose?.id);
  for (var i = 0; i < data.page_category_ids.length; i++) {
    formdata.append('page_category_ids[]', data.page_category_ids[i]);
  }
  if (data.avatar) {
    if (data.avatar.file) {
      formdata.append('avatar[file]', data.avatar.file);
    }
    if (data.avatar.id) {
      formdata.append('avatar[id]', data.avatar.id);
    }
    if (data.avatar?.show_url) {
      formdata.append('avatar[show_url]', data.avatar.show_url);
    }
    formdata.append('avatar[status]', data.avatar.status);
  }
  if (data.banner) {
    if (data.banner.file) {
      formdata.append('banner[file]', data.banner.file);
    }
    if (data.banner.id) {
      formdata.append('banner[id]', data.banner.id);
    }
    if (data.banner.show_url) {
      formdata.append('banner[show_url]', data.banner.show_url);
    }
  }

  return fetchApi('pages', 'POST', null, formdata);
};

export const getPageCategoriesApi = (params: Object) => {
  return fetchApi('page_categories', 'GET', params, null);
};

export const getListPagesApi = (params: any) => {
  return fetchApi('pages', 'GET', params, null);
};

export const getDetailPageApi = async (pageId: any) => {
  return fetchApi(`pages/${pageId}`, 'GET', null, null);
};

export const updatePageApi = async (id: any, data: any) => {
  var formdata = new FormData();
  let params: any = null;
  if (data.avatar) {
    if (data.avatar.file) {
      formdata.append('avatar[file]', data.avatar.file);
    }
    if (data.avatar.id) {
      formdata.append('avatar[id]', data.avatar.id);
    }
    if (data.avatar?.frame_id)
      formdata.append('avatar[frame_id]', data.avatar.frame_id ?? '');
    if (data.avatar?.show_url)
      formdata.append('avatar[show_url]', data.avatar.show_url);
    if (data.avatar?.status)
      formdata.append('avatar[status]', data.avatar.status);
  }
  if (data.banner) {
    if (data.banner.file) {
      formdata.append('banner[file]', data.banner.file);
    }
    if (data.banner.id) {
      formdata.append('banner[id]', data.banner.id);
    }
    formdata.append('banner[show_url]', data.banner.show_url);
  }
  if (data.title) {
    formdata.append('title', data.title);
  }
  if (data.username) {
    formdata.append('username', data.username);
  }
  if (data.description) {
    formdata.append('description', data.description);
  }
  if (data.page_category_ids) {
    for (var i = 0; i < data.page_category_ids.length; i++) {
      formdata.append('page_category_ids[]', data.page_category_ids[i]);
    }
  }
  if (data.email !== null && data.email !== undefined) {
    formdata.append('email', data.email);
  }
  if (data.phone_number !== null && data.phone_number !== undefined) {
    formdata.append('phone_number', data.phone_number);
  }
  if (data.website !== null && data.website !== undefined) {
    formdata.append('website', data.website);
  }
  if (data.impressum !== null && data.impressum !== undefined) {
    formdata.append('impressum', data.impressum);
  }
  if (data.products !== null && data.products !== undefined) {
    formdata.append('products', data.products);
  }
  if (data.policy_link !== null && data.policy_link !== undefined) {
    formdata.append('policy_link', data.policy_link);
  }
  if (
    data.addition_information !== null &&
    data.addition_information !== undefined
  ) {
    formdata.append('addition_information', data.addition_information);
  }
  if (data.button_key !== null && data.button_key !== undefined) {
    formdata.append('button_key', data.button_key);
  }
  if (data.button_value !== null && data.button_value !== undefined) {
    if (typeof data.button_value === 'object') {
      params = { button_value: data.button_value };
    } else {
      formdata.append('button_value', data.button_value);
    }
  }
  if (data.hours_type) {
    formdata.append('hours_type', data.hours_type);
  }
  if (data.temporary_hours_status) {
    formdata.append('temporary_hours_status', data.temporary_hours_status);
  }
  if (data.zip) {
    formdata.append('zip', data.zip);
  }

  if (data.address) {
    formdata.append('address', data.address);
  }
  if (data.location?.lat && data.location?.lng) {
    formdata.append('location[lat]', data.location.lat);
    formdata.append('location[lng]', data.location.lng);
  }

  let _data: any = null;
  if (data.province && data.district && data.ward) {
    let tempData: any = cloneDeep(data);
    _data = {
      ...tempData,
      province_id: data.province.id,
      district_id: data.district.id,
      ward_id: data.ward.id
    };
  }
  if (data.no_place) {
    let tempData: any = cloneDeep(_data);
    _data = {
      ...tempData,
      zip: null,
      province_id: null,
      district_id: null,
      ward_id: null,
      address: null,
      location: null
    };
  }
  if (data.schedule) {
    if (typeof data.schedule === 'object') {
      let tempData: any = cloneDeep(_data);
      _data = { ...tempData, schedule: data.schedule };
    }
  }

  if (_data) {
    return fetchApiRequest(`pages/${id}`, 'PATCH', params, {
      ...data,
      ..._data
    });
  }
  return fetchApi(`pages/${id}`, 'PATCH', params, formdata);
};

export const deletePageApi = async (id: any) => {
  return fetchApi(`pages/${id}`, 'DELETE', null, null);
};

export const lockPageApi = async (data: any) => {
  return fetchApi('lock_page', 'PATCH', null, data);
};

export const unlockPageApi = async (data: any) => {
  return fetchApi('unlock_page', 'PATCH', null, data);
};

export const getListInvitedPageApi = async (param: any) => {
  return fetchApi('page_invitation_follows', 'GET', param, null);
};

export const createInviteFollowsPage = async (idPage: any, data: any) => {
  return fetchApi(`pages/${idPage}/invitation_follows`, 'POST', null, data);
};

export const respondInviteFollowsPage = async (idPage: any, data: any) => {
  return fetchApi(
    `pages/${idPage}/invitation_follows/invitation_follows_respond`,
    'POST',
    data,
    null
  );
};

export const respondInviteManagePage = async (idPage: any, data: any) => {
  return fetchApi(
    `pages/${idPage}/invitations/invitations_respond`,
    'POST',
    data,
    null
  );
};

export const likeFollowPage = async (idPage: any, action: string) => {
  return fetchApi(`pages/${idPage}/${action}`, 'POST', null, null);
};

export const getPageManagementHistory = async (id: any, params: any) => {
  return fetchApi(`pages/${id}/management_histories`, 'GET', params, null);
};

export const inviteManagePage = async (idPage: any, params: any) => {
  return fetchApi(`pages/${idPage}/invitations`, 'POST', params, null);
};

export const getUserLikeFollowPage = async (
  idPage: any,
  params: any,
  action: any
) => {
  return fetchApi(`pages/${idPage}/${action}`, 'GET', params, null);
};

export const getListAdminPage = async (idPage: any) => {
  return fetchApi(`pages/${idPage}/invitations`, 'GET', null, null);
};

export const updateDeleteInviteManagePage = async (
  idPage: any,
  idUser: any,
  action: any
) => {
  return fetchApi(
    `pages/${idPage}/invitations/${idUser}`,
    action === 'delete' ? 'delete' : 'patch',
    null,
    null
  );
};

export const getSettingContentPageApi = async (idPage: any) => {
  return fetchApi(`pages/${idPage}/settings`, 'GET', null, null);
};

export const updateSettingContentPage = async (idPage: any, params: any) => {
  return fetchApiRequest(`pages/${idPage}/settings`, 'PATCH', null, params);
};

export const getSettingNotification = async (idPage: any) => {
  return fetchApi(`pages/${idPage}/setting_notifications`, 'GET', null, null);
};

export const updateSettingNotification = async (idPage: any, params: any) => {
  return fetchApi(
    `pages/${idPage}/setting_notifications`,
    'PATCH',
    params,
    null
  );
};

export const blockUserPage = async (idPage: any, params: any, action: any) => {
  return fetchApi(
    `pages/${idPage}/blocks`,
    action === 'blocks' ? 'post' : 'delete',
    params,
    null
  );
};

export const getListLikedFollowedPages = async (id: any, params: any) => {
  return fetchApi(`accounts/${id}/page_likes`, 'GET', params, null);
};

export const pageTimelineApi = async (id, params) => {
  return fetchApi(`timelines/page/${id}`, 'GET', params, null);
};

export const getMediaPage = async (pageId: string, params: any) => {
  return fetchApi(`pages/${pageId}/media_attachments`, 'GET', params, null);
};
export const getPlaylistPage = async (pageId: string, params: any) => {
  return fetchApi(`pages/${pageId}/series`, 'GET', params, null);
};
export const deletePlaylistPage = async (pageId: string, idSeries: any) => {
  return fetchApi(`pages/${pageId}/series/${idSeries}`, 'DELETE', null, null);
};
export const deleteVideoPlaylistPage = async (
  pageId: string,
  idSeries: any,
  data: any
) => {
  return fetchApi(
    `pages/${pageId}/series/${idSeries}/remove_status`,
    'Post',
    null,
    data
  );
};
export const getListPlaylistPage = async (
  pageId: string,
  idSeries: any,
  params: any
) => {
  return fetchApi(
    `pages/${pageId}/series/${idSeries}/statuses`,
    'GET',
    params,
    null
  );
};
export const addVideoToPlaylist = async (
  pageId: string,
  idSeries: any,
  data: any
) => {
  return fetchApi(
    `pages/${pageId}/series/${idSeries}/add_status`,
    'POST',
    null,
    data
  );
};
export const updatePlaylist = async (
  pageId: string,
  idSeries: any,
  data: any
) => {
  return fetchApi(`pages/${pageId}/series/${idSeries}`, 'PATCH', null, data);
};

export const getPopularPage = async (pageId: string) => {
  return fetchApi(`pages/${pageId}/popular_video`, 'GET', null, null);
};

export const getFeedbacksPage = async (pageId: string, params: any) => {
  return fetchApi(`pages/${pageId}/feedbacks`, 'GET', params, null);
};

export const createFeedbacksPage = async (pageId: string, params: any) => {
  return fetchApi(`pages/${pageId}/feedbacks`, 'POST', params, null);
};
export const createPlayList = async (pageId: string, data: any) => {
  return fetchApi(`pages/${pageId}/series`, 'POST', null, data);
};

export const updateFeedbacksPage = async (pageId: string, params: any) => {
  return fetchApi(`pages/${pageId}/feedbacks`, 'PATCH', params, null);
};

export const deleteFeedbacksPage = async (
  pageId: string,
  idFeedback: string
) => {
  return fetchApi(
    `pages/${pageId}/feedbacks/${idFeedback}`,
    'DELETE',
    null,
    null
  );
};

export const addMonitoredKeywordPage = async (pageId: string, data: any) => {
  return fetchApiRequest(`pages/${pageId}`, 'PATCH', null, data);
};

export const validateUsernamePage = async (params: any) => {
  return fetchApi(`pages_validate_username`, 'GET', params, null);
};

export const changeRoleManagePageApi = async (
  pageId: any,
  userId: any,
  method: any,
  params: any
) => {
  return fetchApi(`pages/${pageId}/accounts/${userId}`, method, params, null);
};

export const searchPage = async (params: any) => {
  return fetchApi(`search/page`, 'GET', params, null);
};
export const searchPageApi = async (id, params) => {
  return fetchApi(`timelines/page/${id}/search`, 'GET', params, null);
};
export const getSuggestionPage = async (params: any) => {
  return fetchApi(`suggestions/page`, 'GET', params, null);
};

export const getPinnedPostPage = async (idPage: any, params: any) => {
  return fetchApi(`pages/${idPage}/pins`, 'GET', params, null);
};

export const getScheduledPostPage = async (idPage: any) => {
  return fetchApi(`pages/${idPage}/scheduled_statuses`, 'GET', null, null);
};

export const updateScheduledPostPage = async (
  idPage: any,
  idPost: any,
  params: any
) => {
  return fetchApi(
    `pages/${idPage}/scheduled_statuses?scheduled_status_id=${idPost}`,
    'PATCH',
    params,
    null
  );
};

export const publicScheduledPostPage = async (idPage: any, idPost: any) => {
  return fetchApi(
    `pages/${idPage}/scheduled_statuses/${idPost}/public`,
    'POST',
    null,
    null
  );
};

export const getStatusAlertsApi = async (idPage: any, params: any) => {
  return fetchApi(`pages/${idPage}/status_alerts`, 'GET', params, null);
};

export const approveStatusAlertApi = async (idPage: any, params: any) => {
  return fetchApi(
    `pages/${idPage}/status_alerts/approve`,
    'POST',
    params,
    null
  );
};

export const deleteSchedulePostPageApi = async (idPage: any, params: any) => {
  return fetchApiRequest(
    `pages/${idPage}/scheduled_statuses`,
    'DELETE',
    null,
    params
  );
};

export const deleteStatusAlertApi = async (idPage: any, params: any) => {
  return fetchApiRequest(
    `pages/${idPage}/status_alerts`,
    'DELETE',
    null,
    params
  );
};

export const getListPageLikesApi = async (idPage: any, params: any) => {
  return fetchApiRequest(
    `/accounts/${idPage}/page_likes/page_likes_watch`,
    'GET',
    params,
    null
  );
};
export const createPageAddress = async (params: any) => {
  return fetchApiRequest(`pages`, 'POST', null, params);
};
export const createlinkGroupToPage = async (idPage: any, params: any) => {
  return fetchApiRequest(`pages/${idPage}/link_group`, 'POST', null, params);
};
export const getGroupsLinkToPage = async (idPage: any, params: any) => {
  return fetchApiRequest(`pages/${idPage}/linked_groups`, 'GET', params, null);
};
export const deleteLinkGroupToPage = async (idPage: any, params: any) => {
  return fetchApiRequest(
    `pages/${idPage}/remove_linked_group`,
    'DELETE',
    null,
    params
  );
};
export const pageIdentityVerification = async (
  data: any,
  type: 'create' | 'update'
) => {
  const formData: any = new FormData();
  if (data.tax_code) {
    formData.append('tax_code', data.tax_code);
  }
  if (data.business_registration_id) {
    formData.append(
      'business_registration_id',
      data.business_registration_id.id
    );
  }
  if (data.company) {
    formData.append('company', data.company);
  }
  if (data.page_id && type === 'create') {
    formData.append('page_id', data.page_id);
  }
  if (type === 'create') {
    return fetchApiRequest(
      'page_identity_verifications',
      'POST',
      null,
      formData
    );
  } else {
    return fetchApiRequest(
      `page_identity_verifications/${data.page_id}`,
      'PATCH',
      null,
      formData
    );
  }
};

export const updateAccountHolderApi = async (idPage: any, params: any) => {
  return fetchApiRequest(
    `pages/${idPage}/account_holder`,
    'PATCH',
    null,
    params
  );
};

export const createPageBloodDonations = async data => {
  return fetchApiRequest('page_blood_donations', 'POST', null, data);
};
