import React, { useState } from 'react';
import { makeStyles, createStyles, useTheme } from '@mui/styles';
import {
  Theme,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Box,
  Tabs,
  Tab,
  Skeleton
} from '@mui/material';
import {
  buttonColor,
  modalBackdrop,
  WebkitScrollbarRGB
} from 'src/constants/styles';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import ListButton from '../List/ListButton';
import { searchFriendUser } from 'src/apis/socialUser';
import { createInviteFollowsPage } from 'src/apis/socialPages.api';
import { createInviteGrow } from 'src/apis/socialGrow.api';
import { createInviteGroup, getListGroupApi } from 'src/apis/socialGroup';
import { createInviteRecruit } from 'src/apis/socialRecruit.api';
import { createInviteCourse } from 'src/apis/socialCourse.api';
import { createInviteEvent } from 'src/apis/socialEvent.api';
import { useDispatch, useSelector } from 'react-redux';
import _, { debounce } from 'lodash';
import { createInviteProduct } from 'src/apis/socialMarketplace.api';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRouteMatch } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { notiSharePost } from 'src/store/action/socialPostAction';
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      scroll: {
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        '&:hover': {
          overflowY: 'auto'
        },
        '&:hover&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#bdbdbd',
          borderRadius: 10
        }
      },
      dialogContent: {
        overflowX: 'hidden',
        alignItems: 'start',
        display: 'flex',
        flexDirection: 'column',
        height: '500px',
        minHeight: '250px',
        padding: '0px 5px !important',
        marginBottom: '30px',
        ...WebkitScrollbarRGB(null)
      },
      itemFlex: {
        display: 'flex',
        alignItems: 'center'
      },
      textNormal: {
        fontSize: '20px !important',
        fontWeight: '700 !important',
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        wordBreak: 'break-word'
      }
    }),
  { index: 1 }
);

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pageInfo?: any;
  groupId?: String | any;
  title?: String;
  eventId?: String | any;
  growId?: String | any;
  recruitId?: String | any;
  courseId?: String | any;
  productId?: String | any;
  data?: any;
}

export default function DialogInvite(props: Props) {
  const classes = useStyles();
  const {
    open,
    setOpen,
    pageInfo,
    groupId,
    title,
    eventId,
    growId,
    recruitId,
    courseId,
    productId,
    data
  } = props;
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState('');
  const theme: any = useTheme();
  const [fieldSearch, setFieldSearch] = useState<any>('all');
  const [value, setValue] = useState(0);
  const [chooseItem, setChooseItem] = useState<any>([]);
  const [listUserSearch, setListUserSearch] = useState<any>([]);
  const [listInvitedUser, setListInvitedUser] = useState<any>([]);
  const [allUninvitedFriends, setAllUninvitedFriends] = useState<any>(0);
  const [totalFriend, setTotalFriendInvited] = useState(0);
  const [listUserGroup, setListUserGroup] = useState<any>([]);
  const meInfo = useSelector((state: any) => state?.meReducer.info);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [isLoading, setIsLoading] = useState<any>(true);
  const [loadingAction, setLoadingAction] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setKeyword('');
  };

  const params = type => {
    if (fieldSearch !== 'all') {
      if (pageInfo?.id) {
        return {
          [`${type}_invitation_member_group`]: fieldSearch,
          entity_id: pageInfo?.id,
          entity_type: 'Page'
        };
      } else if (groupId) {
        return {
          [`${type}_invitation_member_group`]: fieldSearch,
          entity_id: groupId,
          entity_type: 'Group'
        };
      } else if (eventId) {
        return {
          [`${type}_invitation_member_group`]: fieldSearch,
          entity_id: eventId,
          entity_type: 'Event'
        };
      } else if (growId) {
        return {
          [`${type}_invitation_member_group`]: fieldSearch,
          entity_id: growId,
          entity_type: 'Project'
        };
      } else if (recruitId) {
        return {
          [`${type}_invitation_member_group`]: fieldSearch,
          entity_id: recruitId,
          entity_type: 'Recruit'
        };
      } else if (courseId) {
        return {
          [`${type}_invitation_member_group`]: fieldSearch,
          entity_id: courseId,
          entity_type: 'Course'
        };
      } else if (productId) {
        return {
          [`${type}_invitation_member_group`]: fieldSearch,
          entity_id: productId,
          entity_type: 'Product'
        };
      }
    } else {
      if (pageInfo?.id) {
        if (type === 'included') {
          return { [`${type}_invitation_follow_page`]: pageInfo.id };
        } else {
          return { [`${type}_page`]: pageInfo.id };
        }
      } else if (groupId) {
        if (type === 'excluded') {
          return {
            [`${type}_invitation_group`]: groupId,
            excluded_group: groupId
          };
        } else {
          return {
            [`${type}_invitation_group`]: groupId
          };
        }
      } else if (eventId) {
        return { [`${type}_invitation_event`]: eventId };
      } else if (growId) {
        return { [`${type}_invitation_project`]: growId };
      } else if (recruitId) {
        return { [`${type}_invitation_recruit`]: recruitId };
      } else if (courseId) {
        return { [`${type}_invitation_course`]: courseId };
      } else if (productId) {
        return { [`${type}_invitation_product`]: productId };
      }
    }
  };

  const getListInvitedUser = async () => {
    const param = params('included');
    let response: any = await searchFriendUser(meInfo?.id, param);

    if (response.status === 200) {
      setTotalFriendInvited(response?.data?.meta?.total);

      let newDataInvited: any =
        response.data?.data?.map((el: any) => ({
          id: el.id,
          title: !!el.display_name ? el.display_name : el.username,
          avatar_icon: el?.avatar_media
            ? el.avatar_media.url || el.avatar_media.preview_url
            : meInfo?.avatar_static,
          styleAvatar: { height: 40, width: 40 },
          styleImgAvatar: { height: 39, width: 39 }
        })) || [];
      if (data) {
        let filterNewDataInvited = newDataInvited.filter(
          item => item.id !== data?.account?.id
        );
        setListInvitedUser(filterNewDataInvited);
      } else {
        setListInvitedUser(newDataInvited);
      }
      setIsLoading(false);
    }
  };

  const renderAvatar = (info: any) => {
    if (info?.avatar_media) {
      return info?.avatar_media.show_url
        ? info?.avatar_media.show_url
        : info?.avatar_media.preview_url;
    } else {
      return meInfo?.avatar_static;
    }
  };

  const getListUserExcluded = async () => {
    const param: any = params('excluded');
    let response: any = await searchFriendUser(meInfo?.id, {
      ...param,
      limit: 10
    });
    if (response.status === 200) {
      let newDataSearch: any =
        response.data.data?.map((el: any) => ({
          id: el.id,
          title: !!el.display_name ? el.display_name : el.username,
          avatar_icon: renderAvatar(el),
          checkbox: true,
          styleAvatar: { height: 40, width: 40 },
          styleImgAvatar: { height: 39, width: 39 }
        })) || [];
      setIsLoading(false);
      setListUserSearch(newDataSearch);
      if (data) {
        let totalUser = response.data.meta.total;
        setAllUninvitedFriends(totalUser - 1);
      } else {
        setAllUninvitedFriends(response.data.meta.total);
      }
    }
  };

  const handleSearchUser = React.useCallback(
    debounce(async (keyword: any) => {
      const param: any = params('excluded');
      if (Object.keys(param).length) {
        let response: any = await searchFriendUser(
          meInfo?.id,
          keyword === ''
            ? { ...param, limit: 10 }
            : { ...param, limit: 10, keyword: keyword }
        );
        if (response.status === 200) {
          let newDataSearch: any =
            response.data.data?.map((el: any) => ({
              id: el.id,
              title: !!el.display_name ? el.display_name : el.username,

              avatar_icon: renderAvatar(el),
              checkbox: true,
              styleAvatar: { height: 40, width: 40 },
              styleImgAvatar: { height: 39, width: 39 }
            })) || [];
          setIsLoading(false);
          setListUserSearch(newDataSearch);
          if (data) {
            let totalUser = response.data.meta.total;
            setAllUninvitedFriends(totalUser - 1);
          } else {
            setAllUninvitedFriends(response.data.meta.total);
          }
        }
      }
    }, 400),
    [JSON.stringify(params('excluded'))]
  );

  const fetchGroup = async () => {
    let response = await getListGroupApi({ tab: 'join' });
    if (response.status === 200) {
      setListUserGroup(response.data);
    }
  };
  const handleInvite = async () => {
    try {
      let listIdInviteChoose: any = chooseItem.map((el: any) => el.id);
      if (listIdInviteChoose) {
        let res: any = null;
        if (pageInfo?.id) {
          res = await createInviteFollowsPage(pageInfo.id, {
            target_account_ids: listIdInviteChoose,
            invitation_type: 'like'
          });
        } else if (groupId) {
          res = await createInviteGroup(groupId, {
            target_account_ids: listIdInviteChoose
          });
        } else if (eventId) {
          res = await createInviteEvent(eventId, listIdInviteChoose);
        } else if (growId) {
          res = await createInviteGrow(growId, listIdInviteChoose);
        } else if (recruitId) {
          res = await createInviteRecruit(recruitId, listIdInviteChoose);
        } else if (courseId) {
          res = await createInviteCourse(courseId, listIdInviteChoose);
        } else if (productId) {
          res = await createInviteProduct(productId, listIdInviteChoose);
        }

        if (res.status === 200) {
          setChooseItem([]);

          getListUserExcluded();
          setValue(2);
        }
      }
    } catch (error) {
      dispatch(
        notiSharePost({
          code: 400,
          msg: 'Đã có lỗi xảy ra. Vui lòng thử lại sau!'
        })
      );
    } finally {
      setLoadingAction(false);
    }
  };

  const funcLoad = async () => {
    const param = params('excluded');
    let friendLast = [...listUserSearch].pop();
    let maxId = friendLast?.id;

    let res: any = await searchFriendUser(meInfo?.id, {
      ...param,
      max_id: maxId,
      limit: 10
    });

    if (res.data.data.length) {
      const newArray =
        res.data.data?.map((el: any) => ({
          id: el.id,
          title: !!el.display_name ? el.display_name : el.username,
          avatar_icon: renderAvatar(el),
          checkbox: true,
          styleAvatar: { height: 40, width: 40 },
          styleImgAvatar: { height: 39, width: 39 }
        })) || [];
      setListUserSearch(prev => _.uniqBy([...prev, ...newArray], 'id'));
    }
  };

  React.useEffect(() => {
    if (
      (open && pageInfo?.id && meInfo?.id) ||
      (open && groupId && meInfo?.id) ||
      (open && eventId && meInfo?.id) ||
      (open && growId && meInfo?.id) ||
      (open && recruitId && meInfo?.id) ||
      (open && courseId && meInfo?.id) ||
      (open && productId && meInfo?.id)
    ) {
      getListUserExcluded();
      getListInvitedUser();
    }
  }, [
    open,
    JSON.stringify(pageInfo),
    JSON.stringify(groupId),
    JSON.stringify(meInfo),
    JSON.stringify(growId),
    JSON.stringify(recruitId),
    JSON.stringify(courseId),
    JSON.stringify(productId),
    fieldSearch
  ]);

  React.useEffect(() => {
    if (value === 0) {
      getListUserExcluded();
    } else if (value === 2) {
      getListInvitedUser();
    }
  }, [value]);

  React.useEffect(() => {
    if (open) {
      fetchGroup();
    }
  }, [open]);

  const renderTitle = () => {
    if (pageInfo?.id) {
      return `Mời thành viên Nhóm khác thích trang của bạn`;
    } else if (groupId) {
      return `Mời thành viên Nhóm khác vào nhóm của bạn`;
    } else if (eventId) {
      return `Mời thành viên Nhóm khác tham gia sự kiện của bạn`;
    } else if (growId) {
      return `Mời thành viên Nhóm khác tham gia dự án gọi vốn của bạn`;
    } else if (recruitId) {
      return `Mời thành viên Nhóm khác tham gia tuyển dụng của bạn`;
    } else if (courseId) {
      return `Mời thành viên Nhóm khác tham gia khóa học của bạn`;
    } else if (productId) {
      return `Mời thành viên Nhóm khác thích sản phẩm của bạn`;
    }
  };

  let buttonList: any = [
    [
      {
        title: renderTitle(),
        description:
          'Khi bạn chọn Nhóm khác, hệ thống sẽ xóa lựa chọn hiện tại của bạn.'
      }
    ],
    [
      {
        id: 'all',
        label: 'Tất cả bạn bè',
        action: () => setFieldSearch('all'),
        checkbox: true
      },
      ...listUserGroup
        .filter((item: any) => item.id !== groupId)
        .map((item: any) => ({
          id: item.id,
          label: item.title,
          action: () => setFieldSearch(item.id),
          checkbox: true
        }))
    ]
  ];

  const renderLoader = () => {
    return (
      <Box className={classes.itemFlex} sx={{ marginTop: '10px' }}>
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton
          variant="text"
          sx={{ fontSize: '1rem', width: '100%', marginLeft: '10px' }}
        />
      </Box>
    );
  };

  return (
    <Dialog
      onClose={() => handleClose()}
      open={open}
      fullWidth
      BackdropProps={modalBackdrop}
      style={{
        minHeight: '600px'
      }}
      maxWidth="md"
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="h5" className={classes.textNormal}>
          {title
            ? title
            : pageInfo?.title
            ? `Mời bạn thích trang ${pageInfo.title}`
            : `Mời bạn vào nhóm ${title}`}
        </Typography>
        <Box
          onClick={() => setOpen(false)}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CloseIcon sx={{ fontSize: 30 }} />
        </Box>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: '10px 24px', overflow: 'hidden' }}>
        <div>
          <IconButtonOptions
            name={
              buttonList[1]?.find((el: any) => el.id === fieldSearch)?.label
            }
            icon={
              <i
                style={{ fontSize: '16px' }}
                className="fa-solid fa-caret-down"
              ></i>
            }
            openPopup={true}
            style={{ margin: '0px 0px', width: 'auto' }}
            options={buttonList}
            styleListMenu={{
              minWidth: '350px',
              minHeight: '350px'
            }}
            stylePopup={{ height: '350px' }}
            styleNameButton={{ color: 'text.primary' }}
            optionCheckDefault="all"
          />
        </div>
      </DialogContent>
      <DialogContent
        dividers
        sx={{ padding: '0px 9px 9px', overflow: 'hidden' }}
      >
        <Box sx={{ borderBottom: 1, borderColor: '#fff' }}>
          <Tabs
            indicatorColor="secondary"
            textColor="inherit"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {[
              { id: 0, label: 'Chưa mời', total: allUninvitedFriends },
              { id: 1, label: 'Đã chọn', total: chooseItem.length },
              { id: 2, label: 'Đã mời', total: totalFriend }
            ].map((el, index) => (
              <Tab
                key={index}
                style={{
                  textTransform: 'none',
                  fontWeight: 600,
                  fontSize: '15px'
                }}
                label={el.label + `[${el.total}]`}
                {...a11yProps(el.id)}
              />
            ))}
          </Tabs>
        </Box>
      </DialogContent>
      <DialogContent id="scrollAllFriend" className={classes.dialogContent}>
        <Box
          sx={{
            width: '100%',
            maxHeight: '100%',
            padding: value === 0 ? '14px 0px' : '0px'
          }}
        >
          <TabPanel value={value} index={0}>
            <div
              style={{
                display: 'flex'
              }}
            >
              <TextField
                fullWidth
                size="small"
                value={keyword}
                onChange={(event: any) => {
                  handleSearchUser(event.target.value);
                  setKeyword(event.target.value);
                  setIsLoading(true);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <i
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          color:
                            theme.palette.mode === 'dark' ? '#fff' : '#050505'
                        }}
                        className="fa-light fa-magnifying-glass"
                        aria-hidden="true"
                      ></i>
                    </InputAdornment>
                  ),
                  sx: {
                    '&::placeholder': {
                      color: 'green'
                    }
                  },
                  disableUnderline: true
                }}
                InputLabelProps={{
                  style: { fontSize: '19px' }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderRadius: '20px',
                      maxHeight: '40px'
                    }
                  },
                  '& .MuiOutlinedInput-input': {
                    padding: '4px 0px !important'
                  },
                  backgroundColor: 'background.primary',
                  borderRadius: '20px',
                  maxHeight: '35px',
                  width: '100%'
                }}
                placeholder={'Tìm kiếm...'}
                inputProps={{
                  classes: {
                    input: {
                      '&::placeholder': {
                        color: 'blue'
                      }
                    }
                  }
                }}
              />
            </div>
            {isLoading ? (
              Array.from(Array(10).keys()).map(item => renderLoader())
            ) : (
              <InfiniteScroll
                dataLength={listUserSearch?.length}
                next={funcLoad}
                hasMore={true}
                loader={<></>}
                scrollableTarget="scrollAllFriend"
                scrollThreshold={0.6}
              >
                <ListButton
                  item={
                    data
                      ? listUserSearch.filter(
                          item => item.id !== data?.account?.id
                        )
                      : listUserSearch
                  }
                  setChooseItem={setChooseItem}
                  chooseItem={chooseItem}
                  typeAction={'checkbox'}
                />
              </InfiniteScroll>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ListButton
              item={chooseItem}
              setChooseItem={setChooseItem}
              chooseItem={chooseItem}
              typeAction={'checkbox'}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ListButton
              item={listInvitedUser}
              styleBg={{ padding: '0px' }}
              typeAction={'no_action'}
            />
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          width: '100%',
          backgroundColor: 'background.primary'
        }}
      >
        <ButtonInherit
          label={'Gửi lời mời'}
          action={() => {
            setLoadingAction(true);
            handleInvite();
          }}
          color={buttonColor.backgroundColor}
          loading={loadingAction}
          disabled={chooseItem.length === 0}
        />
      </DialogActions>
    </Dialog>
  );
}
