import * as types from 'src/constants/store/groupSocial';
export const getGroupDetailReq = (id: any, type) => ({
  type: types.GET_GROUP_DETAIL_REQ,
  payload: {
    id,
    type
  }
});

export const getGroupDetailSuccess = (data: Object) => ({
  type: types.GET_GROUP_DETAIL_SUCCESS,
  payload: {
    data
  }
});

export const getGroupDetailError = (data: Object) => ({
  type: types.GET_GROUP_DETAIL_ERROR,
  payload: {
    data
  }
});

export const getGroupAccountReq = (id: any, params: any, type: string) => ({
  type: types.GET_GROUP_ACCOUNT_REQ,
  payload: {
    id,
    params,
    type
  }
});

export const getGroupAccountSuccess = (data: Object, type: string) => ({
  type: types.GET_GROUP_ACCOUNT_SUCCESS,
  payload: {
    data,
    type
  }
});

export const deleteAccountReq = (id: any, accountId: any) => ({
  type: types.DELETE_ACCOUNT_REQ,
  payload: {
    id,
    accountId
  }
});

export const deleteAccountSuccess = (id: Object, type) => ({
  type: types.DELETE_ACCOUNT_SUCCESS,
  payload: {
    id,
    type
  }
});

export const getRemoveAccountReq = (id: any) => ({
  type: types.GET_ACCOUNT_REMOVE_REQ,
  payload: {
    id
  }
});

export const getRemoveAccountSuccess = (data: Object) => ({
  type: types.GET_ACCOUNT_REMOVE_SUCCESS,
  payload: {
    data
  }
});

export const getWaitingPostReq = (id: any, params) => ({
  type: types.GET_WAITING_POST_REQ,
  payload: {
    id,
    params
  }
});

export const getWaitingPostSuccess = (data: Object) => ({
  type: types.GET_WAITING_POST_SUCCESS,
  payload: {
    data
  }
});

export const getWaitingMemberReq = (id: any, params) => ({
  type: types.GET_WAITING_MEMBER_REQ,
  payload: {
    id,
    params
  }
});

export const getWaitingMemberSuccess = (data: Object) => ({
  type: types.GET_WAITING_MEMBER_SUCCESS,
  payload: {
    data
  }
});

export const updateWaitingMember = (data: Object) => ({
  type: types.UPDATE_WAITTING_MEMBER,
  payload: {
    data
  }
});

export const getReportPostReq = (id: any, params) => ({
  type: types.GET_REPORT_POST_REQ,
  payload: {
    id,
    params
  }
});

export const getReportPostSuccess = (data: Object) => ({
  type: types.GET_REPORT_POST_SUCCESS,
  payload: {
    data
  }
});

export const updateReportPost = postId => ({
  type: types.UPDATE_REPORT_POST,
  payload: {
    postId
  }
});

export const getRuleGroupReq = (id: any) => ({
  type: types.GET_RULE_GROUP_REQ,
  payload: {
    id
  }
});

export const getRuleGroupSuccess = (data: Object) => ({
  type: types.GET_RULE_GROUP_SUCCESS,
  payload: {
    data
  }
});

export const getMemberQuestionReq = (type, id: any) => ({
  type: types.GET_MEMBER_QUESTION_REQ,
  payload: {
    id,
    type
  }
});

export const createMemberQuestion = data => ({
  type: types.CREATE_MEMBER_QUESTION,
  payload: { data }
});

export const updateMemberQuestion = data => ({
  type: types.UPDATE_MEMBER_QUESTION,
  payload: { data }
});

export const getMemberQuestionSuccess = (data: Object) => ({
  type: types.GET_MEMBER_QUESTION_SUCCESS,
  payload: {
    data
  }
});

export const resetMemberQuestion = () => ({
  type: types.RESET_MEMBER_QUESTION
});

export const getSchedulePostReq = (id: any) => ({
  type: types.GET_SCHEDULE_POST_REQ,
  payload: {
    id
  }
});

export const getSchedulePostSuccess = (data: Object) => ({
  type: types.GET_SCHEDULE_POST_SUCCESS,
  payload: {
    data
  }
});

export const approvedSchedulePost = postId => ({
  type: types.APPROVED_GROUP_SCHEDULE,
  payload: { postId }
});

export const createSchedulePost = data => ({
  type: types.CREATE_SCHEDULE_GROUP,
  payload: { data }
});

export const updateSchedulePost = data => ({
  type: types.UPDATE_SCHEDULE_GROUP,
  payload: { data }
});

export const changeSchedulePost = data => ({
  type: types.CHANGE_SCHEDULE_GROUP,
  payload: { data }
});

export const getCensorshipReq = (id: any) => ({
  type: types.GET_CENSORSHIP_REQ,
  payload: {
    id
  }
});

export const getCensorshipSuccess = (data: Object) => ({
  type: types.GET_CENSORSHIP_SUCCESS,
  payload: {
    data
  }
});

export const getActivityLogReq = (id: any, params) => ({
  type: types.GET_ACTIVITY_LOG_REQ,
  payload: {
    id,
    params
  }
});

export const getActivityLogSuccess = (data: Object) => ({
  type: types.GET_ACTIVITY_LOG_SUCCESS,
  payload: {
    data
  }
});

export const loadingActivityLogGroup = boolean => ({
  type: types.LOADING_ACTIVITY_LOG,
  payload: {
    boolean
  }
});

export const noteActivityLogReq = (id: any, params, data) => ({
  type: types.NOTE_ACTIVITY_LOG_REQ,
  payload: {
    id,
    params,
    data
  }
});

export const noteActivityLogSuccess = (data: Object) => ({
  type: types.NOTE_ACTIVITY_LOG_SUCCESS,
  payload: {
    data
  }
});

export const groupRelationShipReq = (data: Object) => ({
  type: types.GROUP_RELATIONSHIP,
  payload: {
    data
  }
});

export const updateGroupRealationShip = type => ({
  type: types.UPDATE_GROUP_RELATIONSHIP,
  payload: {
    type
  }
});

export const updateGroupPreview = (data: Object) => ({
  type: types.GROUP_PREVIEW,
  payload: {
    data
  }
});

export const groupInvitationReq = (id: string, params: Object) => ({
  type: types.GET_INVITATION_REQ,
  payload: {
    id,
    params
  }
});

export const groupInvitationSuccess = (data: Object, role: string) => ({
  type: types.GET_INVITATION_SUCCESS,
  payload: {
    data,
    role
  }
});

export const resetGroup = () => ({
  type: types.RESET_GROUP
});

export const searchGroup = (data: any, key: string, type: string) => ({
  type: types.GROUP_SEARCH,
  payload: {
    data,
    key,
    type
  }
});
export const updateGroupSearch = data => ({
  type: types.UPDATE_GROUP_SEARCH,
  payload: {
    data
  }
});

export const getSearchPostInGroupAll = (data: any) => ({
  type: types.GET_SEARCH_POST_IN_GROUP_ALL,
  payload: data
});
export const isLoadingGroupMember = (value: any) => ({
  type: types.IS_LOADING_GROUP_MEMBER,
  payload: {
    value
  }
});

export const deleteMemberSuccess = (idMember: any) => ({
  type: types.DELETE_MEMBER_SUCCESS,
  payload: {
    idMember
  }
});

export const loadingSearchGroup = type => ({
  type: types.LOADING_SEARCH_GROUP,
  payload: type
});

export const getListStatusUserInGroupSuccess = (data: any) => ({
  type: types.GET_ACTIVITIES_USER_IN_GROUP_SUCCESS,
  payload: { data }
});

export const getListStatusUserInGroupRequest = (groupId: string, params) => ({
  type: types.GET_ACTIVITIES_USER_IN_GROUP_REQUEST,
  payload: {
    id: groupId,
    params
  }
});

export const changeIsLoadingActivitiesGroup = (isLoading: boolean) => ({
  type: types.CHANGE_ISLOADING_ACTIVITIES_LOG,
  payload: isLoading
});
