import * as types from 'src/constants/store/recruitSocial';
export const recruitPreview = (data: Object) => ({
  type: types.RECRUIT_PREVIEW_INFO,
  payload: {
    data
  }
});

export const recruitUpdatePage = () => ({
  type: types.UPDATE_PAGE_OWNER
});

export const getRecruitDetailSuccess = (data: any) => ({
  type: types.GET_RECRUIT_DETAIL_SUCCESS,
  payload: {
    data
  }
});

export const getRecruitDetailError = (data: any) => ({
  type: types.GET_RECRUIT_DETAIL_ERROR,
  payload: {
    data
  }
});

export const getRecruitDetailRequest = (idRecruit: any) => ({
  type: types.GET_RECRUIT_DETAIL_REQUEST,
  payload: {
    idRecruit
  }
});

export const resetRecruit = () => ({
  type: types.RESET_RECRUIT
});

export const submitRecruit = () => ({
  type: types.SUBMIT_RECRUIT
});

export const resetRecruitError = () => ({
  type: types.RESET_ERROR_RECRUIT
});
export const loadingRecruit = (value: any) => ({
  type: types.LOADING_RECRUIT,
  payload: { value }
});

export const notiMobileCvRecruit = value => ({
  type: types.NOTIMOBILE,
  payload: { value }
});
export const loadingSearchRecruit = value => ({
  type: types.LOADING_SEARCH_RECRUIT,
  payload: { value }
});

export const openCv = value => ({
  type: types.OPENCV,
  payload: { value }
});

export const updateFollowRecruit = (data: any) => ({
  type: types.UPDATE_FOLLOW_RECRUIT,
  payload: { data }
});

export const getRecruitCategoriesReq = () => ({
  type: types.GET_RECRUIT_CATEGORIES_REQ
});
export const getRecruitCategoriesSuccess = (data: any) => ({
  type: types.GET_RECRUIT_CATEGORIES_SUCCESS,
  payload: { data }
});
