import { Box, Theme, Typography } from '@mui/material';import { makeStyles, useTheme } from '@mui/styles';
import React from 'react';
import {
  approveRequestFriendApi,
  cancelRequestFriendApi,
  deleteRequestFriendApi,
  getInforUser,
  listRequestFriendApi,
  listRequestToFriendApi
} from 'src/apis/socialUser';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import TabSocial from 'src/components/Tabs/TabSocial';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { SkeletonFriendItem } from 'src/components/Skeleton/skeleton';
import { countTimeFrom } from 'src/helpers/string';
import { buttonColor } from 'src/constants/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getInforlUserReq } from 'src/store/action/socialUserAction';
import { saveUserInfo } from 'src/store/action/saveUser';
import axios from 'axios';

const useStyles = makeStyles((theme: Theme) => ({
  wrapAvatar: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 12
  },
  wrapUser: {
    width: 298,
    display: 'flex',
    alignItems: 'center',
    padding: '12px 8px',
    margin: '6px 6px 0px 6px',
    borderRadius: 10,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0,0,0,0.05)'
    }
  },
  wrapButtonRequest: {
    display: 'flex',
    alignItems: 'center'
  },
  textSecondary: {
    fontSize: '13px !important',
    color: '#65676b',
    margin: '0px 0px 5px 0px!important'
  },
  countTimeFrom: {
    fontSize: '13px!important',
    color: '#65676b'
  },
  wrapText: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flex: 1
  },
  lineClamp: {
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  }
}));

const FriendRequest = (props: any) => {
  const { setOpenSnackbar, setNoti } = props;
  const [keyTab, setKeyTab] = React.useState('request');
  const [listFriend, setListFriend] = React.useState<any>([]);
  const [listFriendTo, setListFriendTo] = React.useState<any>([]);
  const [listRender, setListRender] = React.useState<any>([]);
  const [hasMore, setHasMore] = React.useState(true);
  const classes = useStyles();
  const history = useHistory();
  const match: any = useRouteMatch();
  const theme: any = useTheme();
  const dispatch = useDispatch();
  const actionFriend = useSelector(
    (state: any) => state.userReducer.actionFriend
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [itemSelected, setItemSelected] = React.useState<any>('');
  const [typeAction, setTypeAction] = React.useState<any>('');

  const buttonRequest = {
    padding: '4px 5px'
  };

  const [total, setTotal] = React.useState({
    friendTo: 0,
    toFriend: 0
  });
  const handleChangeKeyTab = (key: any) => {
    setListRender([]);
    setKeyTab(key);
    fetchDataFriend(key);
    setListFriend([]);
    setListFriendTo([]);
    setHasMore(true);
  };

  let listMenu = [
    { text: `Yêu cầu kết bạn (${total.toFriend})`, id: 'request' },
    { text: `Lời mời đã gửi (${total.friendTo})`, id: 'requestTo' }
  ];

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    let newKeyValue = listMenu[newValue].id;
    handleChangeKeyTab(newKeyValue);
  };

  const fetchDataFriend = async (type, maxId = null) => {
    let response;
    if (type === 'requestTo') {
      response = await listRequestToFriendApi({
        max_id: maxId,
        limit: 20
      });
    } else if (type === 'request') {
      response = await listRequestFriendApi({
        max_id: maxId,
        limit: 20
      });
    }
    if (response.status === 200) {
      const data = response.data?.data;
      if (data.length) {
        if (type === 'requestTo') {
          setTotal(prev => ({
            ...prev,
            friendTo: response.data?.meta.total
          }));
          setListFriendTo(prev => [...prev, ...response.data.data]);
        } else if (type === 'request') {
          setTotal(prev => ({
            ...prev,
            toFriend: response.data?.meta.total
          }));
          setListFriend(prev => [...prev, ...response.data.data]);
        }
        if (data.length < 20) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    }
  };

  const funcLoad = () => {
    let mediaLast = [...listFriend].pop();
    let maxId = mediaLast?.id;
    if (listFriend.length) {
      fetchDataFriend(keyTab, maxId);
    }
  };

  const handleInforUser = async (userId, source: any) => {
    let res = await getInforUser(userId, source);
    if (res.status === 200) {
      dispatch(getInforlUserReq(res.data));
      dispatch(saveUserInfo(res.data));
    }
  };

  const handleAction = async (type, item, index) => {
    try {
      if (!item) return;
      let response;
      if (type === 'removeRequest') {
        response = await cancelRequestFriendApi(item.id);
      } else if (type === 'delete') {
        response = await deleteRequestFriendApi(item.id);
      } else if (type === 'approve') {
        response = await approveRequestFriendApi(item.id);
      }
      if (response.status === 200) {
        if (keyTab === 'requestTo') {
          setListFriendTo(prev => [
            ...prev.slice(0, index),
            ...prev.slice(index + 1)
          ]);
          setTotal(prev => ({
            ...prev,
            friendTo: prev.friendTo - 1
          }));
        } else if (keyTab === 'request') {
          setListFriend(prev => [
            ...prev.slice(0, index),
            ...prev.slice(index + 1)
          ]);
          setTotal(prev => ({
            ...prev,
            toFriend: prev.toFriend - 1
          }));
        }
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        handleInforUser(item.id, source);
        setOpenSnackbar(true);

        setNoti({
          code: 200,
          message: renderTextAlert(type)
        });
        setIsLoading(false);
      }
    } catch (err: any) {
      setIsLoading(false);
      setOpenSnackbar(true);
      setNoti({
        code: err.response.status,
        message: 'Có lỗi xảy ra trong quá trình phản hồi.Vui lòng thử lại sau!'
      });
    }
  };

  const renderTextAlert = type => {
    if (type === 'delete') {
      return 'Đã từ chối lời mời kết bạn.';
    } else if (type === 'removeRequest') {
      return 'Đã hủy yêu cầu kết bạn.';
    } else {
      return 'Đã chấp nhận lời mời kết bạn';
    }
  };

  const renderButton = (item, index) => {
    if (keyTab === 'requestTo') {
      return (
        <ButtonInherit
          label="Hủy yêu cầu"
          icon={
            <i style={{ fontSize: 15 }} className="fa-solid fa-user-xmark"></i>
          }
          action={e => {
            e.stopPropagation();
            handleAction('removeRequest', item, index);
            setItemSelected(item);
            setIsLoading(true);
            setTypeAction('removeRequest');
            dispatch(actionFriend('reset'));
          }}
          loading={
            isLoading &&
            item.id === itemSelected.id &&
            typeAction === 'removeRequest'
              ? true
              : false
          }
        />
      );
    } else if (keyTab === 'request') {
      return (
        <div className={classes.wrapButtonRequest}>
          <ButtonInherit
            action={e => {
              e.stopPropagation();
              handleAction('approve', item, index);
              setItemSelected(item);
              setIsLoading(true);
              setTypeAction('approve');
              dispatch(actionFriend('reset'));
            }}
            label="Chấp nhận"
            color={buttonColor.backgroundColor}
            style={buttonRequest}
            loading={
              isLoading &&
              item.id === itemSelected.id &&
              typeAction === 'approve'
                ? true
                : false
            }
          />
          <ButtonInherit
            action={e => {
              e.stopPropagation();
              handleAction('delete', item, index);
              setItemSelected(item);
              setIsLoading(true);
              setTypeAction('delete');
              dispatch(actionFriend('reset'));
            }}
            style={{ marginRight: 0 }}
            label="Xóa"
            loading={
              isLoading &&
              item.id === itemSelected.id &&
              typeAction === 'delete'
                ? true
                : false
            }
          />
        </div>
      );
    }
  };

  React.useEffect(() => {
    setListFriend([]);
    setListFriendTo([]);
    fetchDataFriend('request');
    fetchDataFriend('requestTo');
    setHasMore(true);
  }, []);

  React.useEffect(() => {
    if (actionFriend) {
      if (keyTab === 'request') {
        fetchDataFriend('request');
        setListFriend([]);
      } else {
        fetchDataFriend('requestTo');
        setListFriendTo([]);
      }
      setHasMore(true);
    }
  }, [actionFriend]);

  React.useEffect(() => {
    if (keyTab === 'requestTo') {
      setListRender(listFriendTo);
    } else if (keyTab === 'request') {
      setListRender(listFriend);
    }
  }, [keyTab, JSON.stringify(listFriend), JSON.stringify(listFriendTo)]);

  return (
    <div>
      <div
        style={{
          borderBottom: '1px solid rgba(0,0,0,0.1)'
        }}
      >
        <TabSocial
          value={keyTab}
          style={{
            borderBottom: `3px solid ${buttonColor.backgroundColor}`,
            padding: '12px 8px'
          }}
          scrollButton={'false'}
          styleTab={{ width: 145, padding: '12px 8px' }}
          listTabs={listMenu?.map((el: any) => ({
            ...el,
            text: (
              <Typography
                style={
                  keyTab === el.id
                    ? {
                        fontSize: 14,
                        color: buttonColor.backgroundColor,
                        fontWeight: 500
                      }
                    : {
                        fontSize: 14,
                        color:
                          theme.palette.mode === 'dark' ? '#fff' : '#65676B',
                        fontWeight: 500
                      }
                }
              >
                {el.text}
              </Typography>
            )
          }))}
          handleChange={handleChange}
        />
      </div>

      <div id="scrollableFriend">
        <InfiniteScroll
          dataLength={listRender.length}
          next={funcLoad}
          hasMore={hasMore}
          loader={<SkeletonFriendItem />}
          style={{ overflow: 'hidden' }}
          scrollableTarget="scrollableFriend"
          scrollThreshold={1}
        >
          {listRender?.length ? (
            listRender?.map((el: any, index) => {
              return (
                <Box
                  onClick={() => {
                    if (el?.account?.id) {
                      history.push(`/friends/requests/${el.account.id}`);
                    } else {
                      history.push(`/friends/requests/${el.target_account.id}`);
                    }
                  }}
                  sx={
                    match.params?.userId === el?.account?.id
                      ? {
                          backgroundColor: 'background.secondary',
                          '&:hover': {
                            backgroundColor: 'button.primary.hover'
                          }
                        }
                      : {
                          '&:hover': {
                            backgroundColor: 'button.primary.hover'
                          }
                        }
                  }
                  className={classes.wrapUser}
                  key={index}
                >
                  <AvatarSocial
                    avatarObj={
                      el?.account?.avatar_media ??
                      el?.target_account?.avatar_media
                    }
                    type="feed"
                    style={{ width: 60, height: 60 }}
                    isPopup
                    object={el?.account ?? el?.target_account}
                  />
                  <Box className={classes.wrapText}>
                    <Box className={classes.wrapAvatar}>
                      <Typography
                        className={classes.lineClamp}
                        sx={{ fontWeight: '500', fontSize: '15px' }}
                      >
                        {el?.account?.display_name ??
                          el?.target_account?.display_name}
                      </Typography>
                      <Typography className={classes.textSecondary}>
                        {el?.account?.relationships?.mutual_friend_count ??
                          el?.target_account?.relationships
                            ?.mutual_friend_count ??
                          0}{' '}
                        bạn chung
                      </Typography>
                      {renderButton(
                        keyTab === 'requestTo'
                          ? el?.target_account
                          : el?.account,
                        index
                      )}
                    </Box>
                    <Typography className={classes.countTimeFrom}>
                      {countTimeFrom(new Date(el.created_at), new Date())}
                    </Typography>
                  </Box>
                </Box>
              );
            })
          ) : !hasMore && !listRender?.length ? (
            <Box sx={{ padding: '10px' }}>
              <Typography sx={{ fontSize: '15px', fontWeight: 500 }}>
                {keyTab === 'request' ? 'Yêu cầu kết bạn' : 'Lời mời kết bạn'}
              </Typography>
              <Typography sx={{ fontSize: '14px' }}>
                Không có yêu cầu mới
              </Typography>
            </Box>
          ) : null}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default FriendRequest;
