import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Avatar,
  Box,
  Dialog,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import * as React from 'react';
import MediaPreview from 'src/components/ImageGrid/components/MediaPreview';
import SocialContent from 'src/components/SocialPost/SocialContent';
import SocialPostAction from 'src/components/SocialPost/SocialPostAction';
import SocialPostInformation from 'src/components/SocialPost/SocialPostInformartion';
import { PATHS } from 'src/constants/paths';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useMatchMedia } from 'src/common/useMatchMedia';
import { EnumEmojiReactType } from 'src/constants/enum/emojiReact';
import { buttonColor } from 'src/constants/styles';
import {
  newPeopleJoin,
  subStreaming,
  unSubStreaming
} from 'src/socket/Streaming';
import ButtonInherit from '../Button/ButtonInherit';
import logoEmso from 'src/assets/images/LogoEmso.svg';
import { reactionPostApi, unReactionPostApi } from 'src/apis/socialPost.api';
import angryStaticUrl from 'src/assets/images/iconEmojiStatic/angry.png';
import hahaStaticUrl from 'src/assets/images/iconEmojiStatic/haha.png';
import loveStaticUrl from 'src/assets/images/iconEmojiStatic/love.png';
import sadStaticUrl from 'src/assets/images/iconEmojiStatic/sad.png';
import yayStaticUrl from 'src/assets/images/iconEmojiStatic/yay.png';
import wowStaticUrl from 'src/assets/images/iconEmojiStatic/wow.png';
import likeStaticUrl from 'src/assets/images/iconEmojiStatic/like.png';
import {
  addLiveStreamSocket,
  reactionPostLive,
  resetLiveStreamSocket
} from 'src/store/action/socialLiveStreamAction';
import _ from 'lodash';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  post?: any;
  setMediaSelected?: React.Dispatch<React.SetStateAction<any>> | any;
  postMedia?: any;
  setPostMedia?: React.Dispatch<React.SetStateAction<any>> | any;
  indexMedia?: any;
  setIndexMedia?: React.Dispatch<React.SetStateAction<any>> | any;
  listMoments?: any;
  listPostMoment?: any;
  isAddMoment?: any;
  setIsLoadMore?: React.Dispatch<React.SetStateAction<any>> | any;
  type?: String | any;
  typePreview?: String | any;
  typeMedia?: String | any;
  typeMomentVideo?: String | any;
  keyword?: String | any;
  groupSelected?: any;
  pageSelected?: any;
  userSelected?: any;
  albumSelected?: any;
}

export default function FullScreenDialog(props: Props) {
  const {
    open,
    setOpen,
    post,
    setMediaSelected,
    postMedia,
    setPostMedia,
    indexMedia,
    setIndexMedia,
    listMoments,
    listPostMoment,
    isAddMoment,
    setIsLoadMore,
    type,
    typePreview,
    typeMedia,
    typeMomentVideo,
    keyword,
    groupSelected,
    pageSelected,
    userSelected,
    albumSelected
  } = props;

  const [isFullScreen, setIsFullScreen] = React.useState<any>(false);
  const isMobile = useMatchMedia('(min-width:500px)', true);
  const [currentMedia, setCurrentMedia] = React.useState<any>();
  const [currentUrl, setCurrentUrl] = React.useState<any>('');
  const history = useHistory();
  const match = useRouteMatch();
  const [mutedVideo, setMutedVideo] = React.useState(false);
  const videoRef: any = React.useRef();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  let typeLive: any = query.get('host');
  let postSaved: any = query.get('type');

  const useStyles = makeStyles((theme: any) => ({
    root: {
      display: 'flex',
      overflow: 'hidden',
      position: 'relative'
    },
    buttonAngle: {
      backgroundColor: theme.palette.button.primary.background,
      width: 42,
      height: 42,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 10,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(255,255,255,0.8)'
      },
      transition: '0.3s'
    },
    buttonAngleDisabled: {
      width: 42,
      margin: 10,
      borderRadius: '50%',
      height: 42
    },
    divLeft: {
      backgroundColor: '#000',
      height: '100vh',
      display: 'flex'
    },
    divCloseButton: {
      position: 'absolute',
      top: 10,
      left: 20,
      zIndex: 1000
    },
    divActionButton: {
      position: 'absolute',
      top: '45%',
      right: isFullScreen ? 5 : postMedia?.post_type === 'moment' ? 549 : 400,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    },
    divWrapIcon: {
      width: 'fit-content',
      height: '35px',
      marginTop: '15px',
      display: 'flex',
      position: 'absolute',
      top: 0,
      right: isFullScreen ? 5 : postMedia?.post_type === 'moment' ? 549 : 375,
      zIndex: 1000
    },
    styleIcon: {
      margin: '0px 5px 0px 10px !important',
      '&.Mui-disabled': {
        pointerEvents: 'auto !important'
      }
    },
    boxEye: {
      position: 'absolute',
      top: '10px',
      marginLeft: '10px',
      zIndex: '1000'
    },
    avatarImage: {
      width: '40px !important',
      height: '40px !important'
    },
    textEye: {
      fontSize: '12px  !important',
      fontWeight: '500 !important',
      color: '#fff'
    },
    emojiLive: {
      height: '60px',
      display: 'flex',
      alignItems: 'end',
      padding: '3px 0px 1px 0px',
      marginBottom: '5px',
      position: 'absolute',
      bottom: '10px',
      right: '34%',
      zoom: '0.8'
    },
    typing: {
      width: '240px',
      height: '12px !important',
      position: 'relative',
      padding: '8px 4px',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    typingDot: {
      float: 'left',
      width: '6px',
      height: '6px',
      margin: '0 3px',
      background: theme.palette.mode === 'light' ? '#8d8c91' : '#FAFAFA',
      borderRadius: '50%',
      opacity: '0'
    },
    typingText: {
      marginLeft: '6px',
      fontColor: theme.palette.mode === 'light' ? '#8d8c91' : '#FAFAFA'
    }
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const pageInfo = useSelector((state: any) => state?.pageReducer.info);
  const liveStreamSocket = useSelector(
    (state: any) => state.socialLiveStreamReducer.liveStreamSocket
  );
  const openRef = React.useRef<any>();
  const [emojiReactId, setEmojiReactId] = React.useState(-1);
  const [idReaction, setIdReaction] = React.useState<number | null>(null);
  const [animationReact, setAnimationReaction] = React.useState<string | null>(
    null
  );

  const handleAnimationReaction = react => {
    setAnimationReaction(react);

    setTimeout(() => {
      setAnimationReaction(null);
    }, 1000);
  };

  const handleChangeReactionAfterCallAPI = response => {
    dispatch(reactionPostLive(response));
  };

  const handleCallAPIClickVote = async (
    idReaction: number,
    idPage: string | null
  ) => {
    let response;
    let data = {
      custom_vote_type: EnumEmojiReactType[idReaction],
      page_id: idPage
    };
    if (idReaction < 0) {
      response = await unReactionPostApi(post.id, { page_id: idPage });
    } else {
      response = await reactionPostApi(post.id, data);
      if (response.status === 200) {
        handleChangeReactionAfterCallAPI(response.data);
      }
    }
  };

  const debounceClickPost = React.useCallback(
    _.debounce(idReaction => {
      handleCallAPIClickVote(idReaction, null);
    }, 300),
    []
  );

  const handleClickPost = async (idReaction: number) => {
    setIdReaction(idReaction);
    if (emojiReactId < 0) {
      setEmojiReactId(idReaction);
    } else if (idReaction === emojiReactId) {
      setEmojiReactId(idReaction);
    } else {
      setEmojiReactId(idReaction);
    }

    debounceClickPost(idReaction);
  };

  const onChange = id => {
    handleClickPost(id);
  };

  const handleClickReactEmoji = (id: number) => {
    openRef.current && clearTimeout(openRef.current);
    onChange(id);
  };

  const spanTextEmoji = text => {
    return (
      <span
        className="reactionLabel"
        style={{
          marginBottom: '-5px'
        }}
      >
        {text}
      </span>
    );
  };

  const renderEyeLive = () => {
    return (
      <Box
        className={classes.boxEye}
        sx={{
          marginLeft: '10px',
          top: '12px',
          left: '38px'
        }}
      >
        <ButtonInherit
          label={
            <Avatar
              alt="logo Emso"
              src={logoEmso}
              className={classes.avatarImage}
            />
          }
          style={{
            backgroundColor: 'transparent',
            margin: '0px'
          }}
          action={() => history.push('/')}
        />

        <ButtonInherit
          label={<Typography className={classes.textEye}>Trực tiếp</Typography>}
          style={{
            height: '28px',
            textTransform: 'uppercase',
            backgroundColor: '#D33115'
          }}
        />
        {liveStreamSocket.eye ? (
          <ButtonInherit
            color={buttonColor.backgroundColor}
            label={
              <Typography className={classes.textEye}>
                <i
                  className="fa-regular fa-eye"
                  style={{ marginRight: '6px', fontSize: '12px' }}
                ></i>
                {liveStreamSocket.eye}
              </Typography>
            }
            style={{
              height: '28px',
              backgroundColor: '#666666'
            }}
          />
        ) : null}
      </Box>
    );
  };

  const handleClose = () => {
    setOpen(false);
    setMediaSelected(null);
    if (typeMomentVideo === 'moment-search') {
      history.push(`${currentUrl}?q=${keyword}`);
    } else if (typePreview === 'preview_live') {
      history.push(
        `/live/detail/${postMedia?.id}?host=${postMedia?.account?.username}`
      );
    } else if (postSaved) {
      history.push('/saved');
    } else {
      history.push(currentUrl);
    }

    if (postMedia?.post_type !== 'moment' || type === 'media_detail') {
      setPostMedia(null);
    }

    if (
      match.path !== PATHS.MOMENT &&
      match.path !== PATHS.MOMENT_TAG &&
      match.path !== PATHS.MOMENT_SEARCH
    ) {
      setIndexMedia(currentMedia);
    }
    if (match.path === PATHS.MOMENT_TAG || match.path === PATHS.MOMENT_SEARCH) {
      setPostMedia(listMoments[currentMedia]);
    }
    setIsLoadMore && setIsLoadMore(false);
  };

  const handleNextMedia = type => {
    let medias = post?.media_attachments;
    if (type === 'up') {
      setMediaSelected(medias[indexMedia + 1].id);
      setIndexMedia(indexMedia + 1);
    } else if (type === 'down') {
      setMediaSelected(medias[indexMedia - 1].id);
      setIndexMedia(indexMedia - 1);
    }
  };

  const handleNextMoment = type => {
    if (match.path === PATHS.MOMENT) {
      let element = document.getElementById(
        listMoments[type === 'up' ? indexMedia + 1 : indexMedia - 1]?.id
      ) as any;
      element?.scrollIntoView();
    }
    let medias = post?.media_attachments;
    if (type === 'up') {
      if (
        match.path === PATHS.MOMENT ||
        match.path === PATHS.MOMENT_TAG ||
        match.path === PATHS.MOMENT_SEARCH
      ) {
        setIndexMedia(indexMedia + 1);
      } else if (
        match.path === PATHS.MOMENT_USER ||
        match.path === PATHS.HOME ||
        match.path === PATHS.PAGE_HASHTAG ||
        typeMomentVideo === 'suggestMoment'
      ) {
        setMediaSelected(listMoments[indexMedia + 1].id);
        setIndexMedia(indexMedia + 1);
      } else {
        setMediaSelected(medias[indexMedia + 1].id);
        setIndexMedia(indexMedia + 1);
      }
    } else if (type === 'down') {
      if (
        match.path === PATHS.MOMENT ||
        match.path === PATHS.MOMENT_TAG ||
        match.path === PATHS.MOMENT_SEARCH
      ) {
        setIndexMedia(indexMedia - 1);
      } else if (
        match.path === PATHS.MOMENT_USER ||
        match.path === PATHS.HOME ||
        match.path === PATHS.PAGE_HASHTAG ||
        typeMomentVideo === 'suggestMoment'
      ) {
        setMediaSelected(listMoments[indexMedia - 1].id);
        setIndexMedia(indexMedia - 1);
      } else {
        setMediaSelected(medias[indexMedia - 1].id);
        setIndexMedia(indexMedia - 1);
      }
    }
  };
  const listIcons = [
    {
      key: 'iconPlus',
      icon: 'far fa-search-plus',
      type: 'zoomIn'
    },
    {
      key: 'iconMinus',
      icon: 'far fa-search-minus',
      type: 'zoomOut'
    },
    {
      key: 'iconFullScreen',
      icon: isFullScreen ? 'fas fa-compress-alt' : 'fas fa-expand-alt',
      action: () => setIsFullScreen(prev => !prev)
    }
  ];

  function myKeyPress(e) {
    if (postMedia?.post_type === 'moment') {
      let medias = post?.media_attachments;
      if (e.keyCode === 38 && indexMedia >= 1) {
        if (match.path === PATHS.MOMENT) {
          setIndexMedia(indexMedia - 1);
        } else {
          setMediaSelected(medias[indexMedia - 1].id);
          setIndexMedia(indexMedia - 1);
        }
      } else if (e.keyCode === 40) {
        if (match.path === PATHS.MOMENT) {
          setIndexMedia(indexMedia + 1);
        } else {
          setMediaSelected(medias[indexMedia + 1].id);
          setIndexMedia(indexMedia + 1);
        }
      }
    } else {
      let medias = post?.media_attachments;
      if (medias.length >= 2) {
        if (e.keyCode === 38 && indexMedia >= 1) {
          setMediaSelected(medias[indexMedia - 1].id);
          setIndexMedia(indexMedia - 1);
        } else if (e.keyCode === 40 && indexMedia + 1 < medias.length) {
          setMediaSelected(medias[indexMedia + 1].id);
          setIndexMedia(indexMedia + 1);
        }
      }
    }
  }

  React.useEffect(() => {
    let params: any = match.params;
    if (match.path === PATHS.MARKETPLACE_SHOP) {
      const url = `${match?.url}?page_id=${pageInfo.id}`;
      setCurrentUrl(url);
    } else if (params) {
      setCurrentUrl(match?.url);
      setOpen(true);
    }
  }, [JSON.stringify(match.params)]);

  React.useEffect(() => {
    if (typePreview === 'preview_live') {
      dispatch(addLiveStreamSocket(post));
      if (post?.page?.id) {
        subStreaming(post?.id, meInfo?.id, post.page.id);
      } else {
        subStreaming(post?.id, meInfo?.id, null);
      }
      newPeopleJoin(post?.id, {
        userId: meInfo?.id,
        display_name: meInfo?.display_name
      });
    }

    return () => {
      if (typePreview === 'preview_live') {
        if (post?.page?.id) {
          unSubStreaming(post.id, meInfo?.id, post.page.id);
        } else {
          unSubStreaming(post.id, meInfo?.id, null);
        }
        dispatch(resetLiveStreamSocket());
      }
    };
  }, [typePreview]);

  React.useEffect(() => {
    if (postMedia?.id && open === true) {
      if (post?.id && !typeLive && !postSaved && typePreview) {
        if (typePreview === 'preview_live') {
          history.push(
            `?host=${
              post?.page?.username
                ? post?.page?.username
                : post?.page?.title
                ? post?.page?.title
                : post?.account?.username
            }&postMediaId=${post?.id}&mediaId=${
              postMedia.media_attachments[0].id
            }`
          );
        } else {
          history.push(
            `?postMediaId=${post?.id}&mediaId=${postMedia.media_attachments[0].id}`
          );
        }
      } else if (typeLive) {
        history.push(
          `?host=${
            post?.page?.username
              ? post?.page?.username
              : post?.page?.title
              ? post?.page?.title
              : post?.account?.username
          }&postMediaId=${post?.id}&mediaId=${
            postMedia.media_attachments[0].id
          }`
        );
      } else if (typeMomentVideo === 'moment-search') {
        history.push(
          `?q=${keyword}&mediaId=${postMedia.media_attachments[0].id}`
        );
      } else if (type === 'album') {
        if (groupSelected) {
          history.push(
            `?group_selected=${groupSelected}&album_selected=${albumSelected?.id}&mediaId=${postMedia.media_attachments[0].id}`
          );
        } else if (userSelected) {
          history.push(
            `?user_selected=${userSelected}&album_selected=${albumSelected?.id}&mediaId=${postMedia.media_attachments[0].id}`
          );
        } else if (pageSelected) {
          history.push(
            `?page_selected=${pageSelected}&album_selected=${albumSelected?.id}&mediaId=${postMedia.media_attachments[0].id}`
          );
        }
      } else if (postSaved) {
        history.push(
          `?mediaId=${postMedia.media_attachments[0].id}&type=post_saved`
        );
      } else {
        history.push(`?mediaId=${postMedia.media_attachments[0].id}`);
      }
    }
  }, [JSON.stringify(postMedia)]);

  React.useEffect(() => {
    if (open && postMedia?.id) {
      if (
        match.path === PATHS.MOMENT_TAG ||
        match.path === PATHS.MOMENT_SEARCH
      ) {
        let indexPost = listMoments?.findIndex(
          (el: any) => el.id === postMedia.id
        );
        setCurrentMedia(indexPost);
      } else {
        let indexPost = listPostMoment?.findIndex(
          (el: any) => el.id === postMedia.id
        );
        setCurrentMedia(indexPost);
      }
    }
  }, []);

  React.useEffect(() => {
    const handlePageShow = event => {
      // Xử lý sự kiện khi trang được hiển thị lại
      setMutedVideo(true);
    };

    // Lắng nghe sự kiện pageshow
    window.addEventListener('pageshow', handlePageShow);

    // Loại bỏ lắng nghe sự kiện khi component unmount
    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  const renderBoxText = (item, text) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fff',
        position: 'fixed',
        bottom: '5px',
        marginLeft: '10px',
        width: '100%'
      }}
    >
      <i
        className="fa-regular fa-arrow-right-from-bracket"
        style={{
          borderRadius: '50%',
          padding: '4px',
          background: '#16182399',
          color: '#fff',
          fontSize: '10px',
          marginRight: '5px'
        }}
      ></i>
      <Typography>
        <span style={{ color: '#16182399' }}>{item?.display_name}</span>{' '}
        <span style={{ color: '#16182399' }}>{text}</span>
      </Typography>
    </Box>
  );

  const Controls = ({ zoomIn, zoomOut, centerView }) => {
    return (
      <>
        {typeMedia || typeLive
          ? null
          : ([
              PATHS.MOMENT,
              PATHS.MOMENT_TAG,
              PATHS.MOMENT_SEARCH,
              PATHS.MOMENT_USER
            ].includes(match.path) ||
            post?.media_attachments[0]?.status_media?.post_type === 'moment'
              ? []
              : post?.media_attachments[indexMedia]?.type === 'video' ||
                post?.media_attachments[0]?.frame
              ? [listIcons[2]]
              : listIcons
            )?.map((el: any) => (
              <IconButton
                key={el?.key}
                onClick={() => {
                  if (el.type === 'zoomIn') {
                    zoomIn();
                  } else if (el.type === 'zoomOut') {
                    zoomOut();
                  } else {
                    el.action();
                  }
                }}
                className={classes.styleIcon}
                disabled={el?.disabled}
                sx={{
                  ...el?.styleIcon,
                  backgroundColor: 'transparent'
                }}
              >
                <i
                  style={{
                    color: '#fff',
                    fontSize: 20
                  }}
                  className={el?.icon}
                ></i>
              </IconButton>
            ))}
      </>
    );
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        onKeyDown={myKeyPress}
      >
        <div
          className={classes.root}
          style={{ flexDirection: isMobile ? 'row' : 'column' }}
        >
          <div
            className={classes.divLeft}
            style={{
              width: !isMobile
                ? '100%'
                : isFullScreen
                ? '100%'
                : postMedia?.post_type === 'moment'
                ? 'calc(100% - 544px)'
                : 'calc(100% - 390px)'
            }}
          >
            <div className={classes.divCloseButton}>
              <Tooltip title="Nhấn ESC để đóng">
                <IconButton
                  onClick={() => {
                    handleClose();
                    if (typePreview === 'preview_live') {
                      if (post?.page?.id) {
                        unSubStreaming(post?.id, meInfo?.id, post.page.id);
                      } else {
                        unSubStreaming(post?.id, meInfo?.id, null);
                      }
                      dispatch(resetLiveStreamSocket());
                    }
                  }}
                  style={{ color: '#fff' }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>
            {(typePreview === 'preview_live' || typeLive) && renderEyeLive()}
            <div
              style={{
                width: '100%',
                position: typeLive ? 'relative' : 'static'
              }}
            >
              {postMedia?.media_attachments?.length ? (
                <>
                  <MediaPreview
                    videoRef={videoRef}
                    indexMedia={indexMedia}
                    postMedia={postMedia}
                    isFullScreen={isFullScreen}
                    medias={postMedia?.media_attachments}
                    typeLive={typeLive}
                    mutedVideo={mutedVideo}
                    setMutedVideo={setMutedVideo}
                    handleClose={handleClose}
                    controls={Controls}
                  />
                </>
              ) : null}
              {typeLive && (
                <Box className={classes.emojiLive}>
                  <div
                    className="emoji"
                    onClick={() => {
                      handleClickReactEmoji(EnumEmojiReactType.like);
                      handleAnimationReaction('like');
                    }}
                  >
                    {animationReact === 'like' ? (
                      <div className="floating-icon-wrapper">
                        <img
                          src={likeStaticUrl}
                          alt="like"
                          style={{ width: '40px', zIndex: 10 }}
                        />
                      </div>
                    ) : null}
                    <div style={{ position: 'relative' }}>
                      {spanTextEmoji('Thích')}
                      <div
                        style={{
                          overflow: 'hidden',
                          height: '50px',
                          width: '50px',
                          margin: '5px -2px 3px 5px'
                        }}
                      >
                        <div style={{ height: '100%', display: 'flex' }}>
                          <div
                            style={{
                              display: 'inline-block',
                              height: '50px',
                              width: '50px',
                              transform: 'rotate(-90deg)'
                            }}
                          >
                            <div className="emoji-like fly-up"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="emoji"
                    onClick={() => {
                      handleClickReactEmoji(EnumEmojiReactType.love);
                      handleAnimationReaction('love');
                    }}
                    style={{ width: '68px', height: '68px' }}
                  >
                    {animationReact === 'love' ? (
                      <div className="floating-icon-wrapper">
                        <img
                          src={loveStaticUrl}
                          alt="love"
                          style={{ width: '40px' }}
                        />
                      </div>
                    ) : null}
                    <div style={{ position: 'relative' }}>
                      {spanTextEmoji('Yêu Thích')}
                      <div
                        style={{
                          overflow: 'hidden',
                          height: '80px',
                          width: '80px'
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            height: '80px',
                            width: '80px',
                            transform: 'rotate(-90deg) translateX(5px)'
                          }}
                        >
                          <div className="emoji-love"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="emoji"
                    onClick={() => {
                      handleClickReactEmoji(EnumEmojiReactType.yay);
                      handleAnimationReaction('yay');
                    }}
                  >
                    {animationReact === 'yay' ? (
                      <div className="floating-icon-wrapper">
                        <img
                          src={yayStaticUrl}
                          alt="yay"
                          style={{ width: '40px' }}
                        />
                      </div>
                    ) : null}
                    <div style={{ position: 'relative' }}>
                      {spanTextEmoji('Tự Hào')}
                      <div
                        style={{
                          overflow: 'hidden',
                          height: '90px',
                          width: '90px',
                          marginBottom: '-8px',
                          marginLeft: '-12px',
                          marginRight: '-12px'
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            height: '90px',
                            width: '90px',
                            transform: 'rotate(-90deg)'
                          }}
                        >
                          <div className="emoji-yay"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="emoji"
                    onClick={() => {
                      handleClickReactEmoji(EnumEmojiReactType.haha);
                      handleAnimationReaction('haha');
                    }}
                  >
                    {animationReact === 'haha' ? (
                      <div className="floating-icon-wrapper">
                        <img
                          src={hahaStaticUrl}
                          alt="haha"
                          style={{ width: '40px' }}
                        />
                      </div>
                    ) : null}
                    <div style={{ position: 'relative' }}>
                      {spanTextEmoji('Haha')}
                      <div
                        style={{
                          overflow: 'hidden',
                          height: '65px',
                          width: '65px',
                          margin: '0px -4px -3px'
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            height: '65px',
                            width: '65px',
                            transform: 'rotate(-90deg) translateX(1px)'
                          }}
                        >
                          <div className="emoji-haha"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="emoji"
                    onClick={() => {
                      handleClickReactEmoji(EnumEmojiReactType.wow);
                      handleAnimationReaction('wow');
                    }}
                  >
                    {animationReact === 'wow' ? (
                      <div className="floating-icon-wrapper">
                        <img
                          src={wowStaticUrl}
                          alt="wow"
                          style={{ width: '40px' }}
                        />
                      </div>
                    ) : null}
                    <div style={{ position: 'relative' }}>
                      {spanTextEmoji('Bất ngờ')}
                      <div
                        style={{
                          overflow: 'hidden',
                          height: '56px',
                          width: '56px',
                          marginBottom: '0px'
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            height: '56px',
                            width: '56px',
                            transform: 'rotate(-90deg)  translateX(3px)'
                          }}
                        >
                          <div className="emoji-wow"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="emoji"
                    onClick={() => {
                      handleClickReactEmoji(EnumEmojiReactType.sad);
                      handleAnimationReaction('sad');
                    }}
                  >
                    {animationReact === 'sad' ? (
                      <div className="floating-icon-wrapper">
                        <img
                          src={sadStaticUrl}
                          alt="sad"
                          style={{ width: '40px' }}
                        />
                      </div>
                    ) : null}
                    <div style={{ position: 'relative' }}>
                      {spanTextEmoji('Buồn')}
                      <div
                        style={{
                          overflow: 'hidden',
                          height: '56px',
                          width: '55px'
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            height: '56px',
                            width: '56px',
                            transform: 'rotate(-90deg) translateX(4px)'
                          }}
                        >
                          <div className="emoji-sad"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="emoji"
                    onClick={() => {
                      handleClickReactEmoji(EnumEmojiReactType.angry);
                      handleAnimationReaction('angry');
                    }}
                  >
                    {animationReact === 'angry' ? (
                      <div className="floating-icon-wrapper">
                        <img
                          src={angryStaticUrl}
                          alt="angry"
                          style={{ width: '40px' }}
                        />
                      </div>
                    ) : null}
                    <div style={{ position: 'relative' }}>
                      {spanTextEmoji('Phẫn Nộ')}
                      <div
                        style={{
                          overflow: 'hidden',
                          height: '52px',
                          width: '52px',
                          margin: '0px 3px 2px 0px '
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            height: '52px',
                            width: '52px',
                            transform: 'rotate(-90deg) translateX(3px)'
                          }}
                        >
                          <div className="emoji-angry"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              )}
            </div>
            {(post?.media_attachments.length ||
              postMedia?.media_attachments.length) === 1 ? null : (
              <div className={classes.divActionButton}>
                {indexMedia ? (
                  <div
                    onClick={() => handleNextMedia('down')}
                    className={classes.buttonAngle}
                  >
                    <ExpandLessIcon fontSize="large" />
                  </div>
                ) : (
                  <div className={classes.buttonAngleDisabled}></div>
                )}
                {indexMedia + 1 !==
                (post
                  ? post?.media_attachments.length
                  : postMedia?.media_attachments.length) ? (
                  <div
                    onClick={() => {
                      handleNextMedia('up');
                    }}
                    className={classes.buttonAngle}
                  >
                    <ExpandMoreIcon fontSize="large" />
                  </div>
                ) : (
                  <div className={classes.buttonAngleDisabled}></div>
                )}
              </div>
            )}
            {postMedia?.post_type === 'moment' &&
              !typeMedia &&
              match.path !== PATHS.PAGE_DETAIL && (
                <div className={classes.divActionButton}>
                  {indexMedia ? (
                    <div
                      onClick={() => handleNextMoment('down')}
                      className={classes.buttonAngle}
                    >
                      <ExpandLessIcon fontSize="large" />
                    </div>
                  ) : (
                    <div className={classes.buttonAngleDisabled}></div>
                  )}

                  {(
                    match.path === PATHS.MOMENT || isAddMoment === undefined
                      ? indexMedia + 1 !== listMoments?.length
                      : indexMedia + 1 !== listPostMoment?.length && isAddMoment
                  ) ? (
                    <div
                      onClick={() => handleNextMoment('up')}
                      className={classes.buttonAngle}
                    >
                      <ExpandMoreIcon fontSize="large" />
                    </div>
                  ) : (
                    <div className={classes.buttonAngleDisabled}></div>
                  )}
                </div>
              )}
          </div>
          {postMedia?.id ? (
            <PerfectScrollbar
              options={{ suppressScrollX: true }}
              style={{
                width: !isMobile
                  ? '100%'
                  : isFullScreen
                  ? 0
                  : postMedia?.post_type === 'moment'
                  ? 544
                  : typeLive
                  ? '380px'
                  : 390,
                height: isMobile ? '100vh' : '100%',
                maxHeight: liveStreamSocket?.userJoin ? '900px' : '100%',
                position: 'static'
              }}
            >
              <SocialPostInformation
                key={`${postMedia.id}-postInformation`}
                post={postMedia ?? post}
                setPostData={setPostMedia}
                type="previewMedia"
              />
              <SocialContent
                key={`${postMedia.id}-socialContent`}
                post={postMedia}
                setPostData={setPostMedia}
                type="previewMedia"
              />
              <SocialPostAction
                type="previewMedia"
                key={`${postMedia.id}-socialAction`}
                post={liveStreamSocket?.id ? liveStreamSocket : postMedia}
                setPostData={setPostMedia}
                typeLive={typeLive}
              />
              {post.isTyping && (
                <div id="typing" className={classes.typing}>
                  <div className={`typing__dot ${classes.typingDot}`}></div>
                  <div className={`typing__dot ${classes.typingDot}`}></div>
                  <div className={`typing__dot ${classes.typingDot}`}></div>
                  <Typography className={classes.typingText} variant="caption">
                    Ai đó đang viết bình luận ...
                  </Typography>
                </div>
              )}

              {liveStreamSocket?.userJoin
                ? renderBoxText(liveStreamSocket?.userJoin, 'đã tham gia')
                : null}
              {liveStreamSocket?.follow
                ? renderBoxText(liveStreamSocket?.follow, 'đã theo dõi')
                : null}
            </PerfectScrollbar>
          ) : null}
        </div>
      </Dialog>
    </div>
  );
}
