import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getDetailPageApi,
  getListPagesApi,
  getSettingContentPageApi
} from 'src/apis/socialPages.api';
import * as types from 'src/constants/store/pageSocial';
import * as actions from 'src/store/action/socialPageAction';
const typesSetting: any = types;

export const getPageDetailSagas = function* ({ payload }) {
  try {
    const { id } = payload;
    let response = yield call(getDetailPageApi, id);
    if (response.status === 200) {
      yield put(
        actions.getPageDetailSuccess({
          ...response.data,
          statusApi: response.status
        })
      );
    }
  } catch (error: any) {
    yield put(actions.getPageDetailError(error.response));
  }
};

export const getListMyPageSagas = function* ({ payload }) {
  try {
    const params = payload.params;
    const type = params?.type;
    if (type) {
      delete params.type;
    }

    yield put(actions.isLoadingListPage(true));
    let response = yield call(getListPagesApi, payload.params);
    if (response.status === 200) {
      yield put(actions.isLoadingListPage(false));
      if (response.data < payload.params.limit) {
        yield put(actions.updateHasMore(false));
      }
      if (type === 'filter') {
        yield put(actions.filterListMyPage(response.data));
      } else {
        yield put(actions.getListMyPageSuccess(response.data));
      }
    }
  } catch (error: any) {}
};

export const getSettingPageSagas = function* ({ payload }) {
  const { id } = payload;

  try {
    let response = yield call(getSettingContentPageApi, id);
    if (response.status === 200) {
      yield put(actions.getSettingPageSuccess(response.data));
    }
  } catch (error: any) {}
};

export function* watchPageSocialAsync() {
  yield takeEvery(typesSetting.GET_PAGE_DETAIL_REQ, getPageDetailSagas);
  yield takeEvery(typesSetting.GET_LIST_MY_PAGE_REQ, getListMyPageSagas);
  yield takeEvery(typesSetting.GET_SETTING_PAGE_REQ, getSettingPageSagas);
}
