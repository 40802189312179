import * as types from 'src/constants/store/searchGlobalSocial';export const keywordSearchGlobalReq = (keyword: string) => ({
  type: types.KEYWORD_SEARCH,
  payload: keyword
});

// export const handleSearchGlobalReq = (params: any) => ({
//   type: types.HANDLE_SEARCH,
//   payload: params
// });

export const resetSearchGlobal = () => ({
  type: types.RESET_SEARCH
});

export const setAnchorElDialogGlobalSearch = anchorEl => ({
  type: types.ANCHOREL_DIALOG_SEARCH,
  payload: anchorEl
});

export const searchFilterPostUserByCreateAt = (data: any) => ({
  type: types.SEARCH_FILTER_POST_USER,
  payload: data
});

export const searchFilterPostGroup = (data: any) => ({
  type: types.SEARCH_FILTER_POST_GROUP,
  payload: data
});
