import { makeStyles } from '@mui/styles';
import * as React from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  createUpdateGrowApi,
  getListHostOfGrow
} from 'src/apis/socialGrow.api';
import { searchFriendUser } from 'src/apis/socialUser';
import { RegText, convertISO8086toLDate } from 'src/common/string';
import CustomPrompt from 'src/components/CustomPrompt';
import { privacyOptions } from 'src/constants/common';
import {
  getGrowDetailRequest,
  growPreview
} from 'src/store/action/socialGrowAction';
import BasicProjectInfomation from './StepCUProject/BasicProjectInfomation';
import DescriptionProject from './StepCUProject/DescriptionProject';
import OtherInformation from './StepCUProject/OtherInformation';
import ProjectInformation from './StepCUProject/ProjectInformation';
import ProjectMedia from './StepCUProject/ProjectMedia';

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: '5px 10px 20px 10px',
    backgroundColor: theme.palette.background.primary,
    borderRadius: 10,
    width: '100%'
  }
}));

const GrowCreateUpdate = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const growInfo = useSelector((state: any) => state.growReducer.info);
  const {
    infoUpdate,
    createGrow,
    setCreateGrow,
    updateGrow,
    setUpdateGrow,
    idUpdate,
    organization,
    setCheckCreateUpdate,
    categories,
    fetchCategories,
    setCheckStep,
    activeStep,
    setNoti,
    setOpenSnackbar,
    isUpdateGrow
  } = props;

  const [currentHost, setCurrentHost] = React.useState<any>([]);
  const [hostGrowAdd, setHostGrowAdd] = React.useState([] as any);
  const [locationSelected, setLocationSelected] = React.useState<any>(null);
  const [imageBanner, setImageBanner] = React.useState<any>([]);
  const matchesCreate = useMediaQuery('(max-width:1000px)');
  const [checkDuration, setCheckDuration] = React.useState<any>(0);
  const [notificationLeave, setNotificationLeave] =
    React.useState<boolean>(false);
  const [openDialogUpdateLocation, setOpenDialogUpdateLocation] =
    React.useState<any>(false);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .test(
        'len',
        'Tên người dùng có ít hơn 50 ký tự',
        (val: any) =>
          val === '' || val === null || val === undefined || val?.length < 250
      )
      .matches(RegText, 'Tên người dùng không được chứa ký tự đặc biệt')
      .max(50, 'Tên người dùng có ít hơn 50 ký tự')
      .nullable(),

    introduction_video: Yup.mixed()
      .test('fileSize', 'Video không được quá 3 phút!', (value: any) => {
        if (!value) {
          return true;
        }
        if (value && value.meta) {
          return value?.meta?.original?.duration <= 180;
        } else {
          return value && value?.file?.file?.meta?.original?.duration <= 180;
        }
      })
      .nullable(),

    target_value: Yup.number()
      .integer('Giá tiền phải là số,không chứa ký tự đặc biệt')
      .positive('Số tiền phải lớn hơn 0')
      .min(1000, 'Số tiền thấp nhất là 1000 VND')
      .max(100000000000, 'Số tiền không được vượt quá 100 tỷ đồng.')
      .typeError('Số tiền nhập không hợp lệ.Vui lòng nhập lại!!')
      .nullable(),
    phone_number: Yup.string()
      .matches(phoneRegExp, 'Số điện thoại không hợp lệ. Vui lòng nhập lại!!')
      .typeError('Số điện thoại không hợp lệ.Vui lòng nhập lại!!')
      .min(10, 'Số điện thoại phải bằng 10 số')
      .max(10, 'Số điện thoại phải bằng 10 số')
      .nullable(),
    start_date: Yup.date().min(
      new Date(Date.now() + 6 * 60 * 1000),
      'Ngày bắt đầu phải lớn hơn ngày hôm nay'
    ),
    due_date: Yup.date().min(
      Yup.ref('start_date'),
      'Ngày kết thúc phải lớn hơn hoặc bằng ngày bắt đầu'
    ),
    banner: Yup.mixed().test(
      'imageDimensions',
      'Kích thước ảnh không hợp lệ',
      value => {
        if (!value) {
          return true;
        }

        if (value?.file?.file) {
          const image: any = new Image();
          image.src = value?.file?.file
            ? URL.createObjectURL(value.file.file)
            : null;

          return new Promise(resolve => {
            image.onload = () => {
              const width = image.width;
              const height = image.height;
              if (width >= 450 && height >= 150) {
                resolve(true);
              } else {
                resolve(false);
              }
            };
          });
        }

        return true;
      }
    )
  });

  React.useEffect(() => {
    if (growInfo?.id) {
      fetchInviteHostGrow(growInfo.id);
    }
  }, [JSON.stringify(growInfo)]);

  const handleCreateUpdateGrow = async (type: any) => {
    try {
      let response = await createUpdateGrowApi(
        {
          banner: formik.values.banner
            ? {
                file: formik.values.banner?.file?.id
                  ? formik.values.banner?.file?.id
                  : type === 'create'
                  ? formik.values.banner
                  : formik.values.banner.id,
                id:
                  type === 'create'
                    ? formik.values.banner?.id ?? null
                    : formik?.values?.banner?.file?.id ??
                      formik.values.banner?.id,
                show_url: formik.values.banner.show_url
              }
            : null,
          title: formik.values.title || null,
          start_date: !infoUpdate
            ? `${convertISO8086toLDate(formik.values.start_date)}`
            : null,
          due_date: !infoUpdate
            ? `${convertISO8086toLDate(formik.values.due_date)}`
            : null,
          page_owner_id:
            type === 'create' && organization?.page_owner_id
              ? organization.page_owner_id
              : null,
          page_id:
            type === 'create' && organization?.page_owner_id
              ? organization.page_owner_id
              : null,
          description_project: formik.values.description_project,
          description_company: formik.values.description_company,
          plan: formik.values.plan,
          target_value: formik.values.target_value,
          category_id: formik.values.category_id.id,
          commitment_form: formik.values.commitment_form
            ? {
                file: formik.values.commitment_form?.file?.file
                  ? formik.values.commitment_form?.file?.file
                  : formik.values.commitment_form,
                id:
                  formik.values.commitment_form?.id ??
                  formik.values.commitment_form?.file.id,
                show_url: formik.values.commitment_form.show_url
              }
            : null,
          business_license: formik.values.business_license
            ? {
                file: formik.values.business_license?.file?.file
                  ? formik.values.business_license?.file?.file
                  : formik.values.business_license,
                id:
                  formik.values.business_license?.id ??
                  formik.values.business_license?.file.id,
                show_url: formik.values.business_license.show_url
              }
            : null,
          pitchdeck: formik.values.pitchdeck
            ? {
                file: formik.values.pitchdeck?.file?.file
                  ? formik.values.pitchdeck?.file?.file
                  : formik.values.pitchdeck,
                id:
                  formik.values.pitchdeck?.id ??
                  formik.values.pitchdeck?.file.id,
                show_url: formik.values.pitchdeck.show_url
              }
            : null,

          introduction_video: formik.values.introduction_video
            ? {
                file: formik.values.introduction_video?.file?.file
                  ? formik.values.introduction_video?.file?.file
                  : formik.values.introduction_video,
                id:
                  formik.values.introduction_video?.id ??
                  formik.values?.introduction_video?.file?.id,
                show_url: formik.values.introduction_video.show_url
              }
            : null,
          location: formik.values.location || null,
          address: formik.values.address || null,
          phone_number: formik.values.phone_number,
          refund_policy: formik.values.refund_policy,
          risks: formik.values.risks,
          visibility: formik.values.visibility.id,
          project_host_added:
            formik.values.project_host_added
              .filter(
                (item: any) =>
                  !currentHost.some(
                    (elCurrent: any) => elCurrent.id === item.id
                  )
              )
              .map((el: any) => el?.id) || null,
          project_host_removed:
            formik.values.project_host_removed.map((el: any) => el?.id) || null
        },
        type
      );

      if (response.status === 200) {
        setNotificationLeave(false);
        if (createGrow) {
          setOpenSnackbar(true);
          setNoti({
            code: 200,
            message:
              'Bạn đã gửi dự án thành công, chúng tôi sẽ xem xét dự án và sớm gửi thông báo đến bạn!'
          });
          setCreateGrow(false);
          history.push(`/grow/${response.data.id}/about`);
        }
        if (updateGrow) {
          setUpdateGrow(false);
          dispatch(getGrowDetailRequest(type));
          history.push(`/grow/${type}/about`);
          setOpenSnackbar(true);
          setNoti({
            code: 200,
            message:
              'Dự án của bạn đã được gửi phê duyệt lại. Vui lòng chờ thông báo kết quả.'
          });
        }
      }
    } catch (error) {
      setOpenSnackbar(true);
      setNoti({
        code: 400,
        message:
          type === 'create'
            ? 'Tạo dự án thất bại. Vui lòng thử lại!'
            : 'Cập nhập dự án thất bại. Vui lòng thử lại!'
      });
      if (type === 'create') {
        setCreateGrow(false);
      } else {
        setUpdateGrow(false);
      }
    }
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      title: '',
      start_date: null,
      due_date: null as any,
      banner: null as any,
      location: null,
      description_project: null as any,
      description_company: null as any,
      category_id: null as any,
      plan: null as any,
      target_value: null as any,
      project_host_added: [],
      project_host_removed: [],
      commitment_form: null as any,
      business_license: null as any,
      pitchdeck: null as any,
      introduction_video: null as any,
      phone_number: null as any,
      refund_policy: null as any,
      risks: null as any,
      address: null as any,
      visibility: privacyOptions[0]
    },
    onSubmit: values => {
      try {
        if (createGrow && values.title) {
          handleCreateUpdateGrow('create');
        }
        if (updateGrow && idUpdate) {
          handleCreateUpdateGrow(idUpdate);
        }
      } catch (error) {
        console.log(error);
      }
    }
  });
  React.useEffect(() => {
    if (Object.keys(growInfo).length > 0 && growInfo.id !== '') {
      let tempData: any = {
        title: growInfo?.title,
        start_date: growInfo?.start_date
          ? convertISO8601toTime(growInfo.start_date, 'data_object')
          : null,
        due_date: growInfo?.due_date
          ? convertISO8601toTime(growInfo.due_date, 'data_object')
          : null,
        banner: growInfo?.banner ? growInfo.banner : null,
        description_project: growInfo?.description_project,
        description_company: growInfo?.description_company,
        plan: growInfo?.plan,
        target_value: growInfo?.target_value,
        address: growInfo?.address,
        category_id: growInfo?.category,
        project_host_added: growInfo?.project_host_added || [],
        project_host_removed: growInfo?.project_host_removed || [],
        commitment_form: growInfo?.commitment_form
          ? growInfo?.commitment_form
          : null,
        business_license: growInfo?.business_license
          ? growInfo?.business_license
          : null,
        pitchdeck: growInfo?.pitchdeck ? growInfo?.pitchdeck : null,
        // identity_card_back: growInfo?.identity_card_back
        //   ? growInfo?.identity_card_back
        //   : null,
        // identity_card_front: growInfo?.identity_card_front
        //   ? growInfo?.identity_card_front
        //   : null,
        // identity_verification_video: formik.values?.identity_verification_video
        //   ? formik.values?.identity_verification_video
        //   : null,
        introduction_video: growInfo?.introduction_video
          ? growInfo?.introduction_video
          : formik?.values?.introduction_video,
        phone_number: growInfo?.phone_number,
        refund_policy: growInfo?.refund_policy,
        risks: growInfo?.risks,
        visibility: privacyOptions?.find(
          (el: any) => el.id === growInfo?.visibility
        ),
        location: growInfo?.location ? growInfo?.location : null
      };
      formik.setValues(tempData);
    }
  }, [JSON.stringify(growInfo)]);

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <BasicProjectInfomation
            formik={formik}
            infoUpdate={infoUpdate}
            categories={categories}
            fetchCategories={fetchCategories}
            setCheckStep={setCheckStep}
            hostGrowAdd={hostGrowAdd}
            growInfo={growInfo}
            imageBanner={imageBanner}
            setImageBanner={setImageBanner}
            fetchHostGrow={fetchHostGrow}
            isUpdateGrow={isUpdateGrow}
            currentListHostProject={currentHost}
            setNotificationLeave={setNotificationLeave}
          />
        );
      case 1:
        return (
          <DescriptionProject
            formik={formik}
            infoUpdate={infoUpdate}
            setCheckStep={setCheckStep}
            setNotificationLeave={setNotificationLeave}
          />
        );

      case 2:
        return (
          <OtherInformation
            setCheckDuration={setCheckDuration}
            formik={formik}
            infoUpdate={infoUpdate}
            setCheckStep={setCheckStep}
            growInfo={growInfo}
            locationSelected={locationSelected}
            setLocationSelected={setLocationSelected}
            openDialogUpdateLocation={openDialogUpdateLocation}
            setOpenDialogUpdateLocation={setOpenDialogUpdateLocation}
            setNoti={setNoti}
            setOpenSnackbar={setOpenSnackbar}
            setNotificationLeave={setNotificationLeave}
          />
        );
      case 3:
        if (privacyOptions?.length) {
          return (
            <ProjectInformation
              formik={formik}
              infoUpdate={infoUpdate}
              setCheckStep={setCheckStep}
              privacyOptions={privacyOptions}
              setNotificationLeave={setNotificationLeave}
            />
          );
        }
        break;
      case 4:
        return (
          <ProjectMedia
            formik={formik}
            infoUpdate={infoUpdate}
            growInfo={growInfo}
            setNotificationLeave={setNotificationLeave}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  }

  const fetchInviteHostGrow = async (id: any) => {
    let response: any = await getListHostOfGrow(id, null);
    if (response.status === 200) {
      let dataDefault: any = response.data.map((el: any) => ({
        id: el?.target_account?.id,
        display_name: el?.target_account?.display_name,
        avatar: el?.target_account?.avatar_media?.show_url
          ? el?.target_account?.avatar_media?.show_url
          : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png',
        status: el?.status,
        description:
          el?.status === 'pending'
            ? 'Đang chờ'
            : el?.status === 'rejected'
            ? 'Đã từ chối'
            : el?.status === 'approved'
            ? 'Đã đồng ý'
            : null
      }));
      setCurrentHost(dataDefault);
      formik.setFieldValue('project_host_added', dataDefault);
    }
  };

  React.useEffect(() => {
    formik.handleSubmit();
  }, [createGrow, updateGrow, idUpdate]);

  React.useEffect(() => {
    if (!infoUpdate) {
      dispatch(
        growPreview({
          title: formik.values.title,
          start_date: formik.values.start_date,
          due_date: formik.values.due_date,
          banner: formik.values.banner?.file,
          description_project: formik.values.description_project,
          description_company: formik.values.description_company,
          plan: formik.values.plan,
          location: formik.values.location,
          target_value: formik.values.target_value,
          category_id: formik.values.category_id,
          project_host_added: formik.values.project_host_added,
          project_host_removed: formik.values.project_host_removed,
          commitment_form: formik.values.commitment_form?.file,
          business_license: formik.values.business_license?.file,
          pitchdeck: formik.values.pitchdeck?.file,
          introduction_video: formik.values.introduction_video?.file,
          pageInfo: organization?.page_owner_id ? { ...organization } : null,
          phone_number: formik.values.phone_number,
          refund_policy: formik.values.refund_policy,
          risks: formik.values.risks,
          address: formik.values.address,
          visibility: formik.values.visibility
        })
      );
    }
    if (setCheckCreateUpdate) {
      if (
        formik.values.title &&
        formik.values.start_date &&
        formik.values.due_date &&
        formik.values.banner &&
        formik.values.description_project &&
        formik.values.description_company &&
        formik.values.plan &&
        formik.values.target_value &&
        formik.values.category_id &&
        formik.values.commitment_form &&
        // formik.values.business_license &&
        // formik.values.identity_card_back &&
        formik.values.pitchdeck &&
        // formik.values.identity_card_front &&
        // formik.values.identity_verification_video &&
        formik.values.introduction_video &&
        formik.values.phone_number &&
        formik.values.refund_policy &&
        formik.values.risks &&
        formik.values.visibility &&
        Object.keys(formik.errors).length === 0
      ) {
        setCheckCreateUpdate(true);
      } else {
        setCheckCreateUpdate(false);
      }
    }
  }, [
    JSON.stringify(formik.values),
    JSON.stringify(formik.errors),
    JSON.stringify(organization)
  ]);

  const convertISO8601toTime = (ISOTime: any, convertTo: any) => {
    var d = new Date(ISOTime),
      year = d.getFullYear(),
      month = d.getMonth() + 1,
      day = d.getDate(),
      hour = d.getHours(),
      minute = d.getMinutes();
    let secondTime: any = hour * 3600 + minute * 60;
    switch (convertTo) {
      case 'second':
        return {
          id: secondTime,
          value: secondTime,
          label: `${
            (hour < 10 ? '0' + hour + ':' : hour + ':') +
            (minute < 10 ? '0' + minute : minute)
          }`
        };
      case 'data_object':
        return d;
      case 'date':
        return `${year}/${month}/${day}`;
      default:
        break;
    }
  };

  const fetchHostGrow = async (keyword: any) => {
    let response = await searchFriendUser(meInfo?.id, {
      keyword: keyword === '' ? ' ' : keyword
    });
    if (response.status === 200) {
      setHostGrowAdd(
        response.data?.data.map((el: any) => ({
          id: el.id,
          display_name: el.display_name,
          avatar: el.avatar_media?.show_url
            ? el.avatar_media?.show_url
            : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png',
          status: 'pending',
          description: 'Đang chờ'
        }))
      );
    }
  };
  const handleOnChangeWarning = (event: React.FormEvent) => {
    const formData: any = new FormData(event.currentTarget as HTMLFormElement);
    const values = Array.from(formData.values());
    const changedFields = values.filter(
      (value: any) => value.length || (value as File).size
    );
    setNotificationLeave(changedFields.length > 0);
  };
  return (
    <div
      className={classes.root}
      style={{
        width: infoUpdate || matchesCreate ? '100%' : '350px'
      }}
    >
      <CustomPrompt
        when={notificationLeave}
        navigate={path => {
          history.push(path);
        }}
        shouldBlockNavigation={() => {
          return notificationLeave;
        }}
        title={'Rời khỏi trang?'}
        content={'Thay đổi chưa lưu của bạn sẽ bị mất nếu bạn rời khỏi trang.'}
        optionDialog={2}
        labelButton={['Ở lại', 'Rời khỏi']}
      />
      <form onChange={handleOnChangeWarning}>{getStepContent(activeStep)}</form>
    </div>
  );
};

export default GrowCreateUpdate;
