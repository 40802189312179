import React from 'react';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

function CollapseText(props) {
  const { text, count, style, placement } = props;
  let newValue = text;
  try {
    if (count > 0) {
      newValue = text.slice(0, count) + '...';
    }
  } catch (error) {}

  return (
    <Tooltip title={text} placement={placement}>
      <p style={style}>{newValue}</p>
    </Tooltip>
  );
}

CollapseText.propTypes = {
  text: PropTypes.string,
  count: PropTypes.number,
  style: PropTypes.object,
  placement: PropTypes.string
};

export default CollapseText;
