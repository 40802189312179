import { useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ListButton from 'src/components/List/ListButton';
import { PATHS } from 'src/constants/paths';
const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      root: {
        height: '100%',
        position: 'fixed',
        backgroundColor: theme.palette.background.primary,
        boxShadow: '1px 0px 5px 0.4px rgb(0 0 0 / 10%)'
      },
      scroll: {
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        paddingBottom: '30px',
        '&:hover': {
          overflowY: 'auto'
        },
        '&:hover&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#bdbdbd',
          borderRadius: 10
        }
      }
    }),
  { index: 1 }
);

const HelperLeft = () => {
  const classes = useStyles();
  const history = useHistory();
  const match: any = useRouteMatch();
  const theme: any = useTheme();
  const [selectedIndex, setSelectedIndex] = React.useState<any>('');

  const listMenuHelp = [
    {
      id: 'use_emso',
      title: 'Sử dụng Emso',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i
          className="fa-solid fa-compass"
          style={{
            fontSize: '20px',
            backgroundColor:
              theme.palette.mode === 'light'
                ? 'rgb(220 214 214)'
                : 'rgb(84 84 84)',
            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
            padding: 10,
            borderRadius: '50%'
          }}
        ></i>
      ),
      action: () => {
        history.push('/user_emso/personal_page?helpref=hc_enav');
      },
      accordion: true,
      style: { height: 36, width: 36 },
      position: 'top',
      itemChildren: [
        {
          id: 'create_account',
          title: 'Cách tạo tài khoản',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-user"
            ></i>
          ),
          itemChildren: [],
          action: () => {
            history.push('/help_item/create_account?helpref=hc_enav');
          }
        },
        {
          id: 'create_user',
          title: 'Trang cá nhân của bạn',
          styleTitle: { fontWeight: '400', margin: '0px !important' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-user"
            ></i>
          ),
          itemChild: [
            {
              id: 'add',
              title: 'Thêm và chỉnh sửa thông tin trên trang cá nhân của bạn',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/add?helpref=hc_enav');
              }
            },
            {
              id: 'img',
              title: 'Ảnh đại diện và ảnh bìa của bạn',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/img?helpref=hc_enav');
              }
            },
            {
              id: 'share',
              title: 'Chia sẻ và quản lý bài viết trên trang cá nhân',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/share?helpref=hc_enav');
              }
            },
            {
              id: 'fix',
              title: 'Khắc phục sự cố ',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/fix?helpref=hc_enav');
              }
            }
          ],
          action: () => {
            history.push(`/help/create_user?helpref=hc_enav`);
          }
        },
        {
          id: 'interested',
          title: 'Thêm bạn bè',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-user-plus"
            ></i>
          ),
          itemChild: [
            {
              id: 'add_friend',
              title: 'Thêm bạn bè',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/add_friend?helpref=hc_enav');
              }
            },
            {
              id: 'friend_suggest',
              title: 'Những người bạn có thể biết',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/friend_suggest?helpref=hc_enav');
              }
            },
            {
              id: 'control_friend',
              title: 'Kiểm soát những người có thể kết bạn và theo dõi bạn',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/control_friend?helpref=hc_enav');
              }
            },
            {
              id: 'unfriend',
              title: 'Hủy kết bạn hoặc chặn ai đó',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/unfriend?helpref=hc_enav');
              }
            }
          ],
          action: () => {
            history.push(`/help/interested??helpref=hc_enav`);
          }
        },
        {
          id: 'home',
          title: 'Trang chủ của bạn',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-house"
            ></i>
          ),
          itemChild: [
            {
              id: 'action_home',
              title: 'Cách hoạt động của bảng tin',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/action_home?helpref=hc_enav');
              }
            },
            {
              id: 'control_home',
              title: 'Quản soát nội dung bạn nhìn thấy trong Bảng tin',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/control_home?helpref=hc_enav');
              }
            },
            {
              id: 'like_post',
              title: 'Thích và bày tỏ cảm xúc bài viết ',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/like_post?helpref=hc_enav');
              }
            },
            {
              id: 'search',
              title: 'Tìm kiếm',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/search?helpref=hc_enav');
              }
            },
            {
              id: 'celebrate',
              title: 'Kỷ niệm',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/celebrate?helpref=hc_enav');
              }
            }
          ],
          action: () => {
            history.push(`/help/interested??helpref=hc_enav`);
          }
        },
        {
          id: 'texting',
          title: 'Nhắn tin',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-messages"
            ></i>
          ),
          itemChild: [
            {
              id: 'send_message',
              title: 'Gửi tin nhắn',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/send_message?helpref=hc_enav');
              }
            },
            {
              id: 'control_message',
              title: 'Xem và quản lý tin nhắn',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/control_message?helpref=hc_enav');
              }
            },
            {
              id: 'report_message',
              title: 'Báo cáo tin nhắn ',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/report_message?helpref=hc_enav');
              }
            },
            {
              id: 'fix_message',
              title: 'Khắc phục sự cố',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/fix_message?helpref=hc_enav');
              }
            }
          ],
          action: () => {
            history.push(`/help/interested??helpref=hc_enav`);
          }
        },
        {
          id: 'picture_video',
          title: 'Ảnh và video',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-camera"
            ></i>
          ),
          itemChild: [
            {
              id: 'picture',
              title: 'Ảnh',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/picture?helpref=hc_enav');
              }
            },
            {
              id: 'video',
              title: 'Video',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/video?helpref=hc_enav');
              }
            },
            {
              id: 'live_stream',
              title: 'Phát trực tiếp',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/live_stream?helpref=hc_enav');
              }
            },
            {
              id: 'album',
              title: 'Album',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/album?helpref=hc_enav');
              }
            },
            {
              id: 'tag',
              title: 'Gắn thẻ',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/tag?helpref=hc_enav');
              }
            },
            {
              id: 'fix_picture_video',
              title: 'Khắc phục sự cố',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/fix_picture_video?helpref=hc_enav');
              }
            }
          ],
          action: () => {
            history.push(`/help/interested??helpref=hc_enav`);
          }
        },
        {
          id: 'watch',
          title: 'Video trên Watch',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-airplay"
            ></i>
          ),
          itemChild: [
            {
              id: 'send_watch',
              title: 'Xem các chương trình và video',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/send_watch?helpref=hc_enav');
              }
            },
            {
              id: 'fix_watch',
              title: 'Khắc phục sự cố',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/fix_watch?helpref=hc_enav');
              }
            }
          ],
          action: () => {
            history.push(`/help/interested??helpref=hc_enav`);
          }
        },
        {
          id: 'page',
          title: 'Trang',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-flag-swallowtail"
            ></i>
          ),
          itemChild: [
            {
              id: 'interact_page',
              title: 'Tương tác với Trang',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/interact_page?helpref=hc_enav');
              }
            },
            {
              id: 'create_page',
              title: 'Tạo và quản lý trang',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/create_page?helpref=hc_enav');
              }
            },
            {
              id: 'name',
              title: 'Tên và tên người dùng',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/name?helpref=hc_enav');
              }
            },
            {
              id: 'control_page',
              title: 'Quản lý phần Cài đặt Trang',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/control_page?helpref=hc_enav');
              }
            },
            {
              id: 'setting_page',
              title: 'Tuỳ chỉnh trang',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/setting_page?helpref=hc_enav');
              }
            },
            {
              id: 'post_page',
              title: 'Đăng',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/post_page?helpref=hc_enav');
              }
            },
            {
              id: 'message_page',
              title: 'Nhắn tin',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/message_page?helpref=hc_enav');
              }
            },
            {
              id: 'ban_page',
              title: 'Cấm và kiểm duyệt',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/ban_page?helpref=hc_enav');
              }
            },
            {
              id: 'fix_page',
              title: 'Khắc phục sự cố',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/fix_page?helpref=hc_enav');
              }
            }
          ],
          action: () => {
            history.push(`/help/interested??helpref=hc_enav`);
          }
        },
        {
          id: 'group',
          title: 'Nhóm',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-user-group"
            ></i>
          ),
          itemChild: [
            {
              id: 'join_group',
              title: 'Tham gia và chọn cài đặt',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/join_group?helpref=hc_enav');
              }
            },
            {
              id: 'post_group',
              title: 'Đăng, tham gia và quyền riêng tư',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/post_group?helpref=hc_enav');
              }
            },
            {
              id: 'creat_group',
              title: 'Tạo, tương tác và quản lý cài đặt',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/creat_group?helpref=hc_enav');
              }
            },
            {
              id: 'control_group',
              title: 'Quản lý mọi người và nội dung',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/control_group?helpref=hc_enav');
              }
            },
            {
              id: 'chat_group',
              title: 'Đoạn chat cộng đồng',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/chat_group?helpref=hc_enav');
              }
            },
            {
              id: 'fix_group',
              title: 'Khắc phục sự cố',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/fix_group?helpref=hc_enav');
              }
            }
          ],
          action: () => {
            history.push(`/help/interested??helpref=hc_enav`);
          }
        },
        {
          id: 'event',
          title: 'Sự kiện',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-regular fa-calendar-days"
            ></i>
          ),
          itemChild: [
            {
              id: 'create_event',
              title: 'Tạo và tuỳ chỉnh sự kiện',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/create_event?helpref=hc_enav');
              }
            },
            {
              id: 'control_event',
              title: 'Quản lý lời mời tham dự sự kiện',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/control_event?helpref=hc_enav');
              }
            },
            {
              id: 'join_event',
              title: 'Tìm và tham gia sự kiện',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/join_event?helpref=hc_enav');
              }
            }
          ],
          action: () => {
            history.push(`/help/interested??helpref=hc_enav`);
          }
        },
        {
          id: 'app_mobile',
          title: 'Ứng dụng Emso trên di động',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-mobile-screen-button"
            ></i>
          ),
          itemChild: [
            {
              id: 'app_mobile_android',
              title: 'Ứng dụng Android',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/app_mobile_android?helpref=hc_enav');
              }
            },
            {
              id: 'app_mobile_ios',
              title: 'Ứng dụng cho Iphone và iPad',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/app_mobile_ios?helpref=hc_enav');
              }
            }
          ],
          action: () => {
            history.push(`/help/interested??helpref=hc_enav`);
          }
        },
        {
          id: 'accessibility',
          title: 'Trợ năng',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-street-view"
            ></i>
          ),
          itemChild: [],
          action: () => {
            history.push(`/help_item/accessibility?helpref=hc_enav`);
          }
        }
      ]
    },
    {
      id: 'use_emso2',
      title: 'Quản lý tài khoản',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i
          className="fa-light fa-user"
          style={{
            fontSize: '20px',
            backgroundColor:
              theme.palette.mode === 'light'
                ? 'rgb(220 214 214)'
                : 'rgb(84 84 84)',
            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
            padding: 10,
            borderRadius: '50%'
          }}
        ></i>
      ),
      action: () => {
        history.push('/help/use_emso?helpref=hc_enav');
      },
      accordion: true,
      style: { height: 36, width: 36 },
      position: 'top',
      itemChildren: [
        {
          id: 'login_passwword',
          title: 'Đăng nhập và mật khẩu',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-key"
            ></i>
          ),
          itemChild: [
            {
              id: 'login',
              title: 'Đăng nhập tài khoản của bạn',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/login?helpref=hc_enav');
              }
            },
            {
              id: 'change_password',
              title: 'Đổi mật khẩu',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/change_password?helpref=hc_enav');
              }
            },
            {
              id: 'fix_login',
              title: 'Khắc phục sự cố đăng nhập',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/fix_login?helpref=hc_enav');
              }
            },

            {
              id: 'post_identity_documents',
              title: 'Xác minh danh tính người dùng',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push(
                  '/help_item/post_identity_documents?helpref=hc_enav'
                );
              }
            }
          ],
          action: () => {
            history.push(`/help_item/create_user?helpref=hc_enav`);
          }
        },
        {
          id: 'setting_account',
          title: 'Cài đặt tài khoản',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-gears"
            ></i>
          ),
          itemChild: [
            {
              id: 'adjust_setting',
              title: 'Điều chỉnh phần cài đặt tài khoản',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/adjust_setting?helpref=hc_enav');
              }
            },
            {
              id: 'user_name',
              title: 'Tên người dùng',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/user_name?helpref=hc_enav');
              }
            }
          ],
          action: () => {
            history.push(`/help_item/infomation??helpref=hc_enav`);
          }
        },
        {
          id: 'notification',
          title: 'Thông báo',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-bell"
            ></i>
          ),
          itemChild: [
            {
              id: 'notification_post_email_message',
              title: 'Thông báo đẩy, qua email và tin nhắn văn bản',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push(
                  '/help_item/notification_post_email_message?helpref=hc_enav'
                );
              }
            },
            {
              id: 'fix_notification',
              title: 'Khắc phục sự cố',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/fix_notification?helpref=hc_enav');
              }
            }
          ],
          action: () => {
            history.push(`/help_item/infomation??helpref=hc_enav`);
          }
        },
        // {
        //   id: 'advertising_options',
        //   title: 'Tuỳ chọn quảng cáo',
        //   styleTitle: { fontWeight: '400' },
        //   icon: (
        //     <i
        //       style={{
        //         fontSize: 20,
        //         color: theme.palette.mode === 'dark' ? '#fff' : '#000'
        //       }}
        //       className="fa-regular fa-credit-card"
        //     ></i>
        //   ),
        //   itemChild: [
        //     {
        //       id: 'advertising_action',
        //       title: 'Cách quảng cáo hoạt động trên Emso',
        //       styleTitle: { fontWeight: '400' },

        //       action: () => {
        //         history.push('/help_item/advertising_action?helpref=hc_enav');
        //       }
        //     },
        //     {
        //       id: 'control_advertising',
        //       title: 'Kiểm soát quảng cáo mà bạn nhìn thấy',
        //       styleTitle: { fontWeight: '400' },

        //       action: () => {
        //         history.push('/help_item/control_advertising?helpref=hc_enav');
        //       }
        //     },
        //     {
        //       id: 'imfomation_advertising',
        //       title: 'Thông tin và quảng cáo trên Emso',
        //       styleTitle: { fontWeight: '400' },

        //       action: () => {
        //         history.push(
        //           '/help_item/imfomation_advertising?helpref=hc_enav'
        //         );
        //       }
        //     }
        //   ],
        //   action: () => {
        //     history.push(`/help_item/infomation??helpref=hc_enav`);
        //   }
        // },
        {
          id: 'remove_account',
          title: 'Vô hiệu hoá hoặc xoá tài khoản của bạn ',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-circle-xmark"
            ></i>
          ),

          action: () => {
            history.push('/help_item/remove_account?helpref=hc_enav');
          }
        }
      ]
    },
    {
      id: 'use_emso3',
      title: 'Quyền riêng tư, an toàn và bảo mật',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i
          className="fa-solid fa-lock"
          style={{
            fontSize: '20px',
            backgroundColor:
              theme.palette.mode === 'light'
                ? 'rgb(220 214 214)'
                : 'rgb(84 84 84)',
            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
            padding: 10,
            borderRadius: '50%'
          }}
        ></i>
      ),
      action: () => {
        history.push('/help/use_emso?helpref=hc_enav');
      },
      accordion: true,
      style: { height: 36, width: 36 },
      position: 'top',
      itemChildren: [
        {
          id: 'privacy',
          title: 'Quyền riêng tư của bạn',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-lock"
            ></i>
          ),
          itemChild: [
            {
              id: 'control_object',
              title:
                'Kiểm soát đối tượng có thể xem nội dung bạn chia sẻ trên Emso',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/control_object?helpref=hc_enav');
              }
            },
            {
              id: 'manage_content_share',
              title: 'Quản lý nội dung bạn đã chia sẻ',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/manage_content_share?helpref=hc_enav');
              }
            },
            {
              id: 'control_find_you',
              title: 'Kiểm soát những người có thể tìm thấy bạn',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/control_find_you?helpref=hc_enav');
              }
            }
          ],
          action: () => {
            history.push(`/help_item/create_user?helpref=hc_enav`);
          }
        },
        {
          id: 'safe',
          title: 'Giữ an toàn',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-sharp fa-solid fa-shield"
            ></i>
          ),
          itemChild: [
            {
              id: 'abuse',
              title: 'Tài nguyên về vấn đề lạm dụng',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/abuse?helpref=hc_enav');
              }
            },
            {
              id: 'suicidal',
              title: 'Tài nguyên về tự tử và tự gây thương tích',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/suicidal?helpref=hc_enav');
              }
            },
            {
              id: 'parent',
              title: 'Tài nguyên về an toàn dành cho phụ huynh',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/parent?helpref=hc_enav');
              }
            }
          ],
          action: () => {
            history.push(`/help_item/infomation??helpref=hc_enav`);
          }
        },
        {
          id: 'maintain_account',
          title: 'Duy trì tính bảo mật cho tài khoản của bạn',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-sharp fa-solid fa-shield-check"
            ></i>
          ),
          itemChild: [
            {
              id: 'security_features',
              title: 'Tính năng và bí quyết bảo mật',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/security_features?helpref=hc_enav');
              }
            },
            {
              id: 'warning_login',
              title: 'Cảnh báo đăng nhập',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/warning_login?helpref=hc_enav');
              }
            },
            {
              id: 'spam',
              title: 'Tránh hành vi lừa đảo và spam',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/spam?helpref=hc_enav');
              }
            }
          ],
          action: () => {
            history.push(`/help_item/infomation??helpref=hc_enav`);
          }
        },
        {
          id: 'safe_market',
          title: 'An toàn khi mua sắm',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-bag-shopping"
            ></i>
          ),
          itemChild: [
            {
              id: 'recognize_fraudulent_behavior',
              title: 'Nhận biết hành vi lừa đảo',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push(
                  '/help_item/recognize_fraudulent_behavior?helpref=hc_enav'
                );
              }
            },
            {
              id: 'avoid_scams',
              title: 'Phòng tránh hành vi lừa đảo',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/avoid_scams?helpref=hc_enav');
              }
            },
            {
              id: 'shopping_on_marketplace',
              title: 'Không Gian Thương Mại',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push(
                  '/help_item/shopping_on_marketplace?helpref=hc_enav'
                );
              }
            },
            {
              id: 'sale_shopping_tips',
              title: 'Bí quyết mua sắm an toàn',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/sale_shopping_tips?helpref=hc_enav');
              }
            }
          ],
          action: () => {
            history.push(`/help_item/infomation??helpref=hc_enav`);
          }
        }
      ]
    },
    {
      id: 'use_emso4',
      title: 'Chính sách và báo cáo',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i
          className="fa-sharp fa-regular fa-circle-exclamation"
          style={{
            fontSize: '20px',
            backgroundColor:
              theme.palette.mode === 'light'
                ? 'rgb(220 214 214)'
                : 'rgb(84 84 84)',
            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
            padding: 10,
            borderRadius: '50%'
          }}
        ></i>
      ),
      action: () => {
        history.push('/help/use_emso?helpref=hc_enav');
      },
      accordion: true,
      style: { height: 36, width: 36 },
      position: 'top',
      itemChildren: [
        {
          id: 'abuse_report',
          title: 'Báo cáo lạm dụng',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-sharp fa-regular fa-circle-exclamation"
            ></i>
          ),
          itemChild: [
            {
              id: 'report_content',
              title: 'Báo cáo nội dung trên Emso',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push('/help_item/report_content?helpref=hc_enav');
              }
            },
            {
              id: 'you_not_account',
              title: 'Bạn chưa có tài khoản',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/help_item/you_not_account?helpref=hc_enav');
              }
            }
          ],
          action: () => {
            history.push(`/help_item/create_user?helpref=hc_enav`);
          }
        },
        {
          id: 'report_problem',
          title: 'Báo cáo sự cố với Emso',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-star"
            ></i>
          ),

          action: () => {
            history.push(`/help_item/report_problem??helpref=hc_enav`);
          }
        },
        {
          id: 'scope_report',
          title: 'Báo cáo vi phạm quyền riêng tư',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-lock-open"
            ></i>
          ),

          action: () => {
            history.push(`/help_item/scope_report??helpref=hc_enav`);
          }
        },
        {
          id: 'account_hack',
          title: 'Tài khoản hack và tài khoản giả',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-id-card"
            ></i>
          ),

          action: () => {
            history.push(`/help_item/account_hack??helpref=hc_enav`);
          }
        },
        {
          id: 'intellectual_property_rights',
          title: 'Quyền sở hữu trí tuệ',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-copyright"
            ></i>
          ),
          itemChild: [
            {
              id: 'license',
              title: 'Bản quyền',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push(`/help_item/license??helpref=hc_enav`);
              }
            },
            {
              id: 'brand',
              title: 'Nhãn hiệu hàng hoá',
              styleTitle: { fontWeight: '400' },

              action: () => {
                history.push(`/help_item/brand??helpref=hc_enav`);
              }
            }
          ],
          action: () => {
            history.push(`/help_item/infomation??helpref=hc_enav`);
          }
        }
      ]
    },
    {
      id: 'use_emso5',
      title: 'Điều khoản và chính sách',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i
          className="fa-solid fa-shield"
          style={{
            fontSize: '20px',
            backgroundColor:
              theme.palette.mode === 'light'
                ? 'rgb(220 214 214)'
                : 'rgb(84 84 84)',
            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
            padding: 10,
            borderRadius: '50%'
          }}
        />
      ),
      action: () => {
        history.push('/user_emso/terms_policy?helpref=hc_enav');
      },
      accordion: true,
      style: { height: 36, width: 36 },
      position: 'top',
      itemChildren: [
        {
          id: 'terms_service',
          title: 'Điều khoản dịch vụ',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-building-shield"
            ></i>
          ),
          action: () => {
            history.push(`/terms_item/terms_service?helpref=hc_enav`);
          }
        },
        {
          id: 'policy_sercuirty',
          title: 'Chính sách riêng tư và tiêu chuẩn bảo mật',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-lock"
            ></i>
          ),
          action: () => {
            history.push(`/terms_item/policy_security?helpref=hc_enav`);
          }
        },
        {
          id: 'standard_community',
          title: 'Tiêu chuẩn cộng đồng',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-triangle-exclamation"
            ></i>
          ),

          action: () => {
            history.push(`/terms_item/standard_community?helpref=hc_enav`);
          }
        },
        {
          id: 'policy_advertise',
          title: 'Chính sách quảng cáo',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-rectangle-ad"
            ></i>
          ),
          action: () => {
            history.push(`/terms_item/policy_advertise?helpref=hc_enav`);
          }
        },
        {
          id: 'report_complain',
          title: 'Báo cáo khiếu nại',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-flag"
            ></i>
          ),
          action: () => {
            history.push(`/terms_item/report_complain?helpref=hc_enav`);
          }
        }
      ]
    },
    {
      id: 'use_emso6',
      title: 'Điều khoản và chính sách riêng',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i
          className="fa-solid fa-key"
          style={{
            fontSize: '20px',
            backgroundColor:
              theme.palette.mode === 'light'
                ? 'rgb(220 214 214)'
                : 'rgb(84 84 84)',
            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
            padding: 10,
            borderRadius: '50%'
          }}
        />
      ),
      action: () => {
        history.push('/user_emso/terms_policy_private?helpref=hc_enav');
      },
      accordion: true,
      style: { height: 36, width: 36 },
      position: 'top',
      itemChildren: [
        {
          id: 'terms_recruit',
          title: 'Tuyển dụng',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-users-rays"
            ></i>
          ),
          itemChild: [
            {
              id: 'policy_emso_recruit',
              title: 'Chính sách của EMSO về tuyển dụng',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/terms_item/policy_emso_recruit?helpref=hc_enav');
              }
            },
            {
              id: 'tools_recruit',
              title: 'Quy chế hoạt động công cụ tuyển dụng',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/terms_item/tools_recruit?helpref=hc_enav');
              }
            }
          ],
          action: () => {
            history.push(`/terms_item/policy_emso_recruit?helpref=hc_enav`);
          }
        },
        {
          id: 'terms_grows',
          title: 'Gọi vốn cộng đồng',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-arrow-up-right-dots"
            ></i>
          ),
          itemChild: [
            {
              id: 'policy_grows_community',
              title: 'Quy chế chính sách gọi vốn cộng đồng',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push(
                  '/terms_item/policy_grows_community?helpref=hc_enav'
                );
              }
            },
            {
              id: 'deal_grows_community',
              title: 'Thoả thuận dịch vụ của gọi vốn cộng đồng',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push(
                  '/terms_item/deal_grows_community?helpref=hc_enav'
                );
              }
            }
          ],
          action: () => {
            history.push(`/terms_item/policy_grows_community?helpref=hc_enav`);
          }
        },
        {
          id: 'terms_music',
          title: 'Không gian âm nhạc',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-music"
            ></i>
          ),
          itemChild: [
            {
              id: 'use_space_music',
              title: 'Quy chế sử dụng không gian âm nhạc',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/terms_item/use_space_music?helpref=hc_enav');
              }
            }
          ],
          action: () => {
            history.push(`/terms_item/use_space_music?helpref=hc_enav`);
          }
        },
        {
          id: 'terms_study',
          title: 'Không gian học tập',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-graduation-cap"
            ></i>
          ),
          itemChild: [
            {
              id: 'use_space_study',
              title: 'Quy chế hoạt động của không gian học tập',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/terms_item/use_space_study?helpref=hc_enav');
              }
            },
            {
              id: 'deal_study',
              title: 'Thoả thuận dịch vụ về không gian học tập',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push('/terms_item/deal_study?helpref=hc_enav');
              }
            },
            {
              id: 'policy_payment_complain',
              title: 'Chính sách thanh toán và khiếu nại',
              styleTitle: { fontWeight: '400' },
              action: () => {
                history.push(
                  '/terms_item/policy_payment_complain?helpref=hc_enav'
                );
              }
            }
          ],
          action: () => {
            history.push(`/terms_item/use_space_study?helpref=hc_enav`);
          }
        },
        {
          id: 'terms_market',
          title: 'Không gian thương mại',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-solid fa-shop"
            ></i>
          ),
          action: () => {
            history.push(`/terms_item/action_space_market?helpref=hc_enav`);
          }
        }
      ]
    }
  ];

  React.useEffect(() => {
    let { key } = match?.params;

    setSelectedIndex(key);
  }, [JSON.stringify(match.params)]);

  const renderBody = () => {
    switch (match.path) {
      case PATHS.HELPER:
      case PATHS.HELPER_ITEM:
      case PATHS.TERMS_ITEM:
      case PATHS.HElPER_WRAP:
        return {
          bodyTop: (
            <ListButton
              item={listMenuHelp.filter(el => el.position === 'top')}
              selectedItem={selectedIndex}
              width={'100%'}
              styleAccordionDetail={{
                padding: '3px'
              }}
              styleAccordion={{ margin: '0px !important' }}
              type="help"
              typeAction={'action'}
            />
          ),
          bodyCenter: null
        };
      default:
        return { bodyTop: null, bodyCenter: null, bodyFooter: null };
    }
  };

  return (
    <div
      className={classes.root}
      style={{
        width: 360,
        margin: '0px',
        padding: '0px',
        overflow: 'auto'
      }}
    >
      <div className={classes.scroll}>{renderBody().bodyTop}</div>
    </div>
  );
};

export default HelperLeft;
