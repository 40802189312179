import * as React from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  Typography,
  Popper,
  Box
} from '@mui/material';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { buttonColor } from 'src/constants/styles';
import { useTheme } from '@mui/styles';
export default function BasicPopover(props) {
  const {
    visibility,
    setVisibility,
    listVisibility,
    handleAction,
    styleVisibility,
    styleTextVisibility,
    classNameVisibility
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [openPopper, setOpenPopper] = React.useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenPopper(false);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const theme: any = useTheme();
  return (
    <div>
      <Button
        className={classNameVisibility || ''}
        aria-describedby={id}
        sx={{
          ...styleVisibility,
          margin: 0.25,
          marginRight: 0,
          height: '36px',
          textTransform: 'none',
          padding: '8px',
          borderRadius: '5px',
          boxShadow: 0,
          fontSize: 15,
          fontWeight: 700,
          color:
            theme.palette.mode === 'light' ? buttonColor.inheritText : '#fff'
        }}
        size="small"
        onClick={handleClick}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {listVisibility?.find((item: any) => item.key === visibility)
            ?.icon ? (
            <>
              <i
                style={{ fontSize: 15 }}
                className={
                  listVisibility?.find((item: any) => item.key === visibility)
                    ?.icon
                }
              ></i>{' '}
              &nbsp;
            </>
          ) : null}
          <Typography style={{ fontWeight: 500, ...styleTextVisibility }}>
            {
              listVisibility?.find((item: any) => item.key === visibility)
                ?.label
            }
          </Typography>{' '}
          &nbsp;
          <i style={{ fontSize: 15 }} className="fas fa-caret-down"></i>
        </div>
      </Button>
      {openPopper && (
        <ClickAwayListener onClickAway={() => setOpenPopper(false)}>
          <Popper
            id={id}
            open={openPopper}
            anchorEl={anchorEl}
            disablePortal={true}
            placement="bottom-end"
            style={{
              borderRadius: '8px',
              zIndex: 1001,
              boxShadow: '0px 2px 13px #525252',
              transition: 'top 0.3s ease-in-out'
            }}
          >
            <List
              dense
              sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.primary',
                borderRadius: '8px'
              }}
            >
              {listVisibility?.map(value => {
                return (
                  <ListItem
                    onClick={() => {
                      handleAction && handleAction(value.key);
                      setVisibility && setVisibility(value.key);
                      handleClose();
                    }}
                    key={value.key}
                    disablePadding
                  >
                    <ListItemButton>
                      {value?.icon ? (
                        <div style={{ width: 32 }}>
                          <i
                            style={{ fontSize: 20, color: '#65676b' }}
                            className={value?.icon}
                          ></i>
                        </div>
                      ) : null}
                      <ListItemText
                        primary={
                          <Typography
                            style={{
                              fontSize: 15,
                              fontWeight: 700
                            }}
                          >
                            {value?.label}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            style={{ fontSize: 13, color: 'text.secondary' }}
                          >
                            {value?.subLabel}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Popper>
        </ClickAwayListener>
      )}
    </div>
  );
}
