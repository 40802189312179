import VerifiedIcon from '@mui/icons-material/Verified';
import { Box, Link, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { buttonColor } from 'src/constants/styles';
import PopupInformation from '../Popup/PopupInformation';
const useStyle = makeStyles(() => ({}));

const NameSocial = props => {
  const {
    name,
    target_name,
    object_target,
    style,
    object,
    isPopup,
    type,
    typePopup,
    styleTextHeader,
    post,
    keyTab,
    action
  } = props;
  const history = useHistory();

  const handleClick = () => {
    object?.display_name
      ? history.push(`/user/${object.username || object.id}`)
      : object?.page_categories
      ? //thêm keyTab vào props để người dùng có thể chuyển hướng đến tab theo ý muốn
        //keyTab = "group" | "video" | "event" | "music" | "review" | "file" | "about" | "community" | "deal" | "service" | 'store | "live"
        keyTab
        ? history.push(`/page/${object.username || object.id}/${keyTab}`)
        : history.push(`/page/${object.username || object.id}`)
      : type === 'course'
      ? history.push(`/course/${object?.id}/about`)
      : type === 'grow'
      ? history.push(`/grow/${object?.id}/about`)
      : history.push(`/group/${object?.username || object.id}`);
  };
  const handleLink = () => {
    if (action) {
      action();
    }
    if (object?.display_name) {
      return `/user/${object?.username || object?.id}`;
    } else if (object?.page_categories) {
      return `/page/${object?.username || object?.id}`;
    } else if (type === 'course') {
      return `/course/${object?.id}/about`;
    } else if (type === 'grow') {
      return `/grow/${object?.id}/about`;
    } else {
      return `/group/${object?.username || object?.id}`;
    }
  };
  const handleLinkTarget = () => {
    if (action) {
      action();
    }
    if (object_target?.display_name) {
      return `/user/${object_target?.username ?? object_target?.id}`;
    } else if (object_target?.page_categories) {
      return `/page/${object_target?.username ?? object_target?.id}`;
    } else {
      return `/group/${object_target?.username ?? object_target?.id}`;
    }
  };
  const handleClickTarget = () => {
    if (action) {
      action();
    }
    if (object_target?.display_name) {
      return history.push(
        `/user/${object_target.username ?? object_target.id}`
      );
    } else if (object_target?.page_categories) {
      return history.push(
        `/page/${object_target.username ?? object_target.id}`
      );
    } else {
      return history.push(
        `/group/${object_target?.username ?? object_target.id}`
      );
    }
  };
  return (
    <>
      {isPopup ? (
        <div
          style={{
            display: 'inline-block',
            alignItems: 'center'
          }}
        >
          <PopupInformation object={object} typePopup={typePopup} type={type}>
            {(popupState, bindHover) => {
              return (
                <Box>
                  <Link underline="hover">
                    <Typography
                      sx={{
                        ...style,
                        fontWeight: '600',
                        color: 'text.primary',
                        '&:hover': {
                          textDecoration: 'underline',
                          cursor: 'pointer'
                        },
                        ...styleTextHeader
                      }}
                      onClick={() => {
                        handleClick();
                        action && action();
                      }}
                      {...bindHover(popupState)}
                    >
                      {name}
                    </Typography>
                  </Link>
                </Box>
              );
            }}
          </PopupInformation>{' '}
          {object?.certified &&
          post &&
          ((!post.mentions?.length &&
            !post.shared_course &&
            !post.shared_event &&
            !post.poll &&
            !post.place &&
            !post.post_type &&
            !post.shared_project &&
            !post.shared_group &&
            !post.shared_page &&
            !post.shared_recruit &&
            !post.status_activity &&
            !post.status_target) ||
            (post.post_type && post.post_type === 'moment')) ? (
            <Tooltip
              placement="top-start"
              title="Emso đã xác nhận tài khoản này đã được xác minh danh tính và có tác động tích cực đến cộng đồng"
              arrow
              sx={{
                '& .MuiTooltip-tooltip': {
                  fontSize: '14px',
                  padding: '10px'
                }
              }}
            >
              <VerifiedIcon
                // titleAccess="Tài khoản đã xác minh"
                fontSize="small"
                viewBox="0 -2 24 24"
                sx={{
                  color: buttonColor.backgroundColor,
                  fontSize: '14px',
                  '&:hover': {
                    color: buttonColor.backgroundColor
                  }
                }}
              />
            </Tooltip>
          ) : null}
          {!object?.certified &&
          object?.identity_verification &&
          post &&
          ((!post.mentions?.length &&
            !post.shared_course &&
            !post.shared_event &&
            !post.poll &&
            !post.place &&
            !post.post_type &&
            !post.shared_project &&
            !post.shared_group &&
            !post.shared_page &&
            !post.shared_recruit &&
            !post.status_activity &&
            !post.status_target) ||
            (post.post_type && post.post_type === 'moment')) ? (
            <Tooltip
              placement="top-start"
              title="Emso đã xác nhận tài khoản này đã được xác minh danh tính"
              arrow
              sx={{
                '& .MuiTooltip-tooltip': {
                  fontSize: '14px',
                  padding: '10px'
                }
              }}
            >
              <VerifiedIcon
                // titleAccess="Tài khoản đã xác minh"
                fontSize="small"
                viewBox="0 -2 24 24"
                sx={{
                  color: 'hsla(0, 0%, 50%)',
                  fontSize: '14px',
                  '&:hover': {
                    color: 'hsla(0, 0%, 60%)'
                  }
                }}
              />
            </Tooltip>
          ) : null}
          {!!target_name ? (
            <PopupInformation object={object_target}>
              {(popupState, bindHover) => {
                return (
                  <NavLink to={handleLinkTarget()}>
                    <Typography
                      sx={{
                        color: 'text.primary',
                        fontWeight: '600',
                        '&:hover': {
                          textDecoration: 'underline',
                          cursor: 'pointer'
                        },
                        ...style
                      }}
                      onClick={handleClickTarget}
                      {...bindHover(popupState)}
                    >
                      {type === 'watch-selected' ? null : (
                        <i
                          className="fa-solid fa-caret-right"
                          style={{ margin: '0px 5px', color: '#383838' }}
                        ></i>
                      )}
                      {target_name}
                    </Typography>
                  </NavLink>
                );
              }}
            </PopupInformation>
          ) : null}
        </div>
      ) : (
        <Link href={handleLink()} underline="hover">
          <Typography
            sx={{
              fontWeight: '600',
              '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer'
              },
              ...style
            }}
            onClick={() => {
              handleClick();
              action && action();
            }}
          >
            {name}
          </Typography>
        </Link>
      )}
    </>
  );
};

export default NameSocial;
