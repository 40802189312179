import { CircularProgress, Divider, Tooltip, Typography } from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { followUser, getInforUser, unFollowUser } from 'src/apis/socialUser';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import CarouselV2 from 'src/components/Carousel/CarouselV2';
import DialogPostShare from 'src/components/Dialog/DialogPostShare';
import DialogViewReaction from 'src/components/Dialog/DialogViewReation';
import RenderReactionImg from 'src/components/ReactEmoji/RenderReactionImg';
import { listReactCount } from 'src/constants/common';
import { PATHS } from 'src/constants/paths';
import { buttonColor } from 'src/constants/styles';

const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 45,
        paddingLeft: '10px'
      },
      react: {
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          cursor: 'pointer'
        }
      },
      reactCount: {
        margin: '0 0 0 5px',
        // fontWeight: 400,
        color: '#65676b',
        fontSize: 15,
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline',
          textDecorationColor: '#65676b'
        }
      },
      inline: {
        display: 'inline',
        '&:hover': {
          textDecoration: 'underline'
        },
        cursor: 'pointer',
        fontSize: '15px !important'
      },
      boxUser: {
        width: '150px',
        maxHeight: '250px',
        overflow: 'hidden auto',
        opacity: '0.8',
        backgroundColor:
          theme.palette.mode === 'dark' ? '#ccc !important' : '#333 !important',
        color:
          theme.palette.mode === 'dark' ? '#333 !important' : '#fff !important'
      },
      lineClamp: {
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        overflowWrap: 'break-word'
      }
    }),
  { index: 1 }
);

interface Props {
  post?: any;
  type?: String;
  reactions?: any;
  commentTotal?: any;
  shareCount?: any;
  listComment?: any;
  handleGetComment?: any;
  setShowComment?: React.Dispatch<React.SetStateAction<boolean>> | any;
  setListComment?: React.Dispatch<React.SetStateAction<any>>;
  reactionCount?: any;
  setReactionCount?: React.Dispatch<React.SetStateAction<number>> | any;
  meReaction?: any;
  listReaction?: any;
  buttonSelected?: any;
  setButtonSelected?: React.Dispatch<React.SetStateAction<any>> | any;
  typeLive?: String;
  setHasMore?: React.Dispatch<React.SetStateAction<boolean>> | any;
}

const PostActionInformation = React.memo((props: Props) => {
  const classes = useStyles();
  let {
    post,
    type,
    reactions,
    commentTotal,
    shareCount,
    listComment,
    handleGetComment,
    setShowComment,
    setListComment,
    reactionCount,
    setReactionCount,
    meReaction,
    listReaction,
    buttonSelected,
    setButtonSelected,
    typeLive,
    setHasMore
  } = props;
  const theme: any = useTheme();
  const match = useRouteMatch();
  const [openViewReaction, setOpenViewReaction] = React.useState(false);
  const [openPostShare, setOpenPostShare] = React.useState(false);
  const [listUserComment, setListUserComment] = React.useState<any>([]);
  const postWatchSelected = useSelector(
    (state: any) => state.watchReducer.postSelected
  );
  const rolePage = useSelector(
    (state: any) => state.pageReducer?.rolePreviewEdit
  );
  const meInfo = useSelector((state: any) => state.meReducer.info);

  const listButton = [
    {
      id: 'overview',
      label: 'Tổng quan'
    },
    {
      id: 'chat',
      label: 'Chat trực tiếp'
    },
    {
      id: 'totle_comment',
      label: 'Câu trả lời'
    },
    {
      id: 'video',
      label: 'Video tương tự'
    }
  ];

  const [userInfo, setUserInfo] = React.useState<any>(null);

  React.useEffect(() => {
    if (post?.account?.id) {
      getUserInfo(post.account.id);
    }
  }, [post?.account?.id]);

  const getUserInfo = async id => {
    try {
      const res = await getInforUser(id, null);
      if (res.status === 200) {
        setUserInfo(res.data);
      }
    } catch (error) {}
  };

  const handleFollow = async id => {
    try {
      if (id) {
        const res = await followUser(id);
        if (res.status === 200) {
          setUserInfo(prev => ({
            ...prev,
            relationships: { ...prev.relationships, following: true }
          }));
        }
      }
    } catch (error) {}
  };

  const handleUnFollow = async id => {
    try {
      if (id) {
        const res = await unFollowUser(id);
        if (res.status === 200) {
          setUserInfo(prev => ({
            ...prev,
            relationships: { ...prev.relationships, following: false }
          }));
        }
      }
    } catch (error) {}
  };

  const getReactCount = key => {
    return reactions?.find(el => el[key] || el[key] === 0)
      ? reactions?.find(el => el[key] || el[key] === 0)[key]
      : 0 ?? 0;
  };

  const handleAction = () => {
    if (
      match.path.includes('watch') &&
      type !== 'watch-selected' &&
      postWatchSelected.id === post.id
    ) {
      return;
    }
    if (!listComment.length) {
      handleGetComment(
        post.id,
        {
          sort_by: 'newest'
        },
        setListComment
      );
    }

    setShowComment(prev => !prev);
  };

  const handleClickReact = () => {
    // setOpenViewReaction(true);
    if (post?.post_type !== 'moment') {
      setOpenViewReaction(true);
    }
  };

  const handleSharePost = () => {
    setOpenPostShare(true);
  };
  React.useEffect(() => {
    if (post?.post_type !== 'moment') {
      let count = listReactCount?.reduce(
        (total, el) => total + getReactCount(el),
        0
      );
      setReactionCount(count);
    }
  }, [JSON.stringify(reactions)]);

  const renderActionsButton = item => {
    return (
      <ButtonInherit
        label={item.label}
        style={{
          width: '100%',
          margin: '0px',
          color:
            buttonSelected === item.id
              ? '#fff'
              : theme.palette.mode === 'dark'
              ? '#e4e6eb'
              : '#000',
          backgroundColor:
            buttonSelected === item.id
              ? buttonColor.backgroundColor
              : theme.palette.mode === 'dark'
              ? '#3a3b3c'
              : '#E4E6EB',
          borderRadius: '10px !important'
        }}
        action={() => setButtonSelected(item.id)}
      />
    );
  };

  const renderButtonOption = () => {
    return (
      <>
        {userInfo ? (
          userInfo?.relationships?.following ? (
            <ButtonInherit
              color={buttonColor.backgroundColor}
              action={() => {
                handleUnFollow(post?.account?.id);
              }}
              label={
                <Typography>
                  <i
                    className="fa-solid fa-circle-check"
                    style={{ marginRight: '10px' }}
                  ></i>
                  theo dõi
                </Typography>
              }
              style={{
                width: '100%'
              }}
            />
          ) : (
            <ButtonInherit
              color={theme.palette.mode === 'dark' ? '#3a3b3c' : '#E4E6EB'}
              action={() => {
                handleFollow(post?.account?.id);
              }}
              label={
                <Typography>
                  <i
                    className="fa-solid fa-folder-medical"
                    style={{ marginRight: '10px' }}
                  ></i>
                  Theo dõi
                </Typography>
              }
              style={{
                width: '100%'
              }}
            />
          )
        ) : null}

        <CarouselV2
          component={renderActionsButton}
          data={listButton}
          elOfPage={2}
          styleRoot={{
            minHeight: '60px',
            margin: '0px'
          }}
          styleButtonLeft={{
            width: '45px !important',
            height: '45px !important',
            left: '-4px !important',
            top: '12% !important'
          }}
          styleButtonRight={{
            width: '45px !important',
            height: '45px !important',
            right: '-5px !important',
            top: '12% !important'
          }}
          styleIcon={{
            fontSize: '16px'
          }}
          styleWrapTitle={{
            padding: '0px'
          }}
          styleTitle={{
            fontSize: '20px'
          }}
          itemComponentStyle={{
            maxHeight: '60px',
            minHeight: '30px',
            width: '163px'
          }}
          typeCarousel="carousel_button"
          typeButton="out_box"
        />
      </>
    );
  };
  const renderTextInfo = (meReaction: any) => {
    const pageMeReaction = meReaction.find(
      item => item?.page?.id === rolePage?.idPage && rolePage?.role
    );
    const accountMeReaction = meReaction.find(
      item => item?.account.id === meInfo?.id && item?.page == null
    );
    const pageReaction = meReaction.find(
      item => item?.page?.id && !rolePage?.role
    );
    const accountReaction = meReaction.find(
      item => item?.account.id !== meInfo?.id && item?.page == null
    );
    if (
      (accountMeReaction && !pageMeReaction) ||
      (pageMeReaction && !accountMeReaction)
    ) {
      return pageReaction
        ? 'Bạn và ' + pageReaction?.page.title
        : 'Bạn và ' + accountReaction.account.display_name;
    } else if (accountMeReaction && pageMeReaction) {
      return 'Bạn và ' + accountMeReaction.account.display_name;
    } else {
      return meReaction.map((item, index) => {
        return (
          <span key={index}>
            {item?.page ? item?.page.title : item?.account.display_name}
            {index === 0 ? ' và ' : null}
          </span>
        );
      });
    }
  };
  const popupUsers = () => {
    const uniqueItems = listUserComment.filter((item, index, self) => {
      const checkItem = item?.page_owner ? 'page_owner' : 'account';
      return (
        self.findIndex(
          value => value[checkItem]?.id === item[checkItem]?.id
        ) === index
      );
    });

    return (
      <>
        {listUserComment.length ? (
          uniqueItems.map((item, index) => (
            <Typography className={classes.lineClamp} sx={{ fontSize: '14px' }}>
              {item?.page_owner?.title ?? item?.account?.display_name}
            </Typography>
          ))
        ) : (
          <CircularProgress style={{ height: 20, width: 20 }} color="inherit" />
        )}
      </>
    );
  };

  return commentTotal ||
    post?.view_3s > 0 ||
    shareCount ||
    (reactionCount > 0 && post.post_type !== 'moment') ||
    (post.post_type === 'moment' && post?.reactions[3].loves_count > 0) ? (
    <>
      <div
        className={classes.root}
        style={{
          zoom: match.path.includes('watch') ? `85%` : '100%'
          // minWidth: match.path.includes('watch') ? `460px` : undefined
        }}
      >
        <div
          onClick={handleClickReact}
          className={classes.react}
          style={{ maxWidth: '250px' }}
        >
          {((reactionCount > 0 && post.post_type !== 'moment') ||
            (post.post_type === 'moment' &&
              post?.reactions[3].loves_count > 0)) && (
            <>
              {post.post_type === 'moment' ? (
                <i
                  style={{ color: 'rgb(254, 44, 85)' }}
                  className="fa-solid fa-heart fa-beat fa-lg"
                ></i>
              ) : (
                <span>
                  <RenderReactionImg post={post} listReaction={listReaction} />
                </span>
              )}

              <p className={classes.reactCount}>
                {post.post_type === 'moment' ? (
                  post?.reactions[3].loves_count
                ) : type === 'previewMedia' ? (
                  reactionCount
                ) : meReaction?.length ? (
                  post?.viewer_reaction ? (
                    reactionCount === 1 ? (
                      <div>
                        {meReaction[0]?.page
                          ? meReaction[0]?.page?.title
                          : meInfo?.display_name}
                      </div>
                    ) : reactionCount === 2 ? (
                      <div>{renderTextInfo(meReaction)}</div>
                    ) : reactionCount === 3 ? (
                      <div>
                        {'Bạn, ' +
                          meReaction
                            ?.filter(item => item?.account?.id !== meInfo?.id)
                            .slice(0, 1)
                            .map(el => el?.account?.display_name) +
                          ' và 1 người khác'}
                      </div>
                    ) : (
                      <div>
                        {'Bạn, ' +
                          meReaction
                            ?.filter(item => item?.account?.id !== meInfo?.id)
                            .slice(0, 1)
                            .map(el => el?.account?.display_name) +
                          ' và ' +
                          (reactionCount - 2) +
                          ' người khác'}
                      </div>
                    )
                  ) : reactionCount === 1 ? (
                    <div>
                      {meReaction
                        ?.filter(item => item?.account?.id !== meInfo?.id)
                        .map(el => el?.account?.display_name)}
                    </div>
                  ) : reactionCount === 2 ? (
                    <div>{renderTextInfo(meReaction)}</div>
                  ) : reactionCount === 3 ? (
                    <div>
                      {meReaction
                        .filter(item => item?.account?.id !== meInfo?.id)
                        .slice(0, 1)
                        .map(el => el?.account?.display_name)}{' '}
                      và 2 người khác
                    </div>
                  ) : (
                    <div>
                      {meReaction
                        .filter(item => item?.account?.id !== meInfo?.id)
                        .slice(0, 1)
                        .map(el => el?.account?.display_name)
                        .join(', ')}{' '}
                      và {reactionCount - 1} người khác
                    </div>
                  )
                ) : post?.viewer_reaction ? (
                  reactionCount === 1 ? (
                    <div>
                      {rolePage?.role === 'admin'
                        ? rolePage?.title
                        : meInfo?.display_name}
                    </div>
                  ) : (
                    <div>{'Bạn và ' + (reactionCount - 1) + ' người khác'}</div>
                  )
                ) : (
                  reactionCount
                )}
              </p>
            </>
          )}
        </div>
        &nbsp; &nbsp;
        <div>
          {commentTotal ? (
            <Tooltip title={popupUsers()}>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
                onClick={e => {
                  handleAction();
                }}
                onMouseEnter={() => {
                  if (listComment.length) {
                    setListUserComment(listComment);
                  } else {
                    handleGetComment(
                      post.id,
                      {
                        sort_by: 'newest'
                      },
                      setListUserComment,
                      setHasMore
                    );
                  }
                }}
                onMouseLeave={() => setListUserComment([])}
              >
                {commentTotal} bình luận
              </Typography>
            </Tooltip>
          ) : null}
          {match.path === PATHS.WATCH ? (
            <>
              {shareCount ? (
                <>
                  &nbsp; &nbsp;
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                    onClick={handleSharePost}
                  >
                    {shareCount} lượt chia sẻ
                  </Typography>
                </>
              ) : null}
              {post.view_3s > 0 ? (
                <>
                  &nbsp; &nbsp;
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                    onClick={handleSharePost}
                  >
                    {post.view_3s > 1000
                      ? (post.view_3s / 1000).toFixed(1)
                      : post.view_3s}{' '}
                    lượt xem
                  </Typography>
                </>
              ) : null}
            </>
          ) : shareCount ? (
            <>
              &nbsp; &nbsp;
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
                onClick={handleSharePost}
              >
                {shareCount} lượt chia sẻ
              </Typography>
            </>
          ) : null}
        </div>
      </div>
      {openViewReaction ? (
        <DialogViewReaction
          post={post}
          open={openViewReaction}
          setOpen={setOpenViewReaction}
        />
      ) : null}

      {openPostShare ? (
        <DialogPostShare
          post={post}
          open={openPostShare}
          setOpen={setOpenPostShare}
        />
      ) : null}

      {typeLive ? renderButtonOption() : null}
      <Divider />
    </>
  ) : (
    <div>{typeLive ? renderButtonOption() : null}</div>
  );
});

export default PostActionInformation;
