import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';

import SocialFieldUpdate from 'src/components/SocialFieldUpdate/SocialFieldUpdate';
import { useDispatch, useSelector } from 'react-redux';
import { getRecruitCategoriesReq } from 'src/store/action/socialRecruitAction';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '5px 10px -10px 10px',
    borderRadius: 10,
    width: '100%'
  }
}));

interface BasicRecruitInfomation {
  infoUpdate: any;
  formik: any;
  setCheckStep: React.Dispatch<React.SetStateAction<any>> | any;
  setNotificationLeave: React.Dispatch<React.SetStateAction<any>> | any;
}

const BasicRecruitInfomation: React.FC<BasicRecruitInfomation> = props => {
  const classes = useStyles();
  const { infoUpdate, formik, setCheckStep, setNotificationLeave } = props;
  const dispatch = useDispatch();
  const categories =
    useSelector((state: any) => state?.recruitReducer?.category) || [];
  const [loading, setLoading] = React.useState<any>('');
  const [verify, setVerify] = React.useState<any>(false);

  const fetchCategories = () => {
    dispatch(getRecruitCategoriesReq());
  };

  React.useEffect(() => {
    fetchCategories();
  }, []);

  React.useEffect(() => {
    if (setCheckStep) {
      if (
        Object.keys(formik.errors).length === 0 &&
        formik.values.title &&
        formik.values.due_date &&
        formik.values.recruitments_count &&
        formik.values.recruit_category_id
      ) {
        setCheckStep(prev => ({ ...prev, step1: true }));
      } else {
        setCheckStep(prev => ({ ...prev, step1: false }));
      }
    }
  }, [JSON.stringify(formik.values), JSON.stringify(formik.errors)]);

  let day = new Date();

  let fieldUpdate: any = [
    {
      field_type: 'textField',
      name: 'title',
      placeholder:
        'VD: Tuyển dụng nhân viên Marketing, tuyển dụng designer, ...',
      label: 'Tiêu đề tin tuyển dụng',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      maxRows: 3,
      defaultValue: formik.values.title
    },
    {
      field_type: 'autocomplete',
      name: 'recruit_category_id',
      options: categories,
      multiple: false,
      setOptions: fetchCategories,
      placeholder: 'VD: Nhân viên Marketing, Designer, ...',
      descriptionLabel: 'Bắt buộc*',
      label: 'Vị trí tuyển dụng'
    },
    {
      field_type: 'typography',
      text: 'Thời gian tuyển dụng',
      style: { fontSize: 15, width: '100%', fontWeight: 700 },
      description: 'Bắt buộc*',
      styleDescription: { fontWeight: 600 }
    },
    {
      field_type: 'dateTime',
      name: 'due_date',
      placeholder: 'Ngày kết thúc',
      label: 'Ngày kết thúc',
      type: 'date',
      minDay: day,
      span: 12
    },
    {
      field_type: 'textField',
      name: 'recruitments_count',
      placeholder: '1',
      label: 'Số lượng tuyển',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      maxRows: 1,
      defaultValue: formik.values.recruitments_count
    }
  ];

  return (
    <Box className={classes.root}>
      <SocialFieldUpdate
        setVerify={setVerify}
        verify={verify}
        typeRecruit="recruit"
        setLoading={setLoading}
        formik={formik}
        fieldUpdate={fieldUpdate}
        infoUpdate={infoUpdate}
        setNotificationLeave={setNotificationLeave}
        spacing={0}
      />
    </Box>
  );
};

export default BasicRecruitInfomation;
