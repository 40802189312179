import React from 'react';
import { makeStyles } from '@mui/styles';
import { buttonColor, scrollStyle } from 'src/constants/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import ClickOutHandler from 'react-onclickout';

import CommonNoti from 'src/Notification/commonNoti';
import { postReadNotiApi } from 'src/apis/socialNoti';
import { Theme, Typography, Box, Skeleton, Avatar } from '@mui/material';
import ButtonCustom from 'src/components/Button/Button';
import { useHistory, useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 'calc(100vh - 75px)',
    borderRadius: 10,
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
    borderTop: '1px solid rgba(0,0,0,0.10)',
    width: 360,
    position: 'absolute',
    top: 50,
    right: 20,
    padding: '12px',
    overflowY: 'scroll',
    zIndex: '1000',
    ...scrollStyle
  },
  rootNoti: {
    display: 'flex',
    maxWidth: '100%',
    flexWrap: 'wrap'
  },
  listItem: {
    padding: '6px !important',
    alignItems: 'flex-start !important',
    '&:hover': {
      borderRadius: '10px !important'
    }
  },
  text: {
    fontSize: '15px',

    fontWeight: '500 !important'
  },
  subText: {
    color: '#65676b',
    fontSize: '13px !important'
  },
  avatar: {
    width: '28px !important',
    height: '28px !important',
    position: 'absolute',
    top: 33,
    right: 2
  }
}));

interface Props {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>> | any;
  fetchNotification?: any;
  notifications?: any;
  hasMore?: boolean | any;
  newNotis?: any;
  oldNotis?: any;
  handleClosePreview?: any;
}

const NotificationHeader = (props: Props) => {
  const classes = useStyles();

  const {
    setOpen,
    fetchNotification,
    notifications,
    hasMore,
    newNotis,
    oldNotis,
    handleClosePreview
  } = props;
  const [allNoti, setAllNoti] = React.useState<any>(true);
  const loaderRef = React.useRef();
  const match: any = useRouteMatch();
  const handleReadNoti = async (id: any) => {
    let res = await postReadNotiApi(id);
    if (res.status === 200) {
      setOpen(false);
    }
  };
  const funcLoad = () => {
    let postLast = [...notifications].pop();
    let maxId = postLast?.id;
    fetchNotification({ max_id: maxId, limit: 20 });
  };

  React.useEffect(() => {
    if (loaderRef.current) {
      const element: any = loaderRef.current;
      if (element?.offsetWidth > 0 && element?.offsetHeight > 0) {
        funcLoad();
      }
    }
  }, []);

  const loaderSkeleton = () =>
    Array.from(Array(2).keys()).map(item => (
      <Box
        sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
        ref={loaderRef}
      >
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>
        <Skeleton width="100%" style={{ marginLeft: '12px' }}>
          <Typography>.</Typography>
        </Skeleton>
      </Box>
    ));
  const history = useHistory();
  const arrConditionNoti = [
    'follow',
    'friendship_request',
    'accept_friendship_request',
    'product_invitation',
    'status_donate',
    'donate',
    'music_donate',
    'rejected_page_identity_verification',
    'approved_page_identity_verification',
    'rejected_identity_verification',
    'approved_identity_verification',
    'approved_earn_money',
    'rejected_earn_money',
    'favourite',
    'order'
  ];
  return (
    <ClickOutHandler onClickOut={() => setOpen(false)}>
      <Box
        id="scrollableDivNotification"
        className={classes.root}
        sx={
          match.path === '/notifications'
            ? { display: 'none', backgroundColor: 'background.primary' }
            : { display: 'block', backgroundColor: 'background.primary' }
        }
      >
        <Typography
          style={{
            fontSize: 24,
            fontWeight: 700,
            padding: '0px 8px 8px 8px'
          }}
        >
          Thông báo
        </Typography>
        <InfiniteScroll
          dataLength={notifications.length}
          next={funcLoad}
          hasMore={hasMore}
          loader={loaderSkeleton()}
          style={{ overflow: 'hidden' }}
          scrollableTarget="scrollableDivNotification"
          scrollThreshold={0.8}
        >
          <div>
            <div className={classes.rootNoti}>
              <ButtonCustom
                style={{
                  margin: '3px 5px',
                  maxWidth: '60px',
                  borderRadius: '20px',
                  fontSize: '15px',
                  backgroundColor: allNoti ? '#e7f3ff' : 'background.secondary',
                  color: allNoti ? buttonColor.backgroundColor : 'text.primary'
                }}
                label="Tất cả"
                action={() => setAllNoti(true)}
              ></ButtonCustom>
              <ButtonCustom
                style={{
                  margin: '3px 5px',
                  maxWidth: '80px',
                  borderRadius: '20px',
                  fontSize: '15px',
                  backgroundColor: !allNoti
                    ? '#e7f3ff'
                    : 'background.secondary',
                  color: !allNoti ? buttonColor.backgroundColor : 'text.primary'
                }}
                label="Chưa đọc"
                action={() => setAllNoti(false)}
              ></ButtonCustom>
            </div>
            {newNotis?.length > 0 && (
              <div>
                <div
                  className={classes.rootNoti}
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: '12px'
                  }}
                >
                  <Typography style={{ fontWeight: 600 }}>Mới</Typography>
                  <ButtonCustom
                    style={{
                      margin: '3px 5px',
                      maxWidth: '100px',
                      borderRadius: '5px',
                      fontSize: '15px',
                      fontWeight: 'normal',
                      backgroundColor: 'transparent',
                      color: buttonColor.backgroundColor
                    }}
                    label="Xem tất cả"
                    action={() => {
                      handleClosePreview && handleClosePreview();
                      history.push('/notifications');
                    }}
                  ></ButtonCustom>
                </div>
                {allNoti
                  ? newNotis?.map((el: any, index) =>
                      el?.status ||
                      el?.event ||
                      el?.course ||
                      el?.page ||
                      el?.group ||
                      el?.recruit ||
                      el?.project ||
                      el?.music ||
                      arrConditionNoti.includes(el?.type) ||
                      (el?.type === 'account_relationship' &&
                        el?.account_relationship) ||
                      !!el?.family_member ? (
                        <React.Fragment key={index}>
                          <CommonNoti
                            handleClosePreview={handleClosePreview}
                            item={el}
                            setOpen={() => handleReadNoti(el?.id)}
                          />
                        </React.Fragment>
                      ) : null
                    )
                  : newNotis?.map((el: any, index) =>
                      el?.read === false &&
                      (el?.status ||
                        el?.event ||
                        el?.course ||
                        el?.page ||
                        el?.group ||
                        el?.recruit ||
                        el?.project ||
                        el?.music ||
                        arrConditionNoti.includes(el?.type) ||
                        !!el?.family_member) ? (
                        <React.Fragment key={index}>
                          <CommonNoti
                            handleClosePreview={handleClosePreview}
                            item={el}
                            setOpen={() => handleReadNoti(el?.id)}
                          />
                        </React.Fragment>
                      ) : null
                    )}
              </div>
            )}
            {oldNotis?.length > 0 && (
              <div>
                <div
                  className={classes.rootNoti}
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: '12px'
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: buttonColor.backgroundColor
                    }}
                  >
                    Trước đó{' '}
                  </Typography>
                  {newNotis?.length === 0 && (
                    <ButtonCustom
                      style={{
                        margin: '3px 5px',
                        maxWidth: '100px',
                        borderRadius: '5px',
                        fontSize: '15px',
                        fontWeight: 'normal',
                        backgroundColor: 'transparent',
                        color: buttonColor.backgroundColor
                      }}
                      label="Xem tất cả"
                      action={() => history.push('/notifications')}
                    ></ButtonCustom>
                  )}
                </div>
                {allNoti
                  ? oldNotis?.map((el: any, index) =>
                      el?.status ||
                      el?.event ||
                      el?.course ||
                      el?.page ||
                      el?.group ||
                      el?.recruit ||
                      el?.project ||
                      el?.music ||
                      arrConditionNoti.includes(el?.type) ||
                      !!el?.family_member ? (
                        <React.Fragment key={index}>
                          <CommonNoti
                            handleClosePreview={handleClosePreview}
                            item={el}
                            setOpen={() => handleReadNoti(el?.id)}
                          />
                        </React.Fragment>
                      ) : null
                    )
                  : oldNotis?.map((el: any, index) =>
                      el?.read === false &&
                      (el?.status ||
                        el?.event ||
                        el?.course ||
                        el?.page ||
                        el?.group ||
                        el?.recruit ||
                        el?.project ||
                        el?.music ||
                        arrConditionNoti.includes(el?.type) ||
                        !!el?.family_member) ? (
                        <React.Fragment key={index}>
                          <CommonNoti
                            handleClosePreview={handleClosePreview}
                            item={el}
                            setOpen={() => handleReadNoti(el?.id)}
                          />
                        </React.Fragment>
                      ) : null
                    )}
              </div>
            )}
          </div>
        </InfiniteScroll>
      </Box>
    </ClickOutHandler>
  );
};

export default NotificationHeader;
