import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Theme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { scrollStyle, modalBackdrop, buttonColor } from 'src/constants/styles';
import CloseIcon from '@mui/icons-material/Close';

import InputSearch from 'src/components/Input/SocialInput/InputSearch';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { useSelector } from 'react-redux';

import { getFriendUser } from 'src/apis/socialUser';
import Loading from '../Loading/Loading';

const useStyles = makeStyles((theme: Theme) => ({
  wrapNoMedia: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: ' center',
    flexDirection: 'column',
    width: '100%',
    margin: '20px 0px'
  },
  wrapTag: {
    minHeight: 56,
    maxHeight: 150,
    borderRadius: 10,
    border: '1px solid rgba(0,0,0,0.2)',
    overflow: 'hidden auto',
    padding: '10px',
    ...scrollStyle
  },
  divWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.mode === 'dark' ? '#3A3B3C' : '#f5f5f5',
      borderRadius: 10
    }
  },
  divWrapImage: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapTagElement: {
    display: 'inline-block',
    backgroundColor: theme.palette.mode === 'dark' ? '#2D88FF33' : '#E7F3ff',
    width: 'fit-content',
    padding: '8px',
    borderRadius: 5,
    margin: 4
  },
  buttonClose: {
    display: 'inline',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  wrapText: {
    fontSize: '15px !important',
    fontWeight:
      theme.palette.mode === 'dark' ? 'bold !important' : '500 !important',
    color:
      theme.palette.mode === 'dark' ? '#2E89FF' : buttonColor.backgroundColor,
    display: 'inline'
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    borderBottom: '0',
    ...scrollStyle,
    padding: '8px 16px'
  },
  '& .MuiPaper-root': {
    width: 500,
    height: 700
  },
  '& .MuiDialogTitle-root': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  }
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs(props) {
  const {
    open,
    handleClose,
    friendSelected,
    setFriendSelected,
    updateTags,
    setUpdateTags
  } = props;
  const classes = useStyles();
  const meInfo = useSelector((state: any) => state?.meReducer.info);
  const [isLoading, setIsLoading] = React.useState(true);
  const [keyword, setKeyword] = React.useState<any>('');
  const [listFriend, setListFriend] = React.useState<any>([]);

  const handleDeleteTag = index => {
    setFriendSelected(prev => [
      ...prev.slice(0, index),
      ...prev.slice(index + 1)
    ]);
  };

  async function getFriendData(params) {
    let response = await getFriendUser(meInfo?.id, params, null);
    if (response.status === 200) {
      setIsLoading(false);
      setListFriend(
        response.data?.filter(
          (el: any) =>
            !friendSelected.map((item: any) => item.id).includes(el.id)
        )
      );
    }
  }

  React.useEffect(() => {
    if (keyword && meInfo && Object.keys(meInfo).length) {
      getFriendData({
        keyword: keyword
      });
      setIsLoading(true);
    } else {
      getFriendData('');
      setListFriend([]);
      setIsLoading(true);
    }
  }, [JSON.stringify(meInfo), keyword]);

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      //TransitionComponent={Transition}
      BackdropProps={modalBackdrop}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography
          style={{ fontWeight: 'bold', fontSize: 20 }}
          textAlign={'center'}
        >
          Gắn thẻ người khác
        </Typography>
      </BootstrapDialogTitle>
      <div
        style={{
          margin: '10px 14px 10px 14px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginRight: 0
        }}
      >
        <div style={{ width: 390 }}>
          <InputSearch
            keyword={keyword}
            setKeyword={setKeyword}
            label="Tìm kiếm bạn bè"
          />
        </div>
        <ButtonInherit
          action={() => {
            handleClose();
            setUpdateTags && setUpdateTags(true);
          }}
          label="Xong"
          variant="text"
          textColor={buttonColor.backgroundColor}
          style={{ width: 50, marginRight: 12 }}
        />
      </div>
      <DialogContent>
        {friendSelected.length ? (
          <>
            <div style={{ margin: '8px 0px' }}>
              <Typography
                style={{ color: '#65676b', fontSize: 14, fontWeight: 500 }}
              >
                ĐÃ GẮN THẺ
              </Typography>
            </div>
            <div className={classes.wrapTag}>
              {friendSelected?.map((friend: any, index) => (
                <div key={index} className={classes.wrapTagElement}>
                  <Typography className={classes.wrapText}>
                    {friend.display_name}
                  </Typography>{' '}
                  &nbsp;
                  <div className={classes.buttonClose}>
                    <i
                      onClick={() => {
                        handleDeleteTag(index);
                        setListFriend(prev => [friend, ...prev]);
                      }}
                      style={{
                        color: buttonColor.backgroundColor,
                        marginTop: 3
                      }}
                      className="fal fa-times"
                    ></i>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : null}
        <div style={{ margin: '16px 0px 8px 0px' }}>
          <Typography
            style={{ color: '#65676b', fontSize: 14, fontWeight: 500 }}
          >
            {keyword ? 'TÌM KIẾM' : 'GỢI Ý'}
          </Typography>
        </div>
        {isLoading ? (
          <Loading />
        ) : listFriend.length ? (
          <div style={{ margin: '8px 0px' }}>
            {listFriend?.map((el: any, index) => (
              <div
                key={index}
                onClick={() => {
                  setFriendSelected(prev => [...prev, el]);
                  setListFriend(prev => prev.filter(item => item.id !== el.id));
                  setKeyword('');
                }}
                className={classes.divWrap}
                style={{
                  borderRadius: 10
                }}
              >
                <div className={classes.divWrapImage}>
                  <AvatarSocial
                    type="feed"
                    avatarObj={el.avatar_media}
                    style={{ width: 40, height: 40 }}
                  />
                  &nbsp;&nbsp;
                  <div>
                    <Typography style={{ fontWeight: 500 }}>
                      {el.display_name}
                    </Typography>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={classes.wrapNoMedia}>
            <Typography
              style={{ color: '#65676b', fontSize: 15, fontWeight: 500 }}
            >
              Không tìm thấy người nào
            </Typography>
          </div>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
}
