import { Divider, Grid, Theme, Typography } from '@mui/material';import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import ListButton from 'src/components/List/ListButton';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        height: '100%',
        width: 310,
        backgroundColor: 'button.primary.background',
        position: 'fixed',
        padding: '5px 10px 40px 5px',
        boxShadow: '1px 0px 5px 0.4px rgb(0 0 0 / 10%)'
      },
      headerPage: {
        minHeight: 50,
        margin: '10px 0px 10px'
      },
      footerPage: {
        position: 'absolute',
        bottom: '55px',
        left: 0,
        width: '100%',
        zIndex: 1200
      },
      rootPage: {
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        '&:hover': {
          overflowY: 'auto'
        },
        '&:hover&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#bdbdbd',
          borderRadius: 10
        }
      },
      inputSearch: {
        width: '100%'
        // marginTop: '10px !important'
      },
      cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
          borderColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        '& fieldset': {
          display: 'none'
        },
        '&:hover': {
          backgroundColor:
            theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C',
        borderRadius: '30px !important',
        height: 35,
        padding: '7px !important',
        margin: 'auto'
      },
      notchedOutline: {
        border: `1px solid ${theme.palette.myBackgroundColor?.main} !important`
      },
      multilineColor: {
        fontSize: 13
      },
      avatar: {
        width: '56px !important',
        height: '56px !important'
      },
      list: {
        width: '100%'
      },
      selected: {
        backgroundColor: '#ECF3FF !important'
      }
    }),
  { index: 1 }
);

const Index = () => {
  const classes = useStyles();
  const history = useHistory();
  const match: any = useRouteMatch();
  const [keyParams, setKeyParams] = React.useState<any>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<any>('feed');
  const listMenuPages = [
    {
      id: 'common',
      title: 'Chung',
      icon: 'fa-light fa-gear',
      action: () => {
        history.push(`/settings/common`);
      },
      position: 'top'
    },
    {
      id: 'security',
      title: 'Bảo mật và đăng nhập',
      icon: 'fa-light fa-shield-plus',
      action: () => {
        history.push(`/settings/security`);
      },
      position: 'top'
    },
    {
      id: 'information',
      title: 'Thông tin của bạn trên Emso',
      icon: 'fa-light fa-memo-circle-info',
      action: () => {
        history.push(`/settings/information`);
      },
      position: 'top'
    },
    {
      id: 'personalPage',
      title: 'Trang cá nhân và gắn thẻ',
      icon: 'fa-light fa-tag',
      action: () => {
        history.push(`/settings/personalPage`);
      },
      position: 'bottom'
    },
    {
      id: 'publicPost',
      title: 'Bài viết công khai',
      icon: 'fa-light fa-earth-americas',
      action: () => {
        history.push(`/settings/publicPost`);
      },
      position: 'bottom'
    },
    {
      id: 'block',
      title: 'Chặn',
      icon: 'fa-light fa-user-lock',
      action: () => {
        history.push(`/settings/block`);
      },
      position: 'bottom'
    },
    {
      id: 'money',
      title: 'Bật kiếm tiền',
      icon: 'fa-light fa-sack-dollar',
      action: () => {
        history.push(`/settings/money`);
      },
      position: 'bottom'
    }
  ];

  React.useEffect(() => {
    let { key } = match.params;
    setSelectedIndex(key);
  }, [match.path, match.params.key]);
  const renderHeader = () => {
    return (
      <Grid container spacing={2}>
        <Grid container item justifyContent="space-between" alignItems="center">
          <Typography
            style={{ fontWeight: 'bold', paddingLeft: 4 }}
            variant="h5"
          >
            Cài đặt
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderBody = () => {
    return {
      bodyTop: (
        <div>
          <ListButton
            item={listMenuPages.filter(el => el.position === 'top')}
            selectedItem={selectedIndex}
            width={'290px'}
            type="watch"
            typeAction={'action'}
          />
        </div>
      ),
      bodyCenter: (
        <div style={{ marginBottom: '60px' }}>
          <ListButton
            item={listMenuPages.filter(el => el.position === 'bottom')}
            selectedItem={selectedIndex}
            width={'290px'}
            type="watch"
            typeAction={'action'}
          />
        </div>
      ),
      bodyFooter: null
    };
  };
  return (
    <div
      className={classes.root}
      style={{
        position:
          renderBody().bodyTop ||
          renderBody().bodyCenter ||
          renderBody().bodyFooter
            ? 'fixed'
            : 'static',

        width:
          renderBody().bodyTop ||
          renderBody().bodyCenter ||
          renderBody().bodyFooter
            ? keyParams
              ? 380
              : 'undefined'
            : 0,
        margin:
          renderBody().bodyTop ||
          renderBody().bodyCenter ||
          renderBody().bodyFooter
            ? 0
            : '5px 10px 40px -20px'
      }}
    >
      <div className={classes.headerPage}>{renderHeader()}</div>

      <div className={classes.rootPage}>
        {renderBody().bodyTop && <Divider />}
        {renderBody().bodyTop}
        {<div style={{ height: 10 }}></div>}
        {renderBody().bodyCenter && <Divider />}
        {renderBody().bodyCenter}
        {renderBody().bodyFooter && <Divider />}
      </div>
    </div>
  );
};

export default Index;
