import * as React from 'react';import 'react-perfect-scrollbar/dist/css/styles.css';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { getPostDetailApi, getPostMediaApi } from 'src/apis/socialPost.api';
import DialogPreviewMedia from 'src/components/Dialog/DialogPreviewMedia';
import SocialEmptyLayout from 'src/components/SocialEmptyLayout';

export default function FullScreenDialog(props: any) {
  const { openPreview, setOpenPreview, typeQuery } = props;
  const [postMedia, setPostMedia] = React.useState<any>(null);
  const [mediaSelected, setMediaSelected] = React.useState<any>(null);
  const [indexMedia, setIndexMedia] = React.useState(0);
  const [post, setPost] = React.useState<any>(null);
  const [postPrivate, setPostPrivate] = React.useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  let paramId: any = query.get('mediaId');

  const getPostMediaDetail = async (id: any) => {
    try {
      let res = await getPostMediaApi(id);
      if (res.status === 200) {
        if (!query.get('postMediaId')) {
          const postId = res.data.id;
          let response = await getPostDetailApi(postId);

          if (response.status === 200) {
            setPostMedia({
              ...res.data,
              comment_moderation: response.data.comment_moderation,
              off_comment: response.data.off_comment
            });
          }
        } else {
          setPostMedia(res.data);
        }
      }
    } catch (error: any) {
      if (error.response?.status === 403) {
        setPostPrivate(true);
      }
    }
  };
  const getPost = async (id: any) => {
    let res = await getPostDetailApi(id);
    if (res.status === 200) {
      setPost(res.data);
      setPostMedia(prev => ({
        ...prev,
        comment_moderation: res.data.comment_moderation,
        off_comment: res.data.off_comment
      }));
    }
  };

  React.useEffect(() => {
    if (query.get('postMediaId')) {
      if (paramId) {
        getPostMediaDetail(paramId);
      }
      getPost(query.get('postMediaId'));
    } else {
      if (paramId) {
        getPostMediaDetail(paramId);
      }
    }
  }, []);

  React.useEffect(() => {
    if (postMedia?.id) {
      let indexPost = post?.media_attachments?.findIndex(
        (el: any) => el.id === postMedia?.media_attachments[0]?.id
      );
      setIndexMedia(indexPost);
    }
  }, [JSON.stringify(postMedia?.id), JSON.stringify(post?.id)]);

  React.useEffect(() => {
    if (mediaSelected) {
      getPostMediaDetail(mediaSelected);
    }
  }, [mediaSelected]);
  return (
    <div>
      {!postPrivate ? (
        <DialogPreviewMedia
          open={openPreview}
          setOpen={setOpenPreview}
          postMedia={postMedia}
          post={post ?? postMedia}
          setPostMedia={setPostMedia}
          indexMedia={indexMedia}
          setIndexMedia={setIndexMedia}
          setMediaSelected={setMediaSelected}
          typeMedia={'media_detail'}
          typeQuery={typeQuery}
        />
      ) : (
        <SocialEmptyLayout
          icon="fa-light fa-file-lock"
          primaryText="Bạn hiện không xem được nội dung này"
          secondaryText="Lỗi này thường do chủ sở hữu chỉ chia sẻ nội dung với một nhóm nhỏ,
thay đổi người được xem hoặc đã xóa nội dung."
        />
      )}
    </div>
  );
}
