import { ListItem, ListItemText } from '@mui/material';import React from 'react';
import AvatarSocial from '../ProfileCardImage/AvatarSocial';
import NameSocial from '../ProfileCardImage/NameSocial';
import { cancelRequestFriendApi, requestFriendApi } from 'src/apis/socialUser';
import ButtonInherit from '../Button/ButtonInherit';
import { createDirectMessage } from 'src/apis/socialChat.apis';
import { selectedUserBoxChat } from 'src/store/action/socialChatAction';
import { useDispatch, useSelector } from 'react-redux';
import { handleChatNow } from 'src/pages/Chat/functionChat';
const UserTagItem = props => {
  const { item, index, handleClose } = props;
  const [relationships, setRelationships] = React.useState<any>([]);
  const dispatch = useDispatch();
  const rocketId = useSelector((state: any) => state.meReducer.info).id || '';
  const handleAction = async (type, userInfor) => {
    let response;
    if (type === 'removeRequest') {
      response = await cancelRequestFriendApi(userInfor.id);
    } else if (type === 'addFriend') {
      response = await requestFriendApi(userInfor.id);
    }
    if (response.status === 200) {
      setRelationships(response.data);
    }
  };

  const renderButton = userInfor => {
    if (userInfor?.relationships?.friendship_status === 'ARE_FRIENDS') {
      return (
        <div>
          <ButtonInherit
            label="Nhắn tin"
            icon={
              <i
                style={{ fontSize: 15 }}
                className="fa-brands fa-facebook-messenger"
              ></i>
            }
            style={{ backgroundColor: '#e4e6eb' }}
            action={() => handleChatNow(item, rocketId, dispatch)}
          />
        </div>
      );
    } else if (relationships?.friendship_status === 'OUTGOING_REQUEST') {
      return (
        <ButtonInherit
          label="Hủy yêu cầu"
          icon={
            <i style={{ fontSize: 15 }} className="fa-solid fa-user-xmark"></i>
          }
          action={() => handleAction('removeRequest', userInfor)}
          style={{ backgroundColor: '#e4e6eb' }}
        />
      );
    } else if (relationships?.friendship_status === 'CAN_REQUEST') {
      return (
        <ButtonInherit
          label="Thêm bạn bè"
          icon={
            <i style={{ fontSize: 15 }} className="fa-solid fa-user-plus"></i>
          }
          action={() => handleAction('addFriend', userInfor)}
          style={{ backgroundColor: '#e4e6eb' }}
        />
      );
    }
  };

  React.useEffect(() => {
    if (item?.relationships) {
      setRelationships(item?.relationships);
    }
  }, [JSON.stringify(item?.relationships)]);

  return (
    <ListItem key={index} secondaryAction={renderButton(item)}>
      <div style={{ position: 'relative' }}>
        <AvatarSocial
          type="feed"
          style={{ width: 40, height: 40 }}
          avatarObj={item?.avatar_media}
          isPopup
          object={item}
        />
      </div>
      &nbsp;&ensp;
      <ListItemText
        primary={<NameSocial isPopup name={item?.display_name} object={item} />}
      />
    </ListItem>
  );
};
export default UserTagItem;
