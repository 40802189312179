import * as React from 'react';import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  AlertTitle,
  Box,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Radio,
  RadioGroup,
  Theme,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import ButtonInherit from '../Button/ButtonInherit';
import SocialPostInformation from 'src/components/SocialPost/SocialPostInformartion';
import SocialContent from 'src/components/SocialPost/SocialContent';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOldPostSuccess } from 'src/store/action/socialPostAction';
import { deletePostApi } from 'src/apis/socialPost.api';
import { buttonColor } from 'src/constants/styles';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    backgroundColor: '#ededed !important',
    fontSize: '20px !important',
    color: '#333 !important',
    right: '0px',
    top: '18px',
    marginRight: '16px !important'
  },
  alertViolet: {
    width: '95% !important',
    alignItems: 'center',
    color:
      theme.palette.mode === 'dark' ? '#fff !important' : '#050505 !important',
    backgroundColor: 'transparent !important',
    marginBottom: '12px !important',
    padding: '0px !important',
    fontSize: '18px !important',
    fontWeight: '500 !important',
    '& .MuiAlert-icon': {
      fontSize: '30px !important'
    }
  },
  textViolate: {
    paddingBottom: '10px !important'
  },
  textAllList: {
    paddingBottom: '70px !important'
  },
  textList: {
    margin: '10px 0px 0px 18px !important '
  },
  cardMedia: {
    borderTopRightRadius: '6px',
    borderTopLeftRadius: '6px'
  },
  cardViolate: {
    width: '100%',
    border: `4px solid ${buttonColor.backgroundColor}`,
    borderRadius: '10px',
    padding: '0px !important',
    margin: '0 auto'
  },
  titleViolate: {
    padding: '10px 16px 10px !important'
  },
  contentViolate: {
    borderBottomRightRadius: '6px',
    borderBottomLeftRadius: '6px'
  },
  actionViolate: {
    justifyContent: 'end !important'
  },
  optionViolate: {
    fontSize: '22px !important',
    fontWeight: '500 !important',
    padding: '20px 10px 30px'
  },
  subTextViolate: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 0px 20px !important'
  },
  titleSubViolate: {
    padding: '20px 16px 0px !important'
  },
  titleFormControl: {
    padding: '10px 12px 20px 14px !important '
  },
  subNormal: {
    padding: '0px !important'
  },
  formControl: {
    width: '100%'
  },
  controlLabel: {
    margin: '0px !important',
    justifyContent: 'space-between'
  },
  textFormControl: {
    fontSize: '18px !important',
    fontWeight: '500 !important'
  },
  subTextForm: {
    fontSize: '12px !important',
    paddingLeft: '2px '
  },
  alertApply: {
    margin: '20px 0px'
  },
  imageReport: {
    maxHeight: '460px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
      borderRadius: '100px'
    }
  }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    overflow: 'unset !important'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    justifyContent: 'center'
  },
  '& .MuiPaper-root ': {
    maxWidth: '750px',
    padding: '10px',
    borderRadius: '8px !important',
    overflow: 'unset !important'
  },
  '& .MuiDialogTitle-root': {
    padding: '0px !important'
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  const classes = useStyles();

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.icon}
          sx={{
            position: 'absolute'
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function DialogViolate(props: any) {
  const { open, item, setOpenDialogViolate } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const [checkStep, setCheckStep] = React.useState(false);

  const handleNext = () => {
    if (value === 'rejected') {
      setActiveStep(activeStep + 2);
    } else {
      setActiveStep(activeStep + 1);
    }
  };
  const dispatch = useDispatch();

  const handleCloseViolet = async () => {
    if (value === 'rejected') {
      setOpenDialogViolate(false);
      return;
    }
    let res = await deletePostApi(item.status.id);

    if (res.status === 200) {
      dispatch(deleteOldPostSuccess(item.status.id));
      setOpenDialogViolate(false);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleChange = event => {
    setValue(event.target.value);
    setCheckStep(true);
  };

  const listViolate = [
    {
      id: 1,
      icon: (
        <i
          className="fa-brands fa-react"
          style={{
            fontSize: '24px',
            opacity: '2.5',
            paddingRight: '20px'
          }}
        ></i>
      ),
      text: 'Tiêu chuẩn cộng động của chúng tôi áp dụng chung cho tất cả mọi người trên Emso.'
    },
    {
      id: 2,
      icon: (
        <i
          className="fa-solid fa-shield-check"
          style={{
            fontSize: '24px',
            opacity: '2.5',
            paddingRight: '20px'
          }}
        ></i>
      ),
      text: 'Công nghệ của chúng tôi hỗ trợ nhiều ngôn ngữ để đảm bảo các tiêu chuẩn chúng tôi đưa ra được áp dụng nhất quán.'
    },
    {
      id: 3,
      icon: (
        <i
          className="fa-solid fa-earth-asia"
          style={{
            fontSize: '24px',
            opacity: '2.5',
            paddingRight: '20px'
          }}
        ></i>
      ),
      text: 'Chúng tôi đã sử dụng công nghệ để xem xét nội dung của bạn và kết quả cho thấy nội dung đó vi phạm tiêu chuẩn của chúng tôi.'
    }
  ];

  const renderButton = () => {
    return (
      <>
        {activeStep !== 0 && activeStep !== 5 && (
          <ButtonInherit
            label="Quay lại"
            action={handleBack}
            style={{
              width: '100px',
              height: '40px',
              fontSize: '18px'
            }}
          />
        )}
        <ButtonInherit
          color={buttonColor.backgroundColor}
          label={
            activeStep === 4
              ? 'Chấp nhận quyết định'
              : activeStep === 5
              ? 'Đóng'
              : 'Tiếp tục'
          }
          disabled={!checkStep && activeStep === 3}
          action={activeStep === 5 ? handleCloseViolet : handleNext}
          style={{
            width: activeStep === 4 ? '210px' : '100px',
            height: '40px',
            fontSize: '18px'
          }}
        />
      </>
    );
  };

  const renderHeader = (step: any) => {
    switch (step) {
      case 0:
        return (
          <Alert severity="warning" className={classes.alertViolet}>
            Bài viết của bạn vi phạm Tiêu chuẩn cộng đồng của chúng tôi về ảnh
            khỏa thân hoặc hoạt động tình dục
          </Alert>
        );
      case 1:
        return (
          <Typography className={classes.optionViolate}>
            Cách chúng tôi quyết định
          </Typography>
        );
      case 2:
        return (
          <Typography className={classes.optionViolate} sx={{ width: '93%' }}>
            Tiêu chuẩn của chúng tôi về ảnh khỏa thân hoặc hoạt động tình dục
          </Typography>
        );
      case 3:
        return (
          <Typography className={classes.optionViolate}>
            Quyết định của bạn ra sao?
          </Typography>
        );
      case 4:
        return (
          <Typography className={classes.optionViolate}>
            Chấp nhận quyết định?
          </Typography>
        );

      case 5:
        return (
          <Typography className={classes.optionViolate}>
            Bạn đã {value === 'rejected' ? 'không chấp nhận' : 'chấp nhận'}{' '}
            quyết định của chúng tôi
          </Typography>
        );

      default:
        throw new Error('Unknown step');
    }
  };

  const renderBody = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography className={classes.textViolate}>
              Bài viết của bạn không hiển thị với bất kỳ ai khác
            </Typography>
            <Typography className={classes.textViolate}>
              Tiêu chuẩn của chúng tôi áp dụng vì lợi ích người dùng và dựa trên
              cộng động
            </Typography>
            <div className={classes.cardViolate}>
              <SocialPostInformation
                type="feed"
                key={`${item?.status?.id}-postInformation`}
                post={item?.status}
                embed="embed"
              />
              <SocialContent
                key={`${item.status.id}-socialContent`}
                post={item.status}
                type="violate"
              />
            </div>
          </>
        );
      case 1:
        return listViolate?.map((item: any, index) => (
          <div key={item?.id ?? index} className={classes.subTextViolate}>
            <Typography>{item.icon}</Typography>
            <Typography className={classes.textViolate}>{item.text}</Typography>
          </div>
        ));
      case 2:
        return (
          <>
            <Typography className={classes.textViolate}>
              Chúng tôi đặt ra một bộ tiêu chuẩn để tất cả mọi người trên Emso
              đều cảm thấy hoan nghênh.
            </Typography>
            <ul className={classes.textAllList}>
              Ảnh khỏa thân hoặc nội dung gợi dục bao gồm:
              <li className={classes.textList}>
                Ảnh khỏa thân thể hiện bộ phận sinh dục
              </li>
              <li className={classes.textList}>Hoạt động tình dục</li>
              <li className={classes.textList}>
                Nhũ hoa phụ nữ(trừ trường hợp cho con bú, khoảnh khắc sau khi
                sinh, trường hợp liên quan đến sức khỏe và hành động phản đối)
              </li>
              <li className={classes.textList}>Ngôn từ khiêu dâm</li>
            </ul>
          </>
        );
      case 3:
        return (
          <>
            <FormControl className={classes.formControl}>
              <Typography className={classes.textViolate}>
                Bài viết của bạn sẽ không hiển thị với bất kỳ ai khác do vi phạm
                Tiêu chuẩn cộng đồng của chúng tôi về ảnh khỏa thân hoặc hoạt
                động tình dục
              </Typography>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="apply"
                  label={
                    <div>
                      <Typography className={classes.textFormControl}>
                        Chấp nhận quyết định
                      </Typography>
                      <FormHelperText
                        className={classes.subTextForm}
                        sx={{ fontSize: 11, margin: 0 }}
                      >
                        Bài viết của bạn sẽ không hiển thị với bất kỳ ai khác và
                        bạn không thể yêu cầu xem xét lại.
                      </FormHelperText>
                    </div>
                  }
                  control={<Radio />}
                  labelPlacement="start"
                  className={classes.controlLabel}
                />
                <FormControlLabel
                  value="rejected"
                  label={
                    <div style={{ paddingTop: '10px' }}>
                      <Typography className={classes.textFormControl}>
                        {' '}
                        Không đồng ý với quyết định
                      </Typography>
                      <FormHelperText
                        className={classes.subTextForm}
                        sx={{ fontSize: 11, margin: 0 }}
                      >
                        Chúng tôi sẽ sử dụng phản hồi của bạn làm cơ sở cho các
                        quyết định trong tương lai.
                      </FormHelperText>
                    </div>
                  }
                  control={<Radio />}
                  labelPlacement="start"
                  className={classes.controlLabel}
                />
              </RadioGroup>
            </FormControl>
          </>
        );
      case 4:
        return (
          <>
            <Typography className={classes.textViolate}>
              Sau đây là thông tin tóm tắt về những gì đã xảy ra:
            </Typography>
            <Alert severity="error" className={classes.alertApply}>
              <AlertTitle>
                Bài viết của bạn vi phạm Tiêu chuẩn cộng động của chúng tôi về
                ảnh khỏa thân hoặc hoạt động tình dục
              </AlertTitle>
              Nếu bạn tái phạm, chúng tôi có thể{' '}
              <strong>hạn chế tài khoản của bạn</strong>
            </Alert>
          </>
        );

      case 5:
        return (
          <div style={{ paddingBottom: '40px' }}>
            <Typography className={classes.textViolate}>
              Nếu sau này vẫn đăng nội dung vi phạm tiêu chuẩn của chúng tôi,khả
              năng đăng bài hoặc bình luận của bạn có thể sẽ bị hạn chế.
            </Typography>
            <Typography className={classes.textViolate}>
              {value === 'rejected'
                ? 'Chúng tôi sẽ sử dụng phản hồi của bạn làm cơ sở cho các quyết định trong tương lai.'
                : 'Chúng tôi đặt ra Tiêu chuẩn cộng đồng để khuyến khích người dùng thể hiện bản thân và kết nối với nhau theo cách tôn trọng người khác.'}
            </Typography>
          </div>
        );

      default:
        throw new Error('Unknown step');
    }
  };

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-video"
      open={open}
      fullWidth
    >
      <BootstrapDialogTitle id="customized-dialog-video">
        {renderHeader(activeStep)}
        <Divider />
      </BootstrapDialogTitle>
      <DialogContent
        className={
          activeStep === 0
            ? classes.titleViolate
            : activeStep === 3
            ? classes.titleFormControl
            : classes.titleSubViolate
        }
      >
        <DialogContent className={classes.subNormal}>
          <Box className={classes.imageReport}>{renderBody(activeStep)}</Box>
        </DialogContent>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.actionViolate}>
        {renderButton()}
      </DialogActions>
    </BootstrapDialog>
  );
}
