import * as types from 'src/constants/store/setting';export const getSettingReq = (params: any) => ({
  type: types.GET_SETTING_REQ,
  payload: {
    params
  }
});

export const getSettingSuccess = (data: any) => ({
  type: types.GET_SETTING_SUCCESS,
  payload: {
    data
  }
});

export const getColorImageReq = (
  params: any,
  setState: any,
  href: string,
  typeEndpoint?: string
) => ({
  type: types.GET_COLOR_IMAGE_REQ,
  payload: {
    params,
    setState,
    href,
    typeEndpoint
  }
});

export const getColorImageSuccess = (data: any, href: string) => ({
  type: types.GET_COLOR_IMAGE_SUCCESS,
  payload: {
    data,
    href
  }
});

export const resetRequest = () => ({
  type: types.RESET_REQ
});

export const saveLinkBanner = (link: any, page) => ({
  type: types.SAVE_LINK_BANNER,
  payload: {
    link,
    page
  }
});

export const saveFileBanner = (file: any) => ({
  type: types.SAVE_FILE_BANNER,
  payload: {
    file
  }
});

export const uploadFileBanner = isLoading => ({
  type: types.UPLOAD_FILE_BANNER,
  payload: {
    isLoading
  }
});

export const checkHasScroll = (hasScroll: any) => ({
  type: types.CHECK_HAS_SCROLL,
  payload: { hasScroll }
});

export const checkSetLoading = value => ({
  type: types.SET_LOADING_REQ,
  payload: value
});
