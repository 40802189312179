import * as React from 'react';
import {
  Dialog,
  DialogContent,
  Divider,
  Typography,
  DialogTitle,
  DialogActions,
  Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  updateTopicConversation,
  getAllTopics
} from 'src/apis/socialChat.apis';
import { useDispatch, useSelector } from 'react-redux';

import IconButtonOptions from 'src/components/Button/IconButtonOption';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { changedTopic } from 'src/store/action/socialChatAction';
import Dexie from 'dexie';
import { buttonColor } from 'src/constants/styles';

function DialogChangedTopic(props) {
  const { open, setOpen, conversation } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const [topicConversation, setTopicConversation] = React.useState<string>('');
  const rocketId = useSelector((state: any) => state.meReducer.info).id || '';
  const [listTopic, setListTopic] = React.useState<any>([]);
  // const [selectedTopic, setSelectedTopic] = React.useState<string>('')
  const handleClose = () => {
    setOpen(false);
  };

  const fetchListTopic = async () => {
    try {
      const response = await getAllTopics();
      if (response.status === 200) {
        setListTopic(response.data.result);
      }
    } catch (err) {}
  };

  React.useEffect(() => {
    fetchListTopic();
  }, []);
  const handleGetTopic = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const id = (e.target as HTMLSpanElement).id;
    setTopicConversation(id);
  };
  const handleChangeTopic = async () => {
    const roomId = conversation.rid;
    try {
      if (topicConversation) {
        const response = await updateTopicConversation(
          roomId,
          topicConversation
        );

        if (response.status === 200) {
        }
      }
    } catch (error) {}
  };

  const handleSaveTopic = async () => {
    await handleChangeTopic();
    handleClose();
  };

  const currentlyTopic = conversation.RoomsList?.[0]?.topic?._id;

  const BoxSelectTopic = props => {
    const { topic } = props;
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '10px'
        }}
        key={topic._id}
      >
        <Box
          id={topic._id}
          style={{
            background: `linear-gradient(to bottom, ${topic.colors?.[0]}, ${topic.colors?.[1]})`,
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            cursor: 'pointer',
            boxShadow:
              topicConversation === topic._id
                ? ` 0 0px 16px ${
                    theme.palette.mode === 'light' ? '#666' : '#111'
                  }`
                : '',
            transition: 'all .5s linear'
          }}
          onClick={handleGetTopic}
        />
      </div>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: '8px',
            height: '600px',
            width: '500px',
            zIndex: 0
          }
        }}
      >
        <DialogTitle
          style={{
            display: 'flex',
            width: '100%',
            height: '40px',
            padding: '8px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'calc(100% - 36px)',
              height: '100%'
            }}
          >
            <Typography style={{ fontSize: '20px', fontWeight: '700' }}>
              Chủ đề
            </Typography>
          </div>
          <div
            style={{
              height: '100%',
              width: '36px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <IconButtonOptions
              icon={
                <i
                  style={{ fontSize: 14, fontWeight: 600, color: '#505256' }}
                  className="fa-light fa-xmark-large"
                ></i>
              }
              action={() => handleClose()}
              style={{
                borderRadius: '50%',
                width: '36px',
                marginRight: '0',
                zIndex: 1,
                top: '12px',
                right: '16px',
                position: 'absolute'
              }}
            />
          </div>
        </DialogTitle>
        <Divider sx={{ marginTop: '5px' }} />
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 0
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
              padding: '5px 20px',

              flexWrap: 'wrap'
            }}
          >
            {listTopic.map(topic => (
              // BoxSelectTopic(backgroundTopic, nameTopic)
              <BoxSelectTopic
                key={topic._id}
                // backgroundTopic={backgroundTopic}
                topic={topic}
              />
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonInherit
            label={'Hủy'}
            style={{
              width: '40px',
              height: '30px',
              color: buttonColor.backgroundColor,
              backgroundColor: '#e4e6eb',
              fontSize: '12px',
              fontWeight: '500'
            }}
            action={handleClose}
          />
          <ButtonInherit
            label={'Lưu'}
            style={{
              width: '40px',
              height: '31px',
              color: '#fff',
              backgroundColor: buttonColor.backgroundColor,
              fontSize: '12px',
              fontWeight: '500'
            }}
            disabled={topicConversation === currentlyTopic ? true : false}
            action={handleSaveTopic}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogChangedTopic;
