import { styled } from '@mui/material/styles';
import React from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import {
  Box,
  Button,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { buttonColor } from 'src/constants/styles';

interface StyledTabProps {
  label: any;
  icon: any;
  keyTab: any;
  key: any;
}

const AntTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    display: 'none'
  }
});

const AntTab = styled((props: StyledTabProps) => (
  <Tab
    disableRipple
    {...props}
    iconPosition="end"
    icon={props?.icon ? props.icon : null}
    sx={{ color: 'text.primary' }}
  />
))(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  fontSize: '14px',
  opacity: 1,
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',

    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(','),
  '&:hover': {
    color: buttonColor.backgroundColor,
    opacity: 1
  },
  '&.Mui-selected': {
    color: buttonColor.backgroundColor,
    fontWeight: theme.typography.fontWeightMedium,
    opacity: 1
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff'
  }
}));

function Index(props) {
  const { keyTab, setKeyTab, listButton, type } = props;
  const [openPopper, setOpenPopper] = React.useState(false);

  const totalButton = React.useMemo(
    () =>
      listButton.reduce((result: number, button) => {
        if (button.disabled === false) {
          result += 1;
          return result;
        } else {
          return result;
        }
      }, 0),
    [listButton]
  );

  const totalShowButton = React.useMemo(
    () =>
      listButton.reduce((result: number, button) => {
        if (button.minWidthShowTab === true) {
          result += 1;
          return result;
        } else {
          return result;
        }
      }, 0),
    [listButton]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    let curentKeyTab: any = keyTab.id;
    event.stopPropagation();
    setKeyTab({
      id: curentKeyTab,
      anchorEl: event.currentTarget
    });
    setOpenPopper(prev => !prev);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    let newKeyValue = listButton[newValue].id;
    if (newKeyValue !== 'more') {
      setKeyTab(newKeyValue);
      setOpenPopper(false);
    }
    setOpenPopper(false);
  };

  return (
    <>
      <AntTabs
        scrollButtons="auto"
        value={listButton.findIndex(el => el?.id === keyTab?.id)}
        onChange={handleChange}
      >
        {listButton
          ?.filter((el: any, index: any) =>
            (el?.minWidthShowTab &&
              (index < 6 || index === listButton.length - 1)) ||
            type === 'moment-search'
              ? el
              : null
          )
          ?.filter((el: any) =>
            totalButton !== totalShowButton ? el : el.id === 'more' ? null : el
          )
          .map((el: any) => (
            <Button
              size="small"
              key={el.id}
              onClick={el?.icon ? handleClick : el.action}
              sx={{
                padding: '0 0px !important',
                height: '60px',
                borderRadius: '0px',
                borderBottom:
                  el.id === keyTab.id ||
                  (el.id === 'more' &&
                    listButton
                      .filter((el: any) => (!el.minWidthShowTab ? el : null))
                      .find((item: any) => item.id === keyTab.id))
                    ? `3px solid ${buttonColor.backgroundColor}`
                    : null,
                '&.MuiButtonBase-root': {
                  opacity: 1
                },
                opacity: 1,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <AntTab
                key={el.id}
                label={el.label}
                icon={el?.icon ? el.icon : null}
                keyTab={keyTab.id}
              />
            </Button>
          ))}
      </AntTabs>
      {openPopper && (
        <ClickAwayListener onClickAway={() => setOpenPopper(false)}>
          <Popper
            open={openPopper}
            anchorEl={keyTab.anchorEl}
            placement="bottom-end"
            disablePortal={true}
            style={{
              borderRadius: '8px',
              zIndex: 1001,
              marginTop: '15px !important',
              padding: '12px 0px'
            }}
          >
            <Box
              sx={{
                padding: '10px 0px',
                width: '340px',
                backgroundColor: 'background.primary',
                boxShadow:
                  'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
                borderRadius: '6px'
              }}
            >
              <React.Fragment>
                {listButton
                  .filter((el: any) => (el.minWidthShowTab ? null : el))
                  .map((el: any, index) => (
                    <>
                      {el?.icon || el?.avatar_icon || el?.label ? (
                        <ListItemButton
                          key={el}
                          sx={{
                            margin: '0px 8px',
                            padding: '4px 4px',
                            borderRadius: '5px',
                            maxWidth: 340
                          }}
                          onClick={() => {
                            el.action();
                            setOpenPopper(false);
                          }}
                        >
                          {el.icon && (
                            <ListItemIcon
                              sx={{
                                minWidth: '25px',
                                marginRight: '10px'
                              }}
                            >
                              {el.icon}
                            </ListItemIcon>
                          )}
                          {el.avatar_icon && (
                            <ListItemIcon
                              sx={{
                                minWidth: '25px',
                                marginRight: '10px'
                              }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: 36,
                                  width: 36,
                                  margin: '0px 10px 0px 0px',
                                  borderRadius: '50%'
                                }}
                                src={el.avatar_icon}
                              />
                            </ListItemIcon>
                          )}
                          <ListItemText>
                            <Typography
                              fontWeight={500}
                              fontSize={'15px'}
                              fontFamily={
                                'Segoe UI, Roboto, Helvetica, Arial, sans-serif'
                              }
                              sx={{ display: 'inline-block' }}
                            >
                              {el.label}
                            </Typography>
                          </ListItemText>
                          {keyTab.id === el.id && (
                            <i
                              style={{ color: '#3935f4', margin: '0 10px' }}
                              className="fa-light fa-check"
                            ></i>
                          )}
                        </ListItemButton>
                      ) : null}
                    </>
                  ))}
              </React.Fragment>
            </Box>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
}

export default Index;
