import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemButton,
  Typography,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Popover,
  Tooltip,
  DialogActions
} from '@mui/material';
import React from 'react';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { DeleteOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';

export default function LableChatPage(props) {
  const {
    labelFilter,
    checked,
    handleChange,
    handleClickAddLabel,
    handleClickOpenDialog,
    openDialog,
    handleCloseDialog,
    openFormAddLable,
    handleDelete,
    setOpenFormAddLable,
    handleOnKeyDown,
    handleInputTitleLabel,
    anchorElPopover,
    colorLabel,
    setAnchorElPopover,
    setColorLabel,
    colorLabelOptions,
    handleAddLabelPage,
    handleDeleteFilter
  } = props;
  const openPopover = Boolean(anchorElPopover);

  const idPopover = openPopover ? 'simple-popover' : undefined;

  return (
    <Box sx={{ maxHeight: '450px', overflow: 'auto' }}>
      {labelFilter.map((option: any, index: any) => {
        return (
          <Box key={`${option.color}`}>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ padding: '0px 8px' }}
                selected={checked === `${option.title}` ? true : false}
              >
                <FormControlLabel
                  sx={{ width: '100%' }}
                  control={
                    <Checkbox
                      size="small"
                      checked={checked.indexOf(`${option.title}`) !== -1}
                      onChange={event => {
                        handleChange(event, `${option.title}`);

                        if (checked.indexOf(`${option.title}`) !== -1) {
                          handleDeleteFilter(option);
                        } else {
                          handleClickAddLabel(option);
                        }
                      }}
                      name={`${option.title}`}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={
                    <Chip
                      sx={{
                        backgroundColor: 'transparent',
                        '& .MuiChip-label': {
                          fontSize: '15px',
                          fontWeight: 450
                        }
                      }}
                      icon={
                        <LocalOfferIcon
                          sx={{
                            width: '18px',
                            height: '18px',
                            color: `${option.color} !important`
                          }}
                        />
                      }
                      label={option.title}
                    />
                  }
                />
              </ListItemButton>
            </ListItem>
          </Box>
        );
      })}
      <Divider />
      <ListItemButton onClick={handleClickOpenDialog}>
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: '500',
            width: '100%',
            textAlign: 'center'
          }}
        >
          Quản lý nhãn
        </Typography>
      </ListItemButton>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="xs"
      >
        {!openFormAddLable ? (
          <>
            <DialogTitle id="alert-dialog-title" sx={{ paddingBottom: '4px' }}>
              Quản lý nhãn
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent
              sx={{ maxHeight: '60vh', padding: '0px 24px 20px 24px' }}
            >
              {labelFilter.map((option: any, index: any) => {
                return (
                  <Box key={`${option.color}`}>
                    <ListItem
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="comments"
                          onClick={handleDelete(option)}
                        >
                          <DeleteOutlined
                            style={{
                              color: option.color,
                              width: '18px',
                              height: '18px'
                            }}
                          />
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton
                        sx={{ paddingLeft: '0px' }}
                        selected={
                          checked === `${option.title}${index}` ? true : false
                        }
                      >
                        <Chip
                          sx={{
                            backgroundColor: 'transparent',
                            '& .MuiChip-label': {
                              fontSize: '16px ',
                              fontWeight: 450
                            }
                          }}
                          icon={
                            <LocalOfferIcon
                              sx={{
                                width: '18px',
                                height: '18px',
                                color: `${option.color} !important`
                              }}
                            />
                          }
                          label={option.title}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Box>
                );
              })}

              <Button
                variant="text"
                startIcon={<AddIcon />}
                sx={{ textTransform: 'none' }}
                onClick={() => {
                  setOpenFormAddLable(true);
                }}
              >
                Thêm nhãn
              </Button>
            </DialogContent>
          </>
        ) : (
          <>
            <IconButton
              aria-label="close"
              onClick={() => {
                setOpenFormAddLable(false);
              }}
              sx={{
                position: 'absolute',
                left: 8,
                top: 8,
                color: theme => theme.palette.grey[500]
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <DialogTitle
              sx={{ marginLeft: '35px', paddingTop: '11px' }}
              id="customized-dialog-title"
            >
              Thêm mới nhãn
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              {/* <Box sx={{ position: 'relative' }}>
                      <TextField
                        autoFocus
                        id="name"
                        label="Tên nhãn"
                        fullWidth
                      />
                    </Box> */}
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="standard-adornment-label">
                  Tên nhãn
                </InputLabel>
                <OutlinedInput
                  id="standard-adornment-label"
                  label="Tên nhãn"
                  onChange={e => handleInputTitleLabel(e)}
                  onKeyDown={e => handleOnKeyDown(e)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle label visibility"
                        sx={{ paddingRight: 2 }}
                        aria-describedby={idPopover}
                        onClick={(event: any) => {
                          setAnchorElPopover(event.currentTarget);
                        }}
                      >
                        <LocalOfferIcon
                          sx={{
                            width: '18px',
                            height: '18px',
                            color: `${colorLabel} !important`
                          }}
                        />
                      </IconButton>
                      <Popover
                        id={idPopover}
                        open={openPopover}
                        anchorEl={anchorElPopover}
                        onClose={() => {
                          setAnchorElPopover(null);
                        }}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                        style={{ display: 'flex' }}
                      >
                        {colorLabelOptions.map(option => (
                          <Tooltip
                            key={option.color}
                            title={option.title}
                            arrow
                            placement="left-start"
                          >
                            <IconButton
                              onClick={() => {
                                setColorLabel(option.color);
                                setAnchorElPopover(null);
                              }}
                            >
                              <LocalOfferIcon
                                sx={{
                                  width: '18px',
                                  height: '18px',
                                  color: `${option.color} !important`
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        ))}
                      </Popover>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ textTransform: 'none' }}
                onClick={() => {
                  setOpenFormAddLable(false);
                }}
              >
                Huỷ
              </Button>
              <Button
                sx={{ textTransform: 'none' }}
                onClick={() => {
                  handleAddLabelPage();
                  setOpenFormAddLable(false);
                }}
              >
                Thêm Nhãn
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
}
