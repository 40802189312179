import axios, { AxiosRequestConfig } from 'axios';
import { USER_TOKEN } from 'src/util/config';
import { BASE_ROOT } from 'src/util/config';

const fetchApi = async (endPoint, method, params, formData) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: formData
  };

  let response = await axios(configs);
  return response;
};
const fetchApiV2 = async (endPoint, method, params, formData) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v2/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: formData
  };

  let response = await axios(configs);
  return response;
};

const fetchApiAdmin = async (endPoint, method, params, formData) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/admin/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: formData
  };

  let response = await axios(configs);
  return response;
};

const fetchApiRequest = async (endPoint, method, params, _data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

export const getArtistApi = async (params: any) => {
  return fetchApiRequest('artists', 'GET', params, null);
};

export const getTopAlbumApi = async (params: any) => {
  return fetchApiRequest('top_albums', 'GET', params, null);
};
export const getMyAlbumApi = async (params: any) => {
  return fetchApiRequest('playlists', 'GET', params, null);
};
export const getCategoriesMusic = async () => {
  return fetchApiRequest('music_categories', 'GET', null, null);
};
export const getTopMusicApi = async (params: any) => {
  return fetchApiRequest('top_musics', 'GET', params, null);
};
export const getMusicInPlaylistApi = async (idALbum: any, params: any) => {
  return fetchApiRequest(`songlists/${idALbum}/musics`, 'GET', params, null);
};
export const getMusicOfArtistApi = async (params: any, IdArtist: any) => {
  return fetchApiRequest(`artists/${IdArtist}/musics`, 'GET', params, null);
};
export const getAlbumMusicApi = async (params: any, IdAlbum: any) => {
  return fetchApiRequest(`playlists/${IdAlbum}/musics`, 'GET', params, null);
};
export const getDetailAlbumApi = async (IdAlbum: any) => {
  return fetchApiRequest(`playlists/${IdAlbum}`, 'GET', null, null);
};
export const getPlaylistApi = async (params: any) => {
  return fetchApiRequest(`songlists`, 'GET', params, null);
};
export const searchFriendUserMusic = async (params: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/friendships`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };
  let response = await axios(configs);
  return response;
};
export const createUpdateMusicApi = async (data: any) => {
  var formdata = new FormData();
  formdata.append('name', data.title);
  formdata.append('music_category_id', data.category_music_id);

  for (var i = 0; i < data.music_host_added.length; i++) {
    formdata.append('music_author_added[]', data.music_host_added[i]);
  }

  if (data.fileMp3.id) {
    formdata.append('media_id', data.fileMp3.id);
  }
  if (data.banner.id) {
    formdata.append('banner_id', data.banner.id);
  }

  if (data.page_owner_id) {
    formdata.append('page_owner_id', data.page_owner_id.id);
  }

  return fetchApi('musics', 'POST', null, formdata);
};

export const createUpdateAlbum = async (data: any, type, idAlbum) => {
  return fetchApi(
    type === 'update' ? `playlists/${idAlbum}` : 'playlists',
    type === 'update' ? 'PATCH' : 'POST',
    null,
    data
  );
};

export const removeAlbumApi = async (id: any) => {
  return fetchApi(`playlists/${id}`, 'DELETE', null, null);
};

export const createUpdatePlaylistApi = async (data: any, type, idPlaylist) => {
  const formdata = new FormData();
  formdata.append('name', data.name);
  if (data.banner) {
    if (data.banner.id) {
      formdata.append('banner_id', data.banner.id);
    } else {
      formdata.append('banner_id', data.banner.file.id);
    }
  }

  return fetchApi(
    type === 'update' ? `songlists/${idPlaylist}` : 'songlists',
    type === 'update' ? 'PATCH' : 'POST',
    null,
    formdata
  );
};

export const removePlaylistApi = async (id: any) => {
  return fetchApiRequest(`songlists/${id}`, 'DELETE', null, null);
};
export const removeMusicInPlaylistApi = async (id: any, data: any) => {
  return fetchApiRequest(`songlists/${id}/remove_music`, 'POST', null, data);
};
export const getAlbumCategoryApi = async (id: any, params: any) => {
  return fetchApiRequest(`music_categories/${id}/albums`, 'GET', params, null);
};
export const likeFanApi = async (id: any, params: any) => {
  return fetchApiRequest(`artists/${id}/fans`, 'GET', params, null);
};
export const getArtistsAlbumApi = async (id: any, params: any) => {
  return fetchApiRequest(`artists/${id}/albums`, 'GET', params, null);
};
export const getNewMusicApi = async (params: any) => {
  return fetchApiRequest(`latest_musics`, 'GET', params, null);
};
export const getListMusicUploadted = async params => {
  return fetchApiRequest(`uploaded_songs`, 'GET', params, null);
};
export const getListAlbumUploaded = async params => {
  return fetchApiRequest(`created_albums`, 'GET', params, null);
};
export const countListen = async (data: any) => {
  return fetchApiRequest(`music_histories`, 'post', null, data);
};
export const addMusicPlaylist = async (id: any, data: any) => {
  let musicId = {
    music_ids: [data]
  };
  return fetchApi(`songlists/${id}/add_music`, 'POST', null, musicId);
};
export const getDetailMusic = async (id: any) => {
  return fetchApi(`musics/${id}`, 'GET', null, null);
};
export const deleteMusic = async data => {
  return fetchApi(`uploaded_songs`, 'DELETE', null, data);
};
export const addMusicToAlbum = async (id: any, data) => {
  return fetchApi(`playlists/${id}/add_music`, 'POST', null, data);
};
export const removeMusicInAlbum = async (id: any, data: any) => {
  return fetchApiRequest(`playlists/${id}/remove_music`, 'POST', null, data);
};
export const musicByCategories = async (params: any) => {
  return fetchApi(`list_by_categories`, 'GET', params, null);
};
export const getListInvitesAuthorsMusic = async () => {
  return fetchApi(`music_invitation_authors`, 'GET', null, null);
};
export const respondInvite = async (id: any, params: any) => {
  return fetchApi(
    `musics/${id}/invitation_authors/invitations_respond`,
    'POST',
    params,
    null
  );
};

export const searchMusicApi = async (keyWord: any) => {
  return fetchApiV2(
    `search?type[]=musics&type[]=playlists&q=${encodeURIComponent(
      keyWord
    )}&limit=3&offset=0`,
    'GET',
    null,
    null
  );
};

export const getListAlbumAdmin = async params => {
  return fetchApiAdmin('playlists', 'GET', params, null);
};

export const getDetailAlbumAdmin = async idAlbum => {
  return fetchApiAdmin(`playlists/${idAlbum}`, 'GET', null, null);
};
