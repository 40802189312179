import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import {
  getDetailActivitiesOrEmojiApi,
  getListActivitiesOrEmojiApi
} from 'src/apis/commonSocialApi';
import { searchUserGlobal } from 'src/apis/socialSearch.api';
import { buttonColor, modalBackdrop, scrollStyle } from 'src/constants/styles';
import { useTheme } from 'styled-components';
import imageDefault from 'src/assets/images/pages_profile.png';
import _ from 'lodash';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    borderBottom: 0,
    ...scrollStyle,
    padding: '8px 12px'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiPaper-root': {
    width: 500
  }
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles((theme: any) => ({
  backgroundPost: {
    height: 120,
    width: 120,
    '&:hover': {
      cursor: 'pointer'
    },
    borderRadius: 15
  },
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  inputSearch: {
    width: '100%'
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
    },
    '& fieldset': {
      display: 'none'
    },
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
    },
    backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C',
    borderRadius: '30px !important',
    height: 35,
    padding: '7px !important',
    margin: 'auto'
  },
  notchedOutline: {
    border: `1px solid ${theme.palette.myBackgroundColor?.main} !important`
  },
  multilineColor: {
    fontSize: 13
  },
  divImage: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    backgroundColor: '#DCDCDC',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  divWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.button.primary.hover,
      borderRadius: 10
    }
  },
  divWrapImage: {
    display: 'flex',
    alignItems: 'center'
  }
}));

interface StyledTabProps {
  label: string;
}

const AntTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: buttonColor.backgroundColor
  }
});

const AntTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0
  },
  fontWeight: 'bold',
  fontSize: '16px',
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.85)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',

    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(','),
  '&:hover': {
    color: buttonColor.backgroundColor,
    opacity: 1
  },
  '&.Mui-selected': {
    color: buttonColor.backgroundColor,
    fontWeight: theme.typography.fontWeightMedium
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff'
  }
}));

export default function CustomizedDialogs(props) {
  const classes = useStyles();
  const {
    open,
    handleClose,
    activitiesSelected,
    setActivitiesSelected,
    parentSelected,
    setParentSelected
  } = props;
  const theme: any = useTheme();
  const [value, setValue] = React.useState(0);
  const [keyword, setKeyword] = React.useState('');
  const [isSubItem, setIsSubItem] = React.useState(false);
  const [pageActivities, setPageActivities] = React.useState<any>([]);
  const [listActivitiesEmoji, setListActivitiesEmoji] = React.useState<any>([]);
  const [listDetailActivity, setListDetailActivity] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<any>(false);

  const fetchActivitiesEmoji = async params => {
    let res = await getListActivitiesOrEmojiApi(params);
    if (res.status === 200) {
      setListActivitiesEmoji(res.data.data);
      setIsLoading(false);
    }
  };

  const fetchDetailActivitiesOrEmoji = async (id, params) => {
    let res = await getDetailActivitiesOrEmojiApi(id, params);
    setListDetailActivity([...res.data.data]);
    setIsLoading(false);
  };

  const handleSearchPage = React.useCallback(
    _.debounce(async (q: any) => {
      let response = await searchUserGlobal({
        type: 'pages',
        q: q ? q : null,
        limit: 3
      });
      if (response.status === 200) {
        setPageActivities([...response.data.pages]);
        setIsLoading(false);
      }
    }, 500),
    []
  );

  const handleSearchEmoji = React.useCallback(
    _.debounce(async function (params, item, type) {
      setIsLoading(true);
      if (type === 'parent_activity') {
        fetchDetailActivitiesOrEmoji(item.id, params);
      } else {
        fetchActivitiesEmoji(params);
      }
    }, 500),
    []
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      setIsSubItem(false);
    }
    if (newValue === 1 && parentSelected) {
      setListActivitiesEmoji([]);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    setKeyword('');
    setValue(newValue);
  };

  React.useEffect(() => {
    let params = {
      page: 1,
      per_page: 100,
      type: value ? 'activity' : 'emoji'
    };

    if (!isSubItem && open) {
      if (value === 1 && parentSelected) {
        setListActivitiesEmoji([]);
      } else {
        setIsLoading(true);
        fetchActivitiesEmoji(params);
      }
    }
  }, [value, isSubItem, open, parentSelected]);

  React.useEffect(() => {
    if (keyword !== '') {
      if (parentSelected) {
        if (listDetailActivity.length) {
          handleSearchEmoji(
            {
              page: 1,
              per_page: 100,
              keyword: keyword
            },
            parentSelected,
            'parent_activity'
          );
        } else {
          setIsLoading(true);
          handleSearchPage(keyword);
        }
      } else {
        handleSearchEmoji(
          {
            page: 1,
            per_page: 100,
            type: value ? 'activity' : 'emoji',
            keyword: keyword
          },
          null,
          'activity_emoji'
        );
      }
    }
  }, [keyword]);

  React.useEffect(() => {
    if (parentSelected) {
      let params = {
        page: 1,
        per_page: 100
      };
      if (value) {
        setIsSubItem(true);
      } else {
        setIsSubItem(false);
      }
      setIsLoading(true);
      setListActivitiesEmoji([]);
      fetchDetailActivitiesOrEmoji(parentSelected.id, params);
    }
  }, [parentSelected]);

  const handleDelete = () => {
    setActivitiesSelected(null);

    setIsLoading(true);
    setIsSubItem(false);
    setParentSelected(null);
    setListDetailActivity([]);
    setKeyword('');
  };
  const renderLoading = () => {
    return <CircularProgress size={30} />;
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        //TransitionComponent={Transition}
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{ fontWeight: '700', fontSize: 20 }}
            textAlign={'center'}
          >
            {value ? 'Bạn đang làm gì?' : 'Bạn đang cảm thấy thế nào?'}
          </Typography>
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ height: 500 }}>
          <Grid container spacing={2}>
            <Box>
              <AntTabs
                value={value}
                onChange={handleChange}
                aria-label="ant example"
              >
                <AntTab label="Cảm xúc" sx={{ color: 'text.primary' }} />
                <AntTab label="Hoạt động" sx={{ color: 'text.primary' }} />
              </AntTabs>
            </Box>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              item
              spacing={2}
            >
              {parentSelected &&
              parentSelected.data_type === 'activity' &&
              value ? (
                <Grid item xs={4}>
                  <Chip
                    color="primary"
                    variant="outlined"
                    icon={
                      <img
                        src={parentSelected?.url ?? imageDefault}
                        alt={parentSelected?.name}
                        width={20}
                        height={20}
                        onError={(e: any) => (e.target.src = imageDefault)}
                        style={{ borderRadius: '50%' }}
                      />
                    }
                    label={parentSelected.name}
                    onDelete={handleDelete}
                  />
                </Grid>
              ) : null}
              <Grid
                item
                xs={
                  activitiesSelected &&
                  activitiesSelected.data_type === 'activity' &&
                  value
                    ? 7
                    : 12
                }
              >
                <TextField
                  classes={{ root: classes.inputSearch }}
                  size="small"
                  fullWidth
                  variant="outlined"
                  placeholder="Tìm kiếm..."
                  value={keyword}
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    startAdornment: (
                      <i
                        style={{ marginRight: 5, padding: 5 }}
                        className="fas fa-search"
                      ></i>
                    ),
                    classes: {
                      root: classes.cssOutlinedInput,
                      notchedOutline: classes.notchedOutline
                    },
                    className: classes.multilineColor
                  }}
                  onChange={e => {
                    setKeyword(e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              {isLoading ? (
                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%'
                  }}
                >
                  {renderLoading()}
                </Grid>
              ) : (
                <>
                  {listActivitiesEmoji.length > 0 ||
                  listDetailActivity.length > 0 ||
                  pageActivities.length > 0 ? (
                    <>
                      {(listActivitiesEmoji.length > 0
                        ? listActivitiesEmoji
                        : listDetailActivity.length > 0
                        ? listDetailActivity
                        : pageActivities
                        ? pageActivities
                        : null
                      )?.map((el: any, index) => (
                        <Grid key={el.id} item xs={value ? 12 : 6}>
                          <div
                            key={`${el.data_type}-${el.id}`}
                            onClick={() => {
                              setActivitiesSelected(
                                activitiesSelected
                                  ? el.id === activitiesSelected?.id
                                    ? null
                                    : el
                                  : el
                              );
                              value && !isSubItem && setParentSelected(el);
                              value === 0
                                ? handleClose()
                                : isSubItem && handleClose();
                            }}
                            className={classes.divWrap}
                            style={{
                              borderRadius:
                                activitiesSelected?.id === el.id ? 10 : 'unset',
                              backgroundColor:
                                activitiesSelected?.id === el.id
                                  ? '#f5f5f5'
                                  : 'unset'
                            }}
                          >
                            <div
                              className={classes.divWrapImage}
                              style={
                                listDetailActivity.length > 0
                                  ? {
                                      height: 40,
                                      paddingLeft: 6
                                    }
                                  : {}
                              }
                            >
                              <div
                                className={classes.divImage}
                                style={
                                  listDetailActivity.length > 0
                                    ? { backgroundColor: '#f5f5f5' }
                                    : {}
                                }
                              >
                                <img
                                  width={22}
                                  height={22}
                                  style={{ borderRadius: '50%' }}
                                  src={
                                    el.url
                                      ? el?.url
                                      : el?.avatar_media?.url
                                      ? el?.avatar_media?.url
                                      : imageDefault
                                  }
                                  alt={el.name}
                                  onError={(e: any) =>
                                    (e.target.src = imageDefault)
                                  }
                                />
                              </div>
                              &nbsp;&nbsp;
                              <div style={{ marginLeft: 12 }}>
                                <Typography style={{ fontWeight: 600 }}>
                                  {el.name ?? el.title}
                                </Typography>
                                {el?.title ? (
                                  <Typography
                                    style={{ fontSize: 13, color: '#65676B' }}
                                  >
                                    {' '}
                                    Trang . {el?.like_count} lượt thích
                                  </Typography>
                                ) : null}
                              </div>
                            </div>
                            {value && !isSubItem ? (
                              <i className="far fa-angle-right"></i>
                            ) : null}
                          </div>
                        </Grid>
                      ))}
                    </>
                  ) : (
                    <div style={{ width: '100%' }}>
                      <h1 style={{ textAlign: 'center' }}>Không có dữ liệu!</h1>
                    </div>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
