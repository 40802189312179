import {
  Avatar,
  Button,
  Divider,
  Theme,
  Tooltip,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  getReactionById,
  reactionPostApi,
  unReactionPostApi
} from 'src/apis/socialPost.api';
import ReactEmoji from 'src/components/ReactEmoji/Index';
import EmojiIconShow from 'src/components/ReactEmojiItem/Index';
import { EnumEmojiReactType } from 'src/constants/enum/emojiReact';

import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import CUPost from 'src/components/CUPost';
import DialogConfirmDelete from 'src/components/Dialog/DialogConfirmDelete';
import DialogShareToGroup from 'src/components/Dialog/DialogShare/DialogShareToGroup';
import DialogShareToMessenger from 'src/components/Dialog/DialogShare/DialogShareToMessenger';
import ListButton from 'src/components/List/ListButton';
import { buttonColor } from 'src/constants/styles';
import { roleEditPreviewDetailPage } from 'src/store/action/socialPageAction';
import { reactionPost } from 'src/store/action/socialPostAction';
import { changeReaction } from 'src/store/action/socialWatchAction';
import logoUser from 'src/assets/images/user.jpg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 43,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  text: {
    fontSize: '15px !important',
    marginLeft: 5,
    fontWeight: '600 !important',
    color: theme.palette.text.secondary
  },
  button: {
    height: '80%',
    borderRadius: '5px',
    width: '100%',
    padding: 0,
    margin: '2px 1px 2px 1px',
    display: 'flex',
    alignItems: 'center'
  }
}));

const setEmojiReactShow = (id: number, classes, type, post) => {
  switch (id) {
    case EnumEmojiReactType.like:
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <EmojiIconShow style={{ marginRight: 5 }} id={id} />
          <Typography
            variant="subtitle1"
            style={{ color: buttonColor.backgroundColor, fontWeight: 'bold' }}
          >
            Thích
          </Typography>
        </div>
      );
    case EnumEmojiReactType.angry:
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <EmojiIconShow style={{ marginRight: 5 }} id={id} />
          <Typography
            variant="subtitle1"
            style={{ color: '#e9710f', fontWeight: 'bold' }}
          >
            Phẫn nộ
          </Typography>
        </div>
      );
    case EnumEmojiReactType.love:
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <EmojiIconShow style={{ marginRight: 5 }} id={id} />
          <Tooltip
            title={
              type === 'previewMedia' && !post.in_reply_to_parent_id
                ? 'Yêu thích'
                : ''
            }
          >
            <Typography
              variant="subtitle1"
              style={{ color: '#f33e58', fontWeight: 'bold' }}
            >
              {type === 'previewMedia' || post.post_type === 'watch'
                ? 'Yêu th...'
                : 'Yêu thích'}
            </Typography>
          </Tooltip>
        </div>
      );
    case EnumEmojiReactType.haha:
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <EmojiIconShow style={{ marginRight: 5 }} id={id} />
          <Typography
            variant="subtitle1"
            style={{ color: '#f7b125', fontWeight: 'bold' }}
          >
            Haha
          </Typography>
        </div>
      );
    case EnumEmojiReactType.sad:
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <EmojiIconShow style={{ marginRight: 5 }} id={id} />
          <Typography
            variant="subtitle1"
            style={{ color: '#f7b125', fontWeight: 'bold' }}
          >
            Buồn
          </Typography>
        </div>
      );
    case EnumEmojiReactType.wow:
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <EmojiIconShow style={{ marginRight: 5 }} id={id} />
          <Typography
            variant="subtitle1"
            style={{ color: '#f7b125', fontWeight: 'bold' }}
          >
            Bất ngờ
          </Typography>
        </div>
      );
    case EnumEmojiReactType.yay:
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <EmojiIconShow
            style={{ marginRight: '5px', marginBottom: '3px' }}
            id={id}
          />
          <Tooltip title={type === 'previewMedia' ? 'Tự hào' : ''}>
            <Typography
              variant="subtitle1"
              style={{ color: '#f7b125', fontWeight: 'bold' }}
            >
              {(type === 'previewMedia' && !post.in_reply_to_parent_id) ||
              post.post_type === 'watch'
                ? 'Tự hào'
                : 'Tự hào'}
            </Typography>
          </Tooltip>
        </div>
      );
    default:
      return (
        <>
          <EmojiIconShow style={{ marginRight: 5 }} id={id} />
          <Typography className={classes.text}>Thích</Typography>
        </>
      );
  }
};

interface PostActionButton {
  post?: any;
  type?: String;
  setShowComment?: React.Dispatch<React.SetStateAction<any>> | any;
  setReactionCount?: React.Dispatch<React.SetStateAction<any>> | any;
  setPostData?: React.Dispatch<React.SetStateAction<any>> | any;
  handleGetComment?: any;
  listComment?: any;
  setListComment?: React.Dispatch<React.SetStateAction<any>> | any;
  setFocus?: React.Dispatch<React.SetStateAction<any>> | any;
  roleInteract?: any;
  listReaction?: any;
  setListReaction?: React.Dispatch<React.SetStateAction<any>> | any;
  setMeReaction?: React.Dispatch<React.SetStateAction<any>> | any;
  setButtonSelected?: React.Dispatch<React.SetStateAction<any>> | any;
  typeLive?: String | any;
  setChooseFocus?: React.Dispatch<React.SetStateAction<any>> | any;
  typePost?: String | any;
  pageInfo?: any;
}

const PostActionButton = React.memo(
  forwardRef((props: PostActionButton, ref: any) => {
    const classes = useStyles();
    const match = useRouteMatch();
    const dispatch = useDispatch();

    const {
      post,
      type,
      setShowComment,
      setReactionCount,
      setPostData,
      handleGetComment,
      listComment,
      setListComment,
      setFocus,
      roleInteract,
      listReaction,
      setListReaction,
      setMeReaction,
      setButtonSelected,
      typeLive,
      setChooseFocus,
      typePost,
      pageInfo
    } = props;
    const history = useHistory();
    const [emojiReactId, setEmojiReactId] = useState(-1);
    const emojiReactShow = setEmojiReactShow(emojiReactId, classes, type, post);
    const [openCUPost, setOpenCUPost] = useState(false);
    const [sharePostNow, setSharePostNow] = useState(false);
    const [sharePostInNewFeed, setSharePostInNewFeed] = useState(false);
    const [openDialogShareToGroup, setOpenDialogShareToGroup] = useState(false);
    const [openDialogShareToMessenger, setOpenDialogShareToMessenger] =
      useState(false);
    const [shareTo, setShareTo] = useState('');
    const [openDialogMoreRole, setOpenDialogMoreRole] = useState(false);
    const [closePopper, setClosePopper] = useState(false);
    const postWatchSelected = useSelector(
      (state: any) => state.watchReducer.postSelected
    );
    const eventInfo = useSelector((state: any) => state.eventReducer.info);
    const growInfo = useSelector((state: any) => state.growReducer);
    const meInfo = useSelector((state: any) => state.meReducer.info);
    const [idReaction, setIdReaction] = React.useState<number | null>(null);

    React.useEffect(() => {
      let reaction;
      switch (idReaction) {
        case -1:
          reaction = undefined;
          break;
        case 0:
          reaction = 'like';
          break;
        case 1:
          reaction = 'love';
          break;
        case 3:
          reaction = 'yay';
          break;
        case 2:
          reaction = 'haha';
          break;
        case 4:
          reaction = 'wow';
          break;
        case 5:
          reaction = 'sad';
          break;
        case 6:
          reaction = 'angry';
          break;
      }
      if (typeof idReaction === 'number') {
        dispatch(
          changeReaction({
            reaction: reaction,
            postSelected: post,
            listReaction: listReaction
          })
        );
      }
    }, [JSON.stringify(listReaction)]);

    React.useEffect(() => {
      let idReactEmojiStr = !post.viewer_reaction
        ? 'default'
        : post.viewer_reaction;
      let idReactEmoji = parseInt(EnumEmojiReactType[idReactEmojiStr]);
      setEmojiReactId(idReactEmoji);
    }, [post.viewer_reaction]);

    const debounceClickPost = useCallback(
      _.debounce(idReaction => {
        handleCallAPIClickVote(
          idReaction,
          roleInteract?.role === 'admin' ? roleInteract?.idPage : null
        );
      }, 300),
      [roleInteract?.idPage]
    );

    const emojiReactionList = [
      'like',
      'love',
      'haha',
      'yay',
      'wow',
      'sad',
      'angry'
    ];

    const pageList =
      useSelector((state: any) => state.pageReducer.my_pages)?.map(
        (el: any) => ({
          id: el.id,
          label: el.title,
          avatar_icon: el.avatar_media?.show_url || meInfo?.avatar_static,
          checkbox: true,
          action: () => {
            handleGetReactionById(el?.id, post?.id);
            dispatch(
              roleEditPreviewDetailPage({
                role: el?.page_relationship?.role,
                idPage: el?.id,
                avatar_media: el?.avatar_media,
                title: el?.title
              })
            );
            setOpenDialogMoreRole(false);
          }
        })
      ) || [];

    const listOptionRole = [
      {
        ...meInfo,
        avatar_icon:
          meInfo?.avatar_media?.preview_url ??
          meInfo?.avatar_media?.url ??
          logoUser,
        checkbox: true,
        action: () => {
          handleGetReactionById(null, post?.id);
          dispatch(
            roleEditPreviewDetailPage({
              role: '',
              idPage: null,
              avatar_media: meInfo?.avatar_media
            })
          );
          setOpenDialogMoreRole(false);
        }
      },
      ...pageList
    ];

    let optionsShare: any = [
      [
        {
          id: 0,
          key: 'share_now',
          label: 'Chia sẻ ngay',
          icon: 'fal fa-share',
          styleIcon: { fontSize: '20px' },
          action: () => {
            setSharePostNow(true);
          }
        },
        {
          id: 1,
          key: 'share',
          label: 'Chia sẻ lên bảng tin',
          icon: 'fal fa-edit',
          styleIcon: { fontSize: '20px' },
          action: () => {
            setSharePostInNewFeed(true);
            setOpenCUPost(true);
          }
        },
        !roleInteract?.role && {
          id: 2,
          key: 'sendMessenger',
          label: 'Gửi bằng Emso chat',
          icon: 'fal fa-comment',
          styleIcon: { fontSize: '20px' },
          action: () => {
            setOpenDialogShareToMessenger(true);
          }
        },
        !roleInteract?.role && {
          id: 3,
          key: 'shareToGroup',
          label: 'Chia sẻ lên cộng đồng',
          icon: 'fal fa-users',
          styleIcon: { fontSize: '20px' },
          action: () => {
            setShareTo('group');
            setOpenDialogShareToGroup(true);
          }
        },
        !roleInteract?.role && {
          id: 4,
          key: 'shareToPage',
          label: 'Chia sẻ lên Trang',
          icon: 'fa-light fa-flag',
          styleIcon: { fontSize: '20px' },
          action: () => {
            setOpenDialogShareToGroup(true);
            setShareTo('page');
          }
        },
        !roleInteract?.role && {
          id: 5,
          key: 'shareToFriend',
          label: 'Chia sẻ lên trang cá nhân của bạn bè',
          icon: 'fal fa-user-friends',
          styleIcon: { fontSize: '20px' },
          action: () => {
            setShareTo('friend');
            setOpenDialogShareToGroup(true);
          }
        }
      ]
    ];
    const hiddenButtonShare = ['pinned', 'rating'];

    let listActionPost = [
      {
        key: 'comment',
        label: 'Bình luận',
        icon: 'fa-light fa-message',
        action: () => {
          ref.current?.firstChild.focus();
          if (typePost === 'pinned') {
            history.push(
              `/${post?.account?.username ?? post?.account?.id}/posts/${
                post.id
              }`
            );
          } else {
            if (
              match.path.includes('watch') &&
              type !== 'watch-selected' &&
              postWatchSelected.id === post.id
            ) {
              setFocus(true);
              setChooseFocus && setChooseFocus(true);
              return;
            }
            if (!listComment.length) {
              handleGetComment(
                post.id,
                {
                  sort_by: 'newest'
                },
                setListComment
              );
            }
            if (typeLive) {
              setButtonSelected('chat');
            }
            setShowComment(true);
            setFocus(true);
            ref?.current?.focus();
          }
        },
        visible: post?.off_comment
      },
      {
        key: 'share',
        label: 'Chia sẻ',
        icon: 'fal fa-share',
        options: optionsShare,
        action: id => {
          // if (roleInteract.role === 'admin') {
          //   setOpenDialogShareToGroup(true);
          // }
        },
        visible:
          hiddenButtonShare.includes(typePost) ||
          (eventInfo?.visibility !== 'public' && eventInfo?.visibility) ||
          (Object.keys(growInfo.info).length &&
            growInfo?.status !== 'approved') ||
          post?.group?.is_private ||
          (meInfo?.id === post?.account?.id &&
            post?.visibility === 'private' &&
            !post?.reblog)
            ? true
            : false
      },
      {
        key: 'send',
        label: 'Gửi',
        icon: 'fa-regular fa-paper-plane',
        action: () => {
          setOpenDialogShareToMessenger(true);
        },
        visible: post?.group?.is_private ? false : true
      }
    ];

    const handleChangeReaction = (idReaction, viewerReaction) => {
      setListReaction(prev =>
        prev.map(item =>
          viewerReaction &&
          post?.viewer_reaction === viewerReaction &&
          item.type === viewerReaction
            ? {
                ...item,
                [`${viewerReaction}s_count`]:
                  item[`${viewerReaction}s_count`] - 1
              }
            : item.type === idReaction
            ? {
                ...item,
                [`${idReaction}s_count`]: item[`${idReaction}s_count`] + 1
              }
            : item
        )
      );
      setPostData(prev => ({
        ...prev,
        reactions: prev.reactions.map(item =>
          viewerReaction &&
          post?.viewer_reaction === viewerReaction &&
          item.type === viewerReaction
            ? {
                ...item,
                [`${viewerReaction}s_count`]:
                  item[`${viewerReaction}s_count`] - 1
              }
            : item.type === idReaction
            ? {
                ...item,
                [`${idReaction}s_count`]: item[`${idReaction}s_count`] + 1
              }
            : item
        ),
        viewer_reaction: idReaction
      }));
    };

    const handleUnfavourte = idReaction => {
      setListReaction(prev =>
        prev.map(item =>
          item.type === idReaction
            ? {
                ...item,
                [`${idReaction}s_count`]: item[`${idReaction}s_count`] - 1
              }
            : item
        )
      );
      setPostData(prev => ({
        ...prev,
        reactions: prev?.reactions?.map(item =>
          item.type === idReaction
            ? {
                ...item,
                [`${idReaction}s_count`]: item[`${idReaction}s_count`] - 1
              }
            : item
        ),
        favourites: prev?.favourites?.filter(
          el => el.account?.id !== meInfo?.id
        ),
        viewer_reaction: null
      }));
      dispatch(
        reactionPost(
          post.id,
          post?.favourites?.filter(el => el.account?.id !== meInfo?.id),
          null,
          post?.reactions?.map(item =>
            item.type === idReaction
              ? {
                  ...item,
                  [`${idReaction}s_count`]: item[`${idReaction}s_count`] - 1
                }
              : item
          )
        )
      );
    };

    const handleChangeReactionAfterCallAPI = response => {
      if (post.post_type === 'moment') {
        setPostData(response);
        dispatch(
          reactionPost(
            post.id,
            response.favourites,
            response.viewer_reaction,
            response.reactions
          )
        );
      } else {
        setListReaction(response.reactions);
        setPostData(response);
        setMeReaction(response.favourites);
        dispatch(
          reactionPost(
            post.id,
            response.favourites,
            response.viewer_reaction,
            response.reactions
          )
        );
      }
    };

    const handleCallAPIClickVote = async (
      idReaction: number,
      idPage: string
    ) => {
      let response;
      let data = {
        custom_vote_type: EnumEmojiReactType[idReaction],
        page_id: idPage
      };
      if (idReaction < 0) {
        response = await unReactionPostApi(post.id, { page_id: idPage });
        if (response.status === 200) {
          if (post.post_type === 'moment') {
            dispatch(
              reactionPost(
                post.id,
                response.data.favourites,
                response.data.viewer_reaction,
                response.data.reactions
              )
            );
          }
        }
      } else {
        response = await reactionPostApi(post.id, data);
        if (response.status === 200) {
          handleChangeReactionAfterCallAPI(response.data);
        }
      }
    };

    const handleGetReactionById = async (idPage: any, idPost: string) => {
      let data = {
        page_id: idPage
      };
      try {
        let response = await getReactionById(idPost, data);
        if (response.status === 200) {
          let custom_vote_type: string = response.data?.custom_vote_type;
          setEmojiReactId(
            emojiReactionList.findIndex(item => item === custom_vote_type)
          );
        }
      } catch (err: any) {
        if (err.response.status === 500) {
          setEmojiReactId(-1);
        }
      }
    };

    const handleClickPost = async (idReaction: number) => {
      setIdReaction(idReaction);
      if (emojiReactId < 0) {
        setEmojiReactId(idReaction);
        setReactionCount(prev => prev + 1);
      } else if (idReaction === emojiReactId) {
        setEmojiReactId(idReaction);
      } else {
        setEmojiReactId(idReaction);
        if (idReaction < 0) {
          setReactionCount(prev => prev - 1);
        }
      }
      switch (idReaction) {
        case 0:
          if (post?.viewer_reaction) {
            switch (post?.viewer_reaction) {
              case 'love':
                handleChangeReaction('like', 'love');
                break;
              case 'haha':
                handleChangeReaction('like', 'haha');
                break;
              case 'yay':
                handleChangeReaction('like', 'yay');
                break;
              case 'wow':
                handleChangeReaction('like', 'wow');
                break;
              case 'sad':
                handleChangeReaction('like', 'sad');
                break;
              case 'angry':
                handleChangeReaction('like', 'angry');
                break;
            }
          } else {
            handleChangeReaction('like', '');
          }
          break;
        case 1:
          if (post?.viewer_reaction) {
            switch (post?.viewer_reaction) {
              case 'like':
                handleChangeReaction('love', 'like');
                break;
              case 'haha':
                handleChangeReaction('love', 'haha');
                break;
              case 'yay':
                handleChangeReaction('love', 'yay');
                break;
              case 'wow':
                handleChangeReaction('love', 'wow');
                break;
              case 'sad':
                handleChangeReaction('love', 'sad');
                break;
              case 'angry':
                handleChangeReaction('love', 'angry');
                break;
            }
          } else {
            handleChangeReaction('love', '');
          }
          break;
        case 2:
          if (post?.viewer_reaction) {
            switch (post?.viewer_reaction) {
              case 'like':
                handleChangeReaction('haha', 'like');
                break;
              case 'love':
                handleChangeReaction('haha', 'love');
                break;
              case 'yay':
                handleChangeReaction('haha', 'yay');
                break;
              case 'wow':
                handleChangeReaction('haha', 'wow');
                break;
              case 'sad':
                handleChangeReaction('haha', 'sad');
                break;
              case 'angry':
                handleChangeReaction('haha', 'angry');
                break;
            }
          } else {
            handleChangeReaction('haha', '');
          }
          break;
        case 3:
          if (post?.viewer_reaction) {
            switch (post?.viewer_reaction) {
              case 'like':
                handleChangeReaction('yay', 'like');
                break;
              case 'love':
                handleChangeReaction('yay', 'love');
                break;
              case 'haha':
                handleChangeReaction('yay', 'haha');
                break;
              case 'wow':
                handleChangeReaction('yay', 'wow');
                break;
              case 'sad':
                handleChangeReaction('yay', 'sad');
                break;
              case 'angry':
                handleChangeReaction('yay', 'angry');
                break;
            }
          } else {
            handleChangeReaction('yay', '');
          }
          break;
        case 4:
          if (post?.viewer_reaction) {
            switch (post?.viewer_reaction) {
              case 'like':
                handleChangeReaction('wow', 'like');
                break;
              case 'love':
                handleChangeReaction('wow', 'love');
                break;
              case 'haha':
                handleChangeReaction('wow', 'haha');
                break;
              case 'yay':
                handleChangeReaction('wow', 'yay');
                break;
              case 'sad':
                handleChangeReaction('wow', 'sad');
                break;
              case 'angry':
                handleChangeReaction('wow', 'angry');
                break;
            }
          } else {
            handleChangeReaction('wow', '');
          }
          break;
        case 5:
          if (post?.viewer_reaction) {
            switch (post?.viewer_reaction) {
              case 'like':
                handleChangeReaction('sad', 'like');
                break;
              case 'love':
                handleChangeReaction('sad', 'love');
                break;
              case 'haha':
                handleChangeReaction('sad', 'haha');
                break;
              case 'yay':
                handleChangeReaction('sad', 'yay');
                break;
              case 'wow':
                handleChangeReaction('sad', 'wow');
                break;
              case 'angry':
                handleChangeReaction('sad', 'angry');
                break;
            }
          } else {
            handleChangeReaction('sad', '');
          }
          break;
        case 6:
          if (post?.viewer_reaction) {
            switch (post?.viewer_reaction) {
              case 'like':
                handleChangeReaction('angry', 'like');
                break;
              case 'love':
                handleChangeReaction('angry', 'love');
                break;
              case 'haha':
                handleChangeReaction('angry', 'haha');
                break;
              case 'yay':
                handleChangeReaction('angry', 'yay');
                break;
              case 'wow':
                handleChangeReaction('angry', 'wow');
                break;
              case 'sad':
                handleChangeReaction('angry', 'sad');
                break;
            }
          } else {
            handleChangeReaction('angry', '');
          }
          break;
        case -1:
          switch (post?.viewer_reaction) {
            case 'like':
              handleUnfavourte('like');
              break;
            case 'love':
              handleUnfavourte('love');
              break;
            case 'haha':
              handleUnfavourte('haha');
              break;
            case 'yay':
              handleUnfavourte('yay');
              break;
            case 'wow':
              handleUnfavourte('wow');
              break;
            case 'sad':
              handleUnfavourte('sad');
              break;
            case 'angry':
              handleUnfavourte('angry');
              break;
          }
          break;
      }
      debounceClickPost(idReaction);
    };

    const handleReactPost = async (idReaction: number) => {
      debounceClickPost(idReaction);
      setPostData(prev => ({
        ...prev,
        reactions: [
          ...post.reactions.slice(0, 3),

          {
            type: 'love',
            loves_count: post?.viewer_reaction
              ? post?.reactions?.[3]?.loves_count - 1
              : post?.reactions?.[3]?.loves_count + 1
          },
          ...post.reactions.slice(4)
        ],
        viewer_reaction: post?.viewer_reaction ? null : 'love'
      }));
    };

    return (
      <div>
        {post.card ||
        post.media_attachments?.length ||
        post.replies_total ||
        post.reblogs_count ||
        post.reblog ||
        post.card ||
        post.status_background ||
        post.place ? null : (
          <Divider />
        )}

        <div
          style={match.path.includes('watch') ? { width: 406 } : {}}
          className={classes.root}
        >
          {post.post_type === 'moment' ? (
            <Button
              key="love"
              className={classes.button}
              startIcon={
                post?.viewer_reaction ? (
                  <i
                    style={{ color: 'rgb(254, 44, 85)' }}
                    className="fa-solid fa-heart"
                  ></i>
                ) : (
                  <i className="fa-light fa-heart"></i>
                )
              }
              color="inherit"
              style={{ textTransform: 'none' }}
              onClick={() => {
                if (post?.viewer_reaction) {
                  handleReactPost(EnumEmojiReactType.default);
                } else {
                  handleReactPost(EnumEmojiReactType.love);
                }
              }}
            >
              <Typography
                style={
                  post?.viewer_reaction ? { color: 'rgb(254, 44, 85)' } : {}
                }
                className={classes.text}
              >
                Thích
              </Typography>
            </Button>
          ) : (
            <ReactEmoji
              onChange={id => {
                handleClickPost(id);
              }}
              closePopper={closePopper}
            >
              {(popupState, bindHover, clearOpen) => {
                setClosePopper(false);
                return (
                  <Button
                    {...bindHover(popupState)}
                    className={classes.button}
                    aria-label="add an alarm"
                    sx={{
                      textTransform: 'none',
                      padding: '5px 0px 5px 0px',
                      width: post.in_reply_to_parent_id ? '100%' : 'undefined',
                      margin: '2px 0px 0px',
                      color: 'text.secondary',
                      '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: 'button.secondary.background'
                      }
                    }}
                    onClick={() => {
                      clearOpen();
                      setClosePopper(true);
                      if (emojiReactId < 0) {
                        handleClickPost(EnumEmojiReactType.like);
                      } else {
                        handleClickPost(EnumEmojiReactType.default);
                      }
                    }}
                  >
                    {emojiReactShow}
                  </Button>
                );
              }}
            </ReactEmoji>
          )}
          {post.in_reply_to_parent_id
            ? null
            : listActionPost
                .filter((item: any) => item?.visible !== true)
                ?.map((item: any) => {
                  return (
                    <div style={{ width: '100%', height: '80%' }}>
                      <IconButtonOptions
                        startIcon={item.icon}
                        name={item.label}
                        startIconStyle={{
                          marginRight: '6px',
                          // color: 'rgb(101, 103, 107)',
                          color: 'text.secondary',
                          fontSize: '18px'
                        }}
                        style={{
                          textTransform: 'none',
                          backgroundColor: 'transparent',
                          '&:hover': {
                            backgroundColor: 'button.primary.secondary'
                          },
                          width: '100%',
                          margin: 0,
                          flexWrap: 'nowrap',
                          padding: '6px'
                        }}
                        styleNameButton={{
                          color: 'text.secondary',
                          display: 'inline',
                          fontSize: 15
                        }}
                        styleListMenu={{
                          maxWidth: '320px',
                          padding: '6px 0px'
                        }}
                        stylePopup={{ padding: '0px', zIndex: 1500 }}
                        options={item.options ? item.options : null}
                        openPopup={item.options ? true : false}
                        disablePortal={false}
                        action={() => item.action(post.id)}
                      />
                    </div>
                  );
                })}
          {typePost !== 'pinned' && (
            <IconButtonOptions
              icon={
                <>
                  <Avatar
                    sx={{
                      marginRight: '3px',
                      height: '25px',
                      width: '25px'
                    }}
                    src={
                      roleInteract?.idPage &&
                      pageList?.find((el: any) => el.id === roleInteract.idPage)
                        ?.avatar_icon
                        ? pageList?.find(
                            (el: any) => el.id === roleInteract.idPage
                          )?.avatar_icon
                        : meInfo?.avatar_media
                        ? meInfo?.avatar_media.show_url ??
                          meInfo?.avatar_media?.preview_url ??
                          meInfo?.avatar_media?.url
                        : logoUser
                    }
                  ></Avatar>
                  <i
                    style={{ fontSize: 14 }}
                    className="fa-solid fa-caret-down"
                    aria-hidden="true"
                  ></i>
                </>
              }
              options={[
                [
                  {
                    id: 'interact',
                    title: 'Chọn cách tương tác',
                    description:
                      'Bình luận và bày tỏ cảm xúc dưới tên trang cá nhân hoặc Trang của bạn.'
                  }
                ],
                [
                  {
                    id: meInfo?.id,
                    label: meInfo?.display_name,
                    avatar_icon:
                      meInfo?.avatar_media?.preview_url ??
                      meInfo?.avatar_media?.url ??
                      meInfo?.avatar_media?.show_url ??
                      logoUser,
                    checkbox: true,
                    action: () => {
                      handleGetReactionById(null, post?.id);
                      dispatch(
                        roleEditPreviewDetailPage({
                          role: '',
                          idPage: null,
                          avatar_media: meInfo?.avatar_media
                        })
                      );
                    }
                  }
                ]?.concat(
                  pageList
                    ?.filter((el: any, index: any) => index < 3)
                    ?.concat(
                      pageList?.length > 3
                        ? [
                            {
                              id: 'more',
                              label: 'Xem thêm',
                              styleLabel: { padding: '0px 5px' },
                              endIcon: 'fa-light fa-ellipsis',
                              styleEndIcon: { fontWeight: 600 },
                              action: () => setOpenDialogMoreRole(true)
                            }
                          ]
                        : []
                    )
                )
              ]}
              openPopup={true}
              styleListMenu={{ maxWidth: '298px' }}
              style={{
                // zoom: match.path.includes('watch') ? '118%' : '100%',
                width: '60px',
                backgroundColor: 'transparent',
                margin: 0,
                '&:hover': {
                  backgroundColor: 'button.primary.secondary'
                },
                padding: '6px'
              }}
              stylePopup={{ padding: '0px', zIndex: 1500 }}
              optionCheckDefault={meInfo?.id}
              pageChecked={roleInteract}
              pageCurrentId={pageInfo?.id}
              disablePortal={false}
            />
          )}
        </div>

        <CUPost
          type={type}
          post={post}
          openCUPost={openCUPost}
          setOpenCUPost={setOpenCUPost}
          shareNow={sharePostNow}
          setSharePostNow={setSharePostNow}
          sharePostInNewFeed={sharePostInNewFeed}
          setSharePostInNewFeed={setSharePostInNewFeed}
        />
        {openDialogShareToGroup && (
          <DialogShareToGroup
            shareTo={shareTo}
            post={post}
            open={openDialogShareToGroup}
            handleClose={() => {
              setOpenDialogShareToGroup(false);
            }}
            pageInfo={pageInfo}
          />
        )}

        {openDialogShareToMessenger && (
          <DialogShareToMessenger
            open={openDialogShareToMessenger}
            handleClose={() => setOpenDialogShareToMessenger(false)}
            post={post}
            meInfo={meInfo}
            type={type}
            page={pageInfo}
          />
        )}
        {openDialogMoreRole && (
          <DialogConfirmDelete
            open={openDialogMoreRole}
            handleClose={() => setOpenDialogMoreRole(false)}
            title="Cách tương tác"
            text="Bình luận và bày tỏ cảm xúc dưới tên trang cá nhân hoặc Trang của bạn."
            alignText="center"
            contentDialog={
              <>
                <ListButton
                  item={listOptionRole?.map((el: any) => ({
                    ...el,
                    label: null,
                    title: el?.label ?? el?.display_name,
                    checkbox: false
                  }))}
                  selectedItem={
                    roleInteract?.idPage && roleInteract?.role === 'admin'
                      ? roleInteract?.idPage
                      : meInfo?.id
                  }
                  checkedIcon={true}
                  typeAction={'action'}
                />
              </>
            }
            hiddenButton={true}
          />
        )}
      </div>
    );
  })
);

export default PostActionButton;
