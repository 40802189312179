import React from 'react';import {
  Theme,
  Checkbox,
  Button,
  Typography,
  MenuItem,
  styled,
  alpha
} from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { makeStyles } from '@mui/styles';

import ButtonInherit from 'src/components/Button/ButtonInherit';

const useStyles = makeStyles((theme: Theme) => ({
  wrapVote: {
    borderRadius: 10,
    border: '1px solid rgba(0,0,0,0.2)',
    padding: '5px 10px 12px 10px',
    margin: '10px 0px',
    position: 'relative'
  },
  wrapOverlay: {
    position: 'absolute',
    top: '-10px',
    left: '0px',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.4)',
    borderRadius: 10,
    zIndex: 1400
  },
  topWrapVote: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  bottomWrapAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  wrapFormOption: {
    padding: '5px 10px 5px 5px',
    margin: '0px 0px 10px 0px'
  },
  formOption: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0px'
  },
  buttonOption: {
    width: 25,
    height: 25,
    borderRadius: '50%',

    '&:hover': {
      backgroundColor: '#D5D5D5',
      cursor: 'pointer'
    },

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  buttonVote: {
    borderRadius: '50%',
    backgroundColor: theme.palette.mode === 'dark' ? '#535353' : '#fff',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#D5D5D5' : '#333',
      cursor: 'pointer'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    height: 28
  },
  divCheckbox: {
    display: 'flex',
    alignItems: 'center',
    width: 135,
    justifyContent: 'flex-expandOpen'
  }
}));

const RedditTextField = styled((props: TextFieldProps) => (
  <TextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 8,
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',

      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    }
  }
}));

const FormVote = props => {
  const { type, pollData, setIsPoll, setPollData, post } = props;
  const classes = useStyles();
  const [listOptions, setListOptions] = React.useState(['', '']);
  const [multiple, setMultiple] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(true);

  const listTime = [
    {
      value: 300,
      label: '5 phút'
    },
    {
      value: 1800,
      label: '30 phút'
    },
    {
      value: 3600,
      label: '1 giờ'
    },
    {
      value: 21600,
      label: '6 giờ'
    },
    {
      value: 86400,
      label: '1 ngày'
    },
    {
      value: 259200,
      label: '3 ngày'
    },
    {
      value: 604800,
      label: '7 Ngày'
    }
  ];

  React.useEffect(() => {
    setPollData({
      ...pollData,
      multiple: multiple,
      options: listOptions
    });
  }, [JSON.stringify(listOptions), multiple]);

  React.useEffect(() => {
    setPollData({
      expires_in: 300
    });
  }, []);

  React.useEffect(() => {
    if (type === 'update' && post.poll) {
      setListOptions(post.poll?.options?.map((el: any) => el.title));
      setMultiple(post.poll?.multiple);

      if (post.poll.expired || post.poll.votes_count >= 1) {
        setIsEdit(false);
      }
    }
  }, [type, JSON.stringify(post)]);

  return (
    <>
      <div className={classes.wrapVote}>
        {!isEdit ? <div className={classes.wrapOverlay}></div> : null}
        <div className={classes.topWrapVote}>
          <Typography variant="h6">Cuộc thăm dò ý kiến</Typography>
          <div
            onClick={() => {
              setIsPoll(false);
              setPollData(null);
            }}
            className={classes.buttonVote}
          >
            <i className="far fa-times"></i>
          </div>
        </div>
        <div className={classes.wrapFormOption}>
          {listOptions?.map((el: any, index: number) => (
            <div key={index} className={classes.formOption}>
              <RedditTextField
                label={`Lựa chọn ${index + 1}`}
                id={el}
                variant="filled"
                style={{ marginRight: 5 }}
                fullWidth
                value={el}
                onChange={e =>
                  setListOptions([
                    ...listOptions.slice(0, index),
                    e.target.value,
                    ...listOptions.slice(index + 1)
                  ])
                }
                helperText={
                  listOptions[index].length > 50
                    ? 'Số kí tự không vượt quá 50'
                    : ''
                }
              />
              <div
                onClick={() =>
                  setListOptions([
                    ...listOptions.slice(0, index),
                    ...listOptions.slice(index + 1)
                  ])
                }
                className={classes.buttonOption}
              >
                <i className="far fa-times"></i>
              </div>
            </div>
          ))}
        </div>
        <div className={classes.bottomWrapAction}>
          <ButtonInherit
            style={{ width: 200 }}
            label="Thêm lựa chọn"
            action={() => setListOptions([...listOptions, ''])}
          />
          <TextField
            name="expires_time"
            sx={{ width: 100, margin: '0px 5px' }}
            size="small"
            select
            defaultValue={300}
            onChange={e =>
              setPollData({
                ...pollData,
                expires_in: e.target.value
              })
            }
          >
            {listTime?.map((item: any) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <div className={classes.divCheckbox}>
            <Typography>Chọn nhiều</Typography>
            <Checkbox
              checked={multiple}
              onChange={e => setMultiple(e.target.checked)}
            />
          </div>
        </div>
      </div>
      {!isEdit ? (
        <Typography style={{ fontSize: 13, color: 'red' }}>
          Không thể chỉnh sửa thăm dò ý kiến đã hết hạn hoặc đã có dữ liệu bình
          chọn
        </Typography>
      ) : null}
    </>
  );
};

export default FormVote;
