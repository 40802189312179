import * as types from 'src/constants/store/searchGlobalSocial';const _ = require('lodash');

const initialState = {
  keyword: '',
  // handleSearch: false,
  anchorElDialogSearch: null,
  dataSearchFilterPostUser: [],
  dataSearchFilterPostGroup: {}
};

export const searchGlobalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.KEYWORD_SEARCH: {
      let keyword = action.payload;
      let newState = _.cloneDeep(keyword);
      return { ...state, keyword: newState };
    }

    // case types.HANDLE_SEARCH: {
    //   let params = action.payload;
    //   let newState = _.cloneDeep(params);
    //   return { ...state, handleSearch: newState };
    // }

    case types.RESET_SEARCH: {
      return {
        keyword: ''
        // handleSearch: false
      };
    }

    case types.ANCHOREL_DIALOG_SEARCH: {
      let anchorEl = action.payload;
      return { ...state, anchorElDialogSearch: anchorEl };
    }
    case types.SEARCH_FILTER_POST_USER: {
      return {
        ...state,
        dataSearchFilterPostUser: action.payload
      };
    }
    case types.SEARCH_FILTER_POST_GROUP: {
      return {
        ...state,
        dataSearchFilterPostGroup: action.payload
      };
    }
    default:
      return state;
  }
};
