import axios, { AxiosRequestConfig } from 'axios';
import { USER_TOKEN } from 'src/util/config';
import { BASE_ROOT } from 'src/util/config';

const fetchApi = async (endPoint, method, params, formdata) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: formdata
  };

  let response = await axios(configs);
  return response;
};
const fetchApiRequest = async (endPoint, method, params, _data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

export const getListProvince = async () => {
  return fetchApi(`provinces`, 'GET', null, null);
};

export const getListProvincesApi = async () => {
  return fetchApiRequest('list_provinces', 'GET', null, null);
};

export const getListDistrictsApi = async provinceId => {
  return fetchApiRequest(
    'list_districts',
    'GET',
    { province_id: provinceId },
    null
  );
};

export const getListWardsApi = async districtId => {
  return fetchApiRequest(
    'list_wards',
    'GET',
    { district_id: districtId },
    null
  );
};
