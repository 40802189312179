import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { getEventLifeApi, getLocationApi } from 'src/apis/socialPost.api';
import avatarDefault from 'src/assets/images/group_cover.png';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import ButtonTime from 'src/components/Button/ButtonTime';
import TextInput from 'src/components/Input/SocialInput/InputText';
import { buttonColor, modalBackdrop } from 'src/constants/styles';
import ImageRender from './ImageRender';

const useStyles = makeStyles((theme: any) => ({
  gridButton: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.button.primary.hover,
      borderRadius: 5
    },
    padding: '0px !important',
    width: 90,
    height: 90
  },
  text: {
    fontSize: '13px !important'
  },
  divTitle: {
    height: 157,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 20,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.background.hover,
      borderRadius: 5
    }
  },
  button: {
    padding: 16,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.button.primary.hover
    }
  },
  iconWrap: {
    width: 28,
    height: 28,
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    objectFit: 'cover',
    objectPosition: 'center',
    marginRight: 5,
    '&:hover': {
      cursor: 'pointer'
    },
    border: '1px solid rgba(0,0,0,0.05)'
  },
  itemFlex: {
    display: 'flex',
    alignItems: 'center'
  },
  listAvatar: {
    width: '40px',
    height: '40px',
    objectFit: 'cover',
    borderRadius: '10px',
    marginRight: '10px'
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => any;
}

type OptionTypeSchoolType = {
  type: string;
  title: string;
};

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function ScrollDialog(props) {
  const {
    open,
    files,
    setFiles,
    handleClose,
    setLifeEvent,
    eventLife,
    setEventLife,
    setOpenCUPost,
    type,
    valueEvent,
    updateEvent
  } = props;
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  const [listMenu, setListMenu] = React.useState<any>([]);
  const [locationOptions, setLocationOptions] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<any>(false);
  const [isFull, setIsFull] = React.useState(false);
  const theme: any = useTheme();
  const [checkCreateUpdate, setCheckCreateUpdate] = React.useState(false);
  const [dataUpdate, setDataUpdate] = React.useState<any>({
    name: '',
    place_id: null,
    place_object: null,
    life_event_category_id: null,
    life_event: null,
    start_date: null,
    partner_id: null,
    default_media_url: null,
    school: null,
    school_type: null
  });

  const lifeEventCode = dataUpdate?.life_event?.code;

  const hiddentOptionsAddress = [
    'tao_su_kien_rieng',
    'dau_moc_thanh_tuu',
    'suc_khoe_the_chat_tinh_than',
    'so_thich_hoat_dong',
    'thu_cung_moi',
    'mat_mot_nguoi_than_yeu',
    'lam_cha_me',
    'con_moi_chao_doi',
    'nghi_huu',
    'thang_chuc',
    'tuong_nho'
  ].includes(lifeEventCode);

  const isEventLifeRelationship = [
    'dinh_hon',
    'cong_khai_thien_huong_tinh_duc',
    'lan_gap_dau_tien',
    'hon_nhan',
    'moi_quan_he_moi'
  ].includes(lifeEventCode);

  const isEventLifeHomeAndLivings = [
    'que_quan',
    'nhung_noi_toi_da_song',
    'chuyen_cho_o'
  ].includes(lifeEventCode);

  const isEventLifeStudent = [
    'thoi_hoc',
    'da_tot_nghiep',
    'truong_moi',
    'hoc_van'
  ].includes(lifeEventCode);

  const isEventLifeTravel = ['du_lich'].includes(lifeEventCode);

  const hasPlaceObjectAndSchoolType =
    dataUpdate.place_object && dataUpdate.school_type;
  const hasEventLifeAccount = !eventLife?.life_event && eventLife?.account;

  const optionSelecteSchool: OptionTypeSchoolType[] = [
    {
      type: 'HIGH_SCHOOL',
      title: 'Trung học'
    },
    {
      type: 'UNIVERSITY',
      title: 'Đại học'
    }
  ];

  const getListPages = React.useCallback(
    _.debounce(async keyword => {
      let response = await getLocationApi({
        keyword: keyword === '' ? '' : keyword,
        limit: 10
      });

      if (response.status === 200) {
        setLocationOptions(response.data);
        setLoading(false);
      }
    }, 800),
    []
  );

  const handleInputChange = (value, reason) => {
    debounceFilterOptions(value, reason);
  };

  const debounceFilterOptions = React.useCallback(
    _.debounce((inputValue, reason) => {
      setLoading(true);
      getListPages(inputValue);
      if (reason === 'input') {
        setDataUpdate(prev => ({
          ...prev,
          place_object: inputValue,
          place_id: null
        }));
        setLocationOptions([]);
      }
    }, 500),
    []
  );

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  React.useEffect(() => {
    if (eventLife?.account || eventLife?.life_event) {
      if (eventLife?.school_type) {
        setDataUpdate(() => ({
          ...eventLife,
          school: optionSelecteSchool.find(
            item => item.type === eventLife.school_type
          )
        }));
      } else {
        setDataUpdate(eventLife);
      }
    }
    return () => {
      setDataUpdate({
        name: '',
        place_id: null,
        place_object: null,
        life_event_category_id: null,
        life_event: null,
        start_date: null,
        partner_id: null,
        default_media_url: null,
        school: null,
        school_type: null
      });
    };
  }, []);

  React.useEffect(() => {
    if (
      ((isEventLifeStudent && hasPlaceObjectAndSchoolType) ||
        (!isEventLifeStudent && dataUpdate.place_object) ||
        hiddentOptionsAddress ||
        isEventLifeHomeAndLivings ||
        isEventLifeTravel ||
        isEventLifeRelationship ||
        hasEventLifeAccount) &&
      dataUpdate.name
    ) {
      setCheckCreateUpdate(false);
    } else {
      setCheckCreateUpdate(true);
    }
  }, [JSON.stringify(dataUpdate), JSON.stringify(eventLife)]);

  const classes = useStyles();

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png,video/*',
    onDrop: (acceptedFiles: any) => {
      let filesState = [...files];
      let fileAccepts = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );

      let newFiles = filesState.concat(fileAccepts);
      setFiles(newFiles);
    }
  });

  const getFilesArray = index => {
    if (!files.length) return;
    return [...files].slice(index, index + 5);
  };

  const removeFiles = preview => {
    let index = files?.findIndex((el: any) => el.preview === preview);
    let newFiles = [...files.slice(0, index), ...files.slice(index + 1)];
    setFiles(newFiles);
  };

  const renderFiles = () => {
    let tags: any = [];
    for (let i = 0; i <= Math.ceil(files.length / 5); i++) {
      tags = [
        ...tags,

        <ImageRender
          type="create-upload"
          removeFiles={removeFiles}
          images={getFilesArray(i)?.map((el: any) => el.preview)}
          imagesType={getFilesArray(i)?.map((el: any) => el.type)}
        />
      ];
    }

    return tags?.map((el: any, index) => (
      <React.Fragment key={index}>{el}</React.Fragment>
    ));
  };

  const renderButton = type => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          padding: 10
        }}
      >
        {type === 'noMedia' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              onClick={() =>
                setDataUpdate(prev => ({
                  ...prev,
                  default_media_url: null
                }))
              }
              className={classes.iconWrap}
              style={
                !dataUpdate.default_media_url
                  ? { border: '2px solid #fff' }
                  : {}
              }
            >
              <i
                style={{ color: '#fff', zIndex: 10 }}
                className="fa-solid fa-file-xmark"
              ></i>
            </div>

            {dataUpdate?.life_event?.video_url?.map((el: any, index) => (
              <div
                onClick={() =>
                  setDataUpdate(prev => ({
                    ...prev,
                    default_media_url: el
                  }))
                }
                key={index}
              >
                <video
                  className={classes.iconWrap}
                  src={el}
                  style={
                    dataUpdate.default_media_url === el
                      ? { border: '2px solid #fff' }
                      : {}
                  }
                ></video>
              </div>
            ))}
          </div>
        ) : files.length >= 6 ? (
          <ButtonInherit
            action={() => setIsFull(prev => !prev)}
            style={{ marginRight: 0 }}
            label={
              <>
                <Typography
                  style={{
                    fontSize: 15,
                    fontWeight: 500,
                    color: '#65676b'
                  }}
                >
                  {isFull ? 'Thu gọn' : 'Mở rộng'}
                </Typography>
              </>
            }
          />
        ) : (
          <div></div>
        )}
        <ButtonInherit
          style={{ marginRight: 0, backgroundColor: '#e4e6eb' }}
          label={
            <div
              {...getRootProps({ className: 'dropzone' })}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <i
                className="fa-solid fa-file-plus"
                style={{ color: 'black' }}
              ></i>{' '}
              &nbsp;
              <Typography
                style={{
                  fontSize: 15,
                  fontWeight: 500,
                  color: '#65676b'
                }}
              >
                Thêm ảnh/video
              </Typography>
              <input {...getInputProps()} />
            </div>
          }
        />
      </div>
    );
  };

  const handleChangeSchoolType = (event, value): void => {
    if (value) {
      setDataUpdate({
        ...dataUpdate,
        school_type: value.type,
        school: value
      });
    } else {
      setDataUpdate({
        ...dataUpdate,
        school_type: dataUpdate?.school?.type
      });
    }
  };

  const renderMenuEventLife = () => {
    if (!eventLife) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              height={150}
              width={500}
              src="https://s3-hn-2.cloud.cmctelecom.vn/sn-web/images/events/image_2023_11_22T10_17_55_020Z.png"
              alt="Event life"
              style={{ marginLeft: '-24px' }}
            />
          </Grid>
          <Grid container item justifyContent="center" xs={12}>
            <Typography variant="h6">
              <b>Sự kiện trong đời</b>
            </Typography>
            <Typography style={{ fontSize: 13 }}>
              Chia sẻ và ghi nhớ những khoảnh khắc quan trọng trong đời.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            item
            xs={12}
          >
            <Typography
              style={{
                textTransform: 'uppercase',
                margin: 20,
                fontWeight: 600
              }}
            >
              Chọn hạng mục
            </Typography>
          </Grid>

          <Grid
            style={{ paddingLeft: '30px !important' }}
            container
            item
            spacing={2}
          >
            {listMenu?.map((item: any) => (
              <Grid
                key={item.id}
                container
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                item
                xs={3}
                className={classes.gridButton}
                onClick={() => {
                  setEventLife(item);
                  if (!item.children.length) {
                    setDataUpdate(prev => ({
                      ...prev,
                      name: item.name ?? '',
                      life_event: {
                        ...item,
                        children: null
                      },
                      life_event_category_id: item.id
                    }));
                  } else {
                    setDataUpdate(prev => ({
                      ...prev,
                      life_event: null
                    }));
                    setDataUpdate(prev => ({
                      ...prev,
                      default_media_url: null
                    }));
                    setFiles([]);
                    setIsFull(false);
                  }
                }}
              >
                <img
                  width={32}
                  height={32}
                  src={
                    theme.palette.mode === 'dark'
                      ? item.dark_mode_url
                      : item.url
                  }
                  alt={item.name}
                />
                <Typography className={classes.text} textAlign={'center'}>
                  {item.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      );
    } else {
      if (!dataUpdate.life_event && !dataUpdate?.account) {
        return (
          <>
            <div
              onClick={() => {
                setDataUpdate(prev => ({
                  ...prev,
                  life_event: {}
                }));
                setDataUpdate(prev => ({
                  ...prev,
                  default_media_url: null
                }));
              }}
              className={classes.divTitle}
            >
              <img
                alt={eventLife?.name}
                src={eventLife?.url}
                style={{ width: '30px' }}
              />
              <Typography variant="h6">
                <i className="fas fa-pen"></i> Nhập tiêu đề...
              </Typography>
              <Typography style={{ fontSize: 13 }}>
                {eventLife.example}
              </Typography>
            </div>
            <div style={{ marginBottom: 20 }}>
              <Divider />
              {eventLife?.children?.map((el: any, index) => {
                return (
                  <>
                    <div
                      onClick={() => {
                        setDataUpdate(prev => ({
                          ...prev,
                          life_event: el,
                          life_event_category_id: el.id,
                          school: valueEvent ? valueEvent : null,
                          school_type: valueEvent ? valueEvent.type : null
                        }));
                        setDataUpdate(prev => ({
                          ...prev,
                          default_media_url: null
                        }));
                        setDataUpdate(prev => ({
                          ...prev,
                          name: el.name ?? ''
                        }));
                      }}
                      key={index}
                      className={classes.button}
                    >
                      <Typography textAlign="center" variant="h6">
                        {el.name}
                      </Typography>
                    </div>
                    <Divider />
                  </>
                );
              })}
            </div>
          </>
        );
      } else {
        return (
          <>
            {files?.length ? (
              <div
                style={{
                  position: 'relative',
                  margin: '-16px -24px'
                }}
              >
                {isFull ? (
                  renderFiles()
                ) : (
                  <ImageRender
                    type="create-upload"
                    removeFiles={removeFiles}
                    images={files?.map((el: any) => el.preview)}
                    imagesType={files?.map((el: any) => el.type)}
                  />
                )}

                {renderButton('media')}
              </div>
            ) : (
              <div
                style={{
                  height: 333,
                  position: 'relative',
                  margin: '-16px -24px',
                  backgroundColor: '#CCD0D5'
                }}
              >
                {dataUpdate.default_media_url && (
                  <video
                    autoPlay={true}
                    muted
                    loop
                    style={{ width: '100%', height: '100%' }}
                    src={dataUpdate.default_media_url}
                  />
                )}
                {!dataUpdate.default_media_url && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: '100%'
                    }}
                  >
                    <i
                      style={{ color: '#65676b' }}
                      className="fa-solid fa-images fa-2x"
                    ></i>{' '}
                    &nbsp;
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: 15,
                        color: '#65676b'
                      }}
                    >
                      Sự kiện trong đời của bạn không có hình ảnh nào đi kèm
                    </Typography>
                  </div>
                )}
                {renderButton('noMedia')}
              </div>
            )}

            <div style={{ height: 40 }}></div>
            <div>
              <TextInput
                placeholder="Tiêu đề"
                value={dataUpdate?.name}
                action={text =>
                  setDataUpdate(prev => ({
                    ...prev,
                    name: text ?? ''
                  }))
                }
              />
              <Typography
                style={{
                  fontSize: '12px',
                  color: '#65676b',
                  marginBottom: '10px'
                }}
              >
                {eventLife.example}
              </Typography>
              {hiddentOptionsAddress ||
              (!eventLife?.life_event &&
                eventLife?.account &&
                !eventLife?.place_object) ? null : (
                <Autocomplete
                  value={
                    dataUpdate.place_object?.title
                      ? dataUpdate.place_object.title
                      : dataUpdate.place_object ?? ''
                  }
                  options={locationOptions || ['']}
                  filterOptions={x => x}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  noOptionsText={
                    loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      'Không có dữ liệu...'
                    )
                  }
                  getOptionLabel={(option: any) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.title;
                  }}
                  onChange={(event, value: any) => {
                    if (typeof value === 'string') {
                      setDataUpdate(prev => ({
                        ...prev,
                        place_id: null,
                        place_object: value
                      }));
                    } else {
                      setDataUpdate(prev => ({
                        ...prev,
                        place_id: value?.id,
                        place_object: value
                      }));
                    }
                    setLocationOptions([]);
                  }}
                  onInputChange={(event, newValue: any, reason) => {
                    handleInputChange(newValue, reason);
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Grid className={classes.itemFlex}>
                        <img
                          src={
                            option.avatar_media
                              ? option.avatar_media.preview_url
                              : avatarDefault
                          }
                          className={classes.listAvatar}
                          alt="avatar"
                        />
                        <Typography>{option.title}</Typography>
                      </Grid>
                    </li>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={
                        isEventLifeStudent
                          ? 'Trường học'
                          : isEventLifeRelationship
                          ? 'Bạn đời(không bắt buộc)'
                          : 'Chọn địa chỉ'
                      }
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          paddingRight: '80px',
                          width: '100%'
                        }
                      }}
                    />
                  )}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '48px',
                      marginBottom: '10px'
                    }
                  }}
                />
              )}

              {isEventLifeStudent ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={dataUpdate?.school}
                  options={optionSelecteSchool}
                  onChange={handleChangeSchoolType}
                  getOptionLabel={(option: OptionTypeSchoolType): string =>
                    option.title
                  }
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '48px'
                    },
                    marginTop: '10px'
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          paddingRight: '80px',
                          width: '100%'
                        }
                      }}
                      label="Loại trường học"
                    />
                  )}
                />
              ) : null}

              <div style={{ height: 20 }}></div>

              <ButtonTime
                style={{ width: 320 }}
                action={data => {
                  setDataUpdate(prev => ({
                    ...prev,
                    start_date: `${data.year}-${data.month}-${data.date}`
                  }));
                }}
              />
            </div>
          </>
        );
      }
    }
  };

  const handleCloseDialog = () => {
    if (eventLife && !dataUpdate.life_event && !updateEvent) {
      setEventLife(null);
      if (type) {
        handleClose();
      }
    } else if (dataUpdate.life_event && eventLife && !updateEvent) {
      if (
        !dataUpdate.life_event.children &&
        dataUpdate.life_event.id === eventLife.id
      ) {
        setEventLife(null);
        if (type) {
          handleClose();
        }
      }
      setDataUpdate(prev => ({
        ...prev,
        life_event: null,
        place_object: null,
        place_id: null,
        school_type: null
      }));
    } else {
      handleClose();
      setDataUpdate(prev => ({
        ...prev,
        place_object: null,
        place_id: null,
        school_type: null
      }));
    }
  };

  React.useEffect(() => {
    async function getListMenuEventLife() {
      let res = await getEventLifeApi();
      if (res.status === 200) {
        setListMenu(res.data);
      }
    }

    getListMenuEventLife();
  }, []);

  React.useEffect(() => {
    if (dataUpdate.life_event && dataUpdate.life_event.video_url?.length) {
      setDataUpdate(prev => ({
        ...prev,
        default_media_url: dataUpdate.life_event.video_url[0]
      }));
    }
  }, [JSON.stringify(dataUpdate.life_event)]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20 }}
            textAlign={'center'}
          >
            Tạo sự kiện trong đời
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ overflowX: 'hidden', width: 500 }}>
          {renderMenuEventLife()}
        </DialogContent>
        {(dataUpdate.life_event || dataUpdate?.account) && (
          <DialogActions
            sx={{
              width: '100%',
              '& div': {
                flex: 1
              }
            }}
          >
            <ButtonInherit
              action={() => {
                setLifeEvent(dataUpdate);
                handleClose();
                setOpenCUPost && setOpenCUPost(true);
                setEventLife(null);
              }}
              color={buttonColor.backgroundColor}
              label="Xong"
              disabled={checkCreateUpdate}
              style={{ marginRight: 0, width: '100%' }}
            />
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
