import axios, { AxiosRequestConfig } from 'axios';
import { USER_TOKEN } from 'src/util/config';
import { BASE_ROOT } from 'src/util/config';

const fetchApi = async (endPoint, method, params, formdata) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: formdata
  };

  let response = await axios(configs);
  return response;
};

const fetchApiRequest = async (endPoint, method, params, _data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

export const createUpdateEventApi = async (data: any, type: any) => {
  var formdata = new FormData();
  formdata.append('title', data.title);
  formdata.append('description', data.description ?? '');
  for (var i = 0; i < data.event_host_added.length; i++) {
    formdata.append('event_host_added[]', data.event_host_added[i]);
  }
  for (let i = 0; i < data.event_host_removed.length; i++) {
    formdata.append('event_host_removed[]', data.event_host_removed[i]);
  }
  if (data.banner) {
    if (data.banner.file) {
      formdata.append('banner[file]', data.banner.file);
    }
    if (data.banner.id) {
      formdata.append('banner[id]', data.banner.id);
      formdata.append('banner[show_url]', data.banner.show_url);
    }
  }
  if (data.visibility) {
    formdata.append('visibility', data.visibility);
  }
  if (data.category_id) {
    formdata.append('category_id', data.category_id);
  }
  if (data.start_time) {
    formdata.append('start_time', data.start_time);
  }
  if (data.end_time) {
    formdata.append('end_time', data.end_time);
  }
  if (data.event_type) {
    formdata.append('event_type', data.event_type);
  }
  if (data.location) {
    formdata.append('id', data.location.id);
    formdata.append('location[lat]', data.location.location.lat);
    formdata.append('location[lng]', data.location.location.lng);
    formdata.append('address', data.location.title);
  }
  if (data.page_owner_id) {
    formdata.append('page_owner_id', data.page_owner_id);
  }
  if (data.page_id) {
    formdata.append('page_id', data.page_id);
  }

  return fetchApi(
    type === 'create' ? 'events' : `events/${type}`,
    type === 'create' ? 'POST' : 'PATCH',
    null,
    formdata
  );
};

export const getCalendarEventApi = async (params: any) => {
  return fetchApiRequest('events', 'GET', params, null);
};
export const getEventCategories = async () => {
  return fetchApi('categories', 'GET', null, null);
};
export const getPageDetailEvent = async (params: any) => {
  return fetchApi('events', 'GET', params, null);
};
export const getEventCategoriesFilter = async (params: any) => {
  return fetchApi('events', 'GET', params, null);
};
export const getDetailEventApi = async (idEvent: any) => {
  return fetchApi(`events/${idEvent}`, 'GET', null, null);
};

export const deleteEventApi = async (id: any) => {
  return fetchApi(`events/${id}`, 'DELETE', null, null);
};

export const shareEventApi = async (params: any, idEvent: any) => {
  return fetchApi(`events/${idEvent}/share`, 'POST', params, null);
};

export const getTimelineEventApi = async (idEvent: any, params: any) => {
  return fetchApi(`timelines/event/${idEvent}`, 'GET', params, null);
};

export const createInviteEvent = async (idEvent: any, params: any) => {
  return fetchApi(`events/${idEvent}/invitations`, 'POST', null, {
    target_account_ids: params
  });
};

export const joinEventApi = async (idEvent: any, params: any) => {
  return fetchApiRequest(`events/${idEvent}/accounts`, 'POST', null, params);
};

export const getListInvitation = async (params: any) => {
  return fetchApiRequest('event_invitations', 'GET', params, null);
};

export const respondInvitationEvent = async (idEvent: any, params: any) => {
  return fetchApiRequest(
    `events/${idEvent}/invitations/invitations_respond`,
    'POST',
    null,
    params
  );
};

export const getListHostOfEvent = async (idEvent: any, params: any) => {
  return fetchApiRequest(
    `/events/${idEvent}/invitation_hosts`,
    'GET',
    params,
    null
  );
};

export const respondInvitationHostEvent = async (idEvent: any, params: any) => {
  return fetchApiRequest(
    `events/${idEvent}/invitation_hosts/invitations_respond`,
    'POST',
    null,
    params
  );
};

export const getListHostEvent = async (idEvent: any, params: any) => {
  return fetchApiRequest(`events/${idEvent}/hosts`, 'GET', params, null);
};

export const getListInvitationHost = async (params?: any) => {
  return fetchApiRequest('event_invitation_hosts', 'GET', params, null);
};

export const getListMemberBirthdays = async () => {
  return fetchApiRequest('birthdays/index', 'GET', null, null);
};

export const getListMemberBirthdaysToday = async () => {
  return fetchApiRequest('birthdays/today', 'GET', null, null);
};

export const getListMemberBirthdaysRecent = async () => {
  return fetchApiRequest('birthdays/recent', 'GET', null, null);
};

export const getListMemberBirthdaysUpcoming = async () => {
  return fetchApiRequest('birthdays/upcoming', 'GET', null, null);
};

export const getMediaEvent = async (eventId: string, params: any) => {
  return fetchApi(`events/${eventId}/media_attachments`, 'GET', params, null);
};

export const getEventSuggestion = async (params: any) => {
  return fetchApi(`suggestions/event`, 'GET', params, null);
};

export const getUserInterestedOrGoingEvent = async (
  idEvent: any,
  params: any
) => {
  return fetchApi(`events/${idEvent}/accounts`, 'GET', params, null);
};
