import {
  Alert,
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import React from 'react';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import InputTextField from 'src/components/Input/SocialInput/InputTextField';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useFormik } from 'formik';
import OTPInput, { ResendOTP } from 'otp-input-react';
import {
  changePasswordApi,
  requestResetPasswordApi,
  requestVerifyOtpPasswordApi
} from 'src/apis/user.api';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import { buttonColor } from 'src/constants/styles';
import * as Yup from 'yup';

const FormResetPassword = props => {
  const { setIsForgetPassword, classes, setOpenRegister, resetInput } = props;

  const [isNextStep, setIsNextStep] = React.useState('1');
  const [isError, setIsError] = React.useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = React.useState(false);
  const [OTP, setOTP] = React.useState('');
  const [token, setToken] = React.useState('');
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });

  const theme = useTheme();

  const validationSchema = Yup.object({
    email: Yup.string().required('Email không được để trống'),
    password: Yup.string()
      .matches(/^\S*$/, 'Không được chứa khoảng trắng')
      .min(9, 'Mật khẩu phải tối thiểu 9 kí tự.')
      .required('Mật khẩu không được để trống'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password')], 'Mật khẩu xác nhận phải giống mật khẩu')
      .matches(/^\S*$/, 'Không được chứa khoảng trắng')
      .min(9, 'Mật khẩu phải tối thiểu 9 kí tự.')
      .required('Mật khẩu không được để trống')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      password_confirmation: ''
    },
    validationSchema: validationSchema,
    onSubmit: values => {}
  });

  const handleResendOTP = () => {
    handleCheckEmail();
  };

  // const handleTimeComplete = () => {
  // };

  const handleCheckEmail = async () => {
    try {
      setIsError(false);
      let response: any = await requestResetPasswordApi({
        email: formik.values.email
      });
      if (response.status === 200) {
        setIsNextStep('2');
      }
    } catch (error) {
      setIsError(true);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      setIsLoadingSubmit(true);
      const res = await requestVerifyOtpPasswordApi({
        otp_code: OTP,
        email: formik.values.email
      });

      if (res.status === 200) {
        setToken(res.data);
        setIsNextStep('3');
      }
    } catch (error: any) {
      setIsError(true);
      setNoti({
        code: 400,
        message: error?.response?.data?.error ?? 'Xác nhận thất bại'
      });
      setOpenSnackbar(true);
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const handleChangePassword = async () => {
    try {
      if (!token) return;
      setIsLoadingSubmit(true);

      const res = await changePasswordApi({
        reset_password_token: token?.token,
        new_password: formik.values.password,
        new_password_confirmation: formik.values.password_confirmation
      });

      if (res.status === 200) {
        setIsForgetPassword(false);
      }
    } catch (error) {}
  };

  const MenuHeader = () => {
    return (
      <>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <span
              onClick={() => setIsForgetPassword(false)}
              className={classes.buttonLink}
            >
              Đăng nhập
            </span>
          </Grid>
          <Grid container justifyContent="flex-end" item xs={6}>
            <span
              onClick={() => setOpenRegister(true)}
              className={classes.buttonLink}
            >
              Đăng ký tài khoản
            </span>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </>
    );
  };

  const StepOne = () => {
    return (
      <Grid container spacing={2}>
        <MenuHeader />
        <Grid item xs={12}>
          <Typography style={{ fontSize: 17, fontWeight: 500 }}>
            Tìm tài khoản của bạn
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ fontSize: 15, fontWeight: 500, color: '#65676b' }}
          >
            Vui lòng nhập địa chỉ email để tìm kiếm tài khoản của bạn.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <InputTextField
            formik={formik}
            id="email"
            label="Địa chỉ email"
            required
            field="email"
            autoComplete="email"
            reset={resetInput}
            autoFocus
          />
        </Grid>

        {isError && (
          <Grid item xs={12}>
            <Alert sx={{ width: '100% !important' }} severity="error">
              <Typography style={{ fontSize: 15, fontStyle: 'italic' }}>
                Không tìm thấy tài khoản, vui lòng kiểm tra lại địa chỉ email đã
                nhập.
              </Typography>
            </Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography
            style={{ fontSize: 12, fontWeight: 500, color: '#65676b' }}
          >
            Bằng cách nhấn vào Tiếp tục, chúng tôi sẽ gửi vào email của bạn một
            tin nhắn xác nhận. Vui lòng đọc kĩ tin nhắn và thực hiện các bước
            tiếp theo theo hướng dẫn.
          </Typography>
        </Grid>

        <Grid container item justifyContent="flex-end" xs={12}>
          <ButtonInherit
            action={handleCheckEmail}
            disabled={!!formik.errors.email}
            style={{
              marginRight: 0,
              color: '#e7f3ff',
              backgroundColor: buttonColor.backgroundColor
            }}
            color={buttonColor.backgroundColor}
            label="Tiếp tục"
          />
        </Grid>
      </Grid>
    );
  };

  const renderButton = buttonProps => {
    return (
      <button
        {...buttonProps}
        style={{
          cursor: buttonProps?.remainingTime === 0 ? 'pointer' : 'default',
          opacity: buttonProps?.remainingTime === 0 ? 1 : 0.5,
          margin: '0 10px',
          fontWeight: '600!important',
          fontSize: '16px',
          padding: '4px 12px',
          backgroundColor: '#7165E0',
          color: '#f7f7f7',
          height: '36px',
          borderRadius: '6px'
        }}
      >
        {buttonProps.remainingTime !== 0
          ? `Gửi lại (${buttonProps.remainingTime.toString().padStart(2, '0')})`
          : 'Gửi lại'}
      </button>
    );
  };

  const renderTime = () => React.Fragment;

  const StepTwo = () => {
    return (
      <Box>
        <Typography
          onClick={() => setIsNextStep('1')}
          className={classes.textBack}
        >
          <ArrowBackIosIcon fontSize="small" /> Quay lại
        </Typography>

        <Divider sx={{ my: '6px' }} />
        <Typography mb="10px">Nhập mã OTP:</Typography>
        <OTPInput
          value={OTP}
          onChange={e => {
            setIsError(false);
            setOTP(e);
          }}
          autoFocus
          OTPLength={6}
          otpType="number"
          disabled={false}
          inputStyles={{
            borderRadius: '5px',
            border: `2px solid`,
            borderColor: isError ? '#ec0b0b' : 'rgb(186, 185, 185)',
            height: '45px',
            margin: '0',
            width: '45px'
          }}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '20px '
          }}
        />

        <Stack
          direction="row"
          my="20px"
          gap={1}
          sx={{ p: 1, backgroundColor: '#e7f3ff', borderRadius: '8px' }}
        >
          <ErrorOutlineIcon fontSize="medium" color="primary" />
          <Typography fontSize={14} fontStyle="italic" color="#666">
            Bạn cần lấy thông tin mã xác nhận từ email đăng ký tài khoản và điền
            mã theo yêu cầu để nhập mật khẩu mới. Nếu chưa nhận được mã xác
            thực, vui lòng chọn Gửi lại.
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="end" alignItems="center">
          <ResendOTP
            style={{ alignItems: 'center' }}
            maxTime={60}
            renderButton={renderButton}
            onResendClick={handleResendOTP}
            renderTime={renderTime}
          />

          <ButtonInherit
            label="Xác nhận"
            loading={isLoadingSubmit}
            color={buttonColor.backgroundColor}
            disabled={OTP.length < 6 ? true : false}
            style={{ marginRight: 0 }}
            action={handleVerifyOtp}
          />
        </Stack>
      </Box>
    );
  };

  const StepThree = () => {
    return (
      <Box>
        <Typography
          onClick={() => setIsNextStep('2')}
          className={classes.textBack}
        >
          <ArrowBackIosIcon fontSize="small" /> Quay lại
        </Typography>

        <Divider sx={{ my: '6px' }} />

        <Stack mb="20px" mt="10px" spacing={2}>
          <Box>
            <Typography gutterBottom>Nhập mật khẩu mới</Typography>
            <InputTextField
              formik={formik}
              id="password"
              placeholder="Mật khẩu"
              size="small"
              required
              field="password"
              type="password"
              autoComplete="new-password"
            />
          </Box>
          <Box>
            <Typography gutterBottom>Nhập lại mật khẩu mới</Typography>
            <InputTextField
              formik={formik}
              id="password_confirmation"
              placeholder="Xác nhận mật khẩu"
              size="small"
              required
              field="password_confirmation"
              type="password"
            />
          </Box>
        </Stack>

        <Stack
          direction="row"
          gap={1}
          sx={{ p: 1, backgroundColor: '#e7f3ff', borderRadius: '8px' }}
        >
          <ErrorOutlineIcon fontSize="medium" color="primary" />
          <Typography fontSize={14} fontStyle="italic" color="#666">
            Bạn cần hoàn thành bước cuối cùng xác nhận mật khẩu mới.
          </Typography>
        </Stack>

        <Box mt="10px" display="flex">
          <ButtonInherit
            label="Hoàn thành"
            loading={isLoadingSubmit}
            color={buttonColor.backgroundColor}
            disabled={
              !!formik.errors.password || !!formik.errors.password_confirmation
            }
            style={{ marginRight: 0, marginLeft: 'auto' }}
            action={handleChangePassword}
          />
        </Box>
      </Box>
    );
  };

  const renderStepResetPassword = () => {
    switch (isNextStep) {
      case '1':
        return StepOne();

      case '2':
        return StepTwo();

      case '3':
        return StepThree();

      default:
        return StepOne();
    }
  };

  return (
    <Box>
      {renderStepResetPassword()}

      <SnackbarNotification
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        code={noti?.code}
        message={noti?.message}
      />
    </Box>
  );
};

export default FormResetPassword;
