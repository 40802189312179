import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import React, { useState } from 'react';
import ButtonCustom from 'src/components/Button/Button';
import ButtonInherit from 'src/components/Button/ButtonInherit';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  getCalendarEventApi,
  getEventCategories,
  getEventSuggestion
} from 'src/apis/socialEvent.api';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import InputSearch from 'src/components/Input/SocialInput/InputSearch';
import ListButton from 'src/components/List/ListButton';
import Loading from 'src/components/Loading/Loading';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import { PATHS } from 'src/constants/paths';
import { buttonColor } from 'src/constants/styles';
import SearchGlobalLeft from 'src/pages/SeachGlobal/SeachGlobalLeft';
import {
  resetEvent,
  statusEventUpdate
} from 'src/store/action/socialEventAction';
import { getListMyPageReq } from 'src/store/action/socialPageAction';
import EventCreateUpdate from '../EventCreateUpdate/EventCreateUpdate';
import avatarDefault from 'src/assets/images/avatar_default.jpg';
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        height: '100%',
        position: 'fixed',
        boxShadow: '1px 0px 5px 0.4px rgb(0 0 0 / 10%)'
      },
      headerPage: {
        minHeight: 50,
        margin: '10px 0px 0px'
      },
      footerPage: {
        position: 'absolute',
        bottom: '50px',
        left: 0,
        width: '95%',
        zIndex: 1200
      },
      rootPage: {
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        '&:hover': {
          overflowY: 'auto'
        },
        '&:hover&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#bdbdbd',
          borderRadius: 10
        }
      },
      inputSearch: {
        width: '100%'
        // marginTop: '10px !important'
      },
      cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
          borderColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        '&:hover': {
          backgroundColor:
            theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        '& fieldset': {
          display: 'none'
        },
        backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C',
        borderRadius: '30px !important',
        height: 35,
        padding: '7px !important',
        margin: 'auto'
      },
      notchedOutline: {
        border: `1px solid ${theme.palette.myBackgroundColor?.main} !important`
      },
      multilineColor: {
        fontSize: 13
      },
      avatar: {
        width: '56px !important',
        height: '56px !important'
      },
      list: {
        width: '100%'
      },
      selected: {
        backgroundColor: '#ECF3FF !important'
      },
      highlight: {
        backgroundColor: theme.palette.background.default
      }
    }),
  { index: 1 }
);

const EventLeft = () => {
  const classes = useStyles();
  const history = useHistory();
  const match: any = useRouteMatch();
  const dispatch = useDispatch();
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const eventInfo = useSelector((state: any) => state.eventReducer.info);
  const statusEvent = useSelector(
    (state: any) => state.eventReducer.status || 'normal'
  );
  const idPage = history?.location?.search.slice(1);
  const pagesAdminIdParam = useSelector(
    (state: any) => state.pageReducer.my_pages
  )?.filter((e: any) => {
    if (e.id === idPage) {
      return e;
    }
  });
  const hasMore = useSelector((state: any) => state.pageReducer.hasMore);
  const pagesAdmin = useSelector(
    (state: any) => state.pageReducer.my_pages
  )?.map((el: any) => ({
    id: el.id,
    username: el.username,
    avatar_icon: el.avatar_media
      ? el.avatar_media.show_url ??
        el.avatar_media.preview_url ??
        el.avatar_media.url
      : avatarDefault,

    action: () => {
      setEventOwner({
        page_owner_id: el.id,
        title: el.title,
        avatar_icon: el.avatar_media
          ? el.avatar_media.show_url ??
            el.avatar_media.preview_url ??
            el.avatar_media.url
          : avatarDefault,
        description: 'Bên tổ chức - Trang của bạn'
      });
    },
    label: el.title,
    styleLabel: { width: '228px' }
  }));
  const [keyParams, setKeyParams] = React.useState<any>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<any>('home');
  const [createEvent, setCreateEvent] = useState(false);
  const [updateEvent, setUpdateEvent] = useState<any>(false);
  const matchesCreate = useMediaQuery('(max-width:1000px)');
  const [eventOwner, setEventOwner] = React.useState<any>({});
  const [listEventMe, setListEventMe] = React.useState<any>(null);
  const [listDiscoverEvent, setListDiscoverEvent] = React.useState<any>(null);
  const [checkCreateUpdate, setCheckCreateUpdate] = React.useState<any>(false);
  const [keyword, setKeyword] = React.useState<any>('');
  const [categories, setCategories] = React.useState<any>([]);
  const [indexCategories, setIndexCategories] = React.useState();
  const theme: any = useTheme();
  const [openSnackbar, setOpenSnackBar] = React.useState<any>(false);
  const [noti, setNoti] = React.useState({
    code: 200,
    message: ''
  });
  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => {
    if (match.path === PATHS.EVENT_CREATE) {
      dispatch(resetEvent());
    }
  }, [match.path]);

  const checkAvatar = () => {
    if (pagesAdminIdParam[0]?.avatar_media?.show_url) {
      return pagesAdminIdParam[0]?.avatar_media?.show_url;
    } else {
      if (meInfo.avatar_media) {
        return (
          meInfo.avatar_media.show_url ??
          meInfo.avatar_media.preview_url ??
          meInfo.avatar_media.url
        );
      } else {
        return avatarDefault;
      }
    }
  };
  React.useEffect(() => {
    setEventOwner({
      page_owner_id: pagesAdminIdParam[0] ? pagesAdminIdParam[0]?.id : null,
      id: pagesAdminIdParam[0] ? pagesAdminIdParam[0]?.id : meInfo.id,
      title: pagesAdminIdParam[0]
        ? pagesAdminIdParam[0]?.title
        : meInfo.display_name,
      avatar_icon: checkAvatar(),
      description: pagesAdminIdParam[0]
        ? 'Bên tổ chức - Trang của bạn'
        : 'Người tổ chức - Trang cá nhân của bạn'
    });
  }, [JSON.stringify(meInfo)]);

  const fetchCalendarEvent = async (params: any, setData: any) => {
    let response = await getCalendarEventApi(params);
    if (response.status === 200) {
      setData(() => response.data);
      dispatch(statusEventUpdate('normal'));
      setIsLoading(false);
    }
  };

  const fetchEventSuggestion = async (params: any) => {
    let response = await getEventSuggestion(params);
    if (response.status === 200) {
      setListDiscoverEvent(() => response.data);
    }
  };

  const listMenuEvent = [
    {
      id: 'event_home',
      title: 'Trang chủ',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i
          className="fa-light fa-calendar-days"
          style={{ fontSize: '20px' }}
        ></i>
      ),
      action: () => {
        history.push(PATHS.EVENT);
      },
      style: { height: 36, width: 36 },
      position: 'top'
    },
    {
      id: 'calendar',
      title: 'Sự kiện của bạn',
      styleTitle: { fontWeight: '600' },
      icon: <i className="fa-light fa-user" style={{ fontSize: '20px' }}></i>,
      action: () => {
        history.push('/events/calendar?q=going');
      },
      accordion: true,
      style: { height: 36, width: 36 },
      position: 'top',
      itemChildren: [
        {
          id: 'going',
          title: 'Sẽ tham gia',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-circle-check"
            ></i>
          ),
          action: () => {
            history.push(`/events/going`);
          }
        },
        {
          id: 'invites',
          title: 'Lời mời',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-envelope"
            ></i>
          ),
          action: () => {
            history.push(`/events/invites`);
          }
        },
        {
          id: 'interested',
          title: 'Quan tâm',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-star"
            ></i>
          ),
          action: () => {
            history.push(`/events/interested`);
          }
        },
        {
          id: 'hosting',
          title: 'Tổ chức',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-house"
            ></i>
          ),
          action: () => {
            history.push(`/events/hosting`);
          }
        },
        {
          id: 'past',
          title: 'Sự kiện đã qua',
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-arrow-rotate-left"
            ></i>
          ),
          action: () => {
            history.push(`/events/past`);
          }
        }
      ]
    },
    {
      id: 'birthday',
      title: 'Sinh nhật',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i
          className="fa-light fa-cake-candles"
          style={{ fontSize: '20px' }}
        ></i>
      ),
      action: () => {
        history.push('/events/birthdays');
      },
      style: { height: 36, width: 36 },
      position: 'top'
    }
    // {
    //   id: 'event_notification',
    //   title: 'Thông báo',
    //   styleTitle: { fontWeight: '600' },
    //   icon: <i className="fa-light fa-bell" style={{ fontSize: '20px' }}></i>,
    //   action: () => {},
    //   style: { height: 36, width: 36 },
    //   position: 'top'
    // }
  ];
  const fetchCategories = async () => {
    let response = await getEventCategories();
    if (response.status === 200) {
      setCategories(response.data);
    }
  };

  React.useEffect(() => {
    fetchCategories();
  }, []);

  React.useEffect(() => {
    let { id, key, tab } = match?.params;
    switch (match.path) {
      case PATHS.EVENT:
        setSelectedIndex('event_home');
        break;
      case PATHS.EVENT_MINE: {
        setKeyParams('key');
        if (id) {
          setSelectedIndex(id);
        }
        if (key) {
          setSelectedIndex(key);
        }
        if (tab) {
          setSelectedIndex(tab);
        }
        break;
      }
      case PATHS.EVENT_CREATE: {
        setSelectedIndex('create_event');
        break;
      }
      case PATHS.EVENT_DETAIL: {
        setSelectedIndex('');
        break;
      }
    }
  }, [match.path, JSON.stringify(match.params)]);

  React.useEffect(() => {
    if (match.path === PATHS.EVENT_MINE || match.path === PATHS.EVENT_DETAIL) {
      fetchEventSuggestion({
        limit: 3,
        time: 'upcoming'
      });
    }
  }, [match.path]);

  React.useEffect(() => {
    if (
      match.path === PATHS.EVENT_MINE ||
      match.path === PATHS.EVENT_DETAIL ||
      statusEvent === 'update'
    ) {
      setIsLoading(true);
      fetchCalendarEvent(
        {
          event_account_status: 'hosting',
          time: 'upcoming',
          limit: 3
        },
        setListEventMe
      );
    }
  }, [match.path, statusEvent]);

  const renderListEvent = (event: any, index: any) => {
    return (
      <Card
        key={event.id}
        sx={{
          boxShadow: 'none !important',
          borderRadius: '10px !important'
        }}
      >
        <CardActionArea
          onClick={() => history.push(`/event/${event.id}/about`)}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CardMedia
              component="img"
              height="50px"
              sx={{
                maxWidth: '80px',
                marginLeft: '12px',
                borderRadius: '8px'
              }}
              image={event?.banner ? event.banner.preview_url : ''}
            />
            <CardContent
              sx={{
                width: '100%',
                paddingRight: '8px'
              }}
            >
              <Typography fontSize={12} color="#f3425f">
                {event?.start_time}
              </Typography>
              <Typography
                gutterBottom
                fontSize={16}
                fontWeight={600}
                sx={{
                  maxWidth: '180px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {event?.title}
              </Typography>
            </CardContent>
          </Box>
        </CardActionArea>
      </Card>
    );
  };

  const loadActivity = maxId => {
    dispatch(
      getListMyPageReq({
        max_id: maxId,
        limit: 10
      })
    );
  };

  const funcLoad = () => {
    let pageLast = [...pagesAdmin].pop();
    let maxId = pageLast?.id;
    loadActivity(maxId);
  };

  const renderCategory = () => {
    return (
      <Box sx={{ height: '1860px' }}>
        <Divider />
        <Box sx={{ margin: '8px 8px 0px' }}>
          <Typography fontSize={16} fontWeight={600} gutterBottom>
            Hạng mục
          </Typography>
        </Box>
        {categories?.map((item: any) => (
          <>
            <ListItem
              key={item.id}
              disablePadding
              onClick={() => setIndexCategories(item?.id)}
              className={
                item && item?.id === indexCategories ? classes.highlight : ''
              }
            >
              <ListItemButton
                onClick={() => history.push(`/events/categories/${item.id}`)}
              >
                <ListItemText
                  primary={
                    <Typography fontSize={15} fontWeight={600}>
                      {item.text}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          </>
        ))}
      </Box>
    );
  };
  const renderHeader = () => {
    let { key, id } = match.params;

    switch (match.path) {
      case PATHS.EVENT_CREATE: {
        switch (key) {
          case 'online':
            return (
              <Grid container>
                <Grid container item xs={12}>
                  <IconButton
                    sx={{
                      backgroundColor: '#999999',
                      color: '#fff',
                      height: 35,
                      width: 35,
                      margin: '0 5px'
                    }}
                    onClick={() => history.push(PATHS.EVENT_CREATE)}
                  >
                    <ClearIcon />
                  </IconButton>
                  &nbsp;
                  <Typography style={{ fontWeight: 'bold' }} variant="h5">
                    Chi tiết sự kiện
                  </Typography>
                </Grid>
              </Grid>
            );
          case 'offline':
            return (
              <Grid container>
                <Grid container item xs={12}>
                  <IconButton
                    sx={{
                      backgroundColor: '#999999',
                      color: '#fff',
                      height: 35,
                      width: 35,
                      margin: '0 5px'
                    }}
                    onClick={() => history.push('/events/home')}
                  >
                    <ClearIcon />
                  </IconButton>
                  &nbsp;
                  <Typography style={{ fontWeight: 'bold' }} variant="h5">
                    Chi tiết sự kiện
                  </Typography>
                </Grid>
              </Grid>
            );
          default:
            return (
              <Grid container>
                <Grid container item xs={12}>
                  <IconButton
                    sx={{
                      backgroundColor: '#b4b3b3',
                      color: '#fff',
                      height: 35,
                      width: 35,
                      margin: '0 5px'
                    }}
                    onClick={() => history.push(PATHS.EVENT)}
                  >
                    <ClearIcon />
                  </IconButton>
                  &nbsp;
                  <Typography style={{ fontWeight: 'bold' }} variant="h5">
                    Tạo sự kiện
                  </Typography>
                </Grid>
              </Grid>
            );
        }
      }
      case PATHS.EVENT_DETAIL:
        switch (id) {
          case 'search':
            return null;
        }
        switch (key) {
          case 'edit':
            return (
              <Grid container>
                <Grid container item xs={12}>
                  <IconButton
                    sx={{
                      backgroundColor: '#999999',
                      color: '#fff',
                      height: 35,
                      width: 35,
                      margin: '0 5px'
                    }}
                    onClick={() => history.push(`/event/${id}/about`)}
                  >
                    <ClearIcon />
                  </IconButton>
                  &nbsp;
                  <Typography style={{ fontWeight: 'bold' }} variant="h5">
                    Chỉnh sửa sự kiện
                  </Typography>
                </Grid>
              </Grid>
            );

          default:
            return (
              <Grid container spacing={2}>
                <Grid
                  container
                  item
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    style={{ margin: '8px 14px 8px 3px', fontWeight: 'bold' }}
                    variant="h5"
                  >
                    Sự kiện
                  </Typography>
                  <div style={{ margin: '8px 14px 8px 3px', width: '100%' }}>
                    <InputSearch
                      keyword={keyword}
                      setKeyword={setKeyword}
                      label="Tìm kiếm sự kiện"
                      action={() => history.push(`/event/search?q=${keyword}`)}
                    />
                  </div>
                </Grid>
              </Grid>
            );
        }
      default:
        return (
          <Grid container spacing={2}>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: 'bold', margin: '8px 14px 3px 3px' }}
                variant="h5"
              >
                Sự kiện
              </Typography>
              <div style={{ margin: '8px 14px 8px 3px', width: '100%' }}>
                <InputSearch
                  keyword={keyword}
                  setKeyword={setKeyword}
                  label="Tìm kiếm sự kiện"
                  action={() => history.push(`/event/search?q=${keyword}`)}
                />
              </div>
            </Grid>
          </Grid>
        );
    }
  };

  const renderBody = () => {
    let { key, id } = match?.params;

    switch (match.path) {
      case PATHS.EVENT_CREATE:
        switch (key) {
          case 'online':
            return {
              bodyTop: null,
              bodyCenter: <div></div>,
              bodyFooter: null
            };
          case 'offline':
            return {
              bodyTop: null,
              bodyCenter: (
                <div style={{ margin: '0px 10px 160px 0px' }}>
                  <IconButtonOptions
                    name={eventOwner.title}
                    styleNameButton={{
                      width: '190px',
                      color: 'text.primary'
                    }}
                    avatar={eventOwner.avatar_icon}
                    description={eventOwner.description}
                    openPopup={!pagesAdmin.length ? false : true}
                    icon={
                      <i
                        style={{ fontSize: '16px' }}
                        className="fa-solid fa-caret-down"
                      ></i>
                    }
                    styleAvatar={{ height: 36, width: 36, marginRight: '12px' }}
                    style={{
                      margin: '0px 10px',
                      width: matchesCreate ? '100%' : '330px',
                      minHeight: '64px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: 'button.primary.background'
                    }}
                    options={[
                      [
                        { title: 'Hồ sơ' },
                        {
                          id: meInfo.id,
                          label: meInfo.display_name,
                          avatar_icon: meInfo.avatar_media
                            ? meInfo.avatar_media.show_url ??
                              meInfo.avatar_media.preview_url ??
                              meInfo.avatar_media.url
                            : avatarDefault,
                          checkbox: true,
                          action: () => {
                            setEventOwner({
                              id: meInfo.id,
                              title: meInfo.display_name,
                              avatar_icon: meInfo.avatar_media
                                ? meInfo.avatar_media.show_url ??
                                  meInfo.avatar_media.preview_url ??
                                  meInfo.avatar_media.url
                                : avatarDefault,
                              description:
                                'Người tổ chức - Trang cá nhân của bạn'
                            });
                          },
                          styleLabel: { width: '228px' }
                        }
                      ],
                      [{ title: 'Trang' }].concat(
                        pagesAdmin.map((el: any) => ({
                          ...el,
                          checkbox: true
                        }))
                      )
                    ]}
                    styleListMenu={{
                      minWidth: '320px',
                      height: '250px',
                      overflowY: 'scroll'
                    }}
                    stylePopup={{
                      zIndex: '1001',
                      width: matchesCreate ? '98%' : '330px'
                    }}
                    searchFeature={true}
                    actionSearch={el => {
                      setEventOwner({
                        page_owner_id: el.id,
                        title: el.title,
                        avatar_icon: el.avatar_media?.show_url
                          ? el.avatar_media.show_url
                          : avatarDefault,
                        description: 'Bên tổ chức - Trang của bạn'
                      });
                    }}
                    infiniteScroll={true}
                    funcLoad={funcLoad}
                    hasMore={hasMore}
                  />
                  <EventCreateUpdate
                    setCheckCreateUpdate={setCheckCreateUpdate}
                    organization={eventOwner}
                    createEvent={createEvent}
                    setCreateEvent={setCreateEvent}
                    setOpenSnackBar={setOpenSnackBar}
                    setNoti={setNoti}
                  />
                </div>
              ),
              bodyFooter: null
            };
          default:
            return {
              bodyTop: null,
              bodyCenter: (
                <div style={{ margin: '0px 10px 0px 0px', padding: '0px 5px' }}>
                  <IconButtonOptions
                    name={eventOwner.title}
                    styleNameButton={{
                      width: '190px',
                      color: 'text.primary'
                    }}
                    avatar={eventOwner.avatar_icon}
                    description={eventOwner.description}
                    styleAvatar={{ height: 36, width: 36, marginRight: '12px' }}
                    icon={
                      <i
                        style={{ fontSize: '16px' }}
                        className="fa-solid fa-caret-down"
                      ></i>
                    }
                    openPopup={true}
                    style={{
                      margin: '0px 10px',
                      width: '330px',
                      minHeight: '64px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: 'button.primary.background'
                    }}
                    options={[
                      [
                        { title: 'Hồ sơ' },
                        {
                          id: meInfo.id,
                          label: meInfo.display_name,
                          avatar_icon: meInfo.avatar_media
                            ? meInfo.avatar_media.show_url ??
                              meInfo.avatar_media.preview_url ??
                              meInfo.avatar_media.url
                            : avatarDefault,
                          checkbox: true,
                          action: () => {
                            setEventOwner({
                              id: meInfo.id,
                              title: meInfo.display_name,
                              avatar_icon: meInfo.avatar_media
                                ? meInfo.avatar_media.show_url ??
                                  meInfo.avatar_media.preview_url ??
                                  meInfo.avatar_media.url
                                : avatarDefault,
                              description:
                                'Người tổ chức - Trang cá nhân của bạn'
                            });
                          },
                          styleLabel: { width: '228px' }
                        }
                      ],
                      [{ title: 'Trang' }].concat(
                        pagesAdmin.map((el: any) => ({
                          ...el,
                          checkbox: true
                        }))
                      )
                    ]}
                    styleListMenu={{
                      minWidth: '320px',
                      height: '250px',
                      overflowY: 'scroll'
                    }}
                    stylePopup={{ zIndex: '1001', width: '330px' }}
                    searchFeature={true}
                    actionSearch={el => {
                      setEventOwner({
                        page_owner_id: el.id,
                        title: el.title,
                        avatar_icon: el.avatar_media?.show_url
                          ? el.avatar_media.show_url
                          : avatarDefault,
                        description: 'Bên tổ chức - Trang của bạn'
                      });
                    }}
                    infiniteScroll={true}
                    funcLoad={funcLoad}
                    hasMore={hasMore}
                  />
                  <ListButton
                    item={[
                      {
                        id: 'create_event',
                        title: 'Tạo sự kiện',
                        styleTitle: { fontWeight: '600' },
                        icon: (
                          <i
                            className="fa-solid fa-user-group"
                            style={{ fontSize: '18px' }}
                          ></i>
                        ),
                        style: { height: 36, width: 36 },
                        position: 'top'
                      }
                    ]}
                    selectedItem={selectedIndex}
                    width={'340px'}
                    typeAction={'action'}
                  />
                </div>
              ),
              bodyFooter: null
            };
        }

      case PATHS.EVENT:
      case PATHS.EVENT_CATEGORY:
        return {
          bodyTop: (
            <>
              <ListButton
                item={listMenuEvent.filter(el => el.position === 'top')}
                selectedItem={selectedIndex}
                width={'280px'}
                styleAccordionDetail={{ padding: '3px' }}
                stylePadding={{ padding: '0px !important', margin: '6px 0' }}
                styleMargin={{ margin: '0px 5px 0px 0px !important' }}
                typeAction={'action'}
              />
              <div style={{ padding: '0px 10px', width: 300 }}>
                <ButtonCustom
                  label="Tạo sự kiện mới"
                  action={() => {
                    history.push('/events/create/offline');
                    dispatch(resetEvent());
                  }}
                  solid={true}
                  style={{
                    color: '#e7f3ff',
                    backgroundColor: buttonColor.backgroundColor
                  }}
                  icon={<i className="far fa-plus"></i>}
                />
              </div>
            </>
          ),
          bodyCenter: renderCategory(),
          bodyFooter: null
        };
      case PATHS.EVENT_MINE:
      case PATHS.EVENT_DETAIL:
        switch (id) {
          case 'search':
            return {
              bodyTop: null,
              bodyCenter: (
                <div>
                  <SearchGlobalLeft
                    type="event"
                    styleGui={{
                      height: '100%',
                      width: 310,
                      backgroundColor: theme.palette.background.primary,
                      position: 'none',
                      overflowY: 'hidden'
                    }}
                  />
                  ;
                </div>
              ),
              bodyFooter: null
            };
        }
        switch (key) {
          case 'edit':
            return {
              bodyTop: (
                <div style={{ margin: '0px 10px 160px 0px' }}>
                  <IconButtonOptions
                    name={eventInfo?.page_owner?.title ?? eventOwner.title}
                    avatar={
                      eventInfo?.page_owner?.avatar_icon ??
                      eventOwner.avatar_icon
                    }
                    description={
                      eventInfo?.page_owner?.description ??
                      eventOwner.description
                    }
                    styleAvatar={{
                      height: 36,
                      width: 36,
                      marginRight: '12px'
                    }}
                    style={{
                      margin: '0px 0px',
                      width: '100%',
                      minHeight: '64px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: '#fff'
                    }}
                    disabled
                  />
                  <EventCreateUpdate
                    setCheckCreateUpdate={setCheckCreateUpdate}
                    updateEvent={updateEvent}
                    idUpdate={id}
                    setUpdateEvent={setUpdateEvent}
                    setOpenSnackBar={setOpenSnackBar}
                    setNoti={setNoti}
                  />
                </div>
              ),
              bodyCenter: null,
              bodyFooter: null
            };

          default:
            return {
              bodyTop: (
                <>
                  <ListButton
                    item={listMenuEvent.filter(el => el.position === 'top')}
                    selectedItem={selectedIndex}
                    width={'280px'}
                    styleAccordionDetail={{ padding: '8px 5px 5px' }}
                    stylePadding={{
                      padding: '0px !important',
                      margin: '6px 0'
                    }}
                    styleMargin={{ margin: '0px 5px 0px 0px !important' }}
                    typeAction={'action'}
                  />
                  <div style={{ padding: '0px 10px', width: 300 }}>
                    <ButtonCustom
                      label="Tạo sự kiện mới"
                      action={() => {
                        history.push('/events/create/offline');
                        dispatch(resetEvent());
                      }}
                      solid={true}
                      style={{
                        color: '#e7f3ff',
                        backgroundColor: buttonColor.backgroundColor
                      }}
                      icon={<i className="far fa-plus"></i>}
                    />
                  </div>
                </>
              ),
              bodyCenter: (
                <div
                  style={{
                    width: '300px',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Divider />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '8px'
                      }}
                    >
                      <Typography fontSize={16} fontWeight={600}>
                        Sự kiện sắp tới của bạn
                      </Typography>
                      <Typography
                        onClick={() => history.push('/events/hosting')}
                        fontSize={14}
                        color={buttonColor.backgroundColor}
                        sx={{ cursor: 'pointer' }}
                      >
                        Xem tất cả
                      </Typography>
                    </div>
                    {isLoading ? (
                      <Loading style={{ marginBottom: '6px' }} />
                    ) : listEventMe?.length ? (
                      listEventMe?.map((el: any, index) =>
                        renderListEvent(el, index)
                      )
                    ) : (
                      <span style={{ paddingLeft: '10px' }}>
                        Không có dữ liệu
                      </span>
                    )}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Divider />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '8px'
                      }}
                    >
                      <Typography fontSize={16} fontWeight={600}>
                        Sự kiện đề xuất
                      </Typography>
                      <Typography
                        onClick={() => history.push('/events/home')}
                        fontSize={14}
                        color={buttonColor.backgroundColor}
                        sx={{ cursor: 'pointer' }}
                      >
                        Xem tất cả
                      </Typography>
                    </div>
                    {listDiscoverEvent?.length ? (
                      listDiscoverEvent?.map((el: any, index) =>
                        renderListEvent(el, index)
                      )
                    ) : (
                      <span>Không có dữ liệu</span>
                    )}
                  </div>
                </div>
              ),
              bodyFooter: renderCategory()
              // <div
              //   style={{
              //     width: '300px',
              //     display: 'flex',
              //     flexDirection: 'column',
              //     marginBottom: '105px'
              //   }}
              // >
              //   <Divider sx={{ margin: '0px 0px 5px' }} />
              //   <div
              //     style={{
              //       margin: '8px 8px 0px'
              //     }}
              //   >
              //     <Typography fontSize={16} fontWeight={600}>
              //       Hạng mục
              //     </Typography>
              //   </div>
              //   <ListButton
              //     item={[
              //       {
              //         id: 1,
              //         title: 'Đồ uống'
              //       },
              //       { id: 2, title: 'Game' },
              //       { id: 3, title: 'Hài kịch' },
              //       { id: 4, title: 'Khiêu vũ' },
              //       { id: 5, title: 'Mua sắm' },
              //       { id: 6, title: 'Nhạc và âm thanh' },
              //       { id: 5, title: 'Phim và chương trình TV' },
              //       { id: 5, title: 'Thể thao' }
              //     ]}
              //     width={'280px'}
              //   />
              // </div>
            };
        }
      default:
        return { bodyTop: null, bodyCenter: null, bodyFooter: null };
    }
  };

  const renderFooter = () => {
    let { key, id } = match.params;
    switch (match.path) {
      case PATHS.EVENT_CREATE: {
        if (key) {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '12px',
                backgroundColor: 'background.secondary',
                marginBottom: '-8px'
              }}
            >
              <ButtonInherit
                loading={createEvent}
                label="Đăng sự kiện"
                color={buttonColor.backgroundColor}
                textColor="#fff"
                fullWidth={true}
                action={() => {
                  setCreateEvent(true);
                }}
                disabled={!checkCreateUpdate}
              />
            </Box>
          );
        } else return null;
      }
      case PATHS.EVENT_MINE:
        return null;
      case PATHS.EVENT_DETAIL:
        if (key === 'edit') {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '12px',
                backgroundColor:
                  theme.palette.mode === 'light' ? '#fff' : '#242526',
                marginBottom: '-5px'
              }}
            >
              <ButtonInherit
                loading={updateEvent}
                label="Cập nhật Sự kiện"
                color={buttonColor.backgroundColor}
                textColor="#fff"
                fullWidth={true}
                action={() => {
                  setUpdateEvent(true);
                }}
                disabled={!checkCreateUpdate}
              />
            </Box>
          );
        }
        break;

      default:
        return null;
    }
  };

  return (
    <Box>
      <Box
        className={classes.root}
        sx={{
          position:
            renderBody().bodyTop ||
            renderBody().bodyCenter ||
            renderBody().bodyFooter
              ? 'fixed'
              : 'static',

          width:
            match.path === PATHS.EVENT_CREATE ||
            (match.params?.key === 'edit' && !!match.params?.id)
              ? matchesCreate
                ? '100%'
                : 370
              : renderBody().bodyTop ||
                renderBody().bodyCenter ||
                renderBody().bodyFooter
              ? 310
              : 0,
          margin:
            renderBody().bodyTop ||
            renderBody().bodyCenter ||
            renderBody().bodyFooter
              ? 0
              : '5px 10px 40px -20px',
          padding:
            match.path === PATHS.EVENT_CREATE ||
            (match.params?.key === 'edit' && !!match.params?.id)
              ? '5px 5px 40px 5px'
              : '5px 0px 40px 3px',
          backgroundColor: 'background.primary'
        }}
      >
        {match.params?.id !== 'search' && (
          <div className={classes.headerPage}>{renderHeader()}</div>
        )}

        <div className={classes.rootPage}>
          {renderBody().bodyTop}
          {match.path !== PATHS.PAGE_CREATE &&
            match.params?.id !== 'search' && <div style={{ height: 10 }}></div>}
          {renderBody().bodyCenter}
          {match.path === PATHS.EVENT_DETAIL || match.path === PATHS.EVENT_MINE
            ? renderBody().bodyFooter
            : null}
        </div>

        <div className={classes.footerPage}>
          {match.path !== PATHS.PAGE_CREATE &&
            match.path !== PATHS.PAGE &&
            renderFooter() && <Divider />}
          {renderFooter()}
        </div>
      </Box>
      {openSnackbar && (
        <SnackbarNotification
          style={{ marginLeft: '50px' }}
          open={openSnackbar}
          setOpen={setOpenSnackBar}
          code={noti?.code}
          message={noti?.message}
        />
      )}
    </Box>
  );
};

export default EventLeft;
