import SearchIcon from '@mui/icons-material/Search';
import {
  Avatar,
  Box,
  Divider,
  Grid,
  Skeleton,
  Slider,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { getFrameApi } from 'src/apis/commonSocialApi';
import { getMediaGroupApi } from 'src/apis/socialGroup';
import { getMediaPage } from 'src/apis/socialPages.api';
import { getMediaUser } from 'src/apis/socialUser';
import group_default from 'src/assets/images/group_cover.png';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import InputTextField from 'src/components/Input/SocialInput/InputTextField';
import TabSocial from 'src/components/Tabs/TabSocial';
import { boxShadow, buttonColor } from 'src/constants/styles';
import DialogReport from '../DialogReport';
import getCroppedImg from './cropImage';

const useStyles = makeStyles((theme: Theme) => ({
  imgGif: {
    borderRadius: 5,
    border: '1px solid rgba(0,0,0,0.1)',
    height: 145,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  gridItem: {
    height: 150
  },
  wrapImage: {
    height: 100,
    width: '100%',
    backgroundColor: '#E4E6EB',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: ' center',
    border: '1px solid rgba(0,0,0,0.1)',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  wrapText: {
    fontSize: '15px !important',
    fontWeight: '500 !important',
    margin: '5px 0px 0px 0px !important',
    wordBreak: 'break-word'
  },
  subWrapText: {
    fontSize: '13px !important',
    wordBreak: 'break-word',
    marginTop: '-2px !important'
  },
  wrapFrame: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 64,
    padding: '0px 8px',
    '&:hover': {
      cursor: 'pointer',
      borderRadius: 10,
      backgroundColor: 'rgba(0,0,0,0.1)'
    }
  },
  wrapAvatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '& .reactEasyCrop_CropAreaRound': {
      border: '0px !important',
      '&::before': {
        border: '0px !important'
      },
      '&::after': {
        border: '0px !important'
      },
      borderRadius: '50% !important'
    },
    '& .reactEasyCrop_CropArea': {
      color: 'rgba(255,255,255,0.7) !important',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      pointerEvents: 'auto'
    }
  },
  boxItemCrop: {
    position: 'relative',
    height: '300px',
    maxWidth: '800px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  wrapHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    '& .reactEasyCrop_Container': {
      width: '100% !important',
      height: '300px !important',
      overflow: 'hidden'
    },
    '& .reactEasyCrop_CropAreaGrid': {
      height: '300px !important',
      width: '100% !important',
      '&::before': {
        border: '0px !important'
      },
      '&::after': {
        border: '0px !important'
      }
    },
    '& .reactEasyCrop_CropArea': {
      color: 'rgba(255,255,255,0.7) !important'
    }
  },
  wrapButtonIcon: {
    color: buttonColor.backgroundColor,
    margin: '0px 12px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  wrapButtonIconDisable: {
    margin: '0px 12px',
    color: 'rgba(0,0,0,0.3)'
  },
  inputSearch: {
    borderRadius: '10px !important',
    width: '100%',
    '&:hover': {
      cursor: 'text'
    }
  },
  searchIcon: {
    marginRight: 10
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
    },
    '& fieldset': {
      display: 'none'
    },
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
    },
    backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C',
    borderRadius: '10px !important',
    width: '100%'
  },
  notchedOutline: {
    borderRadius: '10px'
  },
  multilineColor: {
    fontSize: 13
  },
  loaderItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px'
  },
  lineClamp: {
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  },
  listLoadItem: {
    padding: '10px',
    margin: '0px !important',
    width: '100% !important'
  },
  boxCrop: {
    padding: '0px 14px 10px',
    width: '100%',
    height: '100%'
  }
}));

interface Props {
  type?: String;
  setKeyTabAvatar?: React.Dispatch<React.SetStateAction<any>> | any;
  files?: any;
  setFiles?: React.Dispatch<React.SetStateAction<any>> | any;
  formik?: any;
  typeForm?: String;
  typeInfo?: String;
  backgroundImage?: any;
  styleBox?: React.CSSProperties | any;
  avatarObj?: any;
}

const AvatarBanner = (props: Props) => {
  const {
    type,
    setKeyTabAvatar,
    files,
    setFiles,
    formik,
    typeForm,
    typeInfo,
    backgroundImage,
    styleBox,
    avatarObj
  } = props;

  const classes = useStyles();
  const theme: any = useTheme();
  const ReportInfo = useSelector(
    (state: any) => state.socialReportReducer.listReport
  );
  const meInfo = useSelector((state: any) => state.meReducer.info) || {};
  const groupSelected =
    useSelector((state: any) => state.groupReducer.groupSelected) || {};
  const pageInfo = useSelector((state: any) => state.pageReducer.info) || {};
  const [mediaData, setMediaData] = React.useState<any>([]);
  const [hasMoreMedia, setHasMoreMedia] = React.useState(true);
  const [hasMoreFrame, setHasMoreFrame] = React.useState(true);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState<any>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
  const [frameSelected, setFrameSelected] = React.useState<any>(null);
  const [listFrames, setListFrames] = React.useState<any>([]);
  const [keyWordFrames, setKeyWordFrames] = React.useState<any>(null);
  const [openDialogReport, setOpenDialogReport] = React.useState(false);
  const [itemSelected, setItemSelected] = React.useState<any>(null);
  const [keyTab, setKeyTab] = React.useState(
    typeInfo === 'image-avatar'
      ? 'upload'
      : typeInfo === 'frame'
      ? 'frames'
      : 'image'
  );
  const listMenu = [
    { text: 'Ảnh', id: 'image', type: 'image' },
    { text: 'Tải ảnh lên', id: 'upload', type: 'image' },
    {
      text: 'Thêm khung',
      id: 'frames',
      type: 'image'
    }
  ];

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDrop: (acceptedFiles: any) => {
      let filesState = [...files];
      let fileAccepts = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );

      let newFiles = filesState.concat(fileAccepts);

      setFiles(newFiles);
    }
  });

  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = React.useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        files[0]?.preview,
        croppedAreaPixels
      );
      let newFile = files[0];
      Object.defineProperty(newFile, 'avatar_show_url', {
        value: croppedImage,
        writable: true
      });
      setFiles([newFile]);
    } catch (e) {
      console.error(e);
    }
  }, [JSON.stringify(croppedAreaPixels)]);

  const handleZoomImage = type => {
    showCroppedImage();
    if (type === 'up' && zoom < 3) {
      setZoom(prev => prev + 0.3);
    } else if (type === 'down' && zoom > 1) {
      setZoom(prev => prev - 0.3);
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    let newKeyValue = listMenu[newValue].id;
    setFiles([]);
    setKeyTab(newKeyValue);
    setFrameSelected(null);
    if (newKeyValue === 'frames') {
      setKeyTabAvatar('frames');
    } else {
      setKeyTabAvatar('');
    }
  };

  async function getMediaInfo(maxId = null) {
    let response;
    let params = {
      media_type: 'image',
      limit: 20,
      max_id: maxId
    };
    if (typeForm && typeForm === 'group') {
      response = await getMediaGroupApi(groupSelected?.id, params);
    } else if (typeForm && typeForm === 'page' && pageInfo?.id) {
      response = await getMediaPage(pageInfo?.id, params);
    } else {
      response = await getMediaUser(meInfo?.id, params, null);
    }
    if (response.status === 200) {
      if (response.data.length) {
        if (response.data.length < 20) {
          setHasMoreMedia(false);
        }
        setMediaData(prev => _.uniqBy([...prev, ...response.data], 'id'));
      } else {
        setHasMoreMedia(false);
      }
    }
  }

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setZoom(newValue);
    showCroppedImage();
  };

  const funcLoad = () => {
    let mediaLast = [...mediaData].pop();
    let maxId = mediaLast?.id;

    if (mediaData.length && keyTab !== 'album') {
      getMediaInfo(maxId);
    }
  };

  const handleFileSelected = file => {
    let newFile = {
      ...file,
      preview: file.url
    };
    setFiles([newFile]);
  };

  const getListFrames = async (params, type) => {
    let response = await getFrameApi(params);
    if (response.status === 200) {
      if (type === 'search') {
        setListFrames(response.data);
        setHasMoreFrame(false);
      } else {
        if (response.data.length) {
          setListFrames(prev => _.uniqBy([...prev, ...response.data], 'id'));
        } else {
          setHasMoreFrame(false);
        }
      }
    }
  };

  const funcLoadFrames = () => {
    let postLast: any = [...listFrames].pop();
    let maxId = postLast?.id;
    getListFrames({ max_id: maxId, limit: 10 }, 'get');
  };

  const handleSelectedFrame = el => {
    setFrameSelected(el);
    let newFile = files[0];
    Object.defineProperty(newFile, 'frame_id', {
      value: el.id,
      writable: true
    });
    setFiles([newFile]);
  };

  React.useEffect(() => {
    showCroppedImage();
  }, [croppedAreaPixels]);

  React.useEffect(() => {
    if (avatarObj?.frame && keyTab === 'frames') {
      setFrameSelected(avatarObj.frame);
    }
  }, [avatarObj, keyTab]);

  const renderLoaderFrames = () => {
    return Array.from(Array(10).keys())?.map(el => (
      <Box className={classes.loaderItem}>
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant="text" sx={{ width: '100%', marginLeft: '10px' }} />
      </Box>
    ));
  };

  const renderLoaderMedia = () => (
    <Grid container spacing={2} sx={{ ...styleBox, paddingTop: '4px' }}>
      {Array.from(Array(4).keys())?.map((el, index) => (
        <Grid
          key={index}
          md={3}
          sm={4}
          xs={6}
          className={classes.gridItem}
          item
        >
          <Box>
            <Skeleton
              variant="rectangular"
              width={210}
              height={130}
              sx={{ borderRadius: '10px' }}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );

  const renderImage = () => {
    return (
      <>
        <div className={classes.wrapAvatar}>
          <div
            style={{
              width: 400,
              height: 400,
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {keyTab === 'frames' && (
              <div
                style={{
                  width: 400,
                  height: 400,
                  backgroundImage: `url(${
                    frameSelected ? frameSelected?.url : backgroundImage
                  })`,
                  zIndex: 10,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  pointerEvents: 'none'
                }}
              ></div>
            )}
            {files.length ? (
              <Cropper
                image={files[0].preview}
                crop={crop}
                zoom={zoom}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                cropShape="round"
                zoomWithScroll={false}
                aspect={1}
                objectFit="horizontal-cover"
              />
            ) : null}
          </div>
        </div>
        {keyTab !== 'frames' && <div style={{ height: 30 }}></div>}
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div
            onClick={() => handleZoomImage('down')}
            className={
              zoom > 1 ? classes.wrapButtonIcon : classes.wrapButtonIconDisable
            }
          >
            <i className="fa-solid fa-minus fa-lg"></i>
          </div>
          <Slider
            size="small"
            style={{ width: 350 }}
            value={zoom}
            onChange={handleSliderChange}
            min={1}
            max={3}
            step={0.3}
          />
          <div
            onClick={() => handleZoomImage('up')}
            className={
              zoom < 3 ? classes.wrapButtonIcon : classes.wrapButtonIconDisable
            }
          >
            <i className="fa-solid fa-plus fa-lg"></i>
          </div>
        </div>
      </>
    );
  };

  const renderBanner = () => {
    return (
      <div className={classes.boxCrop}>
        <Typography style={{ fontSize: 17, fontWeight: 500 }}>
          Kéo thả vị trí ảnh bìa
        </Typography>
        <div style={{ height: 30 }}></div>
        <div className={classes.boxItemCrop}>
          <div className={classes.wrapHeader}>
            <Cropper
              image={files.length ? files[0].preview : group_default}
              crop={crop}
              zoom={zoom}
              aspect={8.885 / 3}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              zoomWithScroll={false}
              objectFit="horizontal-cover"
            />
          </div>
        </div>
        <Typography
          style={{
            fontSize: 15,
            fontWeight: 500,
            marginTop: '30px',
            color: '#65676b'
          }}
        >
          <i className="fa-solid fa-earth-americas"></i> &nbsp; Ảnh bìa sẽ hiển
          thị công khai trên bảng tin
        </Typography>
      </div>
    );
  };

  const renderMedia = () => (
    <Grid container spacing={0.5} sx={{ ...styleBox }}>
      {mediaData?.length
        ? mediaData.map((el: any, index) => (
            <Grid
              onClick={() => handleFileSelected(el)}
              key={index}
              className={classes.gridItem}
              item
              md={3}
              sm={4}
              xs={6}
            >
              <div key={index} onClick={() => {}}>
                <div
                  className={classes.imgGif}
                  style={{ backgroundImage: `url(${el?.url})` }}
                />
              </div>
            </Grid>
          ))
        : !hasMoreMedia &&
          !mediaData.length && (
            <Typography
              variant="body1"
              sx={{
                fontSize: '15px',
                textAlign: 'center',
                padding: '25px 20px 30px 15px'
              }}
            >
              Không tìm thấy ảnh phù hợp.....
            </Typography>
          )}
    </Grid>
  );

  const renderFrames = () =>
    listFrames?.length ? (
      listFrames.map((el: any) => (
        <div key={el.id} className={classes.wrapFrame}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}
            onClick={() => handleSelectedFrame(el)}
          >
            <Avatar
              style={{ width: 40, height: 40, marginRight: 8 }}
              src={el.url}
            />
            <div>
              <Typography
                style={{ fontSize: 17, fontWeight: 500 }}
                className={classes.lineClamp}
              >
                {el.name}
              </Typography>
              <Typography style={{ fontSize: 15, color: '#65676b' }}>
                Của {el.author ?? 'Emso'}
              </Typography>
            </div>
          </div>
          <IconButtonOptions
            icon={
              <i
                style={{
                  fontSize: '16px',
                  fontWeight: '500',
                  marginLeft: '0px!important'
                }}
                className="fa-solid fa-ellipsis"
              ></i>
            }
            openPopup={true}
            options={[
              [
                {
                  id: 'report_frame',
                  label: 'Báo cáo',
                  action: () => {
                    setOpenDialogReport(true);
                    setItemSelected(el);
                  }
                }
              ]
            ]}
            styleListMenu={{ minWidth: '160px', padding: '0px' }}
            style={{
              width: '30px',
              height: '30px',
              borderRadius: '50%'
            }}
            typePopup="popover"
          />
        </div>
      ))
    ) : !hasMoreFrame && !listFrames.length ? (
      <Typography
        sx={{
          fontSize: '15px',
          textAlign: 'center'
        }}
      >
        Không tìm thấy khung bạn cần tìm......
      </Typography>
    ) : null;

  const renderInfiniteScroll = (data, renderItem, renderLoaderItem, type) => {
    return (
      <InfiniteScroll
        dataLength={data.length}
        next={type === 'media' ? funcLoad : funcLoadFrames}
        hasMore={type === 'media' ? hasMoreMedia : hasMoreFrame}
        loader={renderLoaderItem()}
        style={{ overflow: 'hidden' }}
        scrollableTarget={'scrollDialogTarget'}
        scrollThreshold={0.75}
      >
        {renderItem()}
      </InfiniteScroll>
    );
  };

  const renderComponentTag = () => {
    if (keyTab === 'upload') {
      return (
        <div style={{ padding: '8px 12px', margin: '15px 0px 0px 15px' }}>
          {files.length ? (
            type === 'avatar' ? (
              <InputTextField
                label="Mô tả"
                formik={formik}
                field="description"
              />
            ) : null
          ) : (
            <ButtonInherit
              label={
                <div
                  {...getRootProps({ className: 'dropzone' })}
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <i className="fas fa-file-plus"></i> &nbsp;
                  <span>Tải ảnh lên</span>
                  <input {...getInputProps()} />
                </div>
              }
            />
          )}

          <div style={{ height: 30 }}></div>

          {files.length ? (
            <div>{type === 'avatar' ? renderImage() : renderBanner()}</div>
          ) : null}
        </div>
      );
    } else if (keyTab === 'image') {
      return files.length ? (
        <>
          <ButtonInherit
            style={{ margin: '10px 0 0 10px' }}
            label="Chọn lại"
            action={() => setFiles([])}
          />
          <div style={{ height: 30 }}></div>

          {files.length ? (
            <div>{type === 'avatar' ? renderImage() : renderBanner()}</div>
          ) : null}
        </>
      ) : (
        <Box>
          {renderInfiniteScroll(
            mediaData,
            renderMedia,
            renderLoaderMedia,
            'media'
          )}
        </Box>
      );
    } else {
      return (
        <Grid style={{ height: '100%' }} container>
          <Grid style={{ padding: 0 }} item xs={5} id="listFrames">
            <Grid style={{ padding: '8px 12px' }} container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontSize: 20, fontWeight: 700 }}>
                  Chọn khung
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  classes={{ root: classes.inputSearch }}
                  size="small"
                  variant="outlined"
                  placeholder="Tìm kiếm khung"
                  value={keyWordFrames}
                  onChange={e => setKeyWordFrames(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon
                        classes={{ root: classes.searchIcon }}
                        sx={{ marginLeft: '-5px' }}
                      />
                    ),
                    classes: {
                      root: classes.cssOutlinedInput,
                      notchedOutline: classes.notchedOutline
                    },
                    className: classes.multilineColor
                  }}
                />
              </Grid>
            </Grid>
            {renderInfiniteScroll(
              listFrames,
              renderFrames,
              renderLoaderFrames,
              'frame'
            )}
          </Grid>
          <Grid
            style={{ backgroundColor: 'rgba(0,0,0,0.1)', padding: 20 }}
            item
            xs={7}
          >
            <Grid container>
              <Grid item xs={12}>
                <div
                  style={{
                    height: 56,
                    backgroundColor:
                      theme.palette.mode === 'light' ? '#fff' : '#383838',
                    borderRadius: 8
                  }}
                >
                  <InputTextField
                    label="Mô tả"
                    formik={formik}
                    field="description"
                  />
                </div>
              </Grid>
              <Grid item xs={12} style={{ height: 20 }} />

              <Grid item xs={12}>
                <div
                  style={{
                    width: '100%',
                    height: 483,
                    ...boxShadow,
                    border: 0,
                    backgroundColor:
                      theme.palette.mode === 'light' ? '#fff' : '#383838',
                    borderRadius: 8,
                    padding: '8px 20px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography style={{ fontSize: 17, fontWeight: 500 }}>
                    Xem trước
                  </Typography>
                  {renderImage()}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };

  React.useEffect(() => {
    setMediaData([]);
    if (!mediaData.length && keyTab === 'image' && meInfo?.id) {
      getMediaInfo();
    }
    if (!listFrames.length && keyTab === 'frames' && meInfo?.id) {
      getListFrames({ limit: 10 }, 'get');
    }
    if (keyTab === 'frames' && pageInfo?.id) {
      setFiles([
        { id: pageInfo?.avatar_media?.id, preview: pageInfo?.avatar_media?.url }
      ]);
    } else if (keyTab === 'frames' && meInfo?.id) {
      setFiles([
        { id: meInfo?.avatar_media?.id, preview: meInfo?.avatar_media?.url }
      ]);
    }
  }, [keyTab, meInfo?.id, pageInfo?.id]);

  React.useEffect(() => {
    if (keyWordFrames !== '' && !!keyWordFrames) {
      const timer = setTimeout(() => {
        getListFrames({ keyword: keyWordFrames }, 'search');
      }, 200);
      return () => {
        clearTimeout(timer);
      };
    } else if (keyWordFrames === '') {
      getListFrames({ limit: 10 }, 'get');
      setHasMoreFrame(true);
    }
  }, [keyWordFrames]);

  return (
    <>
      {!typeInfo && (
        <div style={{ padding: '0px 10px 10px 10px' }}>
          <div>
            <TabSocial
              value={keyTab}
              style={{
                borderBottom: `3px solid ${buttonColor.backgroundColor}`
              }}
              listTabs={(type === 'avatar'
                ? listMenu
                : listMenu.slice(0, 2)
              )?.map((el: any) => ({
                ...el,
                text: (
                  <Typography
                    style={
                      keyTab === el.id
                        ? {
                            fontSize: 15,
                            color: buttonColor.backgroundColor,
                            fontWeight: 500
                          }
                        : {
                            fontSize: 15,
                            color:
                              theme.palette.mode === 'dark'
                                ? '#fff'
                                : '#65676B',
                            fontWeight: 500
                          }
                    }
                  >
                    {el.text}
                  </Typography>
                )
              }))}
              handleChange={handleChange}
            />
          </div>
        </div>
      )}
      {keyTab === 'frames' && <Divider />}

      {renderComponentTag()}
      {openDialogReport && (
        <DialogReport
          open={openDialogReport}
          handleClose={() => setOpenDialogReport(false)}
          title={'Báo cáo'}
          headerText={'Tìm hỗ trợ hoặc báo cáo bình luận'}
          headerSubText={'Bạn có thể báo cáo bình luận sau khi chọn vấn đề.'}
          listCategoriesReport={ReportInfo}
          postReport={itemSelected}
        />
      )}
    </>
  );
};

export default AvatarBanner;
