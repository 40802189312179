import * as types from 'src/constants/store/musicSocial';
export const getListMusic = data => {
  return {
    type: types.GET_LIST_MUSIC,
    payload: { data }
  };
};

export const actionMusic = action => ({
  type: types.ACTION_MUSIC,
  payload: action
});

export const detailAlbum = dataAlbum => ({
  type: types.DETAIL_ALBUM,
  payload: dataAlbum
});

export const updateAlbumMusic = data => ({
  type: types.UPDATE_ALBUM_MUSICSPACE,
  payload: { data }
});

export const resetAlbumMusic = () => ({
  type: types.RESET_ALBUM_MUSICSPACE
});

export const getTopMusicAction = music => ({
  type: types.GET_TOP_MUSIC,
  payload: music
});

export const actionShowMusicPlayer = isShow => ({
  type: types.SHOW_MUSIC_PLAYER,
  payload: isShow
});

export const getVolumeMusic = volume => ({
  type: types.VOLUME_MUSIC,
  payload: volume
});

export const isCheckPath = path => ({
  type: types.CHECK_PATH_MUSIC,
  payload: path
});

export const getDataPlaylist = (data: any) => ({
  type: types.GET_DATA_PLAYLIST,
  payload: data
});

export const isShowMiniAudio = (isShow: any) => ({
  type: types.SHOW_MINI_AUDIO,
  payload: isShow
});

export const activeMusic = (itemActive: any) => ({
  type: types.ACTIVE_MUSIC,
  payload: itemActive
});

export const randomMusic = type => ({
  type: types.RANDOM_MUSIC,
  payload: type
});

export const resetMusicData = () => ({
  type: types.RESET_MUSIC_DATA
});
