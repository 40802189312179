import * as types from 'src/constants/store/friends';const _ = require('lodash');

const initialState = {
  listFriends: [] as any,
  maxId: null as any,
  hasMore: true as Boolean,
  listFriendsChat: [] as any
};

export const socialFriendsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_FRIEND_SUCCESS: {
      let { listFriends } = action.payload;
      let newState = _.cloneDeep(state);
      let newListFriends = [...newState.listFriends, ...listFriends];
      if (listFriends.length > 0) {
        newState.maxId = listFriends[listFriends.length - 1].id;
      }
      if (listFriends.length < 20) {
        newState.hasMore = false;
      }
      newState.listFriends = _.uniqBy(newListFriends, 'id');
      return newState;
    }

    case types.GET_LIST_STATUS_SUCCESS: {
      let { listStatus } = action.payload;
      let newState = _.cloneDeep(state);
      const newListFriends = newState.listFriends.map(el => {
        el.status = listStatus.find(_el => _el._id === el.id)?.status;
        return el;
      });
      newState.listFriends = newListFriends;
      return newState;
    }

    case types.UPDATE_STATUS_FRIEND: {
      let { username, status } = action.payload;
      let newState = _.cloneDeep(state);
      const newListFriends = newState.listFriends;
      const index = newListFriends.findIndex(el => el.username === username);
      if (index !== -1) {
        newListFriends[index].status = status;
      }
      newState.listFriends = newListFriends;

      return newState;
    }

    case types.GET_FRIEND_ONE_SUCCESS: {
      let { friend } = action.payload;
      let newState = _.cloneDeep(state);
      const newListFriends = newState.listFriends;
      const index = newListFriends.findIndex(
        el => el.username === friend.username
      );
      if (index === -1) {
        newListFriends.push(friend);
      }
      newState.listFriends = newListFriends;
      return newState;
    }
    case types.GET_FRIEND_CHAT_SUCCESS: {
      let { listFriends } = action.payload;
      let newState = _.cloneDeep(state);
      let listFriendsChat_new = [...newState.listFriendsChat, ...listFriends];
      newState.listFriends = _.uniqBy(listFriendsChat_new, 'id');
      return newState;
    }
    default:
      return state;
  }
};
