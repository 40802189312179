import {
  createNewComment,
  createUpdateCommentLive,
  followPageLiveStream,
  updateEyeLivestream,
  updateReactionPostLive,
  updateTypingCommentAction,
  updateUserJoinLivestream
} from 'src/store/action/socialLiveStreamAction';
import { updateReactionsPost } from 'src/store/action/socialPostAction';
import store from 'src/store/store';
import { urlStreamingSocket } from 'src/util/config';
const token = localStorage.getItem('token');

let wsStream;
let connected: Boolean = false;

const connectStreaming = () => {
  try {
    if (!connected) {
      wsStream = new WebSocket(`${urlStreamingSocket}?access_token=${token}`);

      wsStream.onopen = () => {
        connected = true;
      };
    }
  } catch (error) {
    console.log(error);
  }
};

const receiveStreaming = () => {
  // listen changed
  wsStream.onmessage = function (e) {
    let response = JSON.parse(e.data);
    let payload = JSON.parse(response.payload);

    if (response.event === 'update') {
      store.dispatch(createNewComment(payload));
      store.dispatch(createUpdateCommentLive(payload));
    } else if (response.event === 'typing') {
      // Dispatch
      store.dispatch(
        updateTypingCommentAction({
          id: payload.statusId,
          isTyping: true
        })
      );

      setTimeout(() => {
        store.dispatch(
          updateTypingCommentAction({
            id: payload.statusId,
            isTyping: false
          })
        );
      }, 5000);
    } else if (response.event === 'join') {
      store.dispatch(updateUserJoinLivestream(payload));
    } else if (response.event === 'subCount') {
      store.dispatch(updateEyeLivestream(payload.count));
    } else if (response.event === 'like') {
      store.dispatch(followPageLiveStream(payload));
    } else if (response.event === 'favourite') {
      store.dispatch(updateReactionPostLive(payload))
      store.dispatch(updateReactionsPost(payload))
    }
  };
};

const subStreaming = (statusId: string, userId: string, pageId: any) => {
  if (connected) {
    if (pageId) {
      wsStream.send(
        JSON.stringify({
          type: 'subscribe',
          stream: 'page',
          pageId: pageId,
          userId: userId
        })
      );
    } else {
      wsStream.send(
        JSON.stringify({
          type: 'subscribe',
          stream: 'status',
          statusId: statusId,
          userId: userId
        })
      );
    }
  }
};

const sendTypingStreaming = (statusId: string) => {
  if (connected) {
    wsStream.send(
      JSON.stringify({
        type: 'typing',
        stream: 'status',
        statusId: statusId
      })
    );
  }
};

const actionTyping = (statusId: string) => {
  if (connected) {
    wsStream.send(
      JSON.stringify({
        type: 'update',
        stream: 'status',
        statusId: statusId
      })
    );
  }
};
const newPeopleJoin = (statusId: string, peopleInfor: any) => {
  if (connected) {
    wsStream.send(
      JSON.stringify({
        type: 'join',
        stream: 'status',
        statusId: statusId,
        peopleInfor: peopleInfor
      })
    );
  }
};
const peopleOut = (statusId: string, peopleId: string) => {
  if (connected) {
    wsStream.send(
      JSON.stringify({
        type: 'out',
        stream: 'status',
        statusId: statusId,
        peopleId: peopleId
      })
    );
  }
};

const unSubStreaming = (statusId: string, userId: string, pageId: any) => {
  if (connected) {
    if (pageId) {
      wsStream.send(
        JSON.stringify({
          type: 'unsubscribe',
          stream: 'page',
          pageId: pageId,
          userId: userId
        })
      );
    } else {
      wsStream.send(
        JSON.stringify({
          type: 'unsubscribe',
          stream: 'status',
          statusId: statusId,
          userId: userId
        })
      );
    }
  }
};
// ==============================================================================

export {
  connectStreaming,
  receiveStreaming,
  subStreaming,
  unSubStreaming,
  newPeopleJoin,
  actionTyping,
  sendTypingStreaming,
  wsStream
};
