import axios, { AxiosRequestConfig } from 'axios';import { USER_TOKEN } from 'src/util/config';
import { BASE_ROOT } from 'src/util/config';

const fetchApi = async (endPoint, method) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    }
  };

  let response = await axios(configs);
  return response;
};

export const SearchSticker = async (params: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/stickers/search`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params
  };
  let response = await axios(configs);
  return response;
};

export const getInventoryApi = () => {
  return fetchApi('stickers/inventory', 'GET');
};

export const getTagsKeywordApi = () => {
  return fetchApi('tags/keyword', 'GET');
};

export const getTrendingApi = () => {
  return fetchApi('stickers/trendings', 'GET');
};

export const getEmoticonApi = () => {
  return fetchApi('stickers/tag2stickers/emotion', 'GET');
};

export const getCategoryApi = () => {
  return fetchApi('stickers/categories', 'GET');
};

export const getPackFreeApi = () => {
  return fetchApi('stickers/packs', 'GET');
};

export const getPackDetailApi = id => {
  return fetchApi(`stickers/packs/${id}`, 'GET');
};
